import { getValue } from "@utils/lodash";
import React, { memo, useMemo, useState } from "react";
import { config } from "../../../../../env";
import TickSvgComponent from "@assets/svg/tick";
import DoubleTickSvgComponent from "@assets/svg/double-tick";
import InfoSvgComponent from "@assets/svg/info";
import "react-image-gallery/styles/css/image-gallery.css";
import AccessTimeSvgComponent from "@assets/svg/accesstime";
import { Button, Modal } from "antd";
import DocumentThumbnail from "./doc-viewer";
import ImagePreview from "./image-preview";
import ContactDocPreview from "./components/DocPreviews/ContactDocPreview";
import TextPreview from "./components/DocPreviews/TextPreview";
import VideoPreview from "./components/DocPreviews/VideoPreview";
import AudioPreview from "./components/DocPreviews/AudioPreview";
import LocationPreview from "./components/DocPreviews/LocationPreview";
import StickerPreview from "./sticker-preview";

function DocumentFormatPreviews(props: any) {
  const { item } = props;

  const url = getValue(item, `upload_file.key`, "")
    ? `https://${config.URL}/${getValue(item, `upload_file.key`, "")}`
    : getValue(item, `upload_file`, "")
    ? `https://${config.URL}/${getValue(item, `upload_file`, "")}`
    : "";

  const images = [
    {
      original: url,
      thumbnail: url,
    },
  ];

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const getReadIcon = (item: object) => {
    if (!getValue(props, `receiver`, false)) {
      if (getValue(item, `is_read`, false) || getValue(item, `read`, false)) {
        return <DoubleTickSvgComponent color={"#34B7F1"} size={14} />;
      } else if (
        getValue(item, `is_delivered`, false) ||
        getValue(item, `delivered`, true) ||
        getValue(item, `received`, false) ||
        getValue(item, `is_received`, false)
      ) {
        return <DoubleTickSvgComponent color={"gray"} size={14} />;
      } else if (
        getValue(item, `is_sent`, false) ||
        getValue(item, `sent`, false)
      ) {
        return <TickSvgComponent color={"gray"} size={14} />;
      } else {
        return <AccessTimeSvgComponent size={14} />;
      }
      // else if (getValue(item, `is_received`, false)) {
      //   return <DoubleTickSvgComponent color={"green"} size={14} />;
      // }
    } else {
      return null;
    }
  };

  return (
    <>
      {getValue(item, `type`, "") !== "image" &&
        getValue(item, `type`, "") !== "document" &&
        getValue(item, `type`, "") !== "video" &&
        getValue(item, `type`, "") !== "audio" &&
        getValue(item, `type`, "") !== "location" &&
        getValue(item, `type`, "") !== "contacts" &&
        getValue(item, `type`, "") !== "sticker" &&
        (getValue(item, `type`, "") === "text" ||
          getValue(item, `type`, "") === "button" ||
          getValue(item, `message`, "") ||
          getValue(item, `text`, "")) && (
          <TextPreview item={item} getReadIcon={getReadIcon} />
        )}
      {getValue(item, `type`, "") === "image" && (
        getValue(item, `status`, "") === "failed" ? (
          <img src={'/images/failed-image.png'} alt="image" height={200} width={200}/>
        ) : (
          <ImagePreview item={item} imageUrl={url} getReadIcon={getReadIcon} />
        )
      )}
      {getValue(item, `type`, "") === "sticker" && (
        <StickerPreview item={item} getReadIcon={getReadIcon} imageUrl={url} />
      )}
      {getValue(item, `type`, "") === "video" && (
        <VideoPreview item={item} getReadIcon={getReadIcon} url={url} />
      )}
      {getValue(item, `type`, "") === "audio" && (
        <AudioPreview item={item} getReadIcon={getReadIcon} url={url} />
      )}
      {getValue(item, `type`, "") === "document" && (
        <DocumentThumbnail
          fileUrl={url}
          item={getValue(item, "upload_file", [])}
          request={item}
          getReadIcon={getReadIcon}
          text={getValue(item, `text`, "") || getValue(item, `text_body`, "")}
          receiver={getValue(props, `receiver`, false)}
        />
      )}
      {getValue(item, `type`, "") === "contacts" && (
        <ContactDocPreview item={item} getReadIcon={getReadIcon} />
      )}
      {getValue(item, `type`, "") === "location" && (
        <LocationPreview item={item} getReadIcon={getReadIcon} />
      )}
      {getValue(item, `error_reason`, "") &&
        getValue(item, `status`, "") === "failed" && (
          <div className="d-flex justify-content-end">
            <Button
              color="danger"
              variant="link"
              onClick={toggle}
              className=" "
              icon={<InfoSvgComponent color="#ff4d4f" size="16" />}
            >
              Failed
            </Button>
          </div>
        )}
      <Modal
        open={modal}
        onCancel={toggle}
        onOk={toggle}
        footer={[
          <Button key="cancel" onClick={toggle}>
            Close
          </Button>,
        ]}
        title="Cannot send message"
      >
        {getValue(item, `error_reason`, "")}
      </Modal>
    </>
  );
}

export default memo(DocumentFormatPreviews);
