import { QueryRequestHelper } from "@common/query-request-helper";
import AntSearchToolTip from "@components/custom/DebounceSelect";
import NewSearchSelect from "@components/custom/Dropdown/AntDropDown";
import { getWhatsappManagerConfig } from "@services/fb.service";
import { getValue } from "@utils/lodash";
import { Card, Checkbox } from "antd";
import React, { useEffect, useState } from "react";

const PaymentAdditionalSettings = (props: any) => {
  const {
    additionalInfo,
    setAdditionalInfo,
    allModules,
    getAllPipelineList,
    pipelineList,
    pipelineDetails,
  } = props;

  useEffect(() => {
    if (additionalInfo.enable_contact_pipeline_stage) {
      const contactModule = allModules.find(
        (item: any) => getValue(item, "api_name", "") === "contacts"
      );
      if (contactModule) {
        getAllPipelineList(getValue(contactModule, "id", ""), "contacts");
      }
    }
  }, [additionalInfo.enable_contact_pipeline_stage, allModules]);

  useEffect(() => {
    if (additionalInfo.enable_deal_pipeline_stage) {
      const dealModule = allModules.find(
        (item: any) => getValue(item, "api_name", "") === "deals"
      );
      if (dealModule) {
        getAllPipelineList(getValue(dealModule, "id", ""), "deals");
      }
    }
  }, [additionalInfo.enable_deal_pipeline_stage, allModules]);

  useEffect(() => {
    if (
      additionalInfo.enable_template ||
      additionalInfo.enable_canned_response
    ) {
      getFbConfig();
    }
  }, [additionalInfo.enable_template, additionalInfo.enable_canned_response]);

  const [waConfigInfo, setWaConfigInfo] = useState<any[]>([]);
  const getFbConfig = async () => {
    try {
      const payload = {
        status: "active",
      };
      const queryRequest = QueryRequestHelper(payload);
      let resp = await getWhatsappManagerConfig(queryRequest);
      if (resp) {
        const variableList = getValue(resp, `data.details`, [])
          .filter(
            (item: any) =>
              getValue(item, "phoneRegistered", false) &&
              getValue(item, "phoneVerificationStatus", false)
          )
          .map((item: any) => ({
            value: getValue(item, `id`, ""),
            label:
              getValue(item, "verifiedName", "") +
              " (" +
              getValue(item, `displayPhoneNumber`, "") +
              ")",
          }));

        setWaConfigInfo(variableList);
      } else {
        setWaConfigInfo([]);
      }
    } catch (error) {
      setWaConfigInfo([]);
    }
  };

  const handleCheckboxChange = (checked: boolean, key: string) => {
    setAdditionalInfo((prevInfo: any) => {
      let updatedInfo = { ...prevInfo, [key]: checked };

      const fieldsToToggle: { [key: string]: string[] } = {
        enable_template: ["wa_account_id", "template_id"],
        enable_canned_response: ["wa_account_id", "canned_response_id"],
        enable_deal_pipeline_stage: [
          "deal_pipeline_id",
          "deal_pipeline_stage_id",
        ],
        enable_contact_pipeline_stage: [
          "contact_pipeline_id",
          "contact_pipeline_stage_id",
        ],
      };

      const fields = fieldsToToggle[key] || [];

      if (checked) {
        fields.forEach((field) => {
          updatedInfo[field] = "";
        });

        if (key === "enable_contact_pipeline_stage") {
          const contactModule = allModules.find(
            (item: any) => getValue(item, "api_name", "") === "contacts"
          );
          getAllPipelineList(getValue(contactModule, "id", ""), "contacts");
        }
        if (key === "enable_deal_pipeline_stage") {
          const dealModule = allModules.find(
            (item: any) => getValue(item, "api_name", "") === "deals"
          );
          getAllPipelineList(getValue(dealModule, "id", ""), "deals");
        }
        if (key === "enable_template" || key === "enable_canned_response") {
          getFbConfig();
        }
      } else {
        fields.forEach((field) => {
          if (
            field === "wa_account_id" &&
            (updatedInfo.enable_template || updatedInfo.enable_canned_response)
          ) {
            return;
          }
          delete updatedInfo[field];
        });
      }

      return updatedInfo;
    });
  };

  return (
    <Card title="Additional Settings" className=" mx-5">
      <div className="d-flex flex-column gap-4 ">
        <div className="d-flex align-items-center gap-2">
          <Checkbox
            className="text-14"
            checked={additionalInfo.enable_contact_pipeline_stage}
            onChange={(e) =>
              handleCheckboxChange(
                e.target.checked,
                "enable_contact_pipeline_stage"
              )
            }
          />
          Change Contact Status to
          {additionalInfo.enable_contact_pipeline_stage && (
            <NewSearchSelect
              label="Contact Stage"
              size="large"
              placeholder="Select Contact Status"
              value={getValue(additionalInfo, `contact_pipeline_stage_id`, "")}
              onChange={(id: any, option: any) => {
                setAdditionalInfo({
                  ...additionalInfo,
                  contact_pipeline_id: getValue(
                    pipelineDetails,
                    "contacts.id",
                    ""
                  ),
                  contact_pipeline_stage_id: id,
                });
              }}
              options={pipelineList.contacts.map((item: any) => ({
                value: getValue(item, `id`, ""),
                label: getValue(item, `label`, ""),
              }))}
              hideLabel
              width="300px"
            />
          )}
        </div>
        <div className="d-flex align-items-center gap-2">
          <Checkbox
            className="text-14"
            checked={additionalInfo.enable_deal_pipeline_stage}
            onChange={(e) =>
              handleCheckboxChange(
                e.target.checked,
                "enable_deal_pipeline_stage"
              )
            }
          />
          Change Deal Status to
          {additionalInfo.enable_deal_pipeline_stage && (
            <NewSearchSelect
              label="Deal Stage"
              size="large"
              placeholder="Select Deal Status"
              value={getValue(additionalInfo, `deal_pipeline_stage_id`, "")}
              onChange={(id: any, option: any) => {
                setAdditionalInfo({
                  ...additionalInfo,
                  deal_pipeline_id: getValue(pipelineDetails, "deals.id", ""),
                  deal_pipeline_stage_id: id,
                });
              }}
              options={pipelineList.deals.map((item: any) => ({
                value: getValue(item, `id`, ""),
                label: getValue(item, `label`, ""),
              }))}
              hideLabel
              width="300px"
            />
          )}
        </div>
        <div className="d-flex align-items-center gap-2 flex-wrap">
          <Checkbox
            className="text-14"
            checked={additionalInfo.enable_template}
            onChange={(e) =>
              handleCheckboxChange(e.target.checked, "enable_template")
            }
          />
          Trigger
          {additionalInfo.enable_template && (
            <>
              Template from
              <NewSearchSelect
                label="Whatsapp Account"
                size="large"
                placeholder="Select Whatsapp Account"
                value={getValue(additionalInfo, `wa_account_id`, "")}
                onChange={(id: any, option: any) => {
                  setAdditionalInfo({
                    ...additionalInfo,
                    wa_account_id: id,
                  });
                }}
                options={waConfigInfo}
                hideLabel
                width="300px"
              />
              <AntSearchToolTip
                placeholder="Select Template"
                onChange={(id: any, data: any) => {
                  setAdditionalInfo({
                    ...additionalInfo,
                    template_id: id,
                  });
                }}
                value={getValue(additionalInfo, `template_id`, "")}
                label="Template"
                lookup_api="templates"
                hideLabel
                width="300px"
              />
            </>
          )}
        </div>
        <div className="d-flex align-items-center gap-2 flex-wrap">
          <Checkbox
            className="text-14"
            checked={additionalInfo.enable_canned_response}
            onChange={(e) =>
              handleCheckboxChange(e.target.checked, "enable_canned_response")
            }
          />
          Send Canned Response
          {additionalInfo.enable_canned_response && (
            <>
              {" "}
              from
              <NewSearchSelect
                label="Whatsapp Account"
                size="large"
                placeholder="Select Whatsapp Account"
                value={getValue(additionalInfo, `wa_account_id`, "")}
                onChange={(id: any, option: any) => {
                  setAdditionalInfo({
                    ...additionalInfo,
                    wa_account_id: id,
                  });
                }}
                options={waConfigInfo}
                hideLabel
                width="300px"
              />
              <AntSearchToolTip
                placeholder="Select Canned Response"
                onChange={(id: any, data: any) => {
                  setAdditionalInfo({
                    ...additionalInfo,
                    canned_response_id: id,
                  });
                }}
                value={getValue(additionalInfo, `canned_response_id`, "")}
                label="Canned Response"
                lookup_api="canned_response"
                hideLabel
                width="300px"
              />
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default PaymentAdditionalSettings;
