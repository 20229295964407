import AccountCircleSvgComponent from "@assets/svg/account-circle";
import WhatsappSvgComponent from "@assets/svg/whastapp";
import { convertCurrentDateToTime } from "@common/date-helpers";
import { getValue } from "@utils/lodash";
import { Avatar, Button, List, message, Modal, Popover } from "antd";
import { ArrowDownToLine, Download, Eye, Files } from "lucide-react";
import { saveAs } from "file-saver";
import { useState } from "react";

const ContactDocPreview = ({ item, getReadIcon }: any) => {
  const handleDownloadCSV = (item: any) => {
    if (!item) return;

    const contacts = getValue(item, `wa_contacts`, []);

    if (!contacts.length) {
      console.warn("No contacts available to download.");
      return;
    }

    let csvContent = "Name,Phone Number\n";

    contacts.forEach((contact: any) => {
      const name = getValue(contact, `name.formatted_name`, "Unknown");
      const phones = getValue(contact, `phones`, [])
        .map((p: any) => p.phone)
        .join(", ");

      csvContent += `"${name}","${phones}"\n`;
    });

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "contacts.csv");
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {" "}
      {getValue(item, `wa_contacts.length`, 0) < 2 ? (
        <>
          <div className="d-flex align-items-center gap-2 p-3 bg-light rounded-3 justify-content-between ">
            <div className="d-flex align-items-center gap-2">
              <img
                src={`/images/icons/contact-book.png`}
                alt="File"
                width={26}
                height={26}
              />

              <p>{getValue(item, `wa_contacts[0].name.formatted_name`, 0)}</p>

              <Popover
                content={
                  <div className="mt-2">
                    {getValue(item, `wa_contacts[0].phones`, []).map(
                      (phone: any, i: number) => (
                        <div className="d-flex justify-content-between align-items-center gap-4">
                          <p key={i} className="small_text__13">
                            {i + 1}. {getValue(phone, "phone", "")}{" "}
                          </p>
                          <div className="d-flex align-items-center gap-2">
                            <WhatsappSvgComponent size={16} color={"#58d68d"} />
                            <Files
                              size={16}
                              color="#434d5f"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  getValue(phone, "phone", "")
                                );
                                message.success("Copied to clipboard");
                              }}
                              className="cursor-pointer"
                            />
                          </div>
                        </div>
                      )
                    )}
                  </div>
                }
                title={getValue(item, `wa_contacts[0].name.formatted_name`, "")}
              >
                <Eye
                  size={26}
                  className="cursor-pointer ms-3 border p-1"
                  style={{ borderRadius: "50%" }}
                />
              </Popover>
              <ArrowDownToLine
                onClick={() => handleDownloadCSV(item)}
                size={26}
                className="cursor-pointer border p-1"
                color="#3c4043"
                style={{ borderRadius: "50%" }}
              />
            </div>
          </div>

          <p className="send-time p-1 mt-1 d-flex justify-content-end">
            {convertCurrentDateToTime(getValue(item, `created_at`, ""))}
            {getValue(item, "is_private", "") === false && (
              <span className="ms-2">{getReadIcon(item)}</span>
            )}
          </p>
        </>
      ) : (
        <>
          <div className="d-flex align-items-center gap-2 py-3 border-bottom justify-content-between ">
            <Avatar.Group>
              {getValue(item, `wa_contacts`, [])
                .slice(0, 4) // Show a maximum of 4 avatars
                .map((contact: any, i: number) => (
                  <Avatar
                    key={i}
                    icon={
                      <AccountCircleSvgComponent color={"#dfe5e7"} size={40} />
                    }
                    style={{ background: "#fff" }}
                  />
                ))}
              {getValue(item, `wa_contacts.length`, 0) > 4 && (
                <Avatar style={{ background: "#ccc", fontSize: "12px" }}>
                  +{getValue(item, `wa_contacts.length`, 0) - 4}
                </Avatar>
              )}
            </Avatar.Group>
            <p>
              {getValue(item, `wa_contacts[0].name.formatted_name`, "")}
              {getValue(item, `wa_contacts.length`, 0) > 1 && (
                <>
                  {" "}
                  and {getValue(item, `wa_contacts.length`, 0) - 1} other
                  contact
                  {getValue(item, `wa_contacts.length`, 0) - 1 === 1
                    ? ""
                    : "'s"}
                </>
              )}
            </p>
          </div>

          <p
            className="text-center pt-2 header_blue_text__14 cursor-pointer"
            onClick={showModal}
          >
            View all
          </p>
          <p className="send-time p-1 mt-1 d-flex justify-content-end">
            {convertCurrentDateToTime(getValue(item, `created_at`, ""))}
            {getValue(item, "is_private", "") === false && (
              <span className="ms-2">{getReadIcon(item)}</span>
            )}
          </p>
        </>
      )}
      <Modal
        title={`${getValue(item, `wa_contacts.length`, 0)} Contacts`}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={
          <Button
            color="default"
            variant="filled"
            icon={<Download size={16} />}
            onClick={() => {
              handleDownloadCSV(item);
              handleCancel();
            }}
          >
            Download All
          </Button>
        }
      >
        <List
          itemLayout="horizontal"
          dataSource={getValue(item, `wa_contacts`, [])}
          renderItem={(contact: any) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <AccountCircleSvgComponent color={"#dfe5e7"} size={40} />
                }
                title={<>{getValue(contact, `name.formatted_name`, "")}</>}
                description={getValue(contact, `phones`, [])
                  .map((phone: any) => phone.phone)
                  .join(", ")}
              />
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};

export default ContactDocPreview;
