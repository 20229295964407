import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllWebForms = (queryRequest: string) =>
  get(`${config.API_URL}/web-form/list?${queryRequest}`);

export const getWebFormRoute = (queryRequest: string) =>
  get(`${config.API_URL}/web-form/list/route?${queryRequest}`);

export const getSpecificWebForm = (id: string) =>
  get(`${config.API_URL}/web-form/${id}`);

export const createWebForm = (payload: object) =>
  post(`${config.API_URL}/web-form`, payload);

export const updateWebForm = (id: string, payload: object) =>
  patch(`${config.API_URL}/web-form/${id}`, payload);

export const assignAgentsToWebForm = (id: string, payload: object) =>
  patch(`${config.API_URL}/web-form/${id}/attach-agents`, payload);

export const detachAgentsFromWebForm = (id: string, payload: object) =>
  patch(`${config.API_URL}/web-form/${id}/detach-agents`, payload);

export const deleteWebForm = (id: string) =>
  Delete(`${config.API_URL}/web-form/${id}`);

// public form

export const getPublicFormInfo = (queryRequest: string) =>
  get(`${config.API_URL}/public/route/web-form?${queryRequest}`);

export const createPublicForm = (queryRequest: string, payload: object) =>
  post(`${config.API_URL}/public/route/web-form?${queryRequest}`, payload);

export const getPublicGlobalFormInfo = (orgID: string, route_name: string) =>
  get(`${config.API_URL}/public/route/${orgID}/web-form/${route_name}`);
