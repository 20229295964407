import React, { useMemo, useRef, useState, useEffect } from "react";
import { Select, Spin, Empty } from "antd";
import type { SelectProps } from "antd";
import debounce from "lodash/debounce";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "common/query-request-helper";
import { getLookupAPIs } from "@components/helpers/lookup-apis";
import { DefaultOptionType } from "antd/lib/select";

export interface AntSearchToolTipProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, "options" | "children"> {
  lookup_api?: string;
  label?: string;
  labelKey?: string;
  selectKey?: string;
  forModule?: string;
  module_id?: string;
  disabled?: boolean;
  placeholder?: string;
  width?: string | number;
  required?: boolean;
  validator?: any;
  errorLabel?: string;
  add?: boolean;
  button_title?: string;
  handleOpenPopup?: () => void;
  hideLabel?: boolean;
  options?: any[];
  optionsData?: any;
  value: any;
  onChange?: (value: any, data: any) => void;
}

function AntSearchToolTip<ValueType = any>({
  lookup_api,
  label,
  labelKey = "label",
  selectKey = "id",
  forModule,
  module_id,
  disabled,
  placeholder = "Search here...",
  width = "100%",
  required = false,
  validator,
  errorLabel,
  add = false,
  button_title,
  handleOpenPopup,
  hideLabel,
  options: propOptions,
  optionsData,
  value,
  onChange,
  ...props
}: AntSearchToolTipProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<DefaultOptionType[]>([]);
  const fetchRef = useRef(0);

  // Initialize data with provided options if any
  useEffect(() => {
    if (propOptions) {
      const uniqueOptionsMap = new Map();
      propOptions.forEach((item) => {
        const itemValue = getValue(item, selectKey, "");
        if (!uniqueOptionsMap.has(itemValue)) {
          uniqueOptionsMap.set(itemValue, {
            label: getValue(item, labelKey, ""),
            value: itemValue,
            data: item,
          });
        }
      });
      const transformedOptions = Array.from(uniqueOptionsMap.values());
      setOptions(transformedOptions);
    } else if (lookup_api) {
      fetchInitialData(value);
    }
  }, [propOptions, lookup_api]);

  // Fetch initial data including selected value if present
  const fetchInitialData = async (id?: string) => {
    try {
      if (!lookup_api) return;

      setFetching(true);

      if (value && !options.some((opt) => opt.value === value)) {
        // Fetch specific item
        const specificPayload = {
          [selectKey]: value,
          forModule,
          module_id,
          id
        };
        const specificQuery = QueryRequestHelper(specificPayload);
        const specificResp = await getLookupAPIs(lookup_api, specificQuery);

        // Fetch remaining items
        const defaultPayload = {
          page_no: 1,
          page_size: value ? 9 : 10,
          forModule,
          module_id,
          id:id
        };
        const defaultQuery = QueryRequestHelper(defaultPayload);
        const defaultResp = await getLookupAPIs(lookup_api, defaultQuery);

        // Combine and deduplicate results
        const combinedData = [
          ...(specificResp
            ? Array.isArray(specificResp)
              ? specificResp
              : [specificResp]
            : []),
          ...(defaultResp || []),
        ].filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                getValue(t, selectKey, "") === getValue(item, selectKey, "")
            )
        );

        setOptions(formatOptions(combinedData));
      } else {
        // Normal fetch when no specific value needed
        const payload = {
          page_no: 1,
          page_size: 10,
          forModule,
          module_id,
          id:id
        };
        const queryRequest = QueryRequestHelper(payload);
        const resp = await getLookupAPIs(lookup_api, queryRequest);
        setOptions(formatOptions(resp || []));
      }
    } catch (error) {
      console.error("Error fetching initial data:", error);
      setOptions([]);
    } finally {
      setFetching(false);
    }
  };

  const handleFetchData = async (search_text: string) => {
    if (!lookup_api) return;

    if (!search_text) {
      fetchInitialData(value);
      return;
    }

    fetchRef.current += 1;
    const fetchId = fetchRef.current;

    try {
      const payload = {
        search: search_text,
        page_no: 1,
        page_size: 10,
        forModule,
        module_id,
      };
      setFetching(true);
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getLookupAPIs(lookup_api, queryRequest);
      if (fetchId !== fetchRef.current) return;

      setOptions(formatOptions(resp || []));
    } catch (error) {
      console.error("Error fetching data:", error);
      setOptions([]);
    } finally {
      setFetching(false);
    }
  };

  const formatOptions = (data: any[]): DefaultOptionType[] => {
    if (!data || data.length === 0) return [];

    const uniqueOptionsMap = new Map();
    data.forEach((item) => {
      const itemValue = getValue(item, selectKey, "");
      if (!uniqueOptionsMap.has(itemValue)) {
        uniqueOptionsMap.set(itemValue, {
          label: getValue(item, labelKey, ""),
          value: itemValue,
          data: item,
        });
      }
    });

    return Array.from(uniqueOptionsMap.values());
  };

  const filterOptions = (input: string, option: any) => {
    const queryLower = input.toLowerCase();
    const labelValue = option?.label || "";
    return String(labelValue).toLowerCase().includes(queryLower);
  };

  const debounceFetcher = useMemo(() => {
    return debounce(handleFetchData, 0);
  }, [lookup_api, forModule, module_id]);

  const handleChange = (selectedValue: any, option: any) => {
    if (!selectedValue && lookup_api) {
      fetchInitialData(value);
    }

    if (onChange) {
      if (Array.isArray(option)) {
        const dataArray = option.map((opt: any) => opt?.data);
        onChange(selectedValue, dataArray);
      } else {
        onChange(selectedValue, (option as DefaultOptionType)?.data);
      }
    }
  };

  const dropdownRender = (menu: React.ReactElement) => {
    const menuWithEmptyState = React.cloneElement(menu, {
      ...menu.props,
      children:
        !fetching && (!options || options.length === 0) ? (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No data found"
          />
        ) : (
          menu.props.children
        ),
    });

    if (!add) return menuWithEmptyState;

    return (
      <>
        {menuWithEmptyState}
        <div
          style={{
            padding: "8px",
            borderTop: "1px solid #e8e8e8",
            cursor: "pointer",
          }}
          onClick={() => {
            handleOpenPopup?.();
          }}
        >
          <img
            src="/dropdown/add.svg"
            style={{ width: 16, height: 16, marginRight: 8 }}
            alt="add"
          />
          <span>{button_title}</span>
        </div>
      </>
    );
  };

  const finalOptions = useMemo(() => {
    if (!optionsData) return options;

    const optionsMap = new Map();
    optionsData.forEach((option: any) => {
      const optionValue = option.value;
      if (!optionsMap.has(optionValue)) {
        optionsMap.set(optionValue, option);
      }
    });

    return Array.from(optionsMap.values());
  }, [optionsData, options]);

  // Refetch when value changes
  useEffect(() => {
    if (lookup_api && value) {
      fetchInitialData(value);
    }
  }, [value]);

  return (
    <div>
      {!hideLabel && label && (
        <p className="form-label">
          {label} {required && <span className="text-danger">*</span>}
        </p>
      )}
      <Select
        showSearch
        size="large"
        className={`${required ? "ant-select-required" : ""}`}
        style={{ width: width }}
        filterOption={lookup_api ? false : filterOptions}
        onSearch={lookup_api ? debounceFetcher : undefined}
        notFoundContent={
          fetching ? (
            <Spin size="small" />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No data found"
            />
          )
        }
        options={finalOptions}
        disabled={disabled}
        placeholder={placeholder}
        dropdownRender={dropdownRender}
        allowClear
        value={value ? value : null}
        onChange={handleChange}
        onClear={() => {
          if (lookup_api) {
            fetchInitialData();
          }
        }}
        {...props}
      />
      {validator?.current?.message && (
        <p className="error-text">
          {validator.current.message(errorLabel || label, value, "required")}
        </p>
      )}
    </div>
  );
}

export default AntSearchToolTip;
