import CloseMainSvgComponent from "@assets/svg/close-main";
import { getColorCode, getTextColor } from "@common/colors";
import { getOnlyFirstLetterOfWord } from "@common/text-helpers";
import Loader from "@components/common/Loader/loading";
import { getValue } from "@utils/lodash";
import { Switch } from "antd";
import { ReactMultiEmail } from "react-multi-email";

function SettingsNotifications(props: any) {
  const { request, setRequest, isLoading } = props;

  const handleKeyDown = (e: any, name: any) => {
    if (e.key === " ") {
      e.preventDefault(); // Prevent space character
      return;
    }
    if (e.key === "Enter") {
      e.preventDefault();
      const currentValue = e.target.value;
      const cursorPosition = e.target.selectionStart;
      const textBeforeCursor = currentValue.substring(0, cursorPosition);
      const textAfterCursor = currentValue.substring(cursorPosition);
      setRequest({
        ...request,
        [name]: textBeforeCursor + "\n" + textAfterCursor,
      });
      // Move cursor to the new line
      setTimeout(() => {
        e.target.selectionStart = cursorPosition + 1;
        e.target.selectionEnd = cursorPosition + 1;
      }, 0);
    }
  };

  const handleChange = (e: any, name: any) => {
    const value = e.target.value;
    setRequest({
      ...request,
      [name]: value,
    });
  };

  const handleChangeEmail = (emails: string[]) => {
    setRequest({ ...request, send_email_notification_to: emails });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="w-50">
      <>
        <div className="">
          <div className="d-flex gap-4 align-items-center">
            <h6 className="header_text__14">Enable Email Notification</h6>
            <Switch
              size="small"
              checked={getValue(request, `enable_email_notification`, false)}
              onChange={() =>
                setRequest({
                  ...request,
                  enable_email_notification: !getValue(
                    request,
                    `enable_email_notification`,
                    false
                  ),
                })
              }
            />
          </div>
        </div>
        {getValue(request, `enable_email_notification`, false) && (
          <div className="my-4">
            <h6 className="header_text__14">Send Email Notifications to</h6>
            <ReactMultiEmail
              placeholder="Enter recipients"
              emails={
                getValue(request, `send_email_notification_to.length`, 0) > 0
                  ? getValue(request, `send_email_notification_to`, [])
                  : []
              }
              style={{ minHeight: "30vh", minWidth: "600px" }}
              className="w-100"
              onChange={handleChangeEmail}
              enableSpinner
              allowDuplicate={false}
              allowDisplayName={true}
              getLabel={(
                email: string,
                index: number,
                removeEmail: (index: number) => void
              ) => {
                return (
                  <div
                    data-tag
                    key={index}
                    className="d-flex align-items-center"
                  >
                    <span
                      data-tag-handle
                      onClick={() => removeEmail(index)}
                      className=""
                    >
                      <div
                        className="profile_circle_email"
                        style={{
                          background: getColorCode(
                            getOnlyFirstLetterOfWord(email)
                          ),
                          // border: "1px solid green",
                        }}
                      >
                        <p
                          className="title"
                          style={{
                            color: getTextColor(
                              getOnlyFirstLetterOfWord(email)
                            ),
                          }}
                        >
                          {getOnlyFirstLetterOfWord(email)}
                        </p>
                      </div>
                    </span>
                    <span className="mx-2">{email}</span>
                    <span
                      data-tag-handle
                      onClick={() => removeEmail(index)}
                      className="react_mail_close_icon"
                    >
                      {/* <DropdownCloseSvgComponent size={18}/> */}
                      <CloseMainSvgComponent size={18} />
                    </span>
                  </div>
                );
              }}
            />
          </div>
        )}
      </>
      {/* <div className="d-flex align-items-center gap-4">
        <p>Receive email with daily leads</p>
        <Switch
          size="small"
          className="ms-2"
          checked={getValue(request, `daily_conversations_active`, false)}
          onChange={(e: any) =>
            setRequest({
              ...request,
              daily_conversations_active: !getValue(
                request,
                `daily_conversations_active`,
                false
              ),
            })
          }
        />
      </div>
      <div>
        <InputRuleForm
          inputType="TEXTAREA"
          name="name"
          placeholder="Enter Daily Conversation Emails"
          label="Daily Conversation Emails"
          value={getValue(request, `daily_conversations_emails`, "")}
          onChange={(e: any) => handleChange(e, "daily_conversations_emails")}
          onKeyDown={(e: any) => handleKeyDown(e, "daily_conversations_emails")}
        />
      </div>
      <div className="d-flex align-items-center gap-4 mt-4">
        <p>Receive email with daily conversations</p>
        <Switch
          size="small"
          className="ms-2"
          checked={getValue(request, `daily_leads_collected_active`, false)}
          onChange={(e: any) =>
            setRequest({
              ...request,
              daily_leads_collected_active: !getValue(
                request,
                `daily_leads_collected_active`,
                false
              ),
            })
          }
        />
      </div>
      <div>
        <InputRuleForm
          inputType="TEXTAREA"
          name="name"
          placeholder="Enter Daily Leads Collected Emails"
          label="Daily Leads Collected Emails"
          value={getValue(request, `daily_leads_collected_emails`, "")}
          onChange={(e: any) => handleChange(e, "daily_leads_collected_emails")}
          onKeyDown={(e: any) =>
            handleKeyDown(e, "daily_leads_collected_emails")
          }
        />
      </div> */}
    </div>
  );
}

export default SettingsNotifications;
