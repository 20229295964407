import ListLoader from "@components/common/Loader";
import SettingsPageDetails from "@components/Pages/PageDescription/SettingsPageDetails";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import { Button, Dropdown, MenuProps, Modal, Pagination } from "antd";
import { useEffect, useState } from "react";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import { MoreVertical, Plus } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { agentShiftDetails } from "@common/Data/page-details-data";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "@common/query-request-helper";
import { deleteAgentShift, getAllShifts } from "@services/agent-shifts.service";
import { toast } from "sonner";
import { capitalizeFirstLetter } from "@common/text-helpers";
import "./agent-shifts.scss";
import AgentShiftsNoDataPage from "@components/common/NoData/agent-shifts-nodata";
import useDynamicTitle from "@context/useDynamicTitle";

const AgentShifts = ({ props }: any) => {
  useDynamicTitle("Agent Shifts");
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getAgentShift(true);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getAgentShift(true);
    }
  }, [window.location.href]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getAgentShift = async (status: boolean) => {
    try {
      setIsLoading(status);
      const payload = {
        page_size: getValue(UrlParams, `page_size`, "")
          ? getValue(UrlParams, `page_size`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getAllShifts(queryRequest);
      if (resp) {
        setList(getValue(resp, "data.shifts", []));
        setPage_no(getValue(resp, "data.pagination.page", 0));
        setTotalCount(getValue(resp, "data.pagination.total", 0));
      }
    } catch (error) {
      toast.error("Failed to Agent Shifts");
    } finally {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: any, limit: any) => {
    setPage_no(page_no);
    setLimit(limit);

    let payload = {
      ...UrlParams,
      page_no: page_no,
      page_size: limit,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    setPage_no(page_no);
  };

  const handleDeleteModal = (id: string, name: string) => {
    Modal.confirm({
      title: `Are you sure you want to delete shift - ${name}?`,
      content: "Once deleted, this shift cannot be recovered.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await deleteAgentShift(id);
          toast.success("Shift deleted successfully");
          getAgentShift(true);
        } catch (error) {
          toast.error("Error deleting shift");
        }
      },
    });
  };

  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        icon="agent-shifts"
        title="Agent Shifts"
        showSubmit
        submitIcon={<Plus size={18} />}
        submitText="New Shift"
        handleSubmit={() =>
          navigate(
            `/${getValue(
              params,
              "orgId",
              ""
            )}/settings/helpdesk/agent-shifts/create`
          )
        }
      />
      <div className="sla-policy-wrapper">
        <div className="w-70 p-4 agent-shifts-card-container">
          <p className="small_text__14">
            Create your team's schedules to make sure that no customer is missed
            when your teams go in and out of shifts.
          </p>
          {isLoading ? (
            <ListLoader />
          ) : list.length !== 0 ? (
            <div className="d-grid gap-2 my-3">
              {list.map((listItem, index) => {
                const items: MenuProps["items"] = [
                  {
                    key: "1",
                    label: "Delete",
                    onClick: () =>
                      handleDeleteModal(
                        getValue(listItem, "id", ""),
                        getValue(listItem, "name", "")
                      ),
                  },
                ];

                return (
                  <div
                    key={index}
                    className="border p-4 rounded-3 agent-shifts-card"
                  >
                    <div
                      className="cursor-pointer w-100"
                      onClick={() =>
                        navigate(
                          `/${getValue(
                            params,
                            "orgId",
                            ""
                          )}/settings/helpdesk/agent-shifts/edit/${getValue(
                            listItem,
                            "id",
                            ""
                          )}`
                        )
                      }
                    >
                      <h6 className="header_text__16 d-flex align-items-center gap-2 mb-2 ">
                        {capitalizeFirstLetter(getValue(listItem, "name", ""))}
                        {/* {getValue(listItem, "is_default", "") && (
                            <Tooltip title="Default Business Hour">
                              <LockKeyhole size={14} />
                            </Tooltip>
                          )} */}
                      </h6>
                      {getValue(listItem, "description", "") && (
                        <p className="small_text__14 pb-1">
                          {getValue(listItem, "description", "")}
                        </p>
                      )}
                      <div className="d-flex align-items-center gap-3">
                        <p className="small_text__13">
                          {getValue(listItem, "time_zone", "")}
                        </p>
                        {/* <Badge status="default" />
                          <p className="small_text__13">
                            {getValue(listItem, "groups.length", "")} groups
                            associated
                          </p> */}
                      </div>
                    </div>
                    {!getValue(list, "is_default", "") && (
                      <Dropdown menu={{ items }}>
                        <MoreVertical size={18} />
                      </Dropdown>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <AgentShiftsNoDataPage />
          )}
          {totalCount > 10 && (
            <Pagination
              align="center"
              className="mt-4"
              total={totalCount}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              defaultPageSize={limit}
              defaultCurrent={page_no}
              onChange={handleChangePagination}
            />
          )}
        </div>
        <div className="w-30 p-4 sla-policy-right-details">
          <SettingsPageDetails data={agentShiftDetails} />
        </div>
      </div>
    </CompanyProfileDetailsLayout>
  );
};

export default AgentShifts;
