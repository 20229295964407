import React from "react";
import TicketList from "./components/list";

function TicketsLeftSction(props: any) {
  return (
    <TicketList
      ticketList={props.ticketList}
      selectedTicket={props.selectedTicket}
      mainQueryRequest={props.mainQueryRequest}
    />
  );
}

export default TicketsLeftSction;
