import {
  handleDateNestedArrayChange,
  handleSelectNestedArrayChange,
  handleTextNestedArrayChange,
} from "@common/handleChange";
import { capitalizeFirstLetter } from "@common/text-helpers";
import AntSearchToolTip from "@components/custom/DebounceSelect";
import NewSearchSelect from "@components/custom/Dropdown/AntDropDown";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import DateMonthYearPicker from "@components/custom/Forms/Date";
import Input from "@components/custom/Forms/Input";
import MainTimePicker from "@components/custom/Forms/Time";
import TextArea from "@components/custom/Forms/textarea";
import { getValue } from "@utils/lodash";
import React from "react";

function CriteriaFilterBuilder(props: any) {
  const renderForm = (
    item: any,
    index: number,
    label: string,
    pipelineStageList: any
  ) => {
    //------------------------ Required ----------------------//
    // let required =
    //   getValue(item, `required`, false) ||
    //   getValue(item, `system_required`, false);
    let required = false;
    const findDateInfo = () => {
      let info = getValue(item, `conditions`, []).find(
        (items: object) =>
          getValue(props, `criteriaInfo.operator`, "") ===
          getValue(items, `operator`, "")
      );
      return info;
    };
    //----------------------- Due date time -------------------//
    switch (getValue(item, `fieldType`, "")) {
      case "text":
        return (
          <div className="">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextNestedArrayChange(
                  props.parentIndex,
                  "value",
                  index,
                  e,
                  "criteria_filters",
                  props.setRequest
                )
              }
              type={"text"}
              placeholder={`Enter ${getValue(item, `fieldLabel`, "")}`}
              label={`${
                getValue(item, `name`, "") === "name"
                  ? capitalizeFirstLetter(label)
                  : ""
              }${" "}${capitalizeFirstLetter(
                getValue(item, `fieldLabel`, "")
              )}`}
              hideLabel
              inline
              value={getValue(props, `value[${0}].key`, null)}
              validator={props.validator}
            />
          </div>
        );
      case "email":
        return (
          <div className="">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextNestedArrayChange(
                  props.parentIndex,
                  "value",
                  index,
                  e,
                  "criteria_filters",
                  props.setRequest
                )
              }
              type={"email"}
              placeholder={`Enter ${getValue(item, `fieldLabel`, "")}`}
              label={`${
                getValue(item, `name`, "") === "name" ? label : ""
              }${" "}${getValue(item, `fieldLabel`, "")}`}
              hideLabel
              inline
              value={getValue(props, `value[${0}].key`, null)}
              validator={props.validator}
            />
          </div>
        );
      case "tel":
        return (
          <div className="w-100">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextNestedArrayChange(
                  props.parentIndex,
                  "value",
                  index,
                  e,
                  "criteria_filters",
                  props.setRequest
                )
              }
              type={"number"}
              placeholder={`Enter ${getValue(item, `fieldLabel`, "")}`}
              label={`${
                getValue(item, `name`, "") === "name" ? label : ""
              }${" "}${getValue(item, `fieldLabel`, "")}`}
              hideLabel
              inline
              value={getValue(props, `value[${0}].key`, null)}
              validator={props.validator}
            />
          </div>
        );
      case "url":
        return (
          <div className="">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextNestedArrayChange(
                  props.parentIndex,
                  "value",
                  index,
                  e,
                  "criteria_filters",
                  props.setRequest
                )
              }
              type={"url"}
              placeholder={`Enter ${getValue(item, `fieldLabel`, "")}`}
              label={`${
                getValue(item, `name`, "") === "name" ? label : ""
              }${" "}${getValue(item, `fieldLabel`, "")}`}
              hideLabel
              inline
              value={getValue(props, `value[${0}].key`, null)}
              validator={props.validator}
            />
          </div>
        );
      case "currency":
      case "percent":
      case "integer":
      case "decimal":
      case "auto_number":
      case "autonumber":
      case "bigint":
        return (
          <div className="">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextNestedArrayChange(
                  props.parentIndex,
                  "value",
                  index,
                  e,
                  "criteria_filters",
                  props.setRequest
                )
              }
              type={"number"}
              placeholder={`Enter ${getValue(item, `fieldLabel`, "")}`}
              label={`${getValue(item, `fieldLabel`, "")}`}
              hideLabel
              inline
              value={getValue(props, `value[${0}].key`, null)}
              validator={props.validator}
            />
          </div>
        );
      case "textarea":
        return (
          <div className="">
            <TextArea
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextNestedArrayChange(
                  props.parentIndex,
                  "value",
                  index,
                  e,
                  "criteria_filters",
                  props.setRequest
                )
              }
              type={"textarea"}
              label={`${getValue(item, `fieldLabel`, "")}`}
              placeholder={`Enter ${getValue(item, `fieldLabel`, "")}`}
              rows={2}
              hideLabel
              inline
              value={getValue(props, `value[${0}].key`, null)}
              validator={props.validator}
            />
          </div>
        );
      case "date":
      case "datetime_dashboard":
        const selectedOperatorInfo: any = findDateInfo();
        if (
          getValue(selectedOperatorInfo, `operator`, "") === "{IN}" ||
          getValue(selectedOperatorInfo, `operator`, "") === "{NOT_IN}" ||
          getValue(selectedOperatorInfo, `operator`, "") === "{BEFORE}" ||
          getValue(selectedOperatorInfo, `operator`, "") === "{AFTER}"
        )
          return (
            <div className="">
              <DateMonthYearPicker
                {...item}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleDateNestedArrayChange(
                    props.parentIndex,
                    "value",
                    index,
                    e,
                    "criteria_filters",
                    props.setRequest
                  )
                }
                type={"text"}
                label={`${getValue(item, `fieldLabel`, "")}`}
                placeholder={`Enter ${getValue(item, `fieldLabel`, "")}`}
                hideLabel
                inline
                value={
                  getValue(props, `value[${0}].key`, null)
                    ? getValue(props, `value[${0}].key`, null)
                    : null
                }
              />
            </div>
          );
        // else if (
        //   getValue(selectedOperatorInfo, `operator`, "") === "BETWEEN" ||
        //   getValue(selectedOperatorInfo, `operator`, "") === "NOT_BETWEEN"
        // ) {
        //   return (
        //     <div className="d-flex align-items-center w-100 gap-1">
        //       <div className="w-50">
        //         <DateMonthYearPicker
        //           {...item}
        //           onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        //             handleDateNestedArrayChange(
        //               props.parentIndex,
        //               "value",
        //               index,
        //               e,
        //               "criteria_filters",
        //               props.setRequest
        //             )
        //           }
        //           type={"text"}
        //           label={`${getValue(item, `fieldLabel`, "")}`}
        //           placeholder={`Enter ${getValue(item, `fieldLabel`, "")}`}
        //           hideLabel
        //           inline
        //           value={
        //             getValue(props, `value[${0}].key`, null)
        //               ? getValue(props, `value[${0}].key`, null)
        //               : null
        //           }
        //         />
        //       </div>
        //       <div className="w-50">
        //         <DateMonthYearPicker
        //           {...item}
        //           onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        //             handleDateNestedArrayChange(
        //               props.parentIndex,
        //               "value",
        //               index,
        //               e,
        //               "criteria_filters",
        //               props.setRequest
        //             )
        //           }
        //           type={"text"}
        //           label={`${getValue(item, `fieldLabel`, "")}`}
        //           placeholder={`Enter ${getValue(item, `fieldLabel`, "")}`}
        //           hideLabel
        //           inline
        //           value={
        //             getValue(props, `value[${1}].key`, null)
        //               ? getValue(props, `value[${1}].key`, null)
        //               : null
        //           }
        //         />
        //       </div>
        //     </div>
        //   );
        // }
        else {
          return null;
        }
      case "time":
        return (
          <div className="">
            <MainTimePicker
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleDateNestedArrayChange(
                  props.parentIndex,
                  "value",
                  index,
                  e,
                  "criteria_filters",
                  props.setRequest
                )
              }
              type={"text"}
              label={`${getValue(item, `fieldLabel`, "")}`}
              placeholder={`Enter ${getValue(item, `fieldLabel`, "")}`}
              showTimeInput
              hideLabel
              inline
              value={
                getValue(props, `value[${0}].key`, null)
                  ? getValue(props, `value[${0}].key`, null)
                  : null
              }
              validator={props.validator}
            />
          </div>
        );
      case "select":
        return (
          <div className="">
            <NewSearchSelect
              {...item}
              hideLabel
              label={getValue(item, `fieldLabel`, "")}
              options={
                getValue(item, `fieldId`, "") === "pipeline_stage_id"
                  ? pipelineStageList
                  : getValue(item, `dropDownOpts`, [])
              }
              selectKey={"value"}
              onChange={(id: string, data: any) =>
                handleSelectNestedArrayChange(
                  props.parentIndex,
                  "value",
                  index,
                  data,
                  "criteria_filters",
                  props.setRequest,
                  "value"
                )
              }
              value={getValue(props, `value[${0}].key`, "")}
              placeholder={`Select ${getValue(item, `fieldLabel`, "")}`}
              validator={props.validator}
            />
          </div>
        );
      case "lookup":
        return (
          <div className="">
            {/* <SearchToolTip
              {...item}
              label={`label`}
              lookup_api={
                getValue(item, `lookupModule.api_name`, "")
                  ? getValue(item, `lookupModule.api_name`, "")
                  : getValue(item, `fieldId`, "") === "pipeline_stage_id"
                  ? `stage_${getValue(
                      props,
                      `request.moduleId`,
                      ""
                    )}_${getValue(props, `request.pipelineId`, "")}`
                  : ""
              }
              selectKey={"id"}
              onChange={(e: any) =>
                handleSelectNestedArrayChange(
                  props.parentIndex,
                  "value",
                  index,
                  e,
                  "criteria_filters",
                  props.setRequest,
                  "id"
                )
              }
              value={getValue(props, `value[${0}].key`, "")}
              placeholder={`Enter ${getValue(item, `fieldLabel`, "")}`}
              hideLabel
            /> */}
            <AntSearchToolTip
              {...item}
              label={`label`}
              lookup_api={
                getValue(item, `lookupModule.api_name`, "")
                  ? getValue(item, `lookupModule.api_name`, "")
                  : getValue(item, `fieldId`, "") === "pipeline_stage_id"
                  ? `stage_${getValue(
                      props,
                      `request.moduleId`,
                      ""
                    )}_${getValue(props, `request.pipelineId`, "")}`
                  : ""
              }
              selectKey={"id"}
              onChange={(e: any, data: any) =>
                handleSelectNestedArrayChange(
                  props.parentIndex,
                  "value",
                  index,
                  data,
                  "criteria_filters",
                  props.setRequest,
                  "id"
                )
              }
              value={getValue(props, `value[${0}].key`, "")}
              placeholder={`Enter ${getValue(item, `fieldLabel`, "")}`}
              hideLabel
              validator={props.validator}
            />
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div className="workflow_builder_container-criteria">
      {renderForm(props.item, 0, "", props.pipelineStageList)}
    </div>
  );
}

export default CriteriaFilterBuilder;
