import type { MenuProps } from "antd";
import { Dropdown, Space, Typography } from "antd";
import { getValue } from "@utils/lodash";
import { formatString } from "@common/text-helpers";

const items: MenuProps["items"] = [
  {
    key: "include",
    label: "Included",
  },
  {
    key: "not-include",
    label: "Not Include",
  },
];

const FilterDropwdown = (props: any) => (
  <Dropdown
    menu={{
      items,
      selectable: true,
      selectedKeys: [getValue(props, `mode`, "")],
      onClick: props.onToggle,
    }}
  >
    <Typography.Link>
      <Space>
        <h6 className=" header_text__14">{formatString(getValue(props, `label`, ""))} </h6>
        <span>
          {formatString(getValue(props, `mode`, ""))}
          <img
            src={"/dropdown/arrow_down_filter.svg"}
            className="inline_dropdown_image"
          />
        </span>
      </Space>
    </Typography.Link>
  </Dropdown>
);

export default FilterDropwdown;
