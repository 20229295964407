import React, { useEffect, useState } from "react";
import { isValidPhoneNumber } from "libphonenumber-js";
import { getValue } from "@utils/lodash";
import countries from "@json/dialcodes.json";
import Input from "./Input";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import CountrySearchToolTip from "../Dropdown/CountrySearchTooltip";
import { handleChangeNumber } from "@common/handleChange";
import timezones from "@common/json/standardized-timezone-data.json";
import countryTimezones from "@common/json/standardized-timezone-data.json";
import { useStateContext } from "@context/dataContext";
import NewCountrySelect from "../Dropdown/NewCountrySelect";

function DialcodePhoneInputCommon(props: any) {
  const {
    value,
    label,
    placeholder,
    hideLabel,
    validator,
    showWhatsapp,
    index,
    setFields,
    mandatory,
    api_name,
    phoneInline,
    removeValidation,
    countryWidth,
    inputWidth,
  } = props;

  useEffect(() => {
    if (!value) {
      // setCountryCode("");
      setNationalNumber("");
    }
  }, [value]);

  const { userInfo, orgInfo } = useStateContext();
  const [countryCode, setCountryCode] = useState("");
  const [nationalNumber, setNationalNumber] = useState("");
  const findTimezone: any = (timezone: string) => {
    return timezones.find(
      (item: object) => getValue(item, `value`, "") === timezone
    );
  };

  const findCountryCode: any = (timezone: string) => {
    let info: any = countryTimezones.find(
      (item: object) => getValue(item, `value`, "") === timezone
    );
    return getValue(info, `phoneCode`, "");
  };
  // useEffect(() => {
  //   if (!countryCode && !props.countryCode) {
  //     if (getValue(userInfo, `timezone`, "")) {
  //       setCountryCode(findCountryCode(getValue(userInfo, `timezone`, "")));
  //     } else {
  //       const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  //       if (timeZone === "Asia/Calcutta") {
  //         if (findCountryCode("Asia/Kolkata")) {
  //           setCountryCode(findCountryCode("Asia/Kolkata"));
  //         } else {
  //           setCountryCode("+91");
  //         }
  //       } else if (timeZone === findTimezone(timeZone)) {
  //         if (findCountryCode(timeZone)) {
  //           setCountryCode(findCountryCode(timeZone));
  //         } else {
  //           setCountryCode("+91");
  //         }
  //       } else {
  //         if (findCountryCode("Asia/Kolkata")) {
  //           setCountryCode(findCountryCode("Asia/Kolkata"));
  //         } else {
  //           setCountryCode("+91");
  //         }
  //       }
  //     }
  //   }
  // }, [countryCode, getValue(userInfo, `timezone`, ""), !props.countryCode]);

  useEffect(() => {
    // If countryCode already exists, prefer user selection or org phone_code
    if (countryCode) {
      // Only update if org has a phone_code that differs from current selection
      // This ensures user manual changes aren't overridden unnecessarily
      if (
        getValue(orgInfo, "phone_code", "") &&
        getValue(orgInfo, "phone_code", "") !== countryCode
      ) {
        setCountryCode(getValue(orgInfo, "phone_code", ""));
      }
      return;
    }

    // No countryCode set yet - determine default

    // First priority: Use org's phone_code if available
    if (getValue(orgInfo, "phone_code", "")) {
      setCountryCode(getValue(orgInfo, "phone_code", ""));
      return;
    }

    // Second priority: Use org's timezone to determine country code
    if (getValue(orgInfo, "timezone", "")) {
      const timezoneCode = findCountryCode(getValue(orgInfo, "timezone", ""));
      if (timezoneCode) {
        setCountryCode(timezoneCode);
        return;
      }
    }

    // Third priority: Use browser's timezone
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    // Handle special case for India (Calcutta → Kolkata)
    if (timeZone === "Asia/Calcutta") {
      const kolkataCode = findCountryCode("Asia/Kolkata");
      setCountryCode(kolkataCode || "+91");
      return;
    }

    // Try to match browser timezone
    if (findTimezone(timeZone)) {
      const timezoneCode = findCountryCode(timeZone);
      if (timezoneCode) {
        setCountryCode(timezoneCode);
        return;
      }
    }

    // Final fallback: Use India's code
    const kolkataCode = findCountryCode("Asia/Kolkata");
    setCountryCode(kolkataCode || "+91");
  }, [orgInfo]); // Only depend on orgInfo changes, not countryCode

  const handleInputChange = (input: any) => {
    if (input) {
      if (
        (getValue(input, `length`, 0) === 10 ||
          getValue(input, `length`, 0) < 10) &&
        !input.startsWith("+")
      ) {
        setNationalNumber(input);
      } else if (getValue(input, `length`, 0) >= 10) {
        // let lastTenDigits = getLastTenDigits(input);
        // let digitsExceptLastTen = getDigitsExceptLastTen(input);
        // setCountryCode(digitsExceptLastTen);
        // setNationalNumber(lastTenDigits);
        separateCountryCodeAndPhoneNumber(input);
      } else {
        const parsedNumber = parsePhoneNumberFromString(input);
        if (parsedNumber) {
          setNationalNumber(parsedNumber.nationalNumber);
        } else {
          let timezone = getValue(localStorage, `timezone`, "");
          let info: any = countryTimezones.find(
            (item: object) => getValue(item, `value`, "") === timezone
          );
          if (getValue(info, `phoneCode`, "")) {
            setCountryCode(getValue(info, `phoneCode`, ""));
          } else {
            setCountryCode("");
            setNationalNumber("");
          }
        }
      }
    }
  };
  useEffect(() => {
    handleInputChange(value);
  }, [value]);

  const handleChangeCountryCode = (obj: object) => {
    let input = getValue(obj, `dial_code`, "");
    setCountryCode(getValue(obj, `dial_code`, ""));
    props.onChange(input + nationalNumber);
  };

  const handleChangePhoneNumber = (e: any) => {
    let input = handleChangeNumber(e);
    setNationalNumber(input);
    props.onChange(countryCode + input);
  };

  function separateCountryCodeAndPhoneNumber(phoneNumber: string) {
    // Loop through the list of country codes
    for (let codeObj of countryTimezones) {
      const code = codeObj.phoneCode;
      // Check if the phone number starts with the current country code
      if (phoneNumber.startsWith(code)) {
        // Extract the phone number by removing the country code from the start
        const numberWithoutCode = phoneNumber.slice(code.length);
        setNationalNumber(numberWithoutCode);
        setCountryCode(code);
      }
    }
    // If no country code matches, return null or an error message
    return null;
  }

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        {!hideLabel && (
          <label className={`form-label`}>
            {label}{" "}
            {mandatory && (
              <span className=" small_text__16 text-danger">*</span>
            )}
          </label>
        )}
        {showWhatsapp && isValidPhoneNumber(value) && (
          <img
            src="/images/whatsapp.png"
            alt=""
            height={25}
            width={25}
            onClick={() =>
              isValidPhoneNumber(value)
                ? window.open(
                    `https://api.whatsapp.com/send?text=Hi&phone=+91${value}`
                  )
                : null
            }
          />
        )}
      </div>
      <div className="d-flex">
        {/* <div className={`${countryWidth ? countryWidth : "w-20"}`}> */}
        {/* <CountrySearchToolTip
            label={"label"}
            data={countries.map((item: object) => ({
              ...item,
              label: getValue(item, "dial_code", ""),
              value: getValue(item, "dial_code", ""),
            }))}
            selectKey="dial_code"
            value={countryCode}
            onChange={handleChangeCountryCode}
            required={mandatory}
            phoneInline={phoneInline}
          /> */}
        <NewCountrySelect
          value={countryCode}
          onChange={(e: any) => handleChangeCountryCode(e)}
        />
        {/* </div> */}
        <div className={`${inputWidth ? inputWidth : "w-100 ms-1"}`}>
          <input
            {...props}
            hideLabel
            removeBottom
            value={nationalNumber}
            onChange={handleChangePhoneNumber}
            placeholder={placeholder}
            mandatory={false}
            validator={null}
            className="form-control"
            // onBlur={handleBlur}
          />
        </div>
      </div>
      {!removeValidation && (validator || nationalNumber) && (
        <p style={{ color: "#d93025", fontSize: "12px" }}>
          {value && !isValidPhoneNumber(countryCode + nationalNumber)
            ? `Please enter valid ${label}`
            : validator &&
              validator.current.message(
                label,
                isValidPhoneNumber(countryCode + nationalNumber)
                  ? nationalNumber
                  : "",
                "required"
              )}
        </p>
      )}
    </div>
  );
}

export default DialcodePhoneInputCommon;
