import React, { memo, useState, useCallback, useMemo } from "react";
import { Button, Empty, Image, Modal, Popover, Tooltip } from "antd";
import { get } from "lodash";
import {
  handleDownloadOnClick,
  handleOpenInNewTab,
} from "@common/downloadfile";
import { concatString } from "@common/text-helpers";
import { config } from "env";
import { Download, Plus, Trash2, X } from "lucide-react";
import { createAttachments } from "@services/attachments.service";
import { toast } from "sonner";
import { mediaUpload } from "@services/upload.service";
import DragAndDropFileComponent from "@components/custom/FileUpload/file-drag-drop";
import CommonDocumentList from "@components/custom/FileUpload/document-list";
import { getFileIcon } from "@common/document-format-helpers";
// Interfaces
interface Document {
  id?: string;
  key?: string;
  filename?: string;
  extension?: string;
}

interface Attachment {
  file: Document;
  id: string;
  [key: string]: any;
}

interface ContactInfo {
  id?: string;
  attachments?: Attachment[];
  [key: string]: any;
}

interface UserDocumentsProps {
  contactInfo?: ContactInfo;
  selectedModule: string;
  getSpecificLeadInfo: (id: string) => void;
  getSpecificContactInfo: (id: string) => void;
}

const isImageFile = (extension?: string) => {
  if (!extension) return false;
  const imageExtensions = [".jpg", ".jpeg", ".png", ".svg"];
  return imageExtensions.includes(extension.toLowerCase());
};

// Memoized Components
const DocumentGridItem = memo(
  ({
    item,
    onRemove,
  }: {
    item: Attachment;
    onRemove: (item: Attachment) => void;
  }) => {
    const fileKey = get(item, "file.key", "");
    const filename = get(item, "file.filename", "Untitled");
    const extension = get(item, "file.extension", "");
    const id = get(item, "id", "");

    if (isImageFile(extension)) {
      return (
        <div key={id} className="relative group">
          <Tooltip title={concatString(filename, 20)}>
            <Image
              src={`https://${config.ASSETS_CDN_ENDPOINT}/${fileKey}`}
              alt={filename}
              preview={{ mask: false }}
              fallback="/images/extention/document.png"
              style={{ objectFit: "cover" }}
              width="100%"
              height="80px"
              className="cursor-pointer"
            />
          </Tooltip>
          <div
            className="absolute top-2 right-2 cursor-pointer opacity-0 group-hover:opacity-100"
            onClick={() => onRemove(item)}
          >
            <X size={16} className="text-red-500 hover:text-red-700" />
          </div>
        </div>
      );
    }

    return (
      <div key={id} className="relative group">
        <Tooltip title={concatString(filename, 20)}>
          <div
            className="d-flex align-items-center justify-content-center p-3 cursor-pointer"
            onClick={() =>
              handleOpenInNewTab(
                `https://${config.ASSETS_CDN_ENDPOINT}/${fileKey}`
              )
            }
          >
            <img src={getFileIcon(extension)} alt="" className="w-100" />
          </div>
        </Tooltip>
        <div
          className="absolute top-2 right-2 cursor-pointer opacity-0 group-hover:opacity-100"
          onClick={() => onRemove(item)}
        >
          <X size={16} className="text-red-500 hover:text-red-700" />
        </div>
      </div>
    );
  },
  (prev, next) =>
    prev.item.id === next.item.id &&
    prev.item.file.key === next.item.file.key &&
    prev.onRemove === next.onRemove
);

const DocumentListItem = memo(
  ({
    item,
    onRemove,
  }: {
    item: Attachment;
    onRemove: (item: Attachment) => void;
  }) => {
    const fileKey = get(item, "file.key", "");
    const filename = get(item, "file.filename", "Untitled");
    const extension = get(item, "file.extension", "");
    const id = get(item, "id", "");

    return (
      <div
        key={id}
        className="document_list_container d-flex align-items-center justify-content-between"
      >
        <div
          className="cursor-pointer d-flex align-items-center gap-2"
          onClick={() =>
            handleOpenInNewTab(
              `https://${config.ASSETS_CDN_ENDPOINT}/${fileKey}`
            )
          }
        >
          <img
            src={getFileIcon(extension)}
            alt=""
            className="document-list-image"
          />
          <span>{concatString(filename, 20)}</span>
        </div>
        <div className="d-flex align-items-center gap-1">
          <Button
            size="small"
            color="default"
            variant="text"
            icon={<Download size={16} />}
            onClick={() =>
              handleDownloadOnClick(
                `https://${config.ASSETS_CDN_ENDPOINT}/${fileKey}`,
                filename
              )
            }
          />
          <Button
            size="small"
            color="danger"
            variant="text"
            onClick={() => onRemove(item)}
            icon={<Trash2 size={16} />}
          />
        </div>
      </div>
    );
  },
  (prev, next) =>
    prev.item.id === next.item.id &&
    prev.item.file.key === next.item.file.key &&
    prev.onRemove === next.onRemove
);

// Main Component
const UserDocuments: React.FC<UserDocumentsProps> = memo(
  ({
    contactInfo,
    selectedModule,
    getSpecificLeadInfo,
    getSpecificContactInfo,
  }) => {
    const [submitLoading, setSubmitLoading] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [documentList, setDocumentList] = useState<Document[]>([]);
    const [open, setOpen] = useState(false);

    // Memoized Data
    const attachments = useMemo(
      () => contactInfo?.attachments || [],
      [contactInfo?.attachments]
    );
    const contactId = useMemo(() => contactInfo?.id, [contactInfo?.id]);
    const moduleIdKey = useMemo(
      () => (selectedModule === "contacts" ? "contact_id" : "lead_id"),
      [selectedModule]
    );

    // Handlers
    const handleSubmit = useCallback(async () => {
      if (!contactId || documentList.length === 0) return;

      try {
        setSubmitLoading(true);
        const newFiles = documentList.map((item) => ({
          action: "add",
          file_id: item.id,
        }));

        const existingFiles = attachments.map((item) => ({
          action: "update",
          file_id: item.file.id,
          id: item.id,
        }));

        const payload = {
          files: [...newFiles, ...existingFiles],
          [moduleIdKey]: contactId,
        };

        const resp = await createAttachments(payload);

        if (resp) {
          toast.success("Attached successfully");
          setDocumentList([]);
          setOpen(false);

          if (selectedModule === "contacts") {
            getSpecificContactInfo(contactId);
          } else {
            getSpecificLeadInfo(contactId);
          }
        }
      } catch (error) {
        toast.error("Failed to attach documents");
      } finally {
        setSubmitLoading(false);
      }
    }, [
      contactId,
      documentList,
      moduleIdKey,
      getSpecificContactInfo,
      getSpecificLeadInfo,
      attachments,
    ]);

    const handleRemoveDocument = useCallback(
      (fileToRemove: Attachment) => {
        if (!contactId) return;

        Modal.confirm({
          title: "Are you sure you want to remove this document?",
          content: "This action cannot be undone.",
          onOk: async () => {
            try {
              setSubmitLoading(true);

              const files = attachments.map((attachment) => ({
                action: attachment.id === fileToRemove.id ? "delete" : "update",
                file_id: attachment.file.id,
                id: attachment.id,
              }));

              const payload = {
                files,
                [moduleIdKey]: contactId,
              };

              const resp = await createAttachments(payload);

              if (resp) {
                toast.success("Document removed successfully");
                if (selectedModule === "contacts") {
                  getSpecificContactInfo(contactId);
                } else {
                  getSpecificLeadInfo(contactId);
                }
              }
            } catch (error) {
              toast.error("Failed to remove document");
            } finally {
              setSubmitLoading(false);
            }
          },
        });
      },
      [
        contactId,
        moduleIdKey,
        getSpecificContactInfo,
        getSpecificLeadInfo,
        attachments,
      ]
    );

    const handleUploadDocuments = useCallback(
      async (files: FileList | null) => {
        if (!files) return;

        try {
          setUploadLoading(true);
          const filesArray = Array.from(files);

          const uploadPromises = filesArray.map(async (file) => {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("filename", file.name);
            formData.append("is_public", "true");
            formData.append("collection", "public-images");
            const resp = await mediaUpload(formData);
            return resp?.data;
          });

          const uploadedDocuments = (await Promise.all(uploadPromises)).filter(
            Boolean
          );
          setDocumentList((prev) => [...prev, ...uploadedDocuments]);
        } catch (error) {
          toast.error("Failed to upload files");
        } finally {
          setUploadLoading(false);
        }
      },
      []
    );

    const handleRemoveUploadedDocument = useCallback((index: number) => {
      setDocumentList((prev) => prev.filter((_, i) => i !== index));
    }, []);

    // Virtualized Grid Renderer
    // const GridRenderer = useMemo(() => {
    //   const columnCount = 4;
    //   const gap = 4;
    //   const containerWidth =
    //     document.querySelector(".contact-documents-grid-wrapper")
    //       ?.clientWidth ?? 480;
    //   const itemWidth =
    //     (containerWidth - gap * (columnCount - 1)) / columnCount;
    //   const itemHeight = itemWidth;
    //   const rowCount = Math.ceil(attachments.length / columnCount);
    //   const totalHeight = rowCount * itemHeight + (rowCount - 1) * gap;

    //   return (
    //     <Grid
    //       columnCount={columnCount}
    //       columnWidth={itemWidth}
    //       height={totalHeight}
    //       rowCount={rowCount}
    //       rowHeight={itemHeight}
    //       width={containerWidth}
    //       itemData={{ items: attachments, gap }}
    //     >
    //       {({ columnIndex, rowIndex, style, data }: any) => {
    //         const index = rowIndex * columnCount + columnIndex;
    //         const item = data.items[index];
    //         if (!item) return null;
    //         const adjustedStyle = {
    //           ...style,
    //           left: `${
    //             parseInt(style.left as string) + columnIndex * data.gap
    //           }px`,
    //           top: `${parseInt(style.top as string) + rowIndex * data.gap}px`,
    //           width: `${parseInt(style.width as string) - data.gap}px`,
    //           height: `${parseInt(style.height as string) - data.gap}px`,
    //         };

    //         return (
    //           <div style={adjustedStyle}>
    //             <DocumentGridItem item={item} onRemove={handleRemoveDocument} />
    //           </div>
    //         );
    //       }}
    //     </Grid>
    //   );
    // }, [attachments, handleRemoveDocument]);

    // Popover Content
    const popoverContent = useMemo(
      () => (
        <div className="p-4 popover-document-upload-wrapper">
          <DragAndDropFileComponent uploadFile={handleUploadDocuments} />
          <CommonDocumentList
            uploadLoading={uploadLoading}
            documentList={documentList}
            handleRemoveDocuments={handleRemoveUploadedDocument}
          />
          {documentList.length > 0 && (
            <div className="d-flex gap-2 align-items-center justify-content-end mt-4">
              <Button
                onClick={() => {
                  setOpen(false);
                  setDocumentList([]);
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                loading={submitLoading}
                onClick={handleSubmit}
                disabled={documentList.length === 0}
              >
                Upload Attachments
              </Button>
            </div>
          )}
        </div>
      ),
      [
        documentList,
        uploadLoading,
        submitLoading,
        handleUploadDocuments,
        handleRemoveUploadedDocument,
        handleSubmit,
      ]
    );

    if (!attachments.length) {
      return (
        <Empty description="No documents available" className="mt-4">
          <Popover
            open={open}
            placement="left"
            title="Add Attachment"
            trigger="click"
            onOpenChange={setOpen}
            content={popoverContent}
          >
            <Button icon={<Plus size={16} />}>Add</Button>
          </Popover>
        </Empty>
      );
    }
    return (
      <>
        <div className="d-flex align-items-center gap-3 justify-content-between w-100 mb-4">
          <div></div>
          <Popover
            open={open}
            placement="left"
            title="Add Attachment"
            trigger="click"
            onOpenChange={setOpen}
            content={popoverContent}
          >
            <Button icon={<Plus size={16} />}>Add</Button>
          </Popover>
        </div>

        <Image.PreviewGroup>
          <div className="contact-documents-grid-wrapper">
            {attachments.map((item) => (
              <DocumentGridItem
                key={item.id}
                item={item}
                onRemove={handleRemoveDocument}
              />
            ))}
          </div>
        </Image.PreviewGroup>
      </>
    );
  }
);

export default memo(UserDocuments);
