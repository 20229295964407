import { convertCurrentDateToTime } from "@common/date-helpers";
import WhatsappMessageLoader from "@components/common/Loader/whatsapp-message-loader";
import { getValue } from "@utils/lodash";
import { Mic } from "lucide-react";

const AudioPreview = ({ item, getReadIcon, url }: any) => {
  return (
    <div className="d-flex flex-column">
      {url ? (
        <audio controls className="sender-message">
          <source src={url} type="audio/ogg" />
        </audio>
      ) : (
        <div className="d-flex align-items-center gap-2">
          <div className="audio-loader-icon">
            <WhatsappMessageLoader icon={<Mic size={20} color="#7e7e7e" />} />
          </div>
        </div>
      )}
      {getValue(item, `media_caption`, "") && (
        <p className="small_text__14 mt-2 text-start">
          {getValue(item, `media_caption`, "")}
        </p>
      )}
      <p className="send-time p-1 mt-1 d-flex justify-content-end">
        {convertCurrentDateToTime(getValue(item, `created_at`, ""))}
        {getValue(item, "is_private", "") === false && (
          <span className="ms-2">{getReadIcon(item)}</span>
        )}
      </p>
    </div>
  );
};

export default AudioPreview;
