import { ReloadOutlined } from "@ant-design/icons";
import { getValue } from "@utils/lodash";
import { Button } from "antd";
import React from "react";

function WebsiteRightSection(props: any) {
  const { findStaticUrl, scrapeInfo, linkURLs, mainURLs } = props;
  return (
    <div className="stats-sidebar">
      <div className="stats-card">
        <h3>Sources</h3>
        <div className="stat-item">
          <div className="label">
            {getValue(linkURLs, `length`, 0)} Links
            {/* (71,630 detected chars) */}
          </div>
        </div>
        <div className="stat-item">
          {/* <div className="label">Total detected characters</div> */}
          {/* <div className="value">71,630</div> */}
          <div className="label">Total character Limit</div>
          <div className="limit">11,000,000</div>
        </div>
      </div>
      <div className="d-flex gap-2 mt-3">
        <Button
          color="default"
          variant="solid"
          onClick={props.handleTrainLink}
          className="w-100"
          size="large"
          disabled={
            props.disableTrain 
            // ||
            // Array.isArray(linkURLs) && linkURLs.length === 0 ||
            // Array.isArray(mainURLs) && mainURLs.length === 0
          }
        >
          {props.disableTrain ? "Training..." : "Train Chatbot"}
        </Button>

        {getValue(scrapeInfo, `status`, "") &&
          getValue(linkURLs, `length`, 0) > 0 &&
          getValue(scrapeInfo, `status`, "") !== "completed" &&
          !findStaticUrl() && (
            <Button
              size="large"
              color="default"
              variant="solid"
              // className="retrain-button"
              onClick={props.ScrapedDocumentStatus}
              icon={<ReloadOutlined />}
            />
          )}
      </div>
    </div>
  );
}

export default WebsiteRightSection;
