import { useEffect, useMemo, useRef, useState } from "react";
import React from "react";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import {
  QueryRequestHelper,
  removeBracketsFromQuery,
} from "common/query-request-helper";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "sonner";
import { handleNavigateURL } from "common/url-navigate";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import { concatString, removeDuplicates } from "common/text-helpers";
import {
  deleteTicket,
  getAllTickets,
  updateTicket,
} from "@services/tickets.service";
import { useStateContext } from "@context/dataContext";
import { sortTaskFieldList } from "@components/Pages/Pipeline/helpers/create-pipeline-helper";
import CommonAddSubPipeline from "@components/Pages/Pipeline/AddSubPipeline";
import CommonAddFilterView from "@components/Pages/Pipeline/AddFilterViews";
import Pagination from "@components/Pages/Pipeline/common/pagination";
import { listAllModules } from "@services/modules.service";
import useDynamicTitle from "@context/useDynamicTitle";
import TicketSubHeader from "@components/Pages/Tickets/SubHeader";
import TicketsLeftSideBar from "@pages/Private/Tickets/components/LeftSection";
import "./tickets.scss";
import {
  getAllModuleFields,
  getModuleColumns,
} from "@services/module-fields.service";
import LoaderMain from "@components/common/Loader/loading1";
import ModulePipelineSubheader from "@components/Pages/Pipeline/common/subheader";
import TicketsRightSection from "./components/RightSection";
import {
  filterInitialState,
  filterModeState,
  getUrlParams,
  getUrlParamsObj,
} from "./components/helper";
import { getAllKanbanTickets } from "@services/tickets.service";
import TicketsKanbanView from "./components/MiddleSection/kanban";
import SidebarLayout from "@layouts/SidebarLayout/SidebarLayout";
import CustomDataTable from "@components/custom/Datatable";
import EditFieldsPopup from "@components/Pages/Pipeline/EditFieldsPopup";
import { usePermissionContext } from "@context/permissionContext";

const tabs = [
  {
    value: "all_tickets",
    label: "All Tickets",
  },
  {
    value: "my_open_and_pending_tickets",
    label: "My Tickets",
  },
  {
    value: "new_and_my_open_tickets",
    label: "New and My Open Tickets",
  },
  {
    value: "all_unresolved_tickets",
    label: "All Unresolved Tickets",
  },
  {
    value: "my_overdue_tickets",
    label: "My Overdue Tickets",
  },
  {
    value: "urgent_and_high_priority_tickets",
    label: "Urgent and High Priority Tickets",
  },
  {
    value: "open_tickets_in_my_groups",
    label: "Open Tickets in My Groups",
  },

  {
    value: "tickets_i_raised",
    label: "Tickets I Raised",
  },
  {
    value: "tickets_im_watching",
    label: "Tickets I'm Watching",
  },
  {
    value: "spam",
    label: "Spam",
  },
  {
    value: "trash",
    label: "Trash",
  },
];

interface ITicketsProps {}

const Tickets: React.FunctionComponent<ITicketsProps> = (props: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const {
    userId,
    selectedModuleId,
    allModules,
    bookmarkedModules,
    themeColor,
    refreshBookmarks,
  } = useStateContext();

  /* -------------------------------------------------------------------------- */
  /*                               Validation Section                           */
  /* -------------------------------------------------------------------------- */
  const simpleValidator1 = useRef(new SimpleReactValidator());
  const [, forceUpdate1] = useState(0);

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getAllModules();
      setSelectedStaus("all_tickets");
    }
  }, []);

  useEffect(() => {
    if (getValue(UrlParams, `kanban`, "")) {
      setShowSidebar(false);
    }
  }, [UrlParams]);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      if (UrlParams.filter_status) {
        setSelectedStaus(UrlParams.filter_status);
      }
      setRequest(getUrlParams());
      getAllModules();
    }
  }, [window.location.href]);
  /* -------------------------------------------------------------------------- */
  /*                                  API Section                               */
  /* -------------------------------------------------------------------------- */
  const [selectedIds, setSelectedIds] = useState([]);
  const [moduleId, setModuleId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownFilterOptions, setDropdownFilterOptions] = useState([]);
  const getAllModules = async () => {
    try {
      let resp = await listAllModules("");
      if (resp) {
        let task_modules = getValue(resp, `data`, []).find(
          (item: object) => getValue(item, `api_name`, "") === "tickets"
        );
        setModuleId(getValue(task_modules, `id`, ""));

        let columnsList = await getModuleColumns(
          getValue(task_modules, `id`, "")
        );
        setHeaders(getValue(columnsList, `data`, []));

        let fields = await getAllModuleFields(getValue(task_modules, `id`, ""));
        if (fields) {
          let list = sortTaskFieldList(getValue(fields, `data`, []));
          for (const item of list) {
            if (getValue(item, `input_type`, "") !== "select_multiple") {
              if (
                typeof getValue(item, `default_value`, null) === "string" ||
                typeof getValue(item, `default_value`, null) === "boolean"
              ) {
                item.value = getValue(item, `default_value`, null);
              }
            } else {
              item.value = [getValue(item, `default_value`, null)];
            }
            if (item.api_name === "owner_id") {
              item.value = userId;
            }
          }
          const aDict = list.reduce((dict: any, item: any) => {
            dict[item.name] = item;
            return dict;
          }, {});
          const ReorderedList: any = [];
          // Add elements from b in the order they appear in a
          for (const name of getValue(fields, `data`, [])) {
            if (aDict[name]) {
              ReorderedList.push(aDict[name]);
            }
          }
          // Add elements from a that are not in b to the end
          for (const item of list) {
            if (!getValue(fields, `data`, []).includes(item.name)) {
              ReorderedList.push(item);
            }
          }
          setSelectedFields(removeDuplicates(ReorderedList));
          setOrginalSelectedFields(removeDuplicates(ReorderedList));
          setDropdownFilterOptions(
            getDropdownFields(removeDuplicates(ReorderedList))
          );
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
        getTickets();
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getDropdownFields = (fields: any) => {
    const result: any = {};
    const dropdownFields = [
      "category",
      "source",
      "resolution",
      "internal_type",
      "segment",
      "status",
    ];

    fields.forEach((field: any) => {
      // Check if this field has dropdown options and is in our target list
      if (
        field.module_field?.dropdown_options?.length > 0 &&
        dropdownFields.includes(field.module_field.api_name)
      ) {
        // Map the options to a simpler format
        const options = field.module_field.dropdown_options.map(
          (option: any) => ({
            label: option.label,
            value: option.value,
            seq_num: option.seq_num,
          })
        );

        // Sort by sequence number
        options.sort((a: any, b: any) => a.seq_num - b.seq_num);

        // Add to result object using api_name as key
        result[field.module_field.api_name] = options;
      }
    });
    return result;
  };
  const [selectedStatus, setSelectedStaus] = useState("");
  const [listLoading, setListLoading] = useState(true);
  const [list, setList] = useState([]);
  const getTickets = async () => {
    try {
      let payload = {
        filter: getValue(UrlParams, `filter_status`, ""),
        module_id: await selectedModuleId(),
      };
      let queryRequest = QueryRequestHelper({
        ...payload,
        ...getUrlParamsObj(),
      });
      setListLoading(true);
      let resp;
      if (getValue(UrlParams, `kanban`, "")) {
        resp = await getAllKanbanTickets(removeBracketsFromQuery(queryRequest));
      } else {
        resp = await getAllTickets(removeBracketsFromQuery(queryRequest));
      }
      if (resp) {
        setList(
          getValue(UrlParams, `kanban`, "")
            ? getValue(resp, `data`, [])
            : getValue(resp, `data.tickets`, [])
        );
        setTotalCount(
          getValue(UrlParams, `kanban`, "")
            ? extractFirstPaginationTotal(getValue(resp, `data`, []))
            : getValue(resp, `data.pagination.total`, 0)
        );
        setListLoading(false);
      } else {
        setList([]);
        setListLoading(false);
      }
    } catch (error) {
      setList([]);
      setListLoading(false);
    }
  };

  const extractFirstPaginationTotal = (data: any) => {
    if (!Array.isArray(data) || data.length === 0) return 0;

    // Extract the first object from the array
    const firstItem = data[0];

    // Get the first key dynamically
    const firstKey = Object.keys(firstItem)[0];

    // Extract and return pagination.total
    return firstItem[firstKey]?.pagination?.total ?? 0;
  };

  /* ----------------------------- Delete View Section  ------------------------- */
  const [deleteId1, setDeleteId1] = useState("");
  const [deleteTableValue, setDeleteTableValue] = useState("");
  const [isOpen1, setIsOpen1] = useState(false);
  const handleModal1 = () => {
    setIsOpen1(!isOpen1);
  };
  const handleDeleteTableRow = async () => {
    try {
      let resp = await deleteTicket(deleteId1);
      if (resp) {
        toast.success("Deleted Successfully");
        //delete
        setSelectedIds([]);
        handleModal1();
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                       Create SubPipline Section                            */
  /* -------------------------------------------------------------------------- */

  const [isOpenSubPipeline, setIsOpenSubPipeline] = useState(false);
  const toggleOpenSubpipeline = () => {
    setIsOpenSubPipeline(!isOpenSubPipeline);
  };
  const [selectedFields, setSelectedFields] = useState([]);
  const [orginalSelectedFields, setOrginalSelectedFields] = useState([]);

  /* -------------------------------------------------------------------------- */
  /*                  Hide Body Scroll When Modal Open                          */
  /* -------------------------------------------------------------------------- */
  /*                  Hide Body Scroll When Modal Open                          */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (isOpenSubPipeline) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpenSubPipeline]);

  const handleNaviagteView = (name: string, value: string) => {
    navigate(handleNavigateURL(name, value));
  };
  /* -------------------------------------------------------------------------- */
  /*                       Advanced Filterview Sectio                           */
  /* -------------------------------------------------------------------------- */
  const [isOpenFilterView, setIsOpenFilterView] = useState(false);
  const handleOpenFilterView = () => {
    setIsOpenFilterView(!isOpenFilterView);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    let payload = {
      ...UrlParams,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleChangeLimit = (limit: string) => {
    let payload: any = {
      ...UrlParams,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Module Info                                */
  /* -------------------------------------------------------------------------- */
  const findModuleType = (id: string) => {
    let info =
      getValue(allModules, `length`, 0) > 0
        ? allModules.find((item: object) => getValue(item, `id`, "") == id)
        : [];
    return info;
  };
  const moduleInfo = useMemo(
    () => findModuleType(moduleId),
    [allModules, moduleId]
  );
  useDynamicTitle(
    getValue(moduleInfo, `linkText`, "")
      ? getValue(moduleInfo, `linkText`, "")
      : "tickets"
  );

  /* -------------------------------------------------------------------------- */
  /*                                Sheet view                                  */
  /* -------------------------------------------------------------------------- */

  const [showSidebar, setShowSidebar] = useState(true);
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    if (UrlParams.search) {
      delete UrlParams.search;
      let payload: any = { ...UrlParams };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      // getData(getValue(staticFieldRequest, `pipeline_id`, ""));
    }
  };
  const handleChangeSearch = async (e: any) => {
    const regex = /^[a-zA-Z0-9@.]*$/;
    if (regex.test(e.target.value)) {
      setSearchInput(e.target.value);
      let payload: any = {
        ...UrlParams,
        search: e.target.value,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Filter section                               */
  /* -------------------------------------------------------------------------- */

  const [request, setRequest] = useState(filterInitialState);
  const [filterMode, setFilterMode] = useState<any>(filterModeState);

  const resetRequest = () => {
    setRequest(filterInitialState);
    const queryRequest = QueryRequestHelper(filterInitialState);
    navigate(`${window.location.pathname}?${queryRequest}`);
    getAllModules();
    setIsFilterOpen(false);
  };

  const handleApplyFilter = () => {
    const queryRequest = QueryRequestHelper(request);
    navigate(`${window.location.pathname}?${queryRequest}`);
    setIsFilterOpen(false);
  };
  /* -------------------------------------------------------------------------- */
  /*                               Association section                          */
  /* -------------------------------------------------------------------------- */
  const [association, setAssociation] = useState(true);
  const [associations, setAssociations] = useState([
    {
      name: "Contact",
      module: "contacts",
      api_name: "contacts",
      record_id: "",
    },
    {
      name: "Companies",
      module: "companies",
      api_name: "companies",
      record_id: "",
    },
  ]);
  const resetAssociations = () => {
    setAssociations([
      {
        name: "Contact",
        module: "contacts",
        api_name: "contacts",
        record_id: "",
      },
      {
        name: "Companies",
        module: "companies",
        api_name: "companies",
        record_id: "",
      },
    ]);
  };
  const handlekanbanView = (value: string) => {
    setListLoading(true);
    setList([]);
    if (value === "kanban") {
      delete UrlParams.list;
      let payload = {
        ...UrlParams,
        kanban: true,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      setShowSidebar(false);
    } else {
      delete UrlParams.kanban;
      let payload = {
        ...UrlParams,
        list: true,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      setShowSidebar(true);
    }
  };

  //Fiter section
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                       Create Filterview Section                            */
  /* -------------------------------------------------------------------------- */
  const [editFieldsOpen, setEditFieldsOpen] = useState(false);
  const handleEditFieldsModal = () => {
    setEditFieldsOpen(!editFieldsOpen);
  };
  const [headers, setHeaders] = useState([]);
  const handleUpdateStatus = async (id: string, status: boolean) => {
    try {
      let resp = await updateTicket(id, { is_completed: status });
      if (resp) {
        toast.success("Updated successfully");
        getAllModules();
      }
    } catch (error) {}
  };
  const [selectedOption, setSelectedOption] = useState({});

  return (
    <SidebarLayout {...props}>
      <TicketSubHeader
        subHeaderListTitle={getValue(moduleInfo, "linkText", "")}
        isRightArrowVisible={true}
        isSubmenuListAvailable={true}
        hideClose={!getValue(UrlParams, `pipeline`, "") ? true : false}
        moduleInfo={moduleInfo}
        sidebar={showSidebar}
        toggleSidebar={toggleSidebar}
        params={params}
        hideView
        //bookmarks
        bookmarkedModules={bookmarkedModules}
        themeColor={themeColor}
        refreshBookmarks={refreshBookmarks}
        bookmarkedModuleName="tickets"
        tabs={tabs}
        UrlParams={UrlParams}
        isSelected={selectedStatus}
        handleNaviagteView={handleNaviagteView}
        permissions={getValue(props, `permissions`, [])}
      />
      <div className={`d-flex`}>
        <div className={`ticketsWrapper `}>
          <div
            className={`d-flex ${
              showSidebar ? "" : "tickets-sidebar-collapsed"
            }`}
          >
            {showSidebar && (
              <TicketsLeftSideBar
                userId={userId}
                permissions={getValue(props, `permissions`, [])}
                handleNaviagteView={handleNaviagteView}
                totalCount={totalCount}
                selectedStatus={selectedStatus}
                tabs={tabs}
              />
            )}
            <div className={"tickets-card-middle-wrapper"}>
              <ModulePipelineSubheader
                hideStage
                hideEditColumns={
                  getValue(UrlParams, `kanban`, "") ? true : false
                }
                // showAdvancedFilter
                handleEditFieldsModal={handleEditFieldsModal}
                hideNavView
                hideCloseDate={true}
                module={"tickets"}
                plural_label={"tickets"}
                permissions={getValue(props, `permissions`, [])}
                moduleInfo={moduleInfo}
                setIsOpenSubPipeline={setIsOpenSubPipeline}
                //search section
                toggleSearch={toggleSearch}
                toggleSearchClose={toggleSearchClose}
                handleChangeSearch={handleChangeSearch}
                searchInput={search}
                showSearchTextbox={showSearchTextbox}
                hideCreateDate={true}
                isCreateDealVisible
                kanban
                handlekanbanView={handlekanbanView}
                setIsFilterOpen={setIsFilterOpen}
                hideUpload
              />
              {listLoading ? (
                <LoaderMain />
              ) : getValue(UrlParams, `kanban`, "") ? (
                <TicketsKanbanView list={list} module="tickets" />
              ) : (
                // <CustomCardView
                //   params={params}
                //   data={list}
                //   module="tickets"
                //   selectedIds={selectedIds}
                //   setSelectedIds={setSelectedIds}
                // />
                <CustomDataTable
                  headers={headers}
                  data={list}
                  setHeaders={setHeaders}
                  SubPiplineTitle={concatString(
                    getValue(moduleInfo, `label_singular`, ""),
                    20
                  )}
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                  stopRedirect
                  isLoading={listLoading}
                  module={"tickets"}
                  handleUpdateStatus={handleUpdateStatus}
                  add
                  handleEditFieldsModal={handleEditFieldsModal}
                />
              )}
              {totalCount > 10 && (
                <Pagination
                  totalCount={totalCount}
                  limit={limit}
                  page_no={page_no}
                  handleChangePagination={handleChangePagination}
                  handleChangeLimit={handleChangeLimit}
                  list={list}
                />
              )}
            </div>
            <TicketsRightSection
              dropdownFilterOptions={dropdownFilterOptions}
              request={request}
              setRequest={setRequest}
              handleApplyFilter={handleApplyFilter}
              resetRequest={resetRequest}
              filterMode={filterMode}
              setFilterMode={setFilterMode}
              isFilterOpen={isFilterOpen}
              setIsFilterOpen={setIsFilterOpen}
              permissions={getValue(props, `permissions`, [])}
            />
          </div>
        </div>
      </div>
      {/* ------------------------- Delete Table Section ------------------------------- */}
      <DeleteModal
        isOpen={isOpen1}
        handleModal={handleModal1}
        handleSubmit={handleDeleteTableRow}
        deleteValue={deleteTableValue}
      />
      {/* ------------------------- Creating Sub Pipelines ------------------------------ */}
      <CommonAddSubPipeline
        isOpen={isOpenSubPipeline}
        toggle={toggleOpenSubpipeline}
        // getAllPipelines={getAllPipelines}
        fields={selectedFields}
        setFields={setSelectedFields}
        simpleValidator={simpleValidator1}
        forceUpdate={forceUpdate1}
        module={"tickets"}
        permissions={getValue(props, `permissions`, [])}
        associations={associations}
        setAssociations={setAssociations}
        association={association}
        setAssociation={setAssociation}
        orginalSelectedFields={orginalSelectedFields}
        resetAssociations={resetAssociations}
      />
      {/* ------------------------- Advanced Filter View ------------------------- */}
      {isOpenFilterView && (
        <CommonAddFilterView
          isOpenFilterView={isOpenFilterView}
          module={"tickets"}
          handleOpenFilterView={handleOpenFilterView}
          // getData={getAllPipelines}
          permissions={["create"]}
        />
      )}
      {/* ------------------------- Editing Table Fields ------------------------- */}
      <EditFieldsPopup
        isOpen={editFieldsOpen}
        toggle={handleEditFieldsModal}
        fields={selectedFields}
        setFields={setSelectedFields}
        id={getValue(selectedOption, `id`, "")}
        headers={headers}
        setHeaders={setHeaders}
        module={"tickets"}
        // getData={getData}
        permissions={getValue(props, `permissions`, [])}
      />
    </SidebarLayout>
  );
};

export default Tickets;
