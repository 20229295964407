import {
  capitalizeFirstLetter,
  formatString,
  removeEmptyValuesInName,
  sortJSONObjectArray,
} from "@common/text-helpers";
import { getListOfAllCannedResponse } from "@services/canned-response.service";
import { getListOfAllCompanies } from "@services/companies.service";
import { getListOfAllContacts } from "@services/contacts.service";
import { getListAllTemplates } from "@services/email-config.service";
import { getWhatsappTemplate } from "@services/fb.service";
import { getGroupsList } from "@services/groups.service";
import { getListOfAllLeads } from "@services/leads.service";
import { getListOfLifeCycleStages } from "@services/life-cycle-stages.service";
import { getPipelineStages } from "@services/pipeline.service";
import { getPrioritiesList } from "@services/priorities.service";
import { getAllProductsList } from "@services/products.service";
import { getAllSaleItems } from "@services/sale-items.service";
import { getAllTags, getAllTagsList } from "@services/tags.service";
import { getTicketStatusList } from "@services/ticket-status.service";
import { getAllTickets } from "@services/tickets.service";
import { getUsers, getUsersEmail } from "@services/users.service";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "common/query-request-helper";

export const usersSearchableDropdown = async (queryRequest: string) => {
  try {
    let resp = await getUsers(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.length`, 0) > 0
          ? getValue(resp, `data`, []).map((item: object) => ({
              id: getValue(item, `id`, ""),
              name: removeEmptyValuesInName(
                getValue(item, `first_name`, ""),
                getValue(item, `last_name`, "")
              ),
              value: removeEmptyValuesInName(
                getValue(item, `first_name`, ""),
                getValue(item, `last_name`, "")
              ),
              label: removeEmptyValuesInName(
                getValue(item, `first_name`, ""),
                getValue(item, `last_name`, "")
              ),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const usersEmailSearchableDropdown = async (queryRequest: string) => {
  try {
    let resp = await getUsersEmail(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.org_users.length`, 0) > 0
          ? getValue(resp, `data.org_users`, []).map((item: object) => ({
              id: getValue(item, `email`, ""),
              main_id: getValue(item, `id`, ""),
              name: removeEmptyValuesInName(
                getValue(item, `first_name`, ""),
                getValue(item, `last_name`, "")
              ),
              value: removeEmptyValuesInName(
                getValue(item, `first_name`, ""),
                getValue(item, `last_name`, "")
              ),
              label:
                removeEmptyValuesInName(
                  getValue(item, `first_name`, ""),
                  getValue(item, `last_name`, "")
                ) + ` ( ${getValue(item, `email`, "")} )`,
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const contactsSearchableDropdown = async (search_text: string) => {
  let paylod = {
    search_text: search_text,
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getListOfAllContacts(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.length`, 0) > 0
          ? getValue(resp, `data`, []).map((item: object) => ({
              id: getValue(item, `id`, ""),
              name: removeEmptyValuesInName(
                getValue(item, `first_name`, ""),
                getValue(item, `last_name`, "")
              ),
              value: removeEmptyValuesInName(
                getValue(item, `first_name`, ""),
                getValue(item, `last_name`, "")
              ),
              label: removeEmptyValuesInName(
                getValue(item, `first_name`, ""),
                getValue(item, `last_name`, "")
              ),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const leadsSearchableDropdown = async (search_text: string) => {
  let paylod = {
    search_text: search_text,
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getListOfAllLeads(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.length`, 0) > 0
          ? getValue(resp, `data`, []).map((item: object) => ({
              id: getValue(item, `id`, ""),
              name: removeEmptyValuesInName(
                getValue(item, `first_name`, ""),
                getValue(item, `last_name`, "")
              ),
              value: removeEmptyValuesInName(
                getValue(item, `first_name`, ""),
                getValue(item, `last_name`, "")
              ),
              label: removeEmptyValuesInName(
                getValue(item, `first_name`, ""),
                getValue(item, `last_name`, "")
              ),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const companiesSearchableDropdown = async (search_text: string) => {
  let paylod = {
    search_text: search_text,
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getListOfAllCompanies(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.length`, 0) > 0
          ? getValue(resp, `data`, []).map((item: object) => ({
              id: getValue(item, `id`, ""),
              name: getValue(item, `name`, ""),
              value: getValue(item, `name`, ""),
              label: getValue(item, `name`, ""),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const cannedResponseSearchableDropdown = async (search_text: string) => {
  let paylod = {
    search_text: search_text,
  };
  let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getListOfAllCannedResponse(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.canned_responses.length`, 0) > 0
          ? getValue(resp, `data.canned_responses`, []).map((item: object) => ({
              id: getValue(item, `id`, ""),
              name: getValue(item, `title`, ""),
              value: getValue(item, `id`, ""),
              label: getValue(item, `title`, ""),
            }))
          : [];
      return data;
    }
  } catch (error) {}
};

export const emailTemplatesSearchableDropdown = async (
  queryRequest: string
) => {
  // let paylod = {
  //   search_text: search_text,
  // };
  // let queryRequest = QueryRequestHelper(paylod);
  try {
    let resp = await getListAllTemplates(queryRequest);
    const combinedTemplates = getValue(resp, `data`, []).flatMap(
      ({ forModule, templates }: any) => {
        return templates.map((template: any) => ({
          forModule,
          ...template,
          value: getValue(template, `id`, ""),
          label: `${getValue(template, `name`, "")}`,
        }));
      }
    );
    if (resp) {
      // let data =
      //   getValue(resp, `data.length`, 0) > 0
      //     ? getValue(resp, `data`, []).map((item: object) => ({
      //         id: getValue(item, `id`, ""),
      //         name: getValue(item, `name`, ""),
      //         value: getValue(item, `name`, ""),
      //         label: getValue(item, `name`, ""),
      //       }))
      //     : [];
      return combinedTemplates;
    }
  } catch (error) {}
};

export const stageSearchableDropdown = async (
  value: string,
  search_text: string
) => {
  let paylod = {
    search_text: search_text,
  };
  let queryRequest = QueryRequestHelper(paylod);
  let req = value.split("_");
  try {
    let resp = await getPipelineStages(req[1], req[2]);
    if (resp) {
      let data =
        getValue(resp, `data.length`, 0) > 0
          ? sortJSONObjectArray(getValue(resp, `data`, []), "seq_num").map(
              (item: object) => ({
                id: getValue(item, `id`, ""),
                name: getValue(item, `api_name`, ""),
                value: getValue(item, `id`, ""),
                label: getValue(item, `label`, ""),
              })
            )
          : [];
      return data;
    }
  } catch (error) {}
};

export const tagsSearchableDropdown = async (queryRequest: string) => {
  // let paylod = {
  //   search_text: search_text,
  // };
  // let queryRequest = QueryRequestHelper(paylod);
  let option = queryRequest.split("&");
  let id = getValue(option, `[${0}]`, "")
    ? getValue(option, `[${0}]`, "").split("module_id=")[1]
    : "";
  // try {
  //   let resp = await getAllTagsList(id, "");
  //   if (resp) {
  //     let data =
  //       getValue(resp, `data.length`, 0) > 0
  //         ? getValue(resp, `data`, []).map((item: object) => ({
  //             id: getValue(item, `value`, ""),
  //             name: getValue(item, `value`, ""),
  //             value: getValue(item, `id`, ""),
  //             label: getValue(item, `label`, ""),
  //           }))
  //         : [];
  //     return data;
  //   }
  // } catch (error) {}
};

export const prioritiesSearchableDropdown = async (queryRequest: string) => {
  try {
    let resp = await getPrioritiesList(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.length`, 0) > 0
          ? getValue(resp, `data`, []).map((item: object) => ({
              id: getValue(item, `id`, ""),
              // name: getValue(item, `value`, ""),
              value: getValue(item, `id`, ""),
              label: formatString(getValue(item, `name`, "")),
            }))
          : [];

      return data;
    }
  } catch (error) {}
};

export const groupsSearchableDropdown = async (queryRequest: string) => {
  try {
    let resp = await getGroupsList(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.length`, 0) > 0
          ? getValue(resp, `data`, []).map((item: object) => ({
              id: getValue(item, `id`, ""),
              // name: getValue(item, `value`, ""),
              value: getValue(item, `id`, ""),
              label: formatString(getValue(item, `name`, "")),
            }))
          : [];

      return data;
    }
  } catch (error) {}
};

export const productsSearchableDropdown = async (queryRequest: string) => {
  try {
    let resp = await getAllProductsList(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.length`, 0) > 0
          ? getValue(resp, `data`, []).map((item: object) => ({
              id: getValue(item, `id`, ""),
              // name: getValue(item, `value`, ""),
              value: getValue(item, `id`, ""),
              label: formatString(getValue(item, `name`, "")),
            }))
          : [];

      return data;
    }
  } catch (error) {}
};

export const ticketsSearchableDropdown = async (queryRequest: string) => {
  try {
    let resp = await getAllTickets(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.tickets.length`, 0) > 0
          ? getValue(resp, `data.tickets`, []).map((item: object) => ({
              ...item,
              id: getValue(item, `id`, ""),
              value: getValue(item, `id`, ""),
              label: formatString(getValue(item, `ticket_name`, "")),
            }))
          : [];

      return data;
    }
  } catch (error) {}
};

export const ticketsStatusSearchableDropdown = async (queryRequest: string) => {
  try {
    let resp = await getTicketStatusList(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.ticket_statuses.length`, 0) > 0
          ? getValue(resp, `data.ticket_statuses`, []).map((item: object) => ({
              ...item,
              id: getValue(item, `id`, ""),
              value: getValue(item, `id`, ""),
              label: formatString(getValue(item, `label`, "")),
            }))
          : [];

      return data;
    }
  } catch (error) {}
};

export const saleItemsSearchableDropdown = async (queryRequest: string) => {
  try {
    let resp = await getAllSaleItems(queryRequest);
    if (resp) {
      let data =
        getValue(resp, `data.sale_items.length`, 0) > 0
          ? getValue(resp, `data.sale_items`, []).map((item: object) => ({
              ...item,
              id: getValue(item, `id`, ""),
              value: getValue(item, `id`, ""),
              label: formatString(getValue(item, `name`, "")),
            }))
          : [];

      return data;
    }
  } catch (error) {}
};

export const whatsappTemplatesSearchableDropdown = async (
  queryRequest1: string,
) => {
  try {
    let payload = {
      status: "APPROVED",
    };
    let queryRequest = QueryRequestHelper(payload);
    let resp = await getWhatsappTemplate(`${queryRequest1}&${queryRequest}`);
    const combinedTemplates = getValue(resp, `data.templates`, []).map(
      (template: any) => {
        return {
          ...template,
          value: getValue(template, `id`, ""),
          label: `${getValue(template, `name`, "")}`,
        };
      }
    );
    if (resp) {
      return combinedTemplates;
    }
  } catch (error) {}
};

export const lifeCycleStageSearchableDropdown = async () => {
  let payload = {
    page_no: 1,
    page_size: 10,
    module: "contacts",
  };
  let queryRequest = QueryRequestHelper(payload);
  try {
    let resp = await getListOfLifeCycleStages(queryRequest);

    if (resp) {
      let data =
        getValue(resp, `data.lifecycle_stages.length`, 0) > 0
          ? getValue(resp, `data.lifecycle_stages`, []).map((item: object) => ({
              ...item,
              id: getValue(item, `id`, ""),
              value: getValue(item, `id`, ""),
              label: formatString(getValue(item, `label`, "")),
            }))
          : [];

      return data;
    }
  } catch (error) {}
};

// export const productsSearchableDropdown = async (search_text: string) => {
//   let paylod = {
//     search_text: search_text,
//   };
//   let queryRequest = QueryRequestHelper(paylod);
//   try {
//     let resp = await getListOfAllContacts(queryRequest);
//     if (resp) {
//       let data =
//         getValue(resp, `data.length`, 0) > 0
//           ? getValue(resp, `data`, []).map((item: object) => ({
//               id: getValue(item, `id`, ""),
//               name: removeEmptyValuesInName(
//                 getValue(item, `first_name`, ""),
//                 getValue(item, `last_name`, "")
//               ),
//               value: removeEmptyValuesInName(
//                 getValue(item, `first_name`, ""),
//                 getValue(item, `last_name`, "")
//               ),
//               label: removeEmptyValuesInName(
//                 getValue(item, `first_name`, ""),
//                 getValue(item, `last_name`, "")
//               ),
//             }))
//           : [];
//       return data;
//     }
//   } catch (error) {}
// };
