import AppBrandingSvgComponent from "@assets/svg/app-branding";
import AtSvgComponents from "@assets/svg/at";
import WhatsappSvgComponent from "@assets/svg/whastapp";
import AntSubmitContainer from "@components/common/ButtonContainer/NewSubmitContainer";
import NewSearchSelect from "@components/custom/Dropdown/AntDropDown";
import MultiSearchLookupTooltip from "@components/custom/Dropdown/MultiSearchLookupTooltip";
import MultiSearchTooltip from "@components/custom/Dropdown/MultiSearchTooltip";
import { getValue } from "@utils/lodash";
import { DatePicker, Drawer, Select } from "antd";
import {
  chatAssignementStatus,
  chatConversationStatus,
  chatManagedBy,
  chatSource,
} from "../helpers/live-chat-helpers";
import dayjs from "dayjs";
import {
  AtSign,
  Bot,
  Globe,
  MessageSquareDot,
  MessageSquarePlus,
  MessageSquareReply,
  MessagesSquare,
  RadioTower,
  SendHorizonal,
  Users,
} from "lucide-react";

const WaAdvancefilter = (props: any) => {
  const {
    allConnectionList,
    isOpenFilterView,
    toggleFilterView,
    filterRequest,
    setFilterRequest,
    filterDirtyRequest,
    handleResetFilter,
    handleApplyFilter,
    UrlParams,
    chatbotList,
    AiAgents,
    webChatbotList,
  } = props;

  const handleCancel = () => {
    setFilterRequest(filterDirtyRequest);
    toggleFilterView();
  };

  const handleMultiSearchLookupTooltipChange = (
    e: any,
    key: string,
    valueKey: string
  ) => {
    if (!getValue(filterRequest, key, []).includes(e[valueKey])) {
      getValue(filterRequest, key, []).push(e[valueKey]);
      setFilterRequest((prevState: any) => ({
        ...prevState,
      }));
    } else {
      let fitlered = getValue(filterRequest, key, []).filter(
        (item: object) => item !== e[valueKey]
      );
      setFilterRequest((prevState: any) => ({
        ...prevState,
        [key]: fitlered,
      }));
    }
  };

  const handleAntMultiSearchChange = (
    e: any,
    key: string,
    valueKey: string
  ) => {
    const values = e.map((item: any) => item[valueKey]);
    setFilterRequest((prevState: any) => ({
      ...prevState,
      [key]: values,
    }));
  };

  const { Option, OptGroup } = Select;

  return (
    <Drawer
      open={isOpenFilterView}
      onClose={toggleFilterView}
      width={"550px"}
      title={"Filter"}
      className="ant-drawer-container-footer"
      footer={
        <AntSubmitContainer
          handleBackButton={() => {
            handleCancel();
          }}
          handleSubmit={() => handleApplyFilter()}
          // submitLoading={submitLoading}
          first_title={"Cancel"}
          second_title={"Apply"}
          third_title="Reset filter"
          disableValidation
          handleThirdButton={() => handleResetFilter()}
          permissions={["create"]}
          // disabled={hasErrors ? true : false}
        />
      }
    >
      <div className="subpipeline-wrapper p-2">
        {/* <div className="d-flex align-items-center gap-2 mb-2 ">
            <AtSign size={18} color="#1973e8" />
            <h6 className="header_text__16">Assigned to</h6>
          </div> */}

        {/* <AntSearchToolTip
            mode="multiple"
            placeholder="Search User"
            lookup_api={"org_users"}
            value={getValue(filterRequest, `forOrgUser`, "")}
            onChange={(e: any) =>
              setFilterRequest({
                ...filterRequest,
                forOrgUser: e,
              })
            }
            label={`label`}
            hideLabel
            selectKey={"id"}
          /> */}

        <div className="mb-4">
          <div className="d-flex align-items-center gap-2 mb-2">
            <SendHorizonal size={18} color="#1973e8" />
            <h6 className="header_text__16">Chat Managed By</h6>
          </div>
          <NewSearchSelect
            placeholder="Select Channel"
            options={chatManagedBy}
            value={getValue(filterRequest, `managed_by`, "") || "all"}
            onChange={(e: any) =>
              setFilterRequest({
                ...filterRequest,
                managed_by: e,
                chatbot_ids: [],
                forOrgUser: [],
              })
            }
            label={`Chat Managed By`}
            hideLabel
          />

          {getValue(filterRequest, `managed_by`, "") === "team" ? (
            <div className="mt-3">
              <div className="d-flex align-items-center gap-2 mb-2">
                <Users size={18} color="#1973e8" />
                <h6 className="header_text__16">Team</h6>
              </div>
              <MultiSearchLookupTooltip
                lookup_api={"org_user"}
                label={`label`}
                value={getValue(filterRequest, `forOrgUser`, [])}
                selectKey={"id"}
                onChange={(e: any) => {
                  handleMultiSearchLookupTooltipChange(e, "forOrgUser", "id");
                }}
                name="Add watcher"
                hideLabel
              />
            </div>
          ) : getValue(filterRequest, `managed_by`, "") === "chatbot" ||
            getValue(filterRequest, `managed_by`, "") === "ai-chatbot" ? (
            // <Select
            //   size="large"
            //   placeholder={`Select ${
            //     getValue(filterRequest, `managed_by`, "") === "chatbot"
            //       ? "Chatbot"
            //       : "AI Agent"
            //   }`}
            //   value={getValue(filterRequest, `chatbot_ids`, [])}
            //   onChange={(value: any, option: any) => {
            //     handleAntMultiSearchChange(option, "chatbot_ids", "value");
            //   }}
            //   className="w-100 mt-3"
            //   mode="multiple"
            // >
            //   {[
            //     {
            //       title:
            //         getValue(filterRequest, `managed_by`, "") === "chatbot"
            //           ? "Chatbot"
            //           : "AI Agent",
            //       key:
            //         getValue(filterRequest, `managed_by`, "") === "chatbot"
            //           ? "bot"
            //           : "ai",
            //       data:
            //         getValue(filterRequest, `managed_by`, "") === "chatbot"
            //           ? chatbotList
            //           : AiAgents,
            //     },
            //   ].map((section) => (
            //     <OptGroup key={section.key} label={section.title}>
            //       {section.data.map((item: any) => (
            //         <Option
            //           key={`${section.key}-${item.id}`}
            //           value={item.id || item.value}
            //           data-section-key={section.key}
            //         >
            //           {item.label}
            //         </Option>
            //       ))}
            //     </OptGroup>
            //   ))}
            // </Select>
            <div className="mt-3">
              <div className="d-flex align-items-center gap-2">
                {getValue(filterRequest, `managed_by`, "") === "chatbot" ? (
                  <Bot size={18} color="#1973e8" />
                ) : (
                  <div className="heart-beat-anim ">
                    <img
                      src="/images/icons/ai-icon.svg"
                      alt="ai-stars"
                      width={24}
                      height={24}
                      style={{
                        top: "4px",
                        left: "6px",
                      }}
                      className=""
                    />
                  </div>
                )}
                <h6 className="header_text__16">
                  {getValue(filterRequest, `managed_by`, "") === "chatbot"
                    ? " Chatbot"
                    : " AI Chatbot"}
                </h6>
              </div>
              <div className="mt-2">
                <MultiSearchTooltip
                  placeholder={
                    getValue(filterRequest, `managed_by`, "") === "chatbot"
                      ? "Select Chatbot"
                      : "Select AI Chatbot"
                  }
                  label="label"
                  data={
                    getValue(filterRequest, `managed_by`, "") === "chatbot"
                      ? chatbotList
                      : AiAgents
                  }
                  selectKey={"value"}
                  onChange={(e: any) => {
                    handleMultiSearchLookupTooltipChange(
                      e,
                      "chatbot_ids",
                      "id"
                    );
                  }}
                  value={getValue(filterRequest, `chatbot_ids`, [])}
                />
              </div>
            </div>
          ) : null}
        </div>
        <hr className="py-1" />
        <div className="mb-4">
          <div className="d-flex align-items-center gap-2 mb-2">
            <MessageSquarePlus size={18} color="#1973e8" />
            <h6 className="header_text__16">Chat Source</h6>
          </div>
          <NewSearchSelect
            placeholder="Select Chat Type"
            options={chatSource}
            value={getValue(filterRequest, `source`, "") || "all"}
            onChange={(e: any) =>
              setFilterRequest({
                ...filterRequest,
                source: e,
                wa_account_ids: [],
                web_chatbot_ids: [],
              })
            }
            label={`Chat Source`}
            hideLabel
          />
        </div>
        {(getValue(filterRequest, `source`, "") === "whatsapp" ||
          getValue(filterRequest, `source`, "") === "webchat") && (
          <div className="mb-4">
            <div className="d-flex align-items-center gap-2 mb-2">
              <RadioTower size={18} color="#1973e8" />
              <h6 className="header_text__16">
                {getValue(filterRequest, `source`, "") === "whatsapp"
                  ? "Connected Channels"
                  : "Connected Web Chatbots"}
              </h6>
            </div>
            <MultiSearchTooltip
              placeholder="Choose Channels"
              label="label"
              data={
                getValue(filterRequest, `source`, "") === "whatsapp"
                  ? allConnectionList
                  : webChatbotList
              }
              selectKey={"value"}
              onChange={(e: any) => {
                handleMultiSearchLookupTooltipChange(
                  e,
                  getValue(filterRequest, `source`, "") === "whatsapp"
                    ? "wa_account_ids"
                    : "web_chatbot_ids",
                  "id"
                );
              }}
              value={
                getValue(filterRequest, `source`, "") === "whatsapp"
                  ? getValue(filterRequest, `wa_account_ids`, [])
                  : getValue(filterRequest, `web_chatbot_ids`, [])
              }
              icon={
                getValue(filterRequest, `source`, "") === "whatsapp" ? (
                  <WhatsappSvgComponent color={"#30d14f"} size={18} />
                ) : (
                  <Globe size={18} color="#1973e8" />
                )
              }
            />
          </div>
        )}

        <hr className="py-1" />
        {/* <div className="mb-4">
          <div className="d-flex align-items-center gap-2 mb-2">
            <MessageSquareDot size={18} color="#1973e8" />
            <h6 className="header_text__16">Chat Assignment Status</h6>
          </div>
          <NewSearchSelect
            placeholder="Select Bot"
            options={chatAssignementStatus}
            value={getValue(filterRequest, `chat_assignment_status`, "")}
            onChange={(e: any) =>
              setFilterRequest({
                ...filterRequest,
                chat_assignment_status: e,
              })
            }
            label={`Chat Assignement Status`}
            hideLabel
            selectKey={"id"}
          />
        </div> */}
        <div className="mb-4">
          <div className="d-flex align-items-center gap-2 mb-2">
            <MessagesSquare size={18} color="#1973e8" />
            <h6 className="header_text__16">Conversation Status</h6>
          </div>
          <NewSearchSelect
            placeholder="Select Bot"
            options={chatConversationStatus}
            value={getValue(filterRequest, `conversation_status`, "")}
            onChange={(e: any) =>
              setFilterRequest({
                ...filterRequest,
                conversation_status: e,
              })
            }
            label={`Conversation Status`}
            hideLabel
          />
        </div>
        {/* <div className="mb-4">
          <div className="d-flex align-items-center gap-2 mb-2">
            <AtSvgComponents size="18" />
            <h6>Messaged on</h6>
          </div>
          <DatePicker
            onChange={(date) => {
              if (date) {
                const formattedDate = dayjs(date).format("YYYY-MM-DD");
                console.log("Formatted Date:", formattedDate);

                setFilterRequest((prev: any) => ({
                  ...prev,
                  filter_messaged_at: formattedDate,
                }));
              }
            }}
          />
        </div> */}
      </div>
    </Drawer>
  );
};

export default WaAdvancefilter;
