import { PlusOutlined } from "@ant-design/icons";
import DeleteSvgComponent from "@assets/svg/delete";
import DragIndicatorSvgComponent from "@assets/svg/drag-indicator";
import InfoSvgComponent from "@assets/svg/info";
import {
  handleTextArrayChange,
  handleValueArrayChange,
} from "@common/handleChange";
import { formatString } from "@common/text-helpers";
import PhoneInputComponent from "@components/custom/Forms/PhoneInput";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Button, Dropdown, Space, Tooltip } from "antd";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";

const TemplateFooter = (props: any) => {
  const {
    params,
    request,
    setRequest,
    editId,
    handleRemoveTypeButton,
    menuProps,
    handleDragEnd,
  } = props;
  return (
    <>
      <div className="border-bottom">
        <div className="d-flex align-items-center gap-2 mt-4">
          <h6 className="">
            Footer <span className="optional-text">(Optional)</span>
          </h6>
          <Tooltip title="Add a short line of text to the bottom of your message template. If you add the marketing opt-out button, the associated footer will be shown here by default. ">
            <span>
              <InfoSvgComponent color="#676767" size="16" />
            </span>
          </Tooltip>
        </div>

        <div className="input-with-length mt-0 mb-2">
          <InputRuleForm
            inputType="TEXT"
            id="footer"
            name="footer"
            value={getValue(request, `footer`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                footer: e.target.value,
              })
            }
            hideFormLabel
            // showCount
            maxLength={60}
            placeholder="You can use this space to add a tagline, a way to unsubscribe, etc."
          />
        </div>
        <p className="greyed-description">
          Ex: To enable unsubscribe functionality, go to{" "}
          <Link
            to={`/${getValue(
              params,
              "orgId",
              ""
            )}/settings/chat/messages?active_tab=2&page_no=1`}
            target="__blank"
          >
            Opt-out page
          </Link>{" "}
          and set Opt-out Keyword (ex: STOP)
        </p>
      </div>
      <div className="mb-5 pb-5">
        <h6 className="mt-3">
          Buttons <span className="optional-text">(Optional)</span>
        </h6>
        <p className="my-2 greyed-description">
          Create buttons that let customers respond to your messages or take
          action.
        </p>

        <div className="">
          {/* {buttonTypeEnum.map((item: object) => {
        return (
          <div
            onClick={() =>
              setButtonType(getValue(item, `value`, ""))
            }
            className="d-flex align-items-center mx-2 "
          >
            <input
              type="radio"
              className="input-radio"
              checked={
                selectedType === getValue(item, `value`, "")
                  ? true
                  : false
              }
            />
            <div className=" ms-1 small_text__14">
              <p>{getValue(item, `label`, "")}</p>
            </div>
          </div>
        );
      })} */}
          {/* <Radio.Group
        onChange={(e: any) => setButtonType(e.target.value)}
        value={selectedType}
        className="d-flex my-3"
        size="large"
      >
        {buttonTypeEnum.map((item: object) => (
          <Radio.Button
            key={getValue(item, `value`, "")}
            value={getValue(item, `value`, "")}
            className="flex-grow-1 text-center"
          >
            <div className="small_text__14">
              {getValue(item, `label`, "")}
            </div>
          </Radio.Button>
        ))}
      </Radio.Group> */}
          {/* <Radio.Group
        options={buttonTypeEnum}
        onChange={(e: any) => setButtonType(e.target.value)}
        value={selectedType}
        className="my-3"
      /> */}
        </div>

        {/* {getValue(request, "buttons.length", 0) < 3 && (
      <div
        className="d-flex align-items-center cursor-pointer"
        onClick={() =>
          handleAddTypeButton(
            selectedType,
            selectedType === "URL" ? "url" : "call"
          )
        }
      >
        <AddSvgComponent />
        <p className="add_text ms-2">Add</p>
      </div>
    )} */}
        <Dropdown menu={menuProps}>
          <Button>
            <Space>
              <PlusOutlined />
              Add a button
            </Space>
          </Button>
        </Dropdown>
        {getValue(request, "buttons.length", 0) > 0 && (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable-buttons">
              {(provided) => (
                <div
                  className="mt-4 template_main_button_container position-relative"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {getValue(request, "buttons", []).map(
                    (item: any, index: any) => {
                      const buttonType = getValue(item, `action_type`, "");
                      return (
                        <Draggable
                          key={index}
                          draggableId={`item-${index}`}
                          index={index}
                          isDragDisabled={
                            getValue(request, "buttons.length", 0) === 1
                          }
                        >
                          {(provided) => (
                            <div
                              className="template_main_button_container_item  d-flex align-items-center gap-2"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              {getValue(request, "buttons.length", 0) > 1 && (
                                <div
                                  className="d-flex justify-content-center"
                                  {...provided.dragHandleProps}
                                >
                                  <DragIndicatorSvgComponent
                                    color={"#aeaeae"}
                                  />
                                </div>
                              )}
                              <div className="flex-grow-1">
                                <h6 className="header_text__14">
                                  {getValue(item, `type`, "")
                                    ? `${formatString(
                                        getValue(item, `type`, "")
                                      )}`
                                    : formatString(
                                        getValue(item, `action_type`, "")
                                      )}
                                </h6>
                                <div className="d-flex align-items-center justify-content-between">
                                  {buttonType === "QUICK_REPLY" ? (
                                    <InputRuleForm
                                      inputType="TEXT"
                                      onChange={(e: any) =>
                                        handleTextArrayChange(
                                          index,
                                          e,
                                          "buttons",
                                          setRequest
                                        )
                                      }
                                      name="text"
                                      value={getValue(item, "text", "")}
                                      placeholder="eg. Yes"
                                      maxLength={20}
                                      // showCount
                                    />
                                  ) : (
                                    <div className="flex-wrap d-flex gap-2">
                                      <div className="w-45">
                                        <InputRuleForm
                                          inputType="TEXT"
                                          onChange={(e: any) =>
                                            handleTextArrayChange(
                                              index,
                                              e,
                                              "buttons",
                                              setRequest
                                            )
                                          }
                                          name="text"
                                          value={getValue(item, "text", "")}
                                          placeholder={
                                            buttonType === "URL"
                                              ? "eg. Visit website"
                                              : "eg. Call us"
                                          }
                                          label="Title"
                                          maxLength={20}
                                        />
                                      </div>
                                      <div className="w-45 ">
                                        {getValue(item, "type", "") ===
                                        "call" ? (
                                          <PhoneInputComponent
                                            placeholder="Enter phone number (+91...)"
                                            value={getValue(
                                              item,
                                              getValue(item, "type", "") ===
                                                "url"
                                                ? "url"
                                                : "phone",
                                              ""
                                            )}
                                            onChange={(e: any) =>
                                              handleValueArrayChange(
                                                index,
                                                e,
                                                "phone",
                                                "buttons",
                                                setRequest
                                              )
                                            }
                                            label="Phone Number"
                                          />
                                        ) : (
                                          <InputRuleForm
                                            inputType="TEXT"
                                            onChange={(e: any) =>
                                              handleTextArrayChange(
                                                index,
                                                e,
                                                "buttons",
                                                setRequest
                                              )
                                            }
                                            name={
                                              getValue(item, "type", "") ===
                                              "url"
                                                ? "url"
                                                : "phone"
                                            }
                                            value={getValue(
                                              item,
                                              getValue(item, "type", "") ===
                                                "url"
                                                ? "url"
                                                : "phone",
                                              ""
                                            )}
                                            placeholder={`Enter ${
                                              getValue(item, "type", "") ===
                                              "url"
                                                ? "url"
                                                : "Phone Number"
                                            }`}
                                            label={
                                              getValue(item, "type", "") ===
                                              "url"
                                                ? "URL"
                                                : "Phone"
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  <div
                                    onClick={() =>
                                      handleRemoveTypeButton(index)
                                    }
                                  >
                                    <DeleteSvgComponent size={22} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    }
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </div>
    </>
  );
};

export default TemplateFooter;
