import {
  handleAntCheckboxChange,
  handleAntTextChange,
  handleRadioChange,
  handleTextChange,
} from "@common/handleChange";
import { covertCamelToSnake } from "@common/text-helpers";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Checkbox, ColorPicker, Modal } from "antd";

const TagsModal = ({
  editId,
  isModalOpen,
  handleSubmit,
  handleCancel,
  request,
  setRequest,
  validator,
}: any) => {
  return (
    <Modal
      title={`${editId ? "Edit" : "New"} Tag`}
      okText={`${editId ? "Update" : "Create"} Tag`}
      open={isModalOpen}
      onOk={handleSubmit}
      onCancel={handleCancel}
    >
      <InputRuleForm
        inputType="TEXT"
        name="label"
        value={getValue(request, "label", "")}
        placeholder="Enter Tag Name"
        required
        label="Name (For Own Reference)"
        onChange={(e: any) => {
          // handleTextChange(e, request, setRequest);
          setRequest({
            ...request,
            label: e.target.value,
            value: covertCamelToSnake(e.target.value),
          });
        }}
        validator={validator}
        validLeft
      />
      <div className="d-flex flex-column mt-3">
        <label className="form-label">Color</label>
        <div>
          <ColorPicker
            value={getValue(request, "color_code", "")}
            showText
            onChange={(color: any) => {
              handleAntTextChange(
                "color_code",
                color.toHexString(),
                request,
                setRequest
              );
            }}
          />
        </div>
      </div>
      <div className="mt-3">
        <label className="form-label">Available for</label>
        {Object.entries(request)
          .filter(([key]) => key.startsWith("is_"))
          .map(([key, value]) => (
            <div key={key} style={{ marginBottom: "8px" }}>
              <Checkbox
                checked={value as boolean}
                onChange={(e) =>
                  handleAntCheckboxChange(e, key, request, setRequest)
                }
              >
                {key.replace("is_", "").charAt(0).toUpperCase() +
                  key.replace("is_", "").slice(1)}
              </Checkbox>
            </div>
          ))}
      </div>
    </Modal>
  );
};

export default TagsModal;
