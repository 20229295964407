import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";
import AIAgentTabHeaders from "./detail-header";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { useStateContext } from "@context/dataContext";
import SidebarLayout from "@layouts/SidebarLayout/SidebarLayout";

function AIAgentFullLayout({
  children,
  wrapperClassname,
  showButton,
  activeTab,
  handleSave,
  submitLoading,
}: any) {
  const navigate = useNavigate();
  const params = useParams();
  const handleNavigateBack = () => {
    navigate(`/${getValue(params, `orgId`, "")}/chatbot`);
  };

  const { chatbotInfo, urls, botLoading } = useStateContext();
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const mainURLs =
    getValue(urls, `length`, 0) > 0
      ? urls.filter((item: object) => getValue(item, `id`, ""))
      : [];
  return (
    <>
      <SidebarLayout>
        <PipelineSubHeader
          showBack
          subHeaderListTitle={getValue(chatbotInfo, `name`, "")}
          isRightArrowVisible={true}
          isSubmenuListAvailable={true}
          handleNavigateBack={handleNavigateBack}
        />
        <div className="ai-chatbot-container">
          {botLoading ? (
            <div
              className="d-flex align-items-center justify-content-center py-4"
              style={{ borderBottom: "1px solid #efefef" }}
            >
              <li
                key={`pending-`}
                className={`pt-1 activebar__main-body-list_active`}
              ></li>
            </div>
          ) : 
          // getValue(mainURLs, `length`, 0) > 0 ? (
            <AIAgentTabHeaders
              showButton={showButton}
              activeTab={activeTab}
              handleSave={handleSave}
              submitLoading={submitLoading}
            />
          // ) : (
          //   <div
          //     className="d-flex align-items-center justify-content-center pt-3"
          //     style={{ borderBottom: "1px solid #efefef" }}
          //   >
          //     <li
          //       key={`pending-`}
          //       className={`pt-1 activebar__main-body-list_active`}
          //     >
          //       <a className={"activebar__main-body-link p-2 "}>Sources</a>
          //     </li>
          //   </div>
          // )
          }
          <div
            className={`${
              wrapperClassname ? wrapperClassname : "contentWrapper"
            }`}
          >
            <div className={"contentFullContainer"}>{children}</div>
          </div>
        </div>
      </SidebarLayout>
    </>
  );
}

export default AIAgentFullLayout;
