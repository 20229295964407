import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import { Button, Checkbox, Modal, Radio } from "antd";

function ConfigAssignPopup(props: any) {
  const assignTypes = [
    { value: "team", label: "Team" },
    { value: "chatbot", label: "Chat BOT" },
    { value: "ai-chatbot", label: "AI Agents" },
  ];

  const { setAssignRequest, assignRequest, handleAssignConversation } = props;
  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      onOk={props.handleSubmit}
      onCancel={() => {
        props.handleModal();
        // setAssignRequest((preState: any) => ({
        //   ...preState,
        //   waAccountId: "",
        //   managedBy: "team",
        //   chatBotId: "",
        // }));
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            props.handleModal();
          }}
          className="cancel__button"
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleAssignConversation}
          className="submit__button"
          loading={props.submitLoading}
          disabled={props.submitLoading}
        >
          Save
        </Button>,
      ]}
    >
      <div className="d-flex align-items-center justify-content-between">
        <div>
          <h6 className="header_text__16">Whatsapp Account</h6>
        </div>
      </div>
      <div className="p-3 px-3 py-3">
        <div className="w-100">
          <SearchToolTip
            label="label"
            selectKey={"value"}
            name="Select Whatsapp Account"
            data={props.allConnectionList}
            value={getValue(props, `assignRequest.waAccountId`, "")}
            onChange={(e: object) =>
              setAssignRequest({
                ...assignRequest,
                waAccountId: getValue(e, `value`, ""),
              })
            }
          />
        </div>
      </div>
    </Modal>
  );
}

export default ConfigAssignPopup;
