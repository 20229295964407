import React, { useRef, useState, useEffect, useMemo } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "./tooltip.scss";
import "./index.scss";
import { getValue } from "@utils/lodash";
import { useStateContext } from "@context/dataContext";
import { concatString } from "@common/text-helpers";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getLookupAPIs } from "@components/helpers/lookup-apis";
import { motion } from "framer-motion";
import ArrowUpSortSvgComponent from "@assets/svg/arrow-up-sort";
import ArrowDownSortSvgComponent from "@assets/svg/arrow-down-sort";
import { CalendarArrowDown, CalendarArrowUp } from "lucide-react";
const SortTooltip = (props) => {
  const {
    label,
    id,
    value,
    onChange,
    selectKey,
    data,
    isLoading,
    sortDateIcon,
  } = props;

  const inputRef = useRef(null);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [query, setQuery] = useState("");
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (getValue(props, `lookup_api`, "")) {
      fetchDefaultData();
    }
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    setQuery("");
  }, [props.resetInput]);

  /* -------------------------------------------------------------------------- */
  /*                                     API Section                            */
  /* -------------------------------------------------------------------------- */
  const [dataInfo, setData] = useState([]);
  const [isLoadingInfo, setIsLoading] = useState(true);
  const fetchDefaultData = async () => {
    try {
      let payload = {
        page_no: 1,
        page_size: 10,
      };
      setIsLoading(true);
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getLookupAPIs(
        getValue(props, `lookup_api`, ""),
        queryRequest
      );
      if (resp) {
        setData(resp);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleFetchData = async (search_text) => {
    if (search_text)
      try {
        let payload = {
          search: search_text,
          page_no: 1,
          page_size: 10,
        };
        setIsLoading(true);
        let queryRequest = QueryRequestHelper(payload);
        let resp = await getLookupAPIs(
          getValue(props, `lookup_api`, ""),
          queryRequest
        );
        if (resp) {
          setData(resp);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    else onChange(null);
  };
  const handleClickOption = (option) => {
    onChange && onChange(option);
    setQuery("");
    if (popupRef.current) {
      popupRef.current.close();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const filter = (options) => {
    return getValue(options, `length`, 0) > 0
      ? options.filter(
          (option) =>
            getValue(option, `[${label}]`, "") &&
            option[label].toLowerCase().indexOf(query.toLowerCase()) > -1
        )
      : [];
  };
  const findSelectedValue = (id) => {
    let list =
      getValue(
        getValue(props, `lookup_api`, "") ? dataInfo : data,
        `length`,
        0
      ) > 0
        ? getValue(props, `lookup_api`, "")
          ? dataInfo.filter(
              (item) =>
                getValue(item, `[${selectKey ? selectKey : "id"}]`, "") == id
            )
          : data.filter(
              (item) =>
                getValue(item, `[${selectKey ? selectKey : "id"}]`, "") == id
            )
        : [];
    return getValue(list, `length`, 0) > 0
      ? getValue(list, `[${0}].label`, "")
      : "";
  };
  const valueLabel = useMemo(
    () => findSelectedValue(value),
    [
      value,
      data,
      dataInfo,
      // getValue(data, `length`, 0) > 0,
      // getValue(dataInfo, `length`, 0) > 0,
    ]
  );
  const handleClose = (e) => {
    e.stopPropagation();
    onChange(null);
    if (popupRef.current) {
      popupRef.current.close();
    }
  };
  const popupRef = useRef(null);
  const POSITION_TYPES = [
    "bottom center",
    "right top",
    "top left",
    "top center",
    "top right",
    "right center",
    "right bottom",
    "bottom left",
    "bottom right",
    "left top",
    "left center",
    "left bottom",
    "center center",
  ];
  const { userId } = useStateContext();

  /* -------------------------------------------------------------------------- */
  /*                                Scroll section                              */
  /* -------------------------------------------------------------------------- */
  const selectedOptionRef = useRef(null);
  const [click, setClick] = useState(false);
  // Scroll to the selected option when the component updates
  useEffect(() => {
    if (selectedOptionRef.current) {
      selectedOptionRef.current.scrollIntoView({ behavior: "instant" });
      setClick(false);
    }
  }, [click]);
  return (
    <Popup
      trigger={(open) => (
        <div className={`d-flex align-items-center cursor-pointer`}>
          {valueLabel === "Asc" && (
            <>
              {sortDateIcon ? (
                <CalendarArrowUp
                  size={20}
                  color={
                    props.sortSelected
                      ? valueLabel === "Asc" && "green"
                      : "lightgray"
                  }
                />
              ) : (
                <ArrowUpSortSvgComponent
                  color={
                    props.sortSelected
                      ? valueLabel === "Asc" && "green"
                      : "lightgray"
                  }
                />
              )}
            </>
          )}
          {!valueLabel && (
            <>
              {sortDateIcon ? (
                <CalendarArrowDown color={"#aeaeae"} size={20} />
              ) : (
                <ArrowDownSortSvgComponent color={"lightgray"} />
              )}
            </>
          )}
          {valueLabel === "Desc" && (
            <>
              {sortDateIcon ? (
                <CalendarArrowDown
                  color={valueLabel === "Desc" && "#f27777"}
                  size={20}
                />
              ) : (
                <ArrowDownSortSvgComponent
                  color={valueLabel === "Desc" && "#f27777"}
                />
              )}
            </>
          )}
        </div>
      )}
      // position="bottom center"
      position={POSITION_TYPES}
      mouseEnterDelay={600} // Delay before showing the popup
      mouseLeaveDelay={300} // Delay before hiding the popup
      closeOnDocumentClick
      ref={popupRef}
      className="popup-content-first smaller-width"
      contentStyle={{
        width: props.width,
        transition: "all 1s ease-in",
        padding: 0,
        zIndex: 9999,
      }}
      nested={props.editInline ? true : false}
    >
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -20, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <div>
          {getValue(props, `lookup_api`, "") ? (
            <div className={"filter-select-form-inline"}>
              <div className="search_container">
                <img src={"/dropdown/search.svg"} className="search_image" />
                <input
                  className="search_input"
                  ref={inputRef}
                  autoFocus
                  type="text"
                  value={query}
                  name="searchTerm"
                  autoComplete="off"
                  placeholder={getValue(props, `placeholder`, "search here...")}
                  onChange={(e) => {
                    setQuery(e.target.value);
                    handleFetchData(e.target.value);
                  }}
                />
                {query && (
                  <div className="input_close_image_container">
                    <img
                      src={"/dropdown/close.svg"}
                      className="input_close_image"
                      onClick={(e) => {
                        setQuery("");
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="option_container">
                {isLoadingInfo ? (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    <img src={`/dropdown/loader1.gif`} className="loader" />
                  </div>
                ) : filter(dataInfo).length > 0 ? (
                  filter(dataInfo).map((option, index) => {
                    return (
                      <div
                        onClick={() => {
                          handleClickOption(option);
                        }}
                        className={`option ${
                          getValue(
                            option,
                            `[${selectKey ? selectKey : "id"}]`,
                            ""
                          ) == value
                            ? "selected"
                            : ""
                        }`}
                        key={`${id}-${index}`}
                        ref={
                          getValue(
                            option,
                            `[${selectKey ? selectKey : "id"}]`,
                            ""
                          ) === value
                            ? selectedOptionRef
                            : null
                        } // Set a ref for the selected option
                      >
                        <div className="selected_text hover-text">
                          {concatString(`${option[label]}`, 30)}
                          {getValue(option, `id`, "") === userId &&
                            ` (` + " Me " + `)`}
                          {getValue(option, `[${label}].length`, 0) > 30 && (
                            <span className="tooltip-text" id="top">
                              {option[label]}
                            </span>
                          )}
                          <p className="option_desc">
                            {getValue(option, `desc`, "")}
                          </p>
                        </div>
                        {getValue(
                          option,
                          `[${selectKey ? selectKey : "id"}]`,
                          ""
                        ) == value && (
                          <img
                            src="/dropdown/done.svg"
                            className="done_image"
                          />
                        )}
                      </div>
                    );
                  })
                ) : (
                  <div className="nodata">No Data Found</div>
                )}
              </div>
            </div>
          ) : (
            <div className={"filter-select-form-inline"}>
              {/* {!props.hideSearch && (
                <div className="search_container">
                  <img src={"/dropdown/search.svg"} className="search_image" />
                  <input
                    className="search_input"
                    ref={inputRef}
                    autoFocus
                    type="text"
                    value={query}
                    name="searchTerm"
                    autoComplete="off"
                    placeholder={getValue(
                      props,
                      `placeholder`,
                      "search here..."
                    )}
                    onChange={(e) => {
                      setQuery(e.target.value);
                    }}
                  />
                  {query && (
                    <div className="input_close_image_container">
                      <img
                        src={"/dropdown/close.svg"}
                        className="input_close_image"
                        onClick={(e) => {
                          setQuery("");
                        }}
                      />
                    </div>
                  )}
                </div>
              )} */}
              <div className="option_container">
                {isLoading ? (
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    <img src={`/dropdown/loader1.gif`} className="loader" />
                  </div>
                ) : filter(data).length > 0 ? (
                  filter(data).map((option, index) => {
                    return (
                      <div
                        onClick={() => {
                          handleClickOption(option);
                        }}
                        className={`option ${
                          value &&
                          getValue(
                            option,
                            `[${selectKey ? selectKey : "id"}]`,
                            ""
                          ) == value
                            ? "selected"
                            : ""
                        }`}
                        key={`${id}-${index}`}
                        ref={
                          getValue(
                            option,
                            `[${selectKey ? selectKey : "id"}]`,
                            ""
                          ) === value
                            ? selectedOptionRef
                            : null
                        } // Set a ref for the selected option
                      >
                        <div className="selected_text hover-text">
                          <div
                            className={
                              getValue(option, `color_code`, "")
                                ? " d-flex align-items-center"
                                : ""
                            }
                          >
                            {getValue(option, `color_code`, "") && (
                              <span
                                style={{
                                  background: getValue(
                                    option,
                                    `color_code`,
                                    ""
                                  ),
                                }}
                                className="stage_color"
                              ></span>
                            )}

                            <span
                              className={
                                getValue(option, `color_code`, "") ? "ms-2" : ""
                              }
                            >
                              {concatString(`${option[label]}`, 30)}
                            </span>
                            {getValue(option, `[${label}].length`, 0) > 30 && (
                              <span className="tooltip-text" id="top">
                                {option[label]}
                              </span>
                            )}
                          </div>
                          <p className="option_desc">
                            {getValue(option, `desc`, "")}
                          </p>
                        </div>
                        {value &&
                          getValue(
                            option,
                            `[${selectKey ? selectKey : "id"}]`,
                            ""
                          ) == value && (
                            <img
                              src="/dropdown/done.svg"
                              className="done_image"
                            />
                          )}
                      </div>
                    );
                  })
                ) : (
                  <div className="nodata">No Data Found</div>
                )}
              </div>
            </div>
          )}
        </div>
      </motion.div>
    </Popup>
  );
};
export default SortTooltip;
