import PendingDealComponent from "./components/TicketDetail";
import "./index.scss";
import { Badge, Segmented } from "antd";
import { getValue } from "@utils/lodash";
import AddInfoPopup from "./common/AddPopup/AddInfoPopup";
import { createSubPipeline } from "@services/common.service";
import { toast } from "sonner";
import { generatePipelineAssociationPayload } from "@components/helpers/request-helper";
import SimpleReactValidator from "simple-react-validator";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { handleFormValidation } from "@components/custom/validation";
import { PlusCircle } from "lucide-react";
import TaskCompanies from "./components/TicketCompanies";
import TaskContacts from "./components/TicketContacts";
import PendingLoanComponent from "./components/TicketDetail/components";

function TicketsRightSection(props: any) {
  const params = useParams();
  const [activeTab, setActiveTab] = useState<string>("details");
  const [popupState, setPopupState] = useState({
    isOpen: false,
    module: "",
    title: ""
  });
  
  // Single validator reference for both contacts and companies
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);

  // Handle opening the appropriate popup form
  const handleOpenForm = (module: string) => {
    props.getForm(module);
    setPopupState({
      isOpen: true,
      module,
      title: module === "contacts" ? "Add Contacts" : "Add Companies"
    });
  };

  // Unified submission handler for both contacts and companies
  const handleSubmit = async (module: string) => {
    if (!module) return;

    const formValid = validator.current.allValid();
    const errors = handleFormValidation(props.form);
    const hasErrors = errors.length > 0;

    if (!formValid && module === "companies") {
      validator.current.showMessages();
      forceUpdate(1);
      return;
    }

    if (hasErrors && module === "contacts") {
      return;
    }

    try {
      setFormSubmitLoading(true);
      
      let fields = JSON.parse(JSON.stringify(props.form));
      let associations = [
        { api_name: props.module, record_id: getValue(params, "id", "") }
      ];
      
      let payloadRequest = generatePipelineAssociationPayload(
        module,
        fields,
        props.staticFieldRequest,
        associations
      );
      
      let resp = await createSubPipeline(payloadRequest, module);
      
      if (resp) {
        toast.success("Created & Associated Successfully");
        props.getData(false);
        validator.current.hideMessages();
        forceUpdate(0);
        setPopupState(prev => ({ ...prev, isOpen: false }));
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setFormSubmitLoading(false);
    }
  };

  const tabOptions = [
    { value: "details", label: "Details" },
    { 
      value: "contacts", 
      label: (
        <div className="d-flex gap-0 align-items-center">
          Contacts
          <Badge
            count={
              getValue(props, "data.contacts.length", 0) < 9 &&
              getValue(props, "data.contacts.length", 0) !== 0
                ? `${getValue(props, "data.contacts.length", 0)}`
                : getValue(props, "data.contacts.length", 0)
            }
            className="ms-1"
          />
        </div>
      )
    },
    { 
      value: "companies", 
      label: (
        <div className="d-flex gap-0 align-items-center">
          Companies
          <Badge
            count={
              getValue(props, "data.companies.length", 0) < 9 &&
              getValue(props, "data.companies.length", 0) !== 0
                ? `${getValue(props, "data.companies.length", 0)}`
                : getValue(props, "data.companies.length", 0)
            }
            className="ms-1"
          />
        </div>
      )
    }
  ];

  // Render active tab content
  const renderTabContent = () => {
    switch (activeTab) {
      case "details":
        return (
          <div className="ticket-tab-content details-tab">
            <div className="ticket-section-header">
              {/* <h3>About this ticket</h3> */}
            </div>
            <PendingLoanComponent {...props} alignValue={activeTab} />
            <hr />
            <PendingDealComponent {...props} />
          </div>
        );
      case "contacts":
        return (
          <div className="ticket-tab-content contacts-tab">
            <div className="ticket-section-header">
              <div className="d-flex justify-content-end px-3 py-1 align-items-center w-full">
                {/* <h3>Contacts</h3> */}
                <PlusCircle
                  size={24}
                  className="cursor-pointer"
                  onClick={() => handleOpenForm("contacts")}
                />
              </div>
            </div>
            <TaskContacts {...props} create_module="contacts" />
          </div>
        );
      case "companies":
        return (
          <div className="ticket-tab-content companies-tab">
            <div className="ticket-section-header">
              <div className="d-flex justify-content-end px-3 py-1 align-items-center w-full">
                {/* <h3>Companies</h3> */}
                <PlusCircle
                  size={24}
                  className="cursor-pointer"
                  onClick={() => handleOpenForm("companies")}
                />
              </div>
            </div>
            <TaskCompanies {...props} create_module="companies" />
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div className="pendingticket-right-container">
      {/* Sticky header with styled segments */}
      <div className="sticky-segment-header">
        <Segmented
          value={activeTab}
          onChange={value => setActiveTab(value as string)}
          options={tabOptions}
          className="custom-segments"
          block
        />
      </div>
      
      <div className={`ticket-content-container ${props.className}`}>
        {renderTabContent()}
      </div>
      
      {/* Unified popup for both contacts and companies */}
      <AddInfoPopup
        isOpen={popupState.isOpen}
        setIsOpen={(isOpen:any) => setPopupState(prev => ({ ...prev, isOpen }))}
        title={popupState.title}
        width="590px"
        form={props.form}
        setForm={props.setForm}
        formLoading={props.formLoading}
        handleSubmit={() => handleSubmit(popupState.module)}
        formSubmitLoading={formSubmitLoading}
        simpleValidator={props.simpleValidator}
        handleAssociate={props.handleAssociate}
        handleDisAssociate={props.handleDisAssociate}
        module={popupState.module}
        pipelineList={props.pipelineList}
        stageList={props.stageList}
        staticFieldRequest={props.staticFieldRequest}
        setStaticFieldRequest={props.setStaticFieldRequest}
        getSpecificPipelineInfo={props.getSpecificPipelineInfo}
        selectedList={
          popupState.module === "contacts"
            ? getValue(props, "data.contacts", []).map((item: object) => getValue(item, "id", ""))
            : getValue(props, "data.companies", []).map((item: object) => getValue(item, "id", ""))
        }
        disabled={handleFormValidation(props.form).length > 0}
        disableValidation={popupState.module === "contacts"}
        permissions={getValue(props, "permissions", [])}
      />
    </div>
  );
}

export default TicketsRightSection;