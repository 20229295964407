import { getColorCode, getTextColor } from "@common/colors";
import { convertCurrentDateWithTime } from "@common/date-helpers";
import { getOnlyFirstLetterOfWord } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Mail, Edit, Clock } from "lucide-react";

const TicketUserMessages = (props: any) => {
  const firstName = getValue(props, `data.contacts[0].first_name`, "");
  const lastName = getValue(props, `data.contacts[0].last_name`, "");
  const createdAt = getValue(props, `data.created_at`, "");
  const ticketDescription = getValue(props, "data.ticket_description", "");
  const ticketName = getValue(props, "data.ticket_name", "");
  const firstLetter = getOnlyFirstLetterOfWord(firstName);
  const colorCode = getColorCode(firstLetter);
  const textColor = getTextColor(colorCode);

  return (
    <div className="ticket-detail-message-wrapper my-3 p-4 rounded">
      <div className="w-100">
        {/* Header Section */}
        <div className="d-flex align-items-center justify-content-between mb-3">
          {/* User Info */}
          <div className="d-flex align-items-center gap-3">
            <div
              className="image-container rounded-circle d-flex align-items-center justify-content-center"
              style={{
                background: colorCode,
                color: textColor,
                width: "30px",
                height: "30px",
              }}
            >
              <p className="header-text m-0 fw-bold">{firstLetter}</p>
            </div>
            <h6 className="header_text__16 fw-semibold m-0">
              {firstName} {lastName}
            </h6>
          </div>
          {/* Timestamp and Actions */}
          <div className="d-flex align-items-center gap-3">
            <div className="d-flex align-items-center text-muted">
              <Clock size={14} className="me-1" />
              <p className="small_text__13 m-0">
                {convertCurrentDateWithTime(createdAt)}
              </p>
            </div>
            <Edit
              size={16}
              className="cursor-pointer text-primary"
              onClick={props.handleEditToggle}
            />
          </div>
        </div>

        {/* Message Content */}
        <div className="w-100 bg-light p-3 rounded">
        <p className="small_text__16 mb-4">{ticketName}</p>
          <div className="settings-details-desc d-flex align-items-start">
            <Mail size={16} className="mt-1 text-secondary" />
            <p
              className="ms-2"
              dangerouslySetInnerHTML={{
                __html: ticketDescription,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketUserMessages;
