import AccessTimeSvgComponent from "@assets/svg/accesstime";
import TickSvgComponent from "@assets/svg/tick";
import DoubleTickSvgComponent from "@assets/svg/double-tick";

import { getValue } from "@utils/lodash";

export const getReadIcon = (item: any) => {
  if (!getValue(item, `receiver`, false)) {
    if (getValue(item, `is_read`, false) || getValue(item, `read`, false)) {
      return <DoubleTickSvgComponent color={"#34B7F1"} size={14} />;
    } else if (
      getValue(item, `is_delivered`, false) ||
      getValue(item, `delivered`, true) ||
      getValue(item, `received`, false) ||
      getValue(item, `is_received`, false)
    ) {
      return <DoubleTickSvgComponent color={"gray"} size={14} />;
    } else if (
      getValue(item, `is_sent`, false) ||
      getValue(item, `sent`, false)
    ) {
      return <TickSvgComponent color={"gray"} size={14} />;
    } else {
      return <AccessTimeSvgComponent size={14} />;
    }
    // else if (getValue(item, `is_received`, false)) {
    //   return <DoubleTickSvgComponent color={"green"} size={14} />;
    // }
  } else {
    return null;
  }
};
