"use client";

import { useState } from "react";
import { formatDistanceToNow } from "date-fns";
import { MoreHorizontal, Clock, Tag, RefreshCcw } from "lucide-react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "./TicketBoard.scss";
import { getValue } from "@utils/lodash";
import { formatString } from "@common/text-helpers";
import { updateTicket } from "@services/tickets.service";
import { toast } from "sonner";
import { Badge } from "antd";
import { useNavigate, useParams } from "react-router-dom";

interface Ticket {
  id: string;
  ticket_id: string;
  ticket_name: string;
  ticket_description: string;
  status: string;
  created_at: string;
  created_by?: {
    first_name: string;
    last_name: string;
  };
}

interface TicketColumnProps {
  title: string;
  count: number;
  tickets: Ticket[];
  type: any;
  colorCode: string;
  statusId: string;
  columnId: string;
  navigate: any;
  params: any;
  id: any;
}

const TicketItem = ({
  key,
  ticket,
  index,
  navigate,
  params,
}: {
  key: string;
  ticket: Ticket;
  index: number;
  navigate: any;
  params: any;
}) => (
  <Draggable draggableId={ticket.id} index={index}>
    {(provided) => (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        className="ticket"
        onClick={() =>
          navigate(
            `/${getValue(params, `orgId`, "")}/tickets/detail/${ticket.id}`
          )
        }
      >
        <div className="ticket__header">
          <div className="ticket__user">
            <div className="ticket__avatar">
              {ticket.created_by?.first_name?.[0] || "U"}
            </div>
            <div className="ticket__user-info">
              <h6 className="header_text__16">
                {ticket.created_by?.first_name} {ticket.created_by?.last_name}
              </h6>
              <div className="small_text__13">#{ticket.ticket_id}</div>
            </div>
          </div>
          <button className="icon-button">
            <MoreHorizontal />
          </button>
        </div>

        <div className="ticket__content">
          <h3 className="header_text__15">{ticket.ticket_name}</h3>
          {/* <p className="small_text__13">{ticket.ticket_description}</p> */}
          <div
            className="small_text__13"
            style={{ marginBottom: 10 }}
            dangerouslySetInnerHTML={{
              __html: getValue(ticket, `ticket_description`, ""),
            }}
          />
        </div>

        <div className="ticket__footer">
          <div className="small_text__12 d-flex align-items-center gap-1">
            <Clock className="ticket__meta-icon" />
            {formatDistanceToNow(new Date(ticket.created_at), {
              addSuffix: true,
            })}
          </div>
          <div className="ticket__actions">
            <Tag className="ticket__action-icon" />
            <RefreshCcw className="ticket__action-icon" />
          </div>
        </div>
      </div>
    )}
  </Draggable>
);

const TicketColumn = ({
  title,
  count,
  tickets,
  type,
  colorCode,
  columnId,
  navigate,
  params,
  id,
}: TicketColumnProps) => {
  return (
    <div className="ticket-column">
      <div className="ticket-column__header">
        <div className="ticket-column__title">
          <div className={`ticket-column__dot ticket-column__dot--${type}`} />
          <h6 className="header_text__16">{title}</h6>
          <Badge
            className="site-badge-count-109"
            count={count}
            style={{ backgroundColor: colorCode }}
          />
        </div>
        <button className="icon-button">
          <MoreHorizontal />
        </button>
      </div>
      {count === 0 && (
        <div className="ticket-column__empty">
          <img
            src="/images/drag-placeholder.svg"
            alt="empty-ticket"
            className="img-fluid"
          />
          <p className="text-center mt-3 secondary_text">
            Drag an existing ticket and drop it under this category or click the
            create button to create a new one.{" "}
          </p>
        </div>
      )}

      <Droppable droppableId={columnId}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="ticket-column__content"
          >
            {tickets.map((ticket, index) => (
              <TicketItem
                key={ticket.id}
                ticket={ticket}
                index={index}
                navigate={navigate}
                params={params}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export default function TicketBoard(props: { list: any[] }) {
  const navigate = useNavigate();
  const params = useParams();
  const { list } = props;
  const [boardData, setBoardData] = useState(list);

  function removeFirstTwoStrings(word: string) {
    if (word) return word.split("-").slice(1).join("-");
  }

  const onDragEnd = (result: any) => {
    const api: any = handleMoveTicketsFromStatus(
      getValue(result, `draggableId`, ""),
      getValue(
        boardData[
          Number.parseInt(result.destination.droppableId.split("-")[0])
        ][result.destination.droppableId.split("-")[1]],
        "status.id",
        ""
      )
    );
    if (api) {
      const { source, destination } = result;
      // If dropped outside the list
      if (!destination) {
        return;
      }

      const sourceBoard = Number.parseInt(source.droppableId.split("-")[0]);
      const sourceColumn = source.droppableId.split("-")[1];
      const destBoard = Number.parseInt(destination.droppableId.split("-")[0]);
      const destColumn = destination.droppableId.split("-")[1];

      // If dropped in the same place
      if (
        sourceBoard === destBoard &&
        sourceColumn === destColumn &&
        source.index === destination.index
      ) {
        return;
      }

      // Create a new boardData array
      const newBoardData = [...boardData];

      // Remove from the source
      const [removed] = newBoardData[sourceBoard][sourceColumn].tickets.splice(
        source.index,
        1
      );

      // Add to the destination
      newBoardData[destBoard][destColumn].tickets.splice(
        destination.index,
        0,
        removed
      );

      // Update the state
      setBoardData(newBoardData);
    }
  };

  const handleMoveTicketsFromStatus = async (id: string, status: any) => {
    try {
      let resp = await updateTicket(id, { status_id: status });
      if (resp) {
        // toast.success("Updated successfully");
        return true;
      }
    } catch (error) {}
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {boardData.map((view, boardIndex) => (
        <div key={boardIndex}>
          {Object.keys(view).map((key) => (
            <div className="ticket-board" key={key}>
              <div className="ticket-board__columns">
                <TicketColumn
                  tickets={getValue(
                    boardData,
                    `[${boardIndex}][${key}].tickets`,
                    []
                  )}
                  title={formatString(key)}
                  count={getValue(
                    boardData,
                    `[${boardIndex}][${key}].tickets.length`,
                    0
                  )}
                  colorCode={getValue(
                    boardData[boardIndex][key],
                    "status.color_code",
                    ""
                  )}
                  statusId={getValue(
                    boardData[boardIndex][key],
                    "status.id",
                    ""
                  )}
                  columnId={`${boardIndex}-${key}`}
                  type={key}
                  navigate={navigate}
                  params={params}
                  id={""}
                />
              </div>
            </div>
          ))}
        </div>
      ))}
    </DragDropContext>
  );
}
