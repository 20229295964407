import React, { memo, useCallback, useMemo, useEffect } from "react";
import DeleteModal from "@components/custom/Modal/DeleteModal";
import { deleteArticle } from "@services/solutions.service";
import { getValue } from "@utils/lodash";
import { Dropdown } from "antd";
import { EllipsisVertical, PlusCircle, Trash2 } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

const RenderArticles = memo(
  ({
    category,
    folder,
    selectedFolder,
    selectedArticle,
    articleRequest,
    handleCreateArticle,
    setPreviousArticle,
    setNextArticle,
  }: any) => {
    const params = useParams();
    const navigate = useNavigate();

    // Memoized values to prevent unnecessary recalculations
    const folderId = useMemo(() => getValue(folder, "id", ""), [folder]);
    const isSelected = useMemo(
      () => selectedFolder === folderId,
      [selectedFolder, folderId]
    );
    const articles = useMemo(
      () => getValue(folder, "solution_articles", []),
      [folder]
    );

    // Optimize navigation and article selection
    const navigateToArticle = useCallback(
      (articleId: string) => {
        navigate(
          `/${getValue(params, "orgId", "")}/solutions/${getValue(
            category,
            "id",
            ""
          )}/folders/${folderId}/article/${articleId}`
        );
      },
      [params, folder, folderId]
    );

    // Calculate next and previous articles with improved efficiency
    useEffect(() => {
      if (!isSelected) return;

      const currentArticleIndex = articles.findIndex(
        (article: any) => article.id === selectedArticle
      );

      setPreviousArticle(
        currentArticleIndex > 0 ? articles[currentArticleIndex - 1] : null
      );

      setNextArticle(
        currentArticleIndex < articles.length - 1
          ? articles[currentArticleIndex + 1]
          : null
      );
    }, [
      articles,
      selectedArticle,
      isSelected,
      setPreviousArticle,
      setNextArticle,
    ]);

    // Memoized article menu generation
    const renderArticleMenu = useCallback(
      (articleId: string, article: any) => [
        {
          key: "delete",
          icon: <Trash2 size={14} />,
          label: (
            <DeleteModal
              api={() => deleteArticle(articleId)}
              name={getValue(article, "title", "")}
              module="Article"
              disabled={getValue(article, "is_active", false)}
              disabledMessage="Cannot delete active article"
              showText
            />
          ),
          disabled: getValue(article, "is_active", false),
        },
      ],
      []
    );

    return (
      <div className={`articles-container ${isSelected ? "selected" : ""}`}>
        {articles.length === 0 ? (
          <>
            <div className="small_text__13 pt-2 text-center ms-4">
              No articles available
            </div>
            <div
              className="add-button mt-2 ms-4 justify-content-center"
              onClick={() =>
                handleCreateArticle(getValue(category, "id", ""), folderId)
              }
            >
              <PlusCircle size={16} /> Add Article
            </div>
          </>
        ) : (
          <>
            {articles.map((article: any) => {
              const articleId = getValue(article, "id", "");
              const isArticleSelected = selectedArticle === articleId;
              const articleTitle = isArticleSelected
                ? getValue(articleRequest, "title", "")
                : getValue(article, "title", "");

              return (
                <div
                  key={articleId}
                  className={`${
                    getValue(params, `articleId`, "") ===
                    getValue(article, `id`, "")
                      ? "folder-articles--selected"
                      : "folder-articles"
                  }`}
                >
                  <div className="d-flex align-items-center gap-2">
                    <span
                      className="cursor-pointer"
                      onClick={() => navigateToArticle(articleId)}
                    >
                      {articleTitle}
                    </span>
                  </div>
                  <div className="article-checkbox">
                    <Dropdown
                      menu={{ items: renderArticleMenu(articleId, article) }}
                      className="more-options"
                    >
                      <EllipsisVertical size={14} className="cursor-pointer" />
                    </Dropdown>
                  </div>
                </div>
              );
            })}
            <div
              className="add-button mt-2 ms-4"
              onClick={() =>
                handleCreateArticle(getValue(category, "id", ""), folderId)
              }
            >
              <PlusCircle size={16} /> Add Article
            </div>
          </>
        )}
      </div>
    );
  }
);

export default RenderArticles;
