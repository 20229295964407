import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllPhoneAssignment = (queryRequest: string) =>
  get(`${config.API_URL}/phone-assignments?${queryRequest}`);

export const getSpecificPhoneAssignment = (id: string) =>
  get(`${config.API_URL}/phone-assignments/${id}`);

export const getAllPhoneAssignmentConditionFields = () =>
  get(`${config.API_URL}/phone-assignments/condition-fields`);

export const createPhoneAssignment = (payload: object) =>
  post(`${config.API_URL}/phone-assignments/manage`, payload);

export const updatePhoneAssignmentPosition = (payload: object) =>
  patch(`${config.API_URL}/phone-assignments/seq-num/swap`, payload);

export const deletePhoneAssignment = (id: string) =>
  Delete(`${config.API_URL}/phone-assignments/${id}`);
