"use client";

import { Button, Input } from "antd";
import { BulbOutlined } from "@ant-design/icons";
import "./index.scss";
import AIAgentFullLayout from "../../Layout/full-layout";
import { getValue } from "@utils/lodash";
import { useParams } from "react-router-dom";

export default function AIAgentPlayground() {
  const params = useParams();
  return (
    <AIAgentFullLayout>
      {/* <header className="chat-header">
        <div className="logo">Playground</div>
        <div className="actions">
          <Button type="default" className="compare-btn">
            Compare
            <BulbOutlined />
          </Button>
        </div>
      </header> */}
      <div className="chat-container">
        <iframe
          src={`https://chatbot-iframe.onrender.com/?id=${getValue(
            params,
            `chatbotId`,
            ""
          )}`}
          width="50%"
          height="500px"
          frameBorder="0"
          title="Embedded Page"
        />
        {/* <main className="chat-window">
          <div className="chat-header">
            <span>powerbaba.com</span>
            <Button type="text" icon={<ReloadOutlined />} />
          </div>

          <div className="messages">
            <div className="message">Hi! What can I help you with?</div>
          </div>

          <div className="input-area">
            <Input
              placeholder="Message..."
              suffix={<SendOutlined />}
              className="message-input"
            />
          </div>

          <div className="powered-by">Powered By Appzo.ai</div>
        </main> */}
      </div>
    </AIAgentFullLayout>
  );
}
