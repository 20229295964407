import { Info, Plus, Trash2 } from "lucide-react";
import { Button, Popconfirm } from "antd";
import TextArea from "antd/es/input/TextArea";
import QARightSection from "./qa-right";
import { getValue } from "@utils/lodash";

export default function AIAgentSourceQandA(props: any) {
  const { request, setRequest, qaInfo, handleDeleteQa } = props;

  const addNewPair = () => {
    setRequest((prevRequest: any) => ({
      ...prevRequest,
      qna: [
        ...prevRequest.qna,
        {
          question: "",
          answer: "",
        },
      ],
    }));
  };

  const deletePair = (index: number) => {
    setRequest((prevRequest: any) => ({
      ...prevRequest,
      qna: prevRequest.qna.filter((_: any, i: number) => i !== index),
    }));
  };

  const updatePair = (
    index: number,
    field: "question" | "answer",
    value: string
  ) => {
    setRequest((prevRequest: any) => ({
      ...prevRequest,
      qna: prevRequest.qna.map((pair: any, i: number) =>
        i === index ? { ...pair, [field]: value } : pair
      ),
    }));
  };

  return (
    <>
      <div className="qa-container">
        <div className="qa-header">
          <Button
            onClick={addNewPair}
            color="default"
            variant="filled"
            icon={<Plus size={18} />}
          />
        </div>

        {request.qna.map((pair: any, index: number) => (
          <div key={index} className="qa-pair">
            <div className="input-group">
              <div className="label-row w-100 py-2 d-flex align-items-center justify-content-between">
                <h6 className="header_text__16">Question</h6>
                <div className="d-flex align-items-center gap-2">
                  {getValue(pair, `status`, "") && (
                    <div
                      key={index}
                      className="d-flex align-items-center gap-2"
                    >
                      <span
                        className={
                          getValue(pair, `status`, "") == "failed"
                            ? "chatbot_status__error"
                            : getValue(pair, `status`, "") == "untrained"
                            ? "chatbot_status__untrained"
                            : "chatbot_status__success"
                        }
                      >
                        <Info size={14} style={{ marginRight: 4 }} />
                        {getValue(pair, `status`, "") === "untrained"
                          ? props.disableTrain
                            ? "training..."
                            : getValue(pair, `status`, "")
                          : getValue(pair, `status`, "")}
                      </span>
                    </div>
                  )}
                  <div className="delete-pair">
                    {pair.id ? (
                      <Popconfirm
                        title="Are you sure to delete this qa?"
                        onConfirm={() => handleDeleteQa(pair.id)}
                      >
                        <Trash2
                          className="trash-icon cursor-pointer"
                          size={18}
                        />
                      </Popconfirm>
                    ) : (
                      <div onClick={() => deletePair(index)}>
                        <Trash2
                          className="trash-icon cursor-pointer"
                          size={18}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <TextArea
                value={pair.question}
                onChange={(e) => updatePair(index, "question", e.target.value)}
                rows={2}
              />
            </div>

            <div className="input-group">
              <h6 className="header_text__16 mb-2 py-2">Answer</h6>
              <TextArea
                value={pair.answer}
                onChange={(e) => updatePair(index, "answer", e.target.value)}
                rows={3}
              />
            </div>
          </div>
        ))}
      </div>
      <QARightSection
        handleSaveURL={props.handleSaveURL}
        findStaticUrl={props.findStaticUrl}
        handleTrainLink={props.handleTrainLink}
        scrapeInfo={props.scrapeInfo}
        ScrapedDocumentStatus={props.ScrapedDocumentStatus}
        linkURLs={props.linkURLs}
        activeTab={props.activeTab}
        qaInfo={qaInfo}
        handleDeleteQa={handleDeleteQa}
        qna={request.qna}
      />
    </>
  );
}
