import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import { Eye, ShieldAlert, Trash2 } from "lucide-react";
import React from "react";
import OptionFieldsBuilder from "../OptionFieldsFormBuilder";
import { Alert } from "antd";
import MultiSearchLookupTooltip from "@components/custom/Dropdown/MultiSearchLookupTooltip";
import { handleUpdateArray } from "@common/handleChange";

const DefaultActions = ({
  index,
  request,
  setRequest,
  updateFields,
  item,
  handleIfCondition,
  validator,
  findInputType,
  removeConditionUpdate,
  handleDeleteOption,
}: any) => {
  // const handleUsers = (selectedAgents: any[]) => {
  //   setRequest((prevRequest: any) => ({
  //     ...prevRequest,
  //     actions: {
  //       ...prevRequest.actions,
  //       updates: prevRequest.actions.updates.map(
  //         (update: any, updateIndex: number) =>
  //           updateIndex === index
  //             ? {
  //                 ...update,
  //                 value: [getValue(selectedAgents, "id", "")],
  //               }
  //             : update
  //       ),
  //     },
  //   }));
  // };

  const handleFilterChange = (value: any, fieldName: string) => {
    setRequest((prevRequest: any) => ({
      ...prevRequest,
      actions: {
        ...prevRequest.actions,
        updates: prevRequest.actions.updates.map(
          (update: any, noteIndex: number) =>
            noteIndex === index
              ? {
                  ...update,
                  [fieldName]: handleUpdateArray(
                    value.id,
                    update[fieldName] || []
                  ),
                }
              : update
        ),
      },
    }));
  };
  console.log(updateFields, "updateFields");

  return (
    <div key={index} className="sla-condition-card p-4 border-bottom">
      <div className="w-9 d-flex align-items-center gap-3 w-100">
        {getValue(item, "field_name", "") &&
        (getValue(item, "field_name", "") === "delete" ||
          getValue(item, "field_name", "") === "spam") ? (
          <Alert
            showIcon
            icon={
              getValue(item, "field_name", "") === "delete" ? (
                <Trash2 size={16} />
              ) : (
                <ShieldAlert size={18} />
              )
            }
            message={
              getValue(item, "field_name", "") === "delete"
                ? "Delete the Ticket"
                : "Mark the Ticket as Spam"
            }
            type={
              getValue(item, "field_name", "") === "delete"
                ? "error"
                : "warning"
            }
          />
        ) : getValue(item, "field_name", "") === "watcher" ? (
          <div className="w-70">
            <MultiSearchLookupTooltip
              lookup_api={"org_user"}
              label={`label`}
              value={getValue(request, `actions.updates[${index}].value`, [])}
              selectKey={"id"}
              onChange={(value: any) => handleFilterChange(value, "value")}
              name="Add watcher"
              // hideLabel
            />
          </div>
        ) : (
          <>
            <div>
              <SearchToolTip
                width={220}
                label="label"
                name="Action"
                selectKey="value"
                data={updateFields}
                value={getValue(item, "fieldId", "")}
                onChange={(e: any) => handleIfCondition(index, e)}
                hideClose
                placeholder="Select Action"
                // validator={validator}
                hideLabel
              />
            </div>
            {item.fieldId && (
              <div className="ms-2">
                <OptionFieldsBuilder
                  item={findInputType(getValue(item, `fieldId`, ""))}
                  automationInfo={item}
                  parentIndex={index}
                  request={request}
                  setRequest={setRequest}
                  value={item.value}
                  validator={validator}
                />
              </div>
            )}
          </>
        )}
      </div>
      {/* {getValue(request, "actions.updates.length", 0) > 1 && ( */}
      <Trash2
        size={18}
        className="delete-sla-condition"
        onClick={() =>
          getValue(item, `id`, "")
            ? removeConditionUpdate(index, "updates")
            : handleDeleteOption(index, "updates")
        }
      />
      {/* )} */}
    </div>
  );
};

export default DefaultActions;
