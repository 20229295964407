import * as React from "react";
import "./index.scss";
import Loading from "./ai-stars.json";
import Lottie from "react-lottie";
interface ILoaderProps {
  size?: string;
}

const AIStars: React.FunctionComponent<ILoaderProps> = ({ size }: any) => {
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: Loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Lottie
      options={defaultOptions}
      height={size ? size : "20px"}
      width={size ? size : "20px"}
      style={{ display: "flex", alignItems: "center" }}
    />
  );
};

export default AIStars;
