import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { getValue } from "@utils/lodash";
import { createPortal } from "react-dom";
import { convertDateTime, getDate } from "@common/date-helpers";

const SheetDateTimeEditor = React.memo(
  ({ col, row, field, handleDisplayItem, handleInputChange }: any) => {
    const initialDate = handleDisplayItem(col, row);
    const convertedInitialDate = initialDate
      ? getDate(getValue(row, `[${col}]`, ""))
      : null;

    // ✅ Prevents blinking by setting state only when needed
    const [selectedDate, setSelectedDate] = useState<Date | null>(
      convertedInitialDate
    );

    useEffect(() => {
      if (!selectedDate) {
        setSelectedDate(convertedInitialDate); // ✅ Update only if no user-selected value exists
      }
    }, [convertedInitialDate]);

    const onChange = (date: Date | null) => {
      setSelectedDate(date); // ✅ Ensures proper selection handling
      if (date) {
        const correctedUtcDate = convertDateTime(date);
        handleInputChange(
          getValue(row, "id", ""),
          col,
          correctedUtcDate,
          field
        );
      } else {
        handleInputChange(getValue(row, "id", ""), col, null, field);
      }
    };

    return (
      <DatePicker
        className="sheet_inline_form_input"
        selected={selectedDate} // ✅ Prevents blinking, properly updates state
        onChange={onChange} // ✅ Handles user input smoothly
        placeholderText="Select date & time"
        showTimeSelect
        showMonthDropdown
        showYearDropdown
        timeIntervals={15}
        dateFormat="d MMM yyyy, p"
        timeCaption="Time"
        popperContainer={({ children }) =>
          createPortal(children, document.body)
        }
      />
    );
  }
);

export default SheetDateTimeEditor;
