import { useState } from "react";
import { getValue } from "@utils/lodash";
import { updatePipelineField } from "@services/pipeline.service";
import { toast } from "sonner";
import LockSvgComponent from "assets/svg/lock";
import "./ManageTable.scss";
import CustomCheckbox from "@components/Form/CheckBox/CustomCheckbox";
import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";
import ManageFieldPopup from "./ManageFieldPopup";
import { useParams } from "react-router-dom";
import { Button, Checkbox } from "antd";
import { Pencil } from "lucide-react";
export default function ManageMandatory(props: any) {
  const [isRollsPopupVisibile, setRollsPopupVisisbility] = useState(false);
  const handleChangeMandatory = async (status: boolean) => {
    try {
      let resp = await updatePipelineField(
        getValue(props, `item.module_id`, ""),
        getValue(props, `selectedPipeline`, ""),
        {
          // api_name: getValue(props, `item.api_name`, ""),
          seq_num: getValue(props, `index`, "") + 1,
          required: status,
        },
        getValue(props, `item.form_field_id`, "")
      );
      if (resp) {
        if (
          getValue(props, `module`, "") === "tasks" ||
          getValue(props, `module`, "") === "calls" ||
          getValue(props, `module`, "") === "meetings"
        ) {
          props.getTaskFields();
        } else {
          props.getData(getValue(props, `selectedPipeline`, ""));
        }
        toast.success("Updated successfully");
      }
    } catch (error) {}
  };
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center gap">
        <div className="d-flex align-items-center">
          {/* <CustomCheckbox
            checked={
              getValue(props, `item.required`, false) ||
              getValue(props, `item.system_required`, false)
            }
            onChange={() =>
              !getValue(props, `item.system_required`, false) &&
              handleChangeMandatory(!getValue(props, `item.required`, false))
            }
            disabled={
              getValue(props, `item.system_required`, false) ? true : false
            }
          /> */}
          <Checkbox
            onChange={() =>
              !getValue(props, `item.system_required`, false) &&
              handleChangeMandatory(!getValue(props, `item.required`, false))
            }
            checked={getValue(props, `item.required`, false)}
            disabled={
              getValue(props, `item.system_required`, false) ? true : false
            }
          />

          <p
            className={`small_text__16 ${
              !getValue(props, `item.system_required`, false) ? "ms-3" : "ms-3"
            }`}
          >
            Mandatory
          </p>
          {/* {getValue(props, `item.system_required`, false) && (
            <div className="ms-1">
             <CustomCheckbox disabled/>
            </div>
          )} */}
        </div>
        {!getValue(props, `item.system_required`, false) && (
          <div
            className="d-flex align-items-center ms-5"
            onClick={() =>
              props.handleEditFields(
                getValue(props, `item.api_name`, ""),
                false
              )
            }
          >
            <img src="/images/icons/hide.svg" />
            <p className={`small_text__16 ms-2`}>Hide</p>
          </div>
        )}
      </div>
      <Button
        variant="text"
        color="default"
        size="small"
        onClick={() => {
          props.handleEditCustomFields(props.item);
        }}
        className="mx-3"
        icon={<Pencil size={16} />}
      />

      <SlideFromRightPopup
        isPopupVisible={isRollsPopupVisibile}
        addCustomClass={"rolls-popup"}
      >
        <ManageFieldPopup
          title="Edit Fields"
          handleBackButton={() => {
            setRollsPopupVisisbility(false);
          }}
          isAddExisting={true}
        />
      </SlideFromRightPopup>
    </div>
  );
}
