import CopySvgComponents from "@assets/svg/copy";
import { CopyToClipboard } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Button } from "antd";
import { Files } from "lucide-react";
import { useParams } from "react-router-dom";

function AIShareWordpress(props: any) {
  const params = useParams();

  const clipBoardData = `<script
      type="text/javascript"
      src="https://st-beta-storage.blr1.cdn.digitaloceanspaces.com/sm-tools/supercrm-chatbot-plugin-v1.js"
      id="supercrm-wa-widget"
      widget-id="${getValue(params, "chatbotId", "")}"
      defer
    ></script>`;
  return (
    <div className="">
      <div className="">
        <h6 className="header_text__20 mb-4">Add to WordPress Website</h6>
        <p className="small_text__14 mt-2 mb-3">
          <b>For Embed-</b> Go to your post or page editor. Copy the following
          URL and paste where required.
        </p>
        <div className="d-flex align-items-center gap-3">
          <pre className="shareable-code m-0">
            <code className="small_text__14">{clipBoardData}</code>
          </pre>
          <Button
            icon={<Files size={16} />}
            onClick={() => CopyToClipboard(clipBoardData)}
          />
        </div>

        <p className="small_text__14 mt-3 mb-2">
          <b>Bot ID:</b> {getValue(params, `chatbotId`, "")}
        </p>
        <p className="small_text__14 mt-2 mb-2">
          <b>Get help</b> with step by step instructions
        </p>
        <p className="small_text__14 mt-2 mb-2">
          <b>Perfect for lead</b> collection, customer education, customer
          feedback.
        </p>
      </div>
    </div>
  );
}

export default AIShareWordpress;
