import { config } from "../env";
import {
  Delete,
  get,
  patch,
  post,
} from "./helpers/http-handler";

export const getBookmarkedModules = () =>
  get(`${config.API_URL}/bookmarks`);

export const getBookmarkedModulesById = (id: string) =>
  get(`${config.API_URL}/bookmarks/${id}`);

export const getBookmarkedModulesList = () =>
  get(`${config.API_URL}/bookmarks/list`);

export const createBookmarkedModules = (payload: object) =>
  post(`${config.API_URL}/bookmarks`, payload);

export const updateBookmarkedModules = (id: string, payload: object) =>
  patch(`${config.API_URL}/bookmarks/${id}`, payload);

export const updateBookmarkedOrder = ( payload: object) =>
  patch(`${config.API_URL}/bookmarks/seq-num/swap`, payload);

export const deleteBookmark = (id: string) =>
  Delete(`${config.API_URL}/bookmarks/${id}`);
