import {
  createOrganisationIntro,
  updateOrganisations,
} from "@services/organisations.service";
import { getValue } from "@utils/lodash";
import { Button, Checkbox, Modal, Radio, Select } from "antd";
import {
  Banknote,
  ShoppingCart,
  Shield,
  Heart,
  Book,
  Film,
  Phone,
  Code,
  Truck,
  Cpu,
  DollarSign,
  Factory,
  Car,
  Bed,
  Battery,
  Hammer,
  Trees,
  Navigation,
  Home,
  Coffee,
  Pill,
  Rocket,
  MoreHorizontal,
  Plane,
  MoveRight,
} from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";

const industries = [
  {
    label: (
      <>
        <Banknote size={16} /> Banking and Financial Services
      </>
    ),
    labelText: "Banking and Financial Services",
    value: "banking",
  },
  {
    label: (
      <>
        <ShoppingCart size={16} /> Retail and E-Commerce
      </>
    ),
    labelText: "Retail and E-Commerce",
    value: "retail",
  },
  {
    label: (
      <>
        <Shield size={16} /> Insurance
      </>
    ),
    labelText: "Insurance",
    value: "insurance",
  },
  {
    label: (
      <>
        <Heart size={16} /> Healthcare
      </>
    ),
    labelText: "Healthcare",
    value: "healthcare",
  },
  {
    label: (
      <>
        <Book size={16} /> Ed-tech
      </>
    ),
    labelText: "Ed-tech",
    value: "edtech",
  },
  {
    label: (
      <>
        <Film size={16} /> Media and Entertainment
      </>
    ),
    labelText: "Media and Entertainment",
    value: "media",
  },
  {
    label: (
      <>
        <Phone size={16} /> Telecom
      </>
    ),
    labelText: "Telecom",
    value: "telecom",
  },
  {
    label: (
      <>
        <Plane size={16} /> Travel
      </>
    ),
    labelText: "Travel",
    value: "travel",
  },
  {
    label: (
      <>
        <Code size={16} /> Software and Internet
      </>
    ),
    labelText: "Software and Internet",
    value: "software",
  },
  {
    label: (
      <>
        <Truck size={16} /> Logistics
      </>
    ),
    labelText: "Logistics",
    value: "logistics",
  },
  {
    label: (
      <>
        <Cpu size={16} /> Technology
      </>
    ),
    labelText: "Technology",
    value: "technology",
  },
  {
    label: (
      <>
        <DollarSign size={16} /> Finance
      </>
    ),
    labelText: "Finance",
    value: "finance",
  },
  {
    label: (
      <>
        <Factory size={16} /> Manufacturing
      </>
    ),
    labelText: "Manufacturing",
    value: "manufacturing",
  },
  {
    label: (
      <>
        <Car size={16} /> Automotive
      </>
    ),
    labelText: "Automotive",
    value: "automotive",
  },
  {
    label: (
      <>
        <Bed size={16} /> Hospitality
      </>
    ),
    labelText: "Hospitality",
    value: "hospitality",
  },
  {
    label: (
      <>
        <Battery size={16} /> Energy
      </>
    ),
    labelText: "Energy",
    value: "energy",
  },
  {
    label: (
      <>
        <Hammer size={16} /> Construction
      </>
    ),
    labelText: "Construction",
    value: "construction",
  },
  {
    label: (
      <>
        <Trees size={16} /> Agriculture
      </>
    ),
    labelText: "Agriculture",
    value: "agriculture",
  },
  {
    label: (
      <>
        <Navigation size={16} /> Transportation
      </>
    ),
    labelText: "Transportation",
    value: "transportation",
  },
  {
    label: (
      <>
        <Home size={16} /> Real Estate
      </>
    ),
    labelText: "Real Estate",
    value: "realestate",
  },
  {
    label: (
      <>
        <Coffee size={16} /> Food and Beverage
      </>
    ),
    labelText: "Food and Beverage",
    value: "food",
  },
  {
    label: (
      <>
        <Pill size={16} /> Pharmaceuticals
      </>
    ),
    labelText: "Pharmaceuticals",
    value: "pharmaceuticals",
  },
  {
    label: (
      <>
        <Rocket size={16} /> Aerospace
      </>
    ),
    labelText: "Aerospace",
    value: "aerospace",
  },
  {
    label: (
      <>
        <MoreHorizontal size={16} /> Others
      </>
    ),
    labelText: "Others",
    value: "others",
  },
];

const options = [
  { label: "1", value: "1" },
  { label: "2-5", value: "2_5" },
  { label: "6-10", value: "6_10" },
  { label: "11-50", value: "11_50" },
  { label: "50+", value: "above_50" },
];

const aboutAppzo = [
  { label: "Linkedin", value: "linkedin" },
  { label: "Web Search", value: "web_search" },
  { label: "Social", value: "social" },
  { label: "Reference", value: "reference" },
  { label: "Others", value: "others" },
];

const checkboxOptions = [
  { label: "CRM", value: "crm" },
  { label: "Marketing", value: "marketing" },
  { label: "WhatsApp Inbox", value: "inbox" },
  { label: "WhatsApp Broadcast", value: "broadcast" },
  { label: "WhatsApp Bot", value: "bot" },
  { label: "ChatBot", value: "chatBot" },
  { label: "AI Agents (Coming Soon)", value: "ai" },
];

const OrgIntro = ({
  open,
  onOk,
  onCancel,
  isLoading,
  orgInfo,
  profileInfo,
  params,
  setIsLoading,
  navigate,
  setOpenIntro,
  refreshOrgInfo,
}: any) => {
  const [request, setRequest] = useState({
    industry: "",
    company_size: "",
    known_source: "",
    product: "",
    org_id: getValue(params, "orgId", ""),
  });
  const [mockLoading, setMockLoading] = useState(false);
  const [introRequest, setIntroRequest] = useState({
    is_intro: true,
  });

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      let resp = await createOrganisationIntro(request);
      if (resp) {
        toast.success("Submitted successfully");
        setIsLoading(false);
        setOpenIntro(false);
        refreshOrgInfo();
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleIntroSubmit = async (intro: any) => {
    setIntroRequest({ is_intro: intro });
    try {
      setMockLoading(true);
      let resp = await updateOrganisations(introRequest);
      if (resp) {
        setOpenIntro(false);
        setMockLoading(false);
        refreshOrgInfo();
      } else {
        setMockLoading(false);
      }
    } catch (error) {
      setMockLoading(false);
    }
  };

  const handleChange = (value: any, field: any) => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      [field]: value,
    }));
  };

  const handleProductChange = (checkedValues: any) => {
    const productString = checkedValues.join(",");
    setRequest((prevRequest) => ({
      ...prevRequest,
      product: productString,
    }));
  };

  return (
    <Modal
      open={open}
      width={800}
      footer={null}
      className="p-0 home-intro-modal"
      closable={false}
      maskClosable={false}
    >
      <div className="intro-wrapper">
        <div className="custom-container py-4  align-items-baseline flex-column justify-content-start">
          <div className="d-flex justify-content-end">
            <button
              onClick={() => handleIntroSubmit(true)}
              className="d-flex align-items-center gap-2 intro-skip"
            >
              Skip
              <MoveRight size={18} />
            </button>
          </div>
          <h1 className="mb-1">
            Welcome, {getValue(profileInfo, "first_name", "")}!👋
          </h1>
          <p className="small_text__16">
            We'd love to know more about you to tailor your experience
          </p>
          <div className="mt-5 row">
            <div className="d-grid col-6">
              <label className="form-label">What industry are you from?</label>
              <Select
                placeholder="Select Industry"
                style={{ width: 300 }}
                size="large"
                showSearch
                optionFilterProp="labelText"
                options={industries.map((industry) => ({
                  ...industry,
                  label: industry.label,
                }))}
                onChange={(value) => handleChange(value, "industry")}
              />
            </div>
            <div className="d-grid col-6">
              <label className="form-label">How big is your company?</label>
              <Radio.Group
                size="large"
                options={options}
                // defaultValue="one"
                optionType="button"
                buttonStyle="solid"
                onChange={(e) => handleChange(e.target.value, "company_size")}
              />
            </div>
            <div className=" col-6 mt-4">
              <label className="form-label">How did you hear about Appzo</label>
              <Radio.Group
                size="large"
                options={aboutAppzo}
                // defaultValue="one1"
                // optionType="button"
                buttonStyle="solid"
                className="d-grid gap-1"
                onChange={(e) => handleChange(e.target.value, "known_source")}
              />
            </div>
            <div className="d-grid col-6 mt-4">
              <label className="form-label">
                Select the product you want to start with
              </label>
              <Checkbox.Group
                options={checkboxOptions}
                // defaultValue={["crm"]}
                onChange={handleProductChange}
                className="d-grid gap-1"
              />
            </div>
          </div>
          <div className="mt-4">
            <Button
              size="large"
              type="primary"
              loading={isLoading}
              disabled={getValue(orgInfo, "is_intro", "") === true}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OrgIntro;
