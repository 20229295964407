import "./index.scss";
import ShareSidebar from "./components/sidebar";
import { useState } from "react";
import AIAgentFullLayout from "../../Layout/full-layout";
import AIShareWebsite from "./components/website";
import AIShareWordpress from "./components/wordpress";
import AIShareURL from "./components/url";
import AIEmbed from "./components/embed";

const AIAgentShare = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <AIAgentFullLayout>
      <div className="config-container">
        <ShareSidebar activeTab={activeTab} setActiveTab={setActiveTab} />

        {activeTab === 0 && <AIEmbed />}
        {activeTab === 1 && <AIShareURL />}
        {activeTab === 2 && <AIShareWebsite />}
        {activeTab === 3 && <AIShareWordpress />}
      </div>
    </AIAgentFullLayout>
  );
};

export default AIAgentShare;
