import AntSearchToolTip from "@components/custom/DebounceSelect";
import {
  assignAgentsToWebForm,
  detachAgentsFromWebForm,
} from "@services/web-forms.service";
import { getValue } from "@utils/lodash";
import { Button, List, Modal } from "antd";
import React from "react";

const FormAgentModal = ({
  params,
  showAgentModal,
  toggleAgentModal,
  agentRequest,
  getData,
}: any) => {
  const attachAgents = async (id: any) => {
    try {
      const resp = await assignAgentsToWebForm(getValue(params, `id`, ""), {
        agents: [
          {
            id: id,
          },
        ],
      });
      if (resp) {
        getData();
      }
    } catch (error) {}
  };

  const detachAgents = async (id: string) => {
    try {
      const resp = await detachAgentsFromWebForm(getValue(params, `id`, ""), {
        agents: [
          {
            id: id,
          },
        ],
      });
      if (resp) {
        getData();
      }
    } catch (error) {}
  };

  return (
    <Modal
      open={showAgentModal}
      onCancel={toggleAgentModal}
      title="Assign Agents"
      onClose={toggleAgentModal}
      footer={false}
    >
      <AntSearchToolTip
        label="Select Team"
        lookup_api={"org_users"}
        selectKey={"id"}
        value={getValue(agentRequest, `id`, "")}
        onChange={(id: string) => {
          attachAgents(id);
        }}
      />
      <List
        className="agents-list"
        itemLayout="horizontal"
        dataSource={agentRequest}
        renderItem={(item: any) => (
          <List.Item
            actions={[
              <Button
                key="detach"
                type="link"
                onClick={() => detachAgents(getValue(item, "agent.id", ""))}
              >
                Detach
              </Button>,
            ]}
          >
            <List.Item.Meta
              title={`${getValue(item, `agent.first_name`, "")}${" "}${getValue(
                item,
                `agent.last_name`,
                ""
              )}`}
              description={
                <span>{getValue(item, `agent.user.email`, "")}</span>
              }
            />
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default FormAgentModal;
