import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

//payment-portal
export const getAllPaymentIntegrationPortals = () =>
  get(`${config.API_URL}/payments-integration/payment-portal`);

export const createPaymentIntegrationPortal = (payload: object) =>
  post(`${config.API_URL}/payments-integration/payment-portal`, payload);

export const deletePaymentIntegrationPortal = (id: string) =>
  Delete(`${config.API_URL}/payments-integration/payment-portal/${id}`);

//payment-gateway

export const getSpecificPaymentIntegrationGateway = (id: string) =>
  get(`${config.API_URL}/payments-integration/payment-gateway/${id}`);

export const createPaymentIntegrationGateway = (payload: object) =>
  post(`${config.API_URL}/payments-integration/payment-gateway`, payload);

export const updatePaymentIntegrationGateway = (id: string, payload: object) =>
  patch(
    `${config.API_URL}/payments-integration/payment-gateway/${id}`,
    payload
  );

export const deletePaymentIntegrationGateway = (id: string) =>
  Delete(`${config.API_URL}/payments-integration/payment-gateway/${id}`);

//razor-pay

export const createRazorpayPaymentLink = (payload: object) =>
  post(`${config.API_URL}/razorpay/payment-link`, payload);

export const getRazorpayPayment = (queryRequest: string) =>
  get(`${config.API_URL}/razorpay?${queryRequest}`);

export const getSpecificRazorpayPayment = (id: string) =>
  get(`${config.API_URL}/razorpay/${id}`);
