import { getValue } from "@utils/lodash";
import React from "react";

function CrmFilledSvgComponent(props: any) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
        fill={
          getValue(props, `color`, "")
            ? getValue(props, `color`, "")
            : "#000000"
        }
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <g>
          <circle cx="7" cy="14" r="3" />
          <circle cx="11" cy="6" r="3" />
          <circle cx="16.6" cy="17.6" r="3" />
        </g>
      </svg>
    </div>
  );
}

export default CrmFilledSvgComponent;
