import React, { useRef, useState, useEffect } from "react";
import { getValue } from "@utils/lodash";
import SimpleReactValidator from "simple-react-validator";
import {
  createRole,
  getAllRolePermissions,
  getSpecificRoleData,
  updateRole,
} from "@services/roles.service";
import _ from "lodash";
import { toast } from "sonner";
import { useParams, useNavigate } from "react-router-dom";
import "./roles-details.scss";
import InputRuleForm from "@components/InputRuleForm/form";
import { formatString } from "@common/text-helpers";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import {
  Button,
  Checkbox,
  Dropdown,
  List,
  Menu,
  MenuProps,
  Radio,
  Switch,
} from "antd";

import { EditOutlined } from "@ant-design/icons";
import ChevronDown from "@assets/svg/chevron-down";
import { usePermissionContext } from "@context/permissionContext";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";

interface ISettingsRoleDetailsProps {}

const SettingsRoleDetails: React.FunctionComponent<
  ISettingsRoleDetailsProps
> = (props: any) => {
  let params = useParams();
  let navigate = useNavigate();
  const { handleTriggerPermissions } = usePermissionContext();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const [request, setRequest] = React.useState({
    title: "",
    description: "",
  });

  const [dirtyFields, setDirtyFields] = useState({
    title: "",
    description: "",
  });

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    // if (props.permissionAPITriggered) {
    getData();
    if (getValue(params, `id`, "")) {
      getSpecificRole(getValue(params, `id`, ""));
    }
    // }
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [info, setInfo] = useState({});
  const getSpecificRole = async (id: string) => {
    try {
      let resp = await getSpecificRoleData(id);
      if (resp) {
        setInfo(getValue(resp, `data`, {}));
        setDirtyFields({
          ...dirtyFields,
          title: getValue(resp, `data.title`, ""),
          description: getValue(resp, `data.description`, ""),
        });
        setRequest({
          ...request,
          title: getValue(resp, `data.title`, ""),
          description: getValue(resp, `data.description`, ""),
        });
        setSelectedPermissionList(getValue(resp, `data.permissions`, []));
      }
    } catch (error) {}
  };
  const groupByTag = (data: any) => {
    return data.reduce((acc: any, item: any) => {
      const tag = item.tag;
      if (!acc[tag]) {
        acc[tag] = [];
      }
      acc[tag].push(item);
      return acc;
    }, {});
  };

  const [permissionHeaders, setPermissionHeaders] = useState<any>([]);
  // const [mainPermissions, setMainPermissions] = useState([]);
  const [allPermissions, setAllPermissions] = useState({});
  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await getAllRolePermissions("");
      if (resp) {
        let arr: any = [];
        let settingPermissions: any = {};
        let appPermissions: any = {};
        getValue(resp, `data`, []).filter((item: any) => {
          if (getValue(item, `component`, "") !== "settings") {
            if (item.is_tab_group) {
              arr.push(item);
            } else {
              arr.push({
                component: item.component,
                is_tab_group: true,
                items: [
                  { component: item.component, permissions: item.permissions },
                ],
              });
            }
            appPermissions = {
              Application: arr,
            };
          } else {
            settingPermissions = groupByTag(getValue(item, `items`, []));
            const headers = Object.keys(settingPermissions);
            if (getValue(headers, `length`, 0) > 0) {
              if (!headers.includes("Application")) {
                headers.unshift("Application");
              }
              setPermissionHeaders(headers);
            }
          }
        });
        setAllPermissions({ ...appPermissions, ...settingPermissions });
        // setMainPermissions(arr);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else if (getValue(selectedPermissionList, `length`, 0) === 0) {
      toast.error("Please select at least one permission");
      return;
    } else {
      setSubmitLoading(true);
      try {
        let payload = {
          title: request.title,
          description: request.description,
          permissions: selectedPermissionList,
        };
        if (getValue(params, `id`, "")) {
          let resp = await updateRole(getValue(params, `id`, ""), payload);
          if (resp) {
            navigate(
              `/${getValue(params, `orgId`, "")}/settings/general/roles`
            );
            toast.success("Updated successfully");
            // window.location.reload();
            setSubmitLoading(false);
            handleTriggerPermissions();
          } else {
            setSubmitLoading(false);
          }
        } else {
          let resp = await createRole(payload);
          if (resp) {
            navigate(
              `/${getValue(params, `orgId`, "")}/settings/general/roles`
            );
            toast.success("Created successfully");
            setSubmitLoading(false);
            handleTriggerPermissions();
          } else {
            setSubmitLoading(false);
          }
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                Prompt Section                              */
  /* -------------------------------------------------------------------------- */
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    setIsChanged(
      _.isEqualWith(request, dirtyFields, (a: any, b: any) => {
        // if both are null or equal to an empty string then they are equal
        if ((_.isNull(a) || a === "") && (_.isNull(b) || b === "")) return true;
      })
    );
  });

  /* -------------------------------------------------------------------------- */
  /*                         New Permission Section                             */
  /* -------------------------------------------------------------------------- */

  const [selectedPermissionList, setSelectedPermissionList] = useState<any>([]);
  // const handleSelectAll = () => {
  //   let allPermission = extractPermissions(
  //     getValue(allPermissions, `[${defaultPath}]`, [])
  //   );
  //   if (
  //     getValue(allPermission, `length`, 0) > 0 &&
  //     getValue(allPermission, `length`, 0) ===
  //       getValue(selectedPermissionList, `length`, 0)
  //   ) {
  //     setSelectedPermissionList([]);
  //   } else {
  //     setSelectedPermissionList(allPermission);
  //   }
  // };

  const handleSelectAll = () => {
    const currentPathPermissions = extractPermissions(
      getValue(allPermissions, `[${defaultPath}]`, [])
    );

    const isCurrentPathFullySelected =
      currentPathPermissions.length > 0 &&
      currentPathPermissions.every((perm: any) =>
        selectedPermissionList.includes(perm)
      );

    setSelectedPermissionList((prevSelected: any) => {
      // Remove current path's permissions if fully selected
      if (isCurrentPathFullySelected) {
        return prevSelected.filter(
          (perm: any) => !currentPathPermissions.includes(perm)
        );
      }

      // Add any permissions from current path that are not already selected
      const newSelectedPermissions = [
        ...prevSelected,
        ...currentPathPermissions.filter(
          (perm: any) => !prevSelected.includes(perm)
        ),
      ];

      return newSelectedPermissions;
    });
  };

  // const handleCheckAllStatus = () => {
  //   // debugger;
  //   let allPermission = extractPermissions(
  //     getValue(allPermissions, `[${defaultPath}]`, [])
  //   );
  //   if (
  //     getValue(allPermission, `length`, 0) > 0 &&
  //     getValue(allPermission, `length`, 0) ===
  //       getValue(selectedPermissionList, `length`, 0)
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const handleCheckAllStatus = () => {
    const currentPathPermissions = extractPermissions(
      getValue(allPermissions, `[${defaultPath}]`, [])
    );

    // Check if all permissions in current path are selected
    return (
      currentPathPermissions.length > 0 &&
      currentPathPermissions.every((perm: any) =>
        selectedPermissionList.includes(perm)
      )
    );
  };

  const extractPermissions = (data: any) => {
    const result: any = [];

    const processItem = (item: any) => {
      if (getValue(item, `value`, "")) {
        // Individual permission format
        result.push(item.value);
      } else if (getValue(item, `permissions.length`, 0) > 0) {
        // Array of permissions format
        item.permissions.forEach((permission: any) => {
          result.push(permission.value);
        });
      }

      if (getValue(item, `items.length`, 0) > 0) {
        item.items.forEach((subItem: any) => processItem(subItem));
      }
    };

    data.forEach(processItem);

    return result;
  };

  const handleChangeSelectedPermission = (data: any) => {
    let permissions: any = extractPermissions(data);
    // Create a Set to eliminate duplicates
    const uniquePermissionsSet = new Set([
      ...selectedPermissionList,
      ...permissions,
    ]);
    // Convert the Set back to an array
    const uniquePermissionsArray = Array.from(uniquePermissionsSet);
    setSelectedPermissionList(uniquePermissionsArray);
  };

  const handleChangeRemoveSelectedPermission = (data: any) => {
    let permissions: any = extractPermissions(data);
    let removedPermissions = selectedPermissionList.filter(
      (item: string) => !permissions.includes(item)
    );
    setSelectedPermissionList(removedPermissions);
  };
  const handleChangeSinglePermission = (per: string) => {
    if (selectedPermissionList.includes(per)) {
      let list = selectedPermissionList.filter((item: string) => item !== per);
      setSelectedPermissionList(list);
    } else {
      selectedPermissionList.push(per);
      setSelectedPermissionList([...selectedPermissionList]);
    }
  };
  const checkSelectedPermission = (data: any) => {
    let permissions: any = extractPermissions(data);
    const result =
      getValue(selectedPermissionList, "length", 0) > 0 &&
      permissions.some((permission: string) =>
        selectedPermissionList.includes(permission)
      );
    return result;
  };

  const [isOpen, setIsOpen] = useState(true);

  const toggleCollapse = (value: any) => {
    setIsOpen(value);
  };

  const views = [
    {
      id: "1",
      name: "Roles",
    },
  ];

  const [showDescription, setShowDescription] = useState(true);

  const [defaultPath, setDefaultPath] = useState<string>("Application");
  const onChange = (e: any) => {
    setDefaultPath(e.target.value);
  };

  return (
    <CompanyProfileDetailsLayout {...props} isLoading={isLoading}>
      <CompanyProfileSubHeader
        settings
        showSubmit
        submitText="Submit"
        handleSubmit={handleSubmit}
        isLoading={submitLoading}
        showCancel
        handleCancel={() =>
          navigate(`/${getValue(params, `orgId`, "")}/settings/general/roles`)
        }
        disabled={getValue(info, `is_sys_role`, false)}
        titleLoading={isLoading}
        title={
          getValue(request, `title`, "")
            ? getValue(request, `title`, "")
            : "Create Role"
        }
        backButton
      />
      <div className={`manageFieldRightSettings overflow-y-auto py-4`}>
        <>
          {/* <div className=" roles-detail-page-section"> */}
          <div className="rolls-wrapper">
            <div className="rolls-wrapper__form-wrapper">
              <InputRuleForm
                inputType="TEXT"
                name="name"
                value={getValue(request, `title`, "")}
                placeholder="Enter Role Name"
                required
                label="Role Name"
                onChange={(e: any) =>
                  setRequest({ ...request, title: e.target.value })
                }
                validator={simpleValidator}
                validLeft
              />
              <div className="mt-2">
                {showDescription ? (
                  <div className="d-flex gap-3 mt-3">
                    {getValue(request, `description`, "") ? (
                      getValue(request, `description`, "")
                    ) : (
                      <span
                        className="header_blue_text__14 cursor-pointer"
                        onClick={() => setShowDescription(false)}
                      >
                        Add description
                      </span>
                    )}
                    <EditOutlined onClick={() => setShowDescription(false)} />
                  </div>
                ) : (
                  <>
                    <InputRuleForm
                      inputType="TEXTAREA"
                      // rows={7}
                      placeholder="Enter Description"
                      className="form-control w-100"
                      name="description"
                      id="description"
                      value={getValue(request, `description`, "")}
                      onChange={(e: any) =>
                        setRequest({
                          ...request,
                          description: e.target.value,
                        })
                      }
                      label="Description"
                    />
                    <Button onClick={() => setShowDescription(true)}>
                      Cancel
                    </Button>
                  </>
                )}
              </div>
              <div className="rolls-wrapper__permission role-permission-padding mt-5">
                <h4 className="rolls-wrapper__permission-title mb-3">
                  Permissions
                </h4>

                <Radio.Group
                  block
                  buttonStyle="solid"
                  value={defaultPath}
                  onChange={onChange}
                >
                  {permissionHeaders.map((item: string) => {
                    return <Radio.Button value={item}>{item}</Radio.Button>;
                  })}
                </Radio.Group>
                <div className="d-flex align-items-center gap-2 mb-2 mt-4">
                  <Checkbox
                    id={`select-1-z2`}
                    onChange={() => handleSelectAll()}
                    checked={handleCheckAllStatus()}
                  >
                    Select All {defaultPath} Permissions
                  </Checkbox>
                </div>
                <p className="rolls-wrapper__permission-text">
                  Applications - Define all allowed actions for the applicable
                  tab
                </p>
              </div>

              {isLoading ? (
                <p className="text-center mt-2">Please wait....</p>
              ) : (
                <List
                  itemLayout="horizontal"
                  className="border rounded-2 px-4 py-3 mt-4 mb-5"
                  dataSource={getValue(allPermissions, `[${defaultPath}]`, [])}
                  renderItem={(item: any, index: number) => (
                    <List.Item key={index}>
                      <List.Item.Meta
                        title={
                          <div className="d-flex gap-2 mt-1 mb-2 align-items-center">
                            {formatString(getValue(item, "component", ""))}
                            <Switch
                              disabled={getValue(info, `is_sys_role`, false)}
                              id={`index1-${index}`}
                              size="small"
                              onChange={() => {
                                const isChecked = checkSelectedPermission(
                                  getValue(item, `items`, [])
                                );

                                checkSelectedPermission(
                                  getValue(item, `items`, [])
                                )
                                  ? handleChangeRemoveSelectedPermission(
                                      getValue(item, `items`, [])
                                    )
                                  : handleChangeSelectedPermission(
                                      getValue(item, `items`, [])
                                    );
                                if (isChecked) {
                                  toggleCollapse(false);
                                } else {
                                  toggleCollapse(true);
                                }
                              }}
                              checked={checkSelectedPermission(
                                getValue(item, `items`, [])
                              )}
                            />
                          </div>
                        }
                        description={
                          <div className="pb-1 position-relative">
                            {getValue(item, `is_tab_group`, false) &&
                              getValue(item, `items`, []).map(
                                (perItem: any, index2: number) => {
                                  let array =
                                    getValue(perItem, `permissions.length`, 0) >
                                    0
                                      ? getValue(perItem, `permissions`, [])
                                      : getValue(perItem, `items`, []);

                                  const permissions = getValue(
                                    perItem,
                                    `permissions`,
                                    []
                                  );

                                  const permissionMenuItems: MenuProps["items"] =
                                    permissions.map(
                                      (per: any, index3: number) => ({
                                        key: `index3-${index3}`,
                                        label: (
                                          <div
                                            className="d-flex align-items-center gap-1 role-permission-checkbox-wrap mw-70"
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <Checkbox
                                              id={`index3-${index3}`}
                                              checked={selectedPermissionList.includes(
                                                getValue(per, `value`, "")
                                              )}
                                              onChange={() =>
                                                handleChangeSinglePermission(
                                                  getValue(per, `value`, "")
                                                )
                                              }
                                              disabled={getValue(
                                                info,
                                                `is_sys_role`,
                                                false
                                              )}
                                            >
                                              {formatString(
                                                getValue(per, `text`, "")
                                              )}
                                            </Checkbox>
                                          </div>
                                        ),
                                      })
                                    );

                                  return (
                                    <div key={index2}>
                                      <div className="d-flex justify-content-between align-items-center p-2">
                                        <div className="d-flex align-items-center gap-2">
                                          <p className="form-label m-0">
                                            {formatString(
                                              getValue(perItem, `component`, "")
                                            )}
                                          </p>
                                          <Switch
                                            disabled={getValue(
                                              info,
                                              `is_sys_role`,
                                              false
                                            )}
                                            size="small"
                                            id={`index2-${index2}`}
                                            onChange={() =>
                                              checkSelectedPermission(array)
                                                ? handleChangeRemoveSelectedPermission(
                                                    array
                                                  )
                                                : handleChangeSelectedPermission(
                                                    array
                                                  )
                                            }
                                            checked={checkSelectedPermission(
                                              array
                                            )}
                                            className={`ms-2 title-wrapper ${
                                              getValue(
                                                perItem,
                                                `is_tab_group`,
                                                false
                                              )
                                                ? "color_primary"
                                                : ""
                                            }`}
                                          />
                                        </div>

                                        {permissions.length >= 1 && (
                                          <Dropdown
                                            menu={{
                                              items: permissionMenuItems,
                                            }}
                                            placement="bottomLeft"
                                            trigger={["click"]}
                                          >
                                            <a
                                              className="ant-dropdown-link d-flex align-items-center gap-1"
                                              onClick={(e) =>
                                                e.preventDefault()
                                              }
                                            >
                                              {permissions
                                                .filter((per: any) =>
                                                  selectedPermissionList.includes(
                                                    getValue(per, `value`, "")
                                                  )
                                                )
                                                .map((per: any) =>
                                                  formatString(
                                                    getValue(per, `text`, "")
                                                  )
                                                )
                                                .join(", ") ||
                                                "Select Permissions"}
                                              <ChevronDown
                                                size="10"
                                                color="#1677ff"
                                              />
                                            </a>
                                          </Dropdown>
                                        )}
                                      </div>
                                      {getValue(
                                        perItem,
                                        `is_tab_group`,
                                        false
                                      ) &&
                                        getValue(perItem, `items`, []).map(
                                          (subItem: object, index4: number) => {
                                            const permissionMenuItems =
                                              getValue(
                                                subItem,
                                                `permissions`,
                                                []
                                              ).map(
                                                (
                                                  perSub: any,
                                                  index5: number
                                                ) => ({
                                                  key: `sub-index5-${index5}`,
                                                  label: (
                                                    <div
                                                      className="d-flex align-items-center gap-1 role-permission-checkbox-wrap mw-70"
                                                      onClick={(e) =>
                                                        e.stopPropagation()
                                                      }
                                                    >
                                                      <Checkbox
                                                        id={`sub-index5-${index5}`}
                                                        checked={selectedPermissionList.includes(
                                                          getValue(
                                                            perSub,
                                                            `value`,
                                                            ""
                                                          )
                                                        )}
                                                        onChange={() =>
                                                          handleChangeSinglePermission(
                                                            getValue(
                                                              perSub,
                                                              `value`,
                                                              ""
                                                            )
                                                          )
                                                        }
                                                        disabled={getValue(
                                                          info,
                                                          `is_sys_role`,
                                                          false
                                                        )}
                                                      >
                                                        {formatString(
                                                          getValue(
                                                            perSub,
                                                            `text`,
                                                            ""
                                                          )
                                                        )}
                                                      </Checkbox>
                                                    </div>
                                                  ),
                                                })
                                              );
                                            const selectedLabels =
                                              getValue(
                                                subItem,
                                                `permissions`,
                                                []
                                              )
                                                .filter((per: any) =>
                                                  selectedPermissionList.includes(
                                                    getValue(per, `value`, "")
                                                  )
                                                )
                                                .map((per: any) =>
                                                  formatString(
                                                    getValue(per, `text`, "")
                                                  )
                                                )
                                                .join(", ") ||
                                              "Select Permissions";
                                            return (
                                              <div className="d-flex justify-content-between align-items-center  p-2 ml-32">
                                                <div className="d-flex align-items-center title-checkbox">
                                                  <Checkbox
                                                    id={`index4-${index4}`}
                                                    className="ps-5"
                                                    onChange={() =>
                                                      checkSelectedPermission(
                                                        getValue(
                                                          subItem,
                                                          `permissions`,
                                                          []
                                                        )
                                                      )
                                                        ? handleChangeRemoveSelectedPermission(
                                                            getValue(
                                                              subItem,
                                                              `permissions`,
                                                              []
                                                            )
                                                          )
                                                        : handleChangeSelectedPermission(
                                                            getValue(
                                                              subItem,
                                                              `permissions`,
                                                              []
                                                            )
                                                          )
                                                    }
                                                    checked={checkSelectedPermission(
                                                      getValue(
                                                        subItem,
                                                        `permissions`,
                                                        []
                                                      )
                                                    )}
                                                    disabled={getValue(
                                                      info,
                                                      `is_sys_role`,
                                                      false
                                                    )}
                                                  >
                                                    {formatString(
                                                      getValue(
                                                        subItem,
                                                        `component`,
                                                        ""
                                                      )
                                                    )}
                                                  </Checkbox>
                                                </div>
                                                <Dropdown
                                                  overlay={
                                                    <Menu
                                                      items={
                                                        permissionMenuItems
                                                      }
                                                    />
                                                  }
                                                  placement="bottomLeft"
                                                  trigger={["click"]}
                                                >
                                                  <a
                                                    className="ant-dropdown-link d-flex align-items-center gap-1"
                                                    onClick={(e) =>
                                                      e.preventDefault()
                                                    }
                                                  >
                                                    {selectedLabels}
                                                    <ChevronDown
                                                      size="10"
                                                      color="#1677ff"
                                                    />
                                                  </a>
                                                </Dropdown>
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                  );
                                }
                              )}
                            <div></div>
                          </div>
                        }
                      />
                    </List.Item>
                  )}
                />
              )}
              <br />
            </div>
            {/* </div> */}
          </div>
        </>
      </div>
    </CompanyProfileDetailsLayout>
  );
};

export default SettingsRoleDetails;
