import CheckCircleSvgComponent from "@assets/svg/check-circle";
import { useState } from "react";
import PrinterSvgComponent from "@assets/svg/printer";
import RecieptSvgComponent from "@assets/svg/reciept";
import MoneySvgComponent from "@assets/svg/money";
import AdsSvgCompnent from "@assets/svg/ads";
import TimerSvgComponent from "@assets/svg/timer";
import RemoveCircleSvgComponent from "@assets/svg/remove-circle";
import CloseMainSvgComponent from "@assets/svg/close-main";
import LockSvgComponent from "@assets/svg/lock";
import CalenderPlusSvgComponent from "@assets/svg/calender-plus";
import CreditCardSvgComponent from "@assets/svg/credit-card";
import CalendarSvgComponent from "@assets/svg/calender";
import MappingSvgComponent from "@assets/svg/mapping";
import {
  deletePropertyBooking,
  propertyBookingCheckin,
  propertyBookingCheckout,
  recordPayment,
} from "@services/properties/property-booking.service";
import { getValue } from "@utils/lodash";
import { toast } from "sonner";
import { releaseReservationInventory } from "@services/properties/reservation.service";
import { useNavigate, useParams } from "react-router-dom";
import { allParams } from "@common/url-params-helper";
import SettlePayment from "./settle-payment";
import { QueryRequestHelper } from "@common/query-request-helper";
import { Modal } from "antd";

const MoreBookingDetails = (props: any) => {
  const allOptions = [
    {
      icon: (
        <CheckCircleSvgComponent
          color={
            getValue(
              props,
              "bookingInfo.room_booking_info[0].booking_status",
              ""
            ) !== "confirmed" && "#aeaeae"
          }
        />
      ),
      name: "Check in ",
      api: "checkin",
      disabled:
        getValue(
          props,
          "bookingInfo.room_booking_info[0].booking_status",
          ""
        ) !== "confirmed",
    },
    {
      icon: (
        <RemoveCircleSvgComponent
          color={
            getValue(
              props,
              "bookingInfo.room_booking_info[0].booking_status",
              ""
            ) !== "checked_in" && "#aeaeae"
          }
        />
      ),
      name: "Check out",
      api: "checkout",
      disabled:
        getValue(
          props,
          "bookingInfo.room_booking_info[0].booking_status",
          ""
        ) !== "checked_in",
    },
    {
      icon: <RecieptSvgComponent color={"#408dfb"} />,
      name: "View folio",
      api: "folio",
    },
    {
      icon: (
        <MoneySvgComponent
          color={
            getValue(
              props,
              "bookingInfo.room_booking_info[0].booking_status",
              ""
            ) === "enquiry" ||
            getValue(
              props,
              "bookingInfo.room_booking_info[0].booking_status",
              ""
            ) === "checked_out" ||
            getValue(
              props,
              "bookingInfo.room_booking_info[0].balance_due",
              ""
            ) === 0
              ? "#aeaeae"
              : "#3C4043"
          }
        />
      ),
      name: "Settle dues",
      api: "record_payment",
      disabled:
        getValue(
          props,
          "bookingInfo.room_booking_info[0].booking_status",
          ""
        ) === "enquiry" ||
        getValue(
          props,
          "bookingInfo.room_booking_info[0].booking_status",
          ""
        ) === "checked_out" ||
        getValue(props, "bookingInfo.room_booking_info[0].balance_due", "") ===
          0,
    },
    {
      icon: <RemoveCircleSvgComponent />,
      name: "Remove booking",
      api: "remove",
      disabled:
        getValue(
          props,
          "bookingInfo.room_booking_info[0].booking_status",
          ""
        ) === "checked_out",
    },
    // {
    //   icon: <PrinterSvgComponent />,
    //   name: "Print reg card",
    //   disabled: true,
    // },
    // {
    //   icon: <AdsSvgCompnent />,
    //   name: "Send magic link",
    //   disabled: true,
    // },
    // {
    //   icon: <TimerSvgComponent />,
    //   name: "Hold booking",
    //   disabled: true,
    // },

    // {
    //   icon: <CloseMainSvgComponent />,
    //   name: "Set to no show",
    //   disabled: true,
    // },
    // {
    //   icon: <LockSvgComponent />,
    //   name: "Lock booking",
    //   disabled: true,
    // },
    // {
    //   icon: <PrinterSvgComponent />,
    //   name: "Unassign room",
    //   disabled: true,
    // },
    // {
    //   icon: <CalenderPlusSvgComponent />,
    //   name: "Modify checkin",
    //   disabled: true,
    // },
    // {
    //   icon: <CreditCardSvgComponent />,
    //   name: "Modify checkout",
    //   disabled: true,
    // },
    // {
    //   icon: <CalendarSvgComponent />,
    //   name: "Edit reservation",
    //   disabled: true,
    // },
    // {
    //   icon: <MappingSvgComponent />,
    //   name: "Move room",
    //   disabled: true,
    // },
  ];

  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */
  // const [request, setRequest] = useState({
  //   amount: 0,
  //   payment_method: "cash",
  // });
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const params = useParams();
  const navigate = useNavigate();
  const handleClickFeature = (name: string) => {
    switch (name) {
      case "checkin":
        return handleCheckin();
      case "checkout":
        return handleCheckout();
      case "release":
        return handleReleaseInventory();
      case "record_payment":
        return toggleCheckin();
      case "folio":
        return handleNavigate();
      case "remove":
        return handleDeleteBooking();
      default:
        return null;
    }
  };

  const handleNavigate = () => {
    navigate(
      `/${getValue(params, `orgId`, "")}/property/details/${getValue(
        params,
        `id`,
        ""
      )}/folio/${getValue(props, `bookingInfo.id`, "")}?${allParams()}`
    );
  };

  const handleCheckin = async () => {
    try {
      let resp = await propertyBookingCheckin(
        getValue(props, `bookingInfo.room_booking_info[0].id`, "")
      );
      if (resp) {
        toast.success("Checked in successfully");
        props.setUpdatingFlag(!props.updatingFlag);
        if (props.toggleBookingInfo) {
          props.toggleBookingInfo();
          props.getAllRoomList("");
        }
      }
    } catch (error) {}
  };
  const handleCheckout = async () => {
    try {
      let resp = await propertyBookingCheckout(
        getValue(props, `bookingInfo.room_booking_info[0].id`, "")
      );
      if (resp) {
        toast.success("Check out successfully");
        props.setUpdatingFlag(!props.updatingFlag);
        if (props.toggleBookingInfo) {
          props.toggleBookingInfo();
          props.getAllRoomList("");
        }
      }
    } catch (error) {}
  };
  const handleReleaseInventory = async () => {
    try {
      let resp = await releaseReservationInventory(
        getValue(props, `bookingInfo.room_booking_info[0].id`, "")
      );
      if (resp) {
        toast.success("Updated successfully");
      }
    } catch (error) {}
  };

  const handleDeleteBooking = () => {
    Modal.confirm({
      title: `Are you sure you want to delete the booking?`,
      content: "Once deleted, this booking cannot be recovered.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          let payload = {
            room_booking_info_id: getValue(
              props,
              `bookingInfo.room_booking_info[0].id`,
              ""
            ),
          };
          let queryRequest = QueryRequestHelper(payload);
          let resp = await deletePropertyBooking(
            getValue(props, `bookingInfo.booking_id`, ""),
            queryRequest
          );
          if (resp) {
            toast.success("Deleted successfully");
            if (props.toggleBookingInfo) {
              props.toggleBookingInfo();
              props.getAllRoomList("");
            } else
              navigate(
                `/${getValue(params, "orgId", "")}/property/details/${getValue(
                  params,
                  "id",
                  ""
                )}/reservations`
              );
          }
        } catch (error) {
          toast.error("Error deleting booking");
        }
      },
    });
  };

  // const [loading, setLoading] = useState(false);
  // const handleRecordPayment = async () => {
  //   try {
  //     const payload: any = JSON.parse(JSON.stringify(request));
  //     payload["amount"] = parseFloat(getValue(request, `amount`, 0));
  //     setLoading(true);
  //     let resp = await recordPayment(
  //       getValue(props, `bookingInfo.id`, ""),
  //       request
  //     );
  //     if (resp) {
  //       toast.success("Updated successfully");
  //       setLoading(false);
  //       toggleCheckin();
  //     } else {
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     toggleCheckin();
  //   }
  // };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const [openCheckin, setOpenCheckin] = useState(false);
  const toggleCheckin = () => {
    setOpenCheckin(!openCheckin);
  };

  return (
    <div className="mt-5">
      <div className="more-options-container gap-3 ">
        {allOptions.map((options, index) => (
          <button
            className={`more-options-card  ${
              !options.disabled && "touchable-text"
            }`}
            key={index}
            onClick={() => handleClickFeature(getValue(options, `api`, ""))}
            disabled={options.disabled}
          >
            {options.icon}
            {options.name}
          </button>
        ))}
      </div>
      <SettlePayment
        openCheckin={openCheckin}
        toggleCheckin={toggleCheckin}
        bookingInfo={props.bookingInfo}
        updatingFlag={props.updatingFlag}
        setUpdatingFlag={props.setUpdatingFlag}
        permissions={getValue(props, `permissions`, [])}
      />
    </div>
  );
};

export default MoreBookingDetails;
