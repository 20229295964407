import * as React from "react";
import { useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import Loader from "@components/common/Loader/loading";
import { QueryRequestHelper } from "common/query-request-helper";
import _ from "lodash";
import { toast } from "sonner";
import { allPipelines, getSpecificPipeline } from "@services/pipeline.service";
import SimpleReactValidator from "simple-react-validator";
import {
  associationSubPipeline,
  disAssociationSubPipeline,
} from "@services/common.service";
import { useStateContext } from "@context/dataContext";
import PendingTaskRight from "./right-container";
import { createTask } from "@services/tasks.service";
import { getAllModuleFields } from "@services/module-fields.service";
import CommonModulesDetailRightPagePage from "./right-container";
import TaskPendingTaskRight from "./right-container/TaskPendingTaskRight";
import ListLoader from "@components/common/Loader";
import LoaderMain from "@components/common/Loader/loading1";

// eslint-disable-next-line @typescript-eslint/no-empty-interface

const TasksRightSectionDetail = (props: any) => {
  const params = useParams();
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  const { userId, selectedModuleId, modules, allModules } = useStateContext();
  const { data, getData, isLoading } = props;

  /* -------------------------------------------------------------------------- */
  /*                               Right Section                                */
  /* -------------------------------------------------------------------------- */

  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [, forceUpdate] = React.useState(0);
  const [form, setForm] = React.useState([]);
  const [formLoading, setFormLoading] = React.useState(false);

  const getForm = async (module: string) => {
    setForm([]);
    if (
      module !== getValue(props, `module`, "")
        ? getValue(props, `module`, "")
        : "tasks"
    ) {
      try {
        let payload = {
          module_name: module,
        };
        let queryRequest = QueryRequestHelper(payload);
        setFormLoading(true);
        let findId =
          getValue(modules, `length`, 0) > 0
            ? modules.find(
                (item: object) =>
                  getValue(item, `api_name`, "") ===
                  (module === "sales" ? "deals" : module)
              )
            : [];
        let resp = await allPipelines(getValue(findId, `id`, ""), queryRequest);
        if (resp) {
          getSpecificPipelineInfo(getValue(resp, `data[${0}].id`, ""));
          // setPipelineList(
          //   getValue(resp, `data`, []).map((item: object) => ({
          //     id: getValue(item, `id`, ""),
          //     module_name: getValue(item, `module_name`, ""),
          //     module_id: getValue(item, `module_id`, ""),
          //     name: getValue(item, `name`, ""),
          //     label: getValue(item, `name`, ""),
          //     value: getValue(item, `id`, ""),
          //     org_id: getValue(item, `org_id`, ""),
          //   }))
          // );
          setFormLoading(false);
        } else {
          setFormLoading(false);
        }
      } catch (error) {
        setFormLoading(false);
      }
    } else {
      try {
        let code =
          getValue(modules, `length`, 0) > 0
            ? modules.find(
                (item: object) => getValue(item, `api_name`, "") == module
              )
            : [];
        let resp = await getAllModuleFields(getValue(code, `id`, ""));
        if (resp) {
          let fields =
            getValue(resp, `data.length`, 0) > 0
              ? getValue(resp, `data`, []).map((item: object) => ({
                  ...getValue(item, `module_field`, {}),
                  name: getValue(item, `module_field.api_name`, ""),
                  quick_add: getValue(item, `quick_add`, ""),
                  required: getValue(item, `required`, ""),
                  seq_num: getValue(item, `seq_num`, ""),
                }))
              : [];
          for (const item of fields) {
            if (item.api_name === "owner_id") {
              item.value = userId;
            }
          }
          setForm(fields);
        }
        // getPredefinedModuleFields(getValue(resp,`data`,[]))
      } catch (error) {}
    }
  };

  /* ---------------------------  Pipeline By ID  -------------------------------- */
  const [staticFieldRequest, setStaticFieldRequest] = React.useState({
    pipeline_id: "",
    stage: "",
  });
  const [stageList, setStageList] = React.useState<any>([]);
  const getSpecificPipelineInfo = async (id: string) => {
    if (id) {
      try {
        let resp = await getSpecificPipeline(await selectedModuleId(), id);
        if (resp) {
          let fields =
            getValue(resp, `data.form_fields.length`, 0) > 0
              ? getValue(resp, `data.form_fields`, []).map((item: object) => ({
                  ...getValue(item, `module_field`, {}),
                  name: getValue(item, `name`, ""),
                  quick_add: getValue(item, `quick_add`, ""),
                  required: getValue(item, `required`, ""),
                  seq_num: getValue(item, `seq_num`, ""),
                }))
              : [];
          for (const item of fields) {
            if (item.api_name === "owner_id") {
              item.value = userId;
            }
          }
          setForm(fields);
          setStageList(
            getValue(resp, `data.stages`, []).map((item: object) => ({
              ...item,
              label: getValue(item, `label`, ""),
              value: getValue(item, `name`, ""),
            }))
          );
          setStaticFieldRequest((prevStaticFieldRequest) => ({
            ...prevStaticFieldRequest,
            stage: getValue(resp, `data.stages[${0}].id`, ""),
            pipeline_id: id,
          }));

          setFormLoading(false);
        } else {
          setFormLoading(false);
        }
      } catch (error) {}
    } else {
      setForm([]);
    }
  };
  /* ---------------------------  Association / DisAssociate Pipeline  --------------------- */

  const handleAssociate = async (id: string, module: string) => {
    if (module) {
      let payload = {
        api_name: module,
        record_id: id,
      };
      let resp = await associationSubPipeline(
        getValue(props, `module`, "") ? getValue(props, `module`, "") : "tasks",
        getValue(params, `id`, ""),
        payload
      );
      if (resp) {
        getData(false);
        toast.success("Associated successfully");
      }
    }
  };
  const handleDisAssociate = async (id: string, module: string) => {
    if (module) {
      let payload = {
        api_name: module,
        record_id: id,
      };
      let resp = await disAssociationSubPipeline(
        getValue(props, `module`, "") ? getValue(props, `module`, "") : "tasks",
        getValue(params, `id`, ""),
        payload
      );
      if (resp) {
        getData(false);
        toast.success("Disassociated successfully");
      }
    }
  };
  return (
    <div>
      {isLoading ? (
        <LoaderMain />
      ) : (
        <>
          <TaskPendingTaskRight
            data={data}
            form={form}
            setForm={setForm}
            formLoading={formLoading}
            handleAssociate={handleAssociate}
            handleDisAssociate={handleDisAssociate}
            getForm={getForm}
            module={
              getValue(props, `module`, "")
                ? getValue(props, `module`, "")
                : "tasks"
            }
            stageList={stageList}
            staticFieldRequest={staticFieldRequest}
            setStaticFieldRequest={setStaticFieldRequest}
            getSpecificPipelineInfo={getSpecificPipelineInfo}
            getData={getData}
            permissions={getValue(props, `permissions`, [])}
            allModules={allModules}
          />
        </>
      )}
    </div>
  );
};

export default TasksRightSectionDetail;
