import AddCircleSvgComponent from "@assets/svg/add-circle";
import { getValue, setValue } from "@utils/lodash";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { handleSelectArrayChange } from "@common/handleChange";
import RemoveCircleSvgComponent from "@assets/svg/remove-circle";
import { ArrowDownUp, Settings } from "lucide-react";
import { Alert, Tag } from "antd";
import ConditionsFormBuilder from "./ConditionsFormBuilder";

function ConditionFields(props: any) {
  const { request, setRequest, workflowConditions, validator, isTemplate } =
    props;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const addCondition = () => {
    if (getValue(request, `conditions.length`, 0) < 10) {
      let obj = {
        action: "add",
        fieldId: "",
        operator: "",
        value: [],
        pattern:
          getValue(request, `conditions.length`, 0) === 0 ? "WHEN" : "AND",
      };
      request.conditions.push(obj);
      setRequest({ ...request });
    }
  };
  const removeCondition = (index: number) => {
    let array = getValue(request, `conditions`, []);
    let filtered = array.filter((item: object, ind: number) => ind !== index);
    setRequest({
      ...request,
      conditions: filtered,
    });
  };
  const removeConditionUpdate = (index: number) => {
    setValue(request, `conditions[${index}].action`, "remove");
    setRequest({ ...request });
  };
  const getCriteria = () => {
    let condtions = getValue(request, `conditions`, []).filter(
      (item: object) => getValue(item, `action`, "") !== "remove"
    );
    return generateExpressions(condtions);
  };

  const generateExpressions = (array: any) => {
    let arr = getFilteredArray(array).map((item: any, index: number) => ({
      ...item,
      index: index,
    }));
    const logicalOperators: any = {
      AND: " AND ",
      OR: " OR ",
    };
    const stack: any = [];
    let expression = "";
    for (const obj of arr) {
      const name = obj.pattern;
      if (logicalOperators[name]) {
        let obje = { operator: logicalOperators[name], index: obj.index };
        stack.unshift(obje);
      } else {
        if (stack.length > 0) {
          const { operator, index } = stack.pop();
          const prevExpression = expression;
          expression = `( ${prevExpression} ${operator}${index + 1} )`;
        } else {
          expression += `${obj.index + 1}`;
        }
      }
    }
    while (stack.length > 0) {
      const { operator, index } = stack.pop();
      const prevExpression = expression;
      expression = `( ${prevExpression} ${operator}${index + 1} )`;
    }

    if (getValue(request, `pattern`, "") !== expression) {
      setRequest({
        ...request,
        pattern: expression,
      });
    }
    return expression;
  };
  const getFilteredArray = (array: any) => {
    if (getValue(array, `length`, 0) > 0) {
      let selectedArray = array.filter(
        (item: object) => getValue(item, `action`, "") !== "REMOVE"
      );
      return selectedArray;
    } else {
      return [];
    }
  };
  const findConditions = (id: string) => {
    let filteredConditions = workflowConditions.find(
      (item: object) => getValue(item, `fieldId`, "") === id
    );

    return getValue(filteredConditions, `conditions`, []).map(
      (item: object) => ({
        label: getValue(item, `label`, ""),
        value: getValue(item, `operator`, ""),
        operator: getValue(item, `operator`, ""),
      })
    );
  };
  const findInputType = (id: string) => {
    let filteredConditions = workflowConditions.find(
      (item: object) => getValue(item, `fieldId`, "") === id
    );
    let index = workflowConditions.findIndex(
      (item: object) => getValue(item, "fieldId", "") === id
    );
    return { index: index, ...filteredConditions };
  };
  const getFilteredConditions = () => {
    let condtions = getValue(request, `conditions`, []).filter(
      (item: object) => getValue(item, `action`, "") !== "remove"
    );
    return getValue(condtions, `length`, 0);
  };

  const filteredConditions = () => {
    let list =
      getValue(request, `conditions.length`, 0) > 0
        ? getValue(request, `conditions`, []).filter(
            (item: object) => getValue(item, `action`, "") !== "remove"
          )
        : [];
    return list;
  };

  return (
    <div className="mt-5">
      <h6 className="header_text__18 mb-3 d-flex align-items-center gap-2">
        <Tag color="orange" bordered={false}>
          <Settings size={16} />
        </Tag>
        Conditions
      </h6>
      <div className="sla-condition-card-wrapper">
        {filteredConditions().map((item: object, index: number) => {
          const alerts: {
            condition: boolean;
            message: string;
            type: "warning" | "info" | "success" | "error";
            closable?: boolean;
          }[] = [
            {
              condition: [
                "last_agent_response_date",
                "resolved_date",
                "waiting_on_customer",
                "created_at",
              ].includes(getValue(item, "fieldId", "")),
              message:
                "Hourly trigger rules run once every hour. Once the conditions match, this rule will be executed within an hour.",
              type: "warning",
              closable: false,
            },
            {
              condition:
                isTemplate &&
                getValue(request, "type", "") === "ticket_creation_rules",
              message:
                'You can change this to group(s) of your choice. You can also choose different ticket properties like "Type" or "Product".',
              type: "info",
              closable: true,
            },
            {
              condition:
                isTemplate &&
                getValue(item, "fieldId", "") === "waiting_on_customer",
              message:
                'The status "Waiting on customer" is commonly used to map tickets for which customer input is needed. If you use a different status, modify the above condition.',
              type: "info",
              closable: true,
            },
          ];
          return (
            <div className="sla-condition-card p-4 border-bottom">
              <div>
                <div className="d-flex align-items-center gap-3 flex-wrap">
                  <div className="criteria-fit">
                    <div className="criteria-count">{index + 1}</div>
                  </div>
                  <div className="d-flex align-items-center gap-3 ">
                    <p className="small_text__14">If</p>
                    <SearchToolTip
                      width={220}
                      label="label"
                      selectKey={"id"}
                      data={getValue(props, `workflowOptions`, [])}
                      value={getValue(item, `fieldId`, "")}
                      onChange={(e: any) => {
                        handleSelectArrayChange(
                          index,
                          e,
                          "fieldId",
                          "conditions",
                          setRequest
                        );
                        setRequest((prev: any) => ({
                          ...prev,
                          conditions: prev.conditions.map(
                            (condition: any, i: number) => {
                              if (i === index) {
                                return {
                                  ...condition,
                                  index: i + 1,
                                };
                              }
                              return condition;
                            }
                          ),
                        }));
                        // handleTextNestedArrayChange(
                        //   index,
                        //   "value",
                        //   0,
                        //   null,
                        //   "conditions",
                        //   setRequest
                        // );
                      }}
                    />
                  </div>

                  <SearchToolTip
                    width={220}
                    label="label"
                    selectKey={"value"}
                    value={getValue(item, `operator`, "")}
                    data={findConditions(getValue(item, `fieldId`, ""))}
                    onChange={(e: any) => {
                      handleSelectArrayChange(
                        index,
                        e,
                        "operator",
                        "conditions",
                        setRequest
                      );
                    }}
                    disabled={!getValue(item, `fieldId`, "")}
                  />
                  <div className="">
                    <ConditionsFormBuilder
                      item={findInputType(getValue(item, `fieldId`, ""))}
                      automationInfo={item}
                      parentIndex={index}
                      request={request}
                      setRequest={setRequest}
                      value={getValue(item, `value`, [])}
                    />
                  </div>
                  {(getValue(item, `fieldId`, "") === "created_at" ||
                    getValue(item, `fieldId`, "") ===
                      "last_agent_response_date" ||
                    getValue(item, `fieldId`, "") === "resolved_date" ||
                    getValue(item, `fieldId`, "") ===
                      "waiting_on_customer") && (
                    <p className="small_text__14">hrs</p>
                  )}

                  {validator &&
                    (validator.current.message(
                      "conditions",
                      getValue(item, `fieldId`, ""),
                      "required"
                    ) ||
                      validator.current.message(
                        "operator",
                        getValue(item, `operator`, ""),
                        "required"
                      ) ||
                      validator.current.message(
                        "value",
                        getValue(item, `value`, ""),
                        "required"
                      )) && (
                      <p className={`error-text ps-2 pb-2`}>
                        All fields are required
                      </p>
                    )}
                </div>
                {alerts.map(
                  ({ condition, message, type, closable }, index) =>
                    condition && (
                      <Alert
                        key={index}
                        message={message}
                        type={type}
                        showIcon
                        className="mt-3"
                        closable={closable}
                      />
                    )
                )}
              </div>
              <div className="cursor-pointer ms-4 d-flex gap-3 align-items-center ">
                {(index > 0 || getFilteredConditions() === 1) && (
                  <button
                    onClick={() =>
                      getValue(item, `id`, "")
                        ? removeConditionUpdate(index)
                        : removeCondition(index)
                    }
                    className="text-danger button-unset-all"
                    disabled={index === 0}
                  >
                    <RemoveCircleSvgComponent />
                  </button>
                )}

                {filteredConditions().length - 1 === index && (
                  <div onClick={addCondition} className="text-success">
                    <AddCircleSvgComponent />
                  </div>
                )}
              </div>

              {index !== 0 && (
                <div
                  className=" multi-condition-button  d-flex align-items-center gap-1"
                  onClick={() =>
                    setRequest((object: any) => {
                      return {
                        ...object,
                        ["conditions"]: object["conditions"].map(
                          (x: any, i: number) => {
                            if (i !== index) return x;
                            return {
                              ...x,
                              pattern:
                                getValue(item, `pattern`, "") === "AND"
                                  ? "OR"
                                  : "AND",
                            };
                          }
                        ),
                      };
                    })
                  }
                >
                  {getValue(item, `pattern`, "")}
                  <ArrowDownUp size={16} />
                </div>
              )}
            </div>
          );
        })}
        <div className="d-flex justify-content-between align-items-center gap-3 px-4 py-3">
          {getValue(request, `conditions.length`, 0) > 1 && (
            <p className="header_text__15">{getCriteria()}</p>
          )}
        </div>

        {getValue(request, `conditions.length`, 0) === 0 && (
          <div
            className="d-flex align-items-center mb-2 cursor-pointer"
            onClick={addCondition}
          >
            <AddCircleSvgComponent color={"#1a73e8"} />
            <h6 className="header_blue_text__18 ms-2">Add Condition</h6>
          </div>
        )}
      </div>
      {/* {getValue(request, `conditions.length`, 0) < 2 && (
        <Alert
          showIcon
          type="info"
          message="Choose atleast 1 condition."
          className="w-fit-content mt-2"
        />
      )} */}
    </div>
  );
}

export default ConditionFields;
