import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

interface BackButtonProps {
  className?: string;
  route?: string;
  handleBack?: () => void;
  icon?: React.ReactNode;
}

const BackButton: React.FC<BackButtonProps> = ({
  className = "",
  route,
  handleBack,
  icon,
}) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    if (handleBack) {
      handleBack();
    } else if (route) {
      navigate(route);
    } else {
      window.history.back();
    }
  };

  return (
    <Button
      size="small"
      color="default"
      variant="text"
      onClick={handleGoBack}
      className={`${className}`}
      icon={
        icon ? (
          icon
        ) : (
          <img
            src="/images/icons/back-icon.svg"
            width={24}
            height={24}
            alt="back"
          />
        )
      }
    />
  );
};

export default BackButton;
