import { useState, useEffect } from "react";
import {
  Table,
  Button,
  Typography,
  Input,
  Row,
  Col,
  Pagination,
  Form,
  InputNumber,
  Tooltip,
  Modal,
} from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  SaveOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import { updateSale } from "@services/deals.service";
import { Save } from "lucide-react";
import { toast } from "sonner";
import NewSearchSelect from "@components/custom/Dropdown/AntDropDown";
import AntSearchToolTip from "@components/custom/DebounceSelect";

const { Text } = Typography;

interface Product {
  key?: string;
  id?: string;
  action: string;
  name?: string;
  list_price: number;
  quantity: number;
  discount: number;
  total: number;
  sale_item_id: string;
}

export default function DealProductFormModel(props: any) {
  const [form] = Form.useForm();
  const { products: initialProducts, visible, onCancel } = props;
  const [products, setLocalProducts] = useState<Product[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  useEffect(() => {
    if (initialProducts) {
      const prod = initialProducts.map((e: any) => {
        const unitPrice =
          Number(getValue(e, "unit_price", 0)) ||
          Number(getValue(e, "list_price", 0)) ||
          0;
        const quantity = Number(getValue(e, "quantity", 1));
        const discount = Number(getValue(e, "discount", 0));

        return {
          ...e,
          id: getValue(e, "id", ""),
          sale_item_id: getValue(e, "sale_item_id", ""),
          list_price: unitPrice,
          quantity: quantity,
          discount: discount,
          total: calculateTotal(unitPrice, quantity, discount),
        };
      });

      setLocalProducts(prod);
      filterProducts(prod, searchQuery);
    }
  }, [initialProducts, props.data]);

  const filterProducts = (products: Product[], query: string) => {
    if (!query.trim()) {
      // Only show products that aren't marked as deleted
      const activeProducts = products.filter(
        (product) => product.action !== "remove"
      );
      setFilteredProducts(activeProducts);
      return;
    }
    const filtered = products.filter(
      (product) =>
        product.action !== "remove" &&
        ((product.name &&
          product.name.toLowerCase().includes(query.toLowerCase())) ||
          (product.sale_item_id &&
            product.sale_item_id.toString().includes(query)))
    );
    setFilteredProducts(filtered);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchQuery(value);
    filterProducts(products, value);
    setCurrentPage(1);
  };

  const handlePageChange = (page: number, size: number) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  // Check if product already exists to prevent duplicates
  const isProductDuplicate = (saleItemId: string): boolean => {
    return products
      .filter((product) => product.action !== "remove")
      .some(
        (product) => product.sale_item_id === saleItemId && saleItemId !== ""
      );
  };

  const addProduct = () => {
    // Generate a unique temporary ID for new products
    const tempId = `temp_${Date.now()}`;

    const newProduct = {
      action: "add",
      list_price: 0,
      quantity: 1,
      discount: 0,
      total: 0,
      sale_item_id: tempId,
    };

    const updatedProducts = [...products, newProduct];
    setLocalProducts(updatedProducts);
    filterProducts(updatedProducts, searchQuery);
  };

  const removeProduct = (index: number) => {
    const originalIndex = getOriginalIndex(index);

    if (originalIndex === -1) return; // Guard clause

    const updatedProducts = [...products];
    const productToRemove = updatedProducts[originalIndex];

    if (productToRemove.id) {
      // If product has an ID, mark it for removal but keep in array
      updatedProducts[originalIndex] = {
        ...productToRemove,
        action: "remove",
      };
    } else {
      // If no ID (new product), remove from array completely
      updatedProducts.splice(originalIndex, 1);
    }

    setLocalProducts(updatedProducts);
    filterProducts(updatedProducts, searchQuery);
  };

  const updateProductSelect = (index: number, field: any, e: any) => {
    // Get the sale_item_id from the selection
    const selectedSaleItemId = getValue(e, "id", "");

    // Check if this product is already in the list
    if (isProductDuplicate(selectedSaleItemId)) {
      toast.error("This product is already in your list");
      return;
    }

    const originalIndex = getOriginalIndex(index);
    if (originalIndex === -1) return;

    const updatedProducts = [...products];
    const price = Number(getValue(e, "unit_price", 0));
    const qty = Number(getValue(e, "quantity", 1)) || 1;
    const disc = Number(getValue(e, "discount", 0));

    updatedProducts[originalIndex] = {
      ...updatedProducts[originalIndex],
      sale_item_id: selectedSaleItemId,
      name: getValue(e, "label", ""),
      list_price: price,
      quantity: qty,
      discount: disc,
      total: calculateTotal(price, qty, disc),
    };

    setLocalProducts(updatedProducts);
    filterProducts(updatedProducts, searchQuery);

    // Update form field values
    form.setFieldsValue({
      [`list_price_${selectedSaleItemId}`]: price,
      [`quantity_${selectedSaleItemId}`]: qty,
      [`discount_${selectedSaleItemId}`]: disc,
    });
  };

  const calculateTotal = (price: number, qty: number, disc: number): number => {
    const numPrice = Number(price) || 0;
    const numQty = Number(qty) || 1;
    const numDisc = Number(disc) || 0;

    // Ensure discount is treated as a percentage (between 0-100)
    const safeDiscount = Math.max(0, Math.min(100, numDisc));
    const discountMultiplier = 1 - safeDiscount / 100;

    return Number((numPrice * numQty * discountMultiplier).toFixed(2));
  };

  const updateProduct = (
    index: number,
    field: string,
    value: number | string
  ) => {
    const originalIndex = getOriginalIndex(index);
    if (originalIndex === -1) return;

    const updatedProducts = [...products];
    const currentProduct = updatedProducts[originalIndex];

    // Sanitize and validate the input value
    let sanitizedValue: number;

    if (field === "discount") {
      // Ensure discount is between 0-100%
      sanitizedValue = Math.max(0, Math.min(100, Number(value) || 0));
    } else if (field === "quantity") {
      // Ensure quantity is at least 1
      sanitizedValue = Math.max(1, Number(value) || 1);
    } else {
      // For list_price, ensure it's non-negative
      sanitizedValue = Math.max(0, Number(value) || 0);
    }

    // Update the product with the sanitized value
    updatedProducts[originalIndex] = {
      ...currentProduct,
      [field]: sanitizedValue,
      action: currentProduct.id ? "update" : "add",
    };

    // Recalculate total
    updatedProducts[originalIndex].total = calculateTotal(
      updatedProducts[originalIndex].list_price,
      updatedProducts[originalIndex].quantity,
      updatedProducts[originalIndex].discount
    );

    setLocalProducts(updatedProducts);
    filterProducts(updatedProducts, searchQuery);
  };

  const getOriginalIndex = (filteredIndex: number): number => {
    if (filteredIndex < 0 || filteredIndex >= filteredProducts.length) {
      return -1;
    }

    const item = filteredProducts[filteredIndex];
    return products.findIndex((p) => p.sale_item_id === item.sale_item_id);
  };

  const calculateGrandTotal = (): number => {
    const activeProducts = products.filter((item) => item.action !== "remove");

    return Number(
      activeProducts
        .reduce((sum, product) => {
          return sum + (product.total || 0);
        }, 0)
        .toFixed(2)
    );
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      setSubmitLoading(true);

      // Filter out temporary products without selection
      const validProducts = products.filter(
        (product) => product.action === "remove" || product.name
      );
      // if (validProducts.length !== products.length) {
      //   toast.error("Please complete all product entries before saving");
      //   setSubmitLoading(false);
      //   return;
      // }

      const resp = await updateSale(getValue(props, "data.id", ""), {
        deal_sale_items: products.map((item) => ({
          id: getValue(item, "id", ""),
          sale_item_id: getValue(item, "sale_item_id", ""),
          action:
            getValue(item, "action", "") ||
            (getValue(item, "id", "") ? "update" : "add"),
          quantity: Math.max(1, Number(getValue(item, "quantity", 1))),
          discount: Math.max(
            0,
            Math.min(100, Number(getValue(item, "discount", 0)))
          ),
          list_price: Math.max(0, Number(getValue(item, "list_price", 0))),
          total: Number(getValue(item, "total", 0)),
        })),
        amount: calculateGrandTotal(),
      });

      if (resp) {
        onCancel();
        toast.success("Saved Successfully");
        props.getSpecificContactInfo(props.contactInfo.id);
        // props.getSpecificDeal(props.contactInfo.data.deals[0].id);
      } else {
        toast.error("Failed to save");
      }
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message || "An error occurred");
      } else {
        toast.error("An error occurred");
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredProducts.slice(startIndex, endIndex);
  };

  const columns: ColumnsType<Product> = [
    {
      title: "PRODUCT",
      key: "product",
      // width: "15%",
      render: (_, record, index) => (
        <Form.Item
          // name={`product_${record.sale_item_id}`}
          rules={[{ required: true, message: "Please select a product" }]}
          style={{ marginBottom: 0, maxWidth: 240 }}
        >
          <AntSearchToolTip
            label={"label"}
            lookup_api={"sale_items"}
            // width={"150px"}
            value={
              record.sale_item_id && !record.sale_item_id.startsWith("temp_")
                ? record.sale_item_id
                : ""
            }
            selectKey={"id"}
            onChange={(e: any, data: any) => {
              updateProductSelect(index, "sale_item_id", data);
            }}
            hideLabel
          />
        </Form.Item>
      ),
    },
    {
      title: "LIST PRICE",
      key: "list_price",
      width: 200,
      render: (_, record, index) => (
        <Form.Item
          name={`list_price_${record.sale_item_id}`}
          rules={[{ required: true, message: "Price required" }]}
          style={{ marginBottom: 0 }}
          initialValue={record.list_price}
        >
          <InputNumber
            min={0}
            precision={2}
            style={{ width: "100%" }}
            value={record.list_price}
            onChange={(value) => updateProduct(index, "list_price", value || 0)}
          />
        </Form.Item>
      ),
    },
    {
      title: "QUANTITY",
      key: "quantity",
      width: 180,
      render: (_, record, index) => (
        <Form.Item
          name={`quantity_${record.sale_item_id}`}
          rules={[{ required: true, message: "Quantity required" }]}
          style={{ marginBottom: 0 }}
          initialValue={record.quantity}
        >
          <InputNumber
            min={1}
            precision={0}
            style={{ width: "100%" }}
            value={record.quantity}
            onChange={(value) => updateProduct(index, "quantity", value || 1)}
          />
        </Form.Item>
      ),
    },
    {
      title: "DISCOUNT (%)",
      key: "discount",
      width: 100,
      render: (_, record, index) => (
        <Form.Item
          name={`discount_${record.sale_item_id}`}
          rules={[
            {
              validator: (_, value) => {
                if (value < 0 || value > 100) {
                  return Promise.reject("Discount must be between 0-100%");
                }
                return Promise.resolve();
              },
            },
          ]}
          style={{ marginBottom: 0 }}
          initialValue={record.discount}
        >
          <InputNumber
            min={0}
            max={100}
            precision={2}
            formatter={(value) => `${value}%`}
            // parser={(value) => value ? value.replace('%', '') : '0'}
            style={{ width: "100%" }}
            value={record.discount}
            onChange={(value) => updateProduct(index, "discount", value || 0)}
          />
        </Form.Item>
      ),
    },
    {
      title: "TOTAL",
      key: "total",
      width: 160,
      render: (_, record) => (
        <Tooltip title="Calculated: Price × Quantity × (1 - Discount%)">
          <Text strong>{record.total.toFixed(2)}</Text>
        </Tooltip>
      ),
    },
    {
      title: "ACTION",
      key: "action",
      width: 80,
      render: (_, record, index) => (
        <Button
          type="text"
          // danger
          icon={<MinusCircleOutlined />}
          onClick={() => removeProduct(index)}
        />
      ),
    },
  ];

  const validateProducts = () => {
    // Filter active products (not marked for removal)
    const activeProducts = products.filter((p) => p.action !== "remove");

    // Check if there are no products
    if (activeProducts.length === 0) return false;

    // Validate each active product
    return activeProducts.every(
      (product) =>
        !product.sale_item_id && // Check for id
        !product.list_price &&
        product.quantity > 0
    );
  };

  return (
    <Modal
      title="Manage Product"
      open={visible}
      onCancel={onCancel}
      width="80%"
      footer={[]}
    >
      <div className="product-management-antd">
        <Form form={form} layout="vertical">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <Col xs={24} sm={12} md={8} lg={6}>
              <Input
                placeholder="Search products..."
                prefix={<SearchOutlined />}
                value={searchQuery}
                onChange={handleSearch}
                allowClear
              />
            </Col>
            <div className="d-flex align-items-center gap-2">
              <Button icon={<PlusOutlined />} onClick={addProduct}>
                Add Product
              </Button>
              {!getValue(props, "hideSave", false) && (
                <Button
                  type="primary"
                  icon={<Save size={16} />}
                  disabled={
                    submitLoading
                    // || validateProducts()
                  }
                  loading={submitLoading}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              )}
            </div>
          </div>

          <Table
            columns={columns}
            dataSource={getPaginatedData()}
            pagination={false}
            bordered
            rowKey={(record) => record.sale_item_id}
            summary={() => (
              <Table.Summary>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={4}>
                    <Text strong>Grand Total</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <Text strong type="success">
                      {calculateGrandTotal().toFixed(2)}
                    </Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} />
                </Table.Summary.Row>
              </Table.Summary>
            )}
            scroll={{
              x: "maxScroll",
            }}
          />

          {filteredProducts.length > 0 && (
            <Row justify="end" style={{ marginTop: "16px" }}>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={filteredProducts.length}
                showSizeChanger
                showQuickJumper
                showTotal={(total) => `Total ${total} items`}
                onChange={handlePageChange}
                onShowSizeChange={handlePageChange}
              />
            </Row>
          )}
        </Form>
      </div>
    </Modal>
  );
}
