import DraftJSRefEditor from "@components/custom/DraftJSEditor/draft-ref-editor";
import MultiSearchLookupTooltip from "@components/custom/Dropdown/MultiSearchLookupTooltip";
import { getValue } from "@utils/lodash";
import { Button, Modal } from "antd";

function AddNotePopup(props: any) {
  const { request, handleChangeNote } = props;
  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      onOk={props.handleSubmit}
      onCancel={props.handleModal}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            props.handleModal();
            props.resetNotes();
          }}
          className="cancel__button"
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={props.handleSubmit}
          disabled={props.isLoading}
          loading={props.isLoading}
          className="submit__button"
        >
          {props.editId ? "Update" : "Add"}
        </Button>,
      ]}
      width={700}
    >
      <h6 className="header_text__16">Note</h6>
      <div className="border-bottom "></div>
      <div className="mt-4">
        <MultiSearchLookupTooltip
          lookup_api={"org_user_mail"}
          label={`label`}
          value={getValue(request, "to", [])}
          selectKey={"id"}
          onChange={(value: any) => props.handleFilterChange(value, "to")}
          name="Notify to"
        />
        <label className="form-label">Note</label>
        <DraftJSRefEditor
          editorState={getValue(request, `html`, "")}
          handleChangeEditor={handleChangeNote}
          toolbarHidden={false}
          name="html"
          toolbar={{
            options: ["inline", "blockType", "fontSize", "list"],
            inline: {
              options: ["bold", "italic"],
            },
          }}
          toolbarClassName={"emailToolbarActive"}
          placeholder="Start typing your note..."
          editorStyle={{
            background: "white",
            paddingLeft: "30px",
            minHeight: "30vh",
            fontSize: "12px",
            //   border: "none",
          }}
          focus
        />
      </div>
    </Modal>
  );
}

export default AddNotePopup;
