import "./CompanyProfile.scss";
import { getValue } from "@utils/lodash";
import { handleSelectChange, handleTextChange } from "common/handleChange";
import timezones from "@common/json/standardized-timezone-data.json";
import countries from "@common/json/contry.json";
import currencies from "@common/json/currency.json";

import { useEffect, useState } from "react";
import { StylesConfig } from "react-select";
import moment from "moment";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { PhoneInput } from "react-international-phone";
import DeleteSvgComponent from "@assets/svg/delete";
import { mediaUpload } from "@services/upload.service";
import { updateOrganisations } from "@services/organisations.service";
import { toast } from "sonner";
import { Alert, Button } from "antd";
import { Trash2 } from "lucide-react";

export default function CompanyProfileForm(props: any) {
  const { request, setRequest, simpleValidator, getData } = props;
  const [date_formats] = useState([
    {
      value: "MM/DD/YYYY",
      label:
        "MM/DD/YYYY" + " [ " + moment(new Date()).format("MM/DD/YYYY") + " ] ",
    },
    {
      value: "DD/MM/YYYY",
      label:
        "DD/MM/YYYY" + " [ " + moment(new Date()).format("DD/MM/YYYY") + " ] ",
    },
    {
      value: "YYYY/MM/DD",
      label:
        "YYYY/MM/DD" + " [ " + moment(new Date()).format("YYYY/MM/DD") + " ] ",
    },
    {
      value: "DD MMM YYYY",
      label:
        "DD MMM YYYY" +
        " [ " +
        moment(new Date()).format("DD MMM YYYY") +
        " ] ",
    },
  ]);
  const [currencyList, setCurrencyList] = useState([]);
  useEffect(() => {
    let codes: any = currencies.map((item: object) => ({
      ...item,
      label: `${getValue(item, `cc`, "")} - ${getValue(item, `name`, "")}`,
      value: `${getValue(item, `cc`, "")}`,
    }));
    setCurrencyList(codes);
  }, []);
  const customStyles: StylesConfig = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#dadce0",
      };
    },
    control: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      height: 40,
      border: state.isFocused ? "1px solid #dadce0" : "1px solid #dadce0",
      boxShadow: state.isFocused ? "0px 0px 0px #dadce0" : "none",
      // marginTop:'6px',
      "&:hover": {
        // border: "1px solid #ff8b67",
        // boxShadow: "0px 0px 6px #ff8b67"
      },
    }),
  };

  const handleSelectCountry = (e: object) => {
    let timezone: any = timezones.find(
      (item: object) =>
        getValue(item, `countryCode`, "") === getValue(e, `code`, "")
    );
    setRequest((prevSta: any) => ({
      ...prevSta,
      phone_code: getValue(e, `dial_code`, ""),
      country: getValue(e, `code`, ""),
      currency: getValue(e, `currency_code`, ""),
      time_zone: getValue(timezone, `value`, ""),
      phone_no: getValue(e, `dial_code`, ""),
    }));
  };

  const [uploadLoading, setUploadLoading] = useState(false);
  const handleUploadDocuments = async (event: any) => {
    try {
      const file = event.target.files[0];
      if (!file) {
        console.error("No file selected.");
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      formData.append("filename", file.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");

      setUploadLoading(true);

      const resp = await mediaUpload(formData);

      if (resp) {
        const response = await updateOrganisations({
          logo_id: getValue(resp, `data.id`, ""),
        });

        if (response) {
          toast.success("Imported successfully");
          getData();
        }
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setUploadLoading(false);
    }
  };
  return (
    <>
      <div className={``}>
        <div
          className={
            "organisations-wrapper__main-section_create overflow-y-auto"
          }
        >
          <div className="d-flex flex-column gap-2 p-5 bg-white my-5 org-creator-container">
            {/* <h6 className="text-center header_text__14">Logo</h6> */}
            <div className="d-flex justify-content-center">
              {getValue(request, `logo`, "") ? (
                uploadLoading ? (
                  <div className="branding-input">
                    <p className="mt-4">Uploading...</p>
                  </div>
                ) : (
                  <div className="position-relative">
                    <label htmlFor="branding-logo" className="cursor-pointer">
                      <input
                        type="file"
                        className="d-none"
                        id="branding-logo"
                        onChange={handleUploadDocuments}
                      />
                      <div className="branding-input">
                        <img
                          src={getValue(request, `logo.url`, "")}
                          className="img-fluid cursor-pointer"
                          alt="logo"
                        />
                      </div>
                    </label>
                    <Button
                      color="danger"
                      variant="text"
                      className="branding_delete_image"
                      onClick={() =>
                        setRequest({
                          ...request,
                          logo: "",
                          logo_id: null,
                        })
                      }
                      size="small"
                      icon={<Trash2 size={18} />}
                    />
                  </div>
                )
              ) : uploadLoading ? (
                <div className="branding-input">
                  <p className="mt-4">Uploading...</p>
                </div>
              ) : (
                <label htmlFor="branding-logo" className="cursor-pointer">
                  <input
                    type="file"
                    className="d-none"
                    id="branding-logo"
                    onChange={handleUploadDocuments}
                  />
                  <div className="branding-input">
                    <img
                      src="/images/icons/camera.svg"
                      className="camera-icon img-fluid cursor-pointer"
                      alt="camera"
                    />
                  </div>
                  <p className="branding-wrapper__text mt-2 text-center">
                    Upload Brand Logo
                  </p>
                </label>
              )}
            </div>
            <Alert
              showIcon
              message="Preferred Image Size: 240px x 48px @ 72 DPI. Maximum size of 1MB."
              type="info"
            />
            <InputRuleForm
              inputType="TEXT"
              label={"Organisation Name"}
              placeholder="Enter Organisation Name"
              name={"name"}
              value={getValue(request, `name`, "")}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextChange(e, request, setRequest)
              }
              validator={simpleValidator}
              style={{ height: "40px" }}
              required
              validLeft
            />
            {/* <InputForms
              inputType="TEXT"
              label={"Currency"}
              placeholder="Enter Currency"
              name={"currency"}
              value={getValue(request, `currency`, "")}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextChange(e, request, setRequest)
              }
              validator={simpleValidator}
            /> */}
            <div>
              <SearchToolTip
                placeholder={`Enter Country`}
                onChange={(e: any) => handleSelectCountry(e)}
                label={`label`}
                data={countries.map((item: object) => ({
                  ...item,
                  value: getValue(item, `code`, ""),
                  label: getValue(item, `name`, ""),
                }))}
                value={getValue(request, `country`, "")}
                selectKey={"value"}
                name={"Country"}
                validator={simpleValidator}
                required
              />
            </div>
            <SearchToolTip
              placeholder={`Enter Currency`}
              onChange={(e: object) =>
                handleSelectChange(e, "currency", request, setRequest)
              }
              label={`label`}
              // width={"365px"}
              data={currencyList}
              value={getValue(request, `currency`, "")}
              selectKey={"value"}
              name={"Currency"}
              validator={simpleValidator}
              required
            />
            {/* <AntDropDown
                placeholder="Enter Time Zone"
                name="Time Zone"
                width="365px"
                data={timezones}
                onChange={(value: any) =>
                  handleSelectChange(value, "time_zone", request, setRequest)
                }
                value={getValue(request, "time_zone", "")}
              /> */}
            <div className="position-relative">
              <SearchToolTip
                placeholder={`Enter Time Zone`}
                onChange={(e: object) =>
                  handleSelectChange(e, "time_zone", request, setRequest)
                }
                label={`label`}
                data={timezones}
                value={getValue(request, `time_zone`, "")}
                selectKey={"value"}
                name={"Time Zone"}
                validator={simpleValidator}
                required
              />
            </div>
            <div>
              <p className="form-label">Phone Number</p>
              <PhoneInput
                defaultCountry={
                  getValue(request, `country`, "")
                    ? getValue(request, `country`, "").toLowerCase() || "in"
                    : "in"
                }
                value={getValue(request, `phone_no`, "")}
                onChange={(e: any) => {
                  setRequest({
                    ...request,
                    phone_no: e,
                  });
                }}
                placeholder={"Enter Phone Number"}
                className=""
              />
              {simpleValidator && (
                <p className={"form-error"}>
                  {simpleValidator.current.message(
                    "Phone Number",
                    getValue(request, `phone_no`, ""),
                    "required"
                  )}
                </p>
              )}
            </div>
            {/* <SearchToolTip
                placeholder={`Enter Date Format`}
                onChange={(e: object) =>
                  handleSelectChange(e, "date_format", request, setRequest)
                }
                label={`label`}
                width={"365px"}
                data={date_formats}
                value={getValue(request, `date_format`, "")}
                selectKey={"value"}
                name={"Date Format"}
              /> */}
          </div>
        </div>
      </div>
      {/* <div className={ClassNames['company-profile__form']}>
			<h6 className={ClassNames['company-profile__form-title']}>
				Company ID 7878787
			</h6>
			<div className="d-flex align-items-center gap">
				<div className={`${ClassNames['company-profile__form-group']} flex-1`}>
					<label className={ClassNames['company-profile__form-label']}>
						Organisation Name
					</label>
					<input
						type="text"
						placeholder="Enter Organisation Name"
						className={`${ClassNames['company-profile__form-control']} form-control`}
					/>
				</div>
				<div className={`${ClassNames['company-profile__form-group']} flex-1`}>
					<label className={ClassNames['company-profile__form-label']}>
						Language
					</label>
					<input
						type="text"
						placeholder="Choose Language"
						className={`${ClassNames['company-profile__form-control']} form-control`}
					/>
				</div>
			</div>
			<div className={`${ClassNames['company-profile__form-group']} flex-1`}>
				<label className={ClassNames['company-profile__form-label']}>
					Time Zone
				</label>
				<input
					type="text"
					placeholder="Choose Time Zone"
					className={`${ClassNames['company-profile__form-control']} form-control`}
				/>
			</div>
			<div className="d-flex align-items-center gap">
				<div className={`${ClassNames['company-profile__form-group']} flex-1`}>
					<label className={ClassNames['company-profile__form-label']}>
						Time Format
					</label>
					<input
						type="text"
						placeholder="Enter Organisation Name"
						className={`${ClassNames['company-profile__form-control']} form-control`}
					/>
				</div>
				<div className={`${ClassNames['company-profile__form-group']} flex-1`}>
					<label className={ClassNames['company-profile__form-label']}>
						Date Format
					</label>
					<input
						type="text"
						placeholder="Enter Organisation Name"
						className={`${ClassNames['company-profile__form-control']} form-control`}
					/>
				</div>
			</div>
		</div> */}
    </>
  );
}
