import { getValue } from "@utils/lodash";
import { handleTextChange } from "common/handleChange";
import InputRuleForm from "@components/InputRuleForm/form";
import { Card } from "antd";

export default function CompanyPaymentForm(props: any) {
  const { request, setRequest, simpleValidator } = props;
  return (
    <div className="d-flex justify-content-center my-4">
      <Card style={{ width: 400 }}>
        <div>
          <InputRuleForm
            inputType="PASSWORD"
            label={"Merchant ID"}
            placeholder="Enter Merchant ID"
            name={"merchant_id"}
            value={getValue(request, `merchant_id`, "")}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleTextChange(e, request, setRequest)
            }
            validator={simpleValidator}
            style={{ height: "40px" }}
            required
            validLeft
          />
        </div>
        <div>
          <InputRuleForm
            inputType="PASSWORD"
            label={"Merchant key"}
            placeholder="Enter Merchant key"
            name={"merchant_key"}
            value={getValue(request, `merchant_key`, "")}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleTextChange(e, request, setRequest)
            }
            validator={simpleValidator}
            style={{ height: "40px" }}
            required
            validLeft
          />
        </div>
      </Card>
    </div>
  );
}
