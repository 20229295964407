import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./createbot.scss";
import { getValue } from "@utils/lodash";
import {
  cloneChatbot,
  deleteChatbot,
  getAllAvatar,
  getAllChatbots,
} from "@services/chatbot.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { toast } from "sonner";
import useDynamicTitle from "@context/useDynamicTitle";
import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import MyBotsPage from "./my-bots";
import BotsLibraryPage from "./bots-library";
import SidebarLayout from "@layouts/SidebarLayout/SidebarLayout";
import { useStateContext } from "@context/dataContext";

const BotsPage = (props: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const { bookmarkedModules, themeColor, refreshBookmarks } = useStateContext();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const [openBotTypeModal, setOpenBotTypeModal] = useState(false);

  const toggleBotTypeModal = () => {
    setOpenBotTypeModal(!openBotTypeModal);
  };

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getData();
      getAvatar();
    }
  }, []);
  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getData();
    }
  }, [window.location.href]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getAvatar = async () => {
    try {
      let resp = await getAllAvatar({});
      if (resp) {
      }
    } catch (error) {}
  };
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        page_size: getValue(UrlParams, `limit`, "")
          ? getValue(UrlParams, `limit`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
        search: getValue(UrlParams, `search`, ""),
        // type: "ai-chatbot",
        types: ["ai-chatbot", "chatbot"],
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllChatbots(queryRequest);
      if (resp) {
        setList(getValue(resp, `data.chatbots`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                              Delete Section                                */
  /* -------------------------------------------------------------------------- */
  const [deleteId, setDeleteId] = useState("");
  const [deleteValue, setDeleteValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleDeleteView = (id: string) => {
    setDeleteId(id);
    handleModal();
  };
  const handleDeleteBot = async () => {
    try {
      let resp = await deleteChatbot(deleteId);
      if (resp) {
        toast.success("Deleted Successfully");
        handleModal();
        setDeleteId("");
        getData();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                              Clone Section                                */
  /* -------------------------------------------------------------------------- */
  const [cloneId, setCloneID] = useState("");
  const [isOpen1, setIsOpen1] = useState(false);

  const handleModal1 = () => {
    setIsOpen1(!isOpen1);
  };

  const handleClone = (id: string) => {
    setCloneID(id);
    handleModal1();
  };
  const handleCloneBot = async () => {
    try {
      let resp = await cloneChatbot(cloneId);
      if (resp) {
        toast.success("Cloned Successfully");
        handleModal1();
        setCloneID("");
        getData();
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    let payload = {
      ...UrlParams,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeLimit = (limit: string) => {
    let payload: any = {
      ...UrlParams,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  useDynamicTitle("Chatbot");

  const [selectedBotType, setSelectedBotType] = useState("web");

  const handleChangeBotType = (e: any) => {
    setSelectedBotType(e.target.value);
  };

  const handleSelectBotType = () => {
    navigate(
      `/${getValue(params, "orgId", "")}/chatbot/builder/${selectedBotType}`
    );
  };

  const [activeTab, setActiveTab] = useState("1");
  const views = [
    {
      id: "1",
      name: "My Bots",
    },
    {
      id: "2",
      name: "Library",
    },
  ];

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      setActiveTab(getValue(UrlParams, `activeTab`, "1"));
    }
  }, [window.location.href]);
  const handleChangeTab = (key: string) => {
    let payload = {
      ...UrlParams,
      page_no: 1,
      activeTab: key,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    setActiveTab(key);
  };
  useDynamicTitle("Broadcast");

  return (
    <SidebarLayout {...props} isLoading={isLoading}>
      <PipelineSubHeader
        subHeaderListTitle={`AI Agents (${getValue(list, "length", "")})`}
        isRightArrowVisible={true}
        isSubmenuListAvailable={true}
        hideClose={true}
        pipeline={false}
        bookmarkedModulePath="chatbot"
        bookmarkedModuleName="AI Agents"
        refreshBookmarks={refreshBookmarks}
        bookmarkedModules={bookmarkedModules}
        themeColor={themeColor}
      />
      <Tabs
        activeKey={activeTab}
        onChange={handleChangeTab}
        type="card"
        className="broadcast-tabs m-3"
      >
        {views.map((item) => (
          <TabPane tab={item.name} key={item.id}>
            {item.id === "1" && <MyBotsPage {...props} />}
            {item.id === "2" && <BotsLibraryPage {...props} />}
          </TabPane>
        ))}
      </Tabs>
      {/* <elevenlabs-convai agent-id="644R50s5c7CvZ8f96xsS"></elevenlabs-convai> */}
    </SidebarLayout>
  );
};

export default BotsPage;
