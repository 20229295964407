import { useEffect, useState } from "react";
import { Button, Tabs } from "antd";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import TemplateMessages from "./TemplateMessages";
import BroadcastHistory from "./BroadcastHistory";
import { useNavigate } from "react-router-dom";
import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";
import useDynamicTitle from "@context/useDynamicTitle";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import StaticTemplateMessages from "./StaticTemplateMessages";
import SidebarLayout from "@layouts/SidebarLayout/SidebarLayout";
import { useStateContext } from "@context/dataContext";
import { getWhatsappManagerConfig } from "@services/fb.service";

const { TabPane } = Tabs;

function Broadcast(props: any) {
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const { bookmarkedModules, themeColor, refreshBookmarks } = useStateContext();

  const [activeTab, setActiveTab] = useState("1");

  const views = [
    {
      id: "1",
      name: " History",
    },
    {
      id: "2",
      name: "Scheduled",
    },
    {
      id: "3",
      name: "My Templates",
    },
    {
      id: "4",
      name: "Template Library",
    },
  ];

  useEffect(() => {
    getFbConfig();
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      setActiveTab(getValue(UrlParams, `activeTab`, "1"));
    }
  }, [window.location.href]);

  const [waConfigInfo, setWaConfigInfo] = useState({});
  const getFbConfig = async () => {
    try {
      const payload = {
        status: "active",
      };
      const queryRequest = QueryRequestHelper(payload);
      let resp = await getWhatsappManagerConfig(queryRequest);
      if (resp) {
        setWaConfigInfo(getValue(resp, `data`, {}));
      } else {
        setWaConfigInfo({});
      }
    } catch (error) {
      setWaConfigInfo({});
    }
  };

  const handleChangeTab = (key: string) => {
    let payload = {
      ...UrlParams,
      page_no: 1,
      activeTab: key,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    setActiveTab(key);
  };
  useDynamicTitle("Broadcast");

  return (
    <SidebarLayout {...props}>
      <PipelineSubHeader
        subHeaderListTitle="WhatsApp Broadcast"
        isRightArrowVisible={true}
        isSubmenuListAvailable={true}
        hideClose={true}
        pipeline={false}
        bookmarkedModules={bookmarkedModules}
        refreshBookmarks={refreshBookmarks}
        themeColor={themeColor}
        bookmarkedModuleName="WhatsApp Broadcast"
        bookmarkedModulePath="broadcast"
        addButton={
          getValue(waConfigInfo, `accountLinked`, false) && (
            <>
              {activeTab === "3" &&
                getValue(props, `permissions`, []).includes("create") && (
                  <div className="d-flex gap-2">
                    <Button
                      size="large"
                      type="primary"
                      onClick={() => navigate("sync-templates")}
                      icon={<SyncOutlined />}
                    >
                      Sync Templates
                    </Button>
                    <Button
                      size="large"
                      type="primary"
                      onClick={() => navigate("create-template")}
                      icon={<PlusOutlined />}
                    >
                      New Template
                    </Button>
                  </div>
                )}

              {["1", "2"].includes(activeTab) &&
                getValue(props, `permissions`, []).includes("create") && (
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => navigate("create-broadcast")}
                    icon={<PlusOutlined />}
                  >
                    New Broadcast
                  </Button>
                )}
            </>
          )
        }
      />
      <div className="d-flex">
        <div className="manageFieldRight w-100 px-5 pt-3">
          <Tabs
            activeKey={activeTab}
            onChange={handleChangeTab}
            type="card"
            className="broadcast-tabs"
          >
            {views.map((item) => (
              <TabPane tab={item.name} key={item.id}>
                {item.id === "1" && (
                  <BroadcastHistory
                    configInfo={waConfigInfo}
                    permissions={getValue(props, `permissions`, [])}
                  />
                )}
                {item.id === "2" && (
                  <BroadcastHistory
                    configInfo={waConfigInfo}
                    permissions={getValue(props, `permissions`, [])}
                  />
                )}
                {item.id === "3" && (
                  <TemplateMessages
                    configInfo={waConfigInfo}
                    permissions={getValue(props, `permissions`, [])}
                  />
                )}
                {item.id === "4" && (
                  <StaticTemplateMessages
                    configInfo={waConfigInfo}
                    permissions={getValue(props, `permissions`, [])}
                  />
                )}
              </TabPane>
            ))}
          </Tabs>
        </div>
      </div>
    </SidebarLayout>
  );
}

export default Broadcast;
