import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllCallers = () => get(`${config.API_URL}/phones`);

export const getSpecificCaller = (id: string) =>
  get(`${config.API_URL}/phones/${id}`);

export const createCaller = (payload: object) =>
  post(`${config.API_URL}/phones`, payload);

export const updateCaller = (id: string, payload: object) =>
  patch(`${config.API_URL}/phones/${id}`, payload);

export const deleteCaller = (id: string) =>
  Delete(`${config.API_URL}/phones/${id}`);
