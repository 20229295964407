import { getValue } from "@utils/lodash";
import React, { memo } from "react";
import {
  formHandleBooleanArrayChange,
  formHandleDateArrayChange,
  formHandleEmailArrayChange,
  formHandleLookeupArrayChange,
  formHandleMultiSelectArrayChange,
  formHandleMultiSelectArrayChangeID,
  formHandleNumericArrayChange,
  formHandleSelectArrayChange,
  formHandleTextArrayChange,
  formHandleTimeArrayChange,
} from "common/handleChange";
import {
  capitalizeFirstLetter,
  sortJSONObjectArray,
} from "common/text-helpers";
import InlineSearchToolTip from "@components/custom/Dropdown/InlineSearchTooltip";
import AlarmPicker from "@components/custom/Forms/AlarmPicker";
import MultiSearchTooltip from "@components/custom/Dropdown/MultiSearchTooltip";
import FutureAlarmPicker from "@components/custom/Forms/FutureAlarmPicker";
import MultiSearchLookupTooltip from "@components/custom/Dropdown/MultiSearchLookupTooltip";
import { QueryRequestHelper } from "@common/query-request-helper";
import { checkModuleDuplicates } from "@services/common.service";
import DialcodeInlinePhoneInputComponent from "@components/custom/Forms/DialcodeInlineInput";
import InlineCheckbox from "@components/Builder/InlineFormBuilder/Components/InlineCheckbox";
import InlineBooleanOptions from "@components/Builder/InlineFormBuilder/Components/boolean_options";
import InlineDateMonthYearPicker from "@components/Builder/InlineFormBuilder/Components/Date";
import InlineTimePicker from "@components/Builder/InlineFormBuilder/Components/DateTime";
import InlineMainTimePicker from "@components/Builder/InlineFormBuilder/Components/Time";
import DebouncedInlineInput from "@components/Builder/InlineFormBuilder/Components/Text/debounce-text-input";
import DebouncedInlineTextArea from "@components/Builder/InlineFormBuilder/Components/Text/debounce-textarea-input";
import NewSearchSelect from "@components/custom/Dropdown/AntDropDown";
import AntSearchToolTip from "@components/custom/DebounceSelect";

function ProfileInlineFormBuilder(props: any) {
  const {
    setFields,
    fields,
    simpleValidator,
    duplicateAPINames,
    setDuplicateAPINames,
  } = props;
  const renderForm = (item: any, index: number, label: string) => {
    //----------------------- Due date time -------------------//
    let dueDateTime =
      getValue(props, `module`, "") == "tasks" &&
      getValue(fields, `length`, 0) > 0
        ? fields.filter(
            (item: object) => getValue(item, `name`, "") === "due_datetime"
          )
        : [];

    const getTime = (field_name: string) => {
      let time =
        // getValue(props, `module`, "") == "tasks" &&
        getValue(fields, `length`, 0) > 0
          ? fields.filter((item: object) => apiName === field_name)
          : [];
      return time;
    };

    const getParentFieldName = (field_name: string) => {
      let name =
        // getValue(props, `module`, "") == "tasks" &&
        getValue(fields, `length`, 0) > 0
          ? fields.find((item: object) => apiName === field_name)
          : [];
      return getValue(name, `label`, "");
    };

    const removeRelatedFields = (name: string) => {
      const removeFields =
        getValue(fields, "length", 0) > 0
          ? fields.filter(
              (item: object) => getValue(item, "parent_field", "") === name
            )
          : [];

      const list =
        getValue(removeFields, "length", 0) > 0
          ? removeFields.map((item: object) => getValue(item, "api_name", ""))
          : [];

      fields.forEach((element: any) => {
        if (list.includes(getValue(element, "api_name", ""))) {
          // Assuming 'value' is a property of the element, update the assignment accordingly
          element["value"] = "";
        }
      });
    };
    const maniLabel = getValue(item, `label`, "");
    const apiName = getValue(item, `api_name`, "");
    const required = getValue(item, `required`, false);
    //---------------- Reminder date time index ----------------//

    const findReminderIndex =
      getValue(props, `module`, "") == "tasks" &&
      getValue(fields, `length`, 0) > 0 &&
      fields.findIndex((element: any) => element.name === "reminder_datetime");
    switch (getValue(item, `input_type`, "")) {
      case "text":
        return (
          <div
            className={`form_inline_builder_field position-relative mt-2`}
            // style={{ width: props.width }}
          >
            <DebouncedInlineInput
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                formHandleTextArrayChange(index, e, setFields)
              }
              type={"text"}
              placeholder={`Enter ${maniLabel}`}
              label={`${
                getValue(item, `name`, "") === "name"
                  ? capitalizeFirstLetter(label)
                  : ""
              }${" "}${capitalizeFirstLetter(maniLabel)}`}
              validator={required && simpleValidator}
              mandatory={required}
              onBlur={(e: any) =>
                getValue(item, `unique`, false)
                  ? handleBlur(e, apiName)
                  : handleNoBlur
              }
            />
            {getValue(item, `unique`, false) &&
              getValue(duplicateAPINames, `length`, 0) > 0 &&
              duplicateAPINames.includes(apiName) && (
                <p className="unique_valid">Number already exist</p>
              )}
          </div>
        );
      case "email":
        return (
          <div
            className={`form_inline_builder_field position-relative mt-2`}
            // style={{ width: props.width }}
          >
            <DebouncedInlineInput
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                formHandleEmailArrayChange(index, e, setFields)
              }
              onBlur={(e: any) =>
                getValue(item, `unique`, false)
                  ? handleBlur(e, apiName)
                  : handleNoBlur
              }
              type={"email"}
              placeholder={`Enter ${maniLabel}`}
              label={`${
                getValue(item, `name`, "") === "name" ? label : ""
              }${" "}${maniLabel}`}
              validator={required && simpleValidator}
              validatoryType={required && "required|email"}
              mandatory={required}
            />
          </div>
        );
      case "tel":
        return (
          <div className="form_builder_field position-relative mt-2">
            <DialcodeInlinePhoneInputComponent
              {...item}
              onChange={(e: any) => {
                formHandleNumericArrayChange(index, e, setFields);
              }}
              type={"text"}
              // onBlur={(e: any) =>
              //   getValue(item, `unique`, false)
              //     ? handleBlur(e, apiName)
              //     : handleNoBlur
              // }
              value={getValue(item, `value`, "")}
              placeholder={`Enter ${maniLabel}`}
              label={`${maniLabel}`}
              // validator={!hideValidation && required && simpleValidator}
              // mandatory={!hideValidation && required}
              index={index}
              setFields={setFields}
              editInline
              module={props.module}
              duplicateAPINames={duplicateAPINames}
              setDuplicateAPINames={setDuplicateAPINames}
              api_name={apiName}
              // showWhatsapp
            />
            {getValue(props, `isChanged`, false) &&
              getValue(item, `unique`, false) &&
              getValue(duplicateAPINames, `length`, 0) > 0 &&
              duplicateAPINames.includes(apiName) && (
                <p className="unique_valid">Number already exist</p>
              )}
          </div>
        );

      case "url":
        return (
          <div
            className={`form_inline_builder_field`}
            // style={{ width: props.width }}
          >
            <DebouncedInlineInput
              {...item}
              onChange={
                (e: React.ChangeEvent<HTMLInputElement>) =>
                  // validateURL(e.target.value)?
                  formHandleTextArrayChange(index, e, setFields)
                // : null
              }
              type={"url"}
              placeholder={`Enter ${maniLabel}`}
              label={`${
                getValue(item, `name`, "") === "name" ? label : ""
              }${" "}${maniLabel}`}
              validator={required && simpleValidator}
              mandatory={required}
            />
          </div>
        );
      case "currency":
      case "percent":
      case "integer":
      case "decimal":
      case "auto_number":
      case "autonumber":
      case "bigint":
        return (
          <div
            className={`form_inline_builder_field mt-2`}
            // style={{ width: props.width }}
          >
            <DebouncedInlineInput
              {...item}
              onChange={
                (e: React.ChangeEvent<HTMLInputElement>) =>
                  // validateNumber(e.target.value)?
                  formHandleNumericArrayChange(index, e, setFields)
                // : null
              }
              type={"text"}
              placeholder={`Enter ${maniLabel}`}
              label={`${maniLabel}`}
              validator={required && simpleValidator}
              mandatory={required}
            />
          </div>
        );
      case "boolean_checkbox":
        return (
          <div className="form_builder_field">
            <InlineCheckbox
              {...item}
              onChange={(e: any) =>
                // formHandleBooleanArrayChange(index, e, setFields)
                setFields((options: any) => {
                  return options.map((x: object, i: number) => {
                    if (i !== index) return x;
                    return {
                      ...x,
                      value: !getValue(item, `value`, false),
                    };
                  });
                })
              }
              data={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
              type={"checkbox"}
              placeholder={`Enter ${maniLabel}`}
              label={`${
                getValue(item, `name`, "") === "name" ? label : ""
              }${" "}${maniLabel}`}
              // validator={required && simpleValidator}
            />
          </div>
        );
      case "boolean_radio":
        return (
          <div className="form_builder_field">
            <InlineBooleanOptions
              {...item}
              onChange={(e: any) =>
                formHandleBooleanArrayChange(index, e, setFields)
              }
              data={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
              type={"radio"}
              placeholder={`Enter ${maniLabel}`}
              label={`${
                getValue(item, `name`, "") === "name" ? label : ""
              }${" "}${maniLabel}`}
              // validator={required && simpleValidator}
            />
          </div>
        );
      case "boolean_select":
        return (
          <div className="form_builder_field">
            <InlineSearchToolTip
              {...item}
              placeholder={`Enter ${maniLabel}`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                formHandleSelectArrayChange(index, e, setFields)
              }
              label={`label`}
              // width={"480px"}
              data={[
                { value: null, label: "None" },
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
              value={getValue(item, `value`, "")}
              selectKey={"value"}
              name={maniLabel}
              // validator={required && simpleValidator}
            />
          </div>
        );
      case "select_multiple":
        return (
          <div className="form_builder_field">
            <MultiSearchTooltip
              {...item}
              placeholder={`Enter ${maniLabel}`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                formHandleMultiSelectArrayChange(
                  index,
                  e,
                  setFields,
                  getValue(item, `value`, "")
                )
              }
              label={`label`}
              // width={"480px"}
              data={
                getValue(item, `dropdown_options.length`, 0) > 0
                  ? getValue(item, `dropdown_options`, []).map(
                      (item: object, key: number) => ({
                        ...item,
                        label: maniLabel,
                        index: key,
                        name: maniLabel,
                        id: getValue(item, `id`, ""),
                      })
                    )
                  : getValue(item, `picklist_options.length`, 0) > 0
                  ? getValue(item, `picklist_options`, []).map(
                      (item: object, key: number) => ({
                        ...item,
                        label: maniLabel,
                        index: key,
                        name: maniLabel,
                        id: getValue(item, `id`, ""),
                      })
                    )
                  : []
              }
              value={getValue(item, `value`, "")}
              selectKey={"value"}
              name={maniLabel}
            />
          </div>
        );
      case "textarea":
        return (
          <div
            className={`form_inline_builder_field mt-2`}
            // style={{ width: props.width }}
          >
            <DebouncedInlineTextArea
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                formHandleTextArrayChange(index, e, setFields)
              }
              type={"textarea"}
              label={`${maniLabel}`}
              placeholder={`Enter ${maniLabel}`}
              rows={1}
              validator={required && simpleValidator}
            />
          </div>
        );
      case "date":
        return (
          <div
            className={`form_inline_builder_field`}
            // style={{ width: props.width }}
          >
            <InlineDateMonthYearPicker
              {...item}
              onChange={(e: any) =>
                formHandleDateArrayChange(index, e, setFields)
              }
              type={"text"}
              label={`${maniLabel}`}
              placeholder={`Enter ${maniLabel}`}
              validator={required && simpleValidator}
              minDate={new Date()}
              mandatory={required}
            />
          </div>
        );
      case "datetime":
        return (
          <div
            className={`form_inline_builder_field`}
            // style={{ width: props.width }}
          >
            <InlineTimePicker
              {...item}
              onChange={(e: any) => {
                formHandleTimeArrayChange(index, e, setFields);
                removeRelatedFields(apiName);
                setFields((fields: any) => {
                  return fields.map((x: object, index: number) => {
                    if (index !== findReminderIndex) return x;
                    return {
                      ...x,
                      value: "",
                    };
                  });
                });
              }}
              type={"text"}
              label={`${maniLabel}`}
              placeholder={`Enter ${maniLabel}`}
              validator={required && simpleValidator}
              showTimeInput
              mandatory={required}
            />
          </div>
        );
      case "time":
        return (
          <div className="">
            <InlineMainTimePicker
              {...item}
              onChange={(e: any) =>
                formHandleTimeArrayChange(index, e, setFields)
              }
              type={"text"}
              label={`${maniLabel}`}
              placeholder={`Enter ${maniLabel}`}
              validator={required && simpleValidator}
              showTimeInput
              mandatory={required}
            />
          </div>
        );
      case "datetime_alarm":
        return (
          <div className="form_builder_field">
            <AlarmPicker
              {...item}
              onChange={(e: any) =>
                formHandleSelectArrayChange(index, e, setFields)
              }
              label={`${maniLabel}`}
              placeholder={`Enter ${maniLabel}`}
              validator={required && simpleValidator}
              showTimeInput
              mandatory={required}
              parent_field_label={getParentFieldName(
                getValue(item, `parent_field`, "")
              )}
              dueDateTime={
                getTime(getValue(item, `parent_field`, "")).length > 0
                  ? getTime(getValue(item, `parent_field`, ""))[0].value
                  : ""
              }
            />
          </div>
        );
      case "datetime_duration":
        return (
          <div className="form_builder_field">
            <FutureAlarmPicker
              {...item}
              onChange={(e: any) =>
                formHandleSelectArrayChange(index, e, setFields)
              }
              label={`${maniLabel}`}
              placeholder={`Enter ${maniLabel}`}
              validator={required && simpleValidator}
              showTimeInput
              mandatory={required}
              parent_field_label={getParentFieldName(
                getValue(item, `parent_field`, "")
              )}
              dueDateTime={
                getTime(getValue(item, `parent_field`, "")).length > 0
                  ? getTime(getValue(item, `parent_field`, ""))[0].value
                  : ""
              }
            />
          </div>
        );
      case "picklist":
      case "select":
        return (
          <div
            className={`form_inline_builder_field my-3`}
            // style={{ width: props.width }}
          >
            <NewSearchSelect
              {...item}
              placeholder={`Select ${maniLabel}`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>, data: any) =>
                formHandleSelectArrayChange(index, data, setFields)
              }
              validator={required && simpleValidator}
              // width={props.width}
              options={
                getValue(item, `dropdown_options.length`, 0) > 0
                  ? getValue(item, `dropdown_options`, []).map(
                      (item: object, key: number) => ({
                        ...item,
                        label: getValue(item, `label`, ""),
                        index: key,
                        name: getValue(item, `label`, ""),
                        id: getValue(item, `id`, ""),
                      })
                    )
                  : []
              }
              value={getValue(item, `value`, "")}
              selectKey={"value"}
              label={maniLabel}
              mandatory={required}
            />
          </div>
        );
      case "lookup":
        return (
          <div
            className={`form_inline_builder_field mt-3`}
            // style={{ width: props.width }}
          >
            <AntSearchToolTip
              {...item}
              placeholder={`Enter ${maniLabel}`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>, data: any) =>
                formHandleLookeupArrayChange(index, data, setFields)
              }
              label={getValue(item, `label`, "")}
              validator={required && simpleValidator}
              // width={props.width}
              value={getValue(item, `value`, "")}
              name={maniLabel}
              lookup_api={getValue(item, `lookup.api_name`, "")}
              mandatory={required}
            />
          </div>
        );
      case "lookup_association":
        return (
          <div className="form_builder_field mt-2">
            <MultiSearchLookupTooltip
              {...item}
              placeholder={`Enter ${maniLabel}`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                formHandleMultiSelectArrayChangeID(
                  index,
                  e,
                  setFields,
                  getValue(item, `value`, [])
                )
              }
              lookup_api={getValue(item, `lookup.api_name`, "")}
              label={`label`}
              validator={required && simpleValidator}
              value={getValue(item, `value`, [])}
              selectKey={"id"}
              name={maniLabel}
              mandatory={required}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const handleBlur = async (e: any, api_name: string) => {
    if (props.module) {
      const { value } = e.target;
      // let outputString = value.replace(/[^+\d]/g, "").trim();
      let outputString = value.trim();
      if (api_name && outputString) {
        let payload = {
          [api_name]: outputString,
        };
        let queryRequest = QueryRequestHelper(payload);
        let resp = await checkModuleDuplicates(
          props.module,
          queryRequest,
          props.pipeline_id
        );
        if (resp) {
          if (getValue(resp, `data.id`, "")) {
            if (!duplicateAPINames.includes(api_name)) {
              duplicateAPINames.push(api_name);
              setDuplicateAPINames([...duplicateAPINames]);
            }
          } else {
            let filtered = duplicateAPINames.filter(
              (item: string) => api_name !== item
            );
            setDuplicateAPINames(filtered);
          }
        }
      }
    }
  };
  const handleNoBlur = () => {};
  return (
    <div
      style={{
        width: props.width || "316px",
      }}
    >
      {getValue(fields, `length`, 0) > 0 &&
        sortJSONObjectArray(fields, "seq_num").map((item: any, key: number) => {
          if (
            // !getValue(item, `form_hidden`, "") &&
            getValue(item, `input_type`, "") !== "lookup_association"
          )
            return (
              <div
                className={`form_wrapper-pipeline`}
                key={key}
                // style={{ width: `${props.width} !important` }}
              >
                {renderForm(item, key, getValue(props, `singular_label`, ""))}
              </div>
            );
        })}
    </div>
  );
}

export default memo(ProfileInlineFormBuilder);
