import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import { FileText, Link, Plus } from "lucide-react";
import SettingsPageDetails from "@components/Pages/PageDescription/SettingsPageDetails";
import { useState, useRef, useEffect } from "react";
import { mediaLibraryDetails } from "@common/Data/page-details-data";
import useDynamicTitle from "@context/useDynamicTitle";
import { Badge, Button, Modal, Tabs } from "antd";
import { mediaUpload } from "@services/upload.service";
import { getValue } from "@utils/lodash";
import { toast } from "sonner";
import SimpleReactValidator from "simple-react-validator";
import MediaLibraryUrlModal from "./components/MediaLibraryUrlModal";
import {
  createMediaLibrary,
  deleteMediaLibrary,
  getAllMediaLibrary,
  updateMediaLibrary,
} from "@services/media-library.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { useNavigate } from "react-router-dom";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import UrlListTable from "./components/UrlListTable";
import {
  allowedFormats,
  findType,
  getFileSizeLimit,
  isValidFileFormat,
  isValidFileSize,
} from "@common/file-type-helper";
import DocumentsListTable from "./components/DocumentsListTable";
import MediaLibraryUploadModal from "./components/MediaLibraryUploadModal";
import "./media-libarary.scss";

type FileType = "image" | "audio" | "document" | "video";

const requestProps: {
  name: string;
  url?: string;
  type: string;
  file_type?: FileType;
  file_id?: string;
} = {
  name: "",
  url: "",
  type: "files",
  file_type: "image",
  file_id: "",
};

const MediaLibrary = (props: any) => {
  useDynamicTitle("Media Library");
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [activeTab, setActiveTab] = useState(
    getValue(UrlParams, "tab", "") || "1"
  );
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isUrlModalOpen, setIsUrlModalOpen] = useState(false);
  const [editId, setEditId] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const [request, setRequest] = useState(requestProps);

  const [fileList, setFileList] = useState([]);
  const [urlList, setUrlList] = useState([]);

  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      handleGetMediaLibrary(true, activeTab);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      if (UrlParams.tab) {
        setActiveTab(UrlParams.tab);
      }
      handleGetMediaLibrary(true, UrlParams.tab);
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const handleGetMediaLibrary = async (status: boolean, tab: string) => {
    try {
      setIsLoading(status);
      const currentType = tab === "1" ? "files" : "urls";
      const payload = {
        page_size: getValue(UrlParams, `page_size`, "") || limit,
        page_no: getValue(UrlParams, `page_no`, "") || page_no,
        type: currentType,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getAllMediaLibrary(queryRequest);
      if (resp) {
        if (currentType === "files") {
          setFileList(getValue(resp, "data.resources", []));
        } else {
          setUrlList(getValue(resp, "data.resources", []));
        }
        setPage_no(getValue(resp, "data.pagination.page", 0));
        setTotalCount(getValue(resp, "data.pagination.total", 0));
      }
    } catch (error) {
      // toast.error("Failed to fetch Media Library");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (request: any) => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      //   toast.error(`Please fill all the mandatory fields`);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setIsLoading1(true);
      try {
        let payload = JSON.parse(JSON.stringify(request));
        const resp = editId
          ? await updateMediaLibrary(
              editId,
              removeNullOrUndefinedProperties(payload)
            )
          : await createMediaLibrary(removeNullOrUndefinedProperties(payload));

        if (resp) {
          toast.success(`${editId ? "Updated" : "Created"} Successfully`);
          resetRequest();
          handleGetMediaLibrary(true, activeTab);
          setEditId("");
          setIsUploadModalOpen(false);
          setIsUrlModalOpen(false);
        } else {
          toast.error(`Failed to ${editId ? "editing" : "creating"} products`);
        }
      } catch (error) {
        toast.error(
          `Error ${editId ? "editing" : "creating"} ${
            activeTab === "1" ? "files" : "url"
          }`
        );
      } finally {
        setIsLoading1(false);
      }
    }
  };

  const handleDeleteModal = (id: string, name: string) => {
    Modal.confirm({
      title: `Are you sure you want to delete - ${name}?`,
      content: "Once deleted, this cannot be recovered.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await deleteMediaLibrary(id);
          toast.success(" Deleted successfully");
          handleGetMediaLibrary(true, activeTab);
        } catch (error) {
          toast.error("Error deleting ");
        }
      },
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: any, limit: any) => {
    setPage_no(page_no);
    setLimit(limit);

    let payload = {
      ...UrlParams,
      page_no: page_no,
      page_size: limit,
      tab: activeTab,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    setPage_no(page_no);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Handlers section                              */
  /* -------------------------------------------------------------------------- */
  const resetRequest = () => {
    setRequest(requestProps);
  };

  const toggleUrlModal = () => {
    setIsUrlModalOpen(!isUrlModalOpen);
  };

  const handleEditUrl = (item: any) => {
    setEditId(getValue(item, "id", ""));
    setIsUrlModalOpen(true);
    setRequest({
      name: getValue(item, "name", ""),
      url: getValue(item, "url", ""),
      type: "urls",
    });
  };

  const handleDocumentCancel = () => {
    resetRequest();
    setIsUploadModalOpen(false);
    setDocumentDetails({});
    simpleValidator.current.hideMessages();
    forceUpdate(1);
  };

  const handleUrlCancel = () => {
    resetRequest();
    setIsUrlModalOpen(false);
    simpleValidator.current.hideMessages();
    forceUpdate(1);
  };

  const [documentDetails, setDocumentDetails] = useState<any>({});
  const [uploadLoading, setUploadLoading] = useState(false);

  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleUploadDocuments = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files?.[0];

    if (!file) return;

    if (!isValidFileFormat(file.name)) {
      toast.error(
        `Invalid file format. Allowed formats: ${allowedFormats.join(", ")}`
      );
      resetFileInput();
      return;
    }

    const maxSize = getFileSizeLimit(
      file.name.split(".").pop()?.toLowerCase() || ""
    );
    if (file.size > maxSize) {
      toast.error(
        `File size must be less than ${maxSize / (1024 * 1024)}MB for .${
          file.name.split(".").pop()?.toLowerCase() || ""
        } files`
      );
      resetFileInput();
      return;
    }

    let formData = new FormData();
    formData.append("file", file);
    formData.append("filename", file.name);
    formData.append("is_public", "true");
    formData.append("collection", "public-images");

    try {
      setUploadLoading(true);
      let resp = await mediaUpload(formData);
      if (resp) {
        setRequest({
          name: "",
          type: "files",
          file_type: findType(getValue(resp, "data.extension", "")),
          file_id: getValue(resp, "data.id", ""),
          url: getValue(resp, "data.key", ""),
        });
        setDocumentDetails(getValue(resp, "data", {}));
        setIsUploadModalOpen(true);

        resetFileInput();
        setUploadLoading(false);
      } else {
        setUploadLoading(false);
        toast.error("Failed to upload file");
      }
    } catch (error) {
      setUploadLoading(false);
      toast.error("Error uploading file");
    }
  };

  // Helper function to reset file input
  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <CompanyProfileDetailsLayout {...props} isLoading={isLoading}>
      <CompanyProfileSubHeader
        settings
        title="Media Library"
        icon="media-library"
      />
      <div className="sla-policy-wrapper">
        <div className="w-70 pb-0 pt-4 px-4 sla-policy-card-container">
          <p className="w-70 pb-4 small_text__14">
            Upload documents, add URLs to be used by you and other agents while
            responding to customers.
          </p>
          <Tabs
            defaultActiveKey={activeTab}
            items={[
              {
                key: "1",
                label: (
                  <div className="d-flex align-items-center gap-2">
                    <FileText size={16} />
                    Files{" "}
                    {activeTab === "1" && (
                      <Badge count={totalCount} className="ms-2" />
                    )}
                  </div>
                ),
                children: (
                  <DocumentsListTable
                    fileList={fileList}
                    handleEditUrl={handleEditUrl}
                    handleDeleteModal={handleDeleteModal}
                    page_no={page_no}
                    limit={limit}
                    totalCount={totalCount}
                    handleChangePagination={handleChangePagination}
                    isLoading={isLoading}
                    permissions={props.permissions}
                  />
                ),
              },
              {
                key: "2",
                label: (
                  <div className="d-flex align-items-center gap-2">
                    <Link size={16} />
                    URLs{" "}
                    {activeTab === "2" && (
                      <Badge count={totalCount} className="ms-2" />
                    )}
                  </div>
                ),
                children: (
                  <UrlListTable
                    urlList={urlList}
                    handleEditUrl={handleEditUrl}
                    handleDeleteModal={handleDeleteModal}
                    page_no={page_no}
                    limit={limit}
                    totalCount={totalCount}
                    handleChangePagination={handleChangePagination}
                    isLoading={isLoading}
                    permissions={props.permissions}
                  />
                ),
              },
            ]}
            onChange={(key) => {
              setActiveTab(key);
              delete UrlParams.page_size;
              let payload = {
                ...UrlParams,
                tab: key,
                page_no: 1,
              };
              let queryRequest = QueryRequestHelper(payload);
              navigate(`${window.location.pathname}?${queryRequest}`);
            }}
            tabBarExtraContent={{
              right: props.permissions.includes("create") && (
                <>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleUploadDocuments}
                    accept=".png,.jpg,.jpeg,.svg,.mp4,.mp3,.docx,.pdf,.doc,.xls,.xlsx,.ppt,.pptx,.txt,.csv"
                  />

                  <Button
                    type="primary"
                    icon={<Plus size={16} />}
                    onClick={() => {
                      if (activeTab === "1") {
                        handleFileInputClick();
                      } else {
                        toggleUrlModal();
                        setRequest({
                          name: "",
                          url: "",
                          type: "urls",
                        });
                      }
                    }}
                    loading={activeTab === "1" && uploadLoading}
                  >
                    {activeTab === "1" ? "Upload File" : "Add URL"}
                  </Button>
                </>
              ),
            }}
          />
        </div>
        <div className="p-4 w-30 sla-policy-right-details">
          <SettingsPageDetails data={mediaLibraryDetails} />
        </div>
      </div>
      <MediaLibraryUploadModal
        documentDetails={documentDetails}
        request={request}
        setRequest={setRequest}
        validator={simpleValidator}
        isUploadModalOpen={isUploadModalOpen}
        handleUrlOk={() => handleSubmit(request)}
        handleDocumentCancel={handleDocumentCancel}
        isLoading={isLoading1}
      />
      <MediaLibraryUrlModal
        editId={editId}
        request={request}
        setRequest={setRequest}
        validator={simpleValidator}
        isUrlModalOpen={isUrlModalOpen}
        handleUrlOk={() => handleSubmit(request)}
        handleUrlCancel={handleUrlCancel}
        isLoading={isLoading1}
      />
    </CompanyProfileDetailsLayout>
  );
};

export default MediaLibrary;
