import React, { useState, useEffect } from "react";
import { getValue } from "@utils/lodash";
import DashboardAgentAvailability from "./AgentAvailability";
import DashboardOverview from "./Overview";
import useDynamicTitle from "@context/useDynamicTitle";
import DashboardAgentPerformance from "./AgentPerformence";
import "./chat-dashboard.scss";
import NoAccessComponent from "@layouts/HomeLayout/noaccess";
import ListLoader from "@components/common/Loader";
import SidebarLayout from "@layouts/SidebarLayout/SidebarLayout";

function ChatDashboard(props: any) {
  useDynamicTitle("Chat Dashboard");
  const { view, setView, viewList } = props;

  // State to track chat_view from URL
  const [chatView, setChatView] = useState(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return getValue(
      Object.fromEntries(urlSearchParams.entries()),
      "chat_view",
      ""
    );
  });

  // Listen to URL changes
  useEffect(() => {
    const handleUrlChange = () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const newChatView = getValue(
        Object.fromEntries(urlSearchParams.entries()),
        "chat_view",
        ""
      );
      setChatView(newChatView);
    };

    window.addEventListener("popstate", handleUrlChange);
    return () => window.removeEventListener("popstate", handleUrlChange);
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);
  return (
    <div>
      {isLoading ? (
        <SidebarLayout {...props} isLoading={isLoading}>
          <ListLoader />
        </SidebarLayout>
      ) : (
        <>
          {getValue(props, `subTabPermissionList`, []).includes(
            "dashboard_chat_conversation_overview"
          ) ? (
            (chatView === "overview" || !chatView) && (
              <DashboardOverview
                view={view}
                setView={setView}
                setChatView={setChatView}
                viewList={viewList}
                {...props}
              />
            )
          ) : (
            <SidebarLayout {...props} isLoading={isLoading}>
              <NoAccessComponent />
            </SidebarLayout>
          )}

          {getValue(props, `subTabPermissionList`, []).includes(
            "dashboard_chat_conversation_agent_availability"
          ) ? (
            chatView === "agent_availability" && (
              <DashboardAgentAvailability
                view={view}
                setView={setView}
                setChatView={setChatView}
                viewList={viewList}
                {...props}
              />
            )
          ) : (
            <SidebarLayout {...props} isLoading={isLoading}>
              <NoAccessComponent />
            </SidebarLayout>
          )}

          {getValue(props, `subTabPermissionList`, []).includes(
            "dashboard_chat_conversation_agent_performance"
          ) ? (
            chatView === "agent_performance" && (
              <DashboardAgentPerformance
                view={view}
                setView={setView}
                setChatView={setChatView}
                viewList={viewList}
                {...props}
              />
            )
          ) : (
            <SidebarLayout {...props} isLoading={isLoading}>
              <NoAccessComponent />
            </SidebarLayout>
          )}
        </>
      )}
    </div>
  );
}

export default ChatDashboard;
