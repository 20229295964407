import React, { useState, useRef, useEffect } from "react";

interface CustomDropdownProps {
  trigger: React.ReactNode;
  menu: React.ReactNode;
  isOpen: boolean;
  sidebarWidth: number;
  forceOpen?: boolean;
  bookmarkDropdown?: boolean;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  trigger,
  menu,
  isOpen,
  sidebarWidth,
  forceOpen = false, // Default is false
  bookmarkDropdown = false,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(forceOpen);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const handleMouseEnter = () => {
    if (!forceOpen) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      setIsDropdownOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (!forceOpen) {
      timeoutRef.current = setTimeout(() => {
        setIsDropdownOpen(false);
      }, 100);
    }
  };

  const handleMenuMouseEnter = () => {
    if (!forceOpen && timeoutRef.current) clearTimeout(timeoutRef.current);
  };

  const handleMenuMouseLeave = () => {
    if (!forceOpen) setIsDropdownOpen(false);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    if (!forceOpen) {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target as Node) &&
          menuRef.current &&
          !menuRef.current.contains(event.target as Node)
        ) {
          setIsDropdownOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [forceOpen]);

  return (
    <div className="position-relative" ref={dropdownRef}>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {trigger}
      </div>
      {(isDropdownOpen || forceOpen) && (
        <div
          ref={menuRef}
          onMouseEnter={handleMenuMouseEnter}
          onMouseLeave={handleMenuMouseLeave}
          className="submenu-dropdown"
          style={{
            right: `-${sidebarWidth}px`,
            top: bookmarkDropdown ? "-8px" : "-60px",
            zIndex: 1000,
            minWidth: "200px",
            position: "absolute",
          }}
        >
          {menu}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
