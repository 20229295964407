import { getValue } from "@utils/lodash";
import { Button } from "antd";
import AIAgentFullLayout from "../../Layout/full-layout";
import "./index.scss";

const AIAgentMapToChannel = () => {
  const channels = [
    {
      brand: "Zapier",
      description: "Connect your chatbot with thousands of apps using Zapier.",
    },
    {
      brand: "Slack",
      description:
        "Connect your chatbot with Slack, mention it, and have it reply to any message.",
    },
    {
      brand: "Wordpress",
      description:
        "Use the official Appzo plugin for Wordpress to add the chat widget to your website.",
    },
    {
      brand: "Whatsapp",
      description:
        "Connect your chatbot to a WhatsApp number and let it respond to messages from your customers.",
    },
    {
      brand: "Messenger",
      description:
        "Connect your chatbot to a facebook page and let it respond to messages from your customers.",
    },
    {
      brand: "Instagram",
      description:
        "Connect your chatbot to a instagram page and let it respond to messages from your customers.",
    },
    {
      brand: "Shopify",
      description:
        "Let your chatbot interact with your customers on Shopify, respond to their queries, recommend products, help with orders, and more.",
    },
  ];
  return (
    <AIAgentFullLayout>
      <div className="map-to-channel-wrapper">
        {channels.map((item, index) => (
          <div className="p-4 map-to-channel-card" key={index}>
            <h6 className="header_text__16">{getValue(item, "brand", "")}</h6>
            <p className="small_text__14">
              {getValue(item, "description", "")}
            </p>
            <div className="d-flex justify-content-center mt-3">
              <Button>Subscribe to Enable</Button>
            </div>
          </div>
        ))}
      </div>
    </AIAgentFullLayout>
  );
};

export default AIAgentMapToChannel;
