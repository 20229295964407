import "./index.scss";
import ListLoader from "@components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import AIAgentFullLayout from "../../Layout/full-layout";
import AgentSidebar from "./components/sidebar";
import { toast } from "sonner";
import {
  addChatbotDocumentLink,
  chatbotDocumentList,
  chatbotDocumentText,
  chatbotDocumentqna,
  deletechatbotDocumentQna,
  deletechatbotDocumentText,
  trainChatbotDocumentQandA,
  trainChatbotDocumentText,
  trainChatbotDocumentWebsite,
} from "@services/chatbot-document.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  removeNullOrUndefinedProperties,
  removeNullValuesFromObjectArray,
} from "@common/text-helpers";
import { chatbotScrapeDocumentStatus } from "@services/chatbot.service";
import { removeNullOrUndefinedPropertiesForProperty } from "@common/text-helpers";
import { useStateContext } from "@context/dataContext";
import AIAgentSourceWebsite from "./Training/Website";
import AIAgentSourceText from "./Training/Text";
import AIAgentSourceQandA from "./Training/QandA";
import Pusher from "pusher-js";
import { config } from "env";
function AIAgentSources() {
  const navigate = useNavigate();
  const params = useParams();
  const { setUrls } = useStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<any>("1");

  const [request, setRequest] = useState({
    url_source_ids: [],
    texts_source: {
      name: "",
      texts: "",
    },
    chatbot_id: "",
    qna: [
      {
        question: "",
        answer: "",
      },
    ],
  });

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getUrlInfo();
    getQaInfo();
    getTextInfo();
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const [loading, setLoading] = useState(false);
  const [textInfo, setTextInfo] = useState({});
  const [qaInfo, setQaInfo] = useState({});

  const getUrlInfo = async () => {
    const payload = {
      chatbot_id: getValue(params, `chatbotId`, ""),
    };
    const queryRequest = QueryRequestHelper(payload);
    const resp = await chatbotDocumentList(queryRequest);
    if (resp) {
      setUrls(getValue(resp, `data`, []));
      setLinkUrls(getValue(resp, `data`, []));
    }
  };
  const getQaInfo = async () => {
    const payload = {
      chatbot_id: getValue(params, `chatbotId`, ""),
    };
    const queryRequest = QueryRequestHelper(payload);
    const resp = await chatbotDocumentqna(queryRequest);
    if (resp) {
      setQaInfo(getValue(resp, `data`, {}));
      const list = getValue(resp, `data`, []).map((item: object) => ({
        ...getValue(item, `chatbot_document[${0}]`, {}),
        ...item,
      }));
      setRequest((prevRequest: any) => ({
        ...prevRequest,
        qna: list,
      }));
    }
  };
  const getTextInfo = async () => {
    const payload = {
      chatbot_id: getValue(params, `chatbotId`, ""),
    };
    const queryRequest = QueryRequestHelper(payload);
    const resp = await chatbotDocumentText(queryRequest);
    if (resp) {
      setTextInfo(getValue(resp, `data`, {}));
      setRequest((prevRequest: any) => ({
        ...prevRequest,
        texts_source: {
          texts: getValue(resp, `data.chatbot_document[${0}].texts`, ""),
          name: getValue(resp, `data.chatbot_document[${0}].texts`, ""),
        },
      }));
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                                Add Link section                            */
  /* -------------------------------------------------------------------------- */
  const [linkURLs, setLinkUrls] = useState<any>([]);
  const handleAddLink = async (url: string) => {
    try {
      let resp = await addChatbotDocumentLink({
        url: url,
        chatbot_id: getValue(params, `chatbotId`, ""),
      });
      if (resp) {
        toast.success("Added Successfully");
        getUrlInfo();
      }
    } catch (error) {}
  };
  const handleSaveURL = async () => {
    let links = linkURLs.filter((item: object) => !getValue(item, `id`, ""));
    links.map((item: object) => {
      return handleAddLink(getValue(item, `url`, ""));
    });
  };
  const findStaticUrl = () => {
    let links = linkURLs.filter((item: object) => !getValue(item, `id`, ""));
    if (getValue(links, `length`, 0) > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleDeleteText = async () => {
    try {
      let resp = await deletechatbotDocumentText(
        getValue(params, `chatbotId`, ""),
        { id: getValue(textInfo, `id`, "") }
      );
      if (resp) {
        toast.success("Deleted Successfully");
        getTextInfo();
      }
    } catch (error) {}
  };
  const handleDeleteQa = async (id: string) => {
    try {
      let resp = await deletechatbotDocumentQna(
        getValue(params, `chatbotId`, ""),
        { ids: [id] }
      );
      if (resp) {
        toast.success("Deleted Successfully");
        getQaInfo();
      }
    } catch (error) {}
  };

  const [scrapeInfo, setScrapeInfo] = useState({});
  const ScrapedDocumentStatus = async () => {
    try {
      const payload = {
        chatbot_id: getValue(params, `chatbotId`, ""),
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await chatbotScrapeDocumentStatus(queryRequest);
      if (resp) {
        setScrapeInfo(getValue(resp, `data`, {}));
      }
    } catch (error) {}
  };
  const websiteSocket = () => {
    // Get existing pusher instance
    const pusher = new Pusher(config.PUSHER_APP_KEY, {
      cluster: config.PUSHER_APP_CLUSTER,
    });
    // Subscribe to the channel
    const channel = pusher.subscribe(
      `webiste-extract-channel-${getValue(params, `chatbotId`, "")}`
    );
    // Set up listeners
    channel.bind("webiste-train", (data: any) => {
      // console.log("Received message:", data);

      // Check if training is complete
      if (data && data.status === "success") {
        // Cleanup when training is complete
        channel.unbind_all();
        pusher.unsubscribe(
          `webiste-extract-channel-${getValue(params, `chatbotId`, "")}`
        );
      }
    });

    // Cleanup function
    return () => {
      channel.unbind_all();
      pusher.unsubscribe(
        `webiste-extract-channel-${getValue(params, `chatbotId`, "")}`
      );
    };
  };
  const qnaSocket = () => {
    // Get existing pusher instance
    const pusher = new Pusher(config.PUSHER_APP_KEY, {
      cluster: config.PUSHER_APP_CLUSTER,
    });

    // Subscribe to the channel
    const channel = pusher.subscribe(
      `qna-training-channel-${getValue(params, `chatbotId`, "")}`
    );
    // Set up listeners
    channel.bind("qna-event", (data: any) => {
      // console.log("data", data);
      // console.log("Received message:", data);
      setQaInfo((prevQaInfo: any) => ({
        ...prevQaInfo,
        status: data.status,
      }));

      // Check if training is complete
      if (data && (data.status === "trained" || data.status === "success")) {
        // Cleanup when training is complete
        getQaInfo();
        channel.unbind_all();
        pusher.unsubscribe(
          `qna-training-channel-${getValue(params, `chatbotId`, "")}`
        );
      }
    });

    // Cleanup function
    return () => {
      channel.unbind_all();
      pusher.unsubscribe(
        `qna-training-channel-${getValue(params, `chatbotId`, "")}`
      );
    };
  };
  const textSocket = () => {
    // Get existing pusher instance
    const pusher = new Pusher(config.PUSHER_APP_KEY, {
      cluster: config.PUSHER_APP_CLUSTER,
    });

    // Subscribe to the channel
    const channel = pusher.subscribe(
      `text-document-channel-${getValue(params, `chatbotId`, "")}`
    );

    // Set up listeners
    channel.bind("text-document-train", (data: any) => {
      // console.log("Received message:", data);
      setTextInfo((prevTextInfo: any) => ({
        ...prevTextInfo,
        status: data.status,
      }));
      // Check if training is complete
      if (data && (data.status === "trained" || data.status === "success")) {
        // Cleanup when training is complete
        channel.unbind_all();
        pusher.unsubscribe(
          `text-document-channel-${getValue(params, `chatbotId`, "")}`
        );
      }
    });

    // Cleanup function
    return () => {
      channel.unbind_all();
      pusher.unsubscribe(
        `text-document-channel-${getValue(params, `chatbotId`, "")}`
      );
    };
  };

  const [disableTrain, setDisableTrain] = useState(false);

  const handleTrain = async () => {
    let payload = {};
    let cleanupFunction = null;

    // Disable the train button while processing
    setDisableTrain(true);

    // Initialize the appropriate socket based on activeTab
    if (activeTab == 1) {
      cleanupFunction = websiteSocket();
      payload = removeNullOrUndefinedPropertiesForProperty({
        url_source_ids: linkURLs.map((item: object) =>
          getValue(item, `id`, "")
        ),
        chatbot_id: getValue(params, `chatbotId`, ""),
        qna: removeNullValuesFromObjectArray(getValue(request, `qna`, [])),
        texts_source: removeNullOrUndefinedProperties(
          getValue(request, `texts_source`, {})
        ),
      });
    } else if (activeTab == 2) {
      cleanupFunction = textSocket();
      payload = {
        chatbot_id: getValue(params, `chatbotId`, ""),
        texts_source: removeNullOrUndefinedProperties(
          getValue(request, `texts_source`, {})
        ),
      };
    } else {
      cleanupFunction = qnaSocket();
      payload = {
        chatbot_id: getValue(params, `chatbotId`, ""),
        qna: removeNullValuesFromObjectArray(getValue(request, `qna`, [])),
      };
    }

    try {
      let resp;
      if (activeTab == 1) {
        resp = await trainChatbotDocumentWebsite(payload);
      } else if (activeTab == 2) {
        resp = await trainChatbotDocumentText(payload);
      } else if (activeTab == 3) {
        resp = await trainChatbotDocumentQandA(payload);
      }

      if (resp) {
        toast.success(`Training started successfully`);

        // Set up a listener for the "trained" event to unsubscribe
        // For now, we'll use a timeout as a fallback mechanism to ensure cleanup
        setTimeout(() => {
          if (cleanupFunction) {
            cleanupFunction();
          }

          // Enable train button after timeout if not website tab
          // For website tab, this will be handled by the websiteURLSocket
          if (activeTab !== 1) {
            setDisableTrain(false);
          }
        }, 30000); // 30 seconds timeout, adjust as needed

        if (activeTab == 1) {
          const payload = {
            chatbot_id: getValue(params, `chatbotId`, ""),
          };
          const queryRequest = QueryRequestHelper(payload);
          const resp = await chatbotDocumentList(queryRequest);
          if (resp) {
            const list = getValue(resp, `data`, []);

            // Check if there are any URLs to process
            if (list.length === 0) {
              setDisableTrain(false);
            } else {
              websiteURLSocket();
            }
          }
        } else if (activeTab == 2) {
          getTextInfo();
          setDisableTrain(false);
        } else if (activeTab == 3) {
          getQaInfo();
          setDisableTrain(false);
        }
      }
    } catch (error) {
      // Handle error
      if (cleanupFunction) {
        cleanupFunction();
      }
      // Re-enable train button on error
      setDisableTrain(false);
    }
  };

  const websiteURLSocket = () => {
    // Get existing pusher instance
    const pusher = new Pusher(config.PUSHER_APP_KEY, {
      cluster: config.PUSHER_APP_CLUSTER,
    });
    // Subscribe to the channel
    const channel = pusher.subscribe(
      `url-training-channel-${getValue(params, `chatbotId`, "")}`
    );
    // Set up listeners
    channel.bind("url-event", (data: any) => {
      // console.log("data", data);

      // Update linkURLs based on matching ID
      setLinkUrls((prevLinkUrls: any) =>
        prevLinkUrls.map((item: object) =>
          getValue(item, `id`, "") === data.id
            ? { ...item, status: data.status, progress: data.progress }
            : item
        )
      );

      // Check for training completion
      if (data && data.status === "success") {
        // Cleanup for this specific URL channel
        channel.unbind_all();
        pusher.unsubscribe(
          `url-training-channel-${getValue(params, "chatbotId", "")}`
        );
      }

      // Check if all URLs are trained
      setTimeout(() => {
        setLinkUrls((currentLinkUrls: any) => {
          const allTrained = currentLinkUrls.every(
            (item: any) => item.status === "trained"
          );

          if (allTrained) {
            setDisableTrain(false);
          }

          return currentLinkUrls;
        });
      }, 0);
    });

    // Cleanup function
    return () => {
      channel.unbind_all();
      pusher.unsubscribe(
        `url-training-channel-${getValue(params, `chatbotId`, "")}`
      );
    };
  };
  return (
    <AIAgentFullLayout>
      {isLoading ? (
        <ListLoader />
      ) : (
        <div className="sources-container">
          <div className="sources-layout">
            <AgentSidebar activeTab={activeTab} setActiveTab={setActiveTab} />
            {activeTab == "1" && (
              <AIAgentSourceWebsite
                request={request}
                setRequest={setRequest}
                handleAddLink={handleAddLink}
                linkURLs={linkURLs}
                setLinkUrls={setLinkUrls}
                scrapeInfo={scrapeInfo}
                loading={loading}
                handleSaveURL={handleSaveURL}
                findStaticUrl={findStaticUrl}
                handleTrainLink={handleTrain}
                ScrapedDocumentStatus={ScrapedDocumentStatus}
                activeTab={activeTab}
                getUrlInfo={getUrlInfo}
                websiteSocket={websiteSocket}
                websiteURLSocket={websiteURLSocket}
                disableTrain={disableTrain}
              />
            )}
            {activeTab === "2" && (
              <AIAgentSourceText
                request={request}
                setRequest={setRequest}
                scrapeInfo={scrapeInfo}
                loading={loading}
                handleSaveURL={handleSaveURL}
                findStaticUrl={findStaticUrl}
                handleTrainLink={handleTrain}
                ScrapedDocumentStatus={ScrapedDocumentStatus}
                activeTab={activeTab}
                textInfo={textInfo}
                handleDeleteText={handleDeleteText}
              />
            )}
            {activeTab == "3" && (
              <AIAgentSourceQandA
                request={request}
                setRequest={setRequest}
                scrapeInfo={scrapeInfo}
                loading={loading}
                handleSaveURL={handleSaveURL}
                findStaticUrl={findStaticUrl}
                handleTrainLink={handleTrain}
                ScrapedDocumentStatus={ScrapedDocumentStatus}
                activeTab={activeTab}
                qaInfo={qaInfo}
                handleDeleteQa={handleDeleteQa}
              />
            )}
          </div>
        </div>
      )}
    </AIAgentFullLayout>
  );
}

export default AIAgentSources;
