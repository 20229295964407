// import React, { useEffect, useState } from "react";
// import { Doughnut } from "react-chartjs-2";
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
// import { capitalizeFirstLetter } from "@common/text-helpers";
// import { findLabelFromKeyItem } from "@pages/Private/NewDashboard/helpers/enum";
// import { Select } from "antd";
// import { Bot, ChevronDown, Users } from "lucide-react";

// // Register the necessary components
// ChartJS.register(ArcElement, Tooltip, Legend);

// function TeamChart(props: any) {
//   const [data, setData] = useState<any>({
//     labels: [],
//     datasets: [],
//   });

//   useEffect(() => {
//     const graphData = props.graph || props.data || {};

//     let labels: string[] = [];
//     let dataValues: number[] = [];
//     let backgroundColors: string[] = [];
//     let centerText = "";

//     if (Array.isArray(graphData)) {
//       if (graphData.length > 0) {
//         const colorPalette = [
//           "#4B9CFC", // Blue for "New"
//           "#FF8C38", // Orange for "Closed"
//           "#A259FF",
//           "#00C4B4",
//           "#FF6B6B",
//         ];

//         const filteredData = graphData.filter((item) => (item.count || 0) > 0);

//         if (filteredData.length > 0) {
//           labels = filteredData.map((item) => {
//             const key = findLabelFromKeyItem
//               ? findLabelFromKeyItem(item)
//               : Object.keys(item)[0];
//             return key === "new" ? "New" : capitalizeFirstLetter(key);
//           });
//           dataValues = filteredData.map((item) => item.count || 0);
//           backgroundColors = filteredData.map(
//             (_, index) => colorPalette[index % colorPalette.length]
//           );
//           centerText = dataValues.reduce((a, b) => a + b, 0).toString();
//         }
//       }
//     } else if (typeof graphData === "object") {
//       const entries = Object.entries(graphData).filter(
//         ([_, value]) => (value as number) > 0
//       );
//       if (entries.length > 0) {
//         const colorPalette = [
//           "#4B9CFC",
//           "#FF8C38",
//           "#A259FF",
//           "#00C4B4",
//           "#FF6B6B",
//         ];

//         labels = entries.map(([key, _]) => {
//           const formattedKey = key.replace(/_/g, " ");
//           return formattedKey === "new"
//             ? "New"
//             : capitalizeFirstLetter(formattedKey);
//         });
//         dataValues = entries.map(([_, value]) => value as number);
//         backgroundColors = entries.map(
//           (_, index) => colorPalette[index % colorPalette.length]
//         );
//         centerText = dataValues.reduce((a, b) => a + b, 0).toString();
//       }
//     }

//     // If no valid data after filtering
//     if (dataValues.length === 0) {
//       labels = ["No Data"];
//       dataValues = [1];
//       backgroundColors = ["#E5E7EB"];
//       centerText = "0";
//     }

//     const label = props.module_field?.label || "Status Distribution";

//     setData({
//       labels: labels,
//       datasets: [
//         {
//           label: label,
//           data: dataValues,
//           backgroundColor: backgroundColors,
//           borderColor: "#ffffff",
//           borderWidth: 2,
//           hoverOffset: 15,
//         },
//       ],
//     });
//   }, [props.graph, props.data, props.module_field?.label, props.source]);

//   const options: any = {
//     responsive: true,
//     maintainAspectRatio: false,
//     cutout: "70%",
//     plugins: {
//       legend: {
//         position: "right",
//         labels: {
//           padding: 20,
//           boxWidth: 12,
//           font: {
//             size: 14,
//             weight: "500",
//           },
//           color: "#1F2937",
//           generateLabels: (chart: any) => {
//             const { data } = chart;
//             if (data.labels.length && data.datasets.length) {
//               return data.labels.map((label: string, i: number) => {
//                 const value = data.datasets[0].data[i];
//                 const isSelected =
//                   props.source === label.toLowerCase() ||
//                   (props.source === "" && i === 0); // Highlight based on source
//                 return {
//                   text: isSelected
//                     ? `${label} - ${value}`
//                     : `${label}: ${value}`, // Add dash for selected
//                   fillStyle: data.datasets[0].backgroundColor[i],
//                   strokeStyle: data.datasets[0].borderColor,
//                   lineWidth: data.datasets[0].borderWidth,
//                   hidden: false,
//                   index: i,
//                   font: {
//                     weight: isSelected ? "bold" : "500",
//                   },
//                 };
//               });
//             }
//             return [];
//           },
//         },
//       },
//       tooltip: {
//         backgroundColor: "rgba(0,0,0,0.8)",
//         titleFont: { size: 14 },
//         bodyFont: { size: 12 },
//         padding: 12,
//         cornerRadius: 8,
//         callbacks: {
//           label: function (context: any) {
//             const label = context.label || "";
//             const value = context.raw || 0;
//             const dataset = context.dataset;
//             const total = dataset.data.reduce(
//               (acc: number, data: number) => acc + data,
//               0
//             );
//             const percentage =
//               total > 0 ? Math.round((value / total) * 100) : 0;
//             return `${label.split(":")[0]}: ${value} (${percentage}%)`; // Adjust for dash format
//           },
//         },
//       },
//       // Custom plugin to show center text
//       beforeDraw: (chart: any) => {
//         const ctx = chart.ctx;
//         const width = chart.width;
//         const height = chart.height;
//         ctx.save();
//         ctx.textAlign = "center";
//         ctx.textBaseline = "middle";
//         ctx.font = "bold 24px sans-serif";
//         ctx.fillStyle = "#6B7280";
//         ctx.fillText(
//           data.datasets[0]?.data.length > 0
//             ? data.datasets[0].data
//                 .reduce((a: number, b: number) => a + b, 0)
//                 .toString()
//             : "0",
//           width / 2,
//           height / 2
//         );
//         ctx.restore();
//       },
//     },
//     animation: {
//       animateScale: true,
//       animateRotate: true,
//     },
//   };

//   const handleClickSource = (value: any) => {
//     props.setSource(value);
//     props.getDashboardData(value);
//   };

//   const dashboardSourceFilters = [
//     { label: "All", value: "" },
//     { label: "Whatsapp", value: "whatsapp" },
//     { label: "Webchat", value: "webchat" },
//   ];

//   return (
//     <div
//       className={`team-chart-container ${props.isMaximized ? "maximized" : ""}`}
//     >
//       <div className="team-chart-header">
//         <div className="title-section">
//           <Bot size={20} />
//           <h6 className="header_text__16">Chatbot</h6>
//         </div>

//         <Select
//           options={dashboardSourceFilters}
//           value={props.source}
//           onChange={(value) => handleClickSource(value)}
//           bordered={false}
//           suffixIcon={<ChevronDown size={16} />}
//           dropdownStyle={{ minWidth: 180 }}
//         />
//       </div>

//       <div className="team-chart-wrapper">
//         <Doughnut data={data} options={options} />
//       </div>
//     </div>
//   );
// }

// export default TeamChart;

import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { capitalizeFirstLetter } from "@common/text-helpers";
import { findLabelFromKeyItem } from "@pages/Private/NewDashboard/helpers/enum";
import { Select } from "antd";
import { ChevronDown, Users, Maximize2, Minimize2, Bot } from "lucide-react";

// Register the necessary components
ChartJS.register(ArcElement, Tooltip, Legend);

function TeamChart(props: any) {
  const [data, setData] = useState<any>({
    labels: [],
    datasets: [],
  });
  const [showLegend, setShowLegend] = useState(false);

  useEffect(() => {
    const graphData = props.graph || props.data || {};

    let labels: string[] = [];
    let dataValues: number[] = [];
    let backgroundColors: string[] = [];
    let centerText = "";

    if (Array.isArray(graphData)) {
      if (graphData.length > 0) {
        const colorPalette = [
          "#64b1fc", // Blue for "New"
          "#fac486", // Orange for "Closed"
          "#f27a7b",
          "#00C4B4",
          "#cccccc",
        ];

        const filteredData = graphData.filter((item) => (item.count || 0) > 0);

        if (filteredData.length > 0) {
          labels = filteredData.map((item) => {
            const key = findLabelFromKeyItem
              ? findLabelFromKeyItem(item)
              : Object.keys(item)[0];
            return key === "new" ? "New" : capitalizeFirstLetter(key);
          });
          dataValues = filteredData.map((item) => item.count || 0);
          backgroundColors = filteredData.map(
            (_, index) => colorPalette[index % colorPalette.length]
          );
          centerText = dataValues.reduce((a, b) => a + b, 0).toString();
        }
      }
    } else if (typeof graphData === "object") {
      const entries = Object.entries(graphData).filter(
        ([_, value]) => (value as number) > 0
      );
      if (entries.length > 0) {
        const colorPalette = [
          "#64b1fc", // Blue for "New"
          "#fac486", // Orange for "Closed"
          "#f27a7b",
          "#00C4B4",
          "#cccccc",
        ];

        labels = entries.map(([key, _]) => {
          const formattedKey = key.replace(/_/g, " ");
          return formattedKey === "new"
            ? "New"
            : capitalizeFirstLetter(formattedKey);
        });
        dataValues = entries.map(([_, value]) => value as number);
        backgroundColors = entries.map(
          (_, index) => colorPalette[index % colorPalette.length]
        );
        centerText = dataValues.reduce((a, b) => a + b, 0).toString();
      }
    }

    // If no valid data after filtering
    if (dataValues.length === 0) {
      labels = ["No Data"];
      dataValues = [1];
      backgroundColors = ["#E5E7EB"];
      centerText = "0";
    }

    const label = props.module_field?.label || "Status Distribution";

    setData({
      labels: labels,
      datasets: [
        {
          label: label,
          data: dataValues,
          backgroundColor: backgroundColors,
          borderColor: "#ffffff",
          borderWidth: 2,
          hoverOffset: 15,
        },
      ],
    });
  }, [props.graph, props.data, props.module_field?.label, props.source]);

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    plugins: {
      legend: {
        display: showLegend,
        position: "right",
        labels: {
          padding: 20,
          boxWidth: 12,
          font: {
            size: 14,
            weight: "500",
          },
          color: "#000000", // Changed to black
          generateLabels: (chart: any) => {
            const { data } = chart;
            if (data.labels.length && data.datasets.length) {
              return data.labels.map((label: string, i: number) => {
                const value = data.datasets[0].data[i];
                const isSelected =
                  props.source === label.toLowerCase() ||
                  (props.source === "" && i === 0); // Highlight based on source
                return {
                  text: isSelected
                    ? `${label} - ${value}`
                    : `${label}: ${value}`,
                  fillStyle: data.datasets[0].backgroundColor[i],
                  strokeStyle: data.datasets[0].borderColor,
                  lineWidth: data.datasets[0].borderWidth,
                  hidden: false,
                  index: i,
                  font: {
                    weight: isSelected ? "bold" : "500",
                  },
                };
              });
            }
            return [];
          },
        },
      },
      tooltip: {
        backgroundColor: "rgba(0,0,0,0.8)",
        titleFont: { size: 14 },
        bodyFont: { size: 12 },
        padding: 12,
        cornerRadius: 8,
        callbacks: {
          label: function (context: any) {
            const label = context.label || "";
            const value = context.raw || 0;
            const dataset = context.dataset;
            const total = dataset.data.reduce(
              (acc: number, data: number) => acc + data,
              0
            );
            const percentage =
              total > 0 ? Math.round((value / total) * 100) : 0;
            return `${label.split(":")[0]}: ${value} (${percentage}%)`;
          },
        },
      },
      // Custom plugin to show center text
      beforeDraw: (chart: any) => {
        const ctx = chart.ctx;
        const width = chart.width;
        const height = chart.height;
        ctx.save();
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = "bold 24px sans-serif";
        ctx.fillStyle = "#6B7280";
        ctx.fillText(
          data.datasets[0]?.data.length > 0
            ? data.datasets[0].data
                .reduce((a: number, b: number) => a + b, 0)
                .toString()
            : "0",
          width / 2,
          height / 2
        );
        ctx.restore();
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };

  const handleClickSource = (value: any) => {
    props.setSource(value);
    props.getDashboardData(value);
  };
  
  const toggleLegend = () => {
    setShowLegend(!showLegend);
  };

  const dashboardSourceFilters = [
    { label: "All", value: "" },
    { label: "Whatsapp", value: "whatsapp" },
    { label: "Webchat", value: "webchat" },
  ];

  return (
    <div
      className={`team-chart-container ${props.isMaximized ? "maximized" : ""}`}
    >
      <div className="team-chart-header">
        <div className="title-section">
        <Bot size={20} />
          <h6 className="header-text">Chatbot</h6>
        </div>
        <div className="chart-controls">
          <button 
            onClick={toggleLegend}
            className="legend-toggle-btn"
            style={{ background: "none", border: "none", cursor: "pointer", marginRight: "10px" }}
          >
            {showLegend ? <Minimize2 size={16} /> : <Maximize2 size={16} />}
          </button>
          
          <Select
            options={dashboardSourceFilters}
            value={props.source}
            onChange={(value) => handleClickSource(value)}
            bordered={false}
            suffixIcon={<ChevronDown size={16} />}
            dropdownStyle={{ minWidth: 180 }}
          />
        </div>
      </div>
      <div className="team-chart-wrapper">
        <Doughnut data={data} options={options} />
      </div>
    </div>
  );
}

export default TeamChart;