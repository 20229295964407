import { DeleteOutlined } from "@ant-design/icons";
import { capitalizeFirstLetter } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Button, Table } from "antd";
import { Plus } from "lucide-react";
import React from "react";

const GroupsAgents = (props: any) => {
  const {
    isLoading2,
    groupDetails,
    setSelectedAgentIds,
    showModal,
    selectedAgentIds,
    handleDeleteAgent,
  } = props;

  const columns = [
    {
      title: "NAME",
      dataIndex: "agent",
      key: "agent",
      render: (agent: any) => (
        <>
          <h6 className="header_text__14">
            {capitalizeFirstLetter(getValue(agent, "first_name", "")) +
              " " +
              capitalizeFirstLetter(getValue(agent, "last_name", ""))}
          </h6>
        </>
      ),
    },
    {
      title: "EMAIL",
      dataIndex: "agent",
      key: "agent",
      render: (agent: any) => getValue(agent, "user.email", "-"),
    },
  ];

  const handleRowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      const updatedAgents = selectedRowKeys.map((key) => ({
        id: key as string,
      }));
      setSelectedAgentIds(updatedAgents);
    },
  };

  return (
    <div>
      <div className="d-flex justify-content-end align-items-center gap-2">
        {selectedAgentIds.length > 0 && (
          <Button
            danger
            type="primary"
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteAgent()}
          >
            Delete Agent{selectedAgentIds.length > 1 ? "'s" : ""}
          </Button>
        )}{" "}
        <Button icon={<Plus size={16} />} onClick={() => showModal()}>
          Add Agents
        </Button>
      </div>
      <Table
        size="middle"
        bordered
        columns={columns}
        dataSource={getValue(groupDetails, "agents", [])}
        rowKey={(record: any) => record.agent_id}
        loading={isLoading2}
        className="mt-3"
        rowSelection={{
          type: "checkbox",
          ...handleRowSelection,
        }}
        pagination={{
          showTotal: (total, range) => `${total} Agents`,
        }}
      />
    </div>
  );
};

export default GroupsAgents;
