import { getValue } from "@utils/lodash";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";

function AddCannedResponseVariablePopup(props: any) {
  const { isOpen, handleModal, handleAddTags, allTags, adminTags } = props;

  return (
    <SlideFromRightPopup
      isPopupVisible={isOpen}
      addCustomClass={"rolls-popup"}
      title={`Variables`}
      toggle={handleModal}
      width={1000}
      permissions={getValue(props, `permissions`, [])}
    >
      <div className="subpipeline-wrapper p-4">
        <div className="px-3 pb-3">
          <p className="sample--template-text mt-1">
            Use variable as placeholders. Variables are filled in automatically
            when they are sent
          </p>
        </div>

        <p className="note mt-3 mb-3 header_blue_text__16">Internal Variables</p>
        <div className="border-bottom"></div>
        <div className="d-flex gap-3 flex-wrap mt-3">
          {getValue(adminTags, `length`, 0) > 0 &&
            adminTags.map((item: any, index: number) => (
              <button
                key={index}
                className="dashboard-button  hover-text touchable-text-white px-4 py-2"
                onClick={() => {
                  handleAddTags(item);
                  handleModal();
                }}
              >
                {getValue(item, `label`, "")}
              </button>
            ))}
        </div>
        <p className="note mt-3 mb-3 header_blue_text__16">External Variables</p>
        <div className="border-bottom"></div>
        <div className="d-flex gap-3 flex-wrap mt-3">
          {getValue(allTags, `length`, 0) > 0 &&
            allTags.map((item: any, index: number) => (
              <button
                key={index}
                className="dashboard-button  hover-text touchable-text-white px-4 py-2"
                onClick={() => {
                  handleAddTags(item);
                  handleModal();
                }}
              >
                {getValue(item, `label`, "")}
              </button>
            ))}
        </div>
      </div>
    </SlideFromRightPopup>
  );
}

export default AddCannedResponseVariablePopup;
