import React, { useEffect, useState } from "react";
// import "./messages.scss";
import { useNavigate } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import {
  assignInboxChatbot,
  getWhatsappManagerConfig,
  syncWhatsappPhoneNumbers,
  updateWhatsappManagerConfig,
  whatsappManagerAccess,
  whatsappRegisterPhone,
  whatsappWebhookSubscribe,
} from "@services/fb.service";
import { getValue } from "@utils/lodash";
import FacebookLogin from "social/facebook-login";
import { QueryRequestHelper } from "@common/query-request-helper";
import { toast } from "sonner";
import RegisterPhonePopup from "../components/register-phone-popup";
import { getAllChatbots } from "@services/chatbot.service";
import {
  formatString,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import WhatsappNoDataPage from "@components/common/NoData/whatsapp-nodata";
import ListLoader from "@components/common/Loader";
import { Button, Table, Switch } from "antd";
import LoaderMain from "@components/common/Loader/loading1";
import ChatSettingsAssignPopup from "../components/settings-popup";
import { allPipelines } from "@services/pipeline.service";
import { useStateContext } from "@context/dataContext";
import { CircleCheck, CircleX, RefreshCw, Settings } from "lucide-react";

function Messages() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  const [available, setAvailable] = useState(false);
  const toggleAvailable = () => {
    setAvailable(!available);
  };
  const { allModules } = useStateContext();
  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getFbConfig();
    getChatbotData();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(true);
  const [configList, setConfigList] = useState([]);
  const [agentsList, setAgentsList] = useState([]);
  const getFbConfig = async () => {
    try {
      const payload = {
        // status: "active",
      };
      const queryRequest = QueryRequestHelper(payload);
      let resp = await getWhatsappManagerConfig(queryRequest);
      if (resp) {
        setConfigList(
          getValue(resp, `data.details.length`, 0) > 0
            ? getValue(resp, `data.details`, []).map((item: any) => ({
                ...item,
                status: item.is_active ? "active" : "inactive",
              }))
            : []
        );
        setRequest({
          ...request,
          waAccountId: getValue(resp, `data.details[${0}].id`, ""),
          managedBy: getValue(resp, `data.details[${0}].managedBy`, ""),
          chatBotId: getValue(resp, `data.details[${0}].chatBotId`, ""),
          team_member_id: getValue(
            resp,
            `data.details[${0}].team_member.id`,
            ""
          ),
          creation_rule: getValue(resp, `data.details[${0}].creation_rule`, "")
            ? getValue(resp, `data.details[${0}].creation_rule`, "")
            : "do_nothing",
          module_id: getValue(resp, `data.details[${0}].module.id`, ""),
          pipeline_id: getValue(resp, `data.details[${0}].pipeline.id`, ""),
          pipeline_stage_id: getValue(
            resp,
            `data.details[${0}].pipeline_stage.id`,
            ""
          ),
          assignment_type: getValue(
            resp,
            `data.details[${0}].assignment_type`,
            ""
          )
            ? getValue(resp, `data.details[${0}].assignment_type`, "")
            : "assign_to_previously_assigned_agent",
        });
        if (getValue(resp, `data.details[${0}].module.id`, "")) {
          getAllPipelineList(
            getValue(resp, `data.details[${0}].module.id`, ""),
            getValue(resp, `data.details[${0}].creation_rule`, "")
          );
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [pipelineList, setPipelineList] = useState([]);
  const [stageList, setStageList] = useState([]);

  const getAllPipelineList = async (module: string, rule: string) => {
    let moudleName = allModules.filter(
      (item: object) => getValue(item, `id`, "") === module
    );
    try {
      let payload = {
        module_name: getValue(moudleName, `[${0}].name`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await allPipelines(module, queryRequest);
      if (resp) {
        setPipelineList(
          getValue(resp, `data`, []).map((item: object) => ({
            name: getValue(item, `api_name`, ""),
            label: getValue(item, `label`, ""),
            id: getValue(item, `id`, ""),
            value: getValue(item, `id`, ""),
          }))
        );
        setStageList(
          getValue(resp, `data[${0}].stages`, []).map((item: object) => ({
            ...item,
          }))
        );

        if (rule === "create_contact") {
          setRequest((prevState: any) => ({
            ...prevState,
            module_id: getValue(resp, `data[${0}].module_id`, ""),
            pipeline_id: getValue(resp, `data[${0}].id`, ""),
            pipeline_stage_id: getValue(resp, `data[${0}].stages[${0}].id`, ""),
          }));
        }
        if (rule === "create_lead") {
          setRequest((prevState: any) => ({
            ...prevState,
            module_id: getValue(resp, `data[${0}].module_id`, ""),
            pipeline_id: getValue(resp, `data[${0}].id`, ""),
            pipeline_stage_id: getValue(resp, `data[${0}].stages[${0}].id`, ""),
          }));
        }
      } else {
        setPipelineList([]);
        setStageList([]);
      }
    } catch (error) {}
  };

  const handleSubmit = async (accessToken: string) => {
    if (accessToken)
      try {
        let payload = {
          accessCode: accessToken,
        };
        let resp = await whatsappManagerAccess(payload);
        if (resp) {
          toast.success("Subscribed successfully");
          getFbConfig();
        }
      } catch (error) {}
  };

  const [isOpen, setIsOpen] = useState(false);
  const [accountId, setAccountId] = useState("");
  const handleModal = async () => {
    setIsOpen(!isOpen);
  };

  const handleRegisterPhone = (id: string) => {
    setAccountId(id);
    handleModal();
  };

  const [otpLoading, setOtpLoading] = useState(false);
  const [opt, setOpt] = useState(true);
  const registerPhone = async (otp: string) => {
    try {
      let payload = {
        pinCode: otp,
        waAccountId: accountId,
      };
      setOtpLoading(true);
      let resp = await whatsappRegisterPhone(payload);
      if (resp) {
        toast.success("Submitted successfully");
        handleModal();
        setOpt(true);
        setOtpLoading(false);
        setAccountId("");
        getFbConfig();
      } else {
        setOtpLoading(false);
      }
    } catch (error) {
      setOtpLoading(false);
      handleModal();
    }
  };

  const webhookSubscribe = async (id: string) => {
    try {
      let payload = {
        waAccountId: id,
      };
      let resp = await whatsappWebhookSubscribe(payload);
      if (resp) {
        toast.success("Subscribed successfully");
        getFbConfig();
      }
    } catch (error) {}
  };

  const handleSyncPhoneNumbers = async () => {
    try {
      let resp = await syncWhatsappPhoneNumbers("");
      if (resp) {
        getFbConfig();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Assign section                             */
  /* -------------------------------------------------------------------------- */

  const [isOpen1, setIsOpen1] = useState(false);
  const [assignId, setAssignId] = useState("");
  const handleModal1 = async () => {
    setIsOpen1(!isOpen1);
  };
  const handleAssign = (id: string) => {
    setActiveTab("1");
    const connectionInfo: any = configList.find(
      (item: object) => getValue(item, `id`, "") === id
    );
    setAgentsList(getValue(connectionInfo, `agents`, []));

    setAssignId(id);
    let findInfo: any = configList.find(
      (item: object) => getValue(item, `id`, "") === id
    );
    setAssignRequest((preState) => ({
      ...preState,
      waAccountId: id,
      chatBotId: getValue(findInfo, `chatBotId`, ""),
      managedBy: getValue(findInfo, `managedBy`, ""),
    }));
    handleModal1();
  };

  const [assignRequest, setAssignRequest] = useState({
    waAccountId: "",
    managedBy: "team",
    chatBotId: "",
    assign_same_user: false,
  });
  const [chatbotList, setChatbotList] = useState([]);
  const [aiAgentList, setAiAgentList] = useState([]);

  const getChatbotData = async () => {
    try {
      setIsLoading(true);
      const payload = {
        page_size: 100,
        page_no: 1,
        platform: "WHATSAPP",
      };
      const payloadAI = {
        page_size: 100,
        page_no: 1,
        type: "ai-chatbot",
      };
      const queryRequest = QueryRequestHelper(payload);
      const queryRequestAI = QueryRequestHelper(payloadAI);

      const [resp, respAI] = await Promise.all([
        getAllChatbots(queryRequest),
        getAllChatbots(queryRequestAI),
      ]);
      if (resp || respAI) {
        // Transform both responses into the required format
        const whatsappChatbots = getValue(resp, "data.chatbots", []).map(
          (item: any) => ({
            ...item,
            label: getValue(item, "name", ""),
            value: getValue(item, "id", ""),
          })
        );

        const aiChatbots = getValue(respAI, "data.chatbots", []).map(
          (item: any) => ({
            ...item,
            label: getValue(item, "name", ""),
            value: getValue(item, "id", ""),
          })
        );
        setChatbotList(whatsappChatbots);
        setAiAgentList(aiChatbots);

        // Combine both arrays and remove duplicates based on id
        // const combinedChatbots = [...whatsappChatbots, ...aiChatbots];
        // const uniqueChatbots: any = Array.from(
        //   new Map(combinedChatbots.map((item) => [item.value, item])).values()
        // );
        // setChatbotList(uniqueChatbots);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleAssignConversation = async () => {
    try {
      setSubmitLoading(true);
      let resp = await assignInboxChatbot(
        removeNullOrUndefinedProperties(assignRequest)
      );
      if (resp) {
        setSubmitLoading(false);
        handleModal1();
        toast.success("Assigned successfully");
        setAssignRequest((preState) => ({
          ...preState,
          waAccountId: "",
          managedBy: "team",
          chatBotId: "",
        }));
        getFbConfig();
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "verifiedName",
      key: "verifiedName",
    },
    {
      title: "PHONE",
      dataIndex: "displayPhoneNumber",
      key: "displayPhoneNumber",
    },
    {
      title: "PHONE VERIFICATION ",
      dataIndex: "phoneVerificationStatus",
      key: "phoneVerificationStatus",
      render: (phoneVerificationStatus: any) =>
        phoneVerificationStatus ? (
          <>
            <CircleCheck color="#3fd1a9" size="18px" />
          </>
        ) : (
          <CircleX color="#f54d4d" size="18px" />
        ),
    },
    {
      title: "PHONE REGISTERED",
      dataIndex: "phoneRegistered",
      key: "phoneRegistered",
      render: (phoneRegistered: any, record: any) => (
        <div className="d-flex gap-2">
          {phoneRegistered ? (
            <CircleCheck color="#3fd1a9" size="18px" />
          ) : (
            <CircleX color="#f54d4d" size="18px" />
          )}
          {!phoneRegistered && (
            <Button
              type="primary"
              size="small"
              onClick={() => handleRegisterPhone(record.id)}
            >
              Click to enable
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "WEBHOOK SUBSCRIBED",
      dataIndex: "webhookSubscribed",
      key: "webhookSubscribed",
      render: (webhookSubscribed: any, record: any) => (
        <div className="d-flex gap-2">
          {webhookSubscribed === true ? (
            <CircleCheck color="#3fd1a9" size="18px" />
          ) : (
            <CircleX color="#f54d4d" size="18px" />
          )}
          {!webhookSubscribed && (
            <Button
              size="small"
              onClick={() => webhookSubscribe(getValue(record, `id`, ""))}
            >
              Click to Enable
            </Button>
          )}
        </div>
      ),
    },

    {
      title: "MANAGED BY",
      dataIndex: "managedBy",
      key: "managedBy",
      render: (managedBy: any) => formatString(managedBy),
    },
    {
      title: "ACTION",
      dataIndex: "managedBy",
      key: "managedBy",
      render: (id: any, record: any) => {
        return (
          <div className="header_blue_text__14 touchable-text">
            <Button
              onClick={() => handleAssign(getValue(record, `id`, ""))}
              size="small"
              color="default"
              variant="text"
            >
              <Settings size={18} />
            </Button>
          </div>
        );
      },
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (status: any, record: any) => {
        return (
          <Switch
            checked={status === "active" ? true : false}
            onChange={() =>
              handleStatusChange(
                getValue(record, `id`, ""),
                status !== "active" ? "active" : "inactive"
              )
            }
          />
        );
      },
    },
  ];

  const handleStatusChange = async (id: string, status: string) => {
    try {
      let resp = await updateWhatsappManagerConfig({
        is_active: status === "active" ? true : false,
        ids: [id],
      });
      if (resp) {
        toast.success("Status updated successfully");
        getFbConfig();
      }
    } catch (error) {}
  };

  const intitalState = {
    waAccountId: "",
    managedBy: "team",
    chatBotId: "",
    team_member_id: "",
    creation_rule: "do_nothing",
    module_id: "",
    pipeline_id: "",
    pipeline_stage_id: "",
    assignment_type: "assign_to_previously_assigned_agent",
  };

  const [request, setRequest] = useState(intitalState);
  const reset = () => {
    setRequest(intitalState);
  };
  const [activeTab, setActiveTab] = useState<any>("1");
  return (
    <>
      {isLoading ? (
        <LoaderMain small />
      ) : (
        <div className="w-100">
          <div className="d-flex justify-content-between align-items-center">
            <div></div>
            <div className="py-2 d-flex align-items-center gap-2">
              <Button
                color="default"
                variant="filled"
                size="large"
                onClick={handleSyncPhoneNumbers}
                icon={<RefreshCw size={18} />}
              >
                Sync
              </Button>
              <FacebookLogin handleSubmit={handleSubmit} />
            </div>
          </div>
          {getValue(configList, `length`, 0) > 0 ? (
            <Table
              size="middle"
              bordered
              columns={columns}
              dataSource={configList}
              rowKey="id"
              scroll={{
                y: "calc(100vh - 240px)",
                x: "maxScroll",
              }}
            />
          ) : (
            <WhatsappNoDataPage />
          )}
        </div>
      )}
      <RegisterPhonePopup
        isOpen={isOpen}
        handleModal={handleModal}
        setAccountId={setAccountId}
        registerPhone={registerPhone}
        submitLoading={otpLoading}
        opt={opt}
        setOpt={setOpt}
        otpLoading={otpLoading}
      />
      <ChatSettingsAssignPopup
        isOpen={isOpen1}
        handleModal={handleModal1}
        setAssignId={setAssignId}
        assignRequest={assignRequest}
        setAssignRequest={setAssignRequest}
        chatbotList={chatbotList}
        aiAgentList={aiAgentList}
        submitLoading={submitLoading}
        handleAssignConversation={handleAssignConversation}
        request={request}
        setRequest={setRequest}
        reset={reset}
        getAllPipelineList={getAllPipelineList}
        pipelineList={pipelineList}
        stageList={stageList}
        getFbConfig={getFbConfig}
        agentsList={agentsList}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    </>
  );
}

export default Messages;
