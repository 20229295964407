import React, { useRef, useState, useEffect, useCallback, memo } from "react";
import {
  Image,
  LibraryBig,
  MessageSquareText,
  NotepadText,
  Plus,
  Smile,
} from "lucide-react";
import EmojiPicker from "./components/EmojiPicker";
import { formatMarkdownText } from "./helpers/textFormatter";
import FormatToolbar from "./components/FormatToolbar";
import "./styles/editor.scss";
import "./styles/floating-toolbar.scss";
import { debounce, throttle } from "lodash";
import { Button, Dropdown, MenuProps, Tooltip } from "antd";
import AIStars from "@components/common/Loader/ai-stars";

const MarkdownEditor = (props: any) => {
  const [message, setMessage] = useState("");
  const [preview, setPreview] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [selection, setSelection] = useState<{
    text: string;
    rect?: DOMRect | null;
  }>({ text: "", rect: null });

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const uploadFileInputRef = useRef<HTMLInputElement>(null);
  const isComposingRef = useRef(false);
  const messageRef = useRef("");

  // Initialize message from props
  useEffect(() => {
    const initialValue = props.value || "";
    messageRef.current = initialValue;
    setMessage(initialValue);
    setPreview(formatMarkdownText(initialValue));
  }, [props.value]);

  // Adjust textarea height with debouncing
  const adjustHeight = useCallback(
    debounce(() => {
      const textarea = textareaRef.current;
      if (textarea) {
        textarea.style.height = "44px"; // Reset height
        textarea.style.height = `${textarea.scrollHeight}px`; // Adjust dynamically
      }
    }, 100),
    []
  );

  useEffect(() => {
    adjustHeight();
    return () => adjustHeight.cancel();
  }, [message, adjustHeight]);

  // Debounced preview update
  const updatePreview = useCallback(
    debounce((value: string) => {
      setPreview(formatMarkdownText(value));
    }, 300),
    []
  );

  useEffect(() => {
    return () => updatePreview.cancel();
  }, [updatePreview]);

  // Throttled selection handling
  const handleSelection = useCallback(
    throttle(() => {
      const textarea = textareaRef.current;
      if (!textarea) return;

      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;

      const selectedText = messageRef.current.substring(start, end);
      if (selectedText) {
        const selection = window.getSelection();
        const range = selection?.getRangeAt(0);
        const rect = range?.getBoundingClientRect();
        setSelection({ text: selectedText, rect });
      } else {
        setSelection({ text: "", rect: null });
      }

      props.handleCursorMovement?.(textarea);
    }, 100),
    [props.handleCursorMovement]
  );

  useEffect(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    textarea.addEventListener("mouseup", handleSelection);
    textarea.addEventListener("keyup", (e) => {
      const selectionKeys = [
        "ArrowLeft",
        "ArrowRight",
        "ArrowUp",
        "ArrowDown",
        "Home",
        "End",
        "PageUp",
        "PageDown",
      ];
      if (selectionKeys.includes(e.key) || e.shiftKey) {
        handleSelection();
      }
    });

    return () => {
      textarea.removeEventListener("mouseup", handleSelection);
      textarea.removeEventListener("keyup", handleSelection);
    };
  }, [handleSelection]);

  // Handle text change
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (isComposingRef.current) return;

      const textarea = e.target;
      const newValue = textarea.value;
      const cursorStart = textarea.selectionStart;
      const cursorEnd = textarea.selectionEnd;

      messageRef.current = newValue;
      setMessage(newValue); // Update state immediately for UI
      props.handleChangeEditor?.(newValue); // Notify parent immediately
      updatePreview(newValue); // Debounced preview update

      // Restore cursor position synchronously
      textarea.setSelectionRange(cursorStart, cursorEnd);
      adjustHeight(); // Adjust height after change
    },
    [props.handleChangeEditor, updatePreview, adjustHeight]
  );

  // Handle formatting
  const handleFormat = useCallback(
    (format: string) => {
      const textarea = textareaRef.current;
      if (!textarea) return;

      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const scrollTop = textarea.scrollTop;

      const selectedText = messageRef.current.substring(start, end);
      const beforeText = messageRef.current.substring(0, start);
      const afterText = messageRef.current.substring(end);

      let newText, newStart, newEnd;
      if (selectedText) {
        newText = `${beforeText}${format}${selectedText}${format}${afterText}`;
        newStart = start + format.length;
        newEnd = end + format.length;
      } else {
        newText = `${beforeText}${format}${format}${afterText}`;
        newStart = start + format.length;
        newEnd = newStart;
      }

      messageRef.current = newText;
      setMessage(newText);
      props.handleChangeEditor?.(newText);
      updatePreview(newText);

      textarea.scrollTop = scrollTop;
      textarea.setSelectionRange(newStart, newEnd);
      setSelection({ text: "", rect: null });
      adjustHeight();
    },
    [props.handleChangeEditor, updatePreview, adjustHeight]
  );

  // Handle emoji selection
  const handleEmojiSelect = useCallback(
    (emoji: any) => {
      const textarea = textareaRef.current;
      if (!textarea) return;

      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const scrollTop = textarea.scrollTop;

      const emojiToInsert = emoji.native || emoji.emoji || emoji.toString();
      const beforeText = messageRef.current.substring(0, start);
      const afterText = messageRef.current.substring(end);
      const newText = `${beforeText}${emojiToInsert}${afterText}`;
      const newPosition = start + emojiToInsert.length;

      messageRef.current = newText;
      setMessage(newText);
      props.handleChangeEditor?.(newText);
      updatePreview(newText);

      setShowEmojiPicker(false);
      textarea.scrollTop = scrollTop;
      textarea.setSelectionRange(newPosition, newPosition);
      adjustHeight();
    },
    [props.handleChangeEditor, updatePreview, adjustHeight]
  );

  // Handle file upload
  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(e.target.files || []) as File[];
      if (props.allowedFileTypes) {
        const allowedExtensions = props.allowedFileTypes
          .split(",")
          .map((ext: string) => ext.trim().toLowerCase());
        const invalidFiles = files.filter((file: File) => {
          const extension = `.${file.name.split(".").pop()?.toLowerCase()}`;
          return !allowedExtensions.includes(extension);
        });

        if (invalidFiles.length > 0) {
          alert(
            `Invalid file type(s): ${invalidFiles
              .map((f) => f.name)
              .join(", ")}`
          );
          e.target.value = "";
          return;
        }
      }

      const start = textareaRef.current?.selectionStart || 0;
      const end = textareaRef.current?.selectionEnd || 0;

      props.handleUploadDocument?.(e);
      e.target.value = "";

      // Restore cursor position synchronously
      const textarea = textareaRef.current;
      if (textarea) {
        textarea.setSelectionRange(start, end);
      }
    },
    [props.allowedFileTypes, props.handleUploadDocument]
  );

  // Handle key down events
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (!props.removeEnterAction && e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        if (messageRef.current.trim()) {
          props.handleChangeEditor?.(messageRef.current);
          messageRef.current = "";
          setMessage("");
          setPreview("");
          adjustHeight();
        }
        return;
      }

      if (
        (e.ctrlKey || e.metaKey) &&
        ["b", "i", "s"].includes(e.key.toLowerCase())
      ) {
        e.preventDefault();
        const formatMap: Record<string, string> = {
          b: "*",
          i: "_",
          s: "~",
        };
        const format = formatMap[e.key.toLowerCase()];
        if (format) {
          handleFormat(format);
        }
      }
    },
    [
      props.removeEnterAction,
      props.handleChangeEditor,
      handleFormat,
      adjustHeight,
    ]
  );

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Upload",
      icon: <Image size={16} />,
      onClick: () => uploadFileInputRef.current?.click(),
    },
    ...(props.editorTab === "1"
      ? [
          {
            key: "2",
            label: "Media Library",
            icon: <LibraryBig size={16} />,
            onClick: () => props.handleMediaLibraryButton?.(),
          },
          {
            key: "3",
            label: "Templates",
            icon: <NotepadText size={16} />,
            onClick: () => props.handleBroadcastEditorButton?.(),
          },
          {
            key: "4",
            label: "Canned Response",
            icon: <MessageSquareText size={16} />,
            onClick: () => props.handleCannedEditorButton?.(),
          },
        ]
      : []),
  ];

  const aiRephraseItems = props.aiRephraseMethods?.map(
    (method: string, index: number) => ({
      key: `${index + 1}`,
      label: method,
      onClick: () => props.toggleAiModal(method),
      disabled: props.isRephraseLoading === true,
    })
  );

  return (
    <>
      <Dropdown menu={{ items }} trigger={["click"]}>
        <Button
          type="text"
          shape="circle"
          icon={<Plus color="#606060" size={24} />}
          title="Add"
        />
      </Dropdown>
      <div className="w-100">
        <div
          onKeyDown={props.handleEnterKey}
          style={{ outline: "none" }}
          ref={props.editorRef}
          className="position-relative"
        >
          <div className="">
            {!props.disabled && (
              <div className="">
                <input
                  type="file"
                  multiple
                  className="visually-hidden"
                  onChange={handleFileChange}
                  ref={uploadFileInputRef}
                  accept={props.allowedFileTypes || "*"}
                />
              </div>
            )}

            <div className="livechat-editor__content">
              {props.showMaxLength && message.length >= props.maxLength && (
                <p className="mb-3 mt-2 error-text text-end">
                  Maximum character limit ({props.maxLength}) reached
                </p>
              )}
              <textarea
                ref={textareaRef}
                value={message}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onCompositionStart={() => (isComposingRef.current = true)}
                onCompositionEnd={() => {
                  isComposingRef.current = false;
                  const textarea = textareaRef.current;
                  if (textarea) {
                    const newValue = textarea.value;
                    messageRef.current = newValue;
                    setMessage(newValue);
                    props.handleChangeEditor?.(newValue);
                    updatePreview(newValue);
                    adjustHeight();
                  }
                }}
                placeholder="Type a message..."
                style={props.style}
                maxLength={props.maxLength}
                className={
                  props.showMaxLength && message.length >= props.maxLength
                    ? "livechat-editor-textarea__error"
                    : "livechat-editor-textarea"
                }
                disabled={props.disabled}
              />
            </div>
          </div>
          <div className="livechat-editor__buttons">
            <FormatToolbar onFormat={handleFormat} />
            <div className="position-relative">
              <Button
                title="Emoji"
                type="text"
                icon={<Smile size={18} color="#606060" />}
                shape="circle"
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              />
              {showEmojiPicker && (
                <div className="emoji-picker">
                  <EmojiPicker
                    onEmojiSelect={handleEmojiSelect}
                    onClose={() => setShowEmojiPicker(false)}
                  />
                </div>
              )}
            </div>
            {aiRephraseItems && props.contentLength < 12 ? (
              <Tooltip title="Add message to let AI help you">
                <Button
                  type="text"
                  icon={<AIStars />}
                  shape="circle"
                  disabled
                />
              </Tooltip>
            ) : (
              <Dropdown menu={{ items: aiRephraseItems }}>
                <Button type="text" icon={<AIStars />} shape="circle" />
              </Dropdown>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(MarkdownEditor);
