import TicketBoard from "./components/ticket-board";

function TicketsKanbanView(props: any) {
  const { list } = props;
  return (
    <div className="ticket-card-wrapper px-2">
      <div className="d-flex">
        <TicketBoard list={list} />
      </div>
    </div>
  );
}

export default TicketsKanbanView;
