import { useEffect, useRef, useState } from "react";
import "./new-signup.scss";
import { ChevronLeft, Info } from "lucide-react";
import SimpleReactValidator from "simple-react-validator";
import { getValue } from "@utils/lodash";
import timezones from "@common/json/standardized-timezone-data.json";
import {
  resendVerificationCode,
  signUpComplete,
  signUpInitiate,
} from "@services/auth.service";
import SignupAllSteps from "./components/SignupAllSteps";
import { createOrganisations } from "@services/organisations.service";
import { allParams } from "@common/url-params-helper";
import { message, Modal } from "antd";
import { Link, useNavigate } from "react-router-dom";

const OTP_INPUTS_PER_GROUP = 3;

const NewSignup = () => {
  const navigate = useNavigate();
  const simpleValidator = useRef(new SimpleReactValidator());
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const [, forceUpdate] = useState(0);
  const [request, setRequest] = useState({
    first_name: "",
    last_name: "",
    identifier_type: "EMAIL",
    identifier: "",
  });
  const [password, setPassword] = useState({
    password: "",
  });
  const passwordValidator = useRef(new SimpleReactValidator());
  const [, forceUpdatePassword] = useState(0);
  const [timezoneRequest, setTimezoneRequest] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [steps, setSteps] = useState(1);
  const totalSteps = 6;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [orgLoading, setOrgLoading] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const otpValidator = useRef(new SimpleReactValidator());
  const inputRefs = useRef<any>([]);
  const [, otpForceUpdate] = useState(0);
  const [otpLoading, setOtpLoading] = useState(false);
  //org
  const [requestFour, setRequestFour] = useState({
    name: "",
    country: "",
  });
  const [requestFive, setRequestFive] = useState({
    currency: "",
    time_zone: "",
    date_format: "DD/MM/YYYY",
  });
  const [requestSix, setRequestSix] = useState({
    phone_code: "",
    phone_no: "",
  });

  const reqFourValidator = useRef(new SimpleReactValidator());
  const [, forceUpdateFour] = useState(0);
  const reqFiveValidator = useRef(new SimpleReactValidator());
  const [, forceUpdateFive] = useState(0);
  const reqSixValidator = useRef(new SimpleReactValidator());
  const [, forceUpdateSix] = useState(0);

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  const findTimezone: any = (timezone: string) => {
    return timezones.find(
      (item: object) => getValue(item, `value`, "") === timezone
    );
  };

  useEffect(() => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    if (timeZone === "Asia/Calcutta") {
      setTimezoneRequest("Asia/Kolkata");
    } else if (findTimezone(timeZone)) {
      setTimezoneRequest(timeZone);
    } else {
      setTimezoneRequest("Asia/Kolkata");
    }
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                                  API Section                               */
  /* -------------------------------------------------------------------------- */

  const handleSubmit = async () => {
    const formValid = passwordValidator.current.allValid();
    if (!formValid) {
      passwordValidator.current.showMessages();
      forceUpdatePassword(1);
      return false;
    }
    setSubmitLoading(true);
    try {
      const payload = {
        ...request,
        password: getValue(password, "password", ""),
        timezone: timezoneRequest,
      };
      let resp = await signUpInitiate(payload);
      if (resp) {
        setVerificationId(getValue(resp, "data.verification_id", ""));
        setSteps((prevStep) => prevStep + 1);
      }
    } catch (error) {
      if (
        (error as any).includes(
          "It seems an account with this email already exists."
        )
      ) {
        showConfirm();
      }
    } finally {
      setSubmitLoading(false);
    }
    return true;
  };

  const { confirm } = Modal;
  const [open, setOpen] = useState(false);
  const showConfirm = () => {
    confirm({
      icon: <Info color="orange" className="me-1" />,
      content:
        "The account already exists. Please sign in to your account and create a new organisation.",
      okText: "Signin",
      onOk: () => navigate("/signin"),
      onCancel: () => setOpen(false),
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                                  OTP Section                               */
  /* -------------------------------------------------------------------------- */
  const handleOtpSubmit = async () => {
    const formValid = otpValidator.current.allValid();
    if (!formValid) {
      otpValidator.current.showMessages();
      otpForceUpdate(1);
      return false;
    } else {
      setOtpLoading(true);
      try {
        const enteredOtp = otp.join("");
        let obj = {
          verification_id: verificationId,
          code: enteredOtp,
        };
        let resp = await signUpComplete(obj);
        if (resp) {
          localStorage.setItem(
            "accessToken",
            getValue(resp, `data.access_token`, "")
          );
          setSteps((prevStep) => prevStep + 1);
        }
      } catch (error) {
        setOtpLoading(false);
      } finally {
        setOtpLoading(false);
      }
      return true;
    }
  };

  const resendOTP = async () => {
    try {
      let resp = await resendVerificationCode(verificationId);
      if (resp) {
        message.success("OTP sent successfully");
      }
    } catch (error) {}
  };

  const handleOtpInputChange = (groupIdx: any, digitIdx: any, value: any) => {
    const newOtp = [...otp];
    const globalDigitIdx = groupIdx * OTP_INPUTS_PER_GROUP + digitIdx;
    newOtp[globalDigitIdx] = value;
    setOtp(newOtp);
    const moveFocus = (moveIndex: any) => {
      if (moveIndex >= 0 && moveIndex < otp.length) {
        inputRefs.current[moveIndex].focus();
      }
    };
    if (value) {
      moveFocus(
        globalDigitIdx < otp.length - 1 ? globalDigitIdx + 1 : globalDigitIdx
      );
    } else {
      moveFocus(globalDigitIdx > 0 ? globalDigitIdx - 1 : globalDigitIdx);
    }
  };

  const handlePaste = async (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    try {
      const clipboardData =
        event.clipboardData || getValue(window, `clipboardData`, {});
      const pastedData = clipboardData.getData("text");
      if (pastedData) {
        const otpArray = pastedData
          .split("")
          .filter((char) => /\d/.test(char))
          .slice(0, otp.length);

        const newOtp = otpArray.map((digit, idx) => digit || "");
        setOtp(newOtp);
        // Set focus on the last input
        const lastInputIndex = newOtp.length - 1;
        if (inputRefs.current[lastInputIndex]) {
          inputRefs.current[lastInputIndex].focus();
        }
      } else {
        console.error("No text found in clipboard.");
      }
    } catch (error) {
      console.error("Error reading clipboard:", error);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleStepper();
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                  Org Section                               */
  /* -------------------------------------------------------------------------- */

  const handleOrgSubmit = async () => {
    try {
      setOrgLoading(true);
      const payload = {
        ...requestFour,
        ...requestFive,
        ...requestSix,
      };
      let resp = await createOrganisations(payload);
      if (resp) {
        message.success("Account created successfully");
        if (getValue(UrlParams, `path`, "") === "shared") {
          window.location.href = `/${getValue(
            resp,
            `data.code`,
            ""
          )}/crm/leads?path=shared`;
        } else {
          window.location.href = `/${getValue(
            resp,
            `data.code`,
            ""
          )}/home?${allParams()}`;
        }
      }
    } catch (error) {
    } finally {
      setOrgLoading(false);
    }
  };

  const handleStepper = async () => {
    if (steps === 1) {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setSteps(2);
    } else if (steps === 2) {
      const success = await handleSubmit();
      if (!success) return;
    } else if (steps === 3) {
      const success = await handleOtpSubmit();
      if (!success) return;
    } else if (steps === 4) {
      const formValid = reqFourValidator.current.allValid();
      if (!formValid) {
        reqFourValidator.current.showMessages();
        forceUpdateFour(1);
        return;
      }
      setSteps(5);
    } else if (steps === 5) {
      const formValid = reqFiveValidator.current.allValid();
      if (!formValid) {
        reqFiveValidator.current.showMessages();
        forceUpdateFive(1);
        return;
      }
      setSteps(6);
    } else {
      const formValid = reqSixValidator.current.allValid();
      if (!formValid) {
        reqSixValidator.current.showMessages();
        forceUpdateSix(1);
        return;
      } else {
        handleOrgSubmit();
      }
    }
  };

  const handlePrevious = () => {
    if (steps > 1) {
      setSteps(steps - 1);
    }
  };

  const progress = (steps / totalSteps) * 100;

  const handleSelectCountry = (e: object) => {
    let timezone: any = timezones.find(
      (item: object) =>
        getValue(item, `countryCode`, "") === getValue(e, `code`, "")
    );
    setRequestFour((prevSta) => ({
      ...prevSta,
      country: getValue(e, `code`, ""),
    }));
    setRequestFive((prevSta) => ({
      ...prevSta,
      currency: getValue(e, `currency_code`, ""),
      time_zone: getValue(timezone, `value`, ""),
    }));
    setRequestSix((prevSta: any) => ({
      ...prevSta,
      phone_code: getValue(e, `dial_code`, ""),
      phone_no: getValue(e, `dial_code`, ""),
    }));
  };

  const handleWrongEmail = () => {
    setSteps(1);
    setRequest({ ...request, identifier: "" });
    setPassword({ password: "" });
    setOtp(Array(6).fill(""));
  };

  return (
    <div className="new-signup-wrapper">
      <div className="signup-overlay">
        <div className="signup-card">
          <p className="stepper-details">
            Step {steps} of {totalSteps}
          </p>
          <div className="progress my-3">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${progress}%` }}
              aria-valuenow={progress}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div>
          <h1 className="header_text__32 mb-3">
            {steps === 1
              ? "Let's get started"
              : steps === 2
              ? "How you'll sign in"
              : steps === 3
              ? "Check your email for code"
              : "Setup your organisation"}
          </h1>
          <p className="signup-details">
            {steps === 1 ? (
              "Create your Appzo account"
            ) : steps === 2 ? (
              "You'll use your email address to sign into your Appzo account"
            ) : steps === 3 ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: `We've sent a 6-character code <b>${getValue(
                    request,
                    "identifier",
                    ""
                  )}</b>. The code expires shortly, please enter it soon`,
                }}
              />
            ) : steps === 4 || steps === 5 ? (
              "You need to select basic details below to get started."
            ) : steps === 6 ? (
              "Enter mobile number"
            ) : (
              "Setup your organisation"
            )}
          </p>
          <SignupAllSteps
            steps={steps}
            request={request}
            setRequest={setRequest}
            validator={simpleValidator}
            password={password}
            setPassword={setPassword}
            passwordValidator={passwordValidator}
            OTP_INPUTS_PER_GROUP={OTP_INPUTS_PER_GROUP}
            otp={otp}
            handleOtpInputChange={handleOtpInputChange}
            handlePaste={handlePaste}
            handleKeyDown={handleKeyDown}
            inputRefs={inputRefs}
            otpValidator={otpValidator}
            requestFour={requestFour}
            setRequestFour={setRequestFour}
            requestFive={requestFive}
            setRequestFive={setRequestFive}
            requestSix={requestSix}
            setRequestSix={setRequestSix}
            reqFourValidator={reqFourValidator}
            reqFiveValidator={reqFiveValidator}
            reqSixValidator={reqSixValidator}
            handleSelectCountry={handleSelectCountry}
            handleWrongEmail={handleWrongEmail}
          />
          <button
            onClick={handleStepper}
            disabled={submitLoading || orgLoading}
            className="signup-submit-button"
          >
            {steps === 2
              ? submitLoading
                ? "Submitting..."
                : "Signup for free"
              : steps === 3
              ? otpLoading
                ? "Verfiying..."
                : "Verify"
              : steps === 6
              ? orgLoading
                ? "Creating..."
                : "Create"
              : "Next"}
          </button>
          {steps === 1 && (
            <div className="mt-4">
              Already have an account?{" "}
              <Link to="/signin" className="ms-1">
                Sign in
              </Link>
            </div>
          )}
          {steps !== 1 && steps !== 3 && steps !== 4 && (
            <button
              className="stepper-previous"
              onClick={handlePrevious}
              disabled={steps === 3}
            >
              <ChevronLeft />
            </button>
          )}
          {steps === 3 && (
            <>
              <div className=" small_text__14 mt-3">
                Did not receive an email? <br />
                Check your spam folder or
                <a href="#0" className="ms-1" onClick={resendOTP}>
                  Resend Code
                </a>
              </div>
              <div className="small_text__14 mb-3">
                Wrong email?
                <a href="#0" className="ms-1" onClick={handleWrongEmail}>
                  Change mail
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewSignup;
