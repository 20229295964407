import { ShareAltOutlined } from "@ant-design/icons";
import UrlSvgComponent from "@assets/svg/custom/url";
import GlobeSvgComponent from "@assets/svg/globe";
import WordpressSvgComponent from "@assets/svg/wordpress";
import { ChevronsLeftRightEllipsis } from "lucide-react";

function ShareSidebar(props: any) {
  const { activeTab, setActiveTab } = props;
  return (
    <aside className="sidebar">
      {/* <Title level={2}>Connect</Title> */}
      <div className="menu-items">
        {/* <div
          className={`menu-item ${activeTab === 1 ? "active" : ""}`}
          onClick={() => setActiveTab(1)}
        >
          <ChevronsLeftRightEllipsis size={16} />
          <span>Embed</span>
        </div> */}
        <div
          className={`menu-item ${activeTab === 0 ? "active" : ""}`}
          onClick={() => setActiveTab(0)}
        >
          <ChevronsLeftRightEllipsis
            size="20"
            color={activeTab === "tab0" ? "#1a73e8" : "#7890a1"}
          />
          Embed
        </div>
        <div
          className={`menu-item ${activeTab === 1 ? "active" : ""}`}
          onClick={() => setActiveTab(1)}
        >
          <UrlSvgComponent
            size="20"
            color={activeTab === "tab1" ? "#1a73e8" : "#7890a1"}
          />{" "}
          URL
        </div>
        <div
          className={`menu-item ${activeTab === 2 ? "active" : ""}`}
          onClick={() => setActiveTab(2)}
        >
          <GlobeSvgComponent
            size="20"
            color={activeTab === "tab2" && "#1a73e8"}
          />{" "}
          Website
        </div>
        <div
          className={`menu-item ${activeTab === 3 ? "active" : ""}`}
          onClick={() => setActiveTab(3)}
        >
          <WordpressSvgComponent
            size="20"
            color={activeTab === "tab3" && "#1a73e8"}
          />{" "}
          Wordpress
        </div>
      </div>
    </aside>
  );
}

export default ShareSidebar;
