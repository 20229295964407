import { getValue } from "@utils/lodash";
import "./view.scss";
import BackSvgComponent from "@assets/svg/back-link";
import { convertObjectKeystoJSON } from "@common/text-helpers";
import FilterFormBuilder from "./Builder/builder";
import EditSvgComponent from "@assets/svg/edit";
import BackButton from "@components/custom/BrowserBack";
import { Radio } from "antd";

function AdvancedFilterViewStep2(props: any) {
  const {
    setActiveTab,
    selectedFilterOption,
    request,
    setRequest,
    handleResetSelectedOptions,
  } = props;
  return (
    <div className="advance_filter_main_container">
      <div className="advance_filter_container">
        <div className="mt-3 d-flex align-items-center">
          <BackButton
            handleBack={() => {
              setActiveTab(0);
              handleResetSelectedOptions();
            }}
          />
          <p className="ms-2 header_text__12">Back</p>
        </div>

        <div
          className="mt-3 d-flex align-items-center cursor-pointer"
          onClick={() => setActiveTab(1)}
        >
          <h6 className="header_text__16 color_primary">
            {getValue(selectedFilterOption, `label`, "")}
          </h6>
          <div className="ms-2">
            <EditSvgComponent color={"#408dfb"} />
          </div>
        </div>
        <div className="mt-2">
          {convertObjectKeystoJSON(
            getValue(selectedFilterOption, `operator`, {})
          ).length > 0 &&
            convertObjectKeystoJSON(
              getValue(selectedFilterOption, `operator`, {})
            ).map((item: object) => {
              return (
                <>
                  <div className="field_container_radio">
                    <Radio
                      checked={
                        getValue(request, `operator`, "") ===
                        getValue(item, `value`, "")
                      }
                      onChange={() =>
                        setRequest({
                          ...request,
                          operator: getValue(item, `value`, ""),
                          input_value_1: "",
                          api_name: getValue(
                            selectedFilterOption,
                            `api_name`,
                            ""
                          ),
                        })
                      }
                    >
                      {getValue(item, `label`, "")}
                    </Radio>
                  </div>
                  <>
                    {getValue(item, `value`, "") === "IS_EMPTY" ||
                    getValue(item, `value`, "") === "IS_NOT_EMPTY" ? (
                      <></>
                    ) : (
                      <>
                        {getValue(request, `operator`, "") ===
                          getValue(item, `value`, "") &&
                          FilterFormBuilder({
                            item: selectedFilterOption,
                            request: request,
                            setRequest: setRequest,
                            key_name: "input_value",
                            module: props.module,
                          })}
                        {getValue(request, `operator`, "") === "BETWEEN" &&
                          getValue(item, `value`, "") === "BETWEEN" && (
                            <>
                              <p className="small_text__16">and</p>
                              {FilterFormBuilder({
                                item: selectedFilterOption,
                                request: request,
                                setRequest: setRequest,
                                key_name: "input_value_1",
                                module: props.module,
                              })}
                            </>
                          )}
                      </>
                    )}
                  </>
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default AdvancedFilterViewStep2;
