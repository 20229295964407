import { handleTextChange } from "@common/handleChange";
import InputRuleForm from "@components/InputRuleForm/form";
import { createChatbot } from "@services/chatbot.service";
import { getValue } from "@utils/lodash";
import { Modal } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

const WebchatCreateModel = (props: any) => {
  const { isModalOpen, handleCancel } = props;

  const params = useParams();
  const navigate = useNavigate();
  const [request, setRequest] = useState({
    name: "",
    type: "agents",
  });

  const handleCreateAIBot = async () => {
    try {
      let resp = await createChatbot({
        name: getValue(request, `name`, ""),
        platform: "WEB",
        type: "webchat",
      });
      if (resp) {
        navigate(
          `/${getValue(
            params,
            "orgId",
            ""
          )}/settings/chat/webchat/settings/${getValue(resp, "data.id", "")}`
        );
        toast.success("Create AI bot successfully");
      }
    } catch (error) {}
  };

  return (
    <Modal
      title="Create Webchat"
      okText="Create"
      open={isModalOpen}
      onOk={handleCreateAIBot}
      onCancel={handleCancel}
      width={600}
    >
      {request.type === "agents" && (
        <InputRuleForm
          inputType="TEXT"
          name="name"
          value={getValue(request, "name", "")}
          placeholder="Enter a Name"
          required
          label="Name"
          onChange={(e: any) => handleTextChange(e, request, setRequest)}
          validLeft
        />
      )}
    </Modal>
  );
};

export default WebchatCreateModel;
