import DraftJSRefEditor from "@components/custom/DraftJSEditor/draft-ref-editor";
import { getValue } from "@utils/lodash";
import { Button, Input, Modal, Radio, Tooltip } from "antd";
import { PlusCircle, Search, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { ContentState, EditorState, Modifier, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import InputRuleForm from "@components/InputRuleForm/form";

const EmailAction = ({
  index,
  item,
  request,
  setRequest,
  removeConditionUpdate,
  handleDeleteOption,
  ticketKeys,
  validator,
}: any) => {
  const fieldName = getValue(item, "field_name", "");
  const [modalType, setModalType] = useState<null | "subject" | "editor">(null);

  const openModal = (type: "subject" | "editor") => {
    setModalType(type);
  };

  const closeModal = () => {
    setModalType(null);
  };

  /* -------------------------------------------------------------------------- */
  /*                              Editor Cursor section                         */
  /* -------------------------------------------------------------------------- */
  const blocksFromHtml = htmlToDraft(
    getValue(request, `actions.emails[${index}].email_body`, "")
  );

  const { contentBlocks, entityMap } = blocksFromHtml;

  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(contentState)
  );
  const [searchQuery, setSearchQuery] = useState("");

  // Function to insert text at the cursor position
  const insertText = (editorState: any, value: any) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    const newContentState = Modifier.insertText(
      contentState,
      selectionState,
      value
    );

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "insert-characters"
    );

    return newEditorState;
  };

  const filteredKeys =
    ticketKeys.length > 0
      ? ticketKeys.filter((key: string) =>
          key.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : [];

  const handleAddTextAtCursor = (item: any) => {
    const textToInsert = ` <%= ${item} %> `; // Text to insert

    // Insert text into the current EditorState (preserves cursor position)
    const newEditorState = insertText(editorState, textToInsert);

    // Update the EditorState
    setEditorState(newEditorState);

    // Convert the updated EditorState back to HTML
    const newContentState = newEditorState.getCurrentContent();
    const newHtmlContent = draftToHtml(convertToRaw(newContentState));

    // Update the request state with the new HTML content
    setRequest((prevRequest: any) => {
      const updatedRequest = { ...prevRequest };
      updatedRequest.actions.emails[index].email_body = newHtmlContent;
      return updatedRequest;
    });

    closeModal();
  };

  const handleChangeEditor = (
    name: string,
    value: string,
    editorState: any
  ) => {
    setRequest((prevRequest: any) => {
      const updatedRequest = { ...prevRequest };
      updatedRequest.actions.emails[index].email_body = value;
      return updatedRequest;
    });
    setEditorState(editorState);
  };

  const handleAddSubjectPlaceholder = (item: any) => {
    const textToInsert = ` <%= ${item} %> `;

    setRequest((prevRequest: any) => {
      const updatedRequest = { ...prevRequest };
      const currentSubject =
        updatedRequest.actions.emails[index].email_subject || "";
      updatedRequest.actions.emails[index].email_subject =
        currentSubject + textToInsert;
      return updatedRequest;
    });

    closeModal();
  };

  // Initialize radioRequest based on existing request data
  const emailTo = getValue(request, `actions.emails[${index}].email_to`, "");
  const emailGroup = getValue(
    request,
    `actions.emails[${index}].email_group_id`,
    null
  );
  const emailAgent = getValue(
    request,
    `actions.emails[${index}].email_agent_id`,
    null
  );

  //options
  const [radioRequest, setRadioRequest] = useState(
    fieldName === "send_email_to_group"
      ? emailTo === "assigned_group"
        ? "assigned_group"
        : emailGroup
        ? "select"
        : ""
      : fieldName === "send_email_to_agent"
      ? emailTo || (emailAgent ? "select" : "")
      : ""
  );
  const groupsOptions = [
    { label: "Assigned Group", value: "assigned_group" },
    { label: "Custom", value: "select" },
  ];

  const agentsOptions = [
    { label: "Assigned Agent", value: "assigned_agent" },
    { label: "Event performing agent", value: "ticket_creating_agent" },
    { label: "Custom", value: "select" },
  ];

  const handleRadioChange = (e: any) => {
    const value = e.target.value;
    setRadioRequest(value);

    setRequest((prevRequest: any) => {
      const updatedRequest = { ...prevRequest };

      if (fieldName === "send_email_to_group") {
        // Handling group options
        if (value === "assigned_group") {
          updatedRequest.actions.emails[index].email_to = value;
          delete updatedRequest.actions.emails[index].email_group_id;
        } else if (value === "select") {
          updatedRequest.actions.emails[index].email_group_id = "";
          delete updatedRequest.actions.emails[index].email_to;
        }
      } else {
        // Handling agent options
        if (value === "assigned_agent" || value === "ticket_creating_agent") {
          updatedRequest.actions.emails[index].email_to = value;
          delete updatedRequest.actions.emails[index].email_agent_id;
        } else if (value === "select") {
          updatedRequest.actions.emails[index].email_agent_id = "";
          delete updatedRequest.actions.emails[index].email_to;
        }
      }

      return updatedRequest;
    });
  };

  const handleRequesterChange = (e: any) => {};

  return (
    <div key={index} className="sla-condition-card p-4 border-bottom">
      <div className="w-100">
        <div className="d-flex align-items-center gap-3">
          <p className="small_text__14 ">
            Send Email to
            {getValue(item, "field_name", "") === "send_email_to_requester" &&
              " Requester"}
          </p>
          {(fieldName === "send_email_to_group" ||
            fieldName === "send_email_to_agent") && (
            <Radio.Group
              options={
                fieldName === "send_email_to_group"
                  ? groupsOptions
                  : agentsOptions
              }
              onChange={(e: any) => {
                fieldName === "send_email_to_group" ||
                fieldName === "send_email_to_agent"
                  ? handleRadioChange(e)
                  : handleRequesterChange(e);
              }}
              value={radioRequest}
            />
          )}
        </div>

        {radioRequest === "select" &&
          (fieldName === "send_email_to_group" ||
            fieldName === "send_email_to_agent") && (
            <div className="mt-2 w-50">
              <SearchToolTip
                placeholder={
                  fieldName === "send_email_to_group"
                    ? "Select Group"
                    : "Select Agent"
                }
                lookup_api={
                  fieldName === "send_email_to_group" ? "groups" : "org_users"
                }
                value={getValue(
                  request,
                  `actions.emails[${index}].${
                    fieldName === "send_email_to_group"
                      ? "email_group_id"
                      : "email_agent_id"
                  }`,
                  ""
                )}
                onChange={(e: any) => {
                  const id = getValue(e, `id`, "");
                  setRequest((prevRequest: any) => {
                    const updatedRequest = { ...prevRequest };
                    if (fieldName === "send_email_to_group") {
                      updatedRequest.actions.emails[index].email_group_id = id;
                    } else {
                      updatedRequest.actions.emails[index].email_agent_id = id;
                    }
                    return updatedRequest;
                  });
                }}
                label="label"
              />
            </div>
          )}

        <div>
          <div className="position-relative mb-3 mt-2">
            <InputRuleForm
              inputType="TEXT"
              name="name"
              value={getValue(
                request,
                `actions.emails[${index}].email_subject`,
                ""
              )}
              placeholder="Subject"
              required
              label="Subject"
              onChange={(e: any) =>
                setRequest((prevRequest: any) => {
                  const updatedRequest = { ...prevRequest };
                  updatedRequest.actions.emails[index].email_subject =
                    e.target.value;
                  return updatedRequest;
                })
              }
              validLeft
              mandatory
              validator={validator}
              // style={{
              //   maxWidth: "440px",
              // }}
            />
            <Tooltip title="Add placeholder">
              <PlusCircle
                size={14}
                color="#389e0d"
                onClick={() => openModal("subject")}
                className="subject-placeholder-button"
              />
            </Tooltip>
          </div>
          <div className="position-relative">
            <DraftJSRefEditor
              editorState={getValue(
                request,
                `actions.emails[${index}].email_body`,
                ""
              )}
              handleChangeEditor={handleChangeEditor}
              name="email_body"
              placeholder="Enter Content here"
              editorClassName="automation-email-editor"
            />
            <Tooltip title="Add placeholder">
              <PlusCircle
                size={14}
                color="#389e0d"
                onClick={() => openModal("editor")}
                className="subject-placeholder-button"
              />
            </Tooltip>
          </div>
        </div>
      </div>
      <Trash2
        size={18}
        onClick={() =>
          getValue(item, `id`, "")
            ? removeConditionUpdate(index, "emails")
            : handleDeleteOption(index, "emails")
        }
        className="cursor-pointer"
      />
      <Modal
        title="Ticket Placeholders"
        open={modalType !== null}
        onOk={closeModal}
        onCancel={closeModal}
        footer={null}
      >
        <div className="d-flex flex-wrap gap-2">
          <Input
            prefix={<Search size={16} />}
            className="my-3"
            placeholder="Search Placeholder"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            allowClear
          />
          {filteredKeys.length > 0 &&
            filteredKeys.map((item: any, i: number) => (
              <Button
                onClick={() =>
                  modalType === "subject"
                    ? handleAddSubjectPlaceholder(item)
                    : handleAddTextAtCursor(item)
                }
                key={`${item}-${i}`}
              >
                {item}
              </Button>
            ))}
        </div>
      </Modal>
    </div>
  );
};

export default EmailAction;
