import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import "./messages.scss";
import "reactjs-popup/dist/index.css";
import {
  getWhatsappManagerConfig,
  getWhatsappTemplate,
} from "@services/fb.service";
import {
  QueryRequestHelper,
  QueryRequestHelperWithAll,
  QueryRequestHelperWithRepeat,
  removeBracketsFromQuery,
} from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import Loader from "@components/common/Loader/loading";
import InboxContacts from "./components/ConversationList/contacts";
import ChatFooter from "./components/ConversationFooter/chat-footer";
import ChatRightHeader from "./components/ConversationHeader/chat-right-header";
import ChatDocumentPreview from "./components/ConversationFooter/chat-doc-preview";
import { mediaUpload } from "@services/upload.service";
import { getSpecificContact } from "@services/contacts.service";
import {
  allPipelines,
  getSpecificPipeline,
  getSpecificPipelineColumns,
} from "@services/pipeline.service";
import { useStateContext } from "@context/dataContext";
import {
  appendObjectValuesToArray,
  removeDuplicatesById,
} from "@components/helpers/request-helper";
import Pagination from "@components/Pages/Pipeline/common/pagination";
import _, { throttle } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import WaAdvancefilter from "./components/ConversationHeader/wa-advance-filter";
import ChatFilter from "./components/ConversationHeader/chat-filter";
import useDynamicTitle from "@context/useDynamicTitle";
import { getSpecificLead } from "@services/leads.service";

import { useSocketContext } from "@context/socketContext";
import ChatContactInfo from "./components/ConversationInfo/contact-info";
import ConversationScroll from "./components/ConversationInfo/conversation-scroll";
import ConversationReply from "./components/ConversationInfo/conversation-reply";
import ChatSearchHeader from "./components/ConversationHeader/chat-search-header";
import Pusher from "pusher-js";
import { config } from "../../../env";
import {
  getLiveChatConversationList,
  getLiveChatConversationListBySearch,
  getSpecificLiveChatConversation,
  liveChatSendMessage,
  readLiveChatConversation,
} from "@services/live-chat.service";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import { createAttachments } from "@services/attachments.service";
import { toast } from "sonner";
import { Button, Col, FloatButton, Row, Spin } from "antd";
import BackButton from "@components/custom/BrowserBack";
import { motion, AnimatePresence } from "framer-motion";
import { SquareArrowOutUpRight } from "lucide-react";
import CloseMainSvgComponent from "@assets/svg/close-main";
import SidebarLayout from "@layouts/SidebarLayout/SidebarLayout";
import InboxSearchContacts from "./components/ConversationList/search-contacts";
import ChatbotFooterBroadcastModel from "./components/ConversationFooter/broadcast-page";
import ConversationTypeFilter from "./components/ConversationType/ConversationTypeFilter";
import { updateOrgUsers } from "@services/users.service";
import BulkUpdateModal from "./components/BulkUpdateModal/BulkUpdateModal";
import {
  allowedFormats,
  fileSizeLimits,
  findType,
  getFileSizeLimit,
  isValidFileFormat,
} from "@common/file-type-helper";
import { getOrganisationUsers } from "@services/organisations.service";
import { getAllChatbots } from "@services/chatbot.service";
import ChatMediaLibraryModal from "./components/ConversationFooter/media-library-modal";
import { getAllMediaLibrary } from "@services/media-library.service";
import ChatLibraryDocumentPreview from "./components/ConversationFooter/chat-doc-library-preview";
import { getDefaultModuleColumns } from "@services/module-fields.service";

const chatFilters = [
  {
    label: "All Unassigned Chats",
    value: "all_unassigned_chats",
    permissions: ["unassigned"],
  },
  {
    label: "My Open Chats",
    value: "my_open_chats",
    permissions: ["self_assigned"],
  },
  {
    label: "My Waiting on Customer Chats",
    value: "my_waiting_on_customer_chats",
    permissions: ["self_assigned"],
  },
  {
    label: "My Waiting on Us Chats",
    value: "my_waiting_on_us_chats",
    permissions: ["self_assigned"],
  },
  {
    label: "My On Hold Chats",
    value: "my_on_hold_chats",
    permissions: ["self_assigned"],
  },
  {
    label: "My Resolved Chats",
    value: "my_resolved_chats",
    permissions: ["self_assigned"],
  },
  {
    label: "All Open Chats",
    value: "all_open_chats",
    permissions: ["global_access"],
  },
  {
    label: "All Waiting on Customer Chats",
    value: "all_waiting_on_customer_chats",
    permissions: ["global_access"],
  },
  {
    label: "All Waiting on Us Chats",
    value: "all_waiting_on_us_chats",
    permissions: ["global_access"],
  },
  {
    label: "All On Hold Chats",
    value: "all_on_hold_chats",
    permissions: ["global_access"],
  },
  {
    label: "All Resolved Chats",
    value: "all_resolved_chats",
    permissions: ["global_access"],
  },
  {
    label: "All Bot Conversations",
    value: "all_bot_conversations",
    permissions: ["global_access"],
  },
];

const MotionCol = motion(Col);
function LiveChat(props: any) {
  useDynamicTitle("Live Chat");
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const [expanded, setExpanded] = useState(false);
  const [docExpanded, setDocExpanded] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isWAConfigured, setIsWAConfigured] = useState(false);
  const [moduleDetails, setModuleDetails] = useState({});

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [selectedDrawerInfo, setSelectedDrawerInfo] = useState<any>({});

  const handleToggleDrawer = () => {
    setIsOpenDrawer(!isOpenDrawer);
    setSelectedDrawerInfo({});
  };
  const handleToggleWidth = () => {
    resetPipelineForm();
    setExpanded(!expanded);
  };
  const {
    selectedModuleId,
    userId,
    modules,
    profileInfo,
    allModules,
    refreshBookmarks,
    bookmarkedModules,
    themeColor,
    getUserIDCallback,
  } = useStateContext();

  const { statusCounts, rawStatusCounts, getAllCount } = useSocketContext();
  const [conversation, setConversation] = useState<any[]>([]);
  const [selectedConversation, setSelectedConversation] = useState("");

  // useEffect(() => {
  //   setDefaultConversationType(
  //     getValue(profileInfo, "inbox_view", "") || "my_open_chats"
  //   );
  // }, [profileInfo]);

  /* -------------------------------------------------------------------------- */
  /*                                 Socket Section                             */
  /* -------------------------------------------------------------------------- */
  const { chatInfo, setChatInfo } = useSocketContext();
  // useEffect(() => {
  //   //---------------------------------------- process webchat message Info -----------------------------//
  //   if (chatInfo) {
  //     if (getValue(chatInfo, `id`, "")) {
  //       if (
  //         selectedConversation &&
  //         getValue(chatInfo, `id`, "") === selectedConversation
  //       ) {
  //         if (getValue(chatInfo, `unread_count`, 0) > 0) {
  //           handleReadMessage(selectedConversation);
  //           updateWebchatInfoFromSocket({ ...chatInfo, unread_count: 0 }, true);
  //         }
  //       } else {
  //         updateWebchatInfoFromSocket(chatInfo, false);
  //       }
  //     }
  //   }
  // }, [chatInfo, selectedConversation]);

  useEffect(() => {
    const findModule = allModules.find((item: any) => {
      const apiName = getValue(item, "api_name", "");
      return apiName === "sales" || apiName === "deals";
    });

    if (findModule) {
      setModuleDetails(findModule);
    }
  }, [allModules]); // Corrected dependency array syntax

  /* -------------------------------------------------------------------------- */
  /*                                  Scroll Section                             */
  /* -------------------------------------------------------------------------- */
  const [showDownButton, setShowDownButton] = useState(false);
  const divRef = useRef<HTMLDivElement | null>(null);

  const [unreadCountForScroll, setUnreadCountForScroll] = useState(0);
  const [scrollCount, setScrollCount] = useState(0);

  useEffect(() => {
    // Throttle function to limit how often the scroll handler executes
    const throttledHandleScroll = throttle(() => {
      if (!divRef.current) return;

      const scrollableDiv = divRef.current;
      const { scrollTop } = scrollableDiv;
      // Update button visibility based on scroll position
      if (scrollTop < -100) {
        setShowDownButton(true);
      } else if (scrollTop >= -20) {
        // Fixed condition
        setShowDownButton(false);
      }

      setScrollCount(scrollTop);

      // Handle message reading based on scroll position
      if (scrollTop === 0) {
        if (getValue(chatInfo, "unread_count", 0) > 0) {
          handleReadMessage(selectedConversation);
          updateWebchatInfoFromSocket({ ...chatInfo, unread_count: 0 }, true);
        }
      } else {
        // Only update unread count when not at bottom
        if(getValue(selectedConversationInfo,"id",'') === getValue(chatInfo,"id",'')){
          setUnreadCountForScroll(getValue(chatInfo, "unread_count", 0));
        }
      }
    }, 200); // Increased throttle time to avoid excessive executions

    // Add scroll event listener
    const scrollableDiv = divRef.current;
    if (scrollableDiv) {
      scrollableDiv.addEventListener("scroll", throttledHandleScroll);
    }

    // Cleanup
    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener("scroll", throttledHandleScroll);
        // Cancel any pending throttled executions
        throttledHandleScroll.cancel && throttledHandleScroll.cancel();
      }
    };
  }, [chatInfo, selectedConversation, conversation]); // Removed conversation from dependencies to avoid unnecessary rerenders

  const scrollToBottom = () => {
    if (!divRef.current) return;

    divRef.current.scrollTo({
      top: 0, // Scroll to bottom since flex is reversed
      behavior: "smooth",
    });

    setTimeout(() => {
      setShowDownButton(false);

      if (getValue(chatInfo, "unread_count", 0) > 0) {
        handleReadMessage(selectedConversation);
        updateWebchatInfoFromSocket({ ...chatInfo, unread_count: 0 }, true);
      }
    }, 800);
  };

  //---------------------------------------- process webchat message Info -----------------------------//

  useEffect(() => {
    // Only proceed if we have valid chat info
    const chatId = getValue(chatInfo, "id", "");
    if (!chatId) return;

    // Check if this is the currently selected conversation
    const isSelectedConversation =
      selectedConversation && chatId === selectedConversation;

    if (isSelectedConversation) {
      const currentUnreadCount = getValue(chatInfo, "unread_count", 0);

      // If at the bottom of the conversation (scrollTop === 0)
      if (scrollCount === 0) {
        // If there are unread messages, mark them as read
        if (currentUnreadCount > 0) {
          handleReadMessage(selectedConversation);

          // Update conversations to reflect read messages
          setConversations((prevConversations) => {
            return prevConversations.map((conversation) => {
              if (conversation.id === selectedConversation) {
                return {
                  ...conversation,
                  unread_count: 0,
                };
              }
              return conversation;
            });
          });

          // Use a local variable to avoid referencing changing state
          const chatInfoToUpdate = { ...chatInfo, unread_count: 0 };
          updateWebchatInfoFromSocket(chatInfoToUpdate, true);
          if(getValue(selectedConversationInfo,"id",'') === getValue(chatInfoToUpdate,"id",'')){
          setUnreadCountForScroll(0);
          }
        }
      } else {
        // Not at bottom - update unread count but don't mark as read
        if (currentUnreadCount > 0) {
          if(getValue(selectedConversationInfo,"id",'') === getValue(chatInfo,"id",'')){
          setUnreadCountForScroll(currentUnreadCount);
          }
        }

        // Update message status
        // const conversationData = chatInfo;
        // if (conversationData && Object.keys(conversationData).length > 0) {
        updateWebchatInfoFromSocket(chatInfo, false);
        // }
      }
    } else {
      // Not the selected conversation, update info without marking as read
      updateWebchatInfoFromSocket(chatInfo, false);
    }
  }, [chatInfo, selectedConversation, scrollCount]);
  //---------------------------------------- process webchat message Info -----------------------------//

  // Remove commented out code for clarity

  //---------------------------------------- Webchat individual channel Logic -----------------------------//

  const [currentChannelName, setCurrentChannelName] = useState("");

  useEffect(() => {
    if (!currentChannelName) return;

    // Get existing pusher instance
    const pusher = new Pusher(config.PUSHER_APP_KEY, {
      cluster: config.PUSHER_APP_CLUSTER,
    });

    // Subscribe to the channel
    const channel = pusher.subscribe(currentChannelName);

    // Set up listeners
    channel.bind("chat-message", (data: any) => {
      const newMessage = {
        ...data,
        ...getValue(data, "last_msg", {}),
      };
      // Update webchat conversations regardless
      updateWebchatConversations(data);
      // Check if the message with last_msg.id already exists
      setConversation((prevConversation) => {
        const messageId = getValue(newMessage, "last_msg.id", "")
          ? getValue(newMessage, "last_msg.id", "")
          : getValue(newMessage, "id", "");
        // If no ID or message doesn't exist, add it
        if (
          !messageId ||
          !prevConversation.some((msg) => msg?.id === messageId)
        ) {
          return [...prevConversation, newMessage];
        }
        // If it exists, return the previous conversation unchanged
        return prevConversation;
      });
    });
    channel.bind("chat-file-uploaded", (data: any) => {
      setConversation((prevMessages) => {
        const newMessages = replaceObjectById(prevMessages, data);
        return newMessages;
      });
    });
    channel.bind("whats-app-message-notification", (data: any) => {
      // console.log("whats-app-message-notification", data);
      setcustomer_last_msg_24hours(false);
      updateWebchatConversations(data);
      updateMessageStatus(getValue(data, "conversation", {}));
    });
    channel.bind("whats-app-message-status-notification", (data: any) => {
      // console.log("whats-app-message-status-notification", data);
      updateMessageStatus(getValue(data, "conversation", {}));
    });

    // Cleanup function
    return () => {
      channel.unbind_all();
      pusher.unsubscribe(currentChannelName);
    };
  }, [currentChannelName]); // Re-run when channel name changes

  const replaceObjectById = (array: any, updatedObject: any) => {
    return [
      ...array.map((item: any) => {
        if (item.id === updatedObject.id) {
          return updatedObject;
        }
        return item;
      }),
    ];
  };

  // Update the updateWebchatConversations function to move the conversation to the top
  const updateWebchatConversations = (newWebChatMessageInfo: any) => {
    const inboxId = getValue(newWebChatMessageInfo, "inbox_id", "");
    if (!inboxId) return;

    setConversations((prevConversations) => {
      const conversationExists = prevConversations.some(
        (conv) => conv.id === inboxId
      );

      if (!conversationExists) {
        // Option to handle new conversation if needed
        return prevConversations;
      }

      // First, find and remove the conversation that matches the inbox ID
      const updatedConversations = prevConversations.filter(
        (conv) => conv.id !== inboxId
      );

      // Then create the updated conversation object
      const updatedConversation = {
        ...prevConversations.find((conv) => conv.id === inboxId),
        ...getValue(newWebChatMessageInfo, "inbox", {}),
        last_msg: { ...newWebChatMessageInfo },
        last_msg_id: getValue(newWebChatMessageInfo, "id", ""),
      };

      // Add the updated conversation to the beginning of the array
      return [updatedConversation, ...updatedConversations];
    });
  };

  const updateWebchatInfoFromSocket = (
    newObject: any,
    unread: any,
    ignorePosition?: any
  ) => {
    // Only proceed if the newObject has an ID
    if (!getValue(newObject, "id", "")) {
      setChatInfo({});
      return;
    }

    const convStatus =
      getValue(UrlParams, "convStatus", "") || conversationStatus;
    const isAssigned = Boolean(getValue(newObject, "assigned_user_id", ""));
    const managedBy = getValue(newObject, "managed_by", "");
    const isTeamManaged = managedBy === "team";
    const isBotManaged = managedBy === "ai-chatbot";
    const conversationStatusValue = getValue(
      newObject,
      "conversation_status",
      ""
    );
    const currentUserId = userId; // Current logged-in user ID
    const assignedUserId = getValue(newObject, "assigned_user_id", ""); // The conversation's assigned user ID

    // Calculate the correct unread count
    const updatedUnreadCount = unread
      ? 0
      : getValue(newObject, "unread_count", 0);

    // Create the updated object once
    const updatedObject = {
      ...newObject,
      unread_count: updatedUnreadCount,
    };

    // Conditions for updating state based on conversation status
    const conditions: any = {
      // My chats (team managed + assigned + matching CURRENT user ID)
      my_open_chats:
        isTeamManaged && isAssigned && currentUserId === assignedUserId,
      my_waiting_on_customer_chats:
        isTeamManaged &&
        isAssigned &&
        currentUserId === assignedUserId &&
        conversationStatusValue === "my_waiting_on_customer_chats",
      my_waiting_on_us_chats:
        isTeamManaged &&
        isAssigned &&
        currentUserId === assignedUserId &&
        conversationStatusValue === "my_waiting_on_us_chats",
      my_on_hold_chats:
        isTeamManaged &&
        isAssigned &&
        currentUserId === assignedUserId &&
        conversationStatusValue === "my_on_hold_chats",
      my_resolved_chats:
        isTeamManaged &&
        isAssigned &&
        currentUserId === assignedUserId &&
        conversationStatusValue === "closed" &&
        convStatus === "my_resolved_chats",

      // All unassigned chats - only if not assigned and either team managed or new
      all_unassigned_chats:
        !isAssigned && (isTeamManaged || conversationStatusValue === "new"),

      // All chats - show all open chats regardless of assignment
      all_open_chats: true, // Will show all conversations since they're open
      all_waiting_on_customer_chats:
        !isAssigned || (isAssigned && currentUserId !== assignedUserId),
      all_waiting_on_us_chats:
        !isAssigned || (isAssigned && currentUserId !== assignedUserId),
      all_on_hold_chats:
        !isAssigned || (isAssigned && currentUserId !== assignedUserId),
      all_resolved_chats:
        conversationStatusValue === "closed" &&
        convStatus !== "my_resolved_chats",

      // Bot conversations
      all_bot_conversations: isBotManaged,
    };

    // If the current convStatus exists in our conditions map and the condition is true
    if (convStatus in conditions && conditions[convStatus]) {
      if (ignorePosition) {
        setConversations((prevConversations) => {
          // Find the index of the conversation to update
          const index = prevConversations.findIndex(
            (conversation) => conversation.id === updatedObject.id
          );
          
          // If not found, add it to the beginning
          if (index === -1) {
            return [updatedObject, ...prevConversations];
          }
          
          // If found, update it in the same position
          const newConversations = [...prevConversations];
          newConversations[index] = updatedObject;
          return newConversations;
        });
      } else {
        setConversations((prevConversations) => {
          const exists = prevConversations.some(
            (conv) => conv.id === updatedObject.id
          );

          if (!exists) {
            return [updatedObject, ...prevConversations];
          }

          // Filter out the conversation with the matching ID
          const filteredConversations = prevConversations.filter(
            (conversation) => conversation.id !== updatedObject.id
          );

          // Add the updated conversation at the beginning
          return [updatedObject, ...filteredConversations];
        });
      }
    }

    // Clear chat info state after processing
    // setChatInfo({});
  };
  // Also update the updateMessageStatus function to keep consistency
  const updateMessageStatus = (whatsappChat: any) => {
    if (!whatsappChat) return;

    const newMessage = getValue(whatsappChat, "last_msg", {});
    if (!newMessage || !newMessage.id) return;

    // Also move the conversation to top when status is updated
    const chatId = getValue(whatsappChat, "id", "");
    if (chatId) {
      setConversations((prevConversations) => {
        const conversationIndex = prevConversations.findIndex(
          (conv) => conv.id === chatId
        );

        if (conversationIndex !== -1) {
          const updatedConversations = [...prevConversations];
          const [currentConversation] = updatedConversations.splice(
            conversationIndex,
            1
          );
          return [currentConversation, ...updatedConversations];
        }

        return prevConversations;
      });
    }

    setConversation((prevConversation) => {
      // Check if the message already exists in the conversation
      const messageExists = prevConversation.some(
        (msg) => msg.id === newMessage.id
      );

      // If the message doesn't exist, add it
      if (!messageExists) {
        return [...prevConversation, newMessage];
      }

      // If the message exists, update the existing message with new information
      return prevConversation.map((msg) =>
        msg.id === newMessage.id
          ? {
              ...msg,
              is_delivered: newMessage.is_delivered ?? msg.is_delivered,
              is_private: newMessage.is_private ?? msg.is_private,
              is_read: newMessage.is_read ?? msg.is_read,
              is_received: newMessage.is_received ?? msg.is_received,
              is_replied: newMessage.is_replied ?? msg.is_replied,
              is_sent: newMessage.is_sent ?? msg.is_sent,
              status: newMessage.status ?? msg.status,
            }
          : msg
      );
    });
  };
  /* -------------------------------------------------------------------------- */
  /*                                Hooks Section                               */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (profileInfo.inbox_view) {
      if (Object.keys(UrlParams).length === 0) {
        getData(true, page_no, query, getValue(profileInfo, "inbox_view", ""));
        setConversationStatus(getValue(profileInfo, "inbox_view", ""));
        getTemplateList(templatePageNo, templatePageSize, templateSearchText);
        getConnectedWhatsappList();
      } else {
        getData(true, page_no, query, conversationStatus);
        getTemplateList(templatePageNo, templatePageSize, templateSearchText);
        getConnectedWhatsappList();
      }
    }
  }, [profileInfo]);

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) return;

    setContactInfo({});
    setSelectedContact({});
    setFormFields([]);
    setExpanded(false);
    setUserDetails(false);

    if (UrlParams.page_no) setPageNo(parseInt(UrlParams.page_no));
    if (UrlParams.searchText) setQuery(UrlParams.searchText);

    const updateFilter = (key: string, isArray = false) => {
      const value = isArray
        ? urlSearchParams.getAll(`${key}[]`)
        : getValue(UrlParams, key, "");
      if (value && (isArray ? value.length > 0 : true)) {
        setFilterRequest((prev) => ({ ...prev, [key]: value }));
        setFilterDirtyRequest((prev) => ({ ...prev, [key]: value }));
      }
    };

    updateFilter("wa_account_ids", true);
    updateFilter("chatbot_ids", true);
    updateFilter("web_chatbot_ids", true);
    updateFilter("forOrgUser", true);
    updateFilter("managed_by");
    updateFilter("source");
    updateFilter("conversation_status");
    updateFilter("filter_messaged_at");

    const waAccountIds = urlSearchParams.getAll("waAccountIds[]");
    const onlyUnread = getValue(UrlParams, "only_unread", "") === "true";

    if (waAccountIds.length > 0 || onlyUnread) {
      setFilterRequest((prev: any) => ({
        ...prev,
        only_unread: onlyUnread,
        waAccountIds,
      }));
      setFilterDirtyRequest((prev: any) => ({
        ...prev,
        only_unread: onlyUnread,
        waAccountIds,
      }));
    }

    getData(true, page_no, query, conversationStatus);
    if (
      getValue(UrlParams, `searchText`, "") &&
      (getValue(templateList, `length`, 0) === 0 ||
        getValue(allConnectionList, `length`, 0) === 0)
    ) {
      getTemplateList(templatePageNo, templatePageSize, templateSearchText);
      getConnectedWhatsappList();
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  /* ----------------------------  Whatsapp Contacts List  --------------------- */
  const [conversations, setConversations] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page_no, setPageNo] = useState(1);
  const [page_size, setPageSize] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const handleChangePagination = (page_no: number) => {
    setPageNo(page_no);
    let payload = {
      ...UrlParams,
      page_no: page_no,
      conversationId: selectedConversation,
      searchText: query,
      convStatus: conversationStatus,
      userId: "",
    };
    let queryRequest = QueryRequestHelper(payload);
    removeDependentState();
    setSelectedConversation("");
    setSelectedConversationInfo({});
    setStageRequest({});
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const [conversationStatus, setConversationStatus] = useState("my_open_chats");
  const [searchConversations, setSearchConversations] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const getData = async (
    status: boolean,
    page_no: number,
    searchText: string,
    convStatus: string
  ) => {
    // const getAssignedUserIdsArray = (
    //   urlSearchParams: URLSearchParams,
    //   key: string
    // ) => {
    //   return urlSearchParams.getAll(key);
    // };

    const fetchParams = async () => {
      return {
        waAccountIds: urlSearchParams.getAll("wa_account_ids[]"),
        chatbotIds: urlSearchParams.getAll("chatbot_ids[]"),
        webChatbotIds: urlSearchParams.getAll("web_chatbot_ids[]"),
        forOrgUser: urlSearchParams.getAll("forOrgUser[]"),
      };
    };

    // Wait for all params to be processed
    const { waAccountIds, chatbotIds, webChatbotIds, forOrgUser } =
      await fetchParams();

    const combinedChatbotIds = [...chatbotIds, ...webChatbotIds];

    try {
      setConversationStatus(convStatus);
      if (!getValue(UrlParams, `searchText`, "")) {
        setIsLoading(status);
      }
      let payload = getValue(UrlParams, `searchText`, "")
        ? {
            page_no: getValue(UrlParams, `page_no`, "")
              ? getValue(UrlParams, `page_no`, "")
              : page_no,
            page_size: getValue(UrlParams, `page_size`, "")
              ? getValue(UrlParams, `page_size`, "")
              : page_size,
            search: getValue(UrlParams, `searchText`, "")
              ? getValue(UrlParams, `searchText`, "")
              : query,
            source: getValue(UrlParams, `source`, ""),
            status: getValue(UrlParams, `convStatus`, "")
              ? getValue(UrlParams, `convStatus`, "")
              : convStatus
              ? convStatus
              : conversationStatus,
          }
        : {
            page_no: getValue(UrlParams, `page_no`, "")
              ? getValue(UrlParams, `page_no`, "")
              : page_no,
            page_size: getValue(UrlParams, `page_size`, "")
              ? getValue(UrlParams, `page_size`, "")
              : page_size,
            search: getValue(UrlParams, `searchText`, "")
              ? getValue(UrlParams, `searchText`, "")
              : query,
            status: getValue(UrlParams, `convStatus`, "")
              ? getValue(UrlParams, `convStatus`, "")
              : convStatus
              ? convStatus
              : conversationStatus,
            // assigned_user_id: getValue(UrlParams, `forOrgUser`, [])
            //   ? getValue(UrlParams, `forOrgUser`, [])
            //   : getValue(UrlParams, `userId`, ""),
            // waAccountIds: waAccountIds,
            only_unread: getValue(UrlParams, `only_unread`, ""),
            source: getValue(UrlParams, `source`, ""),
            wa_account_ids: urlSearchParams.getAll("wa_account_ids[]"),
            chatbot_ids: combinedChatbotIds,
            managed_by: getValue(UrlParams, `managed_by`, ""),
            conversation_status: getValue(UrlParams, `conversation_status`, ""),
            assigned_user_ids: urlSearchParams.getAll("forOrgUser[]"),
            filter_messaged_at: getValue(UrlParams, `filter_messaged_at`, ""),
          };
      let queryRequest = QueryRequestHelperWithRepeat(payload);
      let resp: any;
      if (getValue(UrlParams, `searchText`, "")) {
        resp = await getLiveChatConversationListBySearch(
          removeNullOrUndefinedProperties(payload)
        );
      } else {
        resp = await getLiveChatConversationList(queryRequest);
      }
      if (resp) {
        let result = getValue(resp, `data.inbox`, []);
        const currentFiltered = result.map((item: object) => {
          if (getValue(item, `id`, "") === getValue(chatInfo, `id`, "")) {
            return {
              ...item,
              unread_count: 0,
            };
          } else {
            return {
              ...item,
            };
          }
        });
        if (getValue(UrlParams, `searchText`, "")) {
          setSearchConversations(result);
        } else {
          setConversations(result);
        }

        const findInfo = result.find(
          (item: any) =>
            getValue(item, `id`, "") ===
            getValue(selectedConversationInfo, `id`, "")
        );
        setSelectedConversationInfo(findInfo);
        setStageRequest({
          stage: getValue(findInfo, `${selectedModule}.pipeline_stage_id`, ""),
        });
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* ----------------------------  Whatsapp Specific Contactinfo data  --------------------- */

  const [m_page_no, setMPageNo] = useState(1);
  const [m_page_size, setMPageSize] = useState(30);
  const [m_total_count, setMTotalCount] = useState(0);
  const [conversationLoading, setConversationLoading] = useState(false);
  const [selectedContact, setSelectedContact] = useState<any>({});
  const getSpecificConversation = async (
    status: boolean,
    id: string,
    page_no: any,
    type: string
  ) => {
    try {
      setConversationLoading(status);
      let payload = {
        page_no: page_no ? page_no : m_page_no,
        page_size: m_page_size,
        inbox_id: id,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getSpecificLiveChatConversation(queryRequest);
      if (resp) {
        const newMessages = getValue(resp, `data.conversations`, []);
        if (type === "new") {
          setConversation(newMessages);
        } else {
          setConversation((prevConvo) => [...newMessages, ...prevConvo]);
        }
        setcustomer_last_msg_24hours(
          getValue(resp, `data.conversations[${0}].inbox.source`, "") !==
            "whatsapp"
            ? false
            : page_no === 1
            ? getValue(resp, `data.customer_last_msg_24hours`, false)
            : false
        );

        setMTotalCount(getValue(resp, `data.pagination.total`, 0));
        setConversationLoading(false);
      } else {
        setConversationLoading(false);
      }
    } catch (error) {
      setConversationLoading(false);
    }
  };
  const fetchMoreMessages = () => {
    if (!conversationLoading && conversation.length < m_total_count) {
      setMPageNo(m_page_no + 1); // Increment page number
      getSpecificConversation(false, selectedConversation, m_page_no + 1, "");
    }
  };
  const [selectedConversationInfo, setSelectedConversationInfo] = useState({});
  const handleRemovePipelineInfo = () => {
    setMPageNo(1);
    setConversation([]);
    setSelectedContact({});
    setStaticFieldRequest({
      ...staticFieldRequest,
      pipeline_id: "",
      stage: "",
    });
    setFormFields([]);
    setExpanded(false);
    setUserDetails(false);
    setContactInfo({});
  };
  /* -------------------------------------------------------------------------- */
  /*                             Send Message section                           */
  /* -------------------------------------------------------------------------- */
  const [selectedModule, setSelectedModule] = useState("");
  const removeDependentState = () => {
    setChatInfo({});
    setMessage("");
    setPrivateMessage("");
    setParentChage(true);
  };
  const handleSelectConvesation = (item: any) => {
    removeDependentState();
    // setDocumentRequest([]);
    // setMediaLibraryDocumentRequest([]);
    setDocExpanded(false);
    setSelectedMediaLibraryFilesIds([]);
    setCurrentChannelName(getValue(item, `web_channel`, ""));
    if (selectedConversation !== getValue(item, `id`, "")) {
      setSelectedConversation(getValue(item, `id`, ""));
      setSelectedConversationInfo({
        ...item,
        last_name: getValue(item, `wa_profile_name`, ""),
        mobile_number: getValue(item, `wa_id`, ""),
      });
      if (
        getValue(item, `unread_count`, "") > 0 &&
        getValue(item, `last_msg_id`, "")
      ) {
        getSpecificConversation(true, getValue(item, `id`, ""), 1, "");
        updateWebchatInfoFromSocket(item, true, true);
        setSelectedConversation(getValue(item, `id`, ""));
        handleReadMessage(getValue(item, `id`, ""));
        getContactsLeadsInfo(item);
      } else {
        getSpecificConversation(true, getValue(item, `id`, ""), 1, "");
        if (getValue(item, `contact_id`, "")) {
          setSelectedModule("contacts");
          getSpecificContactInfo(getValue(item, `contact_id`, ""));
        } else if (getValue(item, `lead_id`, "")) {
          setSelectedModule("leads");
          getSpecificLeadInfo(getValue(item, `lead_id`, ""));
        }
      }
      // getData(false, 1, "", conversationStatus);
    }
  };
  const getContactsLeadsInfo = (item: any) => {
    if (getValue(item, `contact_id`, "")) {
      setSelectedModule("contacts");
      getSpecificContactInfo(getValue(item, `contact_id`, ""));
    } else if (getValue(item, `lead_id`, "")) {
      setSelectedModule("leads");
      getSpecificLeadInfo(getValue(item, `lead_id`, ""));
    } else {
      setContactInfo({});
      setColumnsList([]);
      setColumnLabelInfo({});
    }
  };
  const [parentChage, setParentChage] = useState(false);
  const [cannedRespFileId, setCannedRespFileId] = useState("");
  const [cannedRespFileType, setCannedRespFileType] = useState("");
  const livechatPayload = (message: string) => {
    if (getValue(selectedConversationInfo, `source`, "") === "whatsapp") {
      return {
        inbox_id: getValue(selectedConversationInfo, `id`, ""),
        source: getValue(selectedConversationInfo, `source`, ""),
        type: cannedRespFileId
          ? "image"
          : // : cannedRespFileType
            // ? cannedRespFileType
            "text",
        is_private: false,
        text: message,
        replied_message_id: getValue(selectedReplyMessage, `id`, ""),
        file_id: cannedRespFileId,
      };
    } else {
      return {
        web_channel: getValue(selectedConversationInfo, `web_channel`, ""),
        name: getValue(selectedConversationInfo, `org_user_id`, "")
          ? `${getValue(
              selectedConversationInfo,
              `org_user.first_name`,
              ""
            )}${" "}${getValue(
              selectedConversationInfo,
              `org_user.last_name`,
              ""
            )}`
          : `${getValue(profileInfo, `first_name`, "")}${" "}${getValue(
              profileInfo,
              `last_name`,
              ""
            )}`,
        inbox_id: getValue(selectedConversationInfo, `id`, ""),
        org_user_id: getValue(selectedConversationInfo, `org_user_id`, "")
          ? getValue(selectedConversationInfo, `org_user_id`, "")
          : getValue(profileInfo, `id`, ""),
        text: message,
        type: "text",
        source: getValue(selectedConversationInfo, `source`, ""),
      };
    }
  };
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const handleSendMessages = async () => {
    if (message) {
      try {
        const messageToSend = message;
        if (messageToSend) {
          setMessage("");
          setParentChage(true);
        }
        setIsMessageLoading(true);
        let payload = livechatPayload(messageToSend);
        const resp = await liveChatSendMessage(payload);
        if (resp) {
          setMessage("");
          setCannedRespFileId("");
          setCannedRespFileType("");
          setSelectedConversation(selectedConversation);
          setSelectedReplyMessage(null);

          //update state only for whatsapp
          if (getValue(payload, `source`, "") === "whatsapp") {
            setConversation((prevConversation) => {
              const newMessage = getValue(resp, `data`, {});
              // Check if the message already exists in the conversation
              const messageExists = prevConversation.some(
                (msg) => msg.id === newMessage.id
              );
              // If the message doesn't
              //  exist, add it
              if (!messageExists) {
                return [...prevConversation, newMessage];
              }
              // If the message exists, update the existing message with new information
              return prevConversation.map((msg) =>
                msg.id === newMessage.id
                  ? {
                      ...msg,
                      is_delivered: newMessage.is_delivered ?? msg.is_delivered,
                      is_private: newMessage.is_private ?? msg.is_private,
                      is_read: newMessage.is_read ?? msg.is_read,
                      is_received: newMessage.is_received ?? msg.is_received,
                      is_replied: newMessage.is_replied ?? msg.is_replied,
                      is_sent: newMessage.is_sent ?? msg.is_sent,
                      status: newMessage.status ?? msg.status,
                    }
                  : msg
              );
            });
          }

          // Move the current conversation to the top of the conversations list
          setConversations((prevConversations) => {
            // Find the current conversation in the list
            const currentConversationIndex = prevConversations.findIndex(
              (conv) => conv.id === selectedConversation
            );

            // If conversation is found, move it to the top
            if (currentConversationIndex !== -1) {
              const updatedConversations = [...prevConversations];
              const [currentConversation] = updatedConversations.splice(
                currentConversationIndex,
                1
              );
              return [currentConversation, ...updatedConversations];
            }

            return prevConversations;
          });

          scrollToBottom();
          // getSpecificConversation(false, selectedConversation, 1, "new");
        } else {
          setCannedRespFileId("");
          setCannedRespFileType("");
        }
      } catch (error) {
        setCannedRespFileId("");
        setCannedRespFileType("");
      } finally {
        setCannedRespFileId("");
        setCannedRespFileType("");
        setIsMessageLoading(false);
      }
    }
  };
  const [isPrivateMessageLoading, setIsPrivateMessageLoading] = useState(false);
  const handleSendPrivateMessages = async () => {
    if (privateMessage) {
      try {
        setIsPrivateMessageLoading(true);
        let resp = await liveChatSendMessage({
          ...livechatPayload(privateMessage),
          is_private: true,
        });
        if (resp) {
          setPrivateMessage("");
          setParentChage(true);
          setSelectedConversation(selectedConversation);
          // getSpecificConversation(false, selectedConversation, 1, "new");
        }
      } catch (error) {
      } finally {
        setIsPrivateMessageLoading(false);
      }
    }
  };

  const [isSendLoading, setIsSendLoading] = useState(false);
  const handleSendDocumentMessages = async () => {
    setIsSendLoading(true);
    try {
      await Promise.all(
        documentRequest.map((item: object, index: number) => {
          return triggerAPI(
            {
              type: findType(getValue(item, `fileId.extension`, "")),
              text: getValue(item, `textBody`, ""),
              file_id: getValue(item, `fileId.id`, ""),
              source: getValue(selectedConversationInfo, `source`, ""),
              inbox_id: getValue(selectedConversationInfo, `id`, ""),
              web_channel: getValue(
                selectedConversationInfo,
                `web_channel`,
                ""
              ),
            },
            index
          );
        })
      );
      setDocumentRequest([]);
    } catch (error) {
    } finally {
      setIsSendLoading(false);
    }
  };
  const handleSendLibraryDocumentMessages = async () => {
    setIsSendLoading(true);
    try {
      await Promise.all(
        mediaLibraryDocumentRequest.map((item: object, index: number) => {
          return triggerAPI(
            {
              type: getValue(item, `file_type`, ""),
              text: getValue(item, `textBody`, ""),
              file_id: getValue(item, `file_id`, ""),
              source: getValue(selectedConversationInfo, `source`, ""),
              inbox_id: getValue(selectedConversationInfo, `id`, ""),
              web_channel: getValue(
                selectedConversationInfo,
                `web_channel`,
                ""
              ),
            },
            index
          );
        })
      );
      setMediaLibraryDocumentRequest([]);
      setSelectedMediaLibraryFilesIds([]);
    } catch (error) {
    } finally {
      setIsSendLoading(false);
    }
  };
  const triggerAPI = async (obj: object, index: number) => {
    try {
      let resp = await liveChatSendMessage(
        removeNullOrUndefinedProperties(obj)
      );
      if (resp) {
        if (index == 0) {
          setDocumentRequest([]);
          setMediaLibraryDocumentRequest([]);
          setDocExpanded(false);
          getSpecificConversation(false, selectedConversation, 1, "new");
          setSelectedReplyMessage(null);
        }
      }
    } catch (error) {}
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleAddToDocuments = async (
    fileId: string,
    id: string,
    key: string
  ) => {
    try {
      setSubmitLoading(true);
      let payload = {
        files: [
          {
            action: "add",
            file_id: fileId,
          },
        ],
        [key]: id,
      };
      let resp = await createAttachments(payload);
      if (resp) {
        toast.success("Added successfully");
        setSubmitLoading(false);
        if (selectedModule === "contacts") {
          getSpecificContactInfo(getValue(contactInfo, "id", ""));
        } else {
          getSpecificLeadInfo(getValue(contactInfo, "id", ""));
        }
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                   Chat section                             */
  /* -------------------------------------------------------------------------- */
  const emojiPickerRef = useRef<any>(null);
  useEffect(() => {
    // Function to handle click outside
    const handleClickOutside = (event: any) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
    };
    // Attach the event listener on mount
    document.addEventListener("mousedown", handleClickOutside);
    // Detach the event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [emojiPickerRef]);

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };
  const ref = useRef<any>();
  const [message, setMessage] = useState<any>("");
  const [privateMessage, setPrivateMessage] = useState<any>("");

  const handleEmojiClick = (event: any, emojiObject: any) => {
    if (ref.current) {
      const cursor = ref.current.selectionStart;
      const newText =
        message.slice(0, cursor) + event.emoji + message.slice(cursor);
      setMessage(newText);
      toggleEmojiPicker();
    }
  };
  const handlePrivateEmojiClick = (event: any, emojiObject: any) => {
    if (ref.current) {
      const cursor = ref.current.selectionStart;
      const newText =
        message.slice(0, cursor) + event.emoji + message.slice(cursor);
      setPrivateMessage(newText);
      toggleEmojiPicker();
    }
  };
  const [documentRequest, setDocumentRequest] = useState<any>([]);
  const [mediaLibraryDocumentRequest, setMediaLibraryDocumentRequest] =
    useState<any>([]);

  const [uploadLoading, setUploadLoading] = useState(false);

  const handleUploadDocument = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!e.target.files) return; // Exit if no files are selected

    const files = Array.from(e.target.files) as File[]; // Explicitly cast to File[]

    for (const file of files) {
      const fileExt = `.${file.name.split(".").pop()?.toLowerCase() || ""}`;

      if (!allowedFormats.includes(fileExt)) {
        toast.error(
          `Invalid file format. Allowed formats: ${allowedFormats.join(", ")}`
        );
        continue;
      }

      const maxSize = fileSizeLimits[fileExt.slice(1)] || 5 * 1024 * 1024; // Default 5MB

      if (file.size > maxSize) {
        toast.error(
          `File size must be less than ${
            maxSize / (1024 * 1024)
          }MB for ${fileExt}`
        );
        continue;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("filename", file.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");

      try {
        setUploadLoading(true);
        const resp = await mediaUpload(formData);

        if (resp) {
          setDocExpanded(true);

          setDocumentRequest((prev: any) => {
            const updatedRequest = [
              ...prev,
              { fileId: getValue(resp, "data", {}) },
            ];
            // console.log("Updated Document Request:", updatedRequest); // Debugging
            return updatedRequest;
          });

          // Reset file input after successful upload
          if (e.target) e.target.value = "";
        }
      } catch (error) {
        toast.error("File upload failed. Please try again.");
      } finally {
        setUploadLoading(false);
      }
    }
  };

  const handleUploadDragDrop = (files: FileList) => {
    Array.from(files).forEach(async (file) => {
      const fileExt = file.name.split(".").pop()?.toLowerCase() || "";

      if (!isValidFileFormat(file.name)) {
        toast.error(
          `Invalid file format. Allowed formats: ${allowedFormats.join(", ")}`
        );
        return;
      }

      const maxSize = getFileSizeLimit(fileExt);
      if (file.size > maxSize) {
        toast.error(
          `File size must be less than ${
            maxSize / (1024 * 1024)
          }MB for .${fileExt} files`
        );
        return;
      }

      let formData = new FormData();
      formData.append("file", file);
      formData.append("filename", file.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");

      try {
        setUploadLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          setDocExpanded(true);
          setDocumentRequest((prev: any) => [
            ...prev,
            { fileId: getValue(resp, `data`, {}) },
          ]);
        }
      } catch (error) {
        toast.error("Error uploading file");
      } finally {
        setUploadLoading(false);
      }
    });
  };

  const [selectedReplyMessage, setSelectedReplyMessage] = useState<any>(null);
  const handleReadMessage = async (conversationId: string) => {
    try {
      let resp = await readLiveChatConversation(conversationId);
      if (resp) {
        // addSocketMessageStatusToChat(messageId, "is_read");
        if (getValue(chatInfo, "unread_count", 0) > 0) {
          getAllCount();
        }
        setChatInfo({
          ...chatInfo,
          unread_count: 0,
        });
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                                  Contact Section                           */
  /* -------------------------------------------------------------------------- */

  const [contactInfo, setContactInfo] = useState({});
  const [columnList, setColumnsList] = useState([]);
  const [columnLabelInfo, setColumnLabelInfo] = useState({});
  const [contactFields, setConatctFields] = useState([]);
  const [stageRequest, setStageRequest] = useState({});
  const [dirtyFields, setDirtyFields] = useState([]);
  const [stageList, setStageList] = React.useState<any>([]);
  const [contactInfoLoading, setContactInfoLoading] = useState(false);
  const getSpecificContactInfo = async (id: string) => {
    if (id)
      try {
        setContactInfoLoading(true);
        setConatctFields([]);
        setDirtyFields([]);
        let resp = await getSpecificContact(id);
        if (resp) {
          setContactInfo(getValue(resp, `data`, {}));
          if (getValue(resp, `data.pipeline_id`, "")) {
            let columnsList = await getSpecificPipelineColumns(
              await selectedModuleId("contacts"),
              getValue(resp, `data.pipeline_id`, "")
            );
            let stages = await getSpecificPipeline(
              await selectedModuleId("contacts"),
              getValue(resp, "data.pipeline_id", "")
            );
            setStageList(getValue(stages, `data.stages`, []));

            setColumnsList(
              getValue(columnsList, `data`, []).map((item: object) =>
                getValue(item, `api_name`, "")
              )
            );

            const obj: any = {};
            getValue(columnsList, `data`, []).forEach(
              (value: object, index: number) => {
                obj[getValue(value, `api_name`, "")] = getValue(
                  value,
                  `label`,
                  ""
                );
              }
            );
            setColumnLabelInfo(obj);
            let response = await getSpecificPipeline(
              selectedModuleId(),
              getValue(resp, `data.pipeline_id`, "")
            );
            let fields =
              getValue(response, `data.form_fields.length`, 0) > 0
                ? getValue(response, `data.form_fields`, []).map(
                    (item: object) => ({
                      ...getValue(item, `module_field`, {}),
                      name: getValue(item, `name`, ""),
                      quick_add: getValue(item, `quick_add`, ""),
                      required: getValue(item, `required`, ""),
                      seq_num: getValue(item, `seq_num`, ""),
                    })
                  )
                : [];
            let array: any = appendObjectValuesToArray(
              fields,
              getValue(resp, `data`, {})
            );
            setConatctFields(array);
            setDirtyFields(array);
            setStageRequest({
              stage: getValue(resp, `data.pipeline_stage_id`, ""),
            });
          }
          setContactInfoLoading(false);
        } else {
          setContactInfoLoading(false);
        }
      } catch (error) {
        setContactInfoLoading(false);
      }
  };

  const getSpecificLeadInfo = async (id: string) => {
    if (id)
      try {
        let resp = await getSpecificLead(id);
        if (resp) {
          setContactInfo(getValue(resp, `data`, {}));
          if (getValue(resp, `data.pipeline_id`, "")) {
            let columnsList = await getSpecificPipelineColumns(
              await selectedModuleId("leads"),
              getValue(resp, `data.pipeline_id`, "")
            );
            let stages = await getSpecificPipeline(
              await selectedModuleId("contacts"),
              getValue(resp, "data.pipeline_id", "")
            );
            setStageList(getValue(stages, `data.stages`, []));

            setColumnsList(
              getValue(columnsList, `data`, []).map((item: object) =>
                getValue(item, `api_name`, "")
              )
            );

            const obj: any = {};
            getValue(columnsList, `data`, []).forEach(
              (value: object, index: number) => {
                obj[getValue(value, `api_name`, "")] = getValue(
                  value,
                  `label`,
                  ""
                );
              }
            );
            setColumnLabelInfo(obj);
            let response = await getSpecificPipeline(
              selectedModuleId(),
              getValue(resp, `data.pipeline_id`, "")
            );
            let fields =
              getValue(response, `data.form_fields.length`, 0) > 0
                ? getValue(response, `data.form_fields`, []).map(
                    (item: object) => ({
                      ...getValue(item, `module_field`, {}),
                      name: getValue(item, `name`, ""),
                      quick_add: getValue(item, `quick_add`, ""),
                      required: getValue(item, `required`, ""),
                      seq_num: getValue(item, `seq_num`, ""),
                    })
                  )
                : [];
            let array: any = appendObjectValuesToArray(
              fields,
              getValue(resp, `data`, {})
            );
            setConatctFields(array);
            setDirtyFields(array);
          }
        }
      } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                            Form Creation Section                           */
  /* -------------------------------------------------------------------------- */
  const [staticFieldRequest, setStaticFieldRequest] = useState<any>({
    pipeline_id: "",
    stage: "",
  });
  const [options, setOptions] = useState([]);
  const [optionLoading, setOptionLoading] = useState(false);
  const getAllPipelines = async (module: string) => {
    try {
      setOptionLoading(true);
      let payload = {
        module_name: module,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await allPipelines(
        await selectedModuleId(module),
        queryRequest
      );
      if (resp) {
        let list =
          getValue(resp, `data.length`, 0) > 0
            ? getValue(resp, `data`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `api_name`, ""),
                label: getValue(item, `label`, ""),
              }))
            : [];
        setStaticFieldRequest((prevState: any) => ({
          ...prevState,
          pipeline_id: getValue(list, `[${0}].id`, ""),
          stage: getValue(list, `[${0}].stage[${0}].id`, ""),
        }));
        getFormFields(getValue(list, `[${0}].id`, ""));
        //**----------------------- creating pipeline options---------------- */
        setOptions(list);
        setOptionLoading(false);
      } else {
        setOptionLoading(false);
      }
    } catch (error) {
      setOptionLoading(false);
    }
  };
  const [stages, setStages] = useState([]);
  const [formFields, setFormFields] = useState<any>([]);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const getFormFields = async (id: string) => {
    try {
      setIsFormLoading(true);
      let resp = await getSpecificPipeline(
        await selectedModuleId("contacts"),
        id
      );
      if (resp) {
        let stages =
          getValue(resp, `data.stages.length`, 0) > 0
            ? getValue(resp, `data.stages`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `api_name`, ""),
                label: getValue(item, `label`, ""),
              }))
            : [];
        setStaticFieldRequest({
          ...staticFieldRequest,
          pipeline_id: getValue(resp, `data.id`, ""),
          stage: getValue(stages, `[${0}].id`, ""),
        });
        setStages(stages);

        let fields =
          getValue(resp, `data.form_fields.length`, 0) > 0
            ? getValue(resp, `data.form_fields`, []).map((item: object) => ({
                ...getValue(item, `module_field`, {}),
                name: getValue(item, `api_name`, ""),
                quick_add: getValue(item, `quick_add`, ""),
                required: getValue(item, `required`, ""),
                seq_num: getValue(item, `seq_num`, ""),
              }))
            : [];
        for (const item of fields) {
          if (item.api_name === "owner_id") {
            item.value = userId;
          }
        }
        let array: any = appendObjectValuesToArray(
          fields,
          selectedConversationInfo
        );

        setFormFields(removeDuplicatesById(array));

        setIsFormLoading(false);
      } else {
        setIsFormLoading(false);
      }
    } catch (error) {
      setIsFormLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                   Chat section                             */
  /* -------------------------------------------------------------------------- */
  const [query, setQuery] = useState("");
  const handleChangeSearch = (e: any) => {
    const value = e.target.value.trim(" ");
    setQuery(value);
    let payload = {
      ...UrlParams,
      status: false,
      page_no: page_no,
      conversationId: selectedConversation,
      searchText: value,
      convStatus: getValue(UrlParams, `convStatus`, "")
        ? getValue(UrlParams, `convStatus`, "")
        : conversationStatus,
      userId: "",
    };
    let queryRequest = QueryRequestHelper(payload);
    removeDependentState();
    setSelectedConversation("");
    setSelectedConversationInfo({});
    setStageRequest({});
    setContactInfo({});
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const clearSearch = () => {
    setQuery("");
    let payload = {
      ...UrlParams,
      searchText: "",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const maxLength = 260;

  const [userDetails, setUserDetails] = useState(false);
  const toggleUserDetails = (module: string) => {
    resetPipelineForm();
    setSelectedType(module);
    getAllPipelines(module);
    setUserDetails(!userDetails);
  };

  const handleAddContact = () => {
    // getAllPipelines(module)
    resetPipelineForm();
    handleToggleWidth();
    setUserDetails(false);
  };

  const resetPipelineForm = () => {
    setSelectedType("");
    setStaticFieldRequest({
      pipeline_id: "",
      stage: "",
    });
    setFormFields([]);
  };

  const navigate = useNavigate();
  const handleClickFilter = (type: string, id: string) => {
    setContactInfo({});
    setSelectedContact({});
    setSelectedConversation("");
    delete UrlParams.conversationId;
    delete UrlParams.searchText;
    setQuery("");
    let payload = {
      ...UrlParams,

      page_no: 1,
      conversationId: "",
      searchText: "",
      convStatus: type,
      userId: id,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Whatsapp Channel                             */
  /* -------------------------------------------------------------------------- */
  const [isOpenFilterView, setIsOpenFilterView] = useState(false);
  const toggleFilterView = () => {
    setIsOpenFilterView(!isOpenFilterView);
  };

  const [filterRequest, setFilterRequest] = useState({
    only_unread: false,
    waAccountIds: [],
    wa_account_ids: [],
    chatbot_ids: [],
    forOrgUser: [],
    managed_by: "",
    source: "",
    conversation_status: "",
    chat_assignment_status: "",
    filter_messaged_at: "",
    web_chatbot_ids: [],
  });
  const [filterDirtyRequest, setFilterDirtyRequest] = useState({
    only_unread: false,
    waAccountIds: [],
    forOrgUser: [],
    wa_account_ids: [],
    chatbot_ids: [],
    managed_by: "",
    source: "",
    conversation_status: "",
    chat_assignment_status: "",
    filter_messaged_at: "",
    web_chatbot_ids: [],
  });
  const [allConnectionList, setAllConnectionList] = useState([]);
  const getConnectedWhatsappList = async () => {
    try {
      let payload = {
        status: "active",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getWhatsappManagerConfig(queryRequest);
      if (resp) {
        if (getValue(resp, `data.details.length`, 0) > 0) {
          setIsWAConfigured(true);
          setAllConnectionList(
            getValue(resp, `data.details.length`, 0) > 0
              ? getValue(resp, `data.details`, []).map((item: any) => ({
                  ...item,
                  status: item.is_active ? "active" : "inactive",
                  value: getValue(item, `id`, ""),
                  label:
                    getValue(item, `verifiedName`, "") +
                    " ( " +
                    getValue(item, `displayPhoneNumber`, "") +
                    " )",
                }))
              : []
          );
          setSelectedWaAccount(getValue(resp, `data.details[${0}].id`, ""));
        }
      }
    } catch (error) {}
  };

  const handleApplyFilter = () => {
    if (getValue(filterRequest, `only_unread`, false)) {
      delete UrlParams.only_unread;
    }
    delete UrlParams["waAccountIds[]"];
    delete UrlParams["web_chatbot_ids[]"];
    delete UrlParams["chatbot_ids[]"];
    delete UrlParams["forOrgUser[]"];
    let payload = {
      ...UrlParams,
      waAccountIds: getValue(filterRequest, `waAccountIds`, []),
      only_unread: getValue(filterRequest, `only_unread`, false),
      forOrgUser: getValue(filterRequest, `forOrgUser`, []),
      wa_account_ids: getValue(filterRequest, `wa_account_ids`, []),
      chatbot_ids: getValue(filterRequest, `chatbot_ids`, []),
      managed_by: getValue(filterRequest, `managed_by`, ""),
      source: getValue(filterRequest, `source`, ""),
      conversation_status: getValue(filterRequest, `conversation_status`, ""),
      chat_assignment_status: getValue(
        filterRequest,
        `chat_assignment_status`,
        ""
      ),
      filter_messaged_at: getValue(filterRequest, `filter_messaged_at`, ""),
      web_chatbot_ids: getValue(filterRequest, `web_chatbot_ids`, []),
    };
    let queryRequest = QueryRequestHelperWithAll(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    toggleFilterView();
  };

  const handleApplyUnReadFilter = (isUnread: boolean) => {
    setFilterRequest((prevRequest) => {
      const updatedFilter = {
        ...prevRequest,
        only_unread: isUnread,
      };

      let payload = {
        ...UrlParams,
        waAccountIds: getValue(updatedFilter, `waAccountIds`, []),
        only_unread: getValue(updatedFilter, `only_unread`, false),
        forOrgUser: getValue(updatedFilter, `forOrgUser`, []),
      };

      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);

      return updatedFilter;
    });
    setFilterDirtyRequest((prevRequest) => {
      const updatedFilter = {
        ...prevRequest,
        only_unread: isUnread,
      };

      let payload = {
        ...UrlParams,
        waAccountIds: getValue(updatedFilter, `waAccountIds`, []),
        only_unread: getValue(updatedFilter, `only_unread`, false),
        forOrgUser: getValue(updatedFilter, `forOrgUser`, []),
      };

      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);

      return updatedFilter;
    });
  };

  const handleResetFilter = () => {
    setFilterRequest({
      only_unread: false,
      waAccountIds: [],
      forOrgUser: [],
      wa_account_ids: [],
      chatbot_ids: [],
      managed_by: "",
      source: "",
      conversation_status: "",
      chat_assignment_status: "",
      filter_messaged_at: "",
      web_chatbot_ids: [],
    });
    // setFilterRequest((prevState: any) => ({
    //   ...prevState,
    //   waAccountIds: [],
    // }));
    setFilterDirtyRequest({
      only_unread: false,
      waAccountIds: [],
      forOrgUser: [],
      wa_account_ids: [],
      chatbot_ids: [],
      managed_by: "",
      source: "",
      conversation_status: "",
      chat_assignment_status: "",
      filter_messaged_at: "",
      web_chatbot_ids: [],
    });
    // setFilterDirtyRequest((prevState: any) => ({
    //   ...prevState,
    //   waAccountIds: [],
    // }));
    delete UrlParams.only_unread;
    delete UrlParams.waAccountIds;
    delete UrlParams.forOrgUser;
    delete UrlParams.wa_account_ids;
    delete UrlParams.chatbot_ids;
    delete UrlParams.managed_by;
    delete UrlParams.source;
    delete UrlParams.conversation_status;
    delete UrlParams.chat_assignment_status;
    delete UrlParams.filter_messaged_at;
    delete UrlParams.web_chatbot_ids;
    getData(true, page_no, query, conversationStatus);
    navigate(`/${getValue(params, "orgId", "")}/live-chat`);
    // toggleFilterView();
  };

  const [selectedType, setSelectedType] = useState("");

  const isLeadShow = () => {
    let info = modules.find(
      (item: object) => getValue(item, `api_name`, "") === "leads"
    );
    return getValue(info, `api_name`, "");
  };

  const filterCount = useMemo(() => {
    return Object.entries(filterRequest).filter(
      ([key, value]) =>
        key !== "only_unread" &&
        (value === true || (typeof value === "string" && value))
    ).length;
  }, [filterRequest]);

  const [openCannedResponse, setOpenCannedResponse] = useState(false);
  const [cannedPopupPosition, setCannedPopupPosition] = useState({
    top: 0,
    left: 0,
  });
  const [triggerCannedResponseList, setTriggerCannedResponseList] =
    useState(true);
  const handleChangeEditor = (value: any) => {
    setParentChage(true);
    setMessage(value);
    // Detect if the first character is `/`
    // const content = editorState.getCurrentContent().getPlainText();
    const content = value;
    if (content.trimStart() === "/") {
      setTriggerCannedResponseList(true);
      // Get cursor position for the popup
      // const editorBounds = ref.current?.getBoundingClientRect();
      // const calculatedPosition = getCursorPosition(editorBounds);
      // setCannedPopupPosition(calculatedPosition);
      setOpenCannedResponse(true); // Show the popover
    } else {
      setOpenCannedResponse(false); // Hide the popover
    }
  };

  const handleCannedEditorButton = () => {
    setOpenCannedResponse((prevState) => {
      const newState = !prevState;
      if (newState) {
        setTriggerCannedResponseList(true);
      }
      return newState;
    });
  };

  const getCursorPosition = (editorBounds: any) => {
    const { top, left } = editorBounds;
    return {
      top: top + 20,
      left: left + 20,
    };
  };

  const handleCannedOpen = (newOpen: boolean) => {
    setOpenCannedResponse(newOpen);
  };

  const handleChangePrivateEditor = (value: string) => {
    setPrivateMessage(value);
  };
  /* -------------------------------------------------------------------------- */
  /*                              bottom chat Scroll                            */
  /* -------------------------------------------------------------------------- */
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  // const scrollToBottom = () => {
  //   // messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  //   messagesEndRef.current?.scrollIntoView({ behavior: "auto", block: "end" });
  // };

  // useEffect(() => {
  //   scrollToBottom();
  // }, [getValue(conversation, `msgs`, [])]);

  /* -------------------------------------------------------------------------- */
  /*                              Broadcast Scroll                              */
  /* -------------------------------------------------------------------------- */
  const [customer_last_msg_24hours, setcustomer_last_msg_24hours] =
    useState(false);

  const [isLoading1, setIsLoading1] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [templatePageNo, setTemplatePageNo] = useState(1);
  const [templatePageSize, setTemplatePageSize] = useState(50);
  const [templateTotalCount, setTemplateTotalCount] = useState(0);
  const [templateSearchText, setTemplateSearchText] = useState("");
  const getTemplateList = async (
    page_no: number,
    page_size: number,
    search_text: string
  ) => {
    // if (getValue(templateList, `length`, 0) > 0) {
    //   return;
    // }
    try {
      setTemplatePageNo(page_no);
      setIsLoading1(true);
      let payload = {
        status: "APPROVED",
        page_no: page_no ? page_no : templatePageNo,
        page_size: 50,
        search: search_text,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getWhatsappTemplate(queryRequest);
      if (resp) {
        setTemplateList(
          getValue(resp, `data.templates`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `id`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
        setTemplateTotalCount(getValue(resp, `data.total`, 0));
        setIsLoading1(false);
      } else {
        setIsLoading1(false);
      }
    } catch (error) {
      setIsLoading1(false);
    }
  };

  const handleTemplatePagination = (page_no: number, page_size: number) => {
    setTemplatePageNo(page_no);
    // setTemplatePageSize(page_size ? page_size : templatePageSize);
    getTemplateList(
      page_no ? page_no : templatePageNo,
      page_size ? page_size : templatePageSize,
      templateSearchText
    );
  };

  const handleTemplateSearch = (search_text: string) => {
    getTemplateList(1, templatePageSize, search_text);
    setTemplateSearchText(search_text);
  };

  const updateConversation = () => {
    getData(false, 1, "", conversationStatus);
  };
  /* -------------------------------------------------------------------------- */
  /*                              broadcast section                             */
  /* -------------------------------------------------------------------------- */
  const [showBroadcast, setShowBroadcast] = useState(false);
  const handleBroadcastEditorButton = () => {
    setShowBroadcast(!showBroadcast);
  };
  const [selecteWaAccount, setSelectedWaAccount] = useState("");
  /* -------------------------------------------------------------------------- */
  /*                              Tabs section                                  */
  /* -------------------------------------------------------------------------- */

  const [filterDrawer, setFilterDrawer] = useState(false);
  // const [selectedTab, setSelectedTab] = useState({
  //   label: "My Open Chats",
  //   value: "my_open_chats",
  // });
  const handleTabChange = (tab: string, label: string) => {
    // setSelectedTab({
    //   label: label,
    //   value: tab,
    // });
    setFilterDrawer(true);
  };
  const toggleFilterDrawer = () => {
    setFilterDrawer(!filterDrawer);
    getAllCount();
  };

  /* -------------------------------------------------------------------------- */
  /*                                Bookmark Section                            */
  /* -------------------------------------------------------------------------- */

  const [isBookmarkOpen, setIsBookmarkOpen] = useState(false);

  const handleDefaultConversationType = async (value: string) => {
    try {
      let resp = await updateOrgUsers({ inbox_view: value });
      if (resp) {
        setConversationStatus(getValue(resp, `data.inbox_view`, ""));
        getUserIDCallback();
        setIsBookmarkOpen(false);
        toast.success("Default conversation type updated successfully");
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                                  Bulk Update Section                       */
  /* -------------------------------------------------------------------------- */

  const [isBulkUpdateOpen, setIsBulkUpdateOpen] = useState(false);
  const [selectedInboxIds, setSelectedInboxIds] = useState([]);
  const [openBulkUpdateModal, setOpenBulkUpdateModal] = useState(false);
  const [bulkUpdateRequest, setBulkUpdateRequest] = useState({});
  const [bulkUpdateSelectedField, setBulkUpdateSelectedField] = useState("");
  const [bulkUpdateSelectedValue, setBulkUpdateSelectedValue] = useState("");

  const toggleBulkUpdateView = () => {
    setIsBulkUpdateOpen(!isBulkUpdateOpen);
  };

  const handleCancelBulkSelection = () => {
    setIsBulkUpdateOpen(false);
    setSelectedInboxIds([]);
  };

  const toggleOpenBulkUpdateModal = () => {
    setOpenBulkUpdateModal(!openBulkUpdateModal);
  };

  const handleCloseBulkUpdateModal = () => {
    setOpenBulkUpdateModal(false);
    setIsBulkUpdateOpen(false);
    setSelectedInboxIds([]);
    setBulkUpdateRequest({});
    setBulkUpdateSelectedField("");
    setBulkUpdateSelectedValue("");
  };

  /* ---------------------------  OrgUsers section ---------------------------- */
  useEffect(() => {
    getActiveUsersData();
    getChatbotData();
  }, []);

  /* ---------------------------  Org Users section ---------------------------- */

  const [orgUsers, setOrgUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const getActiveUsersData = async () => {
    if (getValue(orgUsers, `length`, 0) === 0) {
      let payload = {
        page_size: 100,
        page_no: 1,
      };
      let queryRequest = QueryRequestHelper(payload);
      try {
        setLoading(true);
        let resp = await getOrganisationUsers(queryRequest);
        if (resp) {
          setOrgUsers(
            getValue(resp, `data.org_users.length`, 0) > 0 &&
              getValue(resp, `data.org_users`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `id`, ""),
                name:
                  getValue(item, `first_name`, "") +
                  " " +
                  getValue(item, `last_name`, ""),
                label:
                  getValue(item, `first_name`, "") +
                  " " +
                  getValue(item, `last_name`, ""),
              }))
          );
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  /* ---------------------------  ChatbotList section ---------------------------- */

  const [chatbotList, setChatbotList] = useState([]);
  const [AiAgents, setAiAgents] = useState([]);
  const [webChatbotList, setWebChatbotList] = useState([]);
  const [isChatbotLoading, setIsChatbotLoading] = useState(true);
  const getChatbotData = async () => {
    try {
      setIsChatbotLoading(true);
      let payload = {
        page_size: 100,
        page_no: 1,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllChatbots(queryRequest);
      if (resp) {
        const list1 =
          getValue(resp, `data.chatbots.length`, 0) > 0
            ? getValue(resp, `data.chatbots`, []).filter(
                (item: object) => getValue(item, `platform`, "") === "WHATSAPP"
              )
            : [];

        const list2 =
          getValue(resp, `data.chatbots.length`, 0) > 0
            ? getValue(resp, `data.chatbots`, []).filter(
                (item: object) => getValue(item, `type`, "") === "ai-chatbot"
              )
            : [];
        const list3 =
          getValue(resp, `data.chatbots.length`, 0) > 0
            ? getValue(resp, `data.chatbots`, []).filter(
                (item: object) => getValue(item, `type`, "") === "webchat"
              )
            : [];
        setAiAgents(
          list2.map((item: object) => ({
            ...item,
            label: getValue(item, `name`, ""),
            value: getValue(item, `id`, ""),
          }))
        );
        setChatbotList(
          list1.map((item: object) => ({
            ...item,
            label: getValue(item, `name`, ""),
            value: getValue(item, `id`, ""),
          }))
        );
        setWebChatbotList(
          list3.map((item: object) => ({
            ...item,
            label: getValue(item, `name`, ""),
            value: getValue(item, `id`, ""),
          }))
        );
        setIsChatbotLoading(false);
      } else {
        setIsChatbotLoading(false);
      }
    } catch (error) {
      setIsChatbotLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Media Library Section                        */
  /* -------------------------------------------------------------------------- */
  const [isMediaLibraryOpen, setIsMediaLibraryOpen] = useState(false);
  const [mediaLibraryType, setMediaLibraryType] = useState("image");
  const [mediaLibraryList, setMediaLibraryList] = useState([]);
  const [mediaLibraryTotalCount, setMediaLibraryTotalCount] = useState(0);
  const [mediaLibraryLimit, setMediaLibraryLimit] = useState(10);
  const [mediaLibraryPage_no, setMediaLibraryPage_no] = useState(1);
  const [isMediaLibraryLoading, setIsMediaLibraryLoading] = useState(false);
  const [selectedMediaLibraryFilesIds, setSelectedMediaLibraryFilesIds] =
    useState([]);

  const handleMediaLibraryButton = () => {
    setIsMediaLibraryOpen(!isMediaLibraryOpen);
    handleGetMediaLibrary();
  };

  const handleSelectMediaLibrary = () => {
    setIsMediaLibraryOpen(!isMediaLibraryOpen);
    setDocExpanded(true);
  };

  const handleCancelMediaLibrary = () => {
    setIsMediaLibraryOpen(!isMediaLibraryOpen);
  };

  useEffect(() => {
    // if (selectedConversationInfo) {
    if (
      getValue(UrlParams, `searchText`, "") === "" ||
      getValue(mediaLibraryList, `length`, 0) === 0
    ) {
      handleGetMediaLibrary();
    }
    // }
  }, [
    mediaLibraryType,
    mediaLibraryPage_no,
    mediaLibraryLimit,
    // selectedConversationInfo,
  ]);

  const handleGetMediaLibrary = async () => {
    try {
      setIsMediaLibraryLoading(true);
      const payload = {
        page_size: mediaLibraryLimit,
        page_no: mediaLibraryPage_no,
        type: "files",
        file_type: mediaLibraryType,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getAllMediaLibrary(queryRequest);
      if (resp) {
        setMediaLibraryList(getValue(resp, "data.resources", []));
        setMediaLibraryPage_no(getValue(resp, "data.pagination.page", 0));
        setMediaLibraryTotalCount(getValue(resp, "data.pagination.total", 0));
      }
    } catch (error) {
      // toast.error("Failed to fetch Media Library");
    } finally {
      setIsMediaLibraryLoading(false);
    }
  };

  const handleChangeMediaLibraryPagination = (page_no: any, limit: any) => {
    setMediaLibraryPage_no(page_no);
    setMediaLibraryLimit(limit);

    // let payload = {
    //   ...UrlParams,
    //   page_no: mediaLibraryPage_no,
    //   page_size: mediaLibraryLimit,
    //   // tab: activeTab,
    // };
    // let queryRequest = QueryRequestHelper(payload);
    // navigate(`${window.location.pathname}?${queryRequest}`);
    setMediaLibraryPage_no(page_no);
  };

  // const handleSendMediaLibraryMessages = async () => {
  //   setIsSendLoading(true);
  //   try {
  //     await Promise.all(
  //       selectedMediaLibraryFiles.map((item: object, index: number) => {
  //         return triggerAPI(
  //           {
  //             type: findType(getValue(item, `file_type`, "")),
  //             text: getValue(item, `name`, ""),
  //             file_id: getValue(item, `file_id`, ""),
  //             source: getValue(selectedConversationInfo, `source`, ""),
  //             inbox_id: getValue(selectedConversationInfo, `id`, ""),
  //             web_channel: getValue(
  //               selectedConversationInfo,
  //               `web_channel`,
  //               ""
  //             ),
  //           },
  //           index
  //         );
  //       })
  //     );
  //     setSelectedMediaLibraryFiles([]);
  //     setSelectedMediaLibraryFilesIds([]);
  //     setIsMediaLibraryOpen(!isMediaLibraryOpen);
  //   } catch (error) {
  //   } finally {
  //     setIsSendLoading(false);
  //   }
  // };

  useEffect(() => {
    handleGetFields();
  }, []);

  const [defaultFields, setDefaultFields] = useState({});
  const handleGetFields = async () => {
    try {
      const payload = {
        module_name: "deals",
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getDefaultModuleColumns(queryRequest);
      if (resp) {
        // setDefaultFields(getValue(resp, "data.module_fields", []));
        const mappedObject = getValue(resp, "data.module_fields", []).reduce(
          (acc: any, item: any) => {
            acc[item.api_name] = item.label;
            return acc;
          },
          {}
        );
        setDefaultFields(mappedObject);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (documentRequest.length === 0) {
      setDocExpanded(false);
    }
  }, [documentRequest]);

  return (
    <SidebarLayout {...props} isLoading={isLoading}>
      <>
        <div className="chatbox-wrapper">
          <div className="chatbox-container">
            <div className="chatbox-left-container">
              <div className="contact-search-wrapper">
                <ChatSearchHeader
                  handleChangeSearch={handleChangeSearch}
                  clearSearch={clearSearch}
                  toggleFilterView={toggleFilterView}
                  filterCount={filterCount}
                  handleApplyUnReadFilter={handleApplyUnReadFilter}
                  filterRequest={filterRequest}
                  searchText={query}
                  selectedTab={
                    getValue(UrlParams, "convStatus", "") || conversationStatus
                  }
                  toggleFilterDrawer={toggleFilterDrawer}
                  refreshBookmarks={refreshBookmarks}
                  bookmarkedModules={bookmarkedModules}
                  themeColor={themeColor}
                  bookmarkedModuleName="live chat"
                  bookmarkedModulePath="live-chat"
                  rawStatusCounts={rawStatusCounts}
                  defaultConversationType={conversationStatus}
                  isBulkUpdateOpen={isBulkUpdateOpen}
                  toggleBulkUpdateView={toggleBulkUpdateView}
                  handleCancelBulkSelection={handleCancelBulkSelection}
                  permissions={getValue(props, `permissions`, [])}
                />
                {/* {!getValue(UrlParams, `searchText`, "") && (
                  <ChatFilter
                    handleClickFilter={handleClickFilter}
                    UrlParams={UrlParams}
                    userId={userId}
                    unreadCount={unreadCount}
                    unassignedCount={unAssigned}
                    assignedCount={assigned}
                    assignedMe={assignedMe}
                    resolvedCount={resolved}
                    botChatCount={assignedChatbot}
                    permissions={["global_access"]}
                  />
                )} */}
              </div>
              {!getValue(UrlParams, `searchText`, "") ? (
                <InboxContacts
                  isLoading={isLoading}
                  conversations={conversations}
                  selectedConversation={selectedConversation}
                  handleSelectConvesation={handleSelectConvesation}
                  handleRemovePipelineInfo={handleRemovePipelineInfo}
                  query={query}
                  isBulkUpdateOpen={isBulkUpdateOpen}
                  selectedInboxIds={selectedInboxIds}
                  setSelectedInboxIds={setSelectedInboxIds}
                  toggleOpenBulkUpdateModal={toggleOpenBulkUpdateModal}
                  handleCancelBulkSelection={handleCancelBulkSelection}
                />
              ) : (
                <InboxSearchContacts
                  conversations={searchConversations}
                  selectedConversation={selectedConversation}
                  handleSelectConvesation={handleSelectConvesation}
                  handleRemovePipelineInfo={handleRemovePipelineInfo}
                  isBulkUpdateOpen={isBulkUpdateOpen}
                  selectedInboxIds={selectedInboxIds}
                  setSelectedInboxIds={setSelectedInboxIds}
                  toggleOpenBulkUpdateModal={toggleOpenBulkUpdateModal}
                  handleCancelBulkSelection={handleCancelBulkSelection}
                />
              )}
              {/* {totalCount > 100 && ( */}
              <Pagination
                totalCount={totalCount}
                limit={page_size}
                page_no={page_no}
                handleChangePagination={handleChangePagination}
                showLimit={"false"}
                // handleChangeLimit={handleChangeLimit}
              />
              {/* )} */}
            </div>

            <div className="chatbox-center-container">
              {selectedConversation ? (
                <Row className="conversation-wrapper">
                  <Col flex={expanded ? "1 1 60%" : "1 1 100%"}>
                    <div className="conversation-section">
                      <ChatRightHeader
                        handleToggleWidth={handleToggleWidth}
                        selectedContact={selectedContact}
                        selectedConversationInfo={selectedConversationInfo}
                        getData={getData}
                        handleClickFilter={handleClickFilter}
                        setSelectedContact={setSelectedContact}
                        setSelectedConversationInfo={
                          setSelectedConversationInfo
                        }
                        setSelectedConversation={setSelectedConversation}
                        removeDependentState={removeDependentState}
                        expanded={expanded}
                        stageList={stageList}
                        permissions={getValue(props, `permissions`, [])}
                      />
                      <div className="message-section">
                        {conversationLoading ? (
                          <Loader small />
                        ) : (
                          <ConversationScroll
                            conversation={conversation}
                            fetchMoreMessages={fetchMoreMessages}
                            m_total_count={m_total_count}
                            conversationLoading={conversationLoading}
                            setSelectedReplyMessage={setSelectedReplyMessage}
                            handleAddToDocuments={handleAddToDocuments}
                            selectedContact={selectedContact}
                            contactInfo={contactInfo}
                            handleAddContact={handleAddContact}
                            isLeadShow={isLeadShow}
                            selectedModule={selectedModule}
                            messagesEndRef={messagesEndRef}
                            broadcastEnable={customer_last_msg_24hours}
                            showDownButton={showDownButton}
                            scrollToBottom={scrollToBottom}
                            divRef={divRef}
                            unreadCountForScroll={unreadCountForScroll}
                            scrollCount={scrollCount}
                            handleUploadDocument={handleUploadDragDrop}
                            uploadLoading={uploadLoading}
                          />
                        )}
                        <ConversationReply
                          selectedReplyMessage={selectedReplyMessage}
                          maxLength={maxLength}
                          setSelectedReplyMessage={setSelectedReplyMessage}
                        />
                        {(getValue(selectedConversationInfo, `source`, "") ===
                          "whatsapp" &&
                          getValue(
                            selectedConversationInfo,
                            `lead.message_opt_out`,
                            false
                          )) ||
                        getValue(
                          selectedConversationInfo,
                          `contact.message_opt_out`,
                          false
                        ) ? (
                          <div className="ant-inbox-tab">
                            <p className="text-center py-3">
                              This user has opted out of whatsapp message
                            </p>
                          </div>
                        ) : customer_last_msg_24hours ? (
                          <div className="text-center w-100 ant-inbox-tab py-3">
                            <p className="pb-2">
                              Let's resume our conversation! Send a broadcast to
                              keep the chat going with the user.
                            </p>
                            <div className="d-flex justify-content-center">
                              <Button onClick={handleBroadcastEditorButton}>
                                Send Broadcast
                              </Button>
                            </div>
                          </div>
                        ) : getValue(
                            selectedConversationInfo,
                            `conversation_status`,
                            ""
                          ) === "closed" ? (
                          <div className="ant-inbox-tab">
                            <p className="text-center py-3">
                              This chat has been resolved, so you can only send
                              a message if the customer initiates a new chat
                            </p>
                          </div>
                        ) : (
                          <ChatFooter
                            showEmojiPicker={showEmojiPicker}
                            handleEmojiClick={handleEmojiClick}
                            toggleEmojiPicker={toggleEmojiPicker}
                            emojiPickerRef={emojiPickerRef}
                            message={message}
                            setMessage={setMessage}
                            privateMessage={privateMessage}
                            setPrivateMessage={setPrivateMessage}
                            handlePrivateEmojiClick={handlePrivateEmojiClick}
                            reference={ref}
                            parentChage={parentChage}
                            setParentChage={setParentChage}
                            handleSendMessages={handleSendMessages}
                            isMessageLoading={isMessageLoading}
                            handleUploadDocument={handleUploadDocument}
                            selectedConversationInfo={selectedConversationInfo}
                            handleChangeEditor={handleChangeEditor}
                            handleChangePrivateEditor={
                              handleChangePrivateEditor
                            }
                            handleSendPrivateMessages={
                              handleSendPrivateMessages
                            }
                            isPrivateMessageLoading={isPrivateMessageLoading}
                            //Canned response
                            openCannedResponse={openCannedResponse}
                            setOpenCannedResponse={setOpenCannedResponse}
                            handleCannedOpen={handleCannedOpen}
                            handleCannedEditorButton={handleCannedEditorButton}
                            cannedPopupPosition={cannedPopupPosition}
                            contentLength={getValue(message, "length", "")}
                            privateContentLength={getValue(
                              privateMessage,
                              "length",
                              ""
                            )}
                            triggerCannedResponseList={
                              triggerCannedResponseList
                            }
                            setTriggerCannedResponseList={
                              setTriggerCannedResponseList
                            }
                            handleBroadcastEditorButton={
                              handleBroadcastEditorButton
                            }
                            setCannedRespFileId={setCannedRespFileId}
                            setCannedRespFileType={setCannedRespFileType}
                            //media library
                            handleMediaLibraryButton={handleMediaLibraryButton}
                            themeColor={themeColor}
                            uploadLoading={uploadLoading}
                          />
                        )}
                      </div>
                      <div
                        className={`document-review-section  ${
                          docExpanded ? "expanded" : ""
                        }`}
                      >
                        <div className={`${docExpanded ? "show" : "hidden"}`}>
                          {documentRequest.length > 0 && (
                            <ChatDocumentPreview
                              showEmojiPicker={showEmojiPicker}
                              handleEmojiClick={handleEmojiClick}
                              toggleEmojiPicker={toggleEmojiPicker}
                              message={message}
                              setMessage={setMessage}
                              reference={ref}
                              handleSendMessages={handleSendMessages}
                              setDocExpanded={setDocExpanded}
                              docExpanded={docExpanded}
                              handleUploadDocument={handleUploadDocument}
                              documentRequest={documentRequest}
                              setDocumentRequest={setDocumentRequest}
                              handleSendDocumentMessages={
                                handleSendDocumentMessages
                              }
                              isSendLoading={isSendLoading}
                              themeColor={themeColor}
                            />
                          )}
                          {mediaLibraryDocumentRequest.length > 0 && (
                            <ChatLibraryDocumentPreview
                              showEmojiPicker={showEmojiPicker}
                              handleEmojiClick={handleEmojiClick}
                              toggleEmojiPicker={toggleEmojiPicker}
                              message={message}
                              setMessage={setMessage}
                              reference={ref}
                              handleSendMessages={handleSendMessages}
                              setDocExpanded={setDocExpanded}
                              docExpanded={docExpanded}
                              handleUploadDocument={handleUploadDocument}
                              documentRequest={mediaLibraryDocumentRequest}
                              setDocumentRequest={
                                setMediaLibraryDocumentRequest
                              }
                              handleSendDocumentMessages={
                                handleSendLibraryDocumentMessages
                              }
                              isSendLoading={isSendLoading}
                              setSelectedMediaLibraryFilesIds={
                                setSelectedMediaLibraryFilesIds
                              }
                              themeColor={themeColor}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <AnimatePresence>
                    {expanded && (
                      <MotionCol
                        flex="1 1 40%"
                        initial={{ width: 0, opacity: 0 }}
                        animate={{
                          width: "30%",
                          opacity: 1,
                          transition: {
                            width: { duration: 0.3, ease: "easeInOut" },
                            opacity: { duration: 0.3, delay: 0.1 },
                          },
                        }}
                        exit={{
                          width: 0,
                          opacity: 0,
                          transition: {
                            width: { duration: 0.3, ease: "easeInOut" },
                            opacity: { duration: 0.2 },
                          },
                        }}
                      >
                        <motion.div
                          className="contact-info-sidebar"
                          initial={{ x: 50 }}
                          animate={{ x: 0 }}
                          exit={{ x: 50 }}
                          transition={{ duration: 0.3, ease: "easeOut" }}
                        >
                          <Col flex="1 1 30%">
                            <div className="contact-info-sidebar">
                              {!getValue(contactInfo, `id`, "") && (
                                <div className="d-flex align-items-center justify-content-between px-4 py-3">
                                  <div className="d-flex align-items-center gap-2">
                                    {isOpenDrawer ? (
                                      <CloseMainSvgComponent />
                                    ) : (
                                      <BackButton
                                        handleBack={() =>
                                          isOpenDrawer
                                            ? handleToggleDrawer()
                                            : handleToggleWidth()
                                        }
                                      />
                                    )}
                                    <p>
                                      {getValue(selectedDrawerInfo, `name`, "")}
                                    </p>
                                  </div>
                                  <div>
                                    <h6 className="d-flex align-items-center gap-2 header_text__16 ms-3">
                                      {getValue(contactInfo, `id`, "")
                                        ? "Contact info"
                                        : "Add Contact"}
                                      {getValue(contactInfo, `id`, "") && (
                                        <a
                                          href={`/${getValue(
                                            params,
                                            "orgId",
                                            ""
                                          )}/crm/${selectedModule}/detail/${getValue(
                                            contactInfo,
                                            `id`,
                                            ""
                                          )}`}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <SquareArrowOutUpRight size={16} />
                                        </a>
                                      )}
                                    </h6>
                                  </div>
                                </div>
                              )}
                              <ChatContactInfo
                                handleToggleWidth={handleToggleWidth}
                                contactInfo={contactInfo}
                                moduleDetails={moduleDetails}
                                columnList={columnList}
                                contactFields={contactFields}
                                dirtyFields={dirtyFields}
                                setConatctFields={setConatctFields}
                                columnLabelInfo={columnLabelInfo}
                                allPipelines={options}
                                stages={stages}
                                fields={formFields}
                                selectedConversation={getValue(
                                  selectedConversationInfo,
                                  `id`,
                                  ""
                                )}
                                setFields={setFormFields}
                                getFormFields={getFormFields}
                                isFormLoading={isFormLoading}
                                staticFieldRequest={staticFieldRequest}
                                setStaticFieldRequest={setStaticFieldRequest}
                                selectedContact={selectedContact}
                                userDetails={userDetails}
                                toggleUserDetails={toggleUserDetails}
                                setSelectedType={setSelectedType}
                                selectedType={selectedType}
                                selectedModule={selectedModule}
                                getSpecificLeadInfo={getSpecificLeadInfo}
                                getSpecificContactInfo={getSpecificContactInfo}
                                isLeadShow={isLeadShow}
                                permissions={getValue(props, `permissions`, [])}
                                selectedConversationInfo={
                                  selectedConversationInfo
                                }
                                isOpenDrawer={isOpenDrawer}
                                setIsOpenDrawer={setIsOpenDrawer}
                                selectedDrawerInfo={selectedDrawerInfo}
                                setSelectedDrawerInfo={setSelectedDrawerInfo}
                                updateConversation={updateConversation}
                                stageList={stageList}
                                getParentData={getData}
                                stageRequest={stageRequest}
                                setStageRequest={setStageRequest}
                                contactInfoLoading={contactInfoLoading}
                                defaultFields={defaultFields}
                              />
                            </div>
                          </Col>
                        </motion.div>
                      </MotionCol>
                    )}
                  </AnimatePresence>
                </Row>
              ) : (
                <div className="no-conversation">
                  <img
                    src="/nodata/messages.svg"
                    className="whatsapp_no"
                    alt=""
                  />
                  <h4 className="mt-3">No Conversation Selected</h4>
                  <p>Select a conversation to read</p>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* {uploadLoading && (
          <FloatButton
            shape="circle"
            type="default"
            style={{ insetInlineEnd: 24 }}
            icon={<Spin />}
          />
        )} */}
      </>
      <WaAdvancefilter
        isOpenFilterView={isOpenFilterView}
        toggleFilterView={toggleFilterView}
        allConnectionList={allConnectionList}
        filterRequest={filterRequest}
        filterDirtyRequest={filterDirtyRequest}
        setFilterRequest={setFilterRequest}
        setFilterDirtyRequest={setFilterDirtyRequest}
        handleResetFilter={handleResetFilter}
        handleApplyFilter={handleApplyFilter}
        UrlParams={UrlParams}
        chatbotList={chatbotList}
        AiAgents={AiAgents}
        webChatbotList={webChatbotList}
      />
      <ChatbotFooterBroadcastModel
        customer_last_msg_24hours={customer_last_msg_24hours}
        templateList={templateList}
        selectedConversationInfo={selectedConversationInfo}
        getSpecificConversation={getSpecificConversation}
        selectedConversation={selectedConversation}
        allConnectionList={allConnectionList}
        isOpen={showBroadcast}
        handleModel={handleBroadcastEditorButton}
        selecteWaAccount={selecteWaAccount}
        setSelectedWaAccount={setSelectedWaAccount}
        handleTemplatePagination={handleTemplatePagination}
        handleTemplateSearch={handleTemplateSearch}
        templatePageNo={templatePageNo}
        templatePageSize={templatePageSize}
        templateTotalCount={templateTotalCount}
        templateLoading={isLoading1}
        templateSearchText={templateSearchText}
      />
      <ConversationTypeFilter
        open={filterDrawer}
        onClose={() => {
          setFilterDrawer(false);
          setSelectedInboxIds([]);
          setBulkUpdateRequest({});
          setBulkUpdateSelectedField("");
          setBulkUpdateSelectedValue("");
          setIsBulkUpdateOpen(false);
          if (isBookmarkOpen) {
            setIsBookmarkOpen(false);
          }
        }}
        chatStatuses={chatFilters}
        params={params}
        navigate={navigate}
        permissions={getValue(props, `permissions`, [])}
        selectedTab={
          getValue(UrlParams, "convStatus", "") || conversationStatus
        }
        handleClickFilter={handleClickFilter}
        rawStatusCounts={rawStatusCounts}
        userId={userId}
        defaultConversationType={conversationStatus}
        handleDefaultConversationType={handleDefaultConversationType}
        themeColor={themeColor}
        isBookmarkOpen={isBookmarkOpen}
        setIsBookmarkOpen={setIsBookmarkOpen}
      />
      <BulkUpdateModal
        openBulkUpdateModal={openBulkUpdateModal}
        handleCancel={handleCloseBulkUpdateModal}
        bulkUpdateRequest={bulkUpdateRequest}
        setBulkUpdateRequest={setBulkUpdateRequest}
        bulkUpdateSelectedField={bulkUpdateSelectedField}
        setBulkUpdateSelectedField={setBulkUpdateSelectedField}
        bulkUpdateSelectedValue={bulkUpdateSelectedValue}
        setBulkUpdateSelectedValue={setBulkUpdateSelectedValue}
        selectedInboxIds={selectedInboxIds}
        getData={getData}
        query={query}
        page_no={page_no}
        conversationStatus={conversationStatus}
        handleCloseBulkUpdateModal={handleCloseBulkUpdateModal}
        getAllCount={getAllCount}
        clearSearch={clearSearch}
        UrlParams={UrlParams}
        chatbotList={chatbotList}
        AiAgents={AiAgents}
        orgUsers={orgUsers}
      />
      <ChatMediaLibraryModal
        isModalOpen={isMediaLibraryOpen}
        handleOk={handleSelectMediaLibrary}
        handleCancel={handleCancelMediaLibrary}
        mediaLibraryType={mediaLibraryType}
        setMediaLibraryType={setMediaLibraryType}
        mediaLibraryList={mediaLibraryList}
        mediaLibraryTotalCount={mediaLibraryTotalCount}
        mediaLibraryLimit={mediaLibraryLimit}
        mediaLibraryPage_no={mediaLibraryPage_no}
        setMediaLibraryLimit={setMediaLibraryLimit}
        setMediaLibraryPage_no={setMediaLibraryPage_no}
        handleChangeMediaLibraryPagination={handleChangeMediaLibraryPagination}
        isLoading={isMediaLibraryLoading}
        selectedMediaLibraryFiles={mediaLibraryDocumentRequest}
        setSelectedMediaLibraryFiles={setMediaLibraryDocumentRequest}
        selectedMediaLibraryFilesIds={selectedMediaLibraryFilesIds}
        setSelectedMediaLibraryFilesIds={setSelectedMediaLibraryFilesIds}
      />
    </SidebarLayout>
  );
}

export default memo(LiveChat);
