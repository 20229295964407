import DialcodePhoneInputCommon from "@components/custom/Forms/DialCodeInputCommon";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Modal } from "antd";

const EditUserDetails = ({
  isOpen,
  handleCancel,
  request,
  setRequest,
  simpleEditValidator,
  handleEditUser,
  editLoading,
}: {
  isOpen: boolean;
  handleCancel: () => void;
  request: any;
  setRequest: (request: any) => void;
  simpleEditValidator: any;
  handleEditUser: () => void;
  editLoading: boolean;
}) => {
  return (
    <Modal
      open={isOpen}
      onCancel={handleCancel}
      title="Edit User"
      width={500}
      okText="Update"
      onOk={handleEditUser}
      confirmLoading={editLoading}
    >
      <div
        className="d-flex flex-column gap-2"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleEditUser();
          }
        }}
      >
        <InputRuleForm
          inputType="TEXT"
          label="First Name"
          name="first_name"
          value={getValue(request, `first_name`, "")}
          onChange={(e: any) =>
            setRequest({
              ...request,
              first_name: getValue(e, `target.value`, ""),
            })
          }
          required
          validator={simpleEditValidator}
        />
        <InputRuleForm
          inputType="TEXT"
          label="Last Name"
          name="last_name"
          value={getValue(request, `last_name`, "")}
          onChange={(e: any) =>
            setRequest({
              ...request,
              last_name: getValue(e, `target.value`, ""),
            })
          }
          required
          validator={simpleEditValidator}
        />

        <DialcodePhoneInputCommon
          placeholder="Enter phone number"
          value={getValue(request, `phone`, "")}
          onChange={(e: any) =>
            setRequest({
              ...request,
              phone: e,
            })
          }
          label={"Phone Number"}
          // mandatory
          // validator={simpleEditValidator}
        />
      </div>
    </Modal>
  );
};

export default EditUserDetails;
