import React, { useState, useEffect } from "react";
import ChatDashboard from "./Chat";
import { getValue } from "@utils/lodash";
import NewDashboard from "../NewDashboard";
import { useLocation } from "react-router-dom";

function Dashboard(props: any) {
  const [view, setView] = useState(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return getValue(Object.fromEntries(urlSearchParams.entries()), "view", "");
  });

  useEffect(() => {
    const handleUrlChange = () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const newView = getValue(
        Object.fromEntries(urlSearchParams.entries()),
        "view",
        ""
      );
      setView(newView);
    };

    window.addEventListener("popstate", handleUrlChange);
    return () => window.removeEventListener("popstate", handleUrlChange);
  }, []);

  // Function to change view programmatically

  const viewList = [
    {
      label: "CRM",
      value: "crm",
    },
    {
      label: "Chat",
      value: "chat",
    },
    // {
    //   label: "Helpdesk",
    //   value: "helpdesk",
    // },
  ];

  const location = useLocation();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  useEffect(() => {
    if (getValue(UrlParams, `view`, "")) {
      setView(getValue(UrlParams, `view`, ""));
    }
  }, [location.key]);
  return (
    <div>
      {view === "chat" ? (
        <ChatDashboard
          view={view}
          setView={setView}
          viewList={viewList}
          {...props}
        />
      ) : (
        <NewDashboard
          view={view}
          setView={setView}
          viewList={viewList}
          {...props}
        />
      )}
    </div>
  );
}

export default Dashboard;
