import SidebarLayout from "@layouts/SidebarLayout/SidebarLayout";
import React, { useState, useEffect, useCallback } from "react";
import DashboardHeader from "../../CRM/components/header";
import { getAgentAvailabilityDashboard } from "@services/dashboard.service";
import { getChatAgentsDashboard } from "@services/dashboard.service";
import { getValue } from "@utils/lodash";
import {
  Card,
  Input,
  Table,
  Select,
  Avatar,
  Form,
  Tabs,
  Statistic,
  Row,
  Col,
} from "antd";
import {
  SearchOutlined,
  UserOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import "./index.scss";
import { getAllAgentStatus } from "@services/agent-status.service";
import { useStateContext } from "@context/dataContext";
import { QueryRequestHelper } from "@common/query-request-helper";
import { dateFilters } from "@common/Date-Helpers/date-filters-helpers";
import { updateUser } from "@services/users.service";
import { UserRoundCheck, UserRoundX } from "lucide-react";
import { concatString, formatString } from "@common/text-helpers";
import ProfileCircle from "@components/common/ProfileCircle";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;
const { Option } = Select;
const { Item: FormItem } = Form;

interface Agent {
  id: string;
  first_name: string;
  last_name: string;
  status: { id: string; name: string };
  Inbox: any[];
}

interface AgentAvailability {
  Available: number;
  Unavailable: number;
}

interface Status {
  id: string;
  name: string;
}

function DashboardAgentAvailability(props: any) {
  const navigate = useNavigate();
  const { view, setView, chatView, setChatView, viewList } = props;
  const {
    getAllAgentStatuseCallback,
    refreshBookmarks,
    bookmarkedModules,
    themeColor,
  } = useStateContext();
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [agentAvailabilityData, setAgentAvailabilityData] =
    useState<AgentAvailability>({ Available: 0, Unavailable: 0 });
  const [chatAgentsDashboardData, setChatAgentsDashboardData] = useState<
    Agent[]
  >([]);
  const [pagination, setPagination] = useState<any>({
    status_id: "",
    order: "asc" as const,
    search: "",
    page_no: 1,
    page_size: 50,
  });
  const [totalItems, setTotalItems] = useState(0);

  const [statusList, setStatusList] = useState([]);
  const getAllAgentStatuses = async () => {
    try {
      const payload = {
        status: "active",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllAgentStatus(queryRequest);
      if (resp) {
        setStatusList(getValue(resp, `data.agent_statuses`, []));
      }
    } catch (error) {}
  };

  // Memoize fetch functions to prevent unnecessary re-renders
  const fetchAgentAvailabilityData = useCallback(async () => {
    try {
      setDashboardLoading(true);
      const response = await getAgentAvailabilityDashboard("");
      setAgentAvailabilityData(
        getValue(response, "data", { Available: 0, Unavailable: 0 })
      );
    } catch (error) {
      console.error("Error fetching agent availability:", error);
    } finally {
      setDashboardLoading(false);
    }
  }, []);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const fetchChatAgentsDashboardData = useCallback(async () => {
    try {
      setDashboardLoading(true);
      const payload = {
        status_id: pagination.status_id,
        order: pagination.order,
        search: pagination.search,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : pagination.page_no,
        page_size: getValue(UrlParams, `limit`, "")
          ? getValue(UrlParams, `limit`, "")
          : pagination.page_size,
        filter_messaged_at: getValue(UrlParams, `date_range`, "")
          ? getValue(UrlParams, `date_range`, "")
          : getValue(dateFilters, `[${0}].value`, ""),
      };
      const queryRequest = QueryRequestHelper(payload);
      const response = await getChatAgentsDashboard(queryRequest);
      const agents = getValue(response, "data.agents", []);
      const total = getValue(response, "data.pagination.total", agents?.length);
      setChatAgentsDashboardData(agents);
      setTotalItems(total);
    } catch (error) {
      console.error("Error fetching chat agents:", error);
    } finally {
      setDashboardLoading(false);
    }
  }, [pagination]);

  useEffect(() => {
    getAllAgentStatuses();
    fetchAgentAvailabilityData();
    fetchChatAgentsDashboardData();
  }, [fetchAgentAvailabilityData, fetchChatAgentsDashboardData]);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPagination((prev: any) => ({
          ...prev,
          page_no: parseInt(UrlParams.page_no),
        }));
      }
      if (UrlParams.limit) {
        setPagination((prev: any) => ({
          ...prev,
          page_size: parseInt(UrlParams.limit),
        }));
      }
      fetchChatAgentsDashboardData();
    }
  }, [window.location.href]);

  const handlePaginationChange = (page: number, pageSize?: number) => {
    setPagination((prev: any) => ({
      ...prev,
      page_no: page,
      page_size: pageSize || prev.page_size,
    }));
    let payload = {
      ...UrlParams,
      page_no: page,
      page_size: pageSize,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleOrderChange = (value: "asc" | "desc") => {
    setPagination((prev: any) => ({ ...prev, order: value, page_no: 1 }));
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPagination((prev: any) => ({
      ...prev,
      search: e.target.value,
      page_no: 1,
    }));
  };

  const handleStatusIdChange = (value: string) => {
    setPagination((prev: any) => ({ ...prev, status_id: value, page_no: 1 }));
  };

  const handleStatusUpdate = async (agentId: string, newStatusId: string) => {
    try {
      let resp = await updateUser(agentId, { status_id: newStatusId });
      if (resp) {
        fetchChatAgentsDashboardData();
        getAllAgentStatuseCallback();
        fetchAgentAvailabilityData();
      }
    } catch (error) {}
  };

  const agentData = chatAgentsDashboardData.map((agent: Agent) => ({
    ...agent,
    key: agent.id,
    name: `${agent.first_name} ${agent.last_name}`,
    status: agent.status?.name || "Unknown",
    statusId: agent.status?.id || "",
    onChat: agent?.Inbox?.length > 0 ? "Yes" : "No",
  }));
  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      render: (text: string) => (
        <div className="agent-name">
          <ProfileCircle value={text} />
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      width: "40%",
      render: (text: string, record: any) => {
        return (
          <FormItem initialValue={record.statusId} className="m-0">
            <Select
              style={{
                width: "240px",
              }}
              value={record.statusId}
              onChange={(value) => handleStatusUpdate(record.key, value)}
              className="status-dropdown"
            >
              {statusList.map((status: any) => (
                <Option key={status.id} value={status.id}>
                  <div className="d-flex align-items-center gap-2">
                    <span className="w-10">
                      {["Available", "Unavailable"].includes(status.name) ? (
                        status.name === "Available" ? (
                          <CheckCircleFilled
                            style={{ color: "#009A00" }}
                            size={16}
                          />
                        ) : (
                          <CloseCircleFilled
                            style={{ color: "red" }}
                            size={16}
                          />
                        )
                      ) : (
                        status.emoji
                      )}
                    </span>
                    <span className="status-item ms-1">{status.name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </FormItem>
        );
      },
    },
    // {
    //   title: "ON CHAT",
    //   dataIndex: "onChat",
    //   key: "onChat",
    //   width: "10%",
    // },
  ];
  return (
    <SidebarLayout {...props} isLoading={dashboardLoading}>
      <DashboardHeader
        hideCloseDate
        allModules={[]}
        setSelectedModule={() => {}}
        setSelectedPipeline={() => {}}
        setSelectedDateRange={() => {}}
        setSelectedFilter={() => {}}
        setSelectedView={() => {}}
        setSelectedUser={() => {}}
        showSourceFilter={true}
        view={view}
        setView={setView}
        setChatView={setChatView}
        viewList={viewList}
        refreshBookmarks={refreshBookmarks}
        bookmarkedModules={bookmarkedModules}
        themeColor={themeColor}
        bookmarkedModuleName="dashboard"
        bookmarkedModulePath="dashboard"
        {...props}
      />
      <div className="chat-agents-container">
        <h3 className="header_text__18 mb-2">Chat agents</h3>
        <Row gutter={16} className="mb-4">
          {statusList.map((status: any) =>
            Object.keys(agentAvailabilityData).map(
              (key) =>
                status.name === key && (
                  <Col span={4}>
                    <Card>
                      <Statistic
                        title={
                          <div className="d-flex align-items-center gap-2">
                            {status.name === "Available" ? (
                              <UserRoundCheck size={18} />
                            ) : status.name === "Unavailable" ? (
                              <UserRoundX size={18} />
                            ) : (
                              <span className="pe-1">{status.emoji}</span>
                            )}
                            {key}
                          </div>
                        }
                        value={
                          agentAvailabilityData[key as keyof AgentAvailability]
                        }
                        precision={0}
                        valueStyle={{
                          color:
                            key === "Available"
                              ? "#3f8600"
                              : key === "Unavailable"
                              ? "#cf1322"
                              : "#000",
                        }}
                      />
                    </Card>
                  </Col>
                )
            )
          )}
        </Row>

        <div className="agents-table-section">
          <div className="table-header">
            <Tabs defaultActiveKey="1" className="agents-tabs">
              <TabPane tab="Chat agents" key="1" />
            </Tabs>

            <div className="table-filters">
              <Input
                placeholder="Search"
                prefix={<SearchOutlined />}
                value={pagination.search}
                onChange={handleSearchChange}
                className="search-input"
              />

              <div className="filter-options">
                <div className="filter-group">
                  <span className="filter-label">Status:</span>
                  <Select
                    value={pagination.status_id}
                    onChange={handleStatusIdChange}
                    className="filter-select"
                  >
                    <Option value="">All</Option>
                    {statusList.map((status: any) => (
                      <Option key={status.id} value={status.id}>
                        {status.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="filter-group">
                  <span className="filter-label">Sort by:</span>
                  <Select
                    value={pagination.order}
                    onChange={handleOrderChange}
                    className="filter-select"
                  >
                    <Option value="asc">Ascending</Option>
                    <Option value="desc">Descending</Option>
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={agentData}
            pagination={{
              current: pagination.page_no,
              pageSize: pagination.page_size,
              total: totalItems,
              onChange: (page_no, pageSize) => {
                handlePaginationChange(page_no, pageSize);
              },
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 50, 100],
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
            className="agents-table"
          />
        </div>
      </div>
    </SidebarLayout>
  );
}

export default DashboardAgentAvailability;
