import "./index.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import React from "react";
import { getValue } from "@utils/lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { QueryRequestHelper } from "common/query-request-helper";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "sonner";
import {
  deleteTask,
  tasksBulkUpdate,
  tasksSheetUpdate,
  updateTask,
} from "@services/tasks.service";
import { useStateContext } from "@context/dataContext";
import { sortTaskFieldList } from "@components/Pages/Pipeline/helpers/create-pipeline-helper";
import CommonBulkEditPopup from "@components/Pages/Pipeline/EditPopup";
import CommonAddSubPipeline from "@components/Pages/Pipeline/AddSubPipeline";
import CommonAddFilterView from "@components/Pages/Pipeline/AddFilterViews";
import EditFieldsPopup from "@components/Pages/Pipeline/EditFieldsPopup";
import PaginationPage from "@components/common/Pagination/pagination";
import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import { handleNavigateURL } from "@common/url-navigate";
import {
  removeDuplicates,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import CustomTaskDataTable from "@components/custom/Datatable/tasks_table";
import { listAllModules } from "@services/modules.service";
import {
  getAllModuleFields,
  getModuleColumns,
} from "@services/module-fields.service";
import { commonCustomFilter } from "@services/smart-filter.service";
import ModulePipelineSubheader from "@components/Pages/Pipeline/common/subheader";
import Pagination from "@components/Pages/Pipeline/common/pagination";
import useDynamicTitle from "@context/useDynamicTitle";

import { Button } from "antd";
import { motion } from "framer-motion";
import CustomSheetTable from "@components/custom/SheetTable";
import SidebarLayout from "@layouts/SidebarLayout/SidebarLayout";
import { modulesBulkRemove } from "@services/common.service";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ITasksProps {}

const Tasks: React.FunctionComponent<ITasksProps> = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const {
    userId,
    allModules,
    selectedModuleId,
    refreshBookmarks,
    bookmarkedModules,
    themeColor,
  } = useStateContext();

  /* -------------------------------------------------------------------------- */
  /*                               Validation Section                           */
  /* -------------------------------------------------------------------------- */
  const simpleValidator1 = useRef(new SimpleReactValidator());
  const [, forceUpdate1] = useState(0);

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getAllModules();
    }
  }, [location.key]);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (getValue(UrlParams, `search`, "")) {
        setSearchInput(UrlParams.search);
        setShowSearchTextbox(true);
      }
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getAllModules();
    }
  }, [
    window.location.href,
    getValue(localStorage, `sf:tasks`, ""),
    getValue(localStorage, `s:tasks`, ""),
  ]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [moduleId, setModuleId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [orginalSelectedFields, setOrginalSelectedFields] = useState([]);
  const getAllModules = async () => {
    try {
      let resp = await listAllModules("");
      if (resp) {
        getTasks();
        let task_modules = getValue(resp, `data`, []).find(
          (item: object) => getValue(item, `api_name`, "") === "tasks"
        );
        setModuleId(getValue(task_modules, `id`, ""));
        let columnsList = await getModuleColumns(
          getValue(task_modules, `id`, "")
        );
        setHeaders(getValue(columnsList, `data`, []));
        setDuplicateHeaders(getValue(columnsList, `data`, []));
        let fields = await getAllModuleFields(getValue(task_modules, `id`, ""));
        if (fields) {
          let list = sortTaskFieldList(getValue(fields, `data`, []));
          for (const item of list) {
            if (getValue(item, `input_type`, "") !== "select_multiple") {
              if (
                typeof getValue(item, `default_value`, null) === "string" ||
                typeof getValue(item, `default_value`, null) === "boolean"
              ) {
                item.value = getValue(item, `default_value`, null);
              }
            } else {
              item.value = [getValue(item, `default_value`, null)];
            }
            if (item.api_name === "owner_id") {
              item.value = userId;
            }
          }
          const aDict = list.reduce((dict: any, item: any) => {
            dict[item.name] = item;
            return dict;
          }, {});
          const ReorderedList: any = [];
          // Add elements from b in the order they appear in a
          for (const name of getValue(fields, `data`, [])) {
            if (aDict[name]) {
              ReorderedList.push(aDict[name]);
            }
          }
          // Add elements from a that are not in b to the end
          for (const item of list) {
            if (!getValue(fields, `data`, []).includes(item.name)) {
              ReorderedList.push(item);
            }
          }
          setSelectedFields(removeDuplicates(ReorderedList));
          setOrginalSelectedFields(removeDuplicates(ReorderedList));
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const [list, setList] = useState([]);
  const [sheetRequest, setSheetRequest] = useState({});
  const [listLoading, setListLoading] = useState(true);
  const getTasks = async () => {
    try {
      let payload = {
        module_id: await selectedModuleId("tasks"),
        filter_owner_id: getValue(UrlParams, `filter_owner_id`, ""),
        filter_pipeline_stage_id: getValue(
          UrlParams,
          `filter_pipeline_stage_id`,
          ""
        ),
        filter_closing_date: getValue(UrlParams, `filter_closing_date`, ""),
        filter_due_datetime: getValue(UrlParams, `filter_due_datetime`, ""),
        filter_created_at: getValue(UrlParams, `filter_created_at`, ""),
        page_size: getValue(UrlParams, `limit`, "")
          ? getValue(UrlParams, `limit`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
        search: getValue(UrlParams, `search`, ""),
        filter_is_completed: getValue(UrlParams, `filter_is_completed`, "")
          ? getValue(UrlParams, `filter_is_completed`, "") === "active"
            ? "true"
            : "false"
          : "false",
      };
      let queryRequest = QueryRequestHelper(payload);
      setListLoading(true);
      let obj = {
        has_advance_filter: getValue(localStorage, `sf:tasks`, "")
          ? true
          : false,
        has_group_by: false,
        filter_groups: getValue(localStorage, `sf:tasks`, "")
          ? JSON.parse(getValue(localStorage, `sf:tasks`, ""))
          : null,
        sort_by: getValue(localStorage, `s:tasks`, "")
          ? {
              ...JSON.parse(getValue(localStorage, `s:tasks`, "")),
              custom: false,
            }
          : {
              custom: false,
              field: "created_at",
              value: "desc",
            },
      };
      let resp = await commonCustomFilter(
        "tasks",
        queryRequest,
        removeNullOrUndefinedProperties(obj)
      );
      if (resp) {
        setList(getValue(resp, `data.data.tasks`, []));
        setSheetRequest(getValue(resp, `data.data.tasks`, []));
        setTotalCount(getValue(resp, `data.data.pagination.total`, 0));
        setListLoading(false);
      } else {
        setList([]);
        setListLoading(false);
      }
    } catch (error) {
      setList([]);
      setListLoading(false);
    }
  };

  const [selectedIds, setSelectedIds] = useState([]);

  /* -------------------------------------------------------------------------- */
  /*                     Other Functions Section                                */
  /* -------------------------------------------------------------------------- */

  const filterNavigationArray: any = [];
  const subMenuArray: any = [];

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    if (UrlParams.search) {
      delete UrlParams.search;
      let payload: any = { ...UrlParams };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      // getData();
    }
  };
  const handleChangeSearch = async (e: any) => {
    const regex = /^[a-zA-Z0-9@.]*$/;
    if (regex.test(e.target.value)) {
      setSearchInput(e.target.value);
      let payload: any = {
        ...UrlParams,
        search: e.target.value,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      // if (!e.target.value) {
      //   // getData();
      // }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    let payload = {
      ...UrlParams,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeLimit = (limit: string) => {
    let payload: any = {
      ...UrlParams,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleChangeFilter = () => {
    let payload;
    if (getValue(UrlParams, `filter_is_completed`, "")) {
      delete UrlParams.filter_is_completed;
    } else {
      payload = {
        ...UrlParams,
        filter_is_completed: false,
        page_no: "1",
      };
    }

    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  /* -------------------------------------------------------------------------- */
  /*                                  Edit section                              */
  /* -------------------------------------------------------------------------- */
  const [isOpenEditPopup, setIsOpenEditPopup] = useState(false);
  const openEditPopup = () => {
    setIsOpenEditPopup(!isOpenEditPopup);
  };
  const [selectedEditOption, setSelectedEditOption] = useState("");
  const [editRequest, setEditRequest] = useState<any>({
    stage: "",
    owner_id: "",
  });
  const [editLoading, setEditLoading] = useState(false);
  const handleOpenFilter = (value: string) => {
    setSelectedEditOption(value);
    openEditPopup();
  };
  const handleSubmitEdit = async () => {
    try {
      setEditLoading(true);
      let resp = await tasksBulkUpdate({
        ids: selectedIds,
        data: {
          [selectedEditOption]: editRequest[selectedEditOption],
        },
      });
      if (resp) {
        toast.success("Updated Succssfully");
        setSelectedIds([]);
        openEditPopup();
        getTasks();
        setSelectedEditOption("");
        setEditRequest({
          ...editRequest,
          stage: "",
          owner_id: "",
        });
        setEditLoading(false);
      } else {
        setEditLoading(false);
      }
    } catch (error) {
      setEditLoading(false);
    }
  };

  /* ----------------------------- Delete View Section  ------------------------- */
  const [deleteId1, setDeleteId1] = useState("");
  const [deleteTableValue, setDeleteTableValue] = useState("");
  const [isOpen1, setIsOpen1] = useState(false);

  const handleModal1 = () => {
    setIsOpen1(!isOpen1);
  };

  const handleDeleteTable = (id: string) => {
    setDeleteId1(id);
    handleModal1();
  };

  const handleDeleteTableRow = async () => {
    try {
      let resp = await deleteTask(deleteId1);
      if (resp) {
        toast.success("Deleted Successfully");
        //delete
        getTasks();
        setSelectedIds([]);
        handleModal1();
      }
    } catch (error) {}
  };

  const handleUpdateStatus = async (id: string, status: boolean) => {
    try {
      let resp = await updateTask(id, { is_completed: status });
      if (resp) {
        toast.success("Updated successfully");
        getTasks();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                       Create SubPipline Section                            */
  /* -------------------------------------------------------------------------- */

  const [isOpenSubPipeline, setIsOpenSubPipeline] = useState(false);
  const toggleOpenSubpipeline = () => {
    setIsOpenSubPipeline(!isOpenSubPipeline);
  };
  const [selectedFields, setSelectedFields] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [duplicateHeaders, setDuplicateHeaders] = useState([]);
  /* -------------------------------------------------------------------------- */
  /*                       Create Filterview Section                            */
  /* -------------------------------------------------------------------------- */
  const [isOpenFilterView, setIsOpenFilterView] = useState(false);
  const handleOpenFilterView = () => {
    setIsOpenFilterView(!isOpenFilterView);
  };
  const handleNaviagteView = (name: string, value: string) => {
    if (!value) {
      delete UrlParams[name];
      getAllModules();
    }
    navigate(handleNavigateURL(name, value));
  };

  /* -------------------------------------------------------------------------- */
  /*                       Create Filterview Section                            */
  /* -------------------------------------------------------------------------- */
  const [editFieldsOpen, setEditFieldsOpen] = useState(false);
  const handleEditFieldsModal = () => {
    setEditFieldsOpen(!editFieldsOpen);
  };
  /* -------------------------------------------------------------------------- */
  /*                  Hide Body Scroll When Modal Open                          */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (isOpenFilterView || isOpenSubPipeline) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpenFilterView, isOpenSubPipeline]);
  /* -------------------------------------------------------------------------- */
  /*                                 Module Info                                */
  /* -------------------------------------------------------------------------- */
  const findModuleType = (id: string) => {
    let info =
      getValue(allModules, `length`, 0) > 0
        ? allModules.find((item: object) => getValue(item, `id`, "") == id)
        : [];
    return info;
  };
  const moduleInfo = useMemo(
    () => findModuleType(moduleId),
    [allModules, moduleId]
  );
  const getAllPipelines = () => {};
  useDynamicTitle(
    getValue(moduleInfo, `linkText`, "")
      ? getValue(moduleInfo, `linkText`, "")
      : "Tasks"
  );

  /* -------------------------------------------------------------------------- */
  /*                                Sheet view                                  */
  /* -------------------------------------------------------------------------- */

  const [currentView, setCurrentView] = useState("tasks");
  const [patchRequest, setPatchRequest] = useState({ tasks: [] });

  const [sheetLoading, setSheetLoading] = useState(false);
  const handleSheetSubmit = async () => {
    try {
      setSheetLoading(true);
      const payload = {
        tasks: patchRequest.tasks,
      };
      let resp = await tasksSheetUpdate(payload);
      if (resp) {
        toast.success("Updated Successfully");
        setSheetLoading(false);
        getTasks();
        setPatchRequest({ tasks: [] });
      } else {
        setSheetLoading(false);
      }
    } catch (error) {
      setSheetLoading(false);
    }
  };

  const handleSheetCancel = () => {
    setPatchRequest({ tasks: [] });
  };

  const [bulkRemoveLoading, setBulkRemoveLoading] = useState(false);
  const [isOpenBulkDelete, setIsOpenBulkDelete] = useState(false);
  const handleOpenBulkDelete = () => {
    setIsOpenBulkDelete(true);
  };
  const handleBulkRemove = async () => {
    try {
      setBulkRemoveLoading(true);
      const payload = {
        ids: selectedIds,
      };
      let resp = await modulesBulkRemove(payload, "tasks");
      if (resp) {
        toast.success("Deleted successfully");
        setBulkRemoveLoading(false);
        getTasks();
        setIsOpenBulkDelete(false);
        setSelectedIds([]);
      } else {
        setBulkRemoveLoading(false);
      }
    } catch (error) {
      setBulkRemoveLoading(false);
    }
  };

  return (
    <SidebarLayout {...props} isLoading={listLoading}>
      <PipelineSubHeader
        subHeaderListTitle={getValue(moduleInfo, `linkText`, "")}
        isRightArrowVisible={true}
        isSubmenuListAvailable={true}
        subMenuArray={subMenuArray}
        hideClose={!getValue(UrlParams, `pipeline`, "") ? true : false}
        pipeline={false}
        refreshBookmarks={refreshBookmarks}
        bookmarkedModules={bookmarkedModules}
        themeColor={themeColor}
        showBack={false}
        moduleInfo={moduleInfo}
        bookmarkedModuleName={getValue(moduleInfo, `linkText`, "")}
        bookmarkedModulePath="tasks"
        key="crm"
      />
      <div className="d-flex crm-wrapper">
        <div className={`manageFieldRight w-100`}>
          <ModulePipelineSubheader
            //navviews section
            subHeaderListTitle={getValue(moduleInfo, `linkText`, "")}
            handleNaviagteView={handleNaviagteView}
            // filter section
            setIsOpenSubPipeline={setIsOpenSubPipeline}
            filterNavigationArray={filterNavigationArray}
            moduleInfo={moduleInfo}
            isFilterVisibile={true}
            isStatusVisible={true}
            //search section
            toggleSearch={toggleSearch}
            toggleSearchClose={toggleSearchClose}
            handleChangeSearch={handleChangeSearch}
            searchInput={search}
            showSearchTextbox={showSearchTextbox}
            //edit table section
            openEditPopup={openEditPopup}
            setSelectedIds={setSelectedIds}
            handleOpenFilter={handleOpenFilter}
            handleDeleteTable={handleDeleteTable}
            //others section
            getListData={getTasks}
            handleEditFieldsModal={handleEditFieldsModal}
            handleOpenFilterView={handleOpenFilterView}
            showAdvancedFilter={true}
            importLogs={true}
            hideCloseDate={true}
            module={"tasks"}
            selectedIds={selectedIds}
            callsRecording={true}
            plural_label={"Tasks"}
            //sort section
            isSort={false}
            fields={selectedFields}
            hideStage={true}
            isCreateDealVisible={true}
            //filter section
            handleChangeFilter={handleChangeFilter}
            showtaskFilter={true}
            getAllPipelines={getAllPipelines}
            permissions={getValue(props, `permissions`, [])}
            showDueDateFiter
            handleBulkRemove={handleOpenBulkDelete}
            bulkRemoveLoading={bulkRemoveLoading}
          />
          {!getValue(UrlParams, `sheet`, "") ? (
            <CustomTaskDataTable
              headers={headers}
              data={list}
              // setHeaders={setHeaders}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              stopRedirect
              isLoading={listLoading}
              module={"tasks"}
              handleUpdateStatus={handleUpdateStatus}
              add
              handleEditFieldsModal={handleEditFieldsModal}
              isSort={false}
              fields={selectedFields}
            />
          ) : (
            <CustomSheetTable
              headers={headers}
              data={list}
              setHeaders={setHeaders}
              // singular_label={getValue(selectedOption, `label`, "")}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              module="tasks"
              isLoading={listLoading}
              add
              sheetRequest={sheetRequest}
              setSheetRequest={setSheetRequest}
              patchRequest={patchRequest}
              setPatchRequest={setPatchRequest}
              fields={selectedFields}
              isOpenSubPipeline={isOpenSubPipeline}
            />
          )}
          <Pagination
            totalCount={totalCount}
            limit={limit}
            page_no={page_no}
            handleChangePagination={handleChangePagination}
            handleChangeLimit={handleChangeLimit}
            list={list}
          />

          {/* ------------------------- Edit Table Section --------------------------------- */}

          <CommonBulkEditPopup
            isOpen={isOpenEditPopup}
            handleModal={openEditPopup}
            request={editRequest}
            setRequest={setEditRequest}
            selectedEditOption={selectedEditOption}
            setSelectedEditOption={setSelectedEditOption}
            isLoading={editLoading}
            handleSubmit={handleSubmitEdit}
            permissions={getValue(props, `permissions`, [])}
          />
          <DeleteModal
            isOpen={isOpen1}
            handleModal={handleModal1}
            handleSubmit={handleDeleteTableRow}
            deleteValue={deleteTableValue}
          />

          {/* ------------------------- Creating Sub Pipelines ------------------------------ */}
          <CommonAddSubPipeline
            isOpen={isOpenSubPipeline}
            toggle={toggleOpenSubpipeline}
            singular_label={getValue(moduleInfo, `linkText`, "")}
            fields={selectedFields}
            setFields={setSelectedFields}
            simpleValidator={simpleValidator1}
            forceUpdate={forceUpdate1}
            module={"tasks"}
            width={"580px"}
            permissions={getValue(props, `permissions`, [])}
            getAllPipelines={getAllModules}
            orginalSelectedFields={orginalSelectedFields}
          />

          {/* ------------------------- Advanced Filter View ------------------------- */}
          {isOpenFilterView && (
            <CommonAddFilterView
              isOpenFilterView={isOpenFilterView}
              module={"tasks"}
              handleOpenFilterView={handleOpenFilterView}
              getData={getTasks}
              permissions={["create"]}
            />
          )}
          {/* ------------------------- Editing Table Fields ------------------------- */}
          <EditFieldsPopup
            isOpen={editFieldsOpen}
            toggle={handleEditFieldsModal}
            fields={selectedFields}
            setFields={setSelectedFields}
            headers={duplicateHeaders}
            setHeaders={setDuplicateHeaders}
            // getData={getData}
            moduleId={moduleId}
            module={"tasks"}
            permissions={getValue(props, `permissions`, [])}
          />
        </div>
      </div>
      {getValue(patchRequest, "tasks.length", "") !== 0 && (
        <motion.div
          initial={{ y: 0, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
          exit={{
            y: 50,
            opacity: 0,
            transition: { duration: 0.5, ease: "easeInOut" },
          }}
        >
          <div className={"sheet_footer_container"}>
            <div className={"button_container"}>
              <div className="d-flex align-items-center gap-2">
                <Button onClick={() => handleSheetCancel()}>Cancel</Button>

                {getValue(props, `permissions`, []).includes("update") && (
                  <Button
                    type="primary"
                    loading={sheetLoading}
                    onClick={() => handleSheetSubmit()}
                    disabled={getValue(patchRequest, "tasks.length", "") === 0}
                  >
                    Submit
                  </Button>
                )}
              </div>
            </div>
            <p className={"text"}>You have unsaved changes</p>
          </div>
        </motion.div>
      )}
      <DeleteModal
        isOpen={isOpenBulkDelete}
        handleModal={() => setIsOpenBulkDelete(false)}
        handleSubmit={handleBulkRemove}
        deleteValue={""}
      />
    </SidebarLayout>
  );
};

export default Tasks;
