import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllLifeCycleStages = (queryRequest: string) =>
  get(`${config.API_URL}/lifecycle-stages?${queryRequest}`);

export const getSpecificLifeCycleStage = (id: string) =>
  get(`${config.API_URL}/lifecycle-stages/${id}`);

export const getListOfLifeCycleStages = (queryRequest: string) =>
  get(`${config.API_URL}/lifecycle-stages/list?${queryRequest}`);

export const createLifeCycleStage = (payload: object) =>
  post(`${config.API_URL}/lifecycle-stages`, payload);

export const updateLifeCycleStage = (id: string, payload: object) =>
  patch(`${config.API_URL}/lifecycle-stages/${id}`, payload);

export const updateLifeCycleStagePosition = (payload: object) =>
  patch(`${config.API_URL}/lifecycle-stages/seq-num/swap`, payload);

export const updateLifeCycleStageStatus = (payload: object) =>
  patch(`${config.API_URL}/lifecycle-stages/bulk/active-inactive`, payload);

export const deleteLifeCycleStage = (id: string) =>
  Delete(`${config.API_URL}/lifecycle-stages/${id}`);
