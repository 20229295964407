import * as React from "react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtmlPuri from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "draft-js/dist/Draft.css";
import { getValue } from "@utils/lodash";
import { motion } from "framer-motion";
// https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
const DraftJSEditor = (props: any) => {
  const [editor, setEditor] = React.useState(true);
  const [editorState, setEditorState] = React.useState<any>(() =>
    EditorState.createEmpty()
  );
  React.useEffect(() => {
    const blocksFromHtml = htmlToDraft(getValue(props, `editorState`, null));
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState);
    someRef.current && someRef.current.focusEditor();
  }, []);
  let currentState = draftToHtmlPuri(
    convertToRaw(editorState.getCurrentContent())
  );

  React.useEffect(() => {
    if (getValue(props, `editorState`, null) !== currentState) {
      const blocksFromHtml = htmlToDraft(getValue(props, `editorState`, null));
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
      setEditor(false);
    }
  }, [getValue(props, `editorState`, null), currentState, editor]);

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
    props.handleChangeEditor(
      props.name,
      draftToHtmlPuri(convertToRaw(editorState.getCurrentContent()))
    );
  };
  const someRef = React.useRef<any>();

  const [isFocused, setIsFocused] = React.useState(false);
  const editorRef = React.useRef(null);

  const setEditorReference = (ref: any) => {
    if (ref) {
      editorRef.current = ref;
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    // <motion.div
    //   initial={{ y: 0, opacity: 0 }}
    //   animate={{ y: 0, opacity: 1 }}
    //   exit={{ y: 20, opacity: 0 }}
    //   transition={{ duration: 0.5 }}
    // >
    <Editor
      toolbarHidden={props.toolbarHidden}
      editorState={editorState}
      ref={(editorRef) => (someRef.current = editorRef)}
      editorRef={setEditorReference}
      toolbarClassName="emailToolbar"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      onEditorStateChange={onEditorStateChange}
      placeholder={props.placeholder}
      onFocus={handleFocus}
      onBlur={handleBlur}
      editorStyle={{
        ...props.editorStyle,
        border: props.focus
          ? isFocused
            ? "1px solid #0279ff"
            : "1px solid #efefef"
          : "none",
        borderRadius: "5px",
      }}
      toolbar={
        props.toolbar
          ? props.toolbar
          : {
              options: ["inline", "blockType", "fontSize", "list", "link"],
            }
      }
    />
    // </motion.div>
  );
};

export default DraftJSEditor;
