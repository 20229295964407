import { useEffect, useRef, useState } from "react";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import { message, Modal, Pagination, Switch } from "antd";
import { Plus } from "lucide-react";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import ListLoader from "@components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import SettingsPageDetails from "@components/Pages/PageDescription/SettingsPageDetails";
import { saleItemDetails } from "@common/Data/page-details-data";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "@common/query-request-helper";
import { toast } from "sonner";
import InputRuleForm from "@components/InputRuleForm/form";
import { handleNumberChange, handleTextChange } from "@common/handleChange";
import SimpleReactValidator from "simple-react-validator";
import useDynamicTitle from "@context/useDynamicTitle";
import {
  bulkUpdateSaleItem,
  createSaleItem,
  deleteSaleItem,
  getAllSaleItems,
  updateSaleItem,
} from "@services/sale-items.service";
import FiltersNoDataPage from "@components/common/NoData/filter-nodata";
import SaleitemTable from "./list";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import FormDescription from "@components/custom/FormDescrption/FormDescription";

const SaleItemsPage = ({ props }: any) => {
  useDynamicTitle("Sale Items");
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [list, setList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [request, setRequest] = useState<any>({
    name: "",
    description: "",
    code: "",
    unit_price: 0,
    is_active: true,
  });
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getSaleItems(true);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getSaleItems(true);
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getSaleItems = async (status: boolean) => {
    try {
      setIsLoading(status);
      const payload = {
        page_size: getValue(UrlParams, `page_size`, "")
          ? getValue(UrlParams, `page_size`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getAllSaleItems(queryRequest);
      if (resp) {
        setList(getValue(resp, "data.sale_items", []));
        setPage_no(getValue(resp, "data.pagination.page", 0));
        setTotalCount(getValue(resp, "data.pagination.total", 0));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Edit Section                                 */
  /* -------------------------------------------------------------------------- */
  const [editId, seteditId] = useState<any>("");
  const handleEdit = (item: any) => {
    seteditId(getValue(item, `id`, ""));
    setRequest({
      name: getValue(item, `name`, ""),
      description: getValue(item, `description`, ""),
      code: getValue(item, `code`, ""),
      unit_price: getValue(item, `unit_price`, 0),
      is_active: getValue(item, `is_active`, false),
    });
    showModal();
  };

  /* -------------------------------------------------------------------------- */
  /*                               Delete Section                               */
  /* -------------------------------------------------------------------------- */
  const handleDeleteModal = (id: string, name: string) => {
    Modal.confirm({
      title: `Are you sure you want to delete saleItem - ${name}?`,
      content: "Once deleted, this saleItem cannot be recovered.",
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: {
        disabled: isLoading1,
      },
      onOk: async () => {
        setIsLoading1(true);
        try {
          await deleteSaleItem(id);
          toast.success("Sale Item deleted successfully");
          getSaleItems(true);
          setIsLoading1(false);
        } catch (error) {
          toast.error("Error deleting saleItem");
          setIsLoading1(false);
        }
      },
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: any, limit: any) => {
    setPage_no(page_no);
    setLimit(limit);

    let payload = {
      ...UrlParams,
      page_no: page_no,
      page_size: limit,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    setPage_no(page_no);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Other section                                */
  /* -------------------------------------------------------------------------- */

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    seteditId("");
    setRequest({
      name: "",
      description: "",
      code: "",
      unit_price: 0,
      is_active: false,
    });
  };

  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      toast.error(`Please fill all the mandatory fields`);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setIsLoading(true);
      try {
        let payload = JSON.parse(JSON.stringify(request));
        payload["unit_price"] = getValue(payload, `unit_price`, 0)
          ? parseFloat(getValue(payload, `unit_price`, 0))
          : "";
        const resp = editId
          ? await updateSaleItem(
              editId,
              removeNullOrUndefinedProperties(payload)
            )
          : await createSaleItem(removeNullOrUndefinedProperties(payload));

        if (resp) {
          message.success(`${editId ? "Updated" : "Created"} Successfully`);
          setRequest({
            name: "",
            description: "",
            code: "",
            unit_price: 0,
            is_active: false,
          });
          getSaleItems(true);
          seteditId("");
          setIsModalOpen(false);
        } else {
          message.error(
            `Failed to ${editId ? "editing" : "creating"} products`
          );
        }
      } catch (error) {
        message.error(`Error ${editId ? "editing" : "creating"} products`);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleStatusChange = async (id: string, status: boolean) => {
    try {
      const payload = {
        ids: [id],
        is_active: !status,
      };
      const resp = await bulkUpdateSaleItem(payload);
      if (resp) {
        message.success(`Updated Successfully`);
        setRequest({
          name: "",
          description: "",
          code: "",
          unit_price: 0,
          is_active: false,
        });
        getSaleItems(false);
        seteditId("");
        setIsModalOpen(false);
      }
    } catch (error) {}
  };
  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        icon="sale-items"
        title="Sale Items"
        showSubmit
        submitIcon={<Plus size={18} />}
        submitText="New Sale Item"
        handleSubmit={() => showModal()}
      />
      <div className="sla-policy-wrapper">
        <div className="w-70 sla-policy-card-container">
          {isLoading ? (
            <ListLoader />
          ) : getValue(list, `length`, 0) > 0 ? (
            <SaleitemTable
              list={list}
              handleEdit={handleEdit}
              handleStatusChange={handleStatusChange}
              handleDeleteModal={handleDeleteModal}
            />
          ) : (
            <FiltersNoDataPage />
          )}
          {totalCount > 10 && (
            <Pagination
              align="center"
              className="mt-4"
              total={totalCount}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              defaultPageSize={limit}
              defaultCurrent={page_no}
              onChange={handleChangePagination}
            />
          )}
        </div>
        <div className="w-30 p-4 sla-policy-right-details">
          <SettingsPageDetails data={saleItemDetails} />
        </div>
      </div>
      <Modal
        title={`${editId ? "Edit" : "New"} Sale Item`}
        okText={`${editId ? "Update" : "Create"} Sale Item`}
        open={isModalOpen}
        onOk={handleSubmit}
        onCancel={handleCancel}
        okButtonProps={{
          disabled: !getValue(request, "name", ""),
        }}
      >
        <InputRuleForm
          inputType="TEXT"
          name="name"
          value={getValue(request, "name", "")}
          placeholder="Enter Sale Item Name"
          required
          label="Name (For Own Reference)"
          onChange={(e: any) => handleTextChange(e, request, setRequest)}
        />
        <FormDescription
          request={request}
          textClassname="mb-1 mt-3 w-100"
          inputClassname="mt-2 mb-2"
          input={
            <InputRuleForm
              inputType="TEXTAREA"
              name="description"
              value={getValue(request, `description`, "")}
              placeholder="Enter Description"
              label="Description"
              onChange={(e: any) => handleTextChange(e, request, setRequest)}
            />
          }
        />
        <InputRuleForm
          inputType="TEXT"
          name="code"
          value={getValue(request, "code", "")}
          placeholder="Enter Sale Item Code"
          label="Code"
          onChange={(e: any) => handleTextChange(e, request, setRequest)}
        />
        <div className="mt-2">
          <InputRuleForm
            inputType="TEXT"
            name="unit_price"
            value={getValue(request, "unit_price", "")}
            placeholder="Enter Unit Price"
            label="Unit Price"
            onChange={(e: any) => handleNumberChange(e, request, setRequest)}
          />
        </div>
        <div className="mt-3 d-flex align-items-center gap-3">
          <p className="">Status :</p>
          <Switch
            size="small"
            checked={getValue(request, "is_active", "")}
            onChange={() =>
              setRequest({
                ...request,
                is_active: !getValue(request, `is_active`, false),
              })
            }
          />
        </div>
      </Modal>
    </CompanyProfileDetailsLayout>
  );
};

export default SaleItemsPage;
