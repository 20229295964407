import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackSvgComponent from "@assets/svg/back-link";
import ButtonComponent from "@components/Form/Button/Button";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import { toast } from "sonner";
import {
  createRoomType,
  getAllRoomType,
  getSpecificRoomType,
} from "@services/properties/room-type.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import { updateRoomType } from "@services/properties/room-type.service";
import PropertyRoomTypeImagePopup from "./images-popup";
import PropertyRoomTypeAmenityPopup from "./amenities-popup";
import ProgressSteps from "@pages/Private/Property/components/addRoomType/room-progress-bar";
import SimpleReactValidator from "simple-react-validator";
import { getAllRoomAmenities } from "@services/properties/master/room-amenites.service";
import SidebarLayout from "@layouts/SidebarLayout/SidebarLayout";

const PropertyAddRoomTypes = (props: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    if (getValue(params, `id`, "")) {
      getData();
      if (getValue(UrlParams, `room_id`, "")) {
        setEditId(getValue(UrlParams, `room_id`, ""));
        getSpecificData(getValue(UrlParams, `room_id`, ""));
      }
      if (getValue(UrlParams, `room_id`, "")) {
        getSpecificAmenityData(getValue(UrlParams, `room_id`, ""));
      }
      getAmentiesData();
    }
  }, []);

  const currentConfig = getValue(params, "config", "");
  const progressSteps = [
    {
      label: "Configure Room",
      identifier: "edit",
      stepNo: 1,
    },
    {
      label: "Room Amenities",
      identifier: "amenities",
      stepNo: 2,
    },
    {
      label: "Photos",
      identifier: "images",
      stepNo: 3,
    },
  ];

  const activeStepIndex =
    progressSteps.findIndex(
      (progressStep) => progressStep.identifier === currentConfig
    ) + 1;

  const totalSteps = progressSteps.length;

  const [roomsLength, setRoomsLength] = useState<any>(0);

  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */

  const intialState = {
    name: "",
    description: "",
    default_occupancy: 15,
    // max_occupancy: 10,
    max_adult: 10,
    max_children: 5,
    default_price: 2000,
    min_price: 500,
    child_price: 1000,
    tax_category_id: "",
    property_id: "",
    rate_plan_id: "",
    rooms: [],
  };
  const [request, setRequest] = useState<any>(intialState);
  const reset = () => {
    setRequest(intialState);
    setEditId("");
  };

  const [isOpen3, setIsOpen3] = useState(false);

  const handleModal3 = () => {
    setIsOpen3(!isOpen3);
  };

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [rooms, setRooms] = useState([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        property_id: getValue(params, `id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllRoomType(queryRequest);
      if (resp) {
        setIsLoading(false);
        setRooms(getValue(resp, `data.properties`, []));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const simpleValidator = useRef(new SimpleReactValidator());

  const [, forceUpdate] = useState(0);

  const showValidation = () => {
    simpleValidator.current.showMessages();
    forceUpdate(1);
  };

  const formValid = simpleValidator.current.allValid();

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    if (!formValid) {
      simpleValidator.current.showMessages();
      showValidation();
    } else {
      try {
        setSubmitLoading(true);
        let requestPayload = JSON.parse(JSON.stringify(request));
        requestPayload["rooms"] = requestPayload["rooms"].map((item: object) =>
          getValue(item, `value`, "")
        );
        requestPayload["property_id"] = getValue(params, `id`, "");
        let resp;
        if (!editId) {
          resp = await createRoomType(
            removeNullOrUndefinedProperties(requestPayload)
          );
        } else {
          resp = await updateRoomType(
            editId,
            removeNullOrUndefinedProperties(requestPayload)
          );
        }
        if (resp) {
          setEditId(getValue(resp, "data.id", ""));
          toast.success("Updated successfully");
          if (activeStepIndex === 3) {
            reset();
          }
          setSubmitLoading(false);
          getData();
          const currentStep = progressSteps.find(
            (step) => step.stepNo === activeStepIndex + 1
          );

          const baseUrl = `/${getValue(params, "orgId", "")}/property/`;
          const url = currentStep
            ? activeStepIndex === progressSteps.length
              ? `${baseUrl}room-types/${getValue(params, "id", "")}`
              : `${baseUrl}room-types/${currentStep.identifier}/${getValue(
                  params,
                  "id",
                  ""
                )}?room_id=${getValue(resp, "data.id", "")}`
            : editId
            ? `${baseUrl}room-types/${getValue(params, "id", "")}`
            : "";

          navigate(url);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        console.error(error);
        setSubmitLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Edit Section                                 */
  /* -------------------------------------------------------------------------- */

  const [editId, setEditId] = useState("");

  const [isLoading1, setIsLoading1] = useState(true);
  const getSpecificData = async (id: string) => {
    try {
      setIsLoading1(true);
      let resp = await getSpecificRoomType(id);
      if (resp) {
        setEditId(getValue(resp, `data.id`, ""));
        setRoomsLength(getValue(resp, `data.room.length`, 0));
        setRequest({
          ...request,
          name: getValue(resp, `data.name`, ""),
          description: getValue(resp, `data.description`, ""),
          default_occupancy: getValue(resp, `data.default_occupancy`, ""),
          max_occupancy: getValue(resp, `data.max_occupancy`, ""),
          max_adult: getValue(resp, `data.max_adult`, ""),
          max_children: getValue(resp, `data.max_children`, ""),
          default_price: getValue(resp, `data.default_price`, ""),
          min_price: getValue(resp, `data.min_price`, ""),
          child_price: getValue(resp, `data.child_price`, ""),
          tax_category_id: getValue(resp, `data.tax_category_id`, ""),
          property_id: getValue(resp, `data.property_id`, ""),
          rate_plan_id: getValue(resp, `data.rate_plan_id`, ""),
          rooms: getValue(resp, `data.room`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `room_id`, ""),
          })),
        });
        setIsLoading1(false);
      } else {
        setIsLoading1(false);
      }
    } catch (error) {
      setIsLoading1(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                              Delete Section                                */
  /* -------------------------------------------------------------------------- */
  const [deleteId, setDeleteId] = useState("");
  const [deleteValue, setDeleteValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleDeleteNote = async () => {
    // try {
    //   let resp = await removeAssociatedAmenityFromProperty(
    //     getValue(params, `id`, ""),
    //     { amenity_ids: [deleteId] }
    //   );
    //   if (resp) {
    //     toast.success("Deleted Successfully");
    //     handleModal();
    //     getData();
    //     setDeleteId("");
    //     // props.getData(false);
    //   }
    // } catch (error) {}
  };

  const [editId1, setEditId1] = useState("");
  /* -------------------------------------------------------------------------- */
  /*                              Image Section                                 */
  /* -------------------------------------------------------------------------- */
  const [isOpen1, setIsOpen1] = useState(false);
  const handleModal1 = () => {
    setIsOpen1(!isOpen1);
  };

  /* -------------------------------------------------------------------------- */
  /*                              Amenity Section                               */
  /* -------------------------------------------------------------------------- */
  const [isOpen2, setIsOpen2] = useState(false);
  const handleModal2 = () => {
    setIsOpen2(!isOpen2);
  };
  const handleAmenityPopup = (id: string) => {
    getSpecificAmenityData(id);
    setEditId1(id);
    handleModal2();
  };

  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const getSpecificAmenityData = async (id: string) => {
    try {
      setIsLoading1(true);
      let resp = await getSpecificRoomType(id);
      if (resp) {
        setEditId1(getValue(resp, `data.id`, ""));
        setSelectedAmenities(
          getValue(resp, `data.room_amenities`, []).map((item: object) =>
            getValue(item, `id`, "")
          )
        );
        setIsLoading1(false);
      } else {
        setIsLoading1(false);
      }
    } catch (error) {
      setIsLoading1(false);
    }
  };

  const handleCombinedSubmit = async () => {
    if (!formValid) {
      showValidation();
    } else {
      try {
        if (editId) {
          // Update the data with new data
          await updateRoomType(
            editId,
            removeNullOrUndefinedProperties(request)
          );
          toast.success("Updated successfully");
        } else {
          await createRoomType(removeNullOrUndefinedProperties(request));
          toast.success("Created successfully");
        }

        getData();

        const currentStep = progressSteps.find(
          (step) => step.stepNo === activeStepIndex + 1
        );

        const baseUrl = `/${getValue(params, "orgId", "")}/property/`;
        const url = currentStep
          ? activeStepIndex === progressSteps.length
            ? `${baseUrl}room-types/${getValue(params, "id", "")}`
            : `${baseUrl}room-types/${currentStep.identifier}/${getValue(
                params,
                "id",
                ""
              )}?room_id=${getValue(UrlParams, "room_id", "")}`
          : editId
          ? `${baseUrl}amenities/${getValue(params, "id", "")}`
          : "";

        navigate(url);

        setSubmitLoading(false);
      } catch (error) {
        console.error(error);
        setSubmitLoading(false);
      }
    }
  };

  const prevStepIndex = activeStepIndex - 2;
  const prevStep = () => {
    if (prevStepIndex >= 0) {
      const prevStepIdentifier = progressSteps[prevStepIndex].identifier;

      navigate(
        `/${getValue(
          params,
          `orgId`,
          ""
        )}/property/room-types/${prevStepIdentifier}/${getValue(
          params,
          `id`,
          ""
        )}?room_id=${getValue(UrlParams, `room_id`, "")}`
      );
    }
  };

  const [isLoading2, setIsLoading2] = useState(true);
  const [list1, setList1] = useState([]);
  const getAmentiesData = async () => {
    try {
      setIsLoading2(true);
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllRoomAmenities(queryRequest);
      if (resp) {
        setList1(getValue(resp, `data.amenities`, []));
        setIsLoading2(false);
      } else {
        setIsLoading2(false);
      }
    } catch (error) {
      setIsLoading2(false);
    }
  };

  return (
    <SidebarLayout props={props}>
      <div className={"company-profile-subheader-wrapper"}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <div onClick={() => window.history.back()}>
              <BackSvgComponent />
            </div>

            <p className="small_text__16 ms-3">{"Property room details"}</p>
          </div>
          <div className="d-flex align-items-center">
            <ButtonComponent
              buttonType="scecondary"
              buttonText={"Back"}
              onClickHandler={prevStep}
              disabled={activeStepIndex === 1}
            />
            <ButtonComponent
              buttonType="primary"
              buttonText={editId ? "Update & Next" : "Save & Next"}
              onClickHandler={handleSubmit}
              disabled={submitLoading === true}
            />
          </div>
        </div>
      </div>
      <div className={`w-100 manageFieldRight px-5  add-overflow-y`}>
        <ProgressSteps
          steps={progressSteps}
          totalSteps={totalSteps}
          activeStep={activeStepIndex}
        />
        <div className="d-flex justify-content-center">
          {activeStepIndex === 1 && (
            <div className="w-50 px-5 pt-4 pb-5 ">
              <InputRuleForm
                inputType="TEXT"
                name="name"
                value={getValue(request, `name`, "")}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    name: e.target.value,
                  })
                }
                placeholder="Enter room type"
                className="mb-2"
                label="Room type name"
                required
                validator={simpleValidator}
              />
              <InputRuleForm
                inputType="TEXT"
                name="roomsLength"
                value={roomsLength}
                onChange={(e: any) => {
                  setRoomsLength(
                    parseInt(e.target.value) > 0 ? parseInt(e.target.value) : ""
                  );
                  setRequest({
                    ...request,
                    rooms:
                      parseInt(e.target.value) && parseInt(e.target.value) > 0
                        ? [...Array(parseInt(e.target.value))].map(
                            (item, i) => ({
                              value: "",
                            })
                          )
                        : [],
                  });
                }}
                placeholder="Enter number of rooms"
                className="mb-2"
                label="How many rooms under this room type?"
                required
                validator={simpleValidator}
              />
              {getValue(request, `rooms.length`, 0) > 0 && (
                <div>
                  <h6 className="header_text__16">Enter Room ID(s)</h6>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {getValue(request, `rooms.length`, 0) > 0 ? (
                      getValue(request, `rooms`, []).map(
                        (item: object, i: number) => (
                          <div key={i} className="ms-2 w-30">
                            <InputRuleForm
                              inputType="TEXT"
                              name={`room_${i}`}
                              value={getValue(item, `value`, "")}
                              onChange={(e: any) => {
                                setRequest((object: any) => {
                                  return {
                                    ...request,
                                    rooms: object["rooms"].map(
                                      (x: any, index: number) => {
                                        if (index !== i) return x;
                                        return {
                                          ...x,
                                          value: e.target.value,
                                        };
                                      }
                                    ),
                                  };
                                });
                              }}
                              placeholder={``}
                              className="mb-2"
                              label={`Room ID ${i + 1}`}
                            />
                          </div>
                        )
                      )
                    ) : (
                      <p className="small_text__16">No Rooms Added</p>
                    )}
                  </div>
                </div>
              )}
              <InputRuleForm
                inputType="NUMBER"
                name="default_occupancy"
                value={getValue(request, `default_occupancy`, "")}
                onChange={(e: any) => {
                  setRequest({
                    ...request,
                    default_occupancy:
                      parseInt(e.target.value) > 0
                        ? parseInt(e.target.value)
                        : "",
                  });
                }}
                placeholder="Enter occupancy"
                className="mb-2"
                label="Default occupancy"
                // disabled
              />
              {/* <InputRuleForm
                inputType="NUMBER"
                name="max_occupancy"
                value={getValue(request, `max_occupancy`, "")}
                onChange={(e: any) => {
                  setRequest({
                    ...request,
                    max_occupancy:
                      parseInt(e.target.value) > 0
                        ? parseInt(e.target.value)
                        : "",
                  });
                }}
                placeholder="Enter adult occupancy"
                className="mb-2"
                label="Maximum adult occupancy?"
              /> */}
              <InputRuleForm
                inputType="NUMBER"
                name="max_adult"
                value={getValue(request, `max_adult`, "")}
                onChange={(e: any) => {
                  setRequest({
                    ...request,
                    max_adult:
                      parseInt(e.target.value) > 0
                        ? parseInt(e.target.value)
                        : "",
                  });
                }}
                placeholder="Enter adult occupancy"
                className="mb-2"
                label="Maximum adult occupancy?"
              />

              <InputRuleForm
                inputType="NUMBER"
                name="max_children"
                value={getValue(request, `max_children`, "")}
                onChange={(e: any) => {
                  setRequest({
                    ...request,
                    max_children:
                      parseInt(e.target.value) > 0
                        ? parseInt(e.target.value)
                        : "",
                  });
                }}
                placeholder="Enter maximum children"
                className="mb-2"
                label="Maximum children?"
              />

              <InputRuleForm
                inputType="NUMBER"
                name="default_price"
                value={getValue(request, `default_price`, "")}
                onChange={(e: any) => {
                  setRequest({
                    ...request,
                    default_price:
                      parseInt(e.target.value) > 0
                        ? parseInt(e.target.value)
                        : "",
                  });
                }}
                placeholder="Enter Default adult price"
                className="mb-2"
                label="Default adult price?"
              />
              <InputRuleForm
                inputType="NUMBER"
                name="min_price"
                value={getValue(request, `min_price`, "")}
                onChange={(e: any) => {
                  setRequest({
                    ...request,
                    min_price:
                      parseInt(e.target.value) > 0
                        ? parseInt(e.target.value)
                        : "",
                  });
                }}
                placeholder="Enter Min adult price"
                className="mb-2"
                label="Min adult price?"
              />
              <InputRuleForm
                inputType="NUMBER"
                name="child_price"
                value={getValue(request, `child_price`, "")}
                onChange={(e: any) => {
                  setRequest({
                    ...request,
                    child_price:
                      parseInt(e.target.value) > 0
                        ? parseInt(e.target.value)
                        : "",
                  });
                }}
                placeholder="Enter Child price"
                className="mb-2"
                label="Child price?"
              />
            </div>
          )}

          {activeStepIndex === 2 && (
            <PropertyRoomTypeAmenityPopup
              id={editId1}
              setEditId={setEditId1}
              selectedAmenities={selectedAmenities}
              getSpecificAmenityData={getSpecificAmenityData}
            />
          )}

          {activeStepIndex === 3 && <PropertyRoomTypeImagePopup />}
        </div>
      </div>
      <DeleteModal
        isOpen={isOpen}
        handleModal={handleModal}
        handleSubmit={handleDeleteNote}
        deleteValue={deleteValue}
      />
    </SidebarLayout>
  );
};

export default PropertyAddRoomTypes;
