import React, { useRef, useState } from "react";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import { addInboxDomain } from "@services/email-config.service";
import { toast } from "sonner";
import SimpleReactValidator from "simple-react-validator";
import InputRuleForm from "@components/InputRuleForm/form";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import { Button } from "antd";

function SettingsAddEmailDeliverability(props: any) {
  const navigate = useNavigate();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const params = useParams();
  const [request, setRequest] = useState({
    domain: "",
    subDomain: "",
  });

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        let resp = await addInboxDomain(request);
        if (resp) {
          toast.success("Added successfully");
          navigate(
            `/${getValue(
              params,
              `orgId`,
              ""
            )}/settings/general/emails/deliverability`
          );
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
        setSubmitLoading(true);
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        title="Emails"
        icon="emails"
        backButton
        // addChildren={
        //   <Button size="large" type="primary" onClick={handleNavigate}>
        //     New Sender
        //   </Button>
        // }
      />

      <div className={`${"email_container"} w-100`}>
        <div className="p-4">
          <p className="sub_text__14">
            Send bulk messages from your own email address. Add a new sender
            below.
          </p>
          <div className="mt-3">
            <div>
              {/* <label className="label_text mb-1">Domain</label> */}
              <InputRuleForm
                inputType="TEXT"
                label="Domain"
                style={{ height: "38px" }}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    domain: e.target.value,
                  })
                }
                value={getValue(request, `domain`, "")}
                name="domain"
                placeholder={"Enter Domain"}
                validator={simpleValidator}
                required
                validLeft
              />
            </div>
            <div className="mt-3">
              {/* <label className="label_text mb-1">Sub Domain</label> */}
              <InputRuleForm
                inputType="TEXT"
                label="Sub Domain"
                style={{ height: "38px" }}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    subDomain: e.target.value,
                  })
                }
                value={getValue(request, `subDomain`, "")}
                name="subDomain"
                placeholder={"Enter Sub Domain"}
                // validator={simpleValidator}
                // required
                // validLeft
              />
            </div>
            <div className="popup-submit-container gap-2">
              <Button
                size="large"
                onClick={() =>
                  navigate(
                    `/${getValue(
                      params,
                      `orgId`,
                      ""
                    )}/settings/general/emails/deliverability`
                  )
                }
              >
                Cancel
              </Button>
              <Button
                type="primary"
                size="large"
                loading={submitLoading}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </CompanyProfileDetailsLayout>
  );
}

export default SettingsAddEmailDeliverability;
