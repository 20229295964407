import ResendSvgComponent from "@assets/svg/resend";
import { findType } from "@common/file-type-helper";
import { replaceDefaultPlaceholders } from "@common/markdown/markdown-helper";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  capitalizeFirstLetter,
  concatString,
  sanitizeHTML,
} from "@common/text-helpers";
import { aiRephraseMethods } from "@components/AI/AiRephraseMethods";
import MarkdownEditor from "@components/LiveChatMarkdownEditor/Editor";
import { formatMarkdownText } from "@components/MarkdownEditor/helpers/textFormatter";
import { getSpecificCannedResponseContent } from "@services/canned-response.service";
import { getValue } from "@utils/lodash";
import { Button, Input, List, Popover, Skeleton } from "antd";
import _ from "lodash";
import { FolderClosed, FolderLock } from "lucide-react";
import { memo, useEffect, useRef } from "react";

const ChatEditor = (props) => {
  const {
    contentLength,
    showEmojiPicker,
    handleEmojiClick,
    handleUploadDocument,
    message,
    toggleAiModal,
    isRephraseLoading,
    handleKeyDown,
    editorRef,
    handleChangeEditor,
    handleSubmit,
    isLoading,
    placeHolder,
    openCannedResponse,
    handleCannedOpen,
    handleCannedEditorButton,
    cannedPopupPosition,
    cannedList,
    isCannedLoading,
    handleCannedResponse,
    handleCannedScroll,
    cannedLoadMore,
    parentChage,
    setParentChage,
    showCannedResponse,
    selectedConversationInfo,
    showMaxLength,
    maxLength,
    handleBroadcastEditorButton,
    showBroadcast,
    searchQuery,
    setSearchQuery,
    filteredCannedList,
    handleSearch,
    setCannedRespFileId,
    setCannedRespFileType,
    handleMediaLibraryButton,
    highlightedData,
    setHighlightedData,
    themeColor,
    editorTab,
  } = props;

  const loadMore = !isCannedLoading ? (
    <div
      style={{
        textAlign: "center",
        marginTop: 12,
        height: 32,
        lineHeight: "32px",
      }}
    >
      <Button onClick={cannedLoadMore}>Load more</Button>
    </div>
  ) : null;

  const handleCannedResponses = async (item) => {
    if (getValue(item, `id`, "")) {
      try {
        const resp = await getSpecificCannedResponseContent(
          getValue(item, `id`, ""),
          QueryRequestHelper({
            lead_id: getValue(selectedConversationInfo, `lead.id`, ""),
            deal_id: getValue(selectedConversationInfo, `deal.id`, ""),
            contact_id: getValue(selectedConversationInfo, `contact.id`, ""),
          })
        );
        if (resp) {
          handleCannedResponse(getValue(resp, `data.content`, ""));
          if (setCannedRespFileId || setCannedRespFileType) {
            setCannedRespFileId(
              getValue(resp, `data.attachments[${0}].id`, "")
            );
            setCannedRespFileType(
              findType(getValue(resp, `data.attachments[${0}].extension`, ""))
            );
          }
        }
      } catch (error) {}
    }
    //
  };

  const handleSendMessage = () => {
    handleSubmit();
    // window.scrollTo(0, document.body.scrollHeight);
  };
  return (
    <div className="position-relative">
      <div className="message-bottom-section p-2">
        <div className="d-flex align-items-center gap-2">
          <MarkdownEditor
            value={message}
            handleChangeEditor={handleChangeEditor}
            parentChage={parentChage}
            setParentChage={setParentChage}
            openCannedResponse={openCannedResponse}
            handleCannedEditorButton={handleCannedEditorButton}
            showCanned={showCannedResponse}
            handleUploadDocument={handleUploadDocument}
            showUpload
            showMediaLibrary
            showMaxLength={showMaxLength}
            maxLength={maxLength}
            allowedFileTypes=".jpg,.jpeg,.png,.gif,.mp4,.mp3,.mov,.avi,.pdf,.docx,.doc,.xls,.xlsx,.csv,.txt,.pptx,.ppt"
            handleBroadcastEditorButton={handleBroadcastEditorButton}
            showBroadcast={
              getValue(selectedConversationInfo, `source`, "") !== "whatsapp"
                ? false
                : showBroadcast
            }
            selectedConversationInfo={selectedConversationInfo}
            handleMediaLibraryButton={handleMediaLibraryButton}
            editorRef={editorRef}
            handleEnterKey={handleKeyDown}
            contentLength={contentLength}
            aiRephraseMethods={aiRephraseMethods}
            isRephraseLoading={isRephraseLoading}
            toggleAiModal={toggleAiModal}
            editorTab={editorTab}
          />

          {cannedList && (
            <Popover
              width={500}
              title="Canned Response"
              content={
                <>
                  <div
                    className="inbox-canned-response-container mt-2"
                    onScroll={handleCannedScroll}
                  >
                    <div className="w-50">
                      <div className="pb-2 position-sticky bg-white">
                        <Input
                          allowClear
                          placeholder="Search Canned Response"
                          loading={isCannedLoading}
                          value={searchQuery}
                          onChange={(e) => {
                            setSearchQuery(e.target.value);
                            handleSearch(e.target.value);
                          }}
                        />
                      </div>
                      <List
                        size="small"
                        loading={isCannedLoading}
                        itemLayout="horizontal"
                        dataSource={filteredCannedList}
                        renderItem={(item) => (
                          <List.Item
                            onMouseEnter={() =>
                              setHighlightedData({
                                title: getValue(item, "title", ""),
                                content: sanitizeHTML(
                                  formatMarkdownText(
                                    getValue(item, "content", "  ")
                                  )
                                ),
                              })
                            }
                            onMouseLeave={() =>
                              setHighlightedData({
                                title: "",
                                content: "",
                              })
                            }
                            onClick={() => handleCannedResponses(item)}
                            className="cursor-pointer"
                          >
                            <Skeleton
                              avatar
                              title={false}
                              loading={item.loading}
                              active
                            >
                              <List.Item.Meta
                                avatar={
                                  getValue(item, "visibility", "") ===
                                  "myself" ? (
                                    <FolderLock size={16} />
                                  ) : (
                                    <FolderClosed size={16} />
                                  )
                                }
                                title={
                                  <div>
                                    {capitalizeFirstLetter(
                                      getValue(item, "title", "  ")
                                    )}
                                  </div>
                                }
                                description={sanitizeHTML(
                                  concatString(
                                    formatMarkdownText(
                                      getValue(item, "content", "  ")
                                    ),
                                    40
                                  )
                                )}
                              />
                            </Skeleton>
                            <div>{getValue(item, "folder.name", "  ")}</div>
                          </List.Item>
                        )}
                      />
                    </div>
                    <div className="w-50 canned-response-list-highlighted px-3">
                      <h6 className="header_text__15 mb-2">
                        {highlightedData.title}
                      </h6>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: replaceDefaultPlaceholders(
                            highlightedData.content ||
                              "Hover over a response to preview"
                          ),
                        }}
                        className="canned-response-list-highlighted-content small_text__14 p-3"
                      />
                    </div>
                  </div>
                </>
              }
              open={openCannedResponse}
              onOpenChange={handleCannedOpen}
              trigger="click"
            >
              <div
                style={{
                  position: "absolute",
                  top: cannedPopupPosition.top,
                  left: "200px",
                  zIndex: 1000,
                }}
              ></div>
            </Popover>
          )}
          <Button
            color="primary"
            variant="text"
            shape="circle"
            onClick={!message ? null : handleSendMessage}
            className=""
            disabled={!message ? true : false}
            loading={isLoading}
            icon={
              <ResendSvgComponent
                color={message ? themeColor : "#aeaeae"}
                size="18"
              />
            }
          ></Button>
        </div>
      </div>
    </div>
  );
};

export default memo(ChatEditor);
