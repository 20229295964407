import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";
import AddPopup from "./AddPopup";
import { getValue } from "@utils/lodash";

function AddInfoPopup(props: any) {
  const { isOpen, setIsOpen, module, title, width } = props;
  return (
    <div>
      <SlideFromRightPopup
        isPopupVisible={isOpen}
        addCustomClass={"rolls-popup"}
        toggle={() => {
          setIsOpen(false);
        }}
        title={title}
        width={width}
      >
        <AddPopup
          isAddExisting={true}
          form={props.form}
          setForm={props.setForm}
          stage={props.stage}
          setStage={props.setStage}
          toggle={() => {
            setIsOpen(false);
          }}
          handleSubmit={props.handleSubmit}
          formLoading={props.formLoading}
          formSubmitLoading={props.formSubmitLoading}
          simpleValidator={props.simpleValidator}
          handleAssociate={props.handleAssociate}
          handleDisAssociate={props.handleDisAssociate}
          selectedList={props.selectedList}
          pipelineList={props.pipelineList}
          stageList={props.stageList}
          module={module}
          staticFieldRequest={props.staticFieldRequest}
          setStaticFieldRequest={props.setStaticFieldRequest}
          getSpecificPipelineInfo={props.getSpecificPipelineInfo}
          disabled={props.disabled}
          disableValidation={props.disableValidation}
          permissions={getValue(props, `permissions`, [])}
        />
      </SlideFromRightPopup>
    </div>
  );
}

export default AddInfoPopup;
