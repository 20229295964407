import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Drawer } from "antd";

export default function GatewayDrawer(props: any) {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const { pathname } = location;

  return (
    <>
      <Drawer
        onClose={props.handleOpenDrawer}
        open={props.isOpen}
        closable={false}
        width={400}
        className="account-sidepar-popup"
      ></Drawer>
    </>
  );
}
