import { useEffect } from "react";
import { useSocketContext } from "./socketContext";

const useDynamicTitle = (screenTitle) => {
  const { rawStatusCounts, totalUnreadInboxCount } = useSocketContext();
  // const totalInboxCount =
  //   rawStatusCounts.unread.find((item) => item.status === "my_open_chats")
  //     ?.count || 0;

  // useEffect(() => {
  //   const inboxCount =
  //     typeof totalInboxCount === "function"
  //       ? totalInboxCount()
  //       : totalInboxCount;

  //   const inboxPart = inboxCount ? `(${inboxCount}) ` : "";
  //   document.title = `${inboxPart}${screenTitle}`;
  // }, [totalInboxCount, rawStatusCounts, screenTitle]);

  const totalInboxCount = totalUnreadInboxCount
  useEffect(() => {
    const inboxCount =
      typeof totalInboxCount === "function"
        ? totalInboxCount()
        : totalInboxCount;

    const inboxPart = inboxCount ? `(${inboxCount}) ` : "";
    document.title = `${inboxPart}${screenTitle}`;
  }, [totalInboxCount, rawStatusCounts, screenTitle]);
};

export default useDynamicTitle;
