import InputRuleForm from "@components/InputRuleForm/form";
import React from "react";
import "../workflow.scss";
import { getValue } from "@utils/lodash";
import { formatString } from "@common/text-helpers";
import { motion } from "framer-motion";
import { Checkbox, Radio } from "antd";

function WorkflowDetailStep2(props: any) {
  const { request, setRequest } = props;
  const executionTypeList = [
    { value: "CREATED", label: "Created" },
    { value: "BOTH", label: "Created or edited" },
    { value: "EDITED", label: "Edited" },
  ];
  return (
    <div className="workflow_detail_container">
      <div className="">
        <h6 className="header_text__20 color_primary">1. Trigger</h6>
        <p className="small_text__16 mt-1 mb-2">
          Trigger this workflow when a event is{" "}
          <span className="color_success">
            <b>
              {formatString(
                getValue(request, `executionType`, "") === "BOTH"
                  ? "Created or Edited"
                  : getValue(request, `executionType`, "")
              )}
            </b>
          </span>
        </p>
        <div className="mt-4">
          <Radio.Group
            value={getValue(request, `executionType`, "")}
            onChange={(e) =>
              setRequest({
                ...request,
                executionType: e.target.value,
              })
            }
          >
            {executionTypeList.map((item) => {
              return (
                <div className="mb-2" key={item.value}>
                  <Radio value={item.value}>
                    {getValue(item, `label`, "")}
                  </Radio>

                  {getValue(request, `executionType`, "") ===
                    getValue(item, `value`, "") &&
                    getValue(request, `executionType`, "") !== "CREATED" && (
                      <motion.div
                        initial={{ y: 20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -20, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <div className="workflow-checkbox-container d-flex gap-2 align-items-center">
                          <div className="ms-2">
                            <Checkbox
                              checked={
                                getValue(request, `repeat`, "") ? true : false
                              }
                              onChange={() =>
                                setRequest({
                                  ...request,
                                  repeat: !getValue(request, `repeat`, ""),
                                })
                              }
                            />
                          </div>
                          <p className="small_text__14">
                            Repeat this workflow whenever a event is edited
                          </p>
                        </div>
                      </motion.div>
                    )}
                </div>
              );
            })}
          </Radio.Group>
          {/* {(getValue(request, `executionType`, "") === "BOTH" ||
            getValue(request, `executionType`, "") === "EDITED") && (
            <div className="d-flex">
              <InputRuleForm
                inputType="CHECKBOX"
                label={getValue(request, `repeat`, "")}
                checked={getValue(request, `repeat`, "") ? true : false}
                onChange={() =>
                  setRequest({
                    ...request,
                    repeat: !getValue(request, `repeat`, ""),
                  })
                }
              />
              <p>Repeat this workflow whenever a event is edited</p>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default WorkflowDetailStep2;
