import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";
import AddPopup from "./AddPopup";
import { getValue } from "@utils/lodash";
import { Drawer } from "antd";
import AntSubmitContainer from "@components/common/ButtonContainer/NewSubmitContainer";

function AddInfoPopup(props: any) {
  const { isOpen, setIsOpen, module, title, width } = props;
  return (
    <div>
      <Drawer
        open={isOpen}
        className="ant-drawer-container-footer "
        onClose={() => {
          setIsOpen(false);
        }}
        title={title}
        width={width}
        footer={
          <AntSubmitContainer
            handleBackButton={() => {
              setIsOpen(false);
            }}
            handleSubmit={() => {
              props.handleSubmit(getValue(props, `module`, ""));
            }}
            submitLoading={props.formSubmitLoading}
            first_title={"Cancel"}
            second_title={"Create"}
            hideNew
            disabled={props.disabled}
            disableValidation={props.disableValidation}
            permissions={getValue(props, `permissions`, [])}
          />
        }
      >
        <AddPopup
          isAddExisting={true}
          form={props.form}
          setForm={props.setForm}
          stage={props.stage}
          setStage={props.setStage}
          toggle={() => {
            setIsOpen(false);
          }}
          handleSubmit={props.handleSubmit}
          formLoading={props.formLoading}
          formSubmitLoading={props.formSubmitLoading}
          simpleValidator={props.simpleValidator}
          handleAssociate={props.handleAssociate}
          handleDisAssociate={props.handleDisAssociate}
          selectedList={props.selectedList}
          pipelineList={props.pipelineList}
          stageList={props.stageList}
          module={module}
          staticFieldRequest={props.staticFieldRequest}
          setStaticFieldRequest={props.setStaticFieldRequest}
          getSpecificPipelineInfo={props.getSpecificPipelineInfo}
          disabled={props.disabled}
          disableValidation={props.disableValidation}
          permissions={getValue(props, `permissions`, [])}
          hideList={props.hideList}
        />
      </Drawer>
    </div>
  );
}

export default AddInfoPopup;
