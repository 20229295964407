import React, { useEffect, useRef, useState } from "react";
import CreateSubPipeline from "./components/add-sub-pipeline";
import {
  concatString,
  formatString,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { toast } from "sonner";
import { useNavigate, useParams } from "react-router-dom";
import { createSubPipeline } from "@services/common.service";
import {
  generatePayload,
  generatePipelineAssociationPayload,
} from "@components/helpers/request-helper";
import AddInfoPopup from "../Detail/right-container/common/AddPopup/AddInfoPopup";
import { QueryRequestHelper } from "@common/query-request-helper";
import { useStateContext } from "@context/dataContext";
import { allPipelines, getSpecificPipeline } from "@services/pipeline.service";
import { getAllModuleFields } from "@services/module-fields.service";
import SimpleReactValidator from "simple-react-validator";
import { handleFormValidation } from "@components/custom/validation";
import { Drawer } from "antd";
import AntSubmitContainer from "@components/common/ButtonContainer/NewSubmitContainer";

function CommonAddSubPipeline(props: any) {
  const {
    isOpen,
    toggle,
    fields,
    setFields,
    simpleValidator,
    singular_label,
    forceUpdate,
    getAllPipelines,
    module,
    staticFieldRequest,
    associations,
    setAssociations,
    association,
    setAssociation,
    orginalSelectedFields,
    resetAssociations,
  } = props;
  const navigate = useNavigate();
  const params = useParams();
  const { userId, allModules, selectedModuleId } = useStateContext();
  const [listCreateLoading, setListCreateLoading] = useState(false);

  const hideValidation = () => {
    simpleValidator.current.hideMessages();
    simpleValidator.current.fields = {};
    simpleValidator.current.errorMessages = {};
    forceUpdate(0);
  };
  const showValidation = () => {
    simpleValidator.current.showMessages();
    forceUpdate(1);
  };
  const handleCreate = async (e?: React.MouseEvent | string) => {
    // If e is a MouseEvent, pass undefined as val
    const val = typeof e === "string" ? e : undefined;

    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      showValidation();
    } else {
      try {
        setListCreateLoading(true);
        let prod =
          getValue(products, `length`, 0) > 0
            ? products.map((item: object) => ({
                ...item,
                list_price: getValue(item, `list_price`, 0)
                  ? parseInt(getValue(item, `list_price`, 0))
                  : 0,
                quantity: getValue(item, `quantity`, 0)
                  ? parseInt(getValue(item, `quantity`, 0))
                  : 0,
                discount: getValue(item, `discount`, 0)
                  ? parseInt(getValue(item, `discount`, 0))
                  : 0,
                total: getValue(item, `total`, 0)
                  ? parseInt(getValue(item, `total`, 0))
                  : 0,
                sale_item_id: getValue(item, `sale_item_id`, ""),
              }))
            : [];
        let payload: any = generatePayload(
          module,
          fields,
          staticFieldRequest,
          prod
        );
        let resp = await createSubPipeline(
          getValue(props, `association`, "")
            ? {
                ...payload,
                associations: associations
                  .filter((item: any) => {
                    return getValue(item, "record_id", "") !== "";
                  })
                  .map((item: any) => ({
                    record_id: getValue(item, "record_id", ""),
                    api_name: getValue(item, "api_name", ""),
                  })),
              }
            : { ...payload },
          module
        );
        if (resp) {
          toast.success("Created Successfully");
          setListCreateLoading(false);
          if (val) {
            simpleValidator.current.hideMessages();
            forceUpdate(0);
            getAllPipelines();
          } else {
            if (
              module === "tasks" ||
              module === "calls" ||
              module === "meetings"
            ) {
              navigate(
                `/${getValue(params, `orgId`, "")}/crm/${
                  module === "sales" ? "deals" : module
                }/detail/${getValue(resp, `data.id`, "")}`
              );
            } else if (module === "tickets") {
              navigate(
                `/${getValue(params, `orgId`, "")}/${module}/detail/${getValue(
                  resp,
                  `data.id`,
                  ""
                )}`
              );
            } else {
              navigate(
                `/${getValue(params, `orgId`, "")}/crm/${
                  module === "sales" ? "deals" : module
                }/detail/${getValue(resp, `data.id`, "")}`
              );
            }

            toggle(false);
          }
          setProducts([]);
        } else {
          setListCreateLoading(false);
        }
      } catch (error) {
        setListCreateLoading(false);
      }
    }
  };
  const [duplicateAPINames, setDuplicateAPINames] = useState<any>([]);

  /* -------------------------------------------------------------------------- */
  /*                        Association Form Section                            */
  /* -------------------------------------------------------------------------- */

  const [isRollsPopupVisibile, setRollsPopupVisisbility] = useState(false);
  const handleOpenForm = (module: string) => {
    getForm(module);
    setRollsPopupVisisbility(true);
  };

  const [formLoading, setFormLoading] = React.useState(false);
  const [associationForm, setAssociationForm] = React.useState([]);
  const [formStageList, setFormStageList] = React.useState<any>([]);
  const [associationPipelines, setAssociationPipelines] = useState([]);

  const [selectedModule, setSelectedModule] = useState("");
  const getForm = async (module: string) => {
    setSelectedModule(module);
    setAssociationForm([]);
    if (module !== "tasks" && module !== "meetings" && module !== "calls") {
      try {
        let payload = {
          module_name: module,
        };
        let queryRequest = QueryRequestHelper(payload);
        setFormLoading(true);
        let findId =
          getValue(allModules, `length`, 0) > 0
            ? allModules.find(
                (item: object) =>
                  getValue(item, `api_name`, "") ===
                  (module === "sales" ? "deals" : module)
              )
            : [];
        let resp = await allPipelines(getValue(findId, `id`, ""), queryRequest);
        if (resp) {
          setAssociationPipelines(getValue(resp, `data`, []));
          getAssociationPipelineInfo(
            getValue(resp, `data[${0}].id`, ""),
            module
          );
          setFormStageList(
            getValue(resp, `data[${0}].stages`, []).map((item: object) => ({
              ...item,
              label: getValue(item, `label`, ""),
              value: getValue(item, `api_name`, ""),
            }))
          );
          setFormLoading(false);
        } else {
          setFormLoading(false);
        }
      } catch (error) {
        setFormLoading(false);
      }
    } else {
      try {
        let code =
          getValue(allModules, `length`, 0) > 0
            ? allModules.find(
                (item: object) => getValue(item, `api_name`, "") == module
              )
            : [];
        let resp = await getAllModuleFields(getValue(code, `id`, ""));
        if (resp) {
          let fields =
            getValue(resp, `data.length`, 0) > 0
              ? getValue(resp, `data`, []).map((item: object) => ({
                  ...getValue(item, `module_field`, {}),
                  name: getValue(item, `module_field.api_name`, ""),
                  quick_add: getValue(item, `quick_add`, ""),
                  required: getValue(item, `required`, ""),
                  seq_num: getValue(item, `seq_num`, ""),
                }))
              : [];
          for (const item of fields) {
            if (item.api_name === "owner_id") {
              item.value = userId;
            }
          }
          setAssociationForm(fields);
        }
      } catch (error) {}
    }
  };
  const [fieldRequest, setFieldRequest] = React.useState({
    pipeline_id: "",
    stage: "",
  });
  const getAssociationPipelineInfo = async (id: string, module: string) => {
    if (id) {
      try {
        let resp = await getSpecificPipeline(await selectedModuleId(), id);
        if (resp) {
          let fields =
            getValue(resp, `data.form_fields.length`, 0) > 0
              ? getValue(resp, `data.form_fields`, []).map((item: object) => ({
                  ...getValue(item, `module_field`, {}),
                  name: getValue(item, `name`, ""),
                  quick_add: getValue(item, `quick_add`, ""),
                  required: getValue(item, `required`, ""),
                  seq_num: getValue(item, `seq_num`, ""),
                }))
              : [];
          for (const item of fields) {
            if (item.api_name === "owner_id") {
              item.value = userId;
            }
          }
          setFieldRequest((prevStaticFieldRequest) => ({
            ...prevStaticFieldRequest,
            stage: getValue(resp, `data.stages[${0}].id`, ""),
            pipeline_id: id,
          }));
          setAssociationForm(fields);
          setFormLoading(false);
        } else {
          setFormLoading(false);
        }
      } catch (error) {}
    } else {
      setAssociationForm([]);
    }
  };

  const simpleValidatorForm = React.useRef(new SimpleReactValidator());
  const [, forceUpdateForm] = React.useState(0);

  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const handleSubmit = async (module: string) => {
    if (module) {
      setAssociation(false);
      // const formValid = simpleValidator.current.allValid();
      // if (!formValid) {
      //   simpleValidator.current.showMessages();
      //   forceUpdateForm(1);
      // } else {
      let fields = JSON.parse(JSON.stringify(associationForm));
      let payloadRequest = generatePipelineAssociationPayload(
        module,
        fields,
        fieldRequest,
        []
      );
      try {
        setFormSubmitLoading(true);
        let resp = await createSubPipeline(
          removeNullOrUndefinedProperties({
            ...payloadRequest,
          }),
          module
        );
        if (resp) {
          toast.success("Created Successfully");
          // simpleValidatorForm.current.hideMessages();
          forceUpdateForm(0);
          setRollsPopupVisisbility(false);
          setFormSubmitLoading(false);
          setAssociation(true);
        } else {
          setFormSubmitLoading(false);
          setAssociation(true);
        }
      } catch (error) {
        setFormSubmitLoading(false);
        setAssociation(true);
      }
      // }
    }
  };

  const errors = handleFormValidation(associationForm);
  const hasErrors = errors.length > 0;

  const [products, setProducts] = useState<any>([]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (!props.disabled) {
        handleCreate();
      }
    }
  };

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.focus();
    }
  }, []);
  const [validPhone, setValidPhone] = useState<any>([]);
  return (
    <div>
      <Drawer
        open={isOpen}
        className="ant-drawer-container-footer "
        onClose={() => {
          toggle();
          hideValidation();
          if (getValue(orginalSelectedFields, `length`, 0) > 0) {
            setFields([]);
            setTimeout(() => {
              setFields(orginalSelectedFields);
            }, 500);
          }
          if (resetAssociations) {
            resetAssociations();
          }
        }}
        title={
          module === "calls"
            ? "Log Call"
            : module === "meetings"
            ? "Log Meeting"
            : `Create ${concatString(singular_label, 20)}`
        }
        width={props.width ? props.width : "600px"}
        maskClosable={false}
        footer={
          <AntSubmitContainer
            handleBackButton={() => {
              toggle();
              hideValidation();
              if (getValue(orginalSelectedFields, `length`, 0) > 0) {
                setFields([]);
                setTimeout(() => {
                  setFields(orginalSelectedFields);
                }, 500);
              }
              if (resetAssociations) {
                resetAssociations();
              }
            }}
            handleSubmit={handleCreate}
            submitLoading={listCreateLoading}
            disabled={
              duplicateAPINames.length > 0 ||
              getValue(validPhone, `length`, 0) > 0
                ? true
                : false || hasErrors
            }
            first_title="Cancel"
            second_title="Submit"
            permissions={["create"]}
          />
        }
      >
        <CreateSubPipeline
          module={module}
          fields={fields}
          setFields={setFields}
          stageList={props.stageList}
          pipelineList={props.pipelineList}
          simpleValidator={simpleValidator}
          hideValidation={hideValidation}
          singular_label={concatString(singular_label, 20)}
          staticFieldRequest={props.staticFieldRequest}
          setStaticFieldRequest={props.setStaticFieldRequest}
          getSpecificPipelineInfo={props.getSpecificPipelineInfo}
          duplicateAPINames={duplicateAPINames}
          setDuplicateAPINames={setDuplicateAPINames}
          pipeline_id={props.pipeline_id}
          association={association}
          associations={associations}
          setAssociations={setAssociations}
          handleOpenForm={handleOpenForm}
          products={products}
          setProducts={setProducts}
          showProducts
          divRef={divRef}
          handleKeyDown={handleKeyDown}
          setValidPhone={setValidPhone}
          validPhone={validPhone}
        />
      </Drawer>

      <AddInfoPopup
        isOpen={isRollsPopupVisibile}
        setIsOpen={setRollsPopupVisisbility}
        title={`Add ${formatString(selectedModule)}`}
        width={"590px"}
        form={associationForm}
        setForm={setAssociationForm}
        formLoading={formLoading}
        //submit section
        handleSubmit={handleSubmit}
        formSubmitLoading={formSubmitLoading}
        simpleValidator={simpleValidatorForm}
        // handleAssociate={props.handleAssociate}
        // handleDisAssociate={props.handleDisAssociate}
        selectedList={[]}
        hideList={true}
        //static List
        module={selectedModule}
        pipelineList={associationPipelines}
        stageList={formStageList}
        staticFieldRequest={fieldRequest}
        setStaticFieldRequest={setFieldRequest}
        getSpecificPipelineInfo={getAssociationPipelineInfo}
        disableValidation
        permissions={getValue(props, `permissions`, [])}
        disabled={hasErrors ? true : false}
      />
    </div>
  );
}

export default CommonAddSubPipeline;
