import { useEffect, useRef, useState } from "react";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import CannedFolders from "./components/canned-folders";
import CannedTable from "./components/canned-table";
import "./canned.scss";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  createCannedResponse,
  createCannedResponseFolder,
  deleteCannedResponse,
  deleteCannedResponseFolder,
  getAllCannedFolderTableResponse,
  getAllCannedResponseFolders,
  updateCannedResponse,
  updateCannedResponseFolder,
} from "@services/canned-response.service";
import { getValue } from "@utils/lodash";
import { useNavigate } from "react-router-dom";
import ListLoader from "@components/common/Loader";
import { Alert, Button, Drawer, Form, message, Modal } from "antd";
import InputRuleForm from "@components/InputRuleForm/form";
import { FolderClosed, Info, Plus } from "lucide-react";
import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";
import CannedResponseDetails from "./components/canned-response-editor";
import { getGroupsList } from "@services/groups.service";
import SimpleReactValidator from "simple-react-validator";
import useDynamicTitle from "@context/useDynamicTitle";
import { listAllPipelines } from "@services/pipeline.service";
import {
  getAllAvailableTemplateTags,
  getloggedInuserTemplateTags,
} from "@services/email-config.service";
import _ from "lodash";
import AntSubmitContainer from "@components/common/ButtonContainer/NewSubmitContainer";

const CannedResponse = (props: any) => {
  const navigate = useNavigate();
  useDynamicTitle("Canned Response");
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const [isLoading, setIsLoading] = useState(false);
  const [request, setRequest] = useState({ name: "" });
  const [editId, setEditID] = useState("");
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState({
    id: "",
    name: "",
  });
  const [tableData, setTableData] = useState({});
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isCannedSliderOpen, setCannedSliderOpen] = useState(false);
  const [isCannedSliderOpen1, setCannedSliderOpen1] = useState(false);
  const [cannedEditId, setCannedEditId] = useState("");
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [groupLoading, setGroupLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  // const [totalFolderCount, setTotalFolderCount] = useState(0);
  // const [folderLimit, setFolderLimit] = useState(2);
  // const [folderPageNo, setFolderPageNo] = useState(1);

  const [cannedRequest, setCannedRequest] = useState<any>({
    title: "",
    content: "",
    folder_id: "",
    attachments: [],
    module_id: "",
    pipeline_id: "",
  });
  const [personalFolderId, setPersonalFolderId] = useState("");
  const [filteredFolderList, setFilteredFolderList] = useState<any[]>([]);
  const handleOpenSlider = (item?: any) => {
    if (getValue(item, `id`, "")) {
      setCannedEditId(getValue(item, `id`, ""));
      setCannedRequest((prev: any) => ({
        ...prev,
        title: getValue(item, `title`, ""),
        content: getValue(item, `content`, ""),
        folder_id: getValue(item, `folder_id`, ""),
        attachments: getValue(item, `attachments`, []),
        module_id: getValue(item, `module_id`, ""),
        pipeline_id: getValue(item, `pipeline_id`, ""),
      }));
    }
    if (getValue(item, `pipeline_id`, "") && getValue(item, `module_id`, "")) {
      tagsByPipelineID(
        getValue(item, `module_id`, ""),
        getValue(item, `pipeline_id`, "")
      );
    }
    setCannedSliderOpen(true);
  };

  const handleCannedSlider = () => {
    setCannedSliderOpen(!isCannedSliderOpen);
    setCannedRequest({
      title: "",
      content: "",
      folder_id: "",
      attachments: [],
      module_id: "",
      pipeline_id: "",
    });
    setCannedEditId("");
  };

  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getCannedResponseList(true);
  }, []);

  useEffect(() => {
    if (UrlParams.folder_id || UrlParams.page_no || UrlParams.page_size) {
      getCannedTableList(true);
    }
    if (selectedFolder.id) {
      getCannedTableList(true);
    }
  }, [
    UrlParams.folder_id,
    UrlParams.page_no,
    UrlParams.page_size,
    selectedFolder.id,
  ]);

  useEffect(() => {
    getGroups();
  }, []);

  // useEffect(() => {
  //   if (Object.keys(UrlParams).length > 0) {
  //     if (UrlParams.folderPageNo) {
  //       setFolderPageNo(parseInt(UrlParams.folderPageNo));
  //     }
  //     if (UrlParams.folderLimit) {
  //       setFolderLimit(parseInt(UrlParams.folderLimit));
  //     }
  //     getCannedResponseList(true);
  //   }
  // }, [window.location.href]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getCannedResponseList = async (status: boolean) => {
    try {
      setIsLoading(status);
      const payload = {
        page_size: 100,
        page_no: 1,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getAllCannedResponseFolders(queryRequest);
      if (resp) {
        // setFolderLimit(getValue(resp, "data.pagination.page_count", 0));
        // setTotalFolderCount(getValue(resp, "data.pagination.total", 0));
        const folders = getValue(resp, "data.folders", []);
        setList(getValue(resp, "data", []));
        if (getValue(resp, "data", [].length > 0)) {
          setSelectedFolder({
            id: getValue(resp, "data.folders[0].id", ""),
            name: getValue(resp, "data.folders[0].name", ""),
          });
        }
        const personalFolder = folders.find(
          (folder: any) => folder.is_personal === true
        );
        if (personalFolder) {
          setPersonalFolderId(personalFolder.id);
        }
        const nonPersonalFolders = folders.filter(
          (folder: any) => folder.is_personal !== true
        );
        setFilteredFolderList(
          getValue(nonPersonalFolders, `length`, 0) > 0
            ? nonPersonalFolders.map((item: object) => ({
                ...item,
                value: getValue(item, `id`, ""),
                label: getValue(item, `name`, ""),
              }))
            : []
        );
      }
    } catch (error) {
      message.error("Failed to load canned responses.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setConfirmLoading(true);
    try {
      const payload: { name: string; id?: string } = { name: request.name };
      const resp = await createCannedResponseFolder(payload);
      if (resp) {
        message.success("Created Successfully");
        setOpen(false);
        getCannedResponseList(true);
        setRequest({
          name: "",
        });
        setSelectedFolder({
          id: getValue(resp, "data.id", ""),
          name: getValue(resp, "data.name", ""),
        });
      } else {
        message.error("Failed to create folder");
      }
    } catch (error) {
    } finally {
      setConfirmLoading(false);
      setCreateLoading(false);
    }
  };

  const handleFolderUpdate = async () => {
    setConfirmLoading(true);
    try {
      const payload = { ...request };
      const resp = await updateCannedResponseFolder(editId, payload);
      if (resp) {
        message.success("Updated Successfully");
        setOpen(false);
        getCannedResponseList(true);
        setRequest({
          name: "",
        });
      } else {
        message.error("Failed to create folder");
      }
    } catch (error) {
      message.error("Error creating folder");
    } finally {
      setConfirmLoading(false);
      setCreateLoading(false);
    }
  };

  const handleDeleteModal = (id: string, name: string) => {
    Modal.confirm({
      title: `Are you sure you want to delete this folder - ${name}?`,
      content:
        "Once deleted, this folder and its canned responses cannot be recovered.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await deleteCannedResponseFolder(id);
          message.success("Folder deleted successfully");
          getCannedResponseList(true);
        } catch (error) {
          message.error("Error deleting folder");
        }
      },
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                           Canned table Section                             */
  /* -------------------------------------------------------------------------- */
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const getCannedTableList = async (status: boolean) => {
    try {
      setIsTableLoading(status);
      const payload = {
        folder_id: getValue(UrlParams, "folder_id", "")
          ? getValue(UrlParams, "folder_id", "")
          : selectedFolder.id,
        page_size: getValue(UrlParams, `page_size`, "")
          ? getValue(UrlParams, `page_size`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
        order_by: "created_at",
        order_type: "desc",
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getAllCannedFolderTableResponse(queryRequest);
      if (resp) {
        setTableData(getValue(resp, "data", []));
        setTotalCount(getValue(resp, "data.pagination.total", 0));
        setLimit(getValue(resp, "data.pagination.page_size", 0));
        setPage_no(getValue(resp, "data.pagination.page", 0));
      }
    } catch (error) {
      message.error("Failed to load canned responses.");
    } finally {
      setIsTableLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const handleChangePagination = (page_no: any, limit: any) => {
    setPage_no(page_no);
    setLimit(limit);

    let payload = {
      ...UrlParams,
      page_no: page_no,
      page_size: limit,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    setPage_no(page_no);
  };

  const handleCannedSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoading(true);
        let payload = JSON.parse(JSON.stringify(cannedRequest));
        const resp = cannedEditId
          ? await updateCannedResponse(cannedEditId, payload)
          : await createCannedResponse(payload);

        if (resp) {
          message.success(
            `${cannedEditId ? "Updated" : "Created"} Successfully`
          );
          simpleValidator.current.hideMessages();
          forceUpdate(0);
          setCannedSliderOpen(false);
          getCannedResponseList(true);
          getCannedTableList(true);
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                              Groups Section                             */
  /* -------------------------------------------------------------------------- */
  const getGroups = async () => {
    try {
      setGroupLoading(true);
      const payload = {
        page_size: 10,
        page_no: 1,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getGroupsList(queryRequest);
      if (resp) {
        const modifiedResp = getValue(resp, "data", []).map((item: any) => ({
          value: getValue(item, "id", ""),
          label: getValue(item, "name", ""),
        }));
        setGroups(modifiedResp);
      }
      setGroupLoading(false);
    } catch (error) {
      message.error("Failed to load groups");
      setGroupLoading(false);
    } finally {
      setGroupLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                              Modal Section                             */
  /* -------------------------------------------------------------------------- */
  const showModal = () => {
    setEditID("");
    setRequest({ name: "" });
    setOpen(true);
  };

  const handleOk = () => {
    if (getValue(request, "name", "")) {
      if (editId !== "") {
        handleFolderUpdate();
      } else {
        handleSubmit();
      }
    } else {
      message.error("Folder name required");
    }
  };

  const handleUpdateModal = (name: string, id: string) => {
    setRequest({ name });
    setEditID(id);
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    setRequest({
      name: "",
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                            Handle folder selection                         */
  /* -------------------------------------------------------------------------- */
  // const handlePaginationChange = (page: any, pageSize: any) => {
  //   setFolderPageNo(page);
  //   // setFolderLimit(pageSize);
  //   let payload = {
  //     folderPageNo: folderPageNo,
  //     // folderLimit: folderLimit,
  //   };
  //   let queryRequest = QueryRequestHelper(payload);
  //   navigate(`${window.location.pathname}?${queryRequest}`);
  // };

  const handleSelectFolder = (id: string, name: string) => {
    setSelectedFolder({
      id: id,
      name: name,
    });
    let payload = {
      // ...UrlParams,
      folder_id: id,
      page_no: 1,
      page_size: limit,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleDeletCannedResponse = (id: string) => {
    Modal.confirm({
      title: `Are you sure you want to delete this Canned response?`,
      content: "Once deleted, it cannot be recovered.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await deleteCannedResponse(id);
          message.success("Response deleted successfully");
          getCannedResponseList(true);
          getCannedTableList(true);
        } catch (error) {
          message.error("Error deleting folder");
        }
      },
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                            Create Canned Response                          */
  /* -------------------------------------------------------------------------- */
  const [allPipelinesList, setAllPipelineList] = useState([]);
  const getAllPipelines = async (module: string) => {
    if (module) {
      try {
        let allPipelines = await listAllPipelines(module, "");
        setCannedRequest((prevState: any) => ({
          ...prevState,
          pipeline_id: getValue(allPipelines, `data.[${0}].id`, ""),
        }));
        let list = getValue(allPipelines, `data`, []).map((item: object) => ({
          ...item,
          value: getValue(item, `id`, ""),
          label: getValue(item, `label`, ""),
        }));
        setAllPipelineList(list);
        tagsByPipelineID(module, getValue(allPipelines, `data.[${0}].id`, ""));
      } catch (error) {}
    }
  };

  const [allTags, setAllTags] = useState<any[]>([]);
  const [adminTags, setAdminTags] = useState([]);
  const tagsByPipelineID = async (module_id: string, id: string) => {
    setCannedRequest((prevState: any) => ({
      ...prevState,
      pipeline_id: id,
    }));

    if (module_id && id) {
      try {
        let payload = {
          pipelineId: id,
        };
        let queryRequest = QueryRequestHelper(payload);

        // Fetch data from both APIs
        let resp = await getAllAvailableTemplateTags(module_id, queryRequest);
        let response = await getloggedInuserTemplateTags();

        if (resp || response) {
          // Transform `resp` data
          let respList = getValue(resp, `data`, []).map((item: object) => ({
            ...item,
            placeHolderOld: getValue(item, `placeHolder`, ""),
            placeHolder: `[[${getValue(item, `name`, "")}]]`,
          }));

          // Transform `response` data
          let responseList = getValue(response, `data`, []).map(
            (item: object) => ({
              ...item,
              placeHolderOld: getValue(item, `placeHolder`, ""),
              placeHolder: `[[${getValue(item, `name`, "")}]]`,
            })
          );

          setAdminTags(responseList);
          setAllTags(respList);

          // // Combine both lists
          // let combinedList = [...responseList, ...respList];

          // const uniqueData = _.uniqBy(combinedList, "name");

          // // Set the unique data to state
          // setAllTags(uniqueData);
        }
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    } else {
      setAllTags([]);
    }
  };
  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        title="Canned Response"
        icon="canned"
        addChildren={
          <div className="d-flex align-items-center gap-3">
            <Info
              onClick={() => setCannedSliderOpen1(true)}
              size={18}
              className="cursor-pointer"
            />
            <Button
              type="primary"
              size="large"
              icon={<Plus size={20} />}
              onClick={() => handleOpenSlider()}
            >
              New Canned Response
            </Button>
          </div>
        }
      />
      {isLoading ? (
        <ListLoader />
      ) : (
        <div className="row w-100 m-0">
          <CannedFolders
            UrlParams={UrlParams}
            list={list}
            showModal={showModal}
            handleUpdateModal={handleUpdateModal}
            handleDeleteModal={handleDeleteModal}
            selectedFolder={selectedFolder.id}
            handleSelectFolder={handleSelectFolder}
            // folderLimit={folderLimit}
            // folderPageNo={folderPageNo}
            // totalFolderCount={totalFolderCount}
            // handlePaginationChange={handlePaginationChange}
          />
          <CannedTable
            list={list}
            tableData={tableData}
            isTableLoading={isTableLoading}
            handleDeletCannedResponse={handleDeletCannedResponse}
            page_no={page_no}
            limit={limit}
            totalCount={totalCount}
            handleChangePagination={handleChangePagination}
            handleOpenSlider={handleOpenSlider}
          />
        </div>
      )}
      <Modal
        title={`${getValue(request, "id", "") ? "Edit" : "Create New"} Folder`}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <Form
          onFinish={handleOk} // Triggers submission when Enter is pressed inside input
        >
          <InputRuleForm
            inputType="TEXT"
            name="name"
            value={getValue(request, "name", "")}
            placeholder="Enter Folder Name"
            required
            label="Folder Name"
            onChange={(e: any) =>
              setRequest({
                ...request,
                name: e.target.value,
              })
            }
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                e.preventDefault(); // Prevents form auto-refresh
                handleOk();
              }
            }}
          />
        </Form>
      </Modal>

      <Drawer
        title={`${cannedEditId ? "Edit" : "Create"} Canned Response`}
        placement="right"
        onClose={handleCannedSlider}
        open={isCannedSliderOpen}
        width={620}
        className="ant-slider-container-footer "
      >
        <Alert
          message={` ${getValue(selectedFolder, "name", "")}`}
          type="info"
          showIcon
          icon={<FolderClosed size={18} />}
          className="mb-2 w-fit-content"
        />
        {/* <div className="d-flex align-items-center gap-2 mb-3">
          <FolderClosed size={20} />
          <p className="small_text__15">
            {getValue(selectedFolder, "name", "")}
          </p>
        </div> */}
        <CannedResponseDetails
          request={cannedRequest}
          setRequest={setCannedRequest}
          personalFolderId={personalFolderId}
          filteredFolderList={filteredFolderList}
          selectedFolder={selectedFolder.id}
          editId={cannedEditId}
          setSubmitLoading={setSubmitLoading}
          groups={groups}
          validator={simpleValidator}
          forceUpdate={forceUpdate}
          pipelineId={getValue(cannedRequest, `pipeline_id`, "")}
          allPipelinesList={allPipelinesList}
          getAllPipelines={getAllPipelines}
          allTags={allTags}
          adminTags={adminTags}
          tagsByPipelineID={tagsByPipelineID}
        />
        {getValue(request, "title", "")}

        <AntSubmitContainer
          handleBackButton={() => handleCannedSlider()}
          handleSubmit={() => handleCannedSubmit()}
          // submitLoading={isSubmitLoading}
          first_title={"Cancel"}
          // disabled={
          //   getValue(cannedRequest, "title", "") === "" ||
          //   getValue(cannedRequest, "content.length", "") < 12
          // }
          // third_title={"Save "}
          // handleThirdButton={handleCannedSubmit}
          // second_title={isEdit ? "Update Filter" : "Save Filter"}
          permissions={getValue(props, `permissions`, [])}
        />
      </Drawer>

      <SlideFromRightPopup
        isPopupVisible={isCannedSliderOpen1}
        addCustomClass={"rolls-popup"}
        title={`Canned Responses`}
        toggle={() => setCannedSliderOpen1(false)}
        width={"420px"}
      >
        <div className="p-4">
          <p className="small_text__15">
            With Canned Responses, agents can use a predefined set of reply
            templates that they can send out with a single click. Creating
            canned responses can help avoid duplication of efforts, as agents
            can avoid repetitive typing of replies during customer
            conversations.
          </p>
          <p className="small_text__15 pt-3">
            For example, when a customer asks ’How to cancel my subscription?’,
            agents can simply insert the response template into their reply to
            improve their response time. You can even personalize canned
            responses by using placeholders that fill dynamic content like the
            requester’s name and ticket ID.
          </p>
        </div>
      </SlideFromRightPopup>
    </CompanyProfileDetailsLayout>
  );
};

export default CannedResponse;
