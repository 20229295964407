import React, { useState } from "react";
// import "../email.scss";
import { getValue } from "@utils/lodash";
import { formatString } from "@common/text-helpers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ButtonComponent from "@components/Form/Button/Button";

function ProfileNavViews(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [tabs] = useState([
    {
      value: "PROFILE",
      route: `/${getValue(params, `orgId`, "")}/profile`,
    },
    // {
    //   value: "EMAIL",
    //   route: `/${getValue(params, `orgId`, "")}/user-emails/config`,
    // },
  ]);
  return (
    <ul
      className="d-flex align-items-center justify-content-between px-5 pt-4"
      style={{ borderBottom: "1px solid #efefef" }}
    >
      <div className="d-flex align-items-center justify-content-start">
        {tabs.map((linkObj: object, index: number) => {
          return (
            <li
              key={`pending-${index}`}
              className={`pt-1 ${
                getValue(location, `pathname`, "") ==
                getValue(linkObj, `route`, "")
                  ? "activebar__main-body-list_active"
                  : "activebar__main-body-list"
              }`}
              onClick={() => navigate(getValue(linkObj, `route`, ""))}
            >
              <a className={"activebar__main-body-link p-2 "}>
                {formatString(getValue(linkObj, `value`, ""))}
              </a>
            </li>
          );
        })}
      </div>
    </ul>
  );
}

export default ProfileNavViews;
