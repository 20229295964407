import { getValue } from "@utils/lodash";
import React from "react";

function CategoryFilledSvgComponent(props: any) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"}
        height={
          getValue(props, `size`, "") ? getValue(props, `size`, "") : "24"
        }
        fill={
          getValue(props, `color`, "")
            ? getValue(props, `color`, "")
            : "#061F48"
        }
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 2l-5.5 9h11z" />
        <circle cx="17.5" cy="17.5" r="4.5" />
        <path d="M3 13.5h8v8H3z" />
      </svg>
    </div>
  );
}

export default CategoryFilledSvgComponent;
