export const findType = (type: string) => {
  switch (type) {
    case ".png":
    case ".jpg":
    case ".jpeg":
    case ".svg":
      return "image";
    case ".mp4":
      return "video";
    case ".mp3":
      return "audio";
    case ".docx":
    case ".pdf":
    case ".doc":
    case ".xls":
    case ".xlsx":
    case ".ppt":
    case ".pptx":
    case ".txt":
    case ".csv":
      return "document";
    default:
      return "image";
  }
};

export const getMimeType = (category: string) => {
  switch (category.toLowerCase()) {
    case "image":
      return "image/*";
    case "video":
      return "video/*";
    case "audio":
      return "audio/*";
    case "document":
      return "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, text/csv";
    default:
      return "*/*"; // Default to any file type
  }
};

export const formatFileSize = (size: number) => {
  if (!size) return "0 KB";
  return size < 1024 * 1024
    ? `${(size / 1024).toFixed(2)} KB`
    : `${(size / (1024 * 1024)).toFixed(2)} MB`;
};

export const allowedFormats = [
  ".jpg",
  ".jpeg",
  ".png",
  ".gif",
  ".mp4",
  ".mp3",
  ".mov",
  ".avi",
  ".pdf",
  ".docx",
  ".doc",
  ".xls",
  ".xlsx",
  ".csv",
  ".txt",
  ".pptx",
  ".ppt",
];

export const fileSizeLimits: Record<string, number> = {
  jpg: 5 * 1024 * 1024, // 5MB
  jpeg: 5 * 1024 * 1024, // 5MB
  png: 5 * 1024 * 1024, // 5MB
  gif: 5 * 1024 * 1024, // 5MB
  mp4: 16 * 1024 * 1024, // 16MB
  mp3: 16 * 1024 * 1024, // 16MB
  mov: 16 * 1024 * 1024, // 16MB
  avi: 16 * 1024 * 1024, // 16MB
  pdf: 100 * 1024 * 1024, // 100MB
  docx: 100 * 1024 * 1024, // 100MB
  doc: 100 * 1024 * 1024, // 100MB
  xls: 100 * 1024 * 1024, // 100MB
  xlsx: 100 * 1024 * 1024, // 100MB
  csv: 100 * 1024 * 1024, // 100MB
  txt: 100 * 1024 * 1024, // 100MB
  pptx: 100 * 1024 * 1024, // 100MB
  ppt: 100 * 1024 * 1024, // 100MB
};

/**
 * Validates if the file format is allowed.
 */
export const isValidFileFormat = (fileName: string): boolean => {
  const fileExt = `.${fileName.split(".").pop()?.toLowerCase() || ""}`;
  return allowedFormats.includes(fileExt);
};

/**
 * Checks if the file size is within the allowed limit.
 */
export const isValidFileSize = (file: File): boolean => {
  const fileExt = file.name.split(".").pop()?.toLowerCase() || "";
  const maxSize = fileSizeLimits[fileExt] || 5 * 1024 * 1024; // Default 5MB
  return file.size <= maxSize;
};

export const getFileSizeLimit = (fileExt: string): number => {
  return fileSizeLimits[fileExt] || 5 * 1024 * 1024; // Default 5MB if not specified
};
