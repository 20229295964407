import React, { useRef } from "react";
import Popup from "reactjs-popup";
import { getValue } from "@utils/lodash";

function TooltipOnHover(props) {
  const POSITION_TYPES = [
    "bottom right",
    "bottom center",
    "top center",
    "left top",
    "left bottom",
    "right top",
    "top left",
    "top center",
    "right center",
    "right bottom",
    "bottom left",
    "left center",
    "center center",
  ];

  const popupRef = useRef();
  const handleClose = (e) => {
    e.stopPropagation();
    if (popupRef.current) {
      popupRef.current.close();
    }
  };
  const handleOpen = () => {
    if (popupRef.current) {
      popupRef.current.open();
    }
  };

  const CustomButtons = React.forwardRef(({ open, ...props }, ref) => (
    <div
      // className="d-flex justify-content-between align-items-center cursor-pointer"
      {...props}
      ref={ref}
    >
      {props.header}
    </div>
  ));
  return (
    <Popup
      trigger={(open) => <CustomButtons open={open} header={props.header} />}
      position={POSITION_TYPES}
      closeOnDocumentClick
      on={
        getValue(props, `show`, false)
          ? "hover"
          : getValue(props, `text.length`, "") > 20
          ? "hover"
          : ""
      }
      contentStyle={{
        width: getValue(props, `width`, "")
          ? getValue(props, `width`, "")
          : "400px",
        minHeight: getValue(props, `height`, "")
          ? getValue(props, `height`, "")
          : "70px",
        zIndex: 9999,
        background: "#1F1F1F",
        boxShadow:
          "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
      }}
      nested
      ref={popupRef}
      onOpen={handleOpen}
      onClick={handleClose}
      arrowStyle={{
        color: "#1F1F1F",
      }}
    >
      <div onClick={handleClose} className="position-relative">
        <div className="p-2 position-relative small_text__13 text-white">
          {getValue(props, `text`, "")}
        </div>
      </div>
    </Popup>
  );
}

export default TooltipOnHover;
