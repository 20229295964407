import { getValue } from "@utils/lodash";
import "./header.scss";

import { Link } from "react-router-dom";
import SignInDropdownOrg from "../HeaderSignin/SignInDropdownOrg";
// import SignInDropdownOrg from "@components/Dashboard/v2/Header/SignInDropdown/SignInDropdownOrg";
// import { getValue } from "@utils/lodash";

export default function Header() {
  return (
    <header
      className={`login-header-wrapper d-flex justify-content-between align-items-center`}
    >
      <div className="container-fluid px-0">
        <Link
          to="/"
          className={`login-header-wrapper__logo cursor-pointer d-inline-block d-flex`}
        >
          <img
            src="/logo_ai.svg"
            className={` w-100 object-fit-contain py-2`}
            alt="APPZO AI"
          />
        </Link>
      </div>
      {getValue(localStorage, `accessToken`, "") && (
        <div style={{ marginRight: "40px" }}>
          <SignInDropdownOrg />
        </div>
      )}
    </header>
  );
}
