import React, { useState } from "react";
import { Checkbox, Drawer } from "antd";
import SubmitButtonContainer from "@components/common/ButtonContainer/SubmitButtonContainer";
import { getValue } from "@utils/lodash";
import InlineFormBuilder from "@components/Builder/InlineFormBuilder";
import FormBuilder from "@components/Builder/FormBuilder";

const TicketEditInfoDrawer = (props: any) => {
  const { isOpen, toggle, permissions } = props;

  return (
    <>
      <Drawer
        title="Edit Ticket"
        placement={"right"}
        onClose={toggle}
        open={isOpen}
        className="editinfo-drawer"
        width="40%"
      >
        <div className={``}>
          <FormBuilder
            fields={props.quickForm}
            setFields={props.setQuickForm}
          />
        </div>

        <SubmitButtonContainer
          handleBackButton={() => {
            props.setIsOpen(false);
          }}
          handleSubmit={() => {
            props.handleSubmit();
          }}
          submitLoading={props.submitLoading}
          disabled={props.submitLoading}
          first_title={"Back"}
          second_title={"Save"}
          hideNew
          permissions={["create", "update"]}
          classname="w-40"
        />
      </Drawer>
    </>
  );
};

export default TicketEditInfoDrawer;
