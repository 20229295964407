import { getAgentAnalysisDashboard } from "@services/dashboard.service";
import { dateFilters } from "@common/Date-Helpers/date-filters-helpers";
import { QueryRequestHelper } from "@common/query-request-helper";
import { usePermissionContext } from "@context/permissionContext";
import { getValue } from "@utils/lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Table, Input, Select, Button, Avatar } from "antd";
import "./DashboardAgentPerformance.scss"; // Optional: For custom styling
import { useLocation, useNavigate } from "react-router-dom";
import SidebarLayout from "@layouts/SidebarLayout/SidebarLayout";
import DashboardHeader from "../../CRM/components/header";
import ProfileCircle from "@components/common/ProfileCircle";
import { useStateContext } from "@context/dataContext";
const { Search } = Input;
const { Option } = Select;

function DashboardAgentPerformance(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const location = useLocation();
  const { permissionAPITriggered } = usePermissionContext();
  const { refreshBookmarks, bookmarkedModules, themeColor } = useStateContext();
  const navigate = useNavigate();

  const [agentAnalysisDashboardData, setAgentAnalysisDashboardData] = useState(
    []
  );
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<any>({
    current: getValue(UrlParams, "page_no", 1),
    pageSize: getValue(UrlParams, "page_size", 50),
    total: 0,
  });
  const [filters, setFilters] = useState({
    filterMessagedAt: getValue(
      UrlParams,
      "filter_messaged_at",
      getValue(dateFilters, `[0].value`, "")
    ),
    order: getValue(UrlParams, "order", "asc"),
    search: getValue(UrlParams, "search", ""),
  });

  useEffect(() => {
    if (
      (Object.keys(UrlParams).length === 0 || permissionAPITriggered) &&
      !loading
    ) {
      getAgentAnalysisData();
    }
  }, [permissionAPITriggered]);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0 && !loading) {
      getAgentAnalysisData();
    }
  }, [
    window.location.href,
    getValue(location, "key", ""),
    permissionAPITriggered,
  ]);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPagination((prev: any) => ({
          ...prev,
          current: parseInt(UrlParams.page_no),
        }));
      }
      if (UrlParams.limit) {
        setPagination((prev: any) => ({
          ...prev,
          pageSize: parseInt(UrlParams.limit),
        }));
      }
      getAgentAnalysisData();
    }
  }, [window.location.href]);

  const getAgentAnalysisData = useCallback(async () => {
    setLoading(true);
    try {
      const payload = {
        filter_messaged_at: getValue(UrlParams, `date_range`, "")
          ? getValue(UrlParams, `date_range`, "")
          : getValue(dateFilters, `[${0}].value`, ""),
        order: filters.order,
        search: filters.search ? filters.search : "",
        page_no: getValue(UrlParams, `page_no`, "") 
          ? getValue(UrlParams, `page_no`, "")
          : pagination.current || 1,
        page_size: getValue(UrlParams, `limit`, "")
          ? getValue(UrlParams, `limit`, "")
          : pagination.pageSize || 50,
      };
      const queryRequest = QueryRequestHelper(payload);
      const response = await getAgentAnalysisDashboard(queryRequest);
      if (response) {
        setAgentAnalysisDashboardData(
          getValue(response, "data.agents", []).map((item: object) => ({
            ...item,
            fullName: `${getValue(item, "first_name", "")} ${getValue(
              item,
              "last_name",
              ""
            )}`,
            email: getValue(item, "email", ""),
            phone: getValue(item, "phone", ""),
            assigned_conversations: getValue(
              item,
              "conversation_counts.assigned_conversations",
              0
            ),
            resolved_conversations: getValue(
              item,
              "conversation_counts.resolved_conversations",
              0
            ),
            first_response_time: getValue(
              item,
              "conversation_counts.first_response_time",
              ""
            ),
            resolution_time: getValue(
              item,
              "conversation_counts.resolution_time",
              ""
            ),
          }))
        );
        setPagination({
          ...pagination,
          total: getValue(response, "data.pagination.total", 0),
        });
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [pagination]);

  const getAgentAnalysisDataPagination = async (
    order: string,
    search: string,
    filterMessagedAt: string,
    page_no: number,
    pageSize: number
  ) => {
    setLoading(true);
    try {
      const payload = {
        filter_messaged_at: filterMessagedAt,
        order: order,
        search: search,
        page_no: page_no,
        page_size: pageSize,
      };
      const queryRequest = QueryRequestHelper(payload);
      const response = await getAgentAnalysisDashboard(queryRequest);
      if (response) {
        setAgentAnalysisDashboardData(
          getValue(response, "data.agents", []).map((item: object) => ({
            ...item,
            fullName: `${getValue(item, "first_name", "")} ${getValue(
              item,
              "last_name",
              ""
            )}`,
            email: getValue(item, "email", ""),
            phone: getValue(item, "phone", ""),
            assigned_conversations: getValue(
              item,
              "conversation_counts.assigned_conversations",
              0
            ),
            resolved_conversations: getValue(
              item,
              "conversation_counts.resolved_conversations",
              0
            ),
            first_response_time: getValue(
              item,
              "conversation_counts.first_response_time",
              ""
            ),
            resolution_time: getValue(
              item,
              "conversation_counts.resolution_time",
              ""
            ),
          }))
        );
        setPagination({
          ...pagination,
          total: getValue(response, "data.pagination.total", 0),
        });
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handlePaginationChange = (page: number, pageSize?: number) => {
    const newPageSize = pageSize || pagination.pageSize;

    setPagination((prev: any) => ({
      ...prev,
      current: page,
      pageSize: newPageSize,
    }));

    let payload = {
      ...UrlParams,
      page_no: page,
      page_size: newPageSize,
    };

    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleTableChange = (pagination: any) => {
    setPagination({
      ...pagination,
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const handleFilterChange = (key: string, value: any) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
    setPagination({ ...pagination, current: 1 }); // Reset to first page on filter change
  };

  const fullName = (firstName: string, lastName: string) => {
    return `${firstName} ${lastName}`;
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "fullName",
      key: "fullName",
      render: (text: string, record: any) => (
        <div className="agent-name d-flex align-items-center">
          <ProfileCircle value={record.fullName} />
          <span className="ms-2">{record.fullName}</span>
        </div>
      ),
    },
    {
      title: "FIRST RESPONSE TIME",
      dataIndex: "first_response_time",
      key: "first_response_time",
      render: (text: string, record: any) => (
        <span>
          {record.first_response_time ? record.first_response_time : ""}
        </span>
      ),
    },
    {
      title: "RESOLUTION TIME",
      dataIndex: "resolution_time",
      key: "resolution_time",
      render: (text: string, record: any) => (
        <span>{record.resolution_time ? record.resolution_time : ""}</span>
      ),
    },
    {
      title: "ASSIGNED CONVERSATIONS",
      dataIndex: "assigned_conversations",
      key: "assigned_conversations",
      width: 200,
    },
    {
      title: "RESOLVED CONVERSATIONS",
      dataIndex: "resolved_conversations",
      key: "resolved_conversations",
      width: 200,
    },
  ];
  return (
    <SidebarLayout {...props} isLoading={loading}>
      <DashboardHeader
        hideCloseDate={false}
        allModules={[]}
        setSelectedModule={() => {}}
        setSelectedPipeline={() => {}}
        setSelectedDateRange={() => {}}
        setSelectedFilter={() => {}}
        setSelectedView={() => {}}
        setSelectedUser={() => {}}
        showSourceFilter={true}
        view={props.view}
        setView={props.setView}
        setChatView={props.setChatView}
        viewList={props.viewList}
        refreshBookmarks={refreshBookmarks}
        bookmarkedModules={bookmarkedModules}
        themeColor={themeColor}
        bookmarkedModuleName="dashboard"
        bookmarkedModulePath="dashboard"
        {...props}
      />
      <div className="dashboard-container-performace">
        <div className="d-flex align-items-center mb-2">
          <div className="d-flex flex-column">
            <label className="form-label">Search:</label>
            <Search
              value={filters.search}
              onChange={(e) => {
                handleFilterChange("search", e.target.value);
                getAgentAnalysisDataPagination(
                  filters.order,
                  e.target.value,
                  filters.filterMessagedAt,
                  pagination.current,
                  pagination.pageSize
                );
              }}
              onSearch={getAgentAnalysisData}
              placeholder="Search agents"
            />
          </div>
          <div className="d-flex flex-column ms-3">
            <label className="form-label">Order:</label>
            <Select
              value={filters.order}
              onChange={(value) => {
                handleFilterChange("order", value);
                getAgentAnalysisDataPagination(
                  value,
                  filters.search,
                  filters.filterMessagedAt,
                  pagination.current,
                  pagination.pageSize
                );
              }}
              //   style={{ width: 120 }}
            >
              <Option value="asc">asc</Option>
              <Option value="desc">desc</Option>
            </Select>
          </div>

          {/* <Button
            type="primary"
            onClick={getAgentAnalysisData}
            style={{ marginTop: 30 }}
            className="ms-2"
          >
            Apply Filters
          </Button> */}
        </div>
        <Table
          columns={columns}
          dataSource={agentAnalysisDashboardData}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            onChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
            showSizeChanger: true,
            pageSizeOptions: [10, 20, 50, 100],
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          loading={loading}
          onChange={handleTableChange}
          scroll={{ x: "max-content", y: "calc(100vh - 280px)" }}
          rowKey="key"
          className="mt-3"
        />
      </div>
    </SidebarLayout>
  );
}

export default DashboardAgentPerformance;
