import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Radio,
  Checkbox,
  Button,
  Space,
  Modal,
  Row,
  Col,
} from "antd";
import { useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { createRazorpayPaymentLink } from "@services/payment-integrations.service";
import { toast } from "sonner";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";

const DealCreatePaymentLinkFormModel = (props: any) => {
  const { visible, handleOk, onCancel, selectBefore } = props;
  const [form] = Form.useForm();
  const params = useParams();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [amount, setAmount] = useState<number | null>(null);
  // Set initial values when props.data changes
  useEffect(() => {
    if (props.contactInfo) {
      const data = getValue(props, "contactInfo.deals", []).find(
        (item: any) => item.id === props.deal_id
      );
      const initialAmount =
        parseFloat(getValue(data, "amount_due", 0)) > 0
          ? parseFloat(getValue(data, "amount_due", 0))
          : 0;
      setAmount(initialAmount);
      console.log(data);
      form.setFieldsValue({
        customer_name:
          getValue(data, "contacts.0.first_name", "") !== "null" &&
          getValue(props.data, "contacts.0.first_name", "") !== null &&
          getValue(props.data, "contacts.0.first_name", "") !== ""
            ? getValue(props.data, "contacts.0.first_name", "")
            : "" +
              " " +
              (getValue(props.data, "contacts.0.last_name", "") !== "null" ||
              getValue(props.data, "contacts.0.last_name", "") !== null ||
              getValue(props.data, "contacts.0.last_name", "") !== ""
                ? getValue(props.data, "contacts.0.last_name", "")
                : ""),
        customer_email: getValue(props.data, "contacts.0.email", ""),
        customer_phone: getValue(props.data, `contacts.0.phone_number`, "")
          ? getValue(props.data, `contacts.0.phone_number`, "")
          : getValue(props.data, "contacts.0.mobile_number", ""),
        amount: initialAmount,
        link_type: "standard",
      });
    }
  }, [props.contactInfo, form, visible]);

  const onFinish = async (values: any) => {
    const formattedValues = {
      amount: values.amount || 0,
      payment_method: values.link_type === "upi" ? "upi" : "standard",
      customer_name: values.customer_name,
      customer_email: values.customer_email,
      customer_phone: values.customer_phone,
      description: values.description,
      notify_sms: values.notify_sms || false,
      notify_email: values.notify_email || false,
      deal_id: getValue(props, `deal_id`, "")
        ? getValue(props, `deal_id`, "")
        : getValue(params, `id`, ""),
    };

    try {
      setSubmitLoading(true);
      const resp = await createRazorpayPaymentLink(
        removeNullOrUndefinedProperties(formattedValues)
      );
      if (resp) {
        toast.success("Payment link created successfully");
        form.resetFields();
        setSubmitLoading(false);
        setAmount(null);
        onCancel();
        props.setTriggerPaymentList(true);
        props.setPaymentListVisible(true);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
      toast.error("Failed to create payment link");
    }
  };

  const handleModalCancel = () => {
    form.resetFields();
    setAmount(null);
    props.onCancel();
  };

  // Update amount state when amount field changes
  const handleAmountChange = (value: number | null) => {
    setAmount(value);
  };

  const isSaveDisabled = submitLoading || !amount || amount <= 0;

  const footerButtons = [
    <Button key="cancel" onClick={handleModalCancel}>
      Cancel
    </Button>,
    <Button
      key="submit"
      type="primary"
      loading={submitLoading}
      onClick={() => form.submit()}
      disabled={isSaveDisabled}
    >
      Create
    </Button>,
  ];

  return (
    <Modal
      title="Create Razorpay Payment Link"
      open={visible}
      onCancel={handleModalCancel}
      okText="Create"
      footer={footerButtons}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          link_type: "standard",
          notify_email: false,
          notify_sms: false,
        }}
      >
        <Form.Item
          label="Amount"
          name="amount"
          rules={[
            { required: true, message: "Amount is required" },
            {
              validator: (_, value) =>
                value > 0
                  ? Promise.resolve()
                  : Promise.reject("Amount must be greater than 0"),
            },
          ]}
        >
          <InputNumber
            className="w-full"
            addonBefore={
              <Form.Item name="currency" noStyle initialValue="₹">
                <span>₹</span>
              </Form.Item>
            }
            placeholder="Amount"
            onChange={handleAmountChange}
            min={0}
          />
        </Form.Item>

        <Form.Item
          label="Customer Name"
          name="customer_name"
          rules={[
            { required: true, message: "Please enter customer name" },
            { min: 2, message: "Name must be at least 2 characters" },
            {
              pattern: /^[a-zA-Z\s]+$/, // Allows only alphabets and spaces
              message: "Name cannot contain special characters or emojis",
            },
          ]}
        >
          <Input placeholder="Customer Name" />
        </Form.Item>

        <Form.Item label="Payment for" name="description">
          <Input.TextArea placeholder="Description" />
        </Form.Item>

        <div className="mb-2">
          <Row className="w-full gap-1">
            <Col span={12}>
              <div className="w-100">
                <Form.Item
                  name="customer_email"
                  label="Email"
                  rules={[
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                      pattern:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value && !getFieldValue("customer_phone")) {
                          return Promise.reject(
                            "Either Email or Phone Number is required"
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
              </div>
            </Col>
            <Col span={11}>
              <div className="">
                <Form.Item
                  name="customer_phone"
                  label="Phone Number"
                  rules={[
                    {
                      pattern: /^(\+?[0-9]{1,3})?[0-9]{10,12}$/,
                      message: "Please enter a valid phone number",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value && !getFieldValue("customer_email")) {
                          return Promise.reject(
                            "Either Email or Phone Number is required"
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input placeholder="919876543210" />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </div>

        <Space className="mb-2">
          <Form.Item name="notify_email" valuePropName="checked">
            <Checkbox>Notify via email</Checkbox>
          </Form.Item>
          <Form.Item name="notify_sms" valuePropName="checked">
            <Checkbox>Notify via SMS</Checkbox>
          </Form.Item>
        </Space>

        <Form.Item
          label="Link type"
          name="link_type"
          required
          rules={[{ required: true, message: "Please select link type" }]}
        >
          <Radio.Group>
            <Space>
              <Radio value="upi">Create UPI Link</Radio>
              <Radio value="standard">Create Standard Link</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        <Form.Item hidden name="deal_id">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DealCreatePaymentLinkFormModel;
