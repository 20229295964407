import PropertySettingsNavigation from "@layouts/PropertyLayout/PropertySettingsNavigation";

const PropertySettingsLayout = ({ children, setActiveTab }: any) => {
  return (
    // <PropertyDetailsLayout setActiveTab={setActiveTab}>
    <div className="d-flex w-100">
      <PropertySettingsNavigation />
      <div className={"manageFieldRight overflow-y-auto px-4 pt-4"}>
        {children}
      </div>
    </div>
    // </PropertyDetailsLayout>
  );
};

export default PropertySettingsLayout;
