import { Image } from "antd";
import { getValue } from "@utils/lodash";
import { convertCurrentDateToTime } from "@common/date-helpers";
import WhatsappMessageLoader from "@components/common/Loader/whatsapp-message-loader";
import { Image as ImageIcon } from "lucide-react";
import { replaceDefaultPlaceholders } from "@common/markdown/markdown-helper";

const ImagePreview = ({ item, imageUrl, getReadIcon }) => {
  return (
    <>
      <div>
        {imageUrl ? (
          getValue(item, `media_caption`, "") ? (
            <Image
              src={imageUrl}
              alt="Preview"
              className="whatsapp_file_with_text"
            />
          ) : (
            <Image src={imageUrl} alt="Preview" className="whatsapp_file" />
          )
        ) : (
          <div className="whatsapp-image-loader">
            <WhatsappMessageLoader
              icon={<ImageIcon size={20} color="#7e7e7e" />}
            />
          </div>
        )}
      </div>
      {getValue(item, `media_caption`, "") && (
        // <p className="small_text__14 text-start mt-2 live-chat-image-caption">
        //   {getValue(item, `media_caption`, "")}
        // </p>
        <p
          dangerouslySetInnerHTML={{
            __html: replaceDefaultPlaceholders(
              getValue(item, `media_caption`, "")
            ),
          }}
          className="small_text__14 text-start mt-2 live-chat-image-caption"
        />
      )}
      <p className="send-time p-1 mt-1 d-flex justify-content-end">
        {convertCurrentDateToTime(getValue(item, `created_at`, ""))}
        {getValue(item, "is_private", "") === false && (
          <span className="ms-2">{getReadIcon(item)}</span>
        )}
      </p>
    </>
  );
};

export default ImagePreview;
