import { convertCurrentDate } from "@common/date-helpers";
import { concatString } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Dropdown, Table, Tooltip } from "antd";
import { MoreVertical, Pencil, Trash2 } from "lucide-react";

const UrlListTable = ({
  urlList,
  handleEditUrl,
  handleDeleteModal,
  page_no,
  limit,
  totalCount,
  handleChangePagination,
  isLoading,
  permissions,
}: {
  urlList: any;
  handleEditUrl: (item: any) => void;
  handleDeleteModal: (id: string, name: string) => void;
  page_no: number;
  limit: number;
  totalCount: number;
  handleChangePagination: any;
  isLoading: boolean;
  permissions: string[];
}) => {
  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (text: string) => (
        <Tooltip title={text}>
          <a
            href={`${text.startsWith("http") ? "" : "https://"}${text}`}
            target="_blank"
            rel="noreferrer"
          >
            {concatString(text, 50)}
          </a>
        </Tooltip>
      ),
    },
    {
      title: "ADDED BY",
      dataIndex: "created_by",
      key: "created_by",
      render: (text: string, record: any) => (
        <p>
          {getValue(record, "created_by.first_name", "")}{" "}
          {getValue(record, "created_by.last_name", "")}
        </p>
      ),
    },
    {
      title: "UPDATED ON",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (updated_at: any) => convertCurrentDate(updated_at),
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (text: string, record: any) => (
        <Dropdown
          menu={{
            items: [
              {
                key: "1",
                label: "Edit",
                icon: <Pencil size={16} />,
                onClick: () => handleEditUrl(record),
              },
              permissions.includes("delete")
                ? {
                    key: "2",
                    label: "Delete",
                    icon: <Trash2 size={16} />,
                    onClick: () =>
                      handleDeleteModal(
                        getValue(record, "id", ""),
                        getValue(record, "name", "")
                      ),
                  }
                : null,
            ].filter(Boolean),
          }}
        >
          <MoreVertical size={18} className="cursor-pointer" />
        </Dropdown>
      ),
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={urlList}
      pagination={{
        current: page_no,
        pageSize: limit,
        total: totalCount,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50"],
        onChange: (page_no, pageSize) => {
          handleChangePagination(page_no, pageSize);
        },
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
      }}
      scroll={{ y: "calc(100vh - 324px)", x: "max-content" }}
      loading={isLoading}
    />
  );
};

export default UrlListTable;
