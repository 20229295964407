import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { useStateContext } from "@context/dataContext";
import SignInDropdownOrg from "./SignInDropdownOrg";
import OrganizationListPopup from "./OrgListPopup";
import ArrowRightSvgComponent from "assets/svg/arrow-right";
import { useState } from "react";
import NotificationCenter from "@common/notification-center";
import { concatString } from "@common/text-helpers";
import TooltipOnHover from "@components/common/Tooltip/tooltip-on-hover";
import "./SignInDropdown.scss";
import { getFirstLetterOfWord } from "@common/text-helpers";
import { Divider, Modal, Popover } from "antd";
import { CloseCircleFilled, SettingOutlined } from "@ant-design/icons";
import { usePermissionContext } from "@context/permissionContext";
import CompanyNavigationList from "@layouts/SettingsLayout/left-menu-tabs";
import { ChevronDown, ChevronsUpDown, House, Settings } from "lucide-react";
import { Dropdown, Menu, Button } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { updateOrgUsers } from "@services/users.service";
import ProfileDetailsPopover from "./ProfileDetailsPopover";

export default function HeaderSignin(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { orgInfo, userId, profileInfo, statusList, getUserIDCallback } =
    useStateContext();
  const [clicked, setClicked] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const togglePopupVisible = () => {
    setIsPopupVisible(!isPopupVisible);
  };
  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const { userInfo, themeColor } = useStateContext();
  const { tabPermissionList } = usePermissionContext();
  let navigationItems = CompanyNavigationList();

  const handleStatusChange = async (id: string) => {
    try {
      let resp = await updateOrgUsers({ status_id: id });
      if (resp) {
        getUserIDCallback();
      }
    } catch (error) {}
  };
  const menu = (
    <Menu>
      {statusList.map((item: any, index: number) => (
        <Menu.Item
          key={index}
          onClick={() => handleStatusChange(getValue(item, `id`, ""))}
        >
          <div className="d-flex align-items-center gap-2">
            <span className="w-10">
              {["Available", "Unavailable"].includes(
                getValue(item, "name", "")
              ) ? (
                getValue(item, "name", "") === "Available" ? (
                  <CheckCircleFilled style={{ color: "#009A00" }} size={16} />
                ) : (
                  <CloseCircleFilled style={{ color: "red" }} size={16} />
                )
              ) : (
                getValue(item, "emoji", "")
              )}
            </span>
            <span className="status-item ms-1">
              {getValue(item, `name`, "")}
            </span>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div>
      <div className="d-flex align-items-center">
        <div className="status-switcher mx-2">
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button color="default" variant="text">
              <div className="d-flex align-items-center gap-2">
                <span className="w-10">
                  {["Available", "Unavailable"].includes(
                    getValue(profileInfo, "status.name", "")
                  ) ? (
                    getValue(profileInfo, "status.name", "") === "Available" ? (
                      <CheckCircleFilled
                        style={{ color: "#009A00" }}
                        size={16}
                      />
                    ) : (
                      <CloseCircleFilled style={{ color: "red" }} size={16} />
                    )
                  ) : (
                    getValue(profileInfo, "status.emoji", "")
                  )}
                </span>
                <span className="status-item ms-1">
                  {getValue(profileInfo, `status.name`, "")}
                </span>
                <ChevronDown size={16} />
              </div>
            </Button>
          </Dropdown>
        </div>
        <Divider
          type="vertical"
          style={{
            height: "24px",
          }}
        />
        <div className="d-flex align-items-center gap-2 px-2">
          <Button
            color="default"
            variant="text"
            onClick={() => navigate(`/${getValue(params, "orgId", "")}/home`)}
            icon={
              <House
                size={20}
                color={
                  location.pathname === `/${getValue(params, "orgId", "")}/home`
                    ? themeColor
                    : "#2c3e50"
                }
              />
            }
          />

          {tabPermissionList.includes("settings") && (
            <Button
              color="default"
              variant="text"
              onClick={() =>
                navigate(
                  getValue(
                    navigationItems,
                    `[${0}].linkArray[${0}].linkUrl`,
                    ""
                  )
                    ? getValue(
                        navigationItems,
                        `[${0}].linkArray[${0}].linkUrl`,
                        ""
                      )
                    : `/${getValue(
                        params,
                        `orgId`,
                        ""
                      )}/settings/general/company-profile`
                )
              }
              icon={<Settings size={20} />}
            />
          )}
          <li>
            <NotificationCenter userId={userId} />
          </li>
        </div>
        <Divider
          type="vertical"
          style={{
            height: "24px",
          }}
        />
        <div className="px-1">
          <OrganizationListPopup
            header={
              <TooltipOnHover
                header={
                  <Button
                    color="default"
                    variant="text"
                    icon={<ChevronsUpDown size={16} />}
                    iconPosition="end"
                  >
                    {concatString(getValue(orgInfo, `name`, ""), 18)}
                  </Button>
                }
                text={getValue(orgInfo, `name`, "")}
              />
              // <div className="d-flex align-items-center">
              //   <p className="org_name">{concatString(getValue(orgInfo, `name`, ""),18)}</p>
              //   <ArrowRightSvgComponent size={24} color={"white"} />
              // </div>
            }
            clicked={clicked}
            setClicked={setClicked}
            isPopupVisible={isPopupVisible}
          />
        </div>
        <Divider
          type="vertical"
          style={{
            height: "24px",
          }}
        />
        <li onClick={togglePopupVisible}>
          <div className={"signin"}>
            <span className={"signin-name"}>
              {getFirstLetterOfWord(getValue(userInfo, `first_name`, ""))}
            </span>
          </div>
        </li>
        {/* <ProfileDetailsPopover userInfo={userInfo} /> */}
      </div>
      <SignInDropdownOrg
        isPopupVisible={isPopupVisible}
        setIsPopupVisible={setIsPopupVisible}
        togglePopupVisible={togglePopupVisible}
        openModal={openModal}
        toggleModal={toggleModal}
        // navigateToProfile={navigateToProfile}
      />
      <Modal
        open={openModal}
        onCancel={toggleModal}
        onOk={toggleModal}
        footer={
          [
            // <Button key="cancel" onClick={toggleModal} className="cancel__button">
            //   Cancel
            // </Button>,
          ]
        }
        className="app-download-modal p-5"
      >
        <section className="">
          <div className="">
            <div className="m-3 ">
              <h5 className="header_text__18">
                Scan the QR Code to start working on mobile
              </h5>
              <p className="small_text__14">
                Empower field agents on the go to deliver better, faster
                service.
              </p>
            </div>
            {/* <ButtonComponent
              buttonType={"delete"}
              buttonText={"Close"}
              onClickHandler={toggleModal}
            /> */}
          </div>
        </section>
      </Modal>
    </div>
  );
}
