import { getValue } from "@utils/lodash";
import React, { ChangeEvent } from "react";
import "./input.scss";
import MP3PlayerComponent from "@components/common/Tooltip/mp3-player";
import AudioSvgComponent from "@assets/svg/audio";

export default function InlineInput(props: any) {
  const {
    disabled,
    name,
    type,
    value,
    onChange,
    maxLength,
    placeholder,
    validator,
    label,
    mandatory,
    hideLabel,
    validatoryType,
    showWhatsapp,
    onBlur,
  } = props;
  return (
    <div>
      {!hideLabel && (
        <div className="d-flex justify-content-between align-items-center">
          <label className={`form-label-inline`}>
            {label} {mandatory && "*"}
          </label>
          {props.api_name === "recording_url" && value ? (
            <MP3PlayerComponent
              autoPlay
              url={value}
              onPlay={(e: any) => {}}
              header={<AudioSvgComponent color={"#f14b62"} />}
            />
          ) : (
            ""
          )}
          {showWhatsapp && getValue(value, `length`, 0) === 10 && (
            <img
              src="/images/whatsapp.png"
              height={25}
              width={25}
              onClick={() =>
                getValue(value, `length`, 0) === 10
                  ? window.open(
                      `https://api.whatsapp.com/send?text=Hi&phone=+91${value}`
                    )
                  : null
              }
              alt=""
            />
          )}
        </div>
      )}
      <div>
        <input
          className="inline_form_input"
          // variant="filled"
          disabled={false || disabled}
          name={name}
          type={type}
          value={value ? value : ""}
          onChange={(e) => onChange(e)}
          placeholder={placeholder}
          maxLength={maxLength}
          onBlur={onBlur}
          // className="mb-2"
        />
      </div>
    </div>
  );
}
