import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllTickets = (queryRequest: string) =>
  get(`${config.API_URL}/records/tickets?${queryRequest}`);

export const getAllKanbanTickets = (queryRequest: string) =>
  get(`${config.API_URL}/records/tickets/kanban-view?${queryRequest}`);

export const getListOfAllTickets = (queryRequest: string) =>
  get(`${config.API_URL}/records/tickets/list?${queryRequest}`);

export const getSpecificTicket = (id: string) =>
  get(`${config.API_URL}/records/tickets/${id}`);

export const createTicket = (payload: object) =>
  post(`${config.API_URL}/records/tickets`, payload);

export const updateTicket = (id: string, payload: object) =>
  patch(`${config.API_URL}/records/tickets/${id}`, payload);

export const deleteTicket = (id: string) =>
  Delete(`${config.API_URL}/records/tickets/${id}`);

export const TicketsBulkUpdate = (payload: object) =>
  patch(`${config.API_URL}/records/tickets/bulk-update`, payload);

export const TicketsSheetUpdate = (payload: object) =>
  patch(`${config.API_URL}/records/tickets/sheet-view`, payload);

//------------------------ info ----------------------//

export const ticketConversation = (id: string,queryRequest: string) =>
  get(`${config.API_URL}/records/tickets/${id}/conversations?${queryRequest}`);

//------------ Association/Disassociation -------------//

export const associateTicket = (id: string, payload: object) =>
  post(`${config.API_URL}/records/tickets/${id}/associations/add`, payload);

export const disAssociateTicket = (id: string, payload: object) =>
  post(`${config.API_URL}/records/tickets/${id}/associations/remove`, payload);

export const mergeTicket = (payload: object) =>
  post(`${config.API_URL}/records/tickets/merge`, payload);

//-------------------- watch/unwatch -------------------//

export const watchTicket = (id: string, payload: object) =>
  patch(`${config.API_URL}/records/tickets/${id}/watch`, payload);

export const unwatchTicket = (id: string, payload: object) =>
  patch(`${config.API_URL}/records/tickets/${id}/unwatch`, payload);

//------------------------ notes ----------------------//

export const addTicketNote = (id: string, payload: object) =>
  post(`${config.API_URL}/records/tickets/${id}/notes`, payload);

export const updateTicketNote = (id: string, payload: object) =>
  patch(`${config.API_URL}/records/tickets/notes/${id}`, payload);

export const deleteTicketNote = (id: string) =>
  Delete(`${config.API_URL}/records/tickets/notes/${id}`);
