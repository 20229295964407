import { ApiOutlined } from "@ant-design/icons";
import { ChevronRight } from "lucide-react";

function ActionsSidebar(props: any) {
  const { activeTab, setActiveTab } = props;
  return (
    <aside className="sidebar">
      {/* <Title level={2}>Connect</Title> */}
      <div className="menu-items">
        <div
          className={`menu-item ${activeTab === 1 ? "active" : ""}`}
          onClick={() => setActiveTab(1)}
        >
          <ChevronRight size={16} />
          <span>Actions</span>
        </div>
        <div
          className={`menu-item ${activeTab === 2 ? "active" : ""}`}
          onClick={() => setActiveTab(2)}
        >
          <ApiOutlined />
          <span>Integrations</span>
        </div>
      </div>
    </aside>
  );
}

export default ActionsSidebar;
