import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";
import { getValue } from "@utils/lodash";
import { Collapse, CollapseProps } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getFormAgentAction } from "@services/agent-actions.service";
import { caFormActionRequest } from "../action-request-helper";
import FAGenral from "./General";
import FAMessages from "./Messages";
import FAFields from "./Fields";
import SidebarLayout from "@layouts/SidebarLayout/SidebarLayout";

const FormAction = () => {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (getValue(UrlParams, `action_id`, "")) {
      getActionData();
    }
  }, [window.location.href]);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const [request, setRequest] = useState(caFormActionRequest);
  const [info, setInfo] = useState({});
  const reset = () => {
    setRequest(caFormActionRequest);
  };

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const getActionData = async () => {
    try {
      let resp = await getFormAgentAction(getValue(UrlParams, `action_id`, ""));
      if (resp) {
        setInfo(getValue(resp, `data`, {}));
        setIsLoading(false);
        setRequest({
          ...request,
          name: getValue(resp, `data.name`, ""),
          description: getValue(resp, `data.description`, ""),
          instruction: getValue(resp, `data.instruction`, ""),
          status: getValue(resp, `data.status`, ""),
          config: {
            ignore_message: getValue(
              resp,
              `data.form_action_config.ignore_message`,
              ""
            ),
            success_message: getValue(
              resp,
              `data.form_action_config.success_message`,
              {}
            ),
            name: getValue(resp, `data.form_action_config.name`, {}),
            email: getValue(resp, `data.form_action_config.email`, {}),
            phone_number: getValue(
              resp,
              `data.form_action_config.phone_number`,
              {}
            ),
          },
        });
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const onChange = (key: string | string[]) => {
    // console.log(key);
  };

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "General",
      children: (
        <FAGenral
          request={request}
          setRequest={setRequest}
          isLoading={isLoading}
        />
      ),
    },
    {
      key: "2",
      label: "Messages",
      children: <FAMessages request={request} setRequest={setRequest} />,
    },
    {
      key: "3",
      label: "Fields",
      children: <FAFields request={request} setRequest={setRequest} />,
    },
  ];
  return (
    <SidebarLayout>
      <PipelineSubHeader
        subHeaderListTitle={`Form Action`}
        showBack
        handleNavigateBack={() =>
          navigate(
            `/${getValue(params, "orgId", "")}/chatbot/${getValue(
              params,
              "chatbotId",
              ""
            )}/ai/detail/actions`
          )
        }
      />
      <div className="custom-action-wrapper">
        <div className="custom-action-left-side">
          <Collapse
            accordion
            items={items}
            defaultActiveKey={["1"]}
            onChange={onChange}
          />
        </div>
        <div className="custom-action-right-side">Chat demo</div>
      </div>
    </SidebarLayout>
  );
};

export default FormAction;
