import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import NewSearchSelect from "@components/custom/Dropdown/AntDropDown";
import { bulkUpdateLiveChatConversation } from "@services/live-chat.service";
import { getValue } from "@utils/lodash";
import { Modal, Select } from "antd";
import { useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "sonner";
import { chatConversationStatus } from "../helpers/live-chat-helpers";

const selectField = [
  {
    label: "Update Conversation Status",
    value: "conversation_status",
  },
  {
    label: "Assign to ",
    value: "assign_to",
  },
];

const BulkUpdateModal = (props: any) => {
  const {
    openBulkUpdateModal,
    handleCancel,
    setBulkUpdateRequest,
    bulkUpdateRequest,
    bulkUpdateSelectedField,
    setBulkUpdateSelectedField,
    bulkUpdateSelectedValue,
    setBulkUpdateSelectedValue,
    selectedInboxIds,
    getData,
    page_no,
    query,
    conversationStatus,
    handleCloseBulkUpdateModal,
    getAllCount,
    clearSearch,
    UrlParams,
    chatbotList,
    AiAgents,
    orgUsers,
  } = props;

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const handleFromFieldChange = (value: string) => {
    setBulkUpdateRequest({ [value]: "" });
    setBulkUpdateSelectedField(value);
    setBulkUpdateSelectedValue("");
  };

  const generatePayload = (value: string, id: string) => {
    switch (value) {
      case "team":
        return {
          //   conversation_status: "new",
          chatbot_id: "",
          assigned_user_id: id,
          managed_by: "team",
        };

      case "bot":
        return {
          chatbot_id: id,
          managed_by: "chatbot",
        };

      case "ai":
        return {
          chatbot_id: id,
          managed_by: "ai-chatbot",
        };
      case "user":
        return {
          chatbot_id: "",
          assigned_user_id: id,
          managed_by: "team",
        };

      default:
        return null;
    }
  };

  const handleBulkUpdateSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setIsSubmitLoading(true);
        interface BulkUpdatePayload {
          inbox_ids: any[];
          conversation_status?: string;
          chatbot_id?: string;
          assigned_user_id?: string;
          managed_by?: string;
        }

        let payload: BulkUpdatePayload = {
          inbox_ids: selectedInboxIds,
        };

        if (bulkUpdateSelectedField === "conversation_status") {
          payload.conversation_status = bulkUpdateSelectedValue;
        } else if (bulkUpdateSelectedField === "assign_to") {
          const sectionKey =
            getValue(bulkUpdateRequest, "managed_by.data-section-key", "") ||
            getValue(bulkUpdateRequest, "chatbot_id.data-section-key", "");

          const updatedPayload = removeNullOrUndefinedProperties(
            generatePayload(sectionKey, bulkUpdateSelectedValue)
          );

          payload = { ...payload, ...updatedPayload };
        }

        let resp = await bulkUpdateLiveChatConversation(payload);
        if (resp) {
          getAllCount();
          handleCloseBulkUpdateModal();
          toast.success(
            `Bulk changes are being processed and will be updated shortly.`
          );
          if (getValue(UrlParams, "searchText", "")) {
            clearSearch();
          }
          getData(true, page_no, query, conversationStatus);
          simpleValidator.current.hideMessages();
          forceUpdate(0);
          setIsSubmitLoading(false);
        }
      } catch (error) {
        console.error("Error updating bulk conversations:", error);
      } finally {
        setIsSubmitLoading(false);
      }
    }
  };

  const { Option, OptGroup } = Select;
  return (
    <Modal
      title="Bulk Update"
      open={openBulkUpdateModal}
      onOk={() => handleBulkUpdateSubmit()}
      onCancel={() => {
        handleCancel();
        simpleValidator.current.hideMessages();
        forceUpdate(0);
      }}
      okText="Update"
      width={700}
      okButtonProps={{ loading: isSubmitLoading }}
    >
      <p className="small_text__13">
        Choose a field from the dropdown and update with new information
      </p>
      <div className="d-flex gap-2 mt-2">
        <div className="w-50">
          <NewSearchSelect
            options={selectField}
            onChange={(value) => {
              handleFromFieldChange(value);
            }}
            value={bulkUpdateSelectedField}
            className="w-100"
            placeholder="Select Field"
            label="Field"
            hideLabel
          />
          {simpleValidator && (
            <p className={"form-error mt-1"}>
              {simpleValidator.current.message(
                "",
                bulkUpdateSelectedField,
                "required"
              )}
            </p>
          )}
        </div>
        <div className="w-50">
          {bulkUpdateSelectedField === "conversation_status" ? (
            <NewSearchSelect
              options={chatConversationStatus}
              onChange={(value) => {
                setBulkUpdateRequest({
                  conversation_status: value,
                });
                setBulkUpdateSelectedValue(value);
              }}
              value={bulkUpdateSelectedValue}
              className="w-100"
              label="Value"
              placeholder="Select Status"
              disabled={!bulkUpdateSelectedField}
              hideLabel
            />
          ) : bulkUpdateSelectedField === "assign_to" ? (
            <Select
              size="large"
              placeholder="Select User or Bot"
              value={bulkUpdateSelectedValue ? bulkUpdateSelectedValue : null}
              onChange={(value: any, option: any) => {
                setBulkUpdateSelectedValue(value);
                setBulkUpdateRequest({
                  [option["data-section-key"] === "user"
                    ? "managed_by"
                    : "chatbot_id"]: option,
                });
              }}
              disabled={!bulkUpdateSelectedField}
              className="w-100"
            >
              <OptGroup key="user" label="Assign to User">
                {orgUsers.map((item: any) => (
                  <Option
                    key={`user-${item.id}`}
                    value={item.id}
                    data-section-key="user"
                  >
                    {item.label}
                  </Option>
                ))}
              </OptGroup>
              <OptGroup key="bot" label="Assign to Bot">
                {chatbotList.map((item: any) => (
                  <Option
                    key={`bot-${item.id}`}
                    value={item.id}
                    data-section-key="bot"
                  >
                    {item.label}
                  </Option>
                ))}
              </OptGroup>
              <OptGroup key="ai" label="Assign to AI Agent">
                {AiAgents.map((item: any) => (
                  <Option
                    key={`ai-${item.id}`}
                    value={item.id}
                    data-section-key="ai"
                  >
                    {item.label}
                  </Option>
                ))}
              </OptGroup>
            </Select>
          ) : null}
          {simpleValidator && (
            <p className={"form-error mt-1"}>
              {simpleValidator.current.message(
                "Option",
                bulkUpdateSelectedValue,
                "required"
              )}
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default BulkUpdateModal;
