import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import { getValue } from "@utils/lodash";
import NewSearchSelect from "@components/custom/Dropdown/AntDropDown";
import InputRuleForm from "@components/InputRuleForm/form";
import { formatString } from "@common/text-helpers";

interface CallerdeskModelProps {
  isModalVisible: boolean;
  submitLoading: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
  pipelineList: any[];
  pipelineStageList: any[];
  editId: string;
  validator: any;
  request: any;
  setRequest: (request: any) => void;
}

function CallerdeskModel(props: CallerdeskModelProps) {
  const {
    isModalVisible,
    handleSubmit,
    handleCancel,
    pipelineList,
    pipelineStageList,
    submitLoading,
    editId,
    validator,
    request,
    setRequest,
  } = props;

  const getPipelineOptions = (list: any[]) => {
    return list.map((item: any) => ({
      value: getValue(item, "id", ""),
      label: getValue(item, "label", ""),
    }));
  };

  useEffect(() => {
    setRequest((prev: any) => ({
      ...prev,
      provider: prev.provider || "callerdesk",
    }));
  }, [pipelineList, pipelineStageList]);

  return (
    <Modal
      title="Phone Settings Configuration"
      open={isModalVisible}
      onOk={handleSubmit}
      onCancel={handleCancel}
      confirmLoading={submitLoading}
      width={600}
      okText={editId ? "Update" : "Add"}
    >
      <InputRuleForm
        label="Destination Number"
        inputType="TEXT"
        value={request.destination_number}
        name="destination_number"
        onChange={(e: any) => {
          setRequest({ ...request, destination_number: e.target.value });
        }}
        placeholder="Enter Destination Number"
        validator={validator}
        validLeft
        required
      />

      <InputRuleForm
        inputType="PASSWORD"
        label="API Key"
        value={request.api_key}
        name="api_key"
        onChange={(e: any) =>
          setRequest({ ...request, api_key: e.target.value })
        }
        placeholder="Enter API Key"
        validator={validator}
        validLeft
        required
      />

      <InputRuleForm
        inputType="PASSWORD"
        label="Secret Key"
        value={request.secret_key}
        name="secret_key"
        onChange={(e: any) =>
          setRequest({ ...request, secret_key: e.target.value })
        }
        placeholder="Enter Secret Key"
        validator={validator}
        validLeft
        required
      />

      {/* {["contact_creation", "contact_updation", "missed_call"].map(
        (prefix: string, index: number) => (
          <div key={index} className="d-grid gap-1 mt-2">
            <NewSearchSelect
              placeholder={`Choose ${prefix.replace("_", " ")} Pipeline`}
              validator={validator}
              options={getPipelineOptions(pipelineList)}
              value={getValue(request, `${prefix}_pipeline_id`, "")}
              onChange={(id: any) => {
                setRequest({ ...request, [`${prefix}_pipeline_id`]: id });
              }}
              // selectKey="id"
              label={`${formatString(prefix)} Pipeline`}
              required={true}
            />

            <NewSearchSelect
              placeholder={`Choose ${prefix.replace("_", " ")} Pipeline Stage`}
              validator={validator}
              options={getPipelineOptions(pipelineStageList)}
              value={getValue(request, `${prefix}_pipeline_stage_id`, "")}
              onChange={(id: any) =>
                setRequest({
                  ...request,
                  [`${prefix}_pipeline_stage_id`]: id,
                })
              }
              // selectKey="id"
              label={`${formatString(prefix)} Pipeline Stage`}
              required={true}
            />
          </div>
        )
      )} */}
    </Modal>
  );
}

export default CallerdeskModel;
