import { useEffect, useState } from "react";
import { RefreshCw, SlidersHorizontal, Download, Trash2 } from "lucide-react";
import "./index.scss";
import AIAgentFullLayout from "../../Layout/full-layout";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAllChatbotLogSessionConversations,
  getAllChatbotLogSessions,
} from "@services/chatbot.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import { formatDateToHoursAgo } from "@common/date-helpers";
import Loader from "@components/common/Loader/loading";
import { concatString } from "@common/text-helpers";
import Nodata from "@components/common/NoData/NoData";
import { Button, Card, Empty } from "antd";

interface ChatMessage {
  id: string;
  content: string;
  timestamp: string;
  timeAgo: string;
  shortQuery?: string;
  confidence?: number;
  source?: string;
}

const initialMessages: ChatMessage[] = [
  {
    id: "1",
    content: "To book a diesel delivery, you can eit...",
    timestamp: "2024-01-26 10:00",
    timeAgo: "1 hour ago",
    shortQuery: "how to book",
    source: "Playground",
  },
  {
    id: "2",
    content: "You can call 88841-44444 to plac...",
    timestamp: "2024-01-25 13:00",
    timeAgo: "22 hours ago",
    shortQuery: "which number can I call",
  },
  {
    id: "3",
    content: "Hello! How can I assist you today i...",
    timestamp: "2024-01-25 13:00",
    timeAgo: "22 hours ago",
    shortQuery: "Hi",
  },
  {
    id: "4",
    content: "I'm here to assist you with any inqui...",
    timestamp: "2024-01-19",
    timeAgo: "7 days ago",
    shortQuery: "what actually you do",
    confidence: 0.65,
  },
  {
    id: "5",
    content: "Hello! How can I assist you today?",
    timestamp: "2024-01-19",
    timeAgo: "7 days ago",
    shortQuery: "Hello",
  },
];

export default function ChatLogs() {
  const params = useParams();
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [messages, setMessages] = useState<ChatMessage[]>(initialMessages);

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [sessions, setSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSession, setSelectedSession] = useState("");
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllChatbotLogSessions(
        getValue(params, `chatbotId`, ""),
        queryRequest
      );
      if (resp) {
        setIsLoading(false);
        setSessions(getValue(resp, `data.sessions`, []));
        getSpecificData(getValue(resp, `data.sessions[${0}].session_id`, ""));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [loading, setLoading] = useState(false);
  const [conversations, setConversations] = useState([]);
  const getSpecificData = async (id: string) => {
    setSelectedSession(id);
    try {
      setLoading(true);
      let payload = {
        chatbot_id: getValue(params, `chatbotId`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllChatbotLogSessionConversations(id, queryRequest);
      if (resp) {
        setLoading(false);
        setConversations(getValue(resp, `data.conversations`, []));
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const deleteMessage = (id: string) => {
    setMessages(messages.filter((msg) => msg.id !== id));
  };
  return (
    <AIAgentFullLayout>
      <div className="chat-logs">
        <header className="chat-logs-header">
          <h1></h1>
          <div className="actions">
            <Button
              color="default"
              variant="filled"
              icon={<RefreshCw size={18} />}
            >
              Refresh
            </Button>
            <Button
              color="default"
              variant="outlined"
              icon={<SlidersHorizontal size={18} />}
            >
              Filter
            </Button>
            <Button type="primary" icon={<Download size={18} />}>
              Export
            </Button>
          </div>
        </header>

        <Card className="ai-chat-conversations">
          {isLoading ? (
            <Loader small />
          ) : getValue(sessions, `length`, 0) > 0 ? (
            <div className="chat-logs-container">
              <div className="chat-logs-list">
                {sessions.map((session: any) => (
                  <div
                    key={session.id}
                    className={`chat-item ${
                      getValue(session, `session_id`, "") === selectedSession
                        ? "active"
                        : ""
                    }`}
                    onClick={() =>
                      getSpecificData(getValue(session, `id`, ""))
                    }
                  >
                    <div className="chat-content">
                      <div className="d-flex align-items-center justify-content-between gap-3">
                        <h3>{session.session_id}</h3>
                        {/* <Button
                          color="danger"
                          variant="text"
                          onClick={() => deleteMessage(session.id)}
                        >
                          <Trash2 size={16} />
                        </Button> */}
                      </div>
                      <p className="small_text__13">
                        {concatString(session.text, 100)}
                      </p>
                    </div>
                    <div className="chat-meta">
                      <span className="small_text__12">
                        {formatDateToHoursAgo(session.created_at)}
                      </span>
                    </div>
                  </div>
                ))}
              </div>

              {loading ? (
                <Loader small />
              ) : getValue(conversations, `length`, 0) > 0 ? (
                <div className="chat-detail">
                  {conversations.map((conversation: any) => {
                    return (
                      <div key={getValue(conversation, `key`, "")}>
                        {!getValue(
                          conversation,
                          `business_initiated`,
                          false
                        ) ? (
                          <div className="message-bubble user">
                            {getValue(conversation, `text`, "")}
                          </div>
                        ) : (
                          <div className="message-bubble bot">
                            {getValue(conversation, `text`, "")}
                            {/* {messages[3]?.confidence && (
                      <div className="message-actions">
                        <span className="confidence">
                          {messages[3].confidence.toFixed(3)}
                        </span>
                        <button className="revise-button">Revise answer</button>
                      </div>
                    )} */}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-center w-100 my-5">
              <Empty />
            </div>
          )}
        </Card>
      </div>
    </AIAgentFullLayout>
  );
}
