import HeaderSignin from "@components/common/Header/HeaderSignin/HeaderSignin";
import Logo from "@components/common/Logo/Logo";

const HorizontalNav = ({ isOpen, setIsOpen }: any) => {
  return (
    <div className={`horizontal-nav ${isOpen ? "horizontal-nav-open" : ""}`}>
      <Logo />
      <HeaderSignin />
    </div>
  );
};

export default HorizontalNav;
