import { formatString } from "@common/text-helpers";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { useRef, useState } from "react";
import { toast } from "sonner";
import { whatsappRequestObj } from "../workflow-request";
import { handleObjectValidation } from "@components/custom/validation";
import SimpleReactValidator from "simple-react-validator";
import AddSvgComponent from "@assets/svg/add";
import { handleValueArrayChange } from "@common/handleChange";
import RemoveCircleSvgComponent from "@assets/svg/remove-circle";
import DialcodePhoneInputCommon from "@components/custom/Forms/DialCodeInputCommon";
import countryTimezones from "@common/json/standardized-timezone-data.json";
import { Drawer } from "antd";
import AntSubmitContainer from "@components/common/ButtonContainer/NewSubmitContainer";
import NewSearchSelect from "@components/custom/Dropdown/AntDropDown";
import AntSearchToolTip from "@components/custom/DebounceSelect";

function WorkflowWhatsappPopup(props: any) {
  const {
    isOpen,
    title,
    toggle,
    request,
    setRequest,
    submitLoading,
    mainRequest,
    setMainRequest,
    editIndex,
    setEditIndex,
  } = props;
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const handleAddEmailToList = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (editIndex) {
        let index = editIndex - 1;
        const updatedData = [...mainRequest.actions.whatsapp];
        updatedData[index] = request;
        setMainRequest((prevState: any) => ({
          ...prevState,
          actions: {
            ...prevState.actions,
            whatsapp: updatedData,
          },
        }));
        toggle();
        setRequest({
          ...whatsappRequestObj,
          alternateNumbers: [],
        });
        setEditIndex(null);
      } else {
        if (getValue(request, `name`, "")) {
          mainRequest.actions.whatsapp.push(request);
          setMainRequest({ ...mainRequest });
          setRequest({
            ...whatsappRequestObj,
            alternateNumbers: [],
          });
          toggle();
        } else {
          toast.error("Please input required fields");
        }
      }
      hideErrorMessages();
    }
  };
  const hideErrorMessages = () => {
    simpleValidator.current.hideMessages();
    forceUpdate(0);
  };
  const [dates] = useState([
    {
      value: "workflow_triggered_at",
      label: formatString("workflow_triggered_at"),
    },
    { value: "record_created_at", label: formatString("record_created_at") },
  ]);
  const errors = handleObjectValidation(request, [
    "name",
    "owner",
    "dueDate",
    "dueDateDays",
    "priority",
  ]);
  const hasErrors = errors.length > 0;

  const handleRemovePhoneNumbers = (index: number) => {
    let number = getValue(request, `alternateNumbers`, []).filter(
      (_item: object, i: number) => i !== index
    );
    setRequest({
      ...request,
      alternateNumbers: number,
    });
  };

  const findCountryCode: any = () => {
    let timezone = getValue(localStorage, `timezone`, "");
    let info: any = countryTimezones.find(
      (item: object) => getValue(item, `value`, "") === timezone
    );
    return getValue(info, `phoneCode`, "");
  };

  const hasDuplicatesAlt = (arr: any) => {
    const valueCount: any = {};
    for (let item of arr) {
      if (valueCount[item.value]) {
        return true; // Duplicate found
      }
      valueCount[item.value] = true;
    }
    return false;
  };

  return (
    <Drawer
      open={isOpen}
      title={title}
      onClose={() => {
        toggle();
        setRequest({
          ...whatsappRequestObj,
          alternateNumbers: [],
        });
        setEditIndex(null);
        hideErrorMessages();
      }}
      width={"40%"}
      className="ant-drawer-container-footer"
      footer={
        <AntSubmitContainer
          handleBackButton={() => {
            toggle();
            setRequest({
              ...whatsappRequestObj,
              alternateNumbers: [],
            });
            setEditIndex(null);
            hideErrorMessages();
          }}
          handleSubmit={() => handleAddEmailToList()}
          submitLoading={submitLoading}
          first_title={"Cancel"}
          second_title={editIndex ? "Update" : "Save"}
          disableValidation
          permissions={["create"]}
          disabled={hasDuplicatesAlt(request.alternateNumbers) ? true : false}
        />
      }
    >
      <div className=" p-3">
        <div className="">
          <NewSearchSelect
            label="Whatsapp Account"
            options={getValue(props, `allConnectionList`, [])}
            selectKey={"value"}
            value={getValue(request, `waAccountId`, "")}
            onChange={(value: any) =>
              setRequest({
                ...request,
                waAccountId: value,
              })
            }
            placeholder="Choose Whatsapp Account"
            required
            validator={simpleValidator}
          />
        </div>
        <div className="mt-2">
          <InputRuleForm
            inputType="TEXT"
            placeholder="Enter Name"
            name="name"
            label={"Name"}
            value={getValue(request, `name`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                name: e.target.value,
              })
            }
            hasRequired
            validator={simpleValidator}
            validLeft
            required
          />
        </div>
        <div className="mt-3">
          <AntSearchToolTip
            label="Templates"
            lookup_api={"templates"}
            selectKey={"id"}
            value={getValue(request, `templateId`, "")}
            onChange={(id: any) =>
              setRequest({
                ...request,
                templateId: id,
              })
            }
            placeholder="Choose Template"
            required
            validator={simpleValidator}
          />
        </div>
        <div className="mt-3">
          <NewSearchSelect
            label="User Preference"
            options={[
              { value: "owner", label: "Owner" },
              { value: "creator", label: "Creator" },
              { value: "user_mobile", label: "User Mobile" },
              { value: "user_phone", label: "User Phone" },
            ]}
            selectKey={"value"}
            value={getValue(request, `userPreference`, "")}
            onChange={(value: any) =>
              setRequest({
                ...request,
                userPreference: value,
              })
            }
            placeholder="Choose User Preference"
            required
            validator={simpleValidator}
          />
        </div>
        <>
          <label className="form-label">Alternative Phone Number(s)</label>
          {getValue(request, `alternateNumbers`, []).map(
            (item: object, index: number) => {
              return (
                <div className="mb-2 d-flex align-items-start">
                  <div className="w-100">
                    {/* <PhoneInputComponent
                      placeholder="Enter phone number (+91...)"
                      value={getValue(item, `value`, "")}
                      onChange={(e: any) => {
                        handleValueArrayChange(
                          index,
                          e,
                          "value",
                          "alternateNumbers",
                          setRequest
                        );
                      }}
                      hideLabel
                      label={"Phone Number"}
                      // mandatory
                      // validator={simpleValidator}
                    /> */}
                    <DialcodePhoneInputCommon
                      placeholder="Enter phone number"
                      value={getValue(item, `value`, "")}
                      onChange={(e: any) => {
                        handleValueArrayChange(
                          index,
                          e,
                          "value",
                          "alternateNumbers",
                          setRequest
                        );
                      }}
                      hideLabel
                      label={"Phone Number"}
                    />
                  </div>
                  <div
                    className="ms-2"
                    onClick={() => handleRemovePhoneNumbers(index)}
                  >
                    <RemoveCircleSvgComponent />
                  </div>
                </div>
              );
            }
          )}
        </>
        <div className="d-flex justify-content-between mt-3 cursor-pointer">
          <div></div>
          <div
            className="d-flex justify-content-between align-items-center"
            onClick={() => {
              getValue(request, `alternateNumbers`, []).push({
                value: findCountryCode(),
              });
              setRequest({ ...request });
            }}
          >
            <AddSvgComponent color={"#408dfb"} />
            <h6 className="header_text__16 color_primary ms-1">
              Add Alternative Number
            </h6>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default WorkflowWhatsappPopup;
