import { handleUpdateArray } from "@common/handleChange";
import DraftJSRefEditor from "@components/custom/DraftJSEditor/draft-ref-editor";
import MultiSearchLookupTooltip from "@components/custom/Dropdown/MultiSearchLookupTooltip";
import { getValue } from "@utils/lodash";
import { Alert } from "antd";
import { Lock, Trash2 } from "lucide-react";

const NotesAction = ({
  index,
  item,
  request,
  setRequest,
  removeConditionUpdate,
  handleDeleteOption,
  validator,
}: any) => {
  const handleChangeNote = (name: string, value: any) => {
    setRequest((prevRequest: any) => ({
      ...prevRequest,
      actions: {
        ...prevRequest.actions,
        notes: prevRequest.actions.notes.map((note: any, noteIndex: number) =>
          noteIndex === index
            ? {
                ...note,
                [name]: value,
              }
            : note
        ),
      },
    }));
  };

  const handleFilterChange = (value: any, fieldName: string) => {
    setRequest((prevRequest: any) => ({
      ...prevRequest,
      actions: {
        ...prevRequest.actions,
        notes: prevRequest.actions.notes.map((note: any, noteIndex: number) =>
          noteIndex === index
            ? {
                ...note,
                [fieldName]: handleUpdateArray(value.id, note[fieldName] || []),
              }
            : note
        ),
      },
    }));
  };

  return (
    <div key={index} className="sla-condition-card p-4 border-bottom">
      <div className="w-9 d-flex align-items-center gap-3 w-100">
        <div className="w-100">
          <Alert
            message="Private"
            type="info"
            showIcon
            icon={<Lock size={16} />}
            className="mb-2 w-fit-content"
          />
          <div className="w-70">
            <MultiSearchLookupTooltip
              lookup_api={"org_user_mail"}
              label={`label`}
              value={getValue(request, `actions.notes[${index}].to`, [])}
              selectKey={"id"}
              onChange={(value: any) => handleFilterChange(value, "to")}
              name="Notify to"
              validator={validator}
            />
          </div>

          <div className="mt-3">
            {/* <label className="form-label">Note</label> */}
            <DraftJSRefEditor
              editorState={getValue(
                request,
                `actions.notes[${index}].html`,
                ""
              )}
              handleChangeEditor={handleChangeNote}
              toolbarHidden={false}
              name="html"
              toolbar={{
                options: ["inline", "blockType", "fontSize", "list"],
                inline: {
                  options: ["bold", "italic"],
                },
              }}
              toolbarClassName={"emailToolbarActive"}
              placeholder="Start typing your note..."
              editorStyle={{
                background: "white",
                paddingLeft: "30px",
                minHeight: "30vh",
                fontSize: "12px",
                //   border: "none",
              }}
              focus
            />
          </div>
        </div>
      </div>
      <Trash2
        size={18}
        onClick={() =>
          getValue(item, `id`, "")
            ? removeConditionUpdate(index, "notes")
            : handleDeleteOption(index, "notes")
        }
        className="cursor-pointer"
      />
    </div>
  );
};

export default NotesAction;
