import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Radio,
  Checkbox,
  Button,
  Space,
  Modal,
  Row,
  Col,
} from "antd";
import { useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { createRazorpayPaymentLink } from "@services/payment-integrations.service";
import { toast } from "sonner";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";

const PaymentLinkForm = (props: any) => {
  const { isModalOpen, handleOk, handleCancel, selectBefore } = props;
  const [form] = Form.useForm();
  const params = useParams();
  const [submitLoading, setSubmitLoading] = useState(false);

  // Set initial values when props.data changes
  useEffect(() => {
    if (props.data?.amount) {
      form.setFieldsValue({
        customer_name:
          getValue(props.data, "contacts.0.first_name", "") !== "null" &&
          getValue(props.data, "contacts.0.first_name", "") !== null &&
          getValue(props.data, "contacts.0.first_name", "") !== ""
            ? getValue(props.data, "contacts.0.first_name", "")
            : "" +
              " " +
              (getValue(props.data, "contacts.0.last_name", "") !== "null" ||
              getValue(props.data, "contacts.0.last_name", "") !== null ||
              getValue(props.data, "contacts.0.last_name", "") !== ""
                ? getValue(props.data, "contacts.0.last_name", "")
                : ""),
        customer_email: getValue(props.data, "contacts.0.email", ""),
        customer_phone:
          getValue(props.data, "contacts.0.mobile_number", "") ||
          getValue(props.data, "contacts.0.phone_number", ""),
        amount: parseInt(props.data.amount),
        link_type: "standard", // Set default link type
      });
    }
  }, [props.data, form, isModalOpen]);

  const onFinish = async (values: any) => {
    const formattedValues = {
      amount: values.amount || 0,
      payment_method: values.link_type === "upi" ? "upi" : "standard",
      customer_name: values.customer_name,
      customer_email: values.customer_email,
      customer_phone: values.customer_phone,
      description: values.description,
      notify_sms: values.notify_sms || false,
      notify_email: values.notify_email || false,
      deal_id: getValue(props, `deal_id`, "")
        ? getValue(props, `deal_id`, "")
        : getValue(params, `id`, ""),
    };

    try {
      setSubmitLoading(true);
      const resp = await createRazorpayPaymentLink(
        removeNullOrUndefinedProperties(formattedValues)
      );
      if (resp) {
        toast.success("Payment link created successfully");
        form.resetFields();
        setSubmitLoading(false);
        handleCancel();
        if (props.setTriggerPaymentList) {
          props.setTriggerPaymentList(true);
        }
        if (props.getData) {
          props.getData();
        }
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
      toast.error("Failed to create payment link");
    }
  };

  const handleModalCancel = () => {
    form.resetFields(); // Reset form when modal is closed
    props.handleCancel();
  };

  return (
    <Modal
      title="Create Razorpay Payment Link"
      open={isModalOpen}
      onCancel={handleModalCancel}
      okText="Create"
      footer={[]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          link_type: "standard", // Set default link type
          notify_email: false,
          notify_sms: false,
        }}
      >
        <Form.Item
          label="Amount"
          name="amount"
          rules={[{ required: true, message: "Amount is required" }]}
        >
          <InputNumber
            className="w-full"
            addonBefore={
              <Form.Item name="currency" noStyle initialValue="₹">
                <span>₹</span>
              </Form.Item>
            }
            placeholder="Amount"
          />
        </Form.Item>

        <Form.Item
          label="Customer Name"
          name="customer_name"
          rules={[
            { required: true, message: "Please enter customer name" },
            { min: 2, message: "Name must be at least 2 characters" },
            {
              pattern: /^[a-zA-Z\s]+$/, // Allows only alphabets and spaces
              message: "Name cannot contain special characters or emojis",
            },
          ]}
        >
          <Input placeholder="Customer Name" />
        </Form.Item>

        <Form.Item label="Payment for" name="description">
          <Input.TextArea placeholder="Description" />
        </Form.Item>

        <div className="mb-2 `">
          {/* <h4 className="mb-2">Customer Details</h4> */}
          <Row className="w-full gap-1">
            <Col span={12}>
              <div className="w-100">
                <Form.Item
                  name="customer_email"
                  label="Email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Please enter a valid email address",
                      pattern:
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
              </div>
            </Col>
            <Col span={11}>
              <div className="">
                <Form.Item
                  name="customer_phone"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      pattern: /^(\+?[0-9]{1,3})?[0-9]{10,12}$/,
                      message: "Please enter a valid phone number",
                    },
                  ]}
                >
                  <Input placeholder="919876543210" />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </div>

        <Space className="mb-2">
          <Form.Item name="notify_email" valuePropName="checked">
            <Checkbox>Notify via email</Checkbox>
          </Form.Item>
          <Form.Item name="notify_sms" valuePropName="checked">
            <Checkbox>Notify via SMS</Checkbox>
          </Form.Item>
        </Space>

        <Form.Item
          label="Link type"
          name="link_type"
          required
          rules={[{ required: true, message: "Please select link type" }]}
        >
          <Radio.Group>
            <Space>
              <Radio value="upi">Create UPI Link</Radio>
              <Radio value="standard">Create Standard Link</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        <Form.Item hidden name="deal_id">
          <Input />
        </Form.Item>

        <Form.Item className="mb-0">
          <Space className="w-full justify-end">
            <Button onClick={handleModalCancel}>Cancel</Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitLoading}
              disabled={submitLoading}
            >
              Create
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PaymentLinkForm;
