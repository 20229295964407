import { getValue } from "@utils/lodash";
import InlineEditTooltipComponent from "@components/Builder/InlineFormBuilder/EditFields";
import { CopyToClipboard, concatString } from "@common/text-helpers";
import { CopyOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

function PendingLoanComponentContacts(props: any) {
  const { submitLoading, handleSubmit, popupFields, setPopupFields } = props;
  return (
    <div className={"form-container"}>
      <div className="d-flex align-items-center justify-content-between detail_header_container pt-2 pb-2">
        <div className="">
          {!getValue(props, `request.first_name`, "") &&
            !getValue(props, `request.last_name`, "") && (
              <h6 className={"form-container_name-text"}>Unknown</h6>
            )}
          <h6 className={"form-container_name-text"}>
            {getValue(props, `request.first_name`, "")}{" "}
            {getValue(props, `request.last_name`, "")}
          </h6>
          {getValue(props, `request.email`, "") && (
            <p className="text-left small_text__14 mb-2 d-flex align-items-center mt-1">
              <span className="">
                {concatString(getValue(props, `request.email`, ""), 30)}
              </span>
              <span
                className="ms-2 cursor-pointer"
                onClick={() =>
                  CopyToClipboard(getValue(props, `request.email`, ""))
                }
              >
                <Tooltip title="Copy">
                  <CopyOutlined size={18} color={"gray"} />
                </Tooltip>
              </span>
            </p>
          )}
        </div>
        <div>
          <InlineEditTooltipComponent
            position={"right top"}
            popupFields={popupFields}
            setPopupFields={setPopupFields}
            width={"400px"}
            submitLoading={submitLoading}
            handleSubmit={handleSubmit}
            permissions={getValue(props, `permissions`, [])}
            module={props.module}
            duplicateAPINames={props.duplicateAPINames}
            setDuplicateAPINames={props.setDuplicateAPINames}
          />
        </div>
      </div>
    </div>
  );
}

export default PendingLoanComponentContacts;
