import React, { useEffect, useState } from "react";
import { Button, Form, Card, Empty, Popconfirm } from "antd";

// Styles
import "./phone.scss";
import { useNavigate, useParams } from "react-router-dom";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import { getValue } from "@utils/lodash";
import { PhoneCall, Settings2, Trash2 } from "lucide-react";
import CallerdeskModel from "./components/callerdesk-model";
import { QueryRequestHelper } from "@common/query-request-helper";
import { allPipelines } from "@services/pipeline.service";
import { listAllModules } from "@services/modules.service";
import {
  createCaller,
  deleteCaller,
  getAllCallers,
  updateCaller,
} from "@services/call-integration.service";
import Loader from "@components/common/Loader/loading";
import { formatString } from "@common/text-helpers";
import SimpleReactValidator from "simple-react-validator";

const ChatPhoneConfig = (props: any) => {
  const params = useParams();
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [, forceUpdate] = React.useState(0);

  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [request, setRequest] = useState({
    provider: "callerdesk",
    destination_number: "",
    api_key: "",
    secret_key: "",
    // contact_creation_pipeline_id: "",
    // contact_creation_pipeline_stage_id: "",
    // contact_updation_pipeline_id: "",
    // contact_updation_pipeline_stage_id: "",
    // missed_call_pipeline_id: "",
    // missed_call_pipeline_stage_id: "",
  });

  const resetRequest = () => {
    setRequest({
      provider: "callerdesk",
      destination_number: "",
      api_key: "",
      secret_key: "",
      // contact_creation_pipeline_id: "",
      // contact_creation_pipeline_stage_id: "",
      // contact_updation_pipeline_id: "",
      // contact_updation_pipeline_stage_id: "",
      // missed_call_pipeline_id: "",
      // missed_call_pipeline_stage_id: "",
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    resetRequest();
    setEditId("");
    simpleValidator.current.hideMessages();
    forceUpdate(0);
  };

  useEffect(() => {
    getModules();
    getAllList();
  }, []);

  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getAllList = async () => {
    try {
      setIsLoading(true);
      const resp = await getAllCallers();
      if (resp) {
        setList(getValue(resp, `data.phones`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getModules = async () => {
    try {
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await listAllModules(queryRequest);
      if (resp) {
        let module = getValue(resp, `data`, []).find(
          (item: object) => getValue(item, `api_name`, "") === "contacts"
        );
        getAllPipelineList(getValue(module, `id`, ""));
      }
    } catch (error) {}
  };

  const [pipelineList, setPipelineList] = useState([]);
  const [stageList, setStageList] = useState([]);
  const getAllPipelineList = async (id: string) => {
    try {
      let payload = {
        // module_name: getValue(moudleName, `[${0}].id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await allPipelines(id, queryRequest);
      if (resp) {
        setPipelineList(
          getValue(resp, `data`, []).map((item: object) => ({
            name: getValue(item, `api_name`, ""),
            label: getValue(item, `label`, ""),
            id: getValue(item, `id`, ""),
            value: getValue(item, `id`, ""),
          }))
        );
        setStageList(
          getValue(resp, `data[${0}].stages`, []).map((item: object) => ({
            ...item,
            label: getValue(item, `label`, ""),
            value: getValue(item, `api_name`, ""),
          }))
        );
      }
    } catch (error) {}
  };

  const [submitLoading, setSubmiLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmiLoading(true);

      if (simpleValidator.current.allValid()) {
        let values = {
          ...request,
          provider: request.provider || "callerdesk",
        };

        let resp = null;
        if (editId) {
          resp = await updateCaller(editId, values);
        } else {
          resp = await createCaller(values);
        }

        if (resp) {
          setIsModalVisible(false);
          resetRequest();
          setSubmiLoading(false);
          getAllList();
          setEditId("");
        } else {
          resetRequest();
          setSubmiLoading(false);
        }
      } else {
        simpleValidator.current.showMessages();
        forceUpdate((n) => n + 1);
        setSubmiLoading(false);
      }
    } catch (error) {
      setSubmiLoading(false);
      console.error("Validation failed:", error);
    }
  };

  const [editId, setEditId] = useState("");
  const handleEdit = (item: object) => {
    setEditId(getValue(item, `id`, ""));
    setIsModalVisible(true);
    setRequest({
      ...request,
      ...item,
      provider: getValue(item, `provider`, ""),
      destination_number: getValue(item, `destination_number`, ""),
      api_key: getValue(item, `api_key`, ""),
      secret_key: getValue(item, `secret_key`, ""),
      // contact_creation_pipeline_id: getValue(
      //   item,
      //   `contact_creation_pipeline_id`,
      //   ""
      // ),
      // contact_creation_pipeline_stage_id: getValue(
      //   item,
      //   `contact_creation_pipeline_stage_id`,
      //   ""
      // ),
      // contact_updation_pipeline_id: getValue(
      //   item,
      //   `contact_updation_pipeline_id`,
      //   ""
      // ),
      // contact_updation_pipeline_stage_id: getValue(
      //   item,
      //   `contact_updation_pipeline_stage_id`,
      //   ""
      // ),
      // missed_call_pipeline_id: getValue(item, `missed_call_pipeline_id`, ""),
      // missed_call_pipeline_stage_id: getValue(
      //   item,
      //   `missed_call_pipeline_stage_id`,
      //   ""
      // ),
    });
  };

  const handleDelete = async (item: object) => {
    try {
      const resp = await deleteCaller(getValue(item, `id`, ""));
      if (resp) {
        getAllList();
      }
    } catch (error) {}
  };

  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        icon="phone"
        title={"Phone Config"}
        backButton
        handleBack={() =>
          navigate(`/${getValue(params, "orgId", "")}/settings/chat/webchat`)
        }
        //   titleLoading={isLoading}
        //   titleChild={<Tag color="green">{getValue(info, `platform`, "")}</Tag>}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="phone-settings">
          <Card className="phone-card">
            <div className="phone-header">
              <div className="phone-info">
                <div className="phone-icon">
                  <PhoneCall />
                </div>
                <div className="phone-text">
                  <h2 className="header_text__18">Phone</h2>
                  <p>
                    Connect your telephony provider and manage your phone
                    conversations.
                  </p>
                  <a href="#">Learn more</a>
                </div>
                <div className="phone-button">
                  <Button
                    type="primary"
                    onClick={() => {
                      showModal();
                      resetRequest();
                    }}
                  >
                    Add Phone
                  </Button>
                </div>
              </div>
            </div>

            <div className="native-telephony">
              <h3 className="header_text__16">Native telephony</h3>
              {list.length > 0 ? (
                list.map((item: any) => (
                  <div className="freshcaller-section">
                    <div className="provider-info">
                      <div className="provider-icon">
                        <img
                          src="https://cdn-1.webcatalog.io/catalog/callerdesk/callerdesk-icon.png?v=1732622526394"
                          alt="Freshcaller"
                          height={60}
                          width={60}
                        />
                      </div>
                      <div className="provider-text">
                        <h4>{formatString(getValue(item, `provider`, ""))}</h4>
                        <p>
                          Use native Appzo telephony system to enable Phone
                          channel for your agents.{" "}
                          <a
                            href="https://callerdesk.io/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Learn more
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="gap-2 d-flex align-items-center">
                      <Popconfirm
                        title="Are you sure to delete this phone?"
                        onConfirm={() => handleDelete(item)}
                        okText="Delete"
                        okButtonProps={{
                          color: "danger",
                          variant: "solid",
                        }}
                      >
                        <Button
                          color="danger"
                          variant="filled"
                          icon={<Trash2 size={16} />}
                        >
                          Delete
                        </Button>
                      </Popconfirm>
                      <Button
                        color="default"
                        variant="filled"
                        onClick={() => handleEdit(item)}
                        icon={<Settings2 size={16} />}
                      >
                        Configure
                      </Button>
                    </div>
                  </div>
                ))
              ) : (
                <Empty />
              )}
            </div>
          </Card>
        </div>
      )}
      <CallerdeskModel
        isModalVisible={isModalVisible}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        pipelineList={pipelineList}
        pipelineStageList={stageList}
        submitLoading={submitLoading}
        editId={editId}
        validator={simpleValidator}
        request={request}
        setRequest={setRequest}
      />
    </CompanyProfileDetailsLayout>
  );
};

export default ChatPhoneConfig;
