import { QueryRequestHelper } from "@common/query-request-helper";
import Loader from "@components/common/Loader/loading";
import { getRazorpayPayment } from "@services/payment-integrations.service";
import { getValue } from "@utils/lodash";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  Table,
  Tag,
  Typography,
  Input,
  Row,
  Col,
  Pagination,
  Button,
  notification,
  Modal,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { formatString } from "@common/text-helpers";
import Pusher from "pusher-js";
import { config } from "../../../../../../../../../../env";
import { convertCurrentDateWithTime } from "@common/date-helpers";

interface PaymentItem {
  payment_id: string;
  customer_name: string;
  customer_phone: string;
  method: string;
  currency: string;
  razorpay_amount: string | number;
  amount: string | number;
  captured: boolean;
  status: string;
  short_url?: string;
}

function DealPaymentFormModel(props: any) {
  const [paymentList, setPaymentList] = useState<PaymentItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [shouldFetch, setShouldFetch] = useState<boolean>(true); // New flag to control fetch
  const params = useParams();

  const fetchPayments = useCallback(
    async (showLoading: boolean = false) => {
      try {
        setIsLoading(showLoading);
        const dealID =
          getValue(props, `deal_id`, "") ||
          getValue(params, `id`, "") ||
          getValue(props, `data.id`, "");

        if (!dealID) return;

        const payload = {
          search: searchQuery,
          page_no: currentPage,
          page_size: pageSize,
          deal_id: dealID,
        };
        const queryRequest = QueryRequestHelper(payload);
        const resp = await getRazorpayPayment(queryRequest);

        if (resp) {
          setPaymentList(
            getValue(resp, `data.razorpay`, []).map((item: object) => ({
              ...item,
              added_on: convertCurrentDateWithTime(getValue(item, `created_at`, "")),
              reference_id: getValue(item, `payment_link_id`, ""),
            }))
          );
          setTotalCount(getValue(resp, `data.pagination.total`, 0));
          props.setTriggerPaymentList(false);
        }
      } catch (error) {
        console.error("Error fetching payment data:", error);
        notification.error({
          message: "Error",
          description: "Failed to fetch payments",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [currentPage, pageSize, searchQuery, props, params]
  );

  // Initial data fetch and updates
  useEffect(() => {
    if (shouldFetch || props.visible) {
      fetchPayments(false);
      setShouldFetch(false); // Reset flag after fetching
    }
  }, [fetchPayments, shouldFetch]);

  // Pusher setup
  useEffect(() => {
    const dealId = getValue(props, `deal_id`, "") || getValue(params, `id`, "");
    if (!dealId) return;

    const pusher = new Pusher(config.PUSHER_APP_KEY, {
      cluster: config.PUSHER_APP_CLUSTER,
      forceTLS: true,
    });

    const channel = pusher.subscribe(`${dealId}`);

    channel.bind("razorpay-payment-notification", (data: PaymentItem) => {
      notification.success({
        message: "Payment Update",
        description: `New payment ${data.status} received from ${
          data.customer_name || "customer"
        }`,
      });

      // Update payment list optimistically
      setPaymentList((prevList) => {
        const exists = prevList.some(
          (payment) => getValue(payment, "id", "") === getValue(data, "id", "")
        );
        if (exists) {
          return prevList.map((payment) =>
            getValue(payment, "id", "") === getValue(data, "id", "")
              ? { ...payment, ...data }
              : payment
          );
        }
        return [data, ...prevList];
      });

      // Optional: Fetch only if necessary (e.g., to sync pagination or totals)
      // setShouldFetch(true); // Uncomment if you want to fetch after Pusher event

      props.getSpecificContactInfo(
        getValue(props, `id`, "") || getValue(props, `contactInfo.id`, "")
      );
    });

    pusher.connection.bind("connected", () => {
      console.log("Pusher connected successfully");
    });

    // pusher.connection.bind("error", (err: any) => {
    //   console.error("Pusher connection error:", err);
    //   notification.error({
    //     message: "Connection Error",
    //     description: "Failed to connect to real-time updates",
    //   });
    // });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
      pusher.disconnect();
    };
  }, [props.deal_id, params.id, props]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
    setShouldFetch(true); // Trigger fetch on search
  };

  const handlePageChange = (page: number, size: number) => {
    setCurrentPage(page);
    setPageSize(size);
    setShouldFetch(true); // Trigger fetch on pagination change
  };

  const handleRefresh = () => {
    setShouldFetch(true); // Trigger fetch on manual refresh
    fetchPayments(true);
  };

  const columns: ColumnsType<PaymentItem> = [
    {
      title: "Reference ID",
      dataIndex: "reference_id",
      key: "reference_id",
      render: (text) => <Typography.Text copyable>{text}</Typography.Text>,
    },
    {
      title: "PAYMENT URL",
      dataIndex: "short_url",
      key: "short_url",
      render: (text) => <Typography.Text copyable>{text}</Typography.Text>,
    },
    {
      title: "ADDED ON",
      dataIndex: "added_on",
      key: "added_on",
    },
    {
      title: "CUSTOMER",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      title: "PHONE",
      dataIndex: "customer_phone",
      key: "customer_phone",
    },
    {
      title: "METHOD",
      dataIndex: "method",
      key: "method",
      render: (method) => <span>{formatString(method)}</span>,
    },
    {
      title: "AMOUNT",
      key: "amount",
      render: (_, record) => (
        <span>
          {record.currency} {record.amount}
        </span>
      ),
    },
    {
      title: "CAPTURE STATUS",
      key: "captured",
      render: (_, record) => (
        <Tag color={record.captured ? "green" : "red"}>
          {record.captured ? "Captured" : "Not Captured"}
        </Tag>
      ),
    },
    // {
    //   title: "PAYMENT STATUS",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (status) => {
    //     let color = "blue";
    //     if (status === "successful" || status === "completed") color = "green";
    //     if (status === "failed") color = "red";
    //     if (status === "pending") color = "orange";
    //     return <Tag color={color}>{formatString(status)}</Tag>;
    //   },
    // },
  ];

  const paymentInfo = useMemo(
    () =>
      getValue(props, `contactInfo.deals`, []).find(
        (item: any) => item.id === props.deal_id
      ),
    [props.deal_id, props.contactInfo]
  );

  return (
    <Modal
      title="Manage Deal Payments"
      open={props.visible}
      onCancel={() => {
        props.onCancel();
        props.setDealPaymentId("");
      }}
      width="80%"
      footer={[]}
    >
      <div className="product-management-antd">
        <Row
          gutter={[16, 16]}
          style={{ marginBottom: "16px" }}
          justify="space-between"
        >
          <Col xs={24} sm={12} md={8} lg={12}>
            <Input
              placeholder="Search payments..."
              prefix={<SearchOutlined />}
              value={searchQuery}
              onChange={handleSearch}
              allowClear
            />
          </Col>
          <Col>
            <Row gutter={[8, 8]}>
              <Col>
                <Button
                  type="default"
                  icon={<ReloadOutlined />}
                  onClick={handleRefresh}
                  loading={isLoading}
                >
                  Refresh
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    props.onCancel();
                    props.setPaymentVisible(true);
                  }}
                >
                  Add Payment
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Table
              columns={columns}
              dataSource={paymentList}
              rowKey={(record) => getValue(record, "id", "")}
              pagination={false}
              bordered
              scroll={{ x: "max-content" }}
            />
            <div className="d-flex justify-content-start align-items-center gap-3 mt-3">
              <div>
                <p>
                  <b>Total Amount Paid</b>
                </p>
                <p className="text-success">
                  {getValue(props, "data.currency", "")}{" "}
                  {getValue(paymentInfo, "amount_paid", 0)}
                </p>
              </div>
              <div>
                <p>
                  <b>Total Amount Due</b>
                </p>
                <p className="text-danger">
                  {getValue(props, "data.currency", "")}{" "}
                  {getValue(paymentInfo, "amount_due", 0)}
                </p>
              </div>
            </div>
            <Row justify="end" style={{ marginTop: "16px" }}>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalCount}
                showSizeChanger
                showQuickJumper
                showTotal={(total) => `Total ${total} items`}
                onChange={handlePageChange}
                onShowSizeChange={handlePageChange}
              />
            </Row>
          </>
        )}
      </div>
    </Modal>
  );
}

export default DealPaymentFormModel;