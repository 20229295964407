import { useEffect, useMemo, useState } from "react";
import { Select, Form } from "antd";
import { concatString } from "common/text-helpers";
import { QueryRequestHelper } from "common/query-request-helper";
import { getLookupAPIs } from "../../helpers/lookup-apis";
import "./index.scss";
import { PlusOutlined } from "@ant-design/icons";
import { SizeType } from "antd/es/config-provider/SizeContext";

interface NewSearchToolTipProps {
  size: SizeType;
  label: string;
  id?: string;
  value: any;
  onChange: (value: any) => void;
  width?: string | number;
  selectKey?: string;
  name?: string;
  data?: any[];
  isLoading?: boolean;
  disabled?: boolean;
  mandatory?: boolean;
  required?: boolean;
  errorLabel?: string;
  concatLength?: number;
  hideLabel?: boolean;
  module_id?: string;
  lookup_api?: string;
  forModule?: string;
  placeholder?: string;
  validator?: any;
  add?: boolean;
  button_title?: string;
  handleOpenPopup?: () => void;
}

const NewSearchToolTip = (props: NewSearchToolTipProps) => {
  const {
    size = "large",
    label,
    value,
    onChange,
    width,
    selectKey = "value",
    name,
    data: propData,
    isLoading: propIsLoading,
    disabled,
    required,
    errorLabel,
    concatLength,
    hideLabel,
    module_id,
    lookup_api,
    placeholder = "Select",
    validator,
    add,
    button_title,
    handleOpenPopup,
  } = props;

  const [dataInfo, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (lookup_api) {
      fetchDefaultData();
    }
  }, [lookup_api]);

  const fetchDefaultData = async () => {
    try {
      const payload = {
        page_no: 1,
        page_size: 10,
        forModule: props.forModule,
        module_id,
      };

      setIsLoading(true);
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getLookupAPIs(lookup_api || "", queryRequest);

      if (resp) {
        setData(resp);
      }
    } catch (error) {
      console.error("Error fetching default data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const options = useMemo(() => {
    const sourceData = lookup_api ? dataInfo : propData;
    return (
      sourceData?.map((item: any) => ({
        label: item[label],
        value: item[selectKey],
        children: concatString(item[label], concatLength || 40),
        originalData: item,
      })) || []
    );
  }, [lookup_api, dataInfo, propData, label, selectKey, concatLength]);

  const handleChange = (selectedValue: any, option: any) => {
    onChange?.(option?.originalData || null);
  };

  return (
    <Form.Item
      className="mb-0"
      label={
        !hideLabel && name ? (
          <span>
            {name} {required && <span className="small_text__16">*</span>}
          </span>
        ) : null
      }
      validateStatus={
        validator?.current?.message(errorLabel || name, value, "required")
          ? "error"
          : undefined
      }
      help={validator?.current?.message(errorLabel || name, value, "required")}
    >
      <Select
        size={size}
        showSearch
        allowClear
        style={{ width: width || "100%" }}
        value={value?.[selectKey]}
        onChange={handleChange}
        placeholder={placeholder}
        loading={isLoading || propIsLoading}
        disabled={disabled}
        optionFilterProp="label"
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        dropdownRender={(menu) => (
          <div>
            {menu}
            {add && (
              <div className="" onClick={handleOpenPopup}>
                <span className="anticon">
                  <PlusOutlined />
                </span>
                {button_title}
              </div>
            )}
          </div>
        )}
        options={options}
      />
    </Form.Item>
  );
};

export default NewSearchToolTip;
