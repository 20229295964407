import { Input } from "antd";
import { ChevronDown, ChevronUp, Search } from "lucide-react";
import { motion } from "framer-motion";
import "./LeftSideBar.scss";
import { useState } from "react";
import { getValue } from "@utils/lodash";

const TicketsLeftSideBar = (props: any) => {
  const { handleNaviagteView, permissions, ticketDetailsPage } = props;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [searchText, setSearchText] = useState("");
  const [openAccordion, setOpenAccordion] = useState(true);
  const toggleAccordion = () => {
    setOpenAccordion(!openAccordion);
  };

  const filteredTabs = props.tabs.filter((tab: object) =>
    getValue(tab, "label", "").toLowerCase().includes(searchText.toLowerCase())
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  return (
    <div
      className={`${
        ticketDetailsPage
          ? "tickets-details-left-sidebar-wrapper"
          : "tickets-left-sidebar-wrapper"
      }`}
    >
      <div className="p-3 position-sticky top-0 bg-white w-100 border-bottom">
        <Input
          placeholder="Search for a view"
          className="search-ticket-view"
          value={searchText}
          onChange={handleSearch}
          prefix={<Search size={16} color="#717171" />}
        />
      </div>
      <div className="px-3 mt-3">
        <div
          className="d-flex align-items-center justify-content-between cursor-pointer "
          onClick={toggleAccordion}
        >
          <span className="header_text__16">Default</span>
          <motion.div
            animate={{ rotate: openAccordion ? 0 : 180 }}
            transition={{ duration: 0.3 }}
          >
            {openAccordion ? (
              <ChevronDown size={16} />
            ) : (
              <ChevronUp size={16} />
            )}
          </motion.div>
        </div>
        {openAccordion && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
            className="d-grid gap-1 my-3"
          >
            {filteredTabs.map((item: object, index: number) => {
              if (getValue(item, "key", "") === "view_all") {
                if (!permissions.includes("view_all")) return null;
              }
              const isSelected =
                (index === 0 &&
                  getValue(item, "value", "") === "all_tickets" &&
                  !getValue(params, "filter_status", "")) ||
                getValue(item, "value", "") ===
                  getValue(params, "filter_status", "");
              return (
                <div
                  key={index}
                  onClick={() =>
                    handleNaviagteView(
                      "filter_status",
                      getValue(item, "value", "")
                    )
                  }
                  className={`${
                    isSelected
                      ? "ticket-sidebar-tab--selected"
                      : "ticket-sidebar-tab"
                  } d-flex justify-content-between align-items-center gap-3`}
                >
                  <p>{getValue(item, "label", "")}</p>
                  {/* <p>{totalCount}</p> */}
                </div>
              );
            })}
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default TicketsLeftSideBar;
