import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import SelectDropdown from "@components/custom/Dropdown/SelectDropdown";
import { Button, Dropdown, MenuProps, Popover, Segmented, Tooltip } from "antd";
import { useState } from "react";
import TextHeaderTooltip from "@components/custom/Dropdown/TextHeaderTooltip";
import { ReloadOutlined } from "@ant-design/icons";
import { Star } from "lucide-react";
import {
  createBookmarkedModules,
  deleteBookmark,
} from "@services/bookmark.service";
import { capitalizeFirstLetter } from "@common/text-helpers";

function DashboardHeader(props: any) {
  const {
    selectedDashboard,
    setSelectedComponentList,
    refreshDashboardList,
    bookmarkedModulePath,
    bookmarkedModules,
    themeColor,
    bookmarkedModuleName,
    refreshBookmarks,
  } = props;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const params = useParams();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState("");
  /* -------------------------------------------------------------------------- */
  /*                                Onchange Section                            */
  /* -------------------------------------------------------------------------- */

  const [open, setOpen] = useState(false);
  const hide = () => {
    setOpen(false);
  };
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const generateRequestArray = (array: any) => {
    return array.map((item: any) => ({
      ...item,
      value: getValue(item, `api_name`, ""),
      label: getValue(item, `label`, ""),
    }));
  };

  /* -------------------------------------------------------------------------- */
  /*                              Navigation Section                            */
  /* -------------------------------------------------------------------------- */
  const handleClickPipeline = (value: object) => {
    let payload = {
      ...UrlParams,
      pipeline: getValue(value, `id`, ""),
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`/${getValue(params, `orgId`, "")}/new-dashboard?${queryRequest}`);
  };
  const handleClickDashboard = (value: object) => {
    setSelectedComponentList([]);
    let payload = {
      ...UrlParams,
      analyticsId: getValue(value, `id`, ""),
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`/${getValue(params, `orgId`, "")}/dashboard?${queryRequest}`);
  };
  const handleClickModule = (value: any) => {
    delete UrlParams.pipeline;
    let payload = {
      ...UrlParams,
      module: getValue(value, `name`, ""),
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`/${getValue(params, `orgId`, "")}/dashboard?${queryRequest}`);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "KPI",
      onClick: () => {
        hide();
        props.openKPI();
      },
    },
    {
      key: "2",
      label: "Chart",
      onClick: () => {
        hide();
        props.openGraph();
      },
    },
  ];

  const handleNavigateDashboard = async () => {
    let payload = {
      ...UrlParams,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`/${getValue(params, `orgId`, "")}/dashboard?${queryRequest}`);
  };

  const dashboardViewFilters = [
    { label: "CRM", value: "crm" },
    { label: "Chat", value: "chat" },
  ];

  const handleClickView = (view: string) => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0]; // YYYY-MM-DD format

    const newView = view;
    const urlSearchParams = new URLSearchParams(window.location.search);

    // Set view parameter
    urlSearchParams.set("view", newView);

    // Set date_range parameter with today's date for both from and to, separated by comma
    urlSearchParams.set("date_range", `${formattedDate},${formattedDate}`);

    window.history.pushState(
      {},
      document.title,
      `?${urlSearchParams.toString()}`
    );

    // Update view in props
    props.setView(newView);

    // If you have props for updating date range, add those here too
    if (props.setDateRange) {
      props.setDateRange({
        from: formattedDate,
        to: formattedDate,
      });
    }
  };

  const createBookmark = async () => {
    let resp = await createBookmarkedModules({
      name: capitalizeFirstLetter(bookmarkedModuleName),
      path: bookmarkedModulePath,
    });

    if (resp) {
      refreshBookmarks();
    }
  };

  const removeBookmark = async (id: string) => {
    let resp = await deleteBookmark(id);
    if (resp) {
      refreshBookmarks();
    }
  };

  return (
    <div className={"common-subheader"}>
      <div className={"common-subheader-wrapper"}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center gap-2">
            {bookmarkedModulePath &&
              (bookmarkedModules.find(
                (bookmarkItem: any) =>
                  bookmarkItem.path === bookmarkedModulePath
              ) ? (
                <Tooltip title="Remove from bookmarks">
                  <Button
                    color="default"
                    variant="text"
                    onClick={() =>
                      removeBookmark(
                        bookmarkedModules.find(
                          (bookmarkItem: any) =>
                            bookmarkItem.path === bookmarkedModulePath
                        ).id
                      )
                    }
                    icon={
                      <Star color={themeColor} fill={themeColor} size={18} />
                    }
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Add to bookmarks">
                  <Button
                    color="primary"
                    variant="text"
                    onClick={createBookmark}
                    icon={<Star size={18} color={themeColor} />}
                  />
                </Tooltip>
              ))}
            {/* <TextHeaderTooltip
              title={`View`}
              data={dashboardViewFilters}
              label={"label"}
              value={getValue(UrlParams, `view`, "")}
              onChange={handleClickView}
              width={"270px"}
              selectKey={"value"}
              editInline
            /> */}
            <Segmented
              options={props.viewList}
              onChange={(value: string) => {
                handleClickView(value);
              }}
              value={getValue(UrlParams, `view`, "") || "crm"}
            />
            {/* <TextHeaderTooltip
              data={generateRequestArray(getValue(props, `allModules`, []))}
              label={"label"}
              title={getValue(props, `selectedModule`, "")}
              value={getValue(props, `selectedModule`, "")}
              onChange={handleClickModule}
              width={"270px"}
              selectKey={"value"}
              editInline
            />
            {getValue(props, `selectedModule`, "") !== "tasks" &&
            getValue(props, `selectedModule`, "") !== "contacts" &&
            getValue(props, `selectedModule`, "") !== "companies" ? (
              <div className="ms-3">
                <SearchToolTip
                  data={getValue(props, `pipelines`, [])}
                  label={"label"}
                  title={getValue(props, `selectedPipeline`, "")}
                  value={getValue(props, `selectedPipeline`, "")}
                  onChange={handleClickPipeline}
                  width={"280px"}
                  isLoading={isLoading}
                  selectKey={"id"}
                />
              </div>
            ) : (
              <div style={{ height: "40px" }}></div>
            )} */}

            <div className="ms-2">
              <SelectDropdown
                placeholder={" Dashboard"}
                data={getValue(props, `allDashboardList`, [])}
                // value={getValue(props, `dashboardName`, "")}
                value={
                  selectedDashboard
                    ? selectedDashboard
                    : getValue(UrlParams, `analyticsId`, "")
                }
                selectKey={"id"}
                nameKey={"Dashboard"}
                onAdd={props.CreateAnalyticsDashboard}
                isLoading={props.loading}
                onSelect={handleClickDashboard}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                name={name}
                setName={setName}
              />
            </div>
          </div>
          <div className="d-flex align-items-center gap-3">
            <div className="cursor-pointer" onClick={refreshDashboardList}>
              <Tooltip title="Reload" placement="bottom">
                <ReloadOutlined style={{ color: "#408dfb" }} />
              </Tooltip>
            </div>
            {/* <div
              className="d-flex align-items-center gap-1"
              onClick={() => handleNavigateDashboard()}
            >
              <AppstoreAddOutlined style={{ color: "#408dfb" }} />
              <p className="header_text__16 color_primary cursor-pointer">
                Dashboard
              </p>
            </div> */}
            {/* <Popover
              content={
                <div className="cursor-pointer">
                  <h6
                    onClick={() => {
                      hide();
                      props.openKPI();
                    }}
                  >
                    KPI
                  </h6>
                  <h6
                    onClick={() => {
                      hide();
                      props.openGraph();
                    }}
                  >
                    Chart
                  </h6>
                </div>
              }
              // title="Title"
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
            >
              <Button
                size="large"
                type="primary"
                onClick={props.showComponentDrawer}
              >
                Component
              </Button>
            </Popover> */}
            <Dropdown menu={{ items }}>
              <Button size="large" type="primary">
                Component
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardHeader;
