import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import { MoreVertical, Plus } from "lucide-react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getValue } from "@utils/lodash";
import {
  Alert,
  Dropdown,
  MenuProps,
  Modal,
  Pagination,
  Tabs,
  TabsProps,
} from "antd";
import { useEffect, useState } from "react";
import { QueryRequestHelper } from "@common/query-request-helper";
import { toast } from "sonner";
import ListLoader from "@components/common/Loader";
import "./phone-assignment.scss";
import { capitalizeFirstLetter, formatString } from "@common/text-helpers";
import SettingsPageDetails from "@components/Pages/PageDescription/SettingsPageDetails";
import {
  automationDetails,
  phoneAssignmentDetails,
} from "@common/Data/page-details-data";
import AutomationsNoDataPage from "@components/common/NoData/automation-nodata";
import DragIndicatorSvgComponent from "@assets/svg/drag-indicator";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import useDynamicTitle from "@context/useDynamicTitle";
import {
  deletePhoneAssignment,
  getAllPhoneAssignment,
  updatePhoneAssignmentPosition,
} from "@services/phone-assignment.service";

const items: TabsProps["items"] = [
  {
    key: "new_contact",
    label: "New Contact",
  },
  {
    key: "existing_contact",
    label: "Existing Contact",
  },
];

const PhoneAssignment = ({ props }: any) => {
  useDynamicTitle("Phone Assignment");
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  // Centralize searchParams parsing
  const searchParams = new URLSearchParams(location.search);
  const UrlParams = Object.fromEntries(searchParams.entries());
  const activeTab = searchParams.get("active_tab") || "new_contact";

  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getAutomationList(true);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) setPage_no(parseInt(UrlParams.page_no));
      if (UrlParams.limit) setLimit(parseInt(UrlParams.limit));
      getAutomationList(true);
    }
  }, [location.search]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getAutomationList = async (status: boolean) => {
    try {
      setIsLoading(status);
      const payload = {
        page_size: UrlParams.page_size || limit,
        page_no: UrlParams.page_no || page_no,
        type: activeTab,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getAllPhoneAssignment(queryRequest);
      if (resp) {
        setList(getValue(resp, "data.phone_assignments", []));
        setPage_no(getValue(resp, "data.pagination.page", 0));
        setTotalCount(getValue(resp, "data.pagination.total", 0));
      }
    } catch (error) {
      toast.error("Failed to load Phone Assignment");
    } finally {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination Section                           */
  /* -------------------------------------------------------------------------- */
  const handleChangePagination = (page_no: number, limit: number) => {
    setPage_no(page_no);
    setLimit(limit);
    const updatedParams = {
      ...UrlParams,
      page_no,
      page_size: limit,
    };
    const queryRequest = QueryRequestHelper(updatedParams);
    navigate(`${location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Tab Change Handler                           */
  /* -------------------------------------------------------------------------- */
  const handleTabChange = (key: string) => {
    const orgId = getValue(params, "orgId", "");
    navigate(`/${orgId}/settings/chat/phone-assignment?active_tab=${key}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Handle New Rule                              */
  /* -------------------------------------------------------------------------- */
  const handleSubmit = () => {
    const orgId = getValue(params, "orgId", "");
    navigate(
      `/${orgId}/settings/chat/phone-assignment/create?type=${activeTab}`,
      {
        replace: true,
      }
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                               Delete Automation                            */
  /* -------------------------------------------------------------------------- */
  const handleDeleteModal = (id: string, name: string) => {
    Modal.confirm({
      title: `Are you sure you want to delete phone assignment - ${name}?`,
      content: "Once deleted, this cannot be recovered.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await deletePhoneAssignment(id);
          toast.success("Phone assignment deleted successfully");
          getAutomationList(true);
        } catch (error) {
          toast.error("Error deleting phone assignment");
        }
      },
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                               Delete Automation                            */
  /* -------------------------------------------------------------------------- */
  const handleUpdatePosition = async (
    updates: { id: string; seq_num: number }[]
  ) => {
    setIsLoading1(true);
    try {
      const payload = { swap_fields: updates };
      const resp = await updatePhoneAssignmentPosition(payload);
      if (resp) {
        getAutomationList(true);
      } else {
        toast.error("Failed to update position");
      }
    } catch (error) {
      toast.error("Error updating position");
    } finally {
      setIsLoading1(false);
    }
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedList = Array.from(list);
    const [removed] = reorderedList.splice(result.source.index, 1);
    reorderedList.splice(result.destination.index, 0, removed);

    setList(reorderedList);
    const updates = reorderedList.map((item, index) => ({
      id: getValue(item, "id", ""),
      seq_num: index + 1,
    }));

    handleUpdatePosition(updates);
  };

  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        icon="phone-assignment"
        title="Phone Assignment"
        showSubmit
        submitIcon={<Plus size={18} />}
        submitText={`New ${formatString(activeTab)}`}
        handleSubmit={handleSubmit}
      />
      <div className="sla-policy-wrapper">
        <div className="p-4 w-70 agent-shifts-card-container">
          <div className="d-flex align-items-center gap-3">
            <p className="pb-3 small_text__15">Rules that run on:</p>
            <Tabs
              activeKey={activeTab}
              onChange={handleTabChange}
              items={items}
            />
          </div>
          <Alert
            type="info"
            showIcon
            message="
                The first matching phone assignment will be applied to calls with
                matching conditions"
            className="w-fit-content mb-4"
          />

          {isLoading ? (
            <ListLoader />
          ) : list.length > 0 ? (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="slaPolicies">
                {(provided) => (
                  <div
                    className="automations-card-wrapper"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {list.map((item, index) => {
                      const menuItems: MenuProps["items"] = [
                        {
                          key: "1",
                          label: "Delete",
                          onClick: () =>
                            handleDeleteModal(
                              getValue(item, "id", ""),
                              getValue(item, "name", "")
                            ),
                          disabled: getValue(item, "is_default", false),
                        },
                      ];

                      return (
                        <Draggable
                          key={getValue(item, "id", "")}
                          draggableId={getValue(item, "id", "")}
                          index={index}
                        >
                          {(provided: any) => (
                            <div
                              key={index}
                              className="automations-card"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <div className="d-flex align-items-center justify-content-between p-4 gap-3 pt-4">
                                <div className="d-flex align-items-center gap-3">
                                  {list.length > 1 && (
                                    <div
                                      className="d-flex justify-content-center cursor-grabber"
                                      {...provided.dragHandleProps}
                                    >
                                      <DragIndicatorSvgComponent
                                        color={"#aeaeae"}
                                      />
                                    </div>
                                  )}
                                  <div
                                    className="cursor-pointer"
                                    onClick={() =>
                                      navigate(
                                        `/${getValue(
                                          params,
                                          "orgId",
                                          ""
                                        )}/settings/chat/phone-assignment/edit/${getValue(
                                          item,
                                          "id",
                                          ""
                                        )}?type=${activeTab}`
                                      )
                                    }
                                  >
                                    <h1 className="header_text__16">
                                      {getValue(item, "seq_num", 0)}.{" "}
                                      {capitalizeFirstLetter(
                                        getValue(item, "name", "")
                                      )}
                                    </h1>
                                    {getValue(item, "description", "") && (
                                      <p className="py-2 small_text__14">
                                        {capitalizeFirstLetter(
                                          getValue(item, "description", "")
                                        )}
                                      </p>
                                    )}
                                    <div className="pt-1">
                                      {getValue(
                                        item,
                                        "summary_conditions",
                                        []
                                      ).map((summary: any, index: number) => (
                                        <span
                                          key={index}
                                          className="small_text__14 summary-conditions"
                                          style={{ marginRight: "4px" }}
                                          dangerouslySetInnerHTML={{
                                            __html: formatString(summary),
                                          }}
                                        />
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                  {/* <Switch
                                    size="small"
                                    checked={getValue(item, "is_active", "")}
                                    onChange={() =>
                                      handleUpdateStatus(
                                        getValue(item, "id", ""),
                                        getValue(item, "is_active", "")
                                          ? false
                                          : true
                                      )
                                    }
                                  /> */}
                                  <Dropdown menu={{ items: menuItems }}>
                                    <MoreVertical size={18} />
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <AutomationsNoDataPage title={formatString(activeTab)} />
          )}
          {totalCount > 10 && (
            <Pagination
              showSizeChanger
              align="center"
              className="mt-4"
              total={totalCount}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              defaultPageSize={limit}
              defaultCurrent={page_no}
              onChange={handleChangePagination}
            />
          )}
        </div>
        <div className="p-4 w-30 sla-policy-right-details">
          <SettingsPageDetails data={phoneAssignmentDetails} />
        </div>
      </div>
    </CompanyProfileDetailsLayout>
  );
};

export default PhoneAssignment;
