import React from "react";
import { getValue } from "@utils/lodash";
import { useNavigate } from "react-router-dom";
import { QueryRequestHelper } from "@common/query-request-helper";
import MessageNavViews from "./components/nav-views";
import Messages from "./Pages/messages";
import OptInMessages from "./Pages/opt-in-messages";
import AutoResponseMessages from "./Pages/auto-response";
import useDynamicTitle from "@context/useDynamicTitle";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";

function MessagePages(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               Tab Section                                  */
  /* -------------------------------------------------------------------------- */
  const activeTab = getValue(UrlParams, `active_tab`, "1");
  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      delete UrlParams.search_text;
      let payload = {
        ...UrlParams,
        active_tab: tab,
        page_no: 1,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    }
  };
  useDynamicTitle("Whatsapp Config");
  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader settings icon="whatsapp" title="WhatsApp" />
      <div className={`manageFieldRightSettings p-4 overflow-y-auto`}>
        <MessageNavViews activeTab={activeTab} toggle={toggle} />
        {activeTab === "1" && <Messages {...props} />}
        {activeTab === "2" && <OptInMessages {...props} />}
        {activeTab === "3" && <AutoResponseMessages {...props} />}
      </div>
    </CompanyProfileDetailsLayout>
  );
}
export default MessagePages;
