import { useEffect } from "react";
import DropdownCloseSvgComponent from "@assets/svg/dropdown-close";
import { getValue } from "@utils/lodash";
import { Dropdown, Menu } from "antd";
import { formatString } from "@common/text-helpers";

const ChartCustomSubmenuDropdown = ({
  label,
  placeholder,
  request,
  setRequest,
  isAntOpen,
  mainMenu,
  subMenu,
  formFields = [], // New prop for form fields
  selectedSubMenu,
  selectedField,
  setSelectedField,
  handleAntOpenChange,
  handleClear,
  handleWithChild,
  dynamicModuleField,
  dynamicDateGranularity,
  validator,
  hideHeader,
  fromMetric,
  required,
}: any) => {
  // Transform date column fields (existing functionality)
  const transformedDateColumnFields = mainMenu.map((item: any) => ({
    key: getValue(item, "id", ""),
    value: getValue(item, "id", ""),
    label: formatString(getValue(item, "label", "")),
    children: subMenu.map((granularity: any) => ({
      key: getValue(granularity, "value", ""),
      value: getValue(granularity, "value", ""),
      label: getValue(granularity, "label", ""),
    })),
  }));
  // Transform form fields (new functionality)
  const transformedFormFields = formFields.map((field: any) => ({
    key: `form_${getValue(field, "id", "")}`,
    value: getValue(field, "id", ""),
    label: formatString(getValue(field, "label", "")),
    type: "form_field", // Add type to distinguish from date fields
  }));
  // Combine both types of fields
  const allMenuItems: any = [
    {
      key: "date_fields",
      type: "group",
      label: "Date Fields",
      children: transformedDateColumnFields,
    },
    {
      key: "form_fields",
      type: "group",
      label: "Form Fields",
      children: transformedFormFields,
    },
  ];

  const handleMenuClick = ({ key, keyPath }: any) => {
    if (key.startsWith("form_")) {
      // Handle form field selection
      const fieldId = key.replace("form_", "");
      const selectedField = formFields.find(
        (field: any) => getValue(field, "id", "") === fieldId
      );

      if (selectedField) {
        setSelectedField(getValue(selectedField, "label", ""));
        if (handleWithChild) {
          setRequest((prevState: any) => ({
            ...prevState,
            is_child: true,
            child_module_field: fieldId,
            child_date_granularity: null, // Reset date granularity for form fields
          }));
        } else {
          setRequest((prevState: any) => ({
            ...prevState,
            [dynamicModuleField]: fieldId,
            [dynamicDateGranularity]: null, // Reset date granularity for form fields
          }));
        }
      }
    } else {
      // Handle date field selection (existing logic)
      const [dateFieldKey, granularityKey] = keyPath;
      const selectedField = subMenu.find(
        (field: any) => getValue(field, "id", "") === dateFieldKey
      );

      if (selectedField) {
        setSelectedField(getValue(selectedField, "label", ""));
      }

      const granularityValue = granularityKey;

      if (handleWithChild) {
        setRequest((prevState: any) => ({
          ...prevState,
          is_child: true,
          child_module_field: granularityValue,
          child_date_granularity: dateFieldKey,
        }));
      } else {
        setRequest((prevState: any) => ({
          ...prevState,
          [dynamicModuleField]: granularityValue,
          [dynamicDateGranularity]: dateFieldKey,
        }));
      }
    }
  };

  const handleLocalClear = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent dropdown from opening when clearing
    setSelectedField(""); // Clear the selected field display
    if (handleClear) {
      handleClear(); // Call the parent's clear handler
    }
  };

  const menu = (
    <Menu
      items={
        fromMetric
          ? [...transformedDateColumnFields, ...transformedFormFields]
          : hideHeader
          ? transformedDateColumnFields
          : allMenuItems
      }
      onClick={handleMenuClick}
      style={{
        height: "260px",
      }}
    />
  );

  useEffect(() => {
    const fieldId = handleWithChild
      ? getValue(request, "child_module_field", "")
      : getValue(request, dynamicModuleField, "");

    // Check in both mainMenu and formFields
    const selectedDateField = mainMenu.find(
      (field: any) => getValue(field, "id", "") === fieldId
    );
    const selectedFormField = formFields.find(
      (field: any) => getValue(field, "id", "") === fieldId
    );

    if (selectedDateField) {
      setSelectedField(getValue(selectedDateField, "label", ""));
    } else if (selectedFormField) {
      setSelectedField(getValue(selectedFormField, "label", ""));
    }
  }, [
    request,
    mainMenu,
    formFields,
    setSelectedField,
    handleWithChild,
    dynamicModuleField,
    getValue(request, "child_module_field", ""),
    getValue(request, dynamicModuleField, ""),
  ]);
  return (
    <div className="custom-submenu-dropdown-container mb-3">
      {label && (
        <label className="form-label">
          {label} {required && <span className="text-danger">*</span>}
        </label>
      )}

      <Dropdown
        overlay={menu}
        trigger={["click"]}
        open={isAntOpen}
        onOpenChange={handleAntOpenChange}
      >
        <div
          className="custom-submenu-dropdown"
          style={{ borderLeft: required && "2px solid red" }}
        >
          {selectedField ? (
            <div className="d-flex justify-content-between align-items-center w-100">
              <div>
                {formatString(selectedField)}
                {selectedSubMenu && ` - ${formatString(selectedSubMenu)}`}
              </div>
              <div className="d-flex align-items-center">
                {selectedField && (
                  <div onClick={handleLocalClear} style={{ cursor: "pointer" }}>
                    <DropdownCloseSvgComponent size={18} />
                  </div>
                )}
                <img
                  src={
                    isAntOpen
                      ? "/dropdown/arrow_up.svg"
                      : "/dropdown/arrow_down.svg"
                  }
                  className="arrow_image"
                  alt=""
                  width={24}
                  height={24}
                />
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="placeholder_text position-relative">
                {placeholder}
              </div>
              <img
                src={
                  isAntOpen
                    ? "/dropdown/arrow_up.svg"
                    : "/dropdown/arrow_down.svg"
                }
                className="arrow_image"
                alt=""
                width={24}
                height={24}
              />
            </div>
          )}
        </div>
      </Dropdown>

      {validator && validator.current.message && (
        <p className={"error-text"}>
          {validator.current.message(
            label,
            getValue(request, `child_module_field`, "") ||
              getValue(request, `child_date_granularity`, "") ||
              getValue(request, `module_field`, "") ||
              getValue(request, `date_granularity`, "") ||
              getValue(request, `metric`, "") ||
              getValue(request, `metric_module_field`, ""),
            "required"
          )}
        </p>
      )}
    </div>
  );
};

export default ChartCustomSubmenuDropdown;
