import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackSvgComponent from "@assets/svg/back-link";
import ButtonComponent from "@components/Form/Button/Button";
import {
  associateAmenityToProperty,
  getSpecificProperty,
  removeAssociatedAmenityFromProperty,
} from "@services/properties/property.service";
import { toast } from "sonner";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getAllPropertyAmenities } from "@services/properties/master/property-amenities.service";
import Loader from "@components/common/Loader/loading";
import AddRoomConfirmation from "@components/Dialogs/Modals/add-room-confirmation";
import SidebarLayout from "@layouts/SidebarLayout/SidebarLayout";

const PropertyAmenities = (props: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const handleOpenConfirmation = () => {
    setOpenConfirmation(!openConfirmation);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */
  const [amenity_ids, setAmenity_ids] = useState<any>([]);
  useEffect(() => {
    getPropertyAmenities();
    if (getValue(params, `id`, "")) {
      getData();
    }
  }, []);

  const getData = async () => {
    try {
      let resp = await getSpecificProperty(getValue(params, `id`, ""));
      if (resp) {
        let ids = getValue(resp, `data.property_amenities`, []).map(
          (item: object) => getValue(item, `id`, "")
        );
        setAmenity_ids(ids);
      }
    } catch (error) {}
  };

  /* ------------------------     Property Amenities  -------------------------- */

  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState([]);
  const getPropertyAmenities = async () => {
    try {
      setIsLoading(true);
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllPropertyAmenities(queryRequest);
      if (resp) {
        setList(getValue(resp, `data.amenities`, []));
        setIsLoading(false);
        getData();
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    navigate(
      `/${getValue(params, `orgId`, "")}/property/room-types/${getValue(
        params,
        `id`,
        ""
      )}`
    );
  };

  const handleAddRemoveAmenities = async (id: any) => {
    try {
      let updatedAmenityIds;
      if (amenity_ids.includes(id)) {
        updatedAmenityIds = amenity_ids.filter(
          (amenityId: any) => amenityId !== id
        );
        let resp = await removeAssociatedAmenityFromProperty(
          getValue(params, `id`, ""),
          {
            amenity_ids: [id],
          }
        );
        if (resp) {
          // toast.success("Removed successfully");
        } else {
          toast.error("Failed to remove");
        }
      } else {
        updatedAmenityIds = [...amenity_ids, id];
        let resp = await associateAmenityToProperty(
          getValue(params, `id`, ""),
          {
            amenity_ids: [id],
          }
        );
        if (resp) {
          // toast.success("Added successfully");
        } else {
          toast.error("Failed to add");
        }
      }

      getData();
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  return (
    <SidebarLayout>
      <div className={"company-profile-subheader-wrapper"}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <div
              onClick={() =>
                navigate(
                  `/${getValue(params, "orgId", "")}/property/images/${getValue(
                    params,
                    "id",
                    ""
                  )}`
                )
              }
            >
              <BackSvgComponent />
            </div>
            <p className="small_text__16 ms-3">{"Property Amenities"}</p>
          </div>
          <div className="d-flex align-items-center">
            <ButtonComponent
              buttonType="primary"
              buttonText={"Next"}
              onClickHandler={handleOpenConfirmation}
            />
          </div>
        </div>
      </div>
      <div className={`w-100 grid-container   add-overflow-y`}>
        <div className="w-100 p-5 add-overflow-y manageFieldRight">
          {
            <div className="">
              <h6 className="header_text__18 mb-4">Select Amenities</h6>
              {isLoading ? (
                <Loader />
              ) : (
                getValue(list, `length`, 0) > 0 && (
                  <div className="amenity_container">
                    {list.map((item: object) => {
                      const itemId = getValue(item, `id`, "");
                      const isSelected = amenity_ids.includes(itemId);
                      return (
                        <div className="position-relative mb-3" key={itemId}>
                          <div
                            className="amenity_text "
                            style={{
                              border: isSelected ? "1px solid #408dfb" : "",
                            }}
                            onClick={() => handleAddRemoveAmenities(itemId)}
                          >
                            <p className="">{getValue(item, `lable`, "")}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )
              )}
            </div>
          }
        </div>
      </div>

      <AddRoomConfirmation
        isOpen={openConfirmation}
        handleModal={handleOpenConfirmation}
        handleSubmit={handleSubmit}
        submitLoading={submitLoading}
      />
    </SidebarLayout>
  );
};

export default PropertyAmenities;
