import AddSvgComponent from "@assets/svg/add";
import DragIndicatorSvgComponent from "@assets/svg/drag-indicator";
import {
  formatString,
  removeNullOrUndefinedProperties,
  sortJSONObjectArray,
} from "@common/text-helpers";
import useDraggableInPortal from "@common/useDraggableInPortal";
import { updateTicketStatusPosition } from "@services/ticket-status.service";
import { getValue } from "@utils/lodash";
import { Switch } from "antd";
import { Lock, Pencil } from "lucide-react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const StatusDragAndDropList = (props: any) => {
  const {
    list,
    editId,
    type,
    handleUpdateStage,
    isChanged,
    isLoading,
    handleAddStage,
    handleUpdateStageStatus,
    handleModal,
    setEditId,
    setRequest,
    getData,
    title,
  } = props;
  const renderDraggable = useDraggableInPortal();

  const handleOnDragEnd = async (result: any) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (source.droppableId === destination.droppableId) {
      const categoryListCopy: any = [...list];

      const [movedItem] = categoryListCopy.splice(source.index, 1);
      categoryListCopy.splice(destination.index, 0, movedItem);

      const updatedList = categoryListCopy.map((item: any, index: number) => ({
        id: item.id,
        seq_num: index + 1,
      }));

      props.setStageRequest({
        ...props.stageRequest,
        [props.name]: removeNullOrUndefinedProperties(categoryListCopy),
      });

      const payload = {
        swap_fields: updatedList,
      };
      let resp = await updateTicketStatusPosition(payload);
      if (resp) {
        getData();
      }
    }
  };

  return (
    list.length > 0 && (
      <div>
        <div className="d-flex justify-content-between m-2 align-items-center">
          <h6 className={"header_text__18"}>{title}</h6>
          <div
            className="d-flex align-items-center cursor-pointer"
            onClick={() => handleAddStage(type)}
          >
            <AddSvgComponent color={"#1a73e8"} />
            <h6 className={"header_blue_text__16 ms-2"}>Add {title}</h6>
          </div>
        </div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="source">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="w-100"
              >
                {sortJSONObjectArray(list, "seq_num").map(
                  (obj: any, index: number) => {
                    return (
                      <Draggable
                        key={index}
                        draggableId={`${index}open`}
                        index={index}
                      >
                        {renderDraggable((provided: any) => (
                          <div
                            className={`d-flex align-items-center `}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div
                              key={index}
                              className={`status-container d-flex align-items-center w-75`}
                            >
                              <div className="ms-3">
                                <div {...provided.dragHandleProps}>
                                  <DragIndicatorSvgComponent
                                    color={"lightgray"}
                                  />
                                </div>
                              </div>
                              <div
                                className={`status-container__code d-flex align-items-center justify-content-between w-100 ${
                                  getValue(obj, "is_default", "")
                                    ? ""
                                    : "cursor-pointer"
                                }`}
                                onClick={() => {
                                  if (getValue(obj, "is_default", "")) return;
                                  setRequest({
                                    label: getValue(obj, `label`, ""),
                                    color_code: getValue(obj, `color_code`, ""),
                                    is_active: getValue(
                                      obj,
                                      `is_active`,
                                      false
                                    ),
                                    description: getValue(
                                      obj,
                                      `description`,
                                      ""
                                    ),
                                    type: getValue(obj, `type`, ""),
                                  });
                                  handleModal();
                                  setEditId(getValue(obj, `id`, ""));
                                }}
                              >
                                <div className="d-flex align-items-center gap">
                                  <div
                                    className={`${"box"} ${`box--light-blue`} `}
                                    style={{
                                      background: getValue(
                                        obj,
                                        `color_code`,
                                        ""
                                      ),
                                    }}
                                  ></div>

                                  <span>{getValue(obj, `label`, "")}</span>
                                  {getValue(obj, "is_default", "") ? (
                                    <Lock
                                      size={16}
                                      className="status-edit-button"
                                    />
                                  ) : (
                                    <Pencil
                                      size={16}
                                      className="status-edit-button"
                                    />
                                  )}
                                </div>
                              </div>
                              <Switch
                                size="small"
                                checked={getValue(obj, `is_active`, false)}
                                onChange={(checked: boolean) => {
                                  handleUpdateStageStatus(
                                    getValue(obj, `id`, ""),
                                    checked ? true : false
                                  );
                                }}
                                className="mx-4"
                              />
                            </div>

                            {!isChanged &&
                            getValue(obj, `name`, "") &&
                            editId === getValue(obj, `id`, "") ? (
                              <button
                                className={"main_button__primary"}
                                onClick={() =>
                                  handleUpdateStage(getValue(obj, `id`, ""))
                                }
                                disabled={isLoading}
                              >
                                {isLoading ? "Loading..." : "Update"}
                              </button>
                            ) : null}
                          </div>
                        ))}
                      </Draggable>
                    );
                  }
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    )
  );
};

export default StatusDragAndDropList;
