import { Select } from "antd";
import countries from "@common/json/country.flag.json";

const { Option } = Select;

const NewCountrySelect = ({ value, onChange, required, disabled }: any) => {
  // Find the selected country OR set to `null` if value is empty
  const selectedCountry = countries.find((c) => c.dial_code === value) || null;

  const handleChange = (code: string | null) => {
    if (!code) {
      // If cleared, reset selection
      onChange(null);
      return;
    }
    const country = countries.find((c) => c.code === code);
    if (country) {
      onChange(country); // Pass entire country object
    }
  };

  return (
    <Select
      size="large"
      value={selectedCountry ? selectedCountry.code : undefined} // Handle clearing
      style={{ width: 120, height: 44, maxWidth: 120 }}
      onChange={handleChange}
      optionLabelProp="label"
      allowClear
      showSearch
      filterOption={(input, option) => {
        const searchValue = input.toLowerCase();
        const country = countries.find((c) => c.code === option?.value);
        if (!country) return false;

        return (
          country.name.toLowerCase().includes(searchValue) ||
          country.dial_code.includes(searchValue) ||
          country.flag.includes(searchValue)
        );
      }}
      dropdownStyle={{ minWidth: 280 }}
      placeholder="Select"
      disabled={disabled && disabled}
      className={`${required ? "ant-select-required" : ""}`}
    >
      {countries.map((country) => (
        <Option
          key={country.code}
          value={country.code}
          label={`${country.flag} ${country.dial_code}`}
        >
          <span>
            {country.flag} {country.name} ({country.dial_code})
          </span>
        </Option>
      ))}
    </Select>
  );
};

export default NewCountrySelect;
