import { QueryRequestHelper } from "@common/query-request-helper";
import {
  downloadBroadcastTemplate,
  editWhatsappBroadcast,
  generateBroadcastTemplate,
  getSpecificWhatsappBroadcast,
  getWhatsappBroadcast,
  getWhatsappBroadcastContacts,
  getWhatsappTemplate,
  manageWhatsappBroadcast,
} from "@services/fb.service";
import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";
import BroadcastHistoryTable from "../components/broadcast-history/history-table";
import { getAllContacts } from "@services/contacts.service";
import { listAllPipelines } from "@services/pipeline.service";
import { useStateContext } from "@context/dataContext";
import { toast } from "sonner";
import { convertDateTime } from "@common/date-helpers";
import { useNavigate } from "react-router-dom";
import { intitalFilterViewState } from "@components/Pages/Pipeline/helpers/create-pipeline-helper";
import { commonCustomFilter } from "@services/smart-filter.service";
import { mediaUpload } from "@services/upload.service";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import { handleDownloadOnClick } from "@common/downloadfile";
import ListLoader from "@components/common/Loader";
import ConfirmModal from "@components/Dialogs/Modals/confirmModal";
import EditBroadcastModal from "./edit-popup";

function BroadcastHistory(props: any) {
  const intialState = {
    id: "",
    name: "",
    templateId: "",
    timestamp: "",
    contacts: [],
    isActive: true,
  };
  const { selectedModuleId } = useStateContext();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const [limit, setLimit] = useState(50);
  const [page_no, setPage_no] = useState(1);
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getData("1");
      getTemplateList();
      getAllPipelinesList();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      if (UrlParams.activeTab) {
        getData(UrlParams.activeTab);
      } else {
        getData("1");
      }
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [scheduleTimestamp, setScheduleTimestamp] = useState();
  const [request, setRequest] = useState(intialState);
  const [templateRequest, setTemplateRequest] = useState({});
  const reset = () => {
    setRequest(intialState);
    setTemplateRequest({});
  };
  /* -------------------------------------------------------------------------- */
  /*                                   API Section                              */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);

  const getData = async (historyTab: any) => {
    try {
      setIsLoading(true);
      let payload = {
        page_size: getValue(UrlParams, `limit`, "")
          ? getValue(UrlParams, `limit`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
        search: getValue(UrlParams, `search`, ""),
        fetchPastOnly:
          (getValue(UrlParams, `activeTab`, "")
            ? getValue(UrlParams, `activeTab`, "")
            : historyTab) === "1"
            ? true
            : false,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getWhatsappBroadcast(queryRequest);
      if (resp) {
        setList(getValue(resp, `data.broadcasts`, []));
        setTotalCount(getValue(resp, `data.total`, 0));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [isLoading1, setIsLoading1] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const getTemplateList = async () => {
    try {
      setIsLoading1(true);
      let payload = {
        status: "APPROVED",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getWhatsappTemplate(queryRequest);
      if (resp) {
        setTemplateList(
          getValue(resp, `data.templates`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `id`, ""),
            label: getValue(item, `name`, ""),
          }))
        );
        setIsLoading1(false);
      } else {
        setIsLoading1(false);
      }
    } catch (error) {
      setIsLoading1(false);
    }
  };

  const [contactPipelineId, setContactPipelineId] = useState("");
  const [allPipelines, setAllPipelines] = useState([]);
  const getAllPipelinesList = async () => {
    try {
      let allPipelines = await listAllPipelines(
        await selectedModuleId("contacts"),
        ""
      );
      let list = getValue(allPipelines, `data`, []).map((item: object) => ({
        ...item,
        value: getValue(item, `id`, ""),
        label: getValue(item, `label`, ""),
      }));
      setAllPipelines(list);
      setContactPipelineId(getValue(allPipelines, `data.[${0}].id`, ""));
      getAllContact(
        page_no1,
        limit1,
        selectedAdvancedFiter,
        getValue(allPipelines, `data.[${0}].id`, "")
      );
    } catch (error) {}
  };

  const handleChangePipeline = (id: string) => {
    setContactPipelineId(id);
    getAllContact(page_no1, limit1, selectedAdvancedFiter, id);
  };

  const [allContacts, setAllContacts] = useState([]);
  const [totalCount1, setTotalCount1] = useState(0);
  const getAllContact = async (
    page: any,
    limit: any,
    filter: any,
    pipeline_id: string
  ) => {
    try {
      let payload = {
        page_no: page,
        page_size: limit,
        pipeline_id: pipeline_id,
        module_id: await selectedModuleId("contacts"),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp;
      if (getValue(filter, `length`, 0) > 0) {
        let obj = {
          has_advance_filter: true,
          has_group_by: false,
          filter_groups: filter,
        };
        resp = await commonCustomFilter("contacts", queryRequest, obj);
      } else {
        resp = await getAllContacts(queryRequest);
      }
      if (resp) {
        setAllContacts(getValue(resp, `data.contacts`, []));
        setTotalCount1(getValue(resp, `data.pagination.total`, 0));
        setSelectedIds([]);
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                                   Modal Section                            */
  /* -------------------------------------------------------------------------- */

  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const [isOpen1, setIsOpen1] = useState(false);
  const handleModal1 = () => {
    setIsOpen1(!isOpen1);
  };
  /* -------------------------------------------------------------------------- */
  /*                               OnChange Section                             */
  /* -------------------------------------------------------------------------- */

  const handleTemplateRequest = (id: string) => {
    setRequest({
      ...request,
      templateId: id,
    });
    let info: any = templateList.find(
      (item: object) => getValue(item, `id`, "") === id
    );
    handlePreview(info);
  };
  /* -------------------------------------------------------------------------- */
  /*                                    Checkbox Section                        */
  /* -------------------------------------------------------------------------- */
  const [selectedIds, setSelectedIds] = useState<any>([]);
  const handleChangeCheckbox = (id: any) => {
    let checkIfExist =
      getValue(selectedIds, `length`, 0) > 0
        ? selectedIds.filter((item: any) => item === id)
        : [];
    if (getValue(checkIfExist, `length`, 0) > 0) {
      let filtered =
        getValue(selectedIds, `length`, 0) > 0
          ? selectedIds.filter((item: any) => item !== id)
          : [];
      setSelectedIds(filtered);
    } else {
      selectedIds.push(id);
      setSelectedIds([...selectedIds]);
    }
  };

  const handleChangeAllCheckbox = (value: any) => {
    if (value === "all_checked") {
      setSelectedIds([]);
    } else {
      let IDs = allContacts.map((item: any) => item.id);
      setSelectedIds(IDs);
    }
  };

  const [page_no1, setPage_no1] = useState(1);
  const [limit1, setLimit1] = useState(50);
  const handleChangePagination1 = (page: any) => {
    setPage_no1(page);
    getAllContact(page, limit1, selectedAdvancedFiter, contactPipelineId);
  };
  const handleChangeLimit1 = (limit: any) => {
    setLimit1(limit);
    getAllContact(page_no1, limit, selectedAdvancedFiter, contactPipelineId);
  };

  /* -------------------------------------------------------------------------- */
  /*                                   Submit Section                           */
  /* -------------------------------------------------------------------------- */

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      let obj = {
        name: getValue(request, `name`, ""),
        templateId: getValue(request, `templateId`, ""),
        timestamp: scheduleTimestamp
          ? convertDateTime(scheduleTimestamp)
          : convertDateTime(new Date()),
        contacts: selectedIds,
        isActive: true,
        contactUploadId: getValue(uploadedFileInfo, `id`, ""),
      };
      let resp = await manageWhatsappBroadcast(
        removeNullOrUndefinedProperties(obj)
      );
      if (resp) {
        toast.success("Submitted successfully");
        setSubmitLoading(false);
        getData(historyTab);
        handleModal();
        reset();
        setTemplateRequest({});
        setIsOpen2(false);
        setUploadedFileInfo({});
        setDownloadFile("");
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  const getSpecificBrodcast = async (id: string) => {
    try {
      let resp = await getSpecificWhatsappBroadcast(id);
      if (resp) {
        handlePreview(getValue(resp, `data.template`, {}));
        setRequest({
          ...request,
          id: getValue(resp, `data.id`, ""),
          name: getValue(resp, `data.name`, ""),
          templateId: getValue(resp, `data.template_id`, ""),
          timestamp: getValue(resp, `data.timestamp`, ""),
          isActive: getValue(resp, `data.is_active`, false),
        });
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  // const [page_size, setPage_size] = useState(10);

  const handleChangePagination = (page_no: number, limit: any) => {
    setPage_no(page_no);
    setLimit(limit);
    let payload = {
      ...UrlParams,
      page_no: page_no,
      page_size: limit,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    setPage_no(page_no);
  };
  const handleChangeLimit = (limit: string) => {
    let payload: any = {
      ...UrlParams,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const [editId, setEditId] = useState("");
  const handlePreview = (findInfo: any) => {
    setEditId("");
    let buttons =
      getValue(findInfo, `buttons.length`, 0) > 0
        ? getValue(findInfo, `buttons`, []).map((item: object) => ({
            ...item,
            type: getValue(item, `action_type`, ""),
            action_type: getValue(item, `type`, ""),
          }))
        : [];
    setTemplateRequest({
      ...templateRequest,
      header: {
        type: getValue(findInfo, `header_type`, ""),
        text: getValue(findInfo, `text`, ""),
        link: getValue(findInfo, `header_file.key`, ""),
      },
      body: getValue(findInfo, `body`, ""),
      footer: getValue(findInfo, `footer`, ""),
      buttons: buttons,
    });
    // handleModal();
  };

  const handleOpenPreview = (id: string) => {
    getSpecificBrodcast(id);
    associatedContacts(id);
    handleModal1();
  };

  const [associatedContactList, setAssociatedContactsList] = useState([]);
  const associatedContacts = async (id: string) => {
    try {
      let resp = await getWhatsappBroadcastContacts(id);
      if (resp) {
        setAssociatedContactsList(getValue(resp, `data.broadcasts`, []));
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                       Create Filterview Section                            */
  /* -------------------------------------------------------------------------- */
  const [createViewStep, setCreateViewStep] = useState(1);
  const [isOpenFilterView, setIsOpenFilterView] = useState(false);
  const [filterRequest, setFilterRequest] = useState(intitalFilterViewState);
  const [filterViewId, setFilterId] = useState("");
  const handleOpenFilterView = () => {
    setIsOpenFilterView(!isOpenFilterView);
    setFilterRequest(intitalFilterViewState);
    setFilterId("");
    setCreateViewStep(1);
  };
  const [selectedAdvancedFiter, setSelectedAdvancedFiter] = useState([]);
  const handleSubmitFilter = (filter: any) => {
    setSelectedAdvancedFiter(filter);
    setIsOpenFilterView(!isOpenFilterView);
    getAllContact(page_no1, limit1, filter, contactPipelineId);
  };
  const handleRemoveSmartFilter = () => {
    setSelectedAdvancedFiter([]);
    getAllContact(page_no1, limit1, [], contactPipelineId);
  };

  /* -------------------------------------------------------------------------- */
  /*                          Import Popup Section                              */
  /* -------------------------------------------------------------------------- */
  const [isOpen2, setIsOpen2] = useState(false);
  const handleModal2 = () => {
    setIsOpen2(!isOpen2);
  };

  const [downloadFile, setDownloadFile] = useState("");
  const handleOpenImportPopup = async () => {
    try {
      let resp = await generateBroadcastTemplate({
        templateId: getValue(request, `templateId`, ""),
      });
      if (resp) {
        setDownloadFile(getValue(resp, `data.url`, ""));
        handleModal2();
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                            Upload Section                                  */
  /* -------------------------------------------------------------------------- */

  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedFileInfo, setUploadedFileInfo] = useState({});
  const handleUploadDocuments = async (e: any) => {
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      formData.append("filename", item.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");
      try {
        setUploadLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          setUploadedFileInfo(getValue(resp, `data`, {}));
          e = null;
          setUploadLoading(false);
        } else {
          e = null;
          setUploadLoading(false);
        }
      } catch (error) {
        e = null;
        setUploadLoading(false);
      }
    });
  };

  const handleDownloadBroadcastTemplate = async (id: string, name: string) => {
    try {
      let resp = await downloadBroadcastTemplate(id);
      if (resp) {
        handleDownloadOnClick(getValue(resp, `data.url`, ""), name);
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                              Tab Section                                   */
  /* -------------------------------------------------------------------------- */
  const [historyTab, setActiveTab] = useState("1");

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      setActiveTab(getValue(UrlParams, `activeTab`, "1"));
    }
  }, [window.location.href]);

  const handleChangeTab = (key: string) => {
    setIsLoading(true);
    setList([]);
    setActiveTab(key);
    let payload: any = {
      ...UrlParams,
      limit: limit,
      historyTab: key,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    // getData(key);
  };

  const [id, setId] = useState("");
  /* -------------------------------------------------------------------------- */
  /*                               Stop Broacast section                        */
  /* -------------------------------------------------------------------------- */
  const [isConfirm, setIsConfirm] = useState(false);
  const handleConfirm = () => {
    setIsConfirm(!isConfirm);
  };
  const handleSubmitStop = (id: string) => {
    setId(id);
    handleConfirm();
  };
  const stopBroadcast = async () => {
    try {
      let resp = await getSpecificWhatsappBroadcast(id);
      if (resp) {
        let obj = {
          id: id,
          isActive: false,
        };
        let response = await editWhatsappBroadcast(
          removeNullOrUndefinedProperties(obj)
        );
        if (response) {
          toast.success("Broacast sent successfully");
          getData("1");
          handleConfirm();
          setId("");
        }
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Edit Broacast section                        */
  /* -------------------------------------------------------------------------- */
  const [isEdit, setIsEdit] = useState(false);
  const [timestamp, setTimestamp] = useState("");
  const handleEdit = () => {
    setIsEdit(!isEdit);
  };
  const handleSubmitEdit = (id: string) => {
    editBroadcast(id);
    handleEdit();
  };
  const editBroadcast = async (id: string) => {
    setId(id);
    try {
      let resp = await getSpecificWhatsappBroadcast(id);
      if (resp) {
        setTimestamp(getValue(resp, `data.trigger_at`, ""));
      }
    } catch (error) {}
  };
  const handleSubmitEditBroadcast = async () => {
    if (timestamp) {
      try {
        let obj = {
          id: id,
          isActive: true,
          timestamp: timestamp,
        };
        let response = await editWhatsappBroadcast(
          removeNullOrUndefinedProperties(obj)
        );
        if (response) {
          toast.success("Broacast sent successfully");
          getData("1");
          setTimestamp("");
          handleEdit();
          setId("");
        }
      } catch (error) {}
    } else {
      toast.error("Please select time");
    }
  };
  return (
    <div>
      <div className="d-flex">
        <div className="w-100">
          {/* <Segmented<string>
            options={[
              { label: 'List', value: 'List', icon: <BarsOutlined /> },
              { label: 'Kanban', value: 'Kanban', icon: <AppstoreOutlined /> },
            ]}
            value={value} 
            onChange={setValue}
          /> */}

          {/* {historyTab === "1" && (
            
          )} */}
          {getValue(UrlParams, "activeTab", "") === "2" ? (
            <div className="mt-2">
              {isLoading ? (
                <ListLoader />
              ) : (
                <>
                  <BroadcastHistoryTable
                    list={list}
                    getSpecificBrodcast={handleOpenPreview}
                    editId={editId}
                    handleDownloadBroadcastTemplate={
                      handleDownloadBroadcastTemplate
                    }
                    totalCount={totalCount}
                    limit={limit}
                    page_no={page_no}
                    page_size={limit}
                    handleChangePagination={handleChangePagination}
                    permissions={getValue(props, `permissions`, [])}
                    stopBroadcast={handleSubmitStop}
                    handleSubmitEdit={handleSubmitEdit}
                    configInfo={props.configInfo}
                  />
                </>
              )}
            </div>
          ) : (
            <div className="mt-2">
              {isLoading ? (
                <ListLoader />
              ) : (
                <>
                  <BroadcastHistoryTable
                    list={list}
                    getSpecificBrodcast={getSpecificBrodcast}
                    editId={editId}
                    handleDownloadBroadcastTemplate={
                      handleDownloadBroadcastTemplate
                    }
                    totalCount={totalCount}
                    limit={limit}
                    page_no={page_no}
                    page_size={limit}
                    handleChangePagination={handleChangePagination}
                    permissions={getValue(props, `permissions`, [])}
                    hideMore
                    configInfo={props.configInfo}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <ConfirmModal
        isOpen={isConfirm}
        handleModal={handleConfirm}
        handleSubmit={stopBroadcast}
        content={"By proceeding, you will stop the broadcast."}
      />
      <EditBroadcastModal
        isOpen={isEdit}
        handleModal={handleEdit}
        handleSubmit={handleSubmitEditBroadcast}
        timestamp={timestamp}
        setTimestamp={setTimestamp}
      />
    </div>
  );
}

export default BroadcastHistory;
