import React, { useEffect, useState } from "react";

import { getValue } from "@utils/lodash";
import { deleteChatbot, getSpecificChatbot } from "@services/chatbot.service";
import { useNavigate, useParams } from "react-router-dom";
import { Button, ColorPicker, List, Switch, Tag } from "antd";
import { toast } from "sonner";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import WebChatSubHeader from "./components/subheader";
import WebChatNavviews from "./components/nav-views";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import CompanyProfileSubHeader from "@pages/Private/Settings/CompanyProfile/components/CompanyProfileSubHeader";

const WebChatManage = (props: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const [info, setInfo] = useState({});
  const [deleteValue, setDeleteValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getChatbot();
    }
  }, []);
  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      getChatbot();
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getChatbot = async () => {
    try {
      setIsLoading(true);
      let resp = await getSpecificChatbot(getValue(params, `id`, ""));
      if (resp) {
        setInfo(getValue(resp, `data`, {}));
        setDeleteValue(getValue(resp, `data.name`, "{}"));
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Delete bot Section                                  */
  /* -------------------------------------------------------------------------- */

  const [isOpen, setIsOpen] = useState(false);

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleDeleteView = () => {
    handleModal();
  };

  const handleDeleteBot = async () => {
    try {
      let resp = await deleteChatbot(getValue(info, "id", ""));
      if (resp) {
        toast.success("Deleted Successfully");
        handleModal();
        handleNavigateBack();
      }
    } catch (error) {}
  };

  const handleNavigateBack = () => {
    navigate(`/${getValue(params, `orgId`, "")}/settings/chat/webchat`);
  };

  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        icon="webchat"
        title={getValue(info, `name`, "")}
        backButton
        handleBack={() =>
          navigate(`/${getValue(params, "orgId", "")}/settings/chat/webchat`)
        }
        titleLoading={isLoading}
        titleChild={<Tag color="green">{getValue(info, `platform`, "")}</Tag>}
      />
      {/* <WebChatSubHeader
        subHeaderListTitle={getValue(info, `name`, "")}
        platform={getValue(info, `platform`, "")}
        isRightArrowVisible={true}
        isSubmenuListAvailable={true}
        hideClose={true}
        pipeline={false}
        showBack={true}
        handleNavigateBack={handleNavigateBack}
        params={params}
        permissions={getValue(props, `permissions`, [])}
      /> */}
      <div className="d-flex chatbot_share_container">
        <div className="w-100">
          <WebChatNavviews />
          <div className="w-100 p-5 d-flex align-items-center flex-column">
            <div>
              <img
                src={
                  getValue(info, `avatar`, "")
                    ? getValue(info, "avatar", "")
                    : "/images/bot-loader.gif"
                }
                width={60}
                height={60}
                className="rounded-5"
                style={{ background: "#e7e7e7" }}
              />
            </div>
            <h6 className="mt-2">{getValue(info, "name", "")}</h6>
            <p className="small_text__13">
              {getValue(info, "description", "")}
            </p>

            <List
              grid={{ gutter: 16, column: 2 }}
              className="mt-4"
              dataSource={[
                {
                  label: "Response interval",
                  value: (
                    <p className="small_text__14">
                      {getValue(info, "response_interval", "")}s
                    </p>
                  ),
                },
                {
                  label: "Idle chat time",
                  value: (
                    <p className="small_text__14">
                      {getValue(info, "idle_chat_time", "")}s
                    </p>
                  ),
                },
                {
                  label: "Idle chat response",
                  value: (
                    <p className="small_text__14">
                      {getValue(info, "idle_chat_response", "")}
                    </p>
                  ),
                },
                {
                  label: "Email notifications",
                  value: (
                    <p className="small_text__14">
                      <Switch
                        disabled
                        checked={
                          getValue(info, "enable_email_notification", "") ===
                          true
                        }
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        size="small"
                      />{" "}
                      {getValue(info, "enable_email_notification", "") ===
                        true && (
                        <>
                          {getValue(info, "send_email_notification_to", "") ===
                          ""
                            ? "(You haven't added any email for notification)"
                            : getValue(info, "send_email_notification_to", "")}
                        </>
                      )}
                    </p>
                  ),
                },

                {
                  label: "Theme Color",
                  value: (
                    <>
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        className="mt-1"
                      >
                        <ColorPicker
                          value={getValue(info, "theme_color", "")}
                        />
                        <p className="small_text__14 ms-2">
                          {getValue(info, "theme_color", "")}
                        </p>
                      </div>
                    </>
                  ),
                },
              ]}
              renderItem={(item) => (
                <List.Item>
                  <div style={{ width: "100%" }}>
                    <strong>{item.label}</strong>
                    <div>{item.value}</div>
                  </div>
                </List.Item>
              )}
            />
            <div className="d-flex gap-3 align-items-center">
              {getValue(props, `permissions`, []).includes("update") && (
                <Button
                  size="large"
                  icon={<SettingOutlined />}
                  onClick={() =>
                    navigate(
                      `/${getValue(
                        params,
                        "orgId",
                        ""
                      )}/settings/chat/webchat/settings/${getValue(
                        params,
                        "id",
                        ""
                      )}`
                    )
                  }
                >
                  Edit settings
                </Button>
              )}
              {getValue(props, `permissions`, []).includes("delete") && (
                <Button
                  size="large"
                  type="primary"
                  icon={<DeleteOutlined />}
                  onClick={handleDeleteView}
                  danger
                >
                  Delete
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        isOpen={isOpen}
        handleModal={handleModal}
        handleSubmit={handleDeleteBot}
        deleteValue={deleteValue}
        deleteMessage="By deleting this bot, you permanently delete all associated data. There's no turning back!"
      />
    </CompanyProfileDetailsLayout>
  );
};

export default WebChatManage;
