import { convertCurrentDateToTime } from "@common/date-helpers";
import { getFileIcon } from "@common/document-format-helpers";
import { formatFileSize } from "@common/file-type-helper";
import {
  capitalizeFirstLetter,
  concatString,
  formatType,
} from "@common/text-helpers";
import WhatsappMessageLoader from "@components/common/Loader/whatsapp-message-loader";
import { getValue } from "@utils/lodash";
import { Badge, Tooltip } from "antd";
import { ArrowDownToLine, File } from "lucide-react";

const handleOpenFile = (fileUrl) => {
  window.open(fileUrl, "_blank");
};

const DocumentThumbnail = ({
  fileUrl,
  item,
  request,
  getReadIcon,
  text,
  receiver,
}) => {
  if (!fileUrl) {
    return (
      <>
        <div
          className={`d-flex align-items-center gap-2 bg-light rounded-3 justify-content-between px-2 py-1`}
        >
          <div className="document-loader-container">
            <div className="document-loader-icon"></div>
            <div className="document-loader-text"></div>
            <WhatsappMessageLoader icon={<File size={20} color="#7e7e7e" />} />
          </div>
        </div>
        <p className="send-time p-1 mt-1 d-flex justify-content-end">
          {convertCurrentDateToTime(getValue(item, "created_at", ""))}
          {!getValue(item, "is_private", "") && (
            <span className="ms-2">{getReadIcon(request)}</span>
          )}
          {!getValue(item, "message", "") &&
            getValue(item, "is_private", "") === false && (
              <span className="ms-2">{getReadIcon(item)}</span>
            )}
        </p>
      </>
    );
  }

  const mimeType = getValue(item, "mime_type", "");
  const filename =
    getValue(request, "media_name", "") ||
    capitalizeFirstLetter(getValue(item, "filename", ""));
  const fileExtension = getValue(item, "extension", "");
  const icon = getFileIcon(fileExtension) || "/images/icons/binder.png";

  return (
    <>
      {request.upload_file ? (
        <>
          <div
            className={`d-flex align-items-center gap-2 rounded-3 justify-content-between ${
              fileUrl ? "p-2" : "px-2 py-1"
            } ${receiver ? "bg-light" : "sender-document-container"}`}
          >
            <div className="d-flex align-items-center gap-2">
              <img src={icon} alt="File" width={26} height={26} />
              <div>
                {filename.length > 40 ? (
                  <Tooltip title={filename}>
                    <p className="document-filename">
                      {concatString(filename, 40)}
                    </p>
                  </Tooltip>
                ) : (
                  <p className="document-filename">{filename}</p>
                )}

                <div className="livechat-document-details">
                  {formatType(fileExtension)}
                  <div className="dot-separator"></div>
                  {formatFileSize(getValue(item, "size", ""))}
                </div>
              </div>
              <ArrowDownToLine
                onClick={() => handleOpenFile(fileUrl)}
                size={26}
                className="cursor-pointer ms-3 border p-1"
                color="gray"
                style={{ borderRadius: "50%" }}
              />
            </div>
          </div>

          {text && <p className="small_text__14 text-start mt-2">{text}</p>}
          <p className="send-time p-1 mt-1 d-flex justify-content-end">
            {convertCurrentDateToTime(getValue(item, "created_at", ""))}
            {!getValue(item, "is_private", "") && (
              <span className="ms-2">{getReadIcon(request)}</span>
            )}
            {!getValue(item, "message", "") &&
              getValue(item, "is_private", "") === false && (
                <span className="ms-2">{getReadIcon(item)}</span>
              )}
          </p>
        </>
      ) : (
        <Tooltip title="Coming soon" color="blue">
          <div className="d-flex align-items-center gap-2 p-3 bg-light rounded-3 justify-content-between disabled mb-2">
            <div className="d-flex align-items-center gap-2">
              <img
                src={"/images/icons/binder.png"}
                alt="File"
                width={26}
                height={26}
              />
              <div>
                <p className="document-filename">{filename}</p>
                {!getValue(request, "media_name", "") && (
                  <p className="small_text__12 pt-1">
                    {formatType(fileExtension)}
                  </p>
                )}
              </div>
            </div>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default DocumentThumbnail;
