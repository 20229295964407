export const getStatusColor = (status: string): string => {
  const lowerCasedStatus = status.toLowerCase();
  return lowerCasedStatus.includes("cancel") ||
    lowerCasedStatus.includes("abandon")
    ? "red"
    : "gray";
};

export const chatManagedBy = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "team",
    label: "Team",
  },
  {
    value: "chatbot",
    label: "Chatbot",
  },
  {
    value: "ai-chatbot",
    label: "AI Chatbot",
  },
  // {
  //   value: "ticket",
  //   label: "Ticket",
  // },
];

export const chatSource = [
  {
    value: "all",
    label: "All",
  },
  {
    value: "webchat",
    label: "Web",
  },
  {
    value: "whatsapp",
    label: "Whatsapp",
  },
  // {
  //   value: "ticket",
  //   label: "Ticket",
  // },
];

export const chatConversationStatus = [
  {
    value: "new",
    label: "New",
  },
  {
    value: "waiting_on_us",
    label: "Waiting on us",
  },
  {
    value: "waiting_on_customer",
    label: "Waiting on customer",
  },
  {
    value: "on_hold",
    label: "On Hold",
  },
  {
    value: "closed",
    label: "Closed",
  },
];

export const chatAssignementStatus = [
  {
    value: "assigned",
    label: "Assigned",
  },
  {
    value: "unassigned",
    label: "Unassigned",
  },
  {
    value: "assigned_and_not_replied",
    label: "Assigned and not replied",
  },
];
