import { getFileIcon } from "@common/document-format-helpers";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Alert, Modal } from "antd";

const MediaLibraryUploadModal = ({
  isUploadModalOpen,
  request,
  setRequest,
  validator,
  handleUrlOk,
  handleDocumentCancel,
  documentDetails,
  isLoading,
}: any) => {
  // const filename = getValue(request, "name", "").includes(
  //   getValue(documentDetails, "extension", "")
  // )
  //   ? getValue(request, "name", "")
  //   : getValue(request, "name", "") +
  //     getValue(documentDetails, "extension", "");

  return (
    <Modal
      title="Upload file"
      open={isUploadModalOpen}
      onOk={handleUrlOk}
      onCancel={handleDocumentCancel}
      okText="Add File"
      okButtonProps={{ loading: isLoading }}
    >
      <div className="media-library-selected-file my-2">
        <img
          src={getFileIcon(getValue(documentDetails, "extension", ""))}
          width={30}
          height={30}
          alt="file-icon"
        />
        {getValue(documentDetails, "filename", "")}
      </div>
      <div className="mb-3">
        <InputRuleForm
          inputType="TEXT"
          name="name"
          value={getValue(request, "name", "")}
          placeholder="Enter Name"
          required
          label="Name"
          onChange={(e: any) =>
            setRequest({
              ...request,
              name: e.target.value,
            })
          }
          validator={validator}
          validLeft
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              handleUrlOk();
            }
          }}
        />
      </div>
    </Modal>
  );
};

export default MediaLibraryUploadModal;
