import { Select, Radio, Space } from "antd";
import { getValue } from "@utils/lodash";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { useStateContext } from "@context/dataContext";
import NewSearchSelect from "@components/custom/Dropdown/AntDropDown";

function formatString(str: string) {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function AssignConfigSettings(props: any) {
  const { selectedModuleId } = useStateContext();
  const {
    chatbotList,
    aiAgentList,
    request,
    setRequest,
    pipelineList,
    stageList,
    getAllPipelineList,
  } = props;

  const managedBy = [
    { value: "team", label: "Team" },
    { value: "chatbot", label: "Chat BOT" },
    { value: "ai-chatbot", label: "AI Agents" },
  ];
  const creationRules = [
    { value: "do_nothing", label: "Do Nothing", module: "" },
    { value: "create_contact", label: "Create Contact", module: "contacts" },
    // { value: "create_lead", label: "Create Lead", module: "leads" },
  ];

  const assignment_types = [
    {
      value: "assign_to_previously_assigned_agent",
      label: formatString("assign_to_previously_assigned_agent"),
    },
    {
      value: "round_robin_with_agent_availability",
      label: formatString("round_robin_with_agent_availability"),
    },
    {
      value: "assign_to_contact_owner",
      label: formatString("assign_to_contact_owner"),
    },
  ];

  const handleCreationRuleChange = (value: string) => {
    setRequest((prev: any) => ({ ...prev, creation_rule: value }));
  };

  const handleAssignmentTypeChange = (value: string) => {
    setRequest((prev: any) => ({
      ...prev,
      assignment_type: value,
      team_member_id: "",
    }));
  };
  return (
    <div className="rules-container">
      <div className="section">
        <h3 className="header_text__18">
          What should happen when a new WhatsApp message is received from a new
          user?
        </h3>

        <div className="creation-rules px-4 pb-4">
          <NewSearchSelect
            label="Creation Rules"
            size="large"
            placeholder="Select Creation Rule"
            value={getValue(request, `creation_rule`, "")}
            onChange={async (value) => {
              setRequest((prevState: any) => ({
                ...prevState,
                module_id: "",
                pipeline_id: "",
                pipeline_stage_id: "",
              }));
              handleCreationRuleChange(value);
              if (value) {
                if (value === "create_lead") {
                  getAllPipelineList(await selectedModuleId("leads"), value);
                } else {
                  getAllPipelineList(await selectedModuleId("contacts"), value);
                }
              }
            }}
            options={creationRules}
          />
          {getValue(request, `creation_rule`, "") === "create_lead" && (
            <NewSearchSelect
              size="large"
              placeholder="Select Pipeline"
              label="Pipeline"
              value={getValue(request, `pipeline_id`, "")}
              onChange={(value: any, option: any) => {
                setRequest({
                  ...request,
                  pipeline_id: value,
                });
              }}
              options={
                getValue(pipelineList, `length`, 0) > 0
                  ? pipelineList.map((item: any) => ({
                      value: getValue(item, "id", ""),
                      label: getValue(item, "label", ""),
                    }))
                  : []
              }
              width={"100%"}
            />
          )}
          {(getValue(request, `creation_rule`, "") === "create_contact" ||
            getValue(request, `creation_rule`, "") === "create_lead") && (
            <NewSearchSelect
              size="large"
              placeholder="Select Stage"
              label="Status"
              value={getValue(request, `pipeline_stage_id`, "")}
              onChange={(value: any, option: any) => {
                setRequest({
                  ...request,
                  pipeline_stage_id: value,
                });
              }}
              options={
                getValue(stageList, `length`, 0) > 0
                  ? stageList.map((item: any) => ({
                      value: getValue(item, "id", ""),
                      label: getValue(item, "label", ""),
                    }))
                  : []
              }
            />
          )}
        </div>
      </div>

      <div className="section">
        <h4>Chat Managed By</h4>
        <div className="managed-by">
          <div className="assignment-rules">
            <div className="w-100">
              <SearchToolTip
                label="label"
                selectKey={"value"}
                name="Select Assignee"
                data={managedBy}
                value={getValue(request, `managedBy`, "")}
                onChange={(e: object) =>
                  setRequest({
                    ...request,
                    managedBy: getValue(e, `value`, ""),
                  })
                }
              />
            </div>
            {/* {getValue(request, `managedBy`, "") === "team" && (
              <div className="w-100">
                <SearchToolTip
                  label="label"
                  name="Select Team"
                  lookup_api={"org_users"}
                  selectKey={"id"}
                  value={getValue(request, `team_member_id`, "")}
                  onChange={(e: object) => {
                    setRequest({
                      ...request,
                      team_member_id: getValue(e, `id`, ""),
                    });
                  }}
                />
              </div>
            )} */}
            {getValue(request, `managedBy`, "") === "chatbot" && (
              <div className="w-100">
                <SearchToolTip
                  label="label"
                  selectKey={"value"}
                  name="Select Chatbot"
                  data={chatbotList}
                  value={getValue(request, `chatBotId`, "")}
                  onChange={(e: object) =>
                    setRequest({
                      ...request,
                      chatBotId: getValue(e, `value`, ""),
                    })
                  }
                />
              </div>
            )}
            {getValue(request, `managedBy`, "") === "ai-chatbot" && (
              <div className="w-100">
                <SearchToolTip
                  label="label"
                  selectKey={"value"}
                  name="Select AI Agent"
                  data={aiAgentList}
                  value={getValue(request, `chatBotId`, "")}
                  onChange={(e: object) =>
                    setRequest({
                      ...request,
                      chatBotId: getValue(e, `value`, ""),
                    })
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {getValue(request, "managedBy", "") === "team" && (
        <div className="section">
          <span className="note">
            What should happen when the conversation is created or reopened?
          </span>
          <h4>Assignment Types</h4>
          <div className="assignment-rules pt-4">
            <Radio.Group
              value={request.assignment_type}
              onChange={(e) => handleAssignmentTypeChange(e.target.value)}
            >
              <Space direction="vertical">
                {/* Standard assignment types */}
                {assignment_types.map((type) => (
                  <Radio key={type.value} value={type.value}>
                    {type.label}
                  </Radio>
                ))}

                {/* Custom team member assignment */}
                <Radio value="assign_to_team_member">
                  <div className="d-flex align-items-center gap-2">
                    If above 3 options are not applicable, then assign to
                    <div>
                      <SearchToolTip
                        label="label"
                        lookup_api={"org_users"}
                        selectKey={"id"}
                        value={getValue(request, `team_member_id`, "")}
                        onChange={(e: object) => {
                          setRequest({
                            ...request,
                            team_member_id: getValue(e, `id`, ""),
                            assignment_type: "assign_to_team_member",
                          });
                        }}
                        width={"300px"}
                        editInline
                      />
                    </div>
                  </div>
                </Radio>

                {/* Unassigned option */}
                <Radio value="unassign">Unassigned</Radio>
              </Space>
            </Radio.Group>
          </div>
        </div>
      )}
    </div>
  );
}

export default AssignConfigSettings;
