import { convertDateTime } from "@common/date-helpers";
import {
  convertCurrentDate,
  convertCurrentDateWithTime,
} from "@common/date-helpers";
import {
  handleBooleanChange,
  handleEmailChange,
  handleNumberChange,
  handleTextChange,
  handleTimeValueChange,
  handleUpdateArray,
} from "@common/handleChange";
import { capitalizeFirstLetter } from "@common/text-helpers";
import AntSearchToolTip from "@components/custom/DebounceSelect";
import NewSearchSelect from "@components/custom/Dropdown/AntDropDown";
import MultiSearchLookupTooltip from "@components/custom/Dropdown/MultiSearchLookupTooltip";
import MultiSearchTooltip from "@components/custom/Dropdown/MultiSearchTooltip";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import Checkbox from "@components/custom/Forms/Checkbox";
import DateMonthYearPicker from "@components/custom/Forms/Date";
import Input from "@components/custom/Forms/Input";
import InputIcon from "@components/custom/Forms/InputIcon";
import MainTimePicker from "@components/custom/Forms/Time";
import BooleanOptions from "@components/custom/Forms/boolean_options";
import TextArea from "@components/custom/Forms/textarea";
import { useStateContext } from "@context/dataContext";
import { getValue } from "@utils/lodash";
import moment from "moment";
import React from "react";

function FilterFormBuilder(props: any) {
  const { request, setRequest, required, simpleValidator, key_name, module } =
    props;

  const { allModules } = useStateContext();
  const renderForm = (item: any, label: string) => {
    const findModuleId = () => {
      let moduleInfo =
        getValue(allModules, `length`, 0) > 0
          ? allModules.find(
              (item: object) => getValue(item, `api_name`, "") === module
            )
          : {};
      return getValue(moduleInfo, `id`, "");
    };
    //----------------------- Due date time -------------------//
    switch (getValue(item, `input_type`, "")) {
      case "text":
      case "url":
        return (
          <div className="form_builder_field">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextChange(e, request, setRequest)
              }
              type={"text"}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              label={`${
                getValue(item, `name`, "") === "name"
                  ? capitalizeFirstLetter(label)
                  : ""
              }${" "}${capitalizeFirstLetter(getValue(item, `label`, ""))}`}
              value={getValue(request, `${key_name}`, "")}
              name={key_name}
              hideLabel
            />
          </div>
        );
      case "email":
        return (
          <div className="form_builder_field">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleEmailChange(e, request, setRequest)
              }
              type={"email"}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              label={`${
                getValue(item, `name`, "") === "name" ? label : ""
              }${" "}${getValue(item, `label`, "")}`}
              value={getValue(request, `${key_name}`, "")}
              name={key_name}
              hideLabel
            />
          </div>
        );

      case "percent":
      case "integer":
      case "decimal":
      case "auto_number":
      case "autonumber":
      case "bigint":
      case "tel":
        return (
          <div className="form_builder_field">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleNumberChange(e, request, setRequest)
              }
              type={"text"}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              label={`${getValue(item, `label`, "")}`}
              value={getValue(request, `${key_name}`, "")}
              name={key_name}
              hideLabel
            />
          </div>
        );
      case "currency":
        return (
          <div className="form_builder_field">
            <InputIcon
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleNumberChange(e, request, setRequest)
              }
              type={"text"}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              label={`${getValue(item, `label`, "")}`}
              value={getValue(request, `${key_name}`, "")}
              name={key_name}
              hideLabel
            />
          </div>
        );
      case "textarea":
        return (
          <div className="form_builder_field">
            <TextArea
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextChange(e, request, setRequest)
              }
              type={"textarea"}
              label={`${getValue(item, `label`, "")}`}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              rows={3}
              value={getValue(request, `${key_name}`, "")}
              name={key_name}
              hideLabel
            />
          </div>
        );

      case "date":
        return (
          <div className="form_builder_field">
            <DateMonthYearPicker
              {...item}
              onChange={(e: any) => {
                setRequest({
                  ...request,
                  [key_name]: moment(e).format("YYYY-MM-DD"),
                  input_value_label: convertCurrentDate(e),
                });
              }}
              label={`${getValue(item, `label`, "")}`}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              value={getValue(request, `${key_name}`, "")}
              name={key_name}
              hideLabel
            />
          </div>
        );
      case "time":
        return (
          <div className="form_builder_field">
            <MainTimePicker
              {...item}
              onChange={(e: any) => {
                handleTimeValueChange(e, `${key_name}`, request, setRequest);
                setRequest({
                  ...request,
                  [key_name]: convertDateTime(e),
                  input_value_label: convertCurrentDateWithTime(e),
                });
              }}
              label={`${getValue(item, `label`, "")}`}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              showTimeInput
              value={getValue(request, `${key_name}`, "")}
              name={key_name}
              hideLabel
            />
          </div>
        );
      case "datetime":
        return (
          <div className="form_builder_field">
            {/* <TimePicker
              {...item}
              onChange={(e: any) => {
                key_name === 'input_value_1'?
                setRequest({
                  ...request,
                  [key_name]:convertDateTime(e),
                  input_value_label_1: convertCurrentDateWithTime(e),
                }):
                setRequest({
                  ...request,
                  [key_name]:convertDateTime(e),
                  input_value_label: convertCurrentDateWithTime(e),
                });
              }}
              label={`${getValue(item, `label`, "")}`}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              showTimeInput
              value={getValue(request, `${key_name}`, "")}
              name={key_name}
              hideLabel
            /> */}
            <DateMonthYearPicker
              {...item}
              onChange={(e: any) => {
                setRequest({
                  ...request,
                  [key_name]: moment(e).format("YYYY-MM-DD"),
                  input_value_label: convertCurrentDate(e),
                });
              }}
              label={`${getValue(item, `label`, "")}`}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              value={getValue(request, `${key_name}`, "")}
              name={key_name}
              hideLabel
            />
          </div>
        );

      case "boolean_checkbox":
        return (
          <div className="form_builder_field">
            <Checkbox
              {...item}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  [key_name]: !getValue(item, `value`, false),
                  input_value_label: getValue(e, `label`, ""),
                })
              }
              type={"checkbox"}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              label={`${
                getValue(item, `name`, "") === "name" ? label : ""
              }${" "}${getValue(item, `label`, "")}`}
              value={getValue(request, `${key_name}`, "")}
              name={key_name}
              hideLabel
            />
          </div>
        );
      case "boolean_radio":
        return (
          <div className="form_builder_field">
            <BooleanOptions
              {...item}
              onChange={(e: any) => {
                handleBooleanChange(
                  `${key_name}`,
                  getValue(e, `value`, false),
                  request,
                  setRequest
                );
                setRequest({
                  ...request,
                  input_value_label: getValue(e, `label`, ""),
                });
              }}
              data={[
                // { value: null, label: "None" },
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
              type={"radio"}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              label={`${
                getValue(item, `name`, "") === "name" ? label : ""
              }${" "}${getValue(item, `label`, "")}`}
              value={getValue(request, `${key_name}`, "")}
              name={key_name}
              hideLabel
            />
          </div>
        );
      case "boolean_select":
        return (
          <div className="form_builder_field">
            <SearchToolTip
              {...item}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              onChange={(e: any) => {
                handleBooleanChange(
                  key_name,
                  getValue(e, `value`, false),
                  request,
                  setRequest
                );
                setRequest({
                  ...request,
                  input_value_label: getValue(e, `label`, ""),
                });
              }}
              label={`label`}
              // width={"480px"}
              data={[
                // { value: null, label: "None" },
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
              selectKey={"value"}
              name={getValue(item, `label`, "")}
              value={getValue(request, `${key_name}`, "")}
              hideLabel
            />
          </div>
        );
      case "lookup_association":
        return (
          <div className="form_builder_field">
            <MultiSearchLookupTooltip
              {...item}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  [key_name]: handleUpdateArray(getValue(e, `id`, ""), []),
                })
              }
              lookup_api={getValue(item, `lookup.api_name`, "")}
              label={`label`}
              validator={required && simpleValidator}
              selectKey={"id"}
              name={getValue(item, `label`, "")}
              value={getValue(request, `${key_name}`, "")}
            />
          </div>
        );

      case "select_multiple":
        return (
          <div className="form_builder_field">
            <MultiSearchTooltip
              {...item}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  [key_name]: handleUpdateArray(getValue(e, `value`, ""), []),
                })
              }
              label={`label`}
              validator={required && simpleValidator}
              // width={"480px"}
              data={
                getValue(item, `dropdown_options.length`, 0) > 0
                  ? getValue(item, `dropdown_options`, []).map(
                      (item: object, key: number) => ({
                        ...item,
                        label: getValue(item, `label`, ""),
                        index: key,
                        name: getValue(item, `label`, ""),
                        id: getValue(item, `id`, ""),
                      })
                    )
                  : getValue(item, `picklist_options.length`, 0) > 0
                  ? getValue(item, `picklist_options`, []).map(
                      (item: object, key: number) => ({
                        ...item,
                        label: getValue(item, `label`, ""),
                        index: key,
                        name: getValue(item, `label`, ""),
                        id: getValue(item, `id`, ""),
                      })
                    )
                  : []
              }
              selectKey={"value"}
              name={getValue(item, `label`, "")}
              value={getValue(request, `${key_name}`, "")}
              hideLabel
            />
          </div>
        );

      case "picklist":
      case "select":
        return (
          <div className="form_builder_field">
            <NewSearchSelect
              {...item}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              onChange={(id: any, data: any) => {
                id
                  ? setRequest({
                      ...request,
                      [key_name]: getValue(data, `custom`, false)
                        ? getValue(data, `value`, "")
                        : [getValue(data, `value`, "")],
                      input_value_label: getValue(data, `label`, ""),
                    })
                  : setRequest({
                      ...request,
                      [key_name]: "",
                      input_value_label: "",
                    });
              }}
              label={`label`}
              validator={required && simpleValidator}
              // width={"480px"}
              options={
                getValue(item, `dropdown_options.length`, 0) > 0
                  ? getValue(item, `dropdown_options`, []).map(
                      (items: object, key: number) => ({
                        ...items,
                        label: getValue(items, `label`, ""),
                        index: key,
                        name: getValue(items, `label`, ""),
                        id: getValue(items, `id`, ""),
                        custom: getValue(item, `custom`, false),
                      })
                    )
                  : getValue(item, `picklist_options.length`, 0) > 0
                  ? getValue(item, `picklist_options`, []).map(
                      (items: object, key: number) => ({
                        ...items,
                        label: getValue(items, `label`, ""),
                        index: key,
                        name: getValue(items, `label`, ""),
                        id: getValue(items, `id`, ""),
                        custom: getValue(item, `custom`, false),
                      })
                    )
                  : []
              }
              // value={
              //   getValue(request, `${key_name}.length`, 0) > 0
              //     ? getValue(request, `${key_name}[${0}]`, "")
              //     : ""
              // }
              value={
                getValue(item, `custom`, false)
                  ? getValue(request, `${key_name}`, "")
                  : getValue(request, `${key_name}[${0}]`, "")
              }
              selectKey={"value"}
              name={getValue(item, `label`, "")}
              hideLabel
            />
          </div>
        );
      case "lookup":
        return (
          <div className="form_builder_field">
            <AntSearchToolTip
              {...item}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              onChange={(e: any, data: any) => {
                getValue(data, `id`, "")
                  ? setRequest({
                      ...request,
                      [key_name]: [getValue(data, `id`, "")],
                      input_value_label: getValue(data, `value`, ""),
                    })
                  : setRequest({
                      ...request,
                      [key_name]: [],
                      input_value_label: "",
                    });
              }}
              label={getValue(item, `label`, "")}
              selectKey={"id"}
              // name={getValue(item, `label`, "")}
              lookup_api={getValue(item, `lookup.api_name`, "")}
              value={
                getValue(request, `${key_name}.length`, 0) > 0
                  ? getValue(request, `${key_name}[${0}]`, "")
                  : ""
              }
              module_id={findModuleId()}
              hideLabel
              required={false}
              mandatory={false}
            />
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div className="workflow_builder_container">
      {renderForm(props.item, getValue(props, `item.label`, ""))}
    </div>
  );
}

export default FilterFormBuilder;
