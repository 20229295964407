import TicketsAutomation from "@pages/Private/Settings/TicketsAutomation";
import TicketsAutomationDetails from "@pages/Private/Settings/TicketsAutomation/TicketAutomationDetails";

export const SettingsTicketAutomationRoutes = [
  {
    path: "/:orgId/settings/helpdesk/tickets",
    name: "settings_templates_business_hours",
    component: TicketsAutomation,
  },
  {
    path: "/:orgId/settings/helpdesk/tickets/create",
    name: "settings_automation_workflows",
    component: TicketsAutomationDetails,
  },
  {
    path: "/:orgId/settings/helpdesk/tickets/edit/:id",
    name: "settings_automation_workflows",
    component: TicketsAutomationDetails,
  },
];
