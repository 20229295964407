import { Button } from "antd";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { formatString } from "@common/text-helpers";

const AIAgentTabHeaders = ({
  showButton,
  activeTab,
  handleSave,
  submitLoading,
}: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const orgId = getValue(params, "orgId", "");

  const items = [
    {
      key: "playground",
      value: "Playground",
      route: `/${orgId}/chatbot/${getValue(
        params,
        "chatbotId",
        ""
      )}/ai/detail/playground`,
    },
    {
      key: "chat-logs",
      value: "Chat Logs",
      route: `/${orgId}/chatbot/${getValue(
        params,
        "chatbotId",
        ""
      )}/ai/detail/chat-logs`,
    },
    {
      key: "sources",
      value: "Sources",
      route: `/${orgId}/chatbot/${getValue(
        params,
        "chatbotId",
        ""
      )}/ai/detail/sources`,
    },
    {
      key: "actions",
      value: "Actions",
      route: `/${orgId}/chatbot/${getValue(
        params,
        "chatbotId",
        ""
      )}/ai/detail/actions`,
    },
    {
      key: "share",
      value: "Share",
      route: `/${orgId}/chatbot/${getValue(
        params,
        "chatbotId",
        ""
      )}/ai/detail/share`,
    },
    {
      key: "settings",
      value: "Settings",
      route: `/${orgId}/chatbot/${getValue(
        params,
        "chatbotId",
        ""
      )}/ai/detail/settings`,
    },
  ];

  const getActiveTab = () => {
    const path = location.pathname;
    const activeItem = items.find((item) => item.route === path);
    return activeItem?.key || "responses";
  };

  const handleTabChange = (key: any) => {
    const selectedTab = items.find((item) => item.key === key);
    if (selectedTab) {
      navigate(selectedTab.route);
    }
  };

  return (
    <div className={"tabWrapper position-"}>
      <div
        className={"tabContainer w-100 px-5 "}
        style={{ borderBottom: "1px solid #efefef" }}
      >
        {/* <Tabs
          items={items.map((item) => ({ key: item.key, label: item.label }))}
          activeKey={getActiveTab()}
          onChange={handleTabChange}
          className={"tabs"}
          tabBarStyle={{
            margin: 0,
          }}
          tabBarGutter={32}
          centered
        /> */}
        <div className="d-flex align-items-center justify-content-start pt-3">
          {items.map((linkObj: object, index: number) => {
            return (
              <li
                key={`pending-${index}`}
                className={`pt-1 ${
                  getValue(location, `pathname`, "") ==
                  getValue(linkObj, `route`, "")
                    ? "activebar__main-body-list_active"
                    : "activebar__main-body-list"
                }`}
                onClick={() => navigate(getValue(linkObj, `route`, ""))}
              >
                <a className={"activebar__main-body-link p-2 "}>
                  {formatString(getValue(linkObj, `value`, ""))}
                </a>
              </li>
            );
          })}
        </div>
        {showButton && (
          <div className="pt-2 mt-1">
            <Button
              onClick={handleSave}
              disabled={submitLoading}
              loading={submitLoading}
              type="primary"
            >
              Save
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AIAgentTabHeaders;
