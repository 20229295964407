import React, { useState } from "react";
import { Input } from "antd";
import { getValue } from "@utils/lodash";
import TextRightSection from "./text-right";
import { Trash2 } from "lucide-react";

const { TextArea } = Input;

function AIAgentSourceText(props: any) {
  return (
    <>
      <div className="main-contant">
        <div className="text-container">
          <div className="text-input-wrapper">
            <TextArea
              placeholder="Enter text ..."
              value={getValue(props, `request.texts_source.texts`, "")}
              onChange={(e) =>
                props.setRequest({
                  ...props.request,
                  texts_source: {
                    ...props.request.texts_source,
                    texts: e.target.value,
                    name: e.target.value,
                  },
                })
              }
              autoSize={{ minRows: 16 }}
              bordered={false}
            />
          </div>
        </div>
        <p className="text-center mt-3 small_text__14">
          {getValue(props, `request.texts_source.texts.length`, 0)} characters
        </p>
      </div>
      <TextRightSection
        handleSaveURL={props.handleSaveURL}
        findStaticUrl={props.findStaticUrl}
        handleTrainLink={props.handleTrainLink}
        scrapeInfo={props.scrapeInfo}
        ScrapedDocumentStatus={props.ScrapedDocumentStatus}
        linkURLs={props.linkURLs}
        activeTab={props.activeTab}
        textInfo={props.textInfo}
        handleDeleteText={props.handleDeleteText}
        texts_source={props.request.texts_source}
      />
    </>
  );
}

export default AIAgentSourceText;
