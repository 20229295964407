import React, { useRef, useState, useEffect, useCallback, memo } from "react";
import {
  Image,
  MessageSquareText,
  NotepadText,
  Plus,
  Smile,
} from "lucide-react";
import EmojiPicker from "./components/EmojiPicker";
import { formatMarkdownText } from "./helpers/textFormatter";
import FormatToolbar from "./components/FormatToolbar";
import "./styles/editor.scss";
import "./styles/floating-toolbar.scss";
import { debounce } from "lodash";
import { Tooltip } from "antd";

const MarkdownEditor = (props: any) => {
  const [message, setMessage] = useState("");
  const [preview, setPreview] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [selection, setSelection] = useState<{
    text: string;
    rect?: DOMRect | null;
  }>({ text: "", rect: null });

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const isComposingRef = useRef(false);
  const messageRef = useRef("");
  const lastCursorPosition = useRef({ start: 0, end: 0 });

  // Initialize message from props
  useEffect(() => {
    const initialValue = props.value || "";
    setMessage(initialValue);
    messageRef.current = initialValue;
    setPreview(formatMarkdownText(initialValue));
  }, [props.value]);

  // Create preview update function
  const updatePreview = useCallback(
    debounce((value: string) => {
      setPreview(formatMarkdownText(value));
    }, 300),
    []
  );

  useEffect(() => {
    return () => {
      updatePreview.cancel();
    };
  }, [updatePreview]);

  // Handle selection changes
  const handleSelection = useCallback(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    lastCursorPosition.current = { start, end };

    const selectedText = messageRef.current.substring(start, end);
    if (selectedText) {
      const selection = window.getSelection();
      const range = selection?.getRangeAt(0);
      const rect = range?.getBoundingClientRect();
      setSelection({ text: selectedText, rect });
    } else {
      setSelection({ text: "", rect: null });
    }

    props.handleCursorMovement?.(textarea);
  }, [props.handleCursorMovement]);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    textarea.addEventListener("mouseup", handleSelection);
    const handleKeyUpSelection = (e: KeyboardEvent) => {
      const selectionKeys = [
        "ArrowLeft",
        "ArrowRight",
        "ArrowUp",
        "ArrowDown",
        "Home",
        "End",
        "PageUp",
        "PageDown",
      ];
      if (selectionKeys.includes(e.key) || e.shiftKey) {
        handleSelection();
      }
    };
    textarea.addEventListener("keyup", handleKeyUpSelection);

    return () => {
      textarea.removeEventListener("mouseup", handleSelection);
      textarea.removeEventListener("keyup", handleKeyUpSelection);
    };
  }, [handleSelection]);

  // Restore cursor position
  const restoreCursorPosition = useCallback((start: number, end: number) => {
    setTimeout(() => {
      const textarea = textareaRef.current;
      if (textarea) {
        textarea.focus();
        textarea.setSelectionRange(start, end);
      }
    }, 0);
  }, []);

  // Handle text change
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (isComposingRef.current) return;

      const textarea = e.target;
      const newValue = textarea.value;
      const cursorStart = textarea.selectionStart;
      const cursorEnd = textarea.selectionEnd;

      messageRef.current = newValue;
      setMessage(newValue);
      lastCursorPosition.current = { start: cursorStart, end: cursorEnd };

      // Async update for non-critical operations
      Promise.resolve().then(() => {
        props.handleChangeEditor?.(newValue);
        updatePreview(newValue);
      });
    },
    [updatePreview, props.handleChangeEditor]
  );

  // Handle formatting
  const handleFormat = useCallback(
    (format: string) => {
      const textarea = textareaRef.current;
      if (!textarea) return;

      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const scrollTop = textarea.scrollTop;

      const selectedText = messageRef.current.substring(start, end);
      const beforeText = messageRef.current.substring(0, start);
      const afterText = messageRef.current.substring(end);

      let newText, newStart, newEnd;
      if (selectedText) {
        newText = `${beforeText}${format}${selectedText}${format}${afterText}`;
        newStart = start + format.length;
        newEnd = end + format.length;
      } else {
        newText = `${beforeText}${format}${format}${afterText}`;
        newStart = start + format.length;
        newEnd = newStart;
      }

      messageRef.current = newText;
      setMessage(newText);
      lastCursorPosition.current = { start: newStart, end: newEnd };

      // Async update for non-critical operations
      Promise.resolve().then(() => {
        props.handleChangeEditor?.(newText);
        updatePreview(newText);
      });

      textarea.scrollTop = scrollTop;
      restoreCursorPosition(newStart, newEnd);
    },
    [updatePreview, props.handleChangeEditor, restoreCursorPosition]
  );

  // Handle emoji selection
  const handleEmojiSelect = useCallback(
    (emoji: any) => {
      const textarea = textareaRef.current;
      if (!textarea) return;

      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const scrollTop = textarea.scrollTop;

      const emojiToInsert = emoji.native || emoji.emoji || emoji.toString();
      const beforeText = messageRef.current.substring(0, start);
      const afterText = messageRef.current.substring(end);
      const newText = `${beforeText}${emojiToInsert}${afterText}`;
      const newPosition = start + emojiToInsert.length;

      messageRef.current = newText;
      setMessage(newText);
      lastCursorPosition.current = { start: newPosition, end: newPosition };

      // Async update for non-critical operations
      Promise.resolve().then(() => {
        props.handleChangeEditor?.(newText);
        updatePreview(newText);
      });

      setShowEmojiPicker(false);
      textarea.scrollTop = scrollTop;
      restoreCursorPosition(newPosition, newPosition);
    },
    [updatePreview, props.handleChangeEditor, restoreCursorPosition]
  );

  // Handle file upload
  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = Array.from(e.target.files || []) as File[];
      if (props.allowedFileTypes) {
        const allowedExtensions = props.allowedFileTypes
          .split(",")
          .map((ext: string) => ext.trim().toLowerCase());
        const invalidFiles = files.filter((file: File) => {
          const extension = `.${file.name.split(".").pop()?.toLowerCase()}`;
          return !allowedExtensions.includes(extension);
        });

        if (invalidFiles.length > 0) {
          alert(
            `Invalid file type(s): ${invalidFiles
              .map((f) => f.name)
              .join(", ")}`
          );
          e.target.value = "";
          return;
        }
      }

      const start = textareaRef.current?.selectionStart || 0;
      const end = textareaRef.current?.selectionEnd || 0;

      // Store current position before upload
      lastCursorPosition.current = { start, end };

      props.handleUploadDocument?.(e);
      e.target.value = "";

      // Restore cursor position after upload
      setTimeout(() => {
        restoreCursorPosition(start, end);
      }, 0);
    },
    [props.allowedFileTypes, props.handleUploadDocument, restoreCursorPosition]
  );

  // Handle key down events
  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (!props.removeEnterAction && e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        if (messageRef.current.trim()) {
          props.handleChangeEditor?.(messageRef.current);
          messageRef.current = "";
          setMessage("");
          setPreview("");
          lastCursorPosition.current = { start: 0, end: 0 };
        }
      }
    },
    [props.removeEnterAction, props.handleChangeEditor]
  );

  return (
    <div className="editor">
      {!props.disabled && (
        <div className="editor__toolbar">
          <FormatToolbar onFormat={handleFormat} />
          {!props.hideEmoji && (
            <div className="editor__emoji">
              <Tooltip title="Emoji">
                <button
                  onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  className="emoji-button"
                  title="Emoji"
                >
                  <Smile color="#606060" />
                </button>
              </Tooltip>
              {showEmojiPicker && (
                <div className="emoji-picker">
                  <EmojiPicker
                    onEmojiSelect={handleEmojiSelect}
                    onClose={() => setShowEmojiPicker(false)}
                  />
                </div>
              )}
            </div>
          )}
          {props.showCanned && (
            <div className="format-toolbar">
              <Tooltip title="Canned Response">
                <button onClick={() => props.handleCannedEditorButton?.()}>
                  <MessageSquareText color="#606060" size={20} />
                </button>
              </Tooltip>
            </div>
          )}
          {props.showUpload && (
            <div className="format-toolbar">
              <Tooltip title="Upload">
                <button onClick={() => fileInputRef.current?.click()}>
                  <Image color="#606060" size={20} />
                </button>
                <input
                  type="file"
                  className="visually-hidden"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  accept={props.allowedFileTypes || "*"}
                />
              </Tooltip>
            </div>
          )}
          {props.showBroadcast && (
            <div className="format-toolbar">
              <Tooltip title="Templates">
                <button onClick={() => props.handleBroadcastEditorButton?.()}>
                  <NotepadText color="#606060" size={20} />
                </button>
              </Tooltip>
            </div>
          )}
          {props.showMediaLibrary && (
            <div className="format-toolbar">
              <Tooltip title="Media Library">
                <button onClick={() => props.handleMediaLibraryButton?.()}>
                  <Plus color="#606060" size={20} />
                </button>
              </Tooltip>
            </div>
          )}
        </div>
      )}

      <div className="editor__content">
        {props.showMaxLength && message.length >= props.maxLength && (
          <p className="m-0 error-text text-end">
            Maximum character limit ({props.maxLength}) reached
          </p>
        )}
        <textarea
          ref={textareaRef}
          value={message}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onCompositionStart={() => (isComposingRef.current = true)}
          onCompositionEnd={() => {
            isComposingRef.current = false;
            if (textareaRef.current) {
              const newValue = textareaRef.current.value;
              messageRef.current = newValue;
              setMessage(newValue);
              Promise.resolve().then(() => {
                props.handleChangeEditor?.(newValue);
                updatePreview(newValue);
              });
            }
          }}
          placeholder="Type a message..."
          style={props.style}
          maxLength={props.maxLength}
          className={
            props.showMaxLength && message.length >= props.maxLength
              ? "editor-textarea-error"
              : ""
          }
          disabled={props.disabled}
        />
      </div>
    </div>
  );
};

export default memo(MarkdownEditor);
