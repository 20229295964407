import { convertDate, getDateFunctions } from "@common/date-helpers";
import { QueryRequestHelper } from "@common/query-request-helper";
import TextHeaderTooltip from "@components/custom/Dropdown/TextHeaderTooltip";
import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import CloseMainSvgComponent from "@assets/svg/close-main";
import { dateFilters } from "@common/Date-Helpers/date-filters-helpers";
import BetaSvgComponent from "@assets/svg/beta";
import NewSearchToolTip from "@components/custom/Dropdown/NewSearchTooltip";
import { ChevronsUpDown, Star } from "lucide-react";
import { Button, Segmented, Select, Tooltip } from "antd";
import {
  createBookmarkedModules,
  deleteBookmark,
} from "@services/bookmark.service";
import NewSearchSelect from "@components/custom/Dropdown/AntDropDown";
import { capitalizeFirstLetter } from "@common/text-helpers";

function DashboardHeader(props: any) {
  const {
    isLoading,
    dateList,
    addChildren,
    bookmarkedModulePath,
    bookmarkedModules,
    themeColor,
    refreshBookmarks,
    bookmarkedModuleName,
    viewList,
  } = props;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const params = useParams();
  const generateRequestArray = (array: any) => {
    return array.map((item: any) => ({
      ...item,
      value: getValue(item, `api_name`, ""),
      label: getValue(item, `label`, ""),
    }));
  };
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                                Onchange Section                            */
  /* -------------------------------------------------------------------------- */
  const handleClickModule = (value: any) => {
    delete UrlParams.pipeline;
    // delete UrlParams.date_range;
    let payload = {
      ...UrlParams,
      module: getValue(value, `name`, ""),
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`/${getValue(params, `orgId`, "")}/dashboard?${queryRequest}`);
  };

  const handleClickPipeline = (value: object) => {
    let payload = {
      ...UrlParams,
      pipeline: getValue(value, `id`, ""),
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`/${getValue(params, `orgId`, "")}/dashboard?${queryRequest}`);
  };
  /* -------------------------------------------------------------------------- */
  /*                               Date Filter Section                          */
  /* -------------------------------------------------------------------------- */
  const [data, setData] = useState<any>([
    { label: "Today", value: "" },
    { label: "Last week", value: "" },
    { label: "This month", value: "" },
    { label: "This Year", value: "" },
  ]);

  useEffect(() => {
    setData(getDateFunctions());
  }, []);

  const handleClickDate = (e: any) => {
    props.setDashboardLoading(true);
    let payload = {
      ...UrlParams,
      date_range: e,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`/${getValue(params, `orgId`, "")}/dashboard?${queryRequest}`);
  };
  /* -------------------------------------------------------------------------- */
  /*                                  Date section                              */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (getValue(UrlParams, `date_range`, "")) {
      let dates = getValue(UrlParams, `date_range`, "").split(",");
      setStartDate(new Date(getValue(dates, `[${0}]`, "")));
      setEndDate(new Date(getValue(dates, `[${1}]`, "")));
    }
  }, [window.location.href]);

  const handleChangeCustomDate = (name: string, start: any, end: any) => {
    if (start && end) {
      handleSelectFilter(name, `${start},${end}`, "custom");
    }
  };

  const handleSelectFilter = (key: string, value: string, type: string) => {
    if (type === "custom") {
    } else {
      delete UrlParams[`${key}_custom`];
    }
    let payload = {
      ...UrlParams,
      page_no: 1,
      [key]: value,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const handleCloseFilter = (name: string) => {
    delete UrlParams[`${name}`];
    delete UrlParams[`${name}_custom`];
    let payload = {
      ...UrlParams,
      page_no: 1,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    handleChangeCustomDate("date_range", convertDate(start), convertDate(end));
  };

  const handleNavigateDashboard = async () => {
    let payload = {
      ...UrlParams,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`/${getValue(params, `orgId`, "")}/new-dashboard?${queryRequest}`);
  };

  const dashboardViewFilters = [
    { label: "CRM", value: "crm" },
    { label: "Chat", value: "chat" },
  ];

  const handleClickView = (view: any) => {
    const newView = view;
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.delete("page_no");
    urlSearchParams.delete("page_size");
    urlSearchParams.set("view", newView);
    window.history.pushState(
      {},
      document.title,
      `?${urlSearchParams.toString()}`
    );
    props.setView(newView);
  };

  useEffect(() => {
    const list = [
      {
        label: "Overview",
        value: "overview",
        name: "dashboard_chat_conversation_overview",
      },
      {
        label: "Agent Availability",
        value: "agent_availability",
        name: "dashboard_chat_conversation_agent_availability",
      },
      {
        label: "Agent Performance",
        value: "agent_performance",
        name: "dashboard_chat_conversation_agent_performance",
      },
    ];

    // Get subTabPermissionList from props safely
    const permissionList = getValue(props, "subTabPermissionList", []);

    // Filter based on matching names
    const dashboardAgentChatFilters = list.filter((item) =>
      permissionList.includes(item.name)
    );

    // Update state
    setDashboardAgentChatFilters(dashboardAgentChatFilters);
  }, [props.subTabPermissionList]); // Depend on permission list to re-run when it changes

  // subTabPermissionList
  const [dashboardAgentChatFilters, setDashboardAgentChatFilters] =
    useState<any>([
      {
        label: "Overview",
        value: "overview",
        name: "dashboard_chat_conversation_overview",
      },
      {
        label: "Agent Availability",
        value: "agent_availability",
        name: "dashboard_chat_conversation_agent_availability",
      },
      {
        label: "Agent Performance",
        value: "agent_performance",
        name: "dashboard_chat_conversation_agent_performance",
      },
    ]);

  const handleClickAgentChat = (e: any) => {
    const newChatView = getValue(e, `value`, "");
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.delete("page_no");
    urlSearchParams.delete("page_size");
    urlSearchParams.set("chat_view", newChatView);
    window.history.pushState(
      {},
      document.title,
      `?${urlSearchParams.toString()}`
    );
    props.setChatView(newChatView);
  };

  const createBookmark = async () => {
    let resp = await createBookmarkedModules({
      name: capitalizeFirstLetter(bookmarkedModuleName),
      path: bookmarkedModulePath,
    });

    if (resp) {
      refreshBookmarks();
    }
  };

  const removeBookmark = async (id: string) => {
    let resp = await deleteBookmark(id);
    if (resp) {
      refreshBookmarks();
    }
  };

  return (
    <div className={"common-subheader"}>
      <div className={"common-subheader-wrapper"}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center gap-2">
            {bookmarkedModulePath &&
              (bookmarkedModules.find(
                (bookmarkItem: any) =>
                  bookmarkItem.path === bookmarkedModulePath
              ) ? (
                <Tooltip title="Remove from bookmarks">
                  <Button
                    color="default"
                    variant="text"
                    onClick={() =>
                      removeBookmark(
                        bookmarkedModules.find(
                          (bookmarkItem: any) =>
                            bookmarkItem.path === bookmarkedModulePath
                        ).id
                      )
                    }
                    icon={
                      <Star color={themeColor} fill={themeColor} size={18} />
                    }
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Add to bookmarks">
                  <Button
                    color="primary"
                    variant="text"
                    onClick={createBookmark}
                    icon={<Star size={18} color={themeColor} />}
                  />
                </Tooltip>
              ))}

            {/* <TextHeaderTooltip
              title={`View`}
              data={dashboardViewFilters}
              label={"label"}
              value={getValue(UrlParams, `view`, "")}
              onChange={handleClickView}
              width={"270px"}
              selectKey={"value"}
              editInline
            /> */}

            <Segmented
              options={viewList}
              onChange={(value: any) => {
                handleClickView(value);
              }}
              value={getValue(UrlParams, `view`, "")}
            />

            {!getValue(UrlParams, `view`, "") && (
              <>
                <TextHeaderTooltip
                  data={generateRequestArray(getValue(props, `allModules`, []))}
                  label={"label"}
                  title={getValue(props, `selectedModule`, "")}
                  value={getValue(props, `selectedModule`, "")}
                  onChange={handleClickModule}
                  width={"270px"}
                  selectKey={"value"}
                  editInline
                />

                {props.isTitleVisible && (
                  <>
                    <div>
                      <img
                        src="/images/header/right-arrow.svg"
                        className="img-fluid"
                      />
                    </div>
                    <p className={"Service-text"}>Service</p>
                  </>
                )}
                {getValue(props, `selectedModule`, "") !== "tasks" &&
                getValue(props, `selectedModule`, "") !== "contacts" &&
                getValue(props, `selectedModule`, "") !== "companies" ? (
                  <NewSearchToolTip
                    size="large"
                    id="select-1"
                    data={getValue(props, `pipelines`, [])}
                    label={"label"}
                    // name={getValue(props, `selectedPipeline`, "")}
                    value={getValue(props, `selectedPipeline`, "")}
                    onChange={handleClickPipeline}
                    width={"280px"}
                    isLoading={isLoading}
                    selectKey={"id"}
                  />
                ) : (
                  <div style={{ height: "40px" }}></div>
                )}
              </>
            )}
            {getValue(UrlParams, `view`, "") === "chat" && (
              <Select
                options={dashboardAgentChatFilters}
                value={getValue(UrlParams, `chat_view`, "") || "overview"}
                onChange={(value: any, data: any) => handleClickAgentChat(data)}
                bordered={false}
                suffixIcon={<ChevronsUpDown size={16} />}
                dropdownStyle={{ minWidth: 280 }}
              />
            )}
          </div>
          {/* <TextHeaderTooltip
            title={`Date Range`}
            data={dateList}
            label={`label`}
            value={getValue(props, `selectedDateRange`, "")}
            onChange={(e: any) => handleClickDate(getValue(e, `value`, ""))}
            selectKey={"value"}
            width={"270px"}
            editInline
          /> */}
          <div className="d-flex align-items-center gap-2">
            {/* {(!getValue(UrlParams, `view`, "") ||
              getValue(UrlParams, `view`, "") === "crm") && (
              <div className="d-flex align-items-center gap-2">
                <div
                  className="d-flex align-items-center gap-1"
                  onClick={() => handleNavigateDashboard()}
                >
                  <p className="header_text__16 color_primary cursor-pointer">
                    Dashboard 2.0
                  </p>
                  <div>
                    <BetaSvgComponent />
                  </div>
                </div>
              </div>
            )} */}
            {!getValue(props, `hideCloseDate`, false) && (
              <div className={"filter_module"}>
                {getValue(UrlParams, `date_range_custom`, "") ? (
                  <div className="position-relative">
                    {/* <label className="absolute-label">Close Date</label> */}
                    <div className="d-flex align-items-center inline_date_active px-2">
                      <DatePicker
                        className="custom-datepicker-wrapper"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        dropdownMode="select"
                        placeholderText={"Enter Close Date"}
                        dateFormat="d MMM yyyy"
                        autoFocus={true}
                        focusSelectedMonth
                      />
                      <div
                        className="inline_close_image_container"
                        onClick={(e) => {
                          setStartDate(null);
                          setEndDate(null);
                          handleCloseFilter("date_range");
                        }}
                      >
                        <CloseMainSvgComponent size={18} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <TextHeaderTooltip
                    title={`Today`}
                    data={dateFilters}
                    label={`label`}
                    value={getValue(
                      UrlParams,
                      `date_range${
                        getValue(UrlParams, `date_range_custom`, "") ===
                        "custom"
                          ? "_custom"
                          : ""
                      }`,
                      ""
                    )}
                    onChange={(e: any) =>
                      handleSelectFilter(
                        `date_range${
                          getValue(e, `value`, "") === "custom" ? "_custom" : ""
                        }`,
                        getValue(e, `value`, ""),
                        ""
                      )
                    }
                    selectKey={"value"}
                    width={"270px"}
                    showActiveInline
                  />
                )}
              </div>
            )}

            {addChildren && addChildren}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardHeader;
