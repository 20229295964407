import { deleteCategory } from "@services/solutions.service";
import { getValue } from "@utils/lodash";
import { Dropdown, Input, Modal, Spin } from "antd";
import {
  Check,
  ChevronRight,
  EllipsisVertical,
  Pencil,
  PlusCircle,
  Trash2,
  X,
} from "lucide-react";
import { toast } from "sonner";
import RenderFolders from "./Folders/RenderFolders";
import InfiniteScroll from "react-infinite-scroll-component";
import { AnimatePresence, motion } from "framer-motion";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { useEffect, useState } from "react";

const CategoryDetailsLeftSide = (props: any) => {
  const {
    isLoading,
    selectedFolder,
    handleFolderEdit,
    selectedArticle,
    categoriesList,
    handleCreateFolderModal,
    showCategoryModal,
    getCategoriesList,
    handleCreateArticle,
    categoriesRequest,
    setCategoriesRequest,
    isCategoryCreationOpen,
    setIsCategoryCreationOpen,
    handleCategoriesSubmit,
    articleRequest,
    selectedCategory,
    setSelectedCategory,
    setPreviousArticle,
    setNextArticle,
    limit,
    fetchMoreData,
    hasMore,
  } = props;

  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const handleAccordionChange = (expanded: string[]) => {
    setExpandedItems(expanded);
  };

  useEffect(() => {
    if (categoriesList.length > 0) {
      const initialExpandedItems = categoriesList.map((category: any) =>
        getValue(category, "id", "")
      );

      setExpandedItems(initialExpandedItems);
    }
  }, [categoriesList]);

  const handleDeleteModal = (
    module: string,
    name: string,
    api: any,
    id: string
  ) => {
    Modal.confirm({
      title: `Are you sure you want to delete ${module} - ${name}?`,
      content: "Once deleted, it cannot be recovered.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          let resp = await api(id);
          if (resp) {
            toast.success(`${module} deleted successfully`);
            getCategoriesList(limit);
          }
        } catch (error) {
          toast.error(`Error deleting ${module}`);
        }
      },
    });
  };

  const renderCategoryMenu = (category: any) => [
    {
      key: "new",
      icon: <PlusCircle size={14} />,
      label: "New Folder",
      onClick: () => handleCreateFolderModal(getValue(category, "id", "")),
    },
    {
      key: "edit",
      icon: <Pencil size={14} />,
      label: "Edit",
      onClick: () =>
        showCategoryModal(
          getValue(category, "id", ""),
          getValue(category, "name", "")
        ),
    },
    {
      key: "delete",
      icon: <Trash2 size={14} />,
      label: "Delete",
      onClick: () =>
        handleDeleteModal(
          "Category",
          getValue(category, "name", ""),
          deleteCategory,
          getValue(category, "id", "")
        ),
      disabled: getValue(category, "folders.length", 0) > 0,
    },
  ];

  const allCategoryIds = categoriesList.map((category: any) => {
    return getValue(category, "id", "");
  });

  return (
    <div className="category-details-leftside">
      {selectedCategory && allCategoryIds && expandedItems ? (
        <InfiniteScroll
          dataLength={categoriesList.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            isLoading ? (
              <div className="d-flex justify-content-center">
                <Spin />
              </div>
            ) : null
          }
          scrollableTarget="category-top-container"
          className="category-infinite-scroller"
        >
          <div
            className="category-top-container py-4 px-3"
            id="category-top-container"
          >
            <Accordion
              className="category-accordion"
              allowZeroExpanded
              allowMultipleExpanded
              preExpanded={allCategoryIds}
              onChange={handleAccordionChange}
            >
              {categoriesList.map((category: any, index: number) => {
                const categoryId = getValue(category, "id", "");
                const isExpanded = expandedItems.includes(categoryId);
                return (
                  <AccordionItem
                    key={categoryId}
                    uuid={categoryId}
                    className={`mb-3 ${
                      index === categoriesList.length - 1
                        ? ""
                        : "border-bottom pb-3"
                    }`}
                  >
                    <AccordionItemHeading>
                      <AccordionItemButton className="d-flex align-items-center justify-content-between gap-3 category-header button-unset-all">
                        <div
                          className={`d-flex align-items-center gap-2 ${
                            selectedCategory === categoryId
                              ? "text-black header_text__14"
                              : "header_text__14"
                          }`}
                          onClick={() => setSelectedCategory(categoryId)}
                        >
                          {getValue(category, "name", "")}
                          <motion.div
                            animate={{
                              rotate: isExpanded ? 90 : 0,
                            }}
                            transition={{ ease: "linear", duration: 0.2 }}
                          >
                            <ChevronRight size={16} />
                          </motion.div>
                        </div>

                        <Dropdown
                          menu={{ items: renderCategoryMenu(category) }}
                          className="more-options"
                        >
                          <EllipsisVertical
                            size={14}
                            className="cursor-pointer"
                          />
                        </Dropdown>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <AnimatePresence>
                        <motion.div
                          key="folder"
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: "auto" }}
                          exit={{ opacity: 0, height: 0 }}
                          transition={{ duration: 0.1, ease: "easeIn" }}
                        >
                          <RenderFolders
                            category={category}
                            selectedFolder={selectedFolder}
                            handleCreateArticle={handleCreateArticle}
                            handleFolderEdit={handleFolderEdit}
                            handleDeleteModal={handleDeleteModal}
                            articleRequest={articleRequest}
                            selectedArticle={selectedArticle}
                            setPreviousArticle={setPreviousArticle}
                            setNextArticle={setNextArticle}
                          />
                        </motion.div>
                      </AnimatePresence>
                    </AccordionItemPanel>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </div>
        </InfiniteScroll>
      ) : (
        <div className="d-flex align-items-center justify-content-center mt-5 pt-5">
          <Spin />
        </div>
      )}
      <div className="category-bottom-container">
        {isCategoryCreationOpen ? (
          <div className="category-creation-input">
            <Input
              value={getValue(categoriesRequest, "name", "")}
              onChange={(e) =>
                setCategoriesRequest((prev: any) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleCategoriesSubmit();
                }
              }}
            />
            <div className="d-flex align-items-center gap-2">
              <X
                size={16}
                color="red"
                className="cursor-pointer"
                onClick={() => setIsCategoryCreationOpen(false)}
              />
              <Check
                size={16}
                className="cursor-pointer"
                onClick={() => handleCategoriesSubmit()}
                color="#08d200"
              />
            </div>
          </div>
        ) : (
          <div
            className="add-button justify-content-center bg-white"
            onClick={() => setIsCategoryCreationOpen(true)}
          >
            <PlusCircle size={16} /> Create Category
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryDetailsLeftSide;
