import * as React from "react";
import {
  EditorState,
  ContentState,
  convertToRaw,
  SelectionState,
  Modifier,
  RichUtils,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtmlPuri from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "draft-js/dist/Draft.css";
import { getValue } from "@utils/lodash";
import { motion } from "framer-motion";

const FormBuilderDraftJSRefEditor = (props: any) => {
  const [editor, setEditor] = React.useState(true);
  const [editorState, setEditorState] = React.useState<any>(() =>
    EditorState.createEmpty()
  );
  const domEditorRef = React.useRef<any>(null);
  const [isFocused, setIsFocused] = React.useState(false);
  const editorRef = React.useRef<any>(null);

  // Initialize editor with content
  React.useEffect(() => {
    try {
      const htmlContent = getValue(props, `editorState`, "") || "";
      const blocksFromHtml = htmlToDraft(htmlContent);
      
      if (blocksFromHtml) {
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
        
        // Focus editor if needed
        if (props.autoFocus && domEditorRef.current && !props.hideFocus) {
          domEditorRef.current.focusEditor();
        }
      }
    } catch (error) {
      console.error("Error initializing editor:", error);
      // Fallback to empty state
      setEditorState(EditorState.createEmpty());
    }
  }, []);

  // Update editor when props.editorState changes
  React.useEffect(() => {
    const currentContent = editorState.getCurrentContent();
    const currentHtml = draftToHtmlPuri(convertToRaw(currentContent));
    
    if (props.editorState && props.editorState !== currentHtml && !editor) {
      try {
        const blocksFromHtml = htmlToDraft(props.editorState);
        
        if (blocksFromHtml) {
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          );
          
          // Create a selection state that points to the end of the content
          const lastBlock = contentState.getLastBlock();
          const endOfBlock = lastBlock.getLength();
          const selectionState = SelectionState.createEmpty(
            lastBlock.getKey()
          ).merge({
            anchorOffset: endOfBlock,
            focusOffset: endOfBlock,
          });
          
          // Apply the content and selection
          const newEditorState = EditorState.createWithContent(contentState);
          const stateWithSelection = EditorState.forceSelection(
            newEditorState,
            selectionState
          );
          
          setEditorState(stateWithSelection);
        }
      } catch (error) {
        console.error("Error updating editor state:", error);
      }
    }
  }, [props.editorState, editor]);

  // Handle editor state changes
  const onEditorStateChange = (newEditorState: any) => {
    setEditorState(newEditorState);
    if (props.handleChangeEditor) {
      const html = draftToHtmlPuri(convertToRaw(newEditorState.getCurrentContent()));
      props.handleChangeEditor(props.name, html, newEditorState);
    }
  };

  // Focus/blur handlers
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  // Set editor reference
  const setEditorReference = (ref: any) => {
    if (ref) {
      editorRef.current = ref;
    }
  };

  // Improved paste handler
  const handlePastedText = (text: string, html: string, editorState: EditorState) => {
    try {
      // If it's plain text, insert it at the current selection
      const selection = editorState.getSelection();
      const contentState = editorState.getCurrentContent();
      const newContentState = Modifier.replaceText(contentState, selection, text);
      const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');
      
      onEditorStateChange(newEditorState);
      return true; // Indicate we've handled the paste
    } catch (error) {
      console.error("Error handling pasted text:", error);
      return false; // Let the default paste handler take over
    }
  };

  // Handle key commands
  const handleKeyCommand = (command: string, editorState: EditorState) => {
    // For "split-block" (Enter key), we want to insert a new line instead of submitting
    if (command === "split-block") {
      // If submitOnEnter is true, handle submission
      if (props.submitOnEnter) {
        props.handleSubmit && props.handleSubmit();
        return 'handled';
      }
      
      // Otherwise, insert a new line (default behavior)
      return 'not-handled';
    }
    
    // Try to handle other commands with RichUtils
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onEditorStateChange(newState);
      return 'handled';
    }
    
    return 'not-handled';
  };

  // Handle return/enter key
  const handleReturn = (event: React.KeyboardEvent) => {
    // If submitOnEnter is true and shift is not pressed, submit
    if (props.submitOnEnter && !event.shiftKey) {
      props.handleSubmit && props.handleSubmit();
      return 'handled';
    }
    
    // Otherwise, create a new line (default behavior)
    return 'not-handled';
  };

  // Focus editor when autoFocus prop changes
  React.useEffect(() => {
    if (props.autoFocus && domEditorRef.current && !props.hideFocus) {
      domEditorRef.current.focusEditor();
    }
  }, [props.autoFocus]);

  return (
    <motion.div
      initial={{ y: 0, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 20, opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Editor
        ref={domEditorRef}
        toolbarHidden={props.toolbarHidden}
        editorState={editorState}
        editorRef={setEditorReference}
        toolbarClassName={
          props.floatToolbar
            ? !props.editorState || props.editorState === "<p></p>\n"
              ? "emailToolbar"
              : "emailToolbarActive"
            : "emailToolbarActive"
        }
        wrapperClassName="wrapperClassName"
        editorClassName={
          props.editorClassName ? props.editorClassName : "editorClassName"
        }
        onEditorStateChange={onEditorStateChange}
        placeholder={props.placeholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
        handlePastedText={handlePastedText}
        handleKeyCommand={handleKeyCommand}
        // handleReturn={handleReturn}
        editorStyle={{
          ...props.editorStyle,
          border: 
            props.border ?
            "1px solid #efefef" :
            props.focus
              ? isFocused
                ? `1px solid ${
                    getValue(props, `focusColor`, "") || "#0279ff"
                  }`
                : "1px solid #efefef"
              : "none",
          transition: "all 0.3s ease-in-out",
          boxShadow:
            getValue(props, `focusColor`, "") &&
            isFocused &&
            `0px 0px 3px 0px ${
              getValue(props, `focusColor`, "") || "#0279ff"
            }`,
          borderRadius: "8px",
        }}
        toolbar={
          props.toolbar || {
            options: ["inline", "blockType", "fontSize", "list", "link"],
          }
        }
      />
    </motion.div>
  );
};

export default FormBuilderDraftJSRefEditor;