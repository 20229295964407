import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import useDynamicTitle from "@context/useDynamicTitle";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import SettingModuleNavbar from "../../ModuleAndField/ManageFields/common/Navbar";
import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";
import {
  createTicketStatus,
  getAllTicketStatus,
  updateTicketStatus,
  updateTicketStatusPosition,
  updateTicketStatusStatus,
} from "@services/ticket-status.service";
import { useNavigate, useParams } from "react-router-dom";
import { QueryRequestHelper } from "@common/query-request-helper";
import StatusDragAndDropList from "./components/StatusDragAndDropList";
import { toast } from "sonner";
import TicketStatusModal from "./components/TicketStatusModal";
import { Pagination } from "antd";

const HelpDeskTicketStatus = (props: any) => {
  useDynamicTitle("Ticket Status");
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const [list, setList] = useState([]);
  const [ticketOpenList, setTicketOpenList] = useState([]);
  const [ticketResolvedList, setTicketResolvedList] = useState([]);
  const [ticketPendingList, setTicketPendingList] = useState([]);
  const [editId, setEditId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [request, setRequest] = useState({
    label: "",
    type: "open",
    description: "",
    color_code: "",
    is_active: true,
  });

  const statusType = ["open", "resolved", "pending"];

  const handleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleAddStage = (name: string) => {
    setRequest({
      ...request,
      type: name,
    });
    setEditId("");
    handleModal();
  };

  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (window.location.pathname.includes("ticket-fields/status")) {
      getTicketStatusList();
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getTicketStatusList();
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getTicketStatusList = async () => {
    try {
      setIsLoading(true);
      const payload = {
        page_size: getValue(UrlParams, `page_size`, "")
          ? getValue(UrlParams, `page_size`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getAllTicketStatus(queryRequest);
      if (resp) {
        setList(getValue(resp, "data.ticket_statuses", []));
        const updatedTicketStatuses = getValue(
          resp,
          "data.ticket_statuses",
          []
        );
        setTicketOpenList(
          updatedTicketStatuses.filter((status: any) => status.type === "open")
        );
        setTicketResolvedList(
          updatedTicketStatuses.filter(
            (status: any) => status.type === "resolved"
          )
        );
        setTicketPendingList(
          updatedTicketStatuses.filter(
            (status: any) => status.type === "pending"
          )
        );
        setPage_no(getValue(resp, "data.pagination.page", 0));
        setTotalCount(getValue(resp, "data.pagination.total", 0));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Create Section                               */
  /* -------------------------------------------------------------------------- */

  const handleCreateStatus = async () => {
    try {
      setIsLoading1(true);
      let resp = await createTicketStatus(request);
      if (resp) {
        toast.success("Created successfully");
        setIsLoading(false);
        getTicketStatusList();
        handleReset();
        handleModal();
      }
    } catch (error) {
      setIsLoading1(false);
    }
  };
  const handleUpdateStatus = async () => {
    try {
      setIsLoading(true);
      let resp = await updateTicketStatus(editId, request);
      if (resp) {
        toast.success("Updated successfully");
        setIsLoading(false);
        getTicketStatusList();
        handleReset();
        handleModal();
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Edit Section                                 */
  /* -------------------------------------------------------------------------- */

  const handleUpdateStageStatus = async (id: object, status: string) => {
    try {
      setIsLoading(true);
      let resp = await updateTicketStatusStatus({
        ids: [id],
        is_active: status,
      });
      if (resp) {
        getTicketStatusList();
        toast.success("Updated successfully");
        setIsLoading(false);
        handleReset();
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handlePositionUpdate = async (allListsData: any) => {
    try {
      setIsLoading(true);
      const resp = await updateTicketStatusPosition({
        swap_fields: {
          open_statuses: allListsData.open_statuses,
          resolved_statuses: allListsData.resolved_statuses,
          pending_statuses: allListsData.pending_statuses,
        },
      });
      if (resp) {
        getTicketStatusList();
        toast.success("Updated successfully");
        setIsLoading(false);
        handleReset();
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: any, limit: any) => {
    setPage_no(page_no);
    setLimit(limit);

    let payload = {
      ...UrlParams,
      page_no: page_no,
      page_size: limit,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    setPage_no(page_no);
  };

  /* -------------------------------------------------------------------------- */
  /*                            Helper Section                                  */
  /* -------------------------------------------------------------------------- */
  const handleReset = () => {
    setRequest({
      label: "",
      type: "open",
      description: "",
      color_code: "",
      is_active: true,
    });
    setEditId("");
  };

  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        icon="fields"
        title="Ticket Status"
        backButton
      />
      <div className="main_container d-flex align-items-start">
        <div className="manage-field__navigation px-3 ">
          <h6 className="mb-2 pt-3">Ticket Status</h6>
          <p className="manage-field__text">
            You can manage and create new stages here. Also, you can select
            colors for all the stages to view Gantt bars in specified colors.
          </p>
        </div>
        <div className="w-100">
          <SettingModuleNavbar
            tab={"stages"}
            params={getValue(props, `params`, {})}
            handleNavigate={props.handleNavigate}
            toggle={props.toggle}
            code={"tickets"}
          />
          <div className="p-3 main_container_list">
            <StatusDragAndDropList
              list={ticketOpenList}
              editId={editId}
              type="open"
              title={"Open Status"}
              handleUpdateStageStatus={handleUpdateStageStatus}
              handlePositionUpdate={handlePositionUpdate}
              setTicketOpenList={setTicketOpenList}
              ticketOpenList={ticketOpenList}
              ticketResolvedList={ticketResolvedList}
              ticketPendingList={ticketPendingList}
              handleAddStage={handleAddStage}
              handleUpdateStatus={handleUpdateStatus}
              handleModal={handleModal}
              setEditId={setEditId}
              setRequest={setRequest}
              stageRequest={list}
              setStageRequest={setList}
              getData={getTicketStatusList}
            />
            <StatusDragAndDropList
              list={ticketResolvedList}
              editId={editId}
              type="resolved"
              title={"Resolved Status"}
              handleUpdateStageStatus={handleUpdateStageStatus}
              handleAddStage={handleAddStage}
              handleUpdateStatus={handleUpdateStatus}
              handleModal={handleModal}
              setEditId={setEditId}
              setRequest={setRequest}
              stageRequest={list}
              setStageRequest={setList}
              getData={getTicketStatusList}
            />
            <StatusDragAndDropList
              list={ticketPendingList}
              editId={editId}
              type="pending"
              title={"Pending Status"}
              handleUpdateStageStatus={handleUpdateStageStatus}
              handlePositionUpdate={handlePositionUpdate}
              setTicketPendingList={setTicketPendingList}
              ticketOpenList={ticketOpenList}
              ticketResolvedList={ticketResolvedList}
              ticketPendingList={ticketPendingList}
              handleAddStage={handleAddStage}
              handleUpdateStatus={handleUpdateStatus}
              handleModal={handleModal}
              setEditId={setEditId}
              setRequest={setRequest}
              stageRequest={list}
              setStageRequest={setList}
              getData={getTicketStatusList}
            />
            {totalCount > 10 && (
              <Pagination
                className="mt-3"
                align="center"
                current={page_no}
                pageSize={limit}
                total={totalCount}
                showSizeChanger
                showTotal={(total) => `Total ${total} items`}
                onChange={handleChangePagination}
                onShowSizeChange={handleChangePagination}
              />
            )}
          </div>
        </div>
      </div>
      <TicketStatusModal
        isOpen={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
          handleReset();
        }}
        handleSubmit={editId ? handleUpdateStatus : handleCreateStatus}
        editId={editId}
        request={request}
        setRequest={setRequest}
      />
    </CompanyProfileDetailsLayout>
  );
};

export default HelpDeskTicketStatus;
