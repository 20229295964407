import { capitalizeFirstLetter } from "@common/text-helpers";
import CannedResponseNoDataPage from "@components/common/NoData/canned-response-nodata";
import { getValue } from "@utils/lodash";
import { Pagination } from "antd";
import {
  FolderClosed,
  FolderCog,
  FolderLock,
  Pencil,
  Plus,
  Trash2,
} from "lucide-react";

const CannedFolders = ({
  UrlParams,
  list,
  showModal,
  handleUpdateModal,
  handleDeleteModal,
  selectedFolder,
  handleSelectFolder,
  folderLimit,
  folderPageNo,
  totalFolderCount,
  handlePaginationChange,
}: any) => {
  return (
    <div className="col-3 canned-folder-wrapper p-0">
      <div className="canned-folder-top-section px-3">
        <h6 className="header_text__18 ">Folders</h6>
        <div
          className="header_blue_text__16 d-flex align-items-center gap-1 cursor-pointer"
          onClick={() => showModal()}
        >
          <Plus size={18} /> New Folder
        </div>
      </div>
      <div className="canned-folder-container px-3">
        {/* <div
          className="canned-new-folder canned-folder d-flex align-items-center gap-2 justify-content-center"
          onClick={() => showModal()}
        >
          <Plus />
          New Folder
        </div> */}
        {getValue(list, "folders.length", "") > 0 ? (
          <>
            {getValue(list, "folders", "").map((item: any, index: number) => (
              <div
                key={index}
                className={`d-flex align-items-center gap-3 canned-folder ${
                  getValue(item, "id", "") ===
                  (getValue(UrlParams, "folder", "") || selectedFolder)
                    ? "canned-folder-selected"
                    : ""
                }`}
                onClick={() =>
                  handleSelectFolder(
                    getValue(item, "id", ""),
                    getValue(item, "name", "")
                  )
                }
              >
                {(getValue(item, "is_default", "") &&
                  getValue(item, "is_personal", "")) === true ? (
                  <FolderLock size={20} />
                ) : getValue(item, "is_default", "") === true &&
                  getValue(item, "is_personal", "") === false ? (
                  <FolderCog size={20} />
                ) : (
                  <FolderClosed size={20} />
                  // <FolderGit2 size={20} />
                )}
                <div
                // to={`/${getValue(
                //   params,
                //   "orgId",
                //   ""
                // )}/settings/canned-response/${getValue(item, "id", "")}`}
                >
                  <p className="header-Text__14">
                    {getValue(item, "name", "")}
                  </p>
                  <span className="small_text__13">
                    {getValue(item, "canned_responses.length", "")} canned
                    response
                  </span>
                </div>
                {(getValue(item, "is_default", "") ||
                  getValue(item, "is_personal", "")) === false && (
                  <div className="canned-folder-edit">
                    <Pencil
                      size={16}
                      onClick={() =>
                        handleUpdateModal(
                          getValue(item, "name", ""),
                          getValue(item, "id", "")
                        )
                      }
                    />
                    <Trash2
                      size={16}
                      onClick={() =>
                        handleDeleteModal(
                          getValue(item, "id", ""),
                          capitalizeFirstLetter(getValue(item, "name", ""))
                        )
                      }
                    />
                  </div>
                )}
              </div>
            ))}
            {/* <Pagination
              size="small"
              current={folderPageNo}
              total={totalFolderCount}
              align="center"
              pageSize={folderLimit}
              onChange={handlePaginationChange}
            /> */}
          </>
        ) : (
          <div className="mt-5">
            <p className="text-center small_text__14">No folders found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CannedFolders;
