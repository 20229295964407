import React, { useEffect, useState } from "react";
import { Select, SelectProps } from "antd";
import { getValue } from "@utils/lodash";
import { DefaultOptionType } from "antd/lib/select";

interface NewSearchSelectProps extends Omit<SelectProps, "options"> {
  label?: string;
  width?: string | number;
  required?: boolean;
  validator?: any;
  lookup_api?: any;
  forModule?: string;
  module_id?: string;
  selectKey?: string;
  errorLabel?: string;
  add?: boolean;
  button_title?: string;
  handleOpenPopup?: () => void;
  labelKey?: string;
  options?: any[];
  hideLabel?: boolean;
  optionsData?: any;
  value: any;
  onChange?: (value: any, data: any) => void;
}

const NewSearchSelect: React.FC<NewSearchSelectProps> = ({
  label,
  width = "100%",
  required = false,
  validator,
  lookup_api,
  forModule,
  module_id,
  selectKey = "value",
  errorLabel,
  add = false,
  button_title,
  handleOpenPopup,
  hideLabel,
  labelKey = "label",
  options: propOptions,
  optionsData,
  value,
  onChange,
  ...props
}) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  // Initialize data with provided options if any - fixed to prevent duplicates
  useEffect(() => {
    if (propOptions) {
      setData(propOptions);
    }
  }, [propOptions]);

  // Transform data to AntD Select options format with unique keys
  // Use a Map to ensure uniqueness based on the selectKey
  const uniqueOptionsMap = new Map();
  data.forEach((item, index) => {
    // Skip archived items
    if (item.archived === true) return;
    const itemValue = getValue(item, selectKey, "") || `option-${index}`;
    if (!uniqueOptionsMap.has(itemValue)) {
      uniqueOptionsMap.set(itemValue, {
        label: getValue(item, labelKey, ""),
        value: itemValue,
        key: itemValue,
        data: item,
      });
    }
  });

  const transformedOptions: DefaultOptionType[] = Array.from(
    uniqueOptionsMap.values()
  );

  // Custom dropdown render to add "Add" button if needed
  const dropdownRender = (menu: React.ReactElement) => {
    if (!add) return menu;
    return (
      <>
        {menu}
        <div
          style={{
            padding: "8px",
            borderTop: "1px solid #e8e8e8",
            cursor: "pointer",
          }}
          onClick={() => {
            handleOpenPopup?.();
          }}
        >
          <img
            src="/dropdown/add.svg"
            style={{ width: 16, height: 16, marginRight: 8 }}
            alt="add"
          />
          <span>{button_title}</span>
        </div>
      </>
    );
  };

  // Ensure optionsData also has unique keys if provided
  let finalOptions: DefaultOptionType[] = [];

  if (optionsData) {
    const optionsMap = new Map();
    optionsData.forEach((option: any, index: number) => {
      const optionValue = option.value || `option-value-${index}`;
      if (!optionsMap.has(optionValue)) {
        optionsMap.set(optionValue, {
          ...option,
          key: option.value || option.key || `option-data-${index}`,
          value: optionValue,
        });
      }
    });
    finalOptions = Array.from(optionsMap.values()).sort((a, b) =>
      a.label.localeCompare(b.label)
    );
  } else {
    finalOptions = transformedOptions.sort((a, b) =>
      (a.label as string).localeCompare(b.label as string)
    );
  }

  return (
    <div>
      {!hideLabel && (
        <p className="form-label">
          {label} {required && <span className="text-danger">*</span>}
        </p>
      )}
      <Select
        size="large"
        className={` ${required ? "ant-select-required" : ""}`}
        style={{ width: width ? width : "100%" }}
        showSearch
        loading={loading}
        optionFilterProp="label"
        dropdownRender={dropdownRender}
        options={finalOptions}
        allowClear={required ? false : true}
        filterOption={(input, option) =>
          (option?.label ?? "")
            .toString()
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        value={value ? value : null}
        onChange={(selectedValue, option) => {
          onChange?.(selectedValue, (option as DefaultOptionType)?.data);
        }}
        {...props}
      />
      {validator?.current?.message && (
        <p className="error-text">
          {validator.current.message(errorLabel || label, value, "required")}
        </p>
      )}
    </div>
  );
};

export default NewSearchSelect;
