import { convertCurrentDate } from "@common/date-helpers";
import { handleDownloadOnClick } from "@common/downloadfile";
import { QueryRequestHelper } from "@common/query-request-helper";
import { formatString } from "@common/text-helpers";
import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";
import { useStateContext } from "@context/dataContext";
import SidebarLayout from "@layouts/SidebarLayout/SidebarLayout";
import {
  getDataImports,
  getSpecificDataImport,
} from "@services/imports-exports.service";
import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";

interface ImportLogItem {
  module: {
    label: string;
  };
  created_by: {
    first_name: string;
    last_name: string;
  };
  created_at: string;
  created_count: number;
  failed_count: number;
  total_count: number;
  status: string;
  log_file_url: string;
}

function ModuleImportLogs(props: any) {
  const params = useParams();
  const { selectedModuleId } = useStateContext();
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [importList, setImportList] = useState<ImportLogItem[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        module_id: await selectedModuleId(getValue(params, `module`, "")),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getDataImports(queryRequest);
      if (resp) {
        setImportList(getValue(resp, `data.dataImports`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const [info, setInfo] = useState({});
  const [infoLoading, setInfoLoading] = useState(false);
  const getSpecificImportInfo = async (id: string) => {
    try {
      setInfoLoading(true);
      let resp = await getSpecificDataImport(getValue(params, `id`, ""));
      if (resp) {
        setInfo(getValue(resp, `data`, {}));
        setInfoLoading(false);
      } else {
        setInfoLoading(false);
      }
    } catch (error) {
      setInfoLoading(false);
    }
  };

  const columns: ColumnsType<ImportLogItem> = [
    {
      title: "MODULE",
      dataIndex: ["module", "label"],
      key: "module",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "CREATED BY",
      key: "createdBy",
      render: (_, record) => (
        <p>
          {getValue(record, `created_by.first_name`, "")}{" "}
          {getValue(record, `created_by.last_name`, "")}
        </p>
      ),
    },
    {
      title: "CREATED AT",
      key: "createdAt",
      render: (_, record) =>
        convertCurrentDate(getValue(record, `created_at`, "")),
    },
    {
      title: "CREATED COUNT",
      dataIndex: "created_count",
      key: "created_count",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "FAILED COUNT",
      dataIndex: "failed_count",
      key: "failed_count",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "TOTAL COUNT",
      dataIndex: "total_count",
      key: "total_count",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (text) => <p>{formatString(text)}</p>,
    },
    {
      title: "ACTION",
      key: "action",
      render: (_, record) => (
        <div
          onClick={() =>
            getValue(record, `log_file_url`, "") &&
            handleDownloadOnClick(
              getValue(record, `log_file_url`, ""),
              "log_file"
            )
          }
        >
          {getValue(record, `log_file_url`, "") ? (
            <p className="color_primary header_text__14 cursor-pointer">
              Download
            </p>
          ) : (
            "-"
          )}
        </div>
      ),
    },
  ];

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  return (
    <SidebarLayout {...props}>
      <PipelineSubHeader subHeaderListTitle="Import Logs" />
      <div className="p-4">
        <Table
          columns={columns}
          dataSource={importList}
          loading={isLoading}
          rowKey="id"
          bordered
          size="middle"
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          scroll={{
            y: "calc(100vh - 190px)",
            x: "maxScroll",
          }}
        />
      </div>
    </SidebarLayout>
  );
}

export default ModuleImportLogs;
