import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import { getValue } from "@utils/lodash";
import { Button, message, Switch, Table, Tooltip } from "antd";
import { Lock, Pencil, Plus, Trash2 } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import {
  createAgentStatus,
  getAllAgentStatus,
  updateAgentStatus,
  updateBulkAgentStatus,
} from "@services/agent-status.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import CreateAgentStatus from "./components/create-agent-status";
import { toast } from "sonner";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { deleteAgentStatus } from "@services/agent-status.service";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import { useStateContext } from "@context/dataContext";
import { capitalizeFirstLetter } from "@common/text-helpers";
import SettingsPageDetails from "@components/Pages/PageDescription/SettingsPageDetails";
import { agentStatusDetails } from "@common/Data/page-details-data";
import SimpleReactValidator from "simple-react-validator";

interface AgentStatus {
  id: string;
  name: string;
  emoji: string;
  type: string;
  is_active: boolean;
  is_default: boolean;
}

const AgentStatus = (props: any) => {
  const { getAllAgentStatuseCallback } = useStateContext();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [_, forceUpdate] = useState(0);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [agentStatusRequest, setAgentStatusRequest] = useState({
    emoji: "🙂",
    name: "",
    type: "online",
    is_active: true,
  });
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getAllAgentsStatus();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [agentStatuses, setAgentStatuses] = useState<AgentStatus[]>([]);
  const getAllAgentsStatus = async () => {
    try {
      setIsLoading(true);
      let payload = {
        page_no: 1,
        page_size: 100,
      };
      let queryRequest = QueryRequestHelper(payload);
      const resp = await getAllAgentStatus(queryRequest);
      if (resp) {
        const agentStatuses = getValue(resp, "data.agent_statuses", []);
        const sortedStatuses = [...agentStatuses].sort((a, b) => {
          if (a.is_default === b.is_default) return 0;
          return a.is_default ? -1 : 1;
        });
        setAgentStatuses(sortedStatuses);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatusChange = async (id: string, status: boolean) => {
    try {
      const payload = {
        ids: [id],
        is_active: !status,
      };
      const resp = await updateBulkAgentStatus(payload);
      if (resp) {
        message.success(`Updated Successfully`);
        getAllAgentStatuseCallback();
        getAllAgentsStatus();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      render: (agent: any, record: any) => {
        return (
          <div className="d-flex align-items-center gap-2">
            <div
              className="header_text__26 d-flex align-items-center justify-content-center"
              style={{
                width: "50px",
              }}
            >
              {getValue(record, `is_default`, false) ? (
                getValue(record, "name", "") === "Available" ? (
                  <CheckCircleFilled
                    style={{ color: "#009A00", fontSize: "20px" }}
                    className="py-2"
                  />
                ) : (
                  <CloseCircleFilled
                    style={{ color: "red", fontSize: "20px" }}
                    className="py-2"
                  />
                )
              ) : (
                getValue(record, "emoji", "")
              )}
            </div>

            <h6 className="header_text__14">
              <span className="pe-2">{agent}</span>

              {getValue(record, "is_default", "") && (
                <Tooltip title="Default Status">
                  <Lock size={16} />
                </Tooltip>
              )}
            </h6>
          </div>
        );
      },
    },
    {
      title: "TYPE",
      dataIndex: "type",
      key: "type",
      render: (type: string) => capitalizeFirstLetter(type),
    },
    // {
    //   title: "STATUS",
    //   dataIndex: "is_active",
    //   key: "is_active",
    //   render: (is_active: any, record: any) => (
    //     <p className="d-flex align-items-center gap-2">
    //       <Badge color={is_active ? "green" : "red"} />
    //       {is_active ? "Active" : "Inactive"}
    //     </p>
    //   ),
    // },
    {
      title: "STATUS",
      dataIndex: "is_active",
      key: "is_active",
      width: 120,
      render: (is_active: any, record: any) => (
        <Switch
          size="small"
          checked={is_active}
          onChange={() =>
            handleStatusChange(
              getValue(record, `id`, ""),
              getValue(record, `is_active`, false)
            )
          }
          disabled={getValue(record, `is_default`, false)}
        />
      ),
    },
    {
      title: "ACTIONS",
      key: "actions",
      width: 120,
      render: (_: any, record: any) => (
        // !getValue(record, `is_default`, false) && (
        <div className="gap-1 d-flex align-items-center">
          <Button
            color="default"
            variant="text"
            onClick={() => handleEdit(record)}
            icon={<Pencil size={16} />}
            disabled={getValue(record, `is_default`, false)}
          />
          <Button
            color="danger"
            variant="text"
            onClick={() => handleDelete(getValue(record, `id`, ""))}
            icon={<Trash2 size={16} />}
            disabled={getValue(record, `is_default`, false)}
          />
        </div>
      ),
      // ),
    },
  ];

  /* -------------------------------------------------------------------------- */
  /*                               Model section                                */
  /* -------------------------------------------------------------------------- */
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const [editId, setEditId] = useState("");
  const handleCreateAgentStatus = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        let resp;
        if (editId) {
          resp = await updateAgentStatus(editId, agentStatusRequest);
        } else {
          resp = await createAgentStatus(agentStatusRequest);
        }
        if (resp) {
          getAllAgentsStatus();
          reset();
          toast.success(`${editId ? "Update" : "Create"} successfully`);
          simpleValidator.current.hideMessages();
          forceUpdate(0);
          toggle();
          getAllAgentStatuseCallback();
        }
      } catch (error) {}
    }
  };
  const reset = () => {
    setEditId("");
    setAgentStatusRequest({
      emoji: "🙂",
      name: "",
      type: "online",
      is_active: true,
    });
  };
  /* ---------------------------  Edit Section --------------------------------- */

  const handleEdit = (obj: any) => {
    setEditId(getValue(obj, `id`, ""));
    setAgentStatusRequest({
      ...agentStatusRequest,
      emoji: getValue(obj, `emoji`, ""),
      name: getValue(obj, `name`, ""),
      type: getValue(obj, `type`, ""),
      is_active: getValue(obj, `is_active`, false),
    });
    toggle();
  };

  /* ---------------------------  Delete Section --------------------------------- */
  const [isDelete, setIsDelete] = useState(false);
  const toggleDelete = () => {
    setIsDelete(!isDelete);
  };
  const [deleteId, setDeleteId] = useState("");
  const handleDelete = (id: string) => {
    setDeleteId(id);
    toggleDelete();
  };
  const handleConfirmDelete = async () => {
    try {
      let resp = await deleteAgentStatus(deleteId);
      if (resp) {
        setDeleteId("");
        toggleDelete();
        getAllAgentsStatus();
        getAllAgentStatuseCallback();
      }
    } catch (error) {}
  };
  return (
    <CompanyProfileDetailsLayout {...props} isLoading={isLoading}>
      <CompanyProfileSubHeader
        settings
        addAgentStatus
        toggle={toggle}
        title="Agent Status"
        icon="agent-status"
        submitIcon={<Plus size={20} />}
      />
      <div className="sla-policy-wrapper">
        <div className="w-70 p-4 sla-policy-card-container">
          <p className="w-70 small_text__14 pb-4">
            With custom statuses, you can get insights into how agents spend
            their time when they’re unavailable to assist new customers. You can
            view and update your team’s statuses in real-time using the
            availability dashboard.
          </p>
          <Table
            size="middle"
            bordered
            columns={columns}
            dataSource={agentStatuses}
            rowKey={(record: any) => record.id}
            loading={isLoading}
            pagination={{
              showTotal: (total, range) => `${total} Agents`,
            }}
            scroll={{
              y: "calc(100vh - 260px)",
            }}
          />
        </div>

        <div className="w-30 p-4 sla-policy-right-details">
          <SettingsPageDetails data={agentStatusDetails} />
        </div>
      </div>
      <CreateAgentStatus
        isOpen={isOpen}
        handleModal={toggle}
        handleSubmit={handleCreateAgentStatus}
        request={agentStatusRequest}
        setRequest={setAgentStatusRequest}
        reset={reset}
        editId={editId}
        agentStatuses={agentStatuses}
        validator={simpleValidator}
      />
      <DeleteModal
        isOpen={isDelete}
        handleModal={toggleDelete}
        handleSubmit={handleConfirmDelete}
        deleteValue=""
      />
    </CompanyProfileDetailsLayout>
  );
};

export default AgentStatus;
