import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Dropdown,
  Empty,
  MenuProps,
  message,
  Popover,
  Space,
  Spin,
  Tag,
  Tooltip,
} from "antd";
import {
  Box,
  CircleDollarSign,
  Edit,
  IndianRupee,
  Pencil,
  SquareArrowOutUpRight,
  Trash2,
} from "lucide-react";
import SimpleReactValidator from "simple-react-validator";

import { useStateContext } from "@context/dataContext";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  capitalizeFirstLetter,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import FormBuilder from "@components/Builder/FormBuilder";
import SaleProductForm from "@components/Pages/Pipeline/Detail/middle-container/SaleItem/components/product-form";
import DealInfoPopup from "./TripManage/deal-info-popup";

import {
  createSubPipeline,
  disAssociationSubPipeline,
} from "@services/common.service";
import { getSpecificSale, updateSale } from "@services/deals.service";
import { allPipelines, getSpecificPipeline } from "@services/pipeline.service";
import { getValue } from "@utils/lodash";
import {
  appendObjectValuesToArray,
  FormRequestHelper,
  generatePipelineAssociationPayload,
} from "@components/helpers/request-helper";
import { convertDateAsString } from "@common/date-helpers";
import DealProductFormModel from "./TripManage/deal-product-form-model";
import ProfileInlineFormBuilder from "../ProfileDetails/ProfileInlineFormBuilder";
import DealCreatePaymentLinkFormModel from "./TripManage/Payments/CreatePayment";
import DealPaymentFormModel from "./TripManage/Payments/deal-payment-form-model";
import DealProductForm from "./TripManage/deal-product-form";
import TripProductForm from "./TripManage/trip-product-form";

const UserDealDetails = (props: any) => {
  const {
    isOpenDrawer,
    setIsOpenDrawer,
    contactInfo,
    selectedModule,
    moduleDetails,
    getSpecificContactInfo,
    getSpecificLeadInfo,
    selectedDrawerInfo,
    setSelectedDrawerInfo,
    defaultFields,
  } = props;
  const { userId, selectedModuleId, orgInfo } = useStateContext();
  const simpleValidator = useRef(new SimpleReactValidator());

  const [formLoading, setFormLoading] = useState(false);
  const [associationForm, setAssociationForm] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [formStageList, setFormStageList] = useState<any[]>([]);
  const [createMode, setCreateMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState<any>({});
  const [editId, setEditId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [staticFieldRequest, setStaticFieldRequest] = useState({
    pipeline_id: "",
    stage: "",
  });
  const [, forceUpdate] = useState(0);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleRefresh = useCallback(() => {
    const id = getValue(contactInfo, "id", "");
    if (selectedModule === "contacts") {
      getSpecificContactInfo(id);
    } else {
      getSpecificLeadInfo(id);
    }
  }, [
    contactInfo,
    selectedModule,
    getSpecificContactInfo,
    getSpecificLeadInfo,
  ]);

  const getForm = useCallback(
    async (module: string) => {
      try {
        setFormLoading(true);
        setAssociationForm([]);

        const payload = { module_name: module };
        const queryRequest = QueryRequestHelper(payload);

        const resp = await allPipelines(
          getValue(moduleDetails, "id", ""),
          queryRequest
        );
        if (resp) {
          const pipelineId = getValue(resp, `data[0].id`, "");
          await getAssociationPipelineInfo(pipelineId, module);
          const list = getValue(resp, `data[0].stages`, []).filter(
            (item: any) => item.archived === false
          );
          setFormStageList(
            list.map((item: any) => ({
              ...item,
              label: getValue(item, "label", ""),
              value: getValue(item, "api_name", ""),
            }))
          );
        }
      } catch (error) {
        console.error("Error in getForm:", error);
      } finally {
        setFormLoading(false);
      }
    },
    [moduleDetails]
  );

  const getAssociationPipelineInfo = useCallback(
    async (id: string, module: string) => {
      if (!id) {
        return;
      }
      try {
        const resp = await getSpecificPipeline(await selectedModuleId(), id);
        if (resp) {
          const fields = getValue(resp, "data.form_fields", []).map(
            (item: any) => ({
              ...getValue(item, "module_field", {}),
              name: getValue(item, "name", ""),
              quick_add: getValue(item, "quick_add", ""),
              required: getValue(item, "required", ""),
              seq_num: getValue(item, "seq_num", ""),
              value: item.api_name === "owner_id" ? userId : undefined,
            })
          );
          for (const item of fields) {
            if (item.api_name === "owner_id") {
              item.value = userId;
            }
          }
          setStaticFieldRequest((prev: any) => ({
            ...prev,
            stage: getValue(resp, "data.stages[0].id", ""),
            pipeline_id: id,
          }));
          setAssociationForm(fields);
        }
      } catch (error) {
        console.error("Error in getAssociationPipelineInfo:", error);
      }
    },
    [selectedModuleId, userId]
  );

  const handleSubmit = async (module: string) => {
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }

    try {
      setFormSubmitLoading(true);
      const fields = [...associationForm];
      const associations = [
        {
          api_name: selectedModule,
          record_id: getValue(contactInfo, "id", ""),
        },
      ];

      const formattedProducts =
        products.length > 0
          ? products.map((item) => ({
              action: "add",
              list_price: parseInt(getValue(item, "list_price", 0)) || 0,
              quantity: parseInt(getValue(item, "quantity", 0)) || 0,
              discount: parseInt(getValue(item, "discount", 0)) || 0,
              total: parseInt(getValue(item, "total", 0)) || 0,
              sale_item_id: getValue(item, "sale_item_id", ""),
            }))
          : null;

      const payload = generatePipelineAssociationPayload(
        module,
        fields,
        staticFieldRequest,
        associations,
        formattedProducts
      );

      const resp = await createSubPipeline(payload, module);
      if (resp) {
        message.success("Created & Associated Successfully");
        setCreateMode(false);
        setAssociationForm([]);
        setProducts([]);
        setEditId("");
        handleRefresh();
        forceUpdate(0);
        await getData(getValue(resp, "data.id", ""));
        simpleValidator.current.hideMessages();
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    } finally {
      setFormSubmitLoading(false);
    }
  };

  const handleDelete = async (deleteId: string) => {
    try {
      const payload = {
        api_name: selectedModule,
        record_id: getValue(contactInfo, "id", ""),
      };

      const resp = await disAssociationSubPipeline("deals", deleteId, payload);
      if (resp) {
        message.success("Disassociated successfully");
        handleRefresh();
      }
    } catch (error) {
      message.error("Error disassociating");
    }
  };

  const handleUpdate = async () => {
    try {
      setSubmitLoading(true);
      const payload = {
        pipeline_stage_id: getValue(staticFieldRequest, "stage", ""),
        ...FormRequestHelper([...associationForm]),
      };

      const resp = await updateSale(
        editId,
        removeNullOrUndefinedProperties(payload)
      );
      if (resp) {
        setEditMode(false);
        message.success("Updated");
        handleRefresh();
      }
    } catch (error) {
      console.error("Error in handleUpdate:", error);
    } finally {
      setSubmitLoading(false);
    }
  };

  const getData = async (id: string) => {
    if (!id) return;
    try {
      setIsLoading(true);
      const response = await getSpecificSale(id);
      if (response) {
        const pipelineResp = await getSpecificPipeline(
          await selectedModuleId(),
          getValue(response, "data.pipeline_id", "")
        );
        const list = getValue(pipelineResp, "data.stages", []).filter(
          (item: any) => item.archived === false
        );
        setFormStageList(
          list.map((item: any) => ({
            ...item,
            label: getValue(item, "label", ""),
            value: getValue(item, "name", ""),
          }))
        );

        setEditId(getValue(response, "data.id", ""));
        setStaticFieldRequest((prev) => ({
          ...prev,
          stage: getValue(response, "data.pipeline_stage.id", ""),
          pipeline_id: getValue(response, "data.pipeline.id", ""),
        }));

        const fields = getValue(pipelineResp, "data.form_fields", []).map(
          (item: any) => ({
            ...getValue(item, "module_field", {}),
            name: getValue(item, "name", ""),
            quick_add: getValue(item, "quick_add", ""),
            required: getValue(item, "required", ""),
            seq_num: getValue(item, "seq_num", ""),
          })
        );
        const formData = appendObjectValuesToArray(
          fields,
          getValue(response, "data", {})
        );
        setData(getValue(response, "data", {}));
        setAssociationForm(formData);
      }
    } catch (error) {
      console.error("Error in getData:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (contactInfo.deals?.length > 0 && Object.keys(data).length === 0) {
      getData(getValue(contactInfo, "deals[0].id", ""));
    }
  }, [contactInfo, data]);

  useEffect(() => {
    setCreateMode(false);
  }, [contactInfo.id]);

  const [dealPaymentId, setDealPaymentId] = useState("");
  const [productsCount, setProductsCount] = useState(0);

  const renderDealsList = () => (
    <>
      {getValue(contactInfo, "deals", []).map((item: any, index: number) => {
        const payementPending = getValue(item, "razorpay.length", 0);
        return (
          <>
            <Card
              type="inner"
              key={index}
              size="small"
              className="mb-3"
              title={
                <span
                  className="cursor-pointer d-flex align-items-center gap-2"
                  onClick={() => {
                    getData(getValue(item, "id", ""));
                    setEditMode(true);
                  }}
                >
                  {capitalizeFirstLetter(getValue(item, "name", ""))}
                  <Edit size={16} />
                </span>
              }
              actions={[
                <Tooltip title="Add Product">
                  <Button
                    size="small"
                    type="link"
                    icon={<Box size={16} />}
                    className="p-0"
                    onClick={() => setVisible(true)}
                  >
                    Products
                  </Button>
                </Tooltip>,
                <Tooltip title="Add Payment">
                  <Button
                    size="small"
                    type="link"
                    icon={<IndianRupee size={16} />}
                    className="p-0"
                    onClick={() => {
                      setPaymentVisible(true);
                      setDealPaymentId(getValue(item, "id", ""));
                    }}
                  >
                    Add
                  </Button>
                </Tooltip>,
                <Tooltip title="Payment List">
                  <Button
                    size="small"
                    type="link"
                    icon={<IndianRupee size={16} />}
                    className="p-0"
                    onClick={() => {
                      setPaymentListVisible(true);
                      setDealPaymentId(getValue(item, "id", ""));
                    }}
                  >
                    List
                  </Button>
                </Tooltip>,
              ]}
            >
              <div className="d-flex justify-content-between align-items-center">
                <p className="small_text__12">Amount</p>
                <p>
                  {getValue(orgInfo, "currency", "")}{" "}
                  {getValue(item, "amount", "")}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p className="small_text__12">
                  {getValue(defaultFields, "closing_date", "Closing Date")}
                </p>
                <p>{convertDateAsString(getValue(item, "closing_date", ""))}</p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p className="small_text__12">Amount Paid</p>
                <p className="text-success">
                  {getValue(orgInfo, "currency", "")}{" "}
                  {getValue(item, "amount_paid", 0)}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p className="small_text__12">Amount Due</p>
                <p className="text-danger">
                  {getValue(orgInfo, "currency", "")}{" "}
                  {getValue(item, "amount_due", 0)}
                </p>
              </div>
              <div className="d-flex gap-1 my-1 align-items-center"></div>
            </Card>
          </>
        );
      })}
    </>
  );

  const renderCreateForm = () => (
    <div onKeyDown={(e) => e.key === "Enter" && handleSubmit("deals")}>
      <div className="form_wrapper_builder-pipeline">
        <SearchToolTip
          placeholder="Choose Status"
          label="label"
          validator={simpleValidator}
          data={formStageList}
          value={getValue(staticFieldRequest, "stage", "")}
          onChange={(e: any) =>
            setStaticFieldRequest((prev) => ({
              ...prev,
              stage: getValue(e, "id", ""),
            }))
          }
          selectKey="id"
          name="Status"
          required
        />
      </div>
      <FormBuilder
        fields={associationForm}
        setFields={setAssociationForm}
        simpleValidator={simpleValidator}
      />
      <Popover
        open={isPopoverOpen}
        onOpenChange={setIsPopoverOpen}
        content={
          <div style={{ width: "900px" }}>
            <TripProductForm
              products={products}
              setProducts={setProducts}
              hideSave={true}
              setProductsCount={setProductsCount}
            />
          </div>
        }
        trigger={"click"}
        // placement="left"
      >
        <Button
          type="default"
          variant="filled"
          icon={<SquareArrowOutUpRight size={16} />}
          className="mb-5 ms-4 mt-3"
          iconPosition="end"
          onClick={() => setIsPopoverOpen(true)}
        >
          {getValue(products, `length`, 0) === 0
            ? "Add"
            : getValue(products, `length`, 0)}{" "}
          Products
        </Button>
      </Popover>
    </div>
  );
  const renderEditForm = () => (
    <div className=" mb-4">
      <div className="profile-deal-details-actions">
        <Button
          // onClick={handleIsOpenDrawer}
          onClick={() => {
            setEditMode(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={submitLoading}
          loading={submitLoading}
          onClick={handleUpdate}
          type="primary"
        >
          Update
        </Button>
      </div>
      <SearchToolTip
        placeholder="Choose Status"
        label="label"
        validator={simpleValidator}
        data={formStageList}
        value={getValue(staticFieldRequest, "stage", "")}
        onChange={(e: object) =>
          setStaticFieldRequest((prevStaticFieldRequest: any) => ({
            ...prevStaticFieldRequest,
            stage: getValue(e, "id", ""),
          }))
        }
        selectKey="id"
        name="Status"
        required
      />
      <ProfileInlineFormBuilder
        fields={associationForm}
        setFields={setAssociationForm}
        simpleValidator={simpleValidator}
        width="100%"
      />
    </div>
  );

  const [visible, setVisible] = useState(false);
  const [paymentVisible, setPaymentVisible] = useState(false);
  const [paymentListVisible, setPaymentListVisible] = useState(false);

  const [triggerPaymentList, setTriggerPaymentList] = useState(false);
  return (
    <>
      <>
        <div className="profile-deal-details-actions">
          {createMode ? (
            <>
              {/* <Button
                color="default"
                variant="filled"
                onClick={() => {
                  // setCreateMode(false);
                  forceUpdate(0);
                  // setProducts([]);
                  simpleValidator.current.hideMessages();
                }}
              >
                Reset
              </Button> */}
              <Button type="default" onClick={() => setCreateMode(false)}>
                Cancel
              </Button>
              <Button
                onClick={() => handleSubmit("deals")}
                loading={formSubmitLoading}
                type="primary"
              >
                Create
              </Button>
            </>
          ) : (
            !editMode &&
            contactInfo.deals?.length > 0 && (
              <Button
                onClick={() => {
                  getForm("deals");
                  setCreateMode(true);
                }}
                type="primary"
                className="mb-3"
              >
                New {getValue(moduleDetails, "linkText", "")}
              </Button>
            )
          )}
        </div>

        {createMode ? (
          formLoading ? (
            <div className="d-flex justify-content-center mt-5 pt-5">
              <Spin />
            </div>
          ) : (
            renderCreateForm()
          )
        ) : editMode ? (
          isLoading ? (
            <div className="d-flex justify-content-center mt-5 pt-5">
              <Spin />
            </div>
          ) : (
            renderEditForm()
          )
        ) : contactInfo.deals?.length > 0 ? (
          renderDealsList()
        ) : (
          <Empty
            description={`No ${getValue(
              moduleDetails,
              "linkText",
              ""
            )} available`}
            className="mt-4"
          >
            <Button
              onClick={() => {
                getForm("deals");
                setCreateMode(true);
              }}
              type="primary"
              className="mb-3"
            >
              New {getValue(moduleDetails, "linkText", "")}
            </Button>
          </Empty>
        )}
      </>
      <DealProductFormModel
        visible={visible}
        onCancel={() => setVisible(false)}
        setProducts={setProducts}
        data={data}
        products={getValue(data, `deal_sale_items`, [])}
        getSpecificDeal={getData}
        contactInfo={contactInfo}
        getSpecificContactInfo={getSpecificContactInfo}
      />
      <DealPaymentFormModel
        visible={paymentListVisible}
        onCancel={() => {
          setPaymentListVisible(false);
          // setDealPaymentId("");
        }}
        deal_id={dealPaymentId}
        setDealPaymentId={setDealPaymentId}
        data={data}
        contactInfo={contactInfo}
        getSpecificContactInfo={getSpecificContactInfo}
        triggerPaymentList={triggerPaymentList}
        setTriggerPaymentList={setTriggerPaymentList}
        setPaymentVisible={setPaymentVisible}
      />
      <DealCreatePaymentLinkFormModel
        visible={paymentVisible}
        setVisible={setPaymentVisible}
        onCancel={() => {
          setPaymentVisible(false);
          // setDealPaymentId("");
        }}
        deal_id={dealPaymentId}
        data={data}
        id={getValue(contactInfo, `id`, "")}
        contactInfo={contactInfo}
        getData={getData}
        triggerPaymentList={triggerPaymentList}
        setTriggerPaymentList={setTriggerPaymentList}
        setPaymentListVisible={setPaymentListVisible}
      />
    </>
  );
};

export default memo(UserDealDetails);
