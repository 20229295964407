import { getStartOfMonthDate } from "@common/date-helpers";
import { QueryRequestHelper } from "@common/query-request-helper";
import { sortJSONObjectArray } from "@common/text-helpers";
import { getAllAgentStatus } from "@services/agent-status.service";
import { getBookmarkedModules } from "@services/bookmark.service";
import { chatbotDocumentList } from "@services/chatbot-document.service";
import { getSpecificChatbot } from "@services/chatbot.service";
import { getWhatsappManagerConfig } from "@services/fb.service";
import { listAllModules } from "@services/modules.service";
import {
  getProfileInfo,
  getSpecificOrganisations,
  getSpecificProfile,
  listOfOrganisations,
} from "@services/organisations.service";
import { getValue } from "@utils/lodash";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

const StateContext = createContext();

export function StateProvider({ children }) {
  const [organizationList, setOrganizationList] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [orgInfo, setOrgInfo] = useState({});
  const [modules, setModules] = useState([]);
  const [allModules, setAllModules] = useState([]);
  const [currentCRMUrl, setCurrentCRMUrl] = useState(``);
  const [appTheme, setAppTheme] = useState(
    localStorage.getItem("theme") || "light"
  );
  const [themeColor, setThemeColor] = useState("");
  const [bookmarkedModules, setBookmarkedModules] = useState([]);

  useEffect(() => {
    document.body.setAttribute("data-bs-theme", appTheme);
    localStorage.setItem("theme", appTheme);
  }, [appTheme]);

  /* -------------------------------------------------------------------------- */
  /*                                UseEffect Section                           */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    let url_leangth = window.location.pathname.split("/");
    if (getValue(localStorage, `accessToken`, "")) {
      getOrganizationList();
      if (!getValue(userInfo, `email`, "")) {
        getOrganizationUser();
      }
      if (getValue(url_leangth, `length`, 0) > 2) {
        getOrganizationInfo();
        getModules();
        getProfile();
        getAllAgentStatuses();
        getBookmarks();
      }
      const array = window.location.pathname.split("/");
      if (array.includes("ai") && array.includes("detail")) {
        getActionData(array[3]);
      }
    }
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                                     API Section                            */
  /* -------------------------------------------------------------------------- */
  const getModules = async () => {
    try {
      let resp = await listAllModules("");
      if (resp) {
        setModules(
          concatenateAndRemoveDuplicates(
            sortJSONObjectArray(getValue(resp, `data`, {}), "seq_num")
          )
        );
        setAllModules(
          allModulesFun(
            sortJSONObjectArray(getValue(resp, `data`, {}), "seq_num")
          )
        );
      }
    } catch (error) {}
  };
  const allModulesFun = (modules) => {
    let orgID = window.location.pathname.split("/")[1];

    let moduleList = modules.map((item) => ({
      linkText: getValue(item, `label`, ""),
      linkUrl: `/${orgID}/activity/${getValue(item, `api_name`, "")}`,
      activeRoute: getValue(item, `api_name`, ""),
      id: getValue(item, `id`, ""),
      api_name: getValue(item, `api_name`, ""),
      name: getValue(item, `api_name`, ""),
      orgID: orgID,
      type: getValue(item, `type`, ""),
      label_singular: getValue(item, `label_singular`, ""),
    }));

    // Remove duplicates based on linkUrl
    let uniqueArray = moduleList.filter((item, index) => {
      return (
        moduleList.findIndex((el) => el.linkUrl === item.linkUrl) === index
      );
    });

    return uniqueArray;
  };
  const concatenateAndRemoveDuplicates = (modules) => {
    let date_range = getStartOfMonthDate();
    let orgID = window.location.pathname.split("/")[1];
    // let array = [
    //   {
    //     linkText: "Dashboard",
    //     linkUrl: `/${orgID}/dashboard?date_range=${date_range}`,
    //     activeRoute: "dashboard",
    //     api_name: "dashboard",
    //     name: "dashboard",
    //   },
    // ];
    // let ActivityModules =
    //   getValue(modules, `length`, 0) > 0
    //     ? modules.filter((item) => getValue(item, `type`, "") === "ACTIVITY")
    //     : [];
    let PipelineModules =
      getValue(modules, `length`, 0) > 0
        ? modules.filter((item) => getValue(item, `type`, ""))
        : [];
    // let ActivityModulesList = ActivityModules.map((item) => ({
    //   linkText: getValue(item, `label`, ""),
    //   linkUrl: `/${orgID}/activity/${getValue(item, `api_name`, "")}`,
    //   activeRoute: getValue(item, `api_name`, ""),
    //   id: getValue(item, `id`, ""),
    //   api_name: getValue(item, `api_name`, ""),
    //   name: getValue(item, `api_name`, ""),
    //   orgID: orgID,
    //   type: getValue(item, `type`, ""),
    //   label_singular: getValue(item, `label_singular`, ""),
    // }));
    let PipelineModulesList = PipelineModules.map((item) => ({
      linkText: getValue(item, `label`, ""),
      linkUrl: `/${orgID}/crm/${getValue(item, `api_name`, "")}`,
      activeRoute: getValue(item, `api_name`, ""),
      id: getValue(item, `id`, ""),
      api_name: getValue(item, `api_name`, ""),
      name: getValue(item, `api_name`, ""),
      orgID: orgID,
      type: getValue(item, `type`, ""),
      label_singular: getValue(item, `label_singular`, ""),
    }));
    setCurrentCRMUrl(
      `/${orgID}/crm/${getValue(PipelineModules, `[${0}].api_name`, "")}`
    );

    // Concatenate the arrays
    // let combinedArray = array.concat(PipelineModulesList, inbox);
    let combinedArray = PipelineModulesList;
    // Remove duplicates based on linkUrl
    let uniqueArray = combinedArray.filter((item, index) => {
      return (
        combinedArray.findIndex((el) => el.linkUrl === item.linkUrl) === index
      );
    });

    return uniqueArray;
  };
  const getOrganizationList = async () => {
    let resp = await listOfOrganisations("");
    if (resp) {
      setOrganizationList(getValue(resp, `data`, []));
    }
  };
  const getOrganizationUser = async () => {
    let resp = await getSpecificProfile();
    if (resp) {
      setUserInfo(getValue(resp, `data`, {}));
      setThemeColor(getValue(resp, "data.theme", ""));
      document.documentElement.style.setProperty(
        "--primary-color",
        getValue(resp, "data.theme", "")
          ? hexToRgbA(getValue(resp, "data.theme", "#D7242E"))
          : hexToRgbA("#D7242E")
      );
      document.documentElement.style.setProperty(
        "--button-primary",
        getValue(resp, "data.theme", "")
          ? hexToRgbA(getValue(resp, "data.theme", "#D7242E"))
          : hexToRgbA("#D7242E")
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        getValue(resp, "data.secondary_color", "")
          ? hexToRgbA(getValue(resp, "data.secondary_color", "#0d0d73"))
          : hexToRgbA("#0d0d73")
      );
      document.documentElement.style.setProperty(
        "--color",
        getValue(resp, "data.text_color", "#ffffff")
          ? hexToRgbA(getValue(resp, "data.text_color", "#ffffff"))
          : hexToRgbA("#ffffff")
      );
    }
  };
  const getOrganizationInfo = async () => {
    try {
      let resp = await getSpecificOrganisations();
      if (resp) {
        setOrgInfo(getValue(resp, `data`, {}));
      } else {
        setOrgInfo({});
      }
    } catch (error) {
      setOrgInfo({});
    }
  };
  function hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",");
      // return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
    }
    throw new Error("Bad Hex");
  }

  const [userId, setUserId] = useState("");
  const [profileInfo, setProfileInfo] = useState({});

  const getProfile = async () => {
    try {
      let resp = await getProfileInfo();
      if (resp) {
        setUserId(getValue(resp, `data.id`, ""));
        setProfileInfo(getValue(resp, `data`, {}));
      }
    } catch (error) {}
  };

  const [statusList, setStatusList] = useState([]);
  const getAllAgentStatuses = async () => {
    try {
      const payload = {
        status: "active",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllAgentStatus(queryRequest);
      if (resp) {
        setStatusList(getValue(resp, `data.agent_statuses`, []));
      }
    } catch (error) {}
  };

  const [chatbotInfo, setChatbotInfo] = useState({});
  const [urls, setUrls] = useState([]);
  const [botLoading, setBotLoading] = useState(false);
  const getActionData = async (id) => {
    let payload = {
      chatbot_id: id,
    };
    let queryRequest = QueryRequestHelper(payload);
    try {
      setBotLoading(true);
      let resp = await getSpecificChatbot(id);
      let links = await chatbotDocumentList(queryRequest);
      if (resp || links) {
        setUrls(getValue(links, `data`, []));
        setChatbotInfo(getValue(resp, `data`, {}));
        setBotLoading(false);
      } else {
        setBotLoading(false);
      }
    } catch (error) {
      setBotLoading(false);
    }
  };

  const getBookmarks = async () => {
    let resp = await getBookmarkedModules();
    if (resp) {
      setBookmarkedModules(getValue(resp, `data.bookmarks`, []));
    } else {
      setBookmarkedModules([]);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                  Callback Section                          */
  /* -------------------------------------------------------------------------- */
  const handleChangeModule = useCallback(() => {
    getModules();
  }, []);
  const handleChangeOrgainization = useCallback(() => {
    getOrganizationInfo();
  }, []);
  const getUserIDCallback = useCallback(() => {
    getProfile();
  }, []);
  const getUserInfoCallback = useCallback(() => {
    getOrganizationUser();
  }, []);

  const getChatbotInfoCallback = useCallback((id) => {
    getActionData(id);
  }, []);
  const getAllAgentStatuseCallback = useCallback(() => {
    getAllAgentStatuses();
  }, []);

  const refreshOrgInfo = useCallback(() => {
    getOrganizationInfo();
  }, []);

  const refreshBookmarks = useCallback(() => {
    getBookmarks();
  }, []);

  const selectedModuleId = async (module_name) => {
    if (getValue(modules, `length`, 0) === 0) {
      let resp = await listAllModules("");
      if (module_name) {
        let moduleInfo = getValue(resp, `data`, []).find(
          (item) => getValue(item, `api_name`, "") === module_name
        );
        return getValue(moduleInfo, `id`, "");
      } else {
        let name = window.location.pathname.split("/")[2];
        let moduleInfo = getValue(resp, `data`, []).find(
          (item) => getValue(item, `api_name`, "") === name
        );
        return getValue(moduleInfo, `id`, "");
      }
    } else {
      if (module_name) {
        let moduleInfo = modules.find(
          (item) => getValue(item, `api_name`, "") === module_name
        );
        return getValue(moduleInfo, `id`, "");
      } else {
        let name = window.location.pathname.split("/")[2];
        let moduleInfo = modules.find(
          (item) => getValue(item, `api_name`, "") === name
        );
        return getValue(moduleInfo, `id`, "");
      }
    }
  };

  return (
    <StateContext.Provider
      value={{
        organizationList,
        setOrganizationList,
        userInfo,
        setUserInfo,
        orgInfo,
        modules,
        userId,
        themeColor,
        allModules,
        profileInfo,
        bookmarkedModules,
        appTheme,
        setAppTheme,
        //calbacks
        handleChangeModule,
        handleChangeOrgainization,
        getUserIDCallback,
        selectedModuleId,
        getUserInfoCallback,
        currentCRMUrl,
        statusList,
        chatbotInfo,
        getChatbotInfoCallback,
        urls,
        setUrls,
        botLoading,
        getAllAgentStatuseCallback,
        refreshOrgInfo,
        refreshBookmarks,
        bookmarkedModules,
      }}
    >
      {children}
    </StateContext.Provider>
  );
}

export function useStateContext() {
  return useContext(StateContext);
}
