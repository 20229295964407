import React, { useState, useRef } from "react";
import { Button, Modal, Tag, Input, Spin } from "antd";
import { getValue } from "@utils/lodash";
import {
  capitalizeFirstLetterOfEachWord,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import ReplySvgComponent from "@assets/svg/whatsapp-reply";
import CallSvgComponent from "@assets/svg/phone";
import UrlSvgComponent from "@assets/svg/custom/url";
import { convertCurrentDate, convertDateTime } from "@common/date-helpers";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { toast } from "sonner";
import {
  getBroadcastConfigVaribles,
  manageWhatsappBroadcast,
} from "@services/fb.service";
import NewSearchSelect from "@components/custom/Dropdown/AntDropDown";
import { config } from "../../../../../../env";
import Pagination from "react-js-pagination";

const { Search } = Input;

function ModuleBroadcastModel(props: any) {
  const {
    templateList,
    handleModel,
    isOpen,
    handleBroadcastEditorButton,
    selecteWaAccount,
    setSelectedWaAccount,
  } = props;
  const swiperRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);

  const handleTemplateSelect = async (request: any) => {
    // If the same template is clicked again, deselect it
    setSelectedTemplate((prev: any) =>
      prev?.id === request.id ? null : request
    );
  };

  const handleReset = () => {
    setSelectedTemplate(null);
    setSearchTerm("");
  };

  const handleContinue = async () => {
    if (!selectedTemplate) {
      toast.error("Please select a template");
      return;
    }

    if (!selecteWaAccount) {
      toast.error("Please select a WhatsApp account");
      return;
    }

    try {
      await handleSendBroadcastSubmit(selectedTemplate);

      // Reset selections after processing
      handleReset();
      handleModel();
    } catch (error) {
      toast.error("Failed to send broadcast");
    }
  };

  const handleSendBroadcastSubmit = async (request: any) => {
    if (selecteWaAccount) {
      const variableConfig = await getConfigVar(
        getValue(request, `id`, ""),
        getValue(props, `selectedConversationInfo.mobile_number`, "") ||
          getValue(props, `selectedConversationInfo.phone_number`, "")
          ? getValue(props, `selectedConversationInfo.id`, "")
          : getValue(props, `selectedConversationInfo.id`, "")
      );

      if (variableConfig) {
        const obj: any = {
          name: getValue(request, `name`, ""),
          body: getValue(request, `body`, ""),
          userTagMapping: removeNullOrUndefinedProperties({
            ...variableConfig,
            body: getValue(request, `body`, ""),
            carousels: getValue(request, `carousel`, []),
            tags: getValue(variableConfig, `templateTags`, {}).map(
              (item: object) => ({
                ...item,
                id: getValue(item, `id`, ""),
                contextId: getContextTagId(
                  getValue(variableConfig, `contextTags`, {}),
                  getValue(item, `tag`, "")
                ),
                extractType: "field",
                sample: getValue(item, `sample`, ""),
                tag: getValue(item, `tag`, ""),
                type: getValue(item, `type`, ""),
              })
            ),
          }),
          configTmpId: getValue(variableConfig, `tmpId`, ""),
          timestamp: convertDateTime(new Date()),
          contacts: [
            getValue(props, `selectedConversationInfo.mobile_number`, "") ||
            getValue(props, `selectedConversationInfo.phone_number`, "")
              ? getValue(props, `selectedConversationInfo.id`, "")
              : getValue(props, `selectedConversationInfo.id`, ""),
          ],
          isActive: true,
          templateId: getValue(request, `id`, ""),
          waAccountId: selecteWaAccount,
          contactType:
            getValue(props, `selectedConversationInfo.mobile_number`, "") ||
            getValue(props, `selectedConversationInfo.phone_number`, "")
              ? "contact"
              : "lead",
        };

        delete obj["variableConfig"];
        delete obj["userTagMapping"]["templateTags"];
        delete obj["userTagMapping"]["tmpId"];

        const resp = await manageWhatsappBroadcast(
          removeNullOrUndefinedProperties({
            ...obj,
          })
        );

        if (resp) {
          toast.success(
            `Broadcast sent successfully for template: ${obj.name}`
          );
          return resp;
        }
      }
    } else {
      toast.error("Please select Whatsapp account to continue");
    }
  };

  const getConfigVar = async (id: string, contextId: string) => {
    try {
      let payload = {
        isContactContext: true,
        templateId: id,
        contextId: contextId,
      };
      let resp = await getBroadcastConfigVaribles(
        id,
        removeNullOrUndefinedProperties(payload)
      );
      if (resp) {
        let info = {
          ...getValue(resp, `data`, ""),
          templateTags: getValue(resp, `data.templateTags`, []).map(
            (item: object) => ({
              ...item,
              actual_tag: getValue(item, `tag`, ""),
            })
          ),
        };
        return info;
      }
    } catch (error) {}
  };

  const getContextTagId = (contextTagList: any, name: string) => {
    let info: any = contextTagList.find(
      (ite: object) => getValue(ite, `name`, "") === name
    );
    return getValue(info, `fieldId`, "");
  };

  // Filter templates based on search term
  const filteredTemplates = templateList.filter((item: any) =>
    getValue(item, "name", "").toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Modal
      open={isOpen}
      onCancel={handleModel}
      title={`${
        selectedTemplate
          ? `Selected Template: ${selectedTemplate.name}`
          : "Select Template"
      }`}
      footer={[
        selectedTemplate ? (
          <div className="d-flex justify-content-end align-items-center gap-2">
            <Button onClick={handleReset} className="reset-button">
              Reset
            </Button>
            <Button
              type="primary"
              onClick={handleContinue}
              className="continue-button"
              disabled={!selectedTemplate || !selecteWaAccount}
            >
              Continue
            </Button>
          </div>
        ) : null,
      ]}
      width={1000}
      className="broadcast-modal"
      style={{ top: 40, paddingBottom: 0 }}
    >
      <div className="d-grid grid-cols-2 gap-4 mb-3">
        <NewSearchSelect
          size="large"
          placeholder="Choose Whatsapp Account"
          value={selecteWaAccount ? selecteWaAccount : null}
          onChange={(value) => setSelectedWaAccount(value)}
          options={getValue(props, "allConnectionList", [])}
          className="whatsapp-account-select"
          label="Select Whatsapp Account"
        />
        <div>
          <label className="form-label">Search Template</label>
          <Search
            placeholder="Search templates by name"
            allowClear
            size="large"
            // loading={templateLoading}
            // disabled={templateLoading}
            value={props.templateSearchText}
            onChange={(e) => props.handleTemplateSearch(e.target.value)}
          />
        </div>
      </div>

      {props.templateLoading ? (
        <div className="d-flex justify-content-center align-items-center chat-template-list">
          <Spin />
        </div>
      ) : (
        <div className="chat-template-list">
          {filteredTemplates.map((item: any, index: any) => {
            const isSelected = selectedTemplate?.id === item.id;

            return (
              <div
                key={index}
                className={`template-item template-radio-wrapper ${
                  isSelected ? "selected" : ""
                }`}
                onClick={() => handleTemplateSelect(item)}
              >
                <div className="template-header">
                  <h6 className="header_text__15">
                    {capitalizeFirstLetterOfEachWord(
                      getValue(item, "name", "")
                    )}
                  </h6>
                  <div className="template-status">
                    <Tag color={isSelected ? "green" : "default"}>
                      {isSelected ? "Selected" : getValue(item, "status", "")}
                    </Tag>
                    <span>{getValue(item, "category", "")}</span>
                  </div>
                </div>

                <div className="template-message-container p-4">
                  <div className="template-message-body">
                    <MarkdownPreview source={getValue(item, `body`, "")} />

                    {/* Existing template body rendering */}
                    {getValue(item, `header_type`, "") === "IMAGE" &&
                      getValue(item, `header_file`, "") && (
                        <div className="template-header-image">
                          <img
                            src={`https://${config.URL}/${getValue(
                              item,
                              `header_file.key`,
                              ""
                            )}`}
                            alt="Selected"
                          />
                        </div>
                      )}

                    {getValue(item, `buttons`, []) &&
                      getValue(item, "buttons", []).map(
                        (button: any, btnIndex: number) => (
                          <div key={btnIndex} className="">
                            <div className="d-flex justify-content-center align-items-center gap-2 broadcast_button py-2">
                              {getValue(button, "type", "") ===
                              "QUICK_REPLY" ? (
                                <ReplySvgComponent color="#408dfb" size={16} />
                              ) : getValue(button, "type", "") ===
                                "CALL_TO_ACTION" ? (
                                <CallSvgComponent color="#408dfb" size={14} />
                              ) : getValue(button, "type", "") === "URL" ? (
                                <UrlSvgComponent color="#408dfb" size={14} />
                              ) : null}

                              <p className="small_text__13 text-black">
                                {getValue(button, "text", "")}
                              </p>
                            </div>
                          </div>
                        )
                      )}
                  </div>
                </div>

                <div className="template-footer">
                  <Tag bordered={false} color="blue">
                    {capitalizeFirstLetterOfEachWord(
                      getValue(item, "language", "")
                    )}
                  </Tag>
                  <p>{convertCurrentDate(getValue(item, "created_at", ""))}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="mt-2">
        <Pagination
          activePage={props.templatePageNo}
          itemsCountPerPage={props.templatePageSize}
          totalItemsCount={props.templateTotalCount}
          pageRangeDisplayed={5}
          onChange={props.handleTemplatePagination}
        />
      </div>
    </Modal>
  );
}

export default ModuleBroadcastModel;
