import InputRuleForm from "@components/InputRuleForm/form";
import { Input, Modal } from "antd";

const EmailVerifyModal = ({
  open,
  onCancel,
  isInitiateVerification,
  isVerifyEmail,
  otp,
  setOtp,
  handleSubmitOtp,
  handleInitiateEmailVerification,
  currentStep,
  newEmail,
  setNewEmail,
  validator,
  currentEmail,
}: any) => {
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      if (currentStep === 1) {
        handleInitiateEmailVerification();
      } else {
        handleSubmitOtp();
      }
    }
  };
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      //   footer={null}
      title={
        isInitiateVerification
          ? "Submitting request"
          : currentStep === 1
          ? "Verify Email"
          : "Verify OTP"
      }
      loading={isInitiateVerification || isVerifyEmail}
      okText="Submit"
      onOk={() => {
        if (currentStep === 1) {
          handleInitiateEmailVerification();
        } else {
          handleSubmitOtp();
        }
      }}
    >
      {currentStep === 1 && (
        <div className="mb-3" onKeyDown={handleKeyDown}>
          <InputRuleForm
            inputType="TEXT"
            label="Email"
            value={newEmail}
            name="email"
            onChange={(e: any) => setNewEmail(e.target.value)}
            placeholder="Enter Your New Email"
            required
            validator={validator}
            validLeft
          />
        </div>
      )}
      {currentStep === 2 && (
        <div onKeyDown={handleKeyDown}>
          <p className="small_text__14">
            Please enter the verification code sent to <b>{currentEmail}</b>.
            After verification, you will need to log in using your new email.
          </p>
          <div className="d-flex justify-content-center align-items-center my-3">
            <Input.OTP
              length={6}
              onChange={(e: any) => setOtp(e)}
              size="large"
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default EmailVerifyModal;
