export const primaryIntialState = {
  ticketInfo: {},
  html: "",
  to: [],
  is_private: true,
  attachments: [],
};
export const secondaryIntialState = {
  ticketInfo: {},
  html: "",
  to: [],
  is_private: true,
  attachments: [],
};
