import { useEffect, useRef, useState } from "react";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Alert, Button, Modal, Select } from "antd";
import { toast } from "sonner";
import { getSpecificCannedResponse } from "@services/canned-response.service";
import { mediaUpload } from "@services/upload.service";
import DragAndDropFileComponent from "@components/custom/FileUpload/file-drag-drop";
import { FileText, ImageIcon, Plus, SquarePlay, X } from "lucide-react";
import MarkdownEditor from "@components/MarkdownEditor/Editor";
import { getStringContentLength } from "@common/markdown/html-to-text-parser";
import { useStateContext } from "@context/dataContext";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import AddCannedResponseVariablePopup from "./add-variable-popup";
import NewSearchSelect from "@components/custom/Dropdown/AntDropDown";

const CannedResponseDetails = ({
  request,
  setRequest,
  personalFolderId,
  filteredFolderList,
  selectedFolder,
  editId,
  setSubmitLoading,
  groups,
  validator,
  forceUpdate,
  pipelineId,
  allPipelinesList,
  getAllPipelines,
  allTags,
  tagsByPipelineID,
  adminTags,
}: any) => {
  const [isResponseLoading, setResponseLoading] = useState(false);
  const [uploadedFileInfo, setUploadedFileInfo] = useState<any>([]);
  const { selectedModuleId, allModules } = useStateContext();

  useEffect(() => {
    if (editId) {
      getCannedResponse(true);
    }
    setUploadedFileInfo([]);
  }, [editId]);

  useEffect(() => {
    if (editId) {
      getCannedResponse(true);
    }
    setUploadedFileInfo([]);
  }, [editId]);

  useEffect(() => {
    if (request.visibility === "myself") {
      setRequest((prev: any) => ({
        ...prev,
        folder_id: personalFolderId,
      }));
    } else if (getValue(request, "folder_id", "") === "") {
      if (filteredFolderList.length > 0) {
        setRequest((prev: any) => ({
          ...prev,
          folder_id: filteredFolderList[0].id,
        }));
      }
    }
  }, [request.visibility, personalFolderId, filteredFolderList]);

  const getCannedResponse = async (status: boolean) => {
    try {
      setResponseLoading(status);
      const resp = await getSpecificCannedResponse(editId);
      if (resp) {
        setRequest({
          ...request,
          content: getValue(resp, "data.content", ""),
          folder_id: getValue(resp, "data.folder_id", ""),
          title: getValue(resp, "data.title", ""),
          attachments: getValue(resp, "data.attachments", ""),
          module_id: getValue(resp, "data.module_id", ""),
          pipeline_id: getValue(resp, "data.pipeline_id", ""),
        });
        await getAllPipelines(getValue(resp, "data.module_id", ""));
      }
    } catch (error) {
      toast.error("Failed to load canned responses.");
    } finally {
      setResponseLoading(false);
    }
  };

  console.log("request", request);

  const handleVisibilityChange = (e: any) => {
    const visibilityValue = e.target.value;

    let newFolderValue =
      filteredFolderList.length > 0 ? filteredFolderList[0].id : "";

    if (visibilityValue === "myself") {
      newFolderValue = "";
    } else if (
      visibilityValue === "all_agents" ||
      visibilityValue === "agents_in_group"
    ) {
      newFolderValue = filteredFolderList[0]?.value || "";
    }

    setRequest((prev: any) => ({
      ...prev,
      visibility: visibilityValue,
      folder: newFolderValue,
    }));

    if (visibilityValue !== "agents_in_group") {
      validator.current.hideMessages();
      forceUpdate(0);
    }
  };
  const handleFolderChange = (value: any) => {
    setRequest({
      ...request,
      folder: value,
    });
  };
  const handleChangeNoteEditor = (name: string, value: any) => {
    setRequest({
      ...request,
      [name]: value,
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                           Upload Avatar section                            */
  /* -------------------------------------------------------------------------- */

  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const [uploadLoading, setUploadLoading] = useState(false);
  const handleUploadDocuments = async (e: any) => {
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      formData.append("filename", item.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");
      try {
        setUploadLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          setUploadedFileInfo((prev: any) => [
            ...prev,
            getValue(resp, `data`, {}),
          ]);
          setRequest({
            ...request,
            attachments: [
              ...request.attachments,
              {
                id: getValue(resp, "data.id", ""),
              },
            ],
          });
          e = null;
          setUploadLoading(false);
          setOpen(false);
          // handleCancel();
          // handleDocumentSubmit();
        } else {
          e = null;
          setUploadLoading(false);
        }
      } catch (error) {
        e = null;
        setUploadLoading(false);
      }
    });
  };

  const { confirm } = Modal;

  const handleRemoveAttachment = (id: any) => {
    confirm({
      title: "Are you sure you want to delete this attachment?",
      content: "This action cannot be undone.",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        setRequest((prevRequest: any) => ({
          ...prevRequest,
          attachments: prevRequest.attachments.filter(
            (attachment: any) => attachment.id !== id
          ),
        }));
      },
      onCancel: () => {},
    });
  };

  const handleRemoveAttachmentBeforeSubmit = (id: any) => {
    confirm({
      title: "Are you sure you want to delete this attachment?",
      content: "This action cannot be undone.",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        setUploadedFileInfo((prevRequest: any) =>
          prevRequest.filter((attachment: any) => attachment.id !== id)
        );
        setRequest((prevRequest: any) => ({
          ...prevRequest,
          attachments: prevRequest.attachments.filter(
            (attachment: any) => attachment.id !== id
          ),
        }));
      },
      onCancel: () => {},
    });
  };

  const handleChangeGroups = (e: any) => {
    const selectedId = e.value;
    setRequest((prevRequest: any) => {
      const updatedGroups = [
        ...prevRequest.groups.filter((group: any) => group.id !== selectedId),
        { id: selectedId },
      ];
      return { ...prevRequest, groups: updatedGroups };
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                            Variable Modal section                          */
  /* -------------------------------------------------------------------------- */

  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleAddTags = (item: any) => {
    setParentChage(true);
    const name = getValue(item, `name`, "");
    let text: any = insertText(`[[${name}]]`);
    let finalText = text;
    setRequest({
      ...request,
      content: finalText,
    });
  };

  const insertText = (value: any) => {
    const newEditorState =
      getValue(request, `content`, "").slice(0, start) +
      value +
      getValue(request, `content`, "").slice(end);
    return newEditorState;
  };

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const [parentChage, setParentChage] = useState(false);
  const handleCursorMovement = (textarea: any) => {
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    setEnd(end);
    setStart(start);
    // Add logic to handle cursor movement
  };

  const handleChangeEditor = (text: any) => {
    // let value = formatMarkdownText(text);
    let value = text;
    setParentChage(true);
    // setMessage(value);
    // Assuming you're using a state variable called "editor" to store the editor state
    if (getStringContentLength(value) <= 1024) {
      setRequest({
        ...request,
        content: value,
      });
      // Extract custom tags from the editor text
      const regex = /\[\[(.*?)\]\]/g;
      const matches = value.match(regex);
      if (matches) {
        setRequest({
          ...request,
          content: value,
        });
      } else {
        setRequest({
          ...request,
          content: value,
        });
      }
    }
  };

  const findModule = (id: string) => {
    const mod = allModules.find((item: any) => getValue(item, "id", "") === id);
    return getValue(mod, `api_name`, "");
  };

  return (
    <>
      {/* showCancel
         handleCancel={() =>
          navigate(`/${getValue(params, "orgId", "")}/settings/canned-response`)
        }
        showSubmit
        isLoading={isSubmitLoading}
        handleSubmit={() => handleSubmit()}
        disabled={
          getValue(request, "title", "") === "" ||
          getValue(request, "content.length", "") < 12
        } */}

      <div className="d-grid gap-2">
        <InputRuleForm
          inputType="TEXT"
          name="title"
          value={getValue(request, "title", "")}
          placeholder="Enter Response Title"
          required
          label="Response Title"
          onChange={(e: any) =>
            setRequest({
              ...request,
              title: e.target.value,
            })
          }
          validator={validator}
          validLeft
        />

        <NewSearchSelect
          value={getValue(request, `module_id`, "")}
          label="Module"
          onChange={(e: any, option: any) => {
            getAllPipelines(e);
            setRequest({
              ...request,
              module_id: e,
            });
          }}
          optionsData={allModules
            .filter(
              (item: any) =>
                getValue(item, "api_name", "") !== "calls" &&
                getValue(item, "api_name", "") !== "meetings" &&
                getValue(item, "api_name", "") !== "tasks" &&
                getValue(item, "api_name", "") !== "tickets"
            )
            .map((item: any) => ({
              value: getValue(item, "id", ""), // Value used for selection
              label: getValue(item, "api_name", ""), // Displayed label
            }))}
          placeholder="Select Module"
          required
          validator={validator}
        />

        {getValue(request, `module_id`, "") &&
          findModule(getValue(request, `module_id`, "")) !== "contacts" &&
          findModule(getValue(request, `module_id`, "")) !== "companies" && (
            <>
              <NewSearchSelect
                value={getValue(request, `pipeline_id`, "")}
                label="Pipeline"
                onChange={(e: any) => {
                  tagsByPipelineID(getValue(request, `module_id`, ""), e);
                }}
                options={allPipelinesList.map((item: any) => ({
                  value: getValue(item, `id`, ""),
                  label: getValue(item, `label`, ""),
                }))}
                selectKey={"value"}
                required
                validator={validator}
              />
            </>
          )}
        <div className=" w-100 mt-2">
          {getValue(request, `module_id`, "") &&
            getValue(request, `pipeline_id`, "") && (
              <div className="d-flex align-items-center justify-content-between">
                <label className="form-label mt-0">Message *</label>
                <Button type="primary" onClick={() => handleModal()}>
                  Add variable
                </Button>
              </div>
            )}
          {/* <DraftJSRefEditor
            // toolbarHidden
            editorState={getValue(request, `content`, "")}
            handleChangeEditor={handleChangeNoteEditor}
            name="content"
            placeholder="Enter Message"
            focus
            editorStyle={{
              background: "white",
              paddingLeft: "10px",
              height: "200px",
              border: "1px solid #efefef",
            }}
            focusColor={"#3fd1a9"}
            toolbar={{
              options: ["inline", "emoji"],
              inline: {
                options: ["bold", "italic"],
              },
              emoji: {
                emojis: emojisList,
              },
            }}
            className="canned-editor"
          /> */}

          <MarkdownEditor
            handleChangeEditor={handleChangeEditor}
            handleCursorMovement={handleCursorMovement}
            value={getValue(request, `content`, "")}
            parentChage={parentChage}
            setParentChage={setParentChage}
            style={{
              minHeight: "40vh",
              overflowY: "auto",
              resize: "vertical",
            }}
            removeEnterAction
          />
          {validator?.current?.message && (
            <p className="error-text">
              {validator.current.message(
                "Message",
                getValue(request, `content`, ""),
                "required"
              )}
            </p>
          )}
        </div>
        <h6
          className="header_blue_text__16 d-flex align-items-center gap-1 cursor-pointer mt-3"
          onClick={showModal}
        >
          <Plus size={18} /> Add Attachment
        </h6>
        {open && (
          <DragAndDropFileComponent
            uploadFile={handleUploadDocuments}
            name="file"
            classes="bulk-upload_drag-n-drop-wrapper position-absolute "
            isLoading={uploadLoading}
            supportedFormats="All types"
          />
        )}
        <div className="d-flex align-items-center gap-2 flex-wrap">
          {getValue(request, "attachments.length", 0) > 0 &&
            getValue(request, "attachments", {}).map(
              (item: any, index: any) =>
                getValue(item, "filename", "") && (
                  <Alert
                    key={index}
                    message={getValue(item, "filename", "")}
                    showIcon
                    icon={
                      getValue(item, "mime_type", "") === "image/jpeg" ? (
                        <ImageIcon size={16} />
                      ) : getValue(item, "mime_type", "") === "video/mp4" ? (
                        <SquarePlay size={16} />
                      ) : (
                        <FileText size={16} />
                      )
                    }
                    type="info"
                    className="canned-attachment"
                    action={
                      <X
                        onClick={() => handleRemoveAttachment(item.id)}
                        className="cursor-pointer"
                        size={14}
                      />
                    }
                  />
                )
            )}
          {uploadedFileInfo.length > 0 &&
            uploadedFileInfo.map((item: any, index: any) => (
              <Alert
                key={index}
                message={getValue(item, "filename", "")}
                showIcon
                icon={
                  getValue(item, "mime_type", "") === "image/jpeg" ? (
                    <ImageIcon size={16} />
                  ) : getValue(item, "mime_type", "") === "video/mp4" ? (
                    <SquarePlay size={16} />
                  ) : (
                    <FileText size={16} />
                  )
                }
                type="info"
                className="canned-attachment"
                action={
                  <X
                    onClick={() => handleRemoveAttachmentBeforeSubmit(item.id)}
                    className="cursor-pointer"
                    size={14}
                  />
                }
              />
            ))}
        </div>
        {/* <label className="form-label mt-3">Available for</label>
        <div>
          <Radio.Group
            value={getValue(request, "visibility", "")}
            onChange={handleVisibilityChange}
            className=""
          >
            <Space direction="vertical">
              {availableFor.map((item, index) => (
                <Radio value={getValue(item, "value", "")} key={index}>
                  {getValue(item, "label", "")}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
          {getValue(request, "visibility", "") === "agents_in_group" && (
            <div className="d-grid mt-2">
              <MultiSearchTooltip
                placeholder="Select one or more groups"
                onChange={(e: any) => handleChangeGroups(e)}
                label={`label`}
                data={groups}
                value={getValue(request, "groups", []).map(
                  (group: any) => group.id
                )}
                selectKey={"value"}
                name="Groups"
                validator={
                  getValue(request, "visibility", "") === "agents_in_group"
                    ? validator
                    : undefined
                }
              />
            </div>
          )}
          {getValue(request, "visibility", "") !== "myself" && (
            <div className="d-grid mt-2">
              <SearchToolTip
                placeholder={`Select folder `}
                onChange={(e: object) => {
                  setRequest({
                    ...request,
                    folder: getValue(e, `id`, ""),
                  });
                }}
                label={`label`}
                data={filteredFolderList}
                value={getValue(request, "folder", "")}
                selectKey={"id"}
                name={"Select Folder"}
                // mandatory={required}
                validator={validator}
                // disabled={props.disabled}
              />
            </div>
          )}
        </div> */}

        <AddCannedResponseVariablePopup
          isOpen={isOpen}
          handleModal={handleModal}
          handleAddTags={handleAddTags}
          allPipelinesList={allPipelinesList}
          pipelineId={pipelineId}
          allTags={allTags}
          adminTags={adminTags}
        />
      </div>
    </>
  );
};

export default CannedResponseDetails;
