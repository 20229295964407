import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Button, Radio, Space } from "antd";
import React from "react";

const BroadcastVariableConfig = ({
  request,
  setRequest,
  handleModal,
  options,
  onChangeExtractType,
}: any) => {
  const showMapColumn = getValue(
    request,
    `variableConfig.templateTags`,
    []
  ).some((item: any) => getValue(item, `extractType`, "") !== "manual");

  return (
    <div className="p-4">
      <table className="w-100">
        <thead className="pb-2">
          <tr>
            <td className="header_text__14 pb-2">Variable</td>
            <td className="header_text__14 pb-2">Source</td>
            <td className="header_text__14 pb-2">Map</td>
            {showMapColumn && (
              <td className="header_text__14 pb-2">Pick Variable</td>
            )}
          </tr>
        </thead>
        <tbody>
          {getValue(request, `variableConfig.templateTags`, []).map(
            (item: any, index: any) => (
              <tr key={index} className={` ${index !== 0 ? "border-top" : ""}`}>
                <td className="small_text__14 py-2">
                  {"[["}
                  {item.actual_tag}
                  {"]]"}
                </td>
                <td className="py-2">
                  <Radio.Group
                    onChange={(e: any) => onChangeExtractType(e, index)}
                    value={getValue(item, `extractType`, "")}
                  >
                    <Space direction="vertical">
                      {options.map((item: any, i: any) => (
                        <Radio value={item.value} key={i}>
                          {item.label}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </td>
                <td className="py-2">
                  <InputRuleForm
                    // label={`{{${getValue(item, `tag`, "")}}}`}
                    inputType="TEXT"
                    // value={getValue(item, `tag`, "")}
                    value={
                      getValue(item, `extractType`, "") === "manual"
                        ? getValue(item, `value`, "")
                        : getValue(item, `tag`, "")
                    }
                    name="name"
                    placeholder={`${getValue(item, `actual_tag`, "")}`}
                    onChange={(e: any) =>
                      setRequest((prevRequest: any) => {
                        return {
                          ...prevRequest,
                          variableConfig: {
                            ...prevRequest.variableConfig,
                            templateTags:
                              prevRequest.variableConfig.templateTags.map(
                                (button: any, i: number) => {
                                  if (index !== i) return button;
                                  return {
                                    ...button,
                                    [getValue(item, `extractType`, "") ===
                                    "manual"
                                      ? "value"
                                      : "tag"]: e.target.value,
                                  };
                                }
                              ),
                          },
                        };
                      })
                    }
                    required
                    // className="mb-2"
                    disabled={getValue(item, `extractType`, "") !== "manual"}
                    hideLabel
                  />
                </td>
                {getValue(item, `extractType`, "") !== "manual" && (
                  <td>
                    <Button className="mx-2" onClick={() => handleModal(index)}>
                      Select
                    </Button>
                  </td>
                )}
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BroadcastVariableConfig;
