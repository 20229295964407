import { Button } from "antd";
import { getValue } from "@utils/lodash";
import { useParams } from "react-router-dom";
import { CopyToClipboard } from "@common/text-helpers";
import { Files } from "lucide-react";
import { CodeBlock } from "react-code-blocks";

export default function AIEmbed() {
  const params = useParams();

  return (
    <div className="share-container">
      <h6 className="header_text__20 mb-4">www.appzo.ai</h6>

      <p className="small_text__14 mb-3">
        To add the chatbot any where on your website, add this iframe to your
        html code
      </p>
      <div className="d-flex align-items-center gap-1">
        <CodeBlock
          text={`<iframe
    src="https://chatbot-iframe.onrender.com/?id=${getValue(
      params,
      "chatbotId",
      ""
    )}"
    width="50%"
    height="500px"
    title="Embedded Page"
/>`}
          language="js"
          showLineNumbers={true}
          wrapLongLines={true}
        />
        <div className="d-flex align-items-center justify-content-between px-4">
          <div></div>
          <Button
            icon={<Files size={16} />}
            onClick={() =>
              CopyToClipboard(`<iframe
  src="https://chatbot-iframe.onrender.com/?id=${getValue(params, "id", "")}"
  width="50%"
  height="500px"
  title="Embedded Page"
/>`)
            }
            className="action-button mt-3"
          ></Button>
        </div>
      </div>
    </div>
  );
}
