import { convertCurrentDate } from "@common/date-helpers";
import { getFileIcon } from "@common/document-format-helpers";
import { handleDownloadOnClick } from "@common/downloadfile";
import { formatFileSize } from "@common/file-type-helper";
import { concatString } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Dropdown, Image, Table, Tag, Tooltip } from "antd";
import { config } from "env";
import { Download, MoreVertical, Pencil, Trash2 } from "lucide-react";

const DocumentsListTable = ({
  fileList,
  handleEditUrl,
  handleDeleteModal,
  page_no,
  limit,
  totalCount,
  handleChangePagination,
  isLoading,
  permissions,
}: {
  fileList: any;
  handleEditUrl: (item: any) => void;
  handleDeleteModal: (id: string, name: string) => void;
  page_no: number;
  limit: number;
  totalCount: number;
  handleChangePagination: any;
  isLoading: boolean;
  permissions: string[];
}) => {
  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      render: (name: string, record: any) => (
        <div className="d-flex align-items-center gap-2">
          <div className="d-flex align-items-center justify-content-center w-20">
            {getValue(record, "file_type", "") === "image" ? (
              <Image
                width={40}
                height={40}
                src={`https://${config.URL}/${getValue(record, `url`, "")}`}
              />
            ) : getValue(record, "file_type", "") === "video" ? (
              <video
                width={40}
                height={40}
                autoPlay
                muted
                loop
                src={`https://${config.URL}/${getValue(record, `url`, "")}`}
              />
            ) : getValue(record, "file_type", "") === "audio" ? (
              <img
                src="/images/icons/audio.png"
                width={30}
                height={30}
                alt="audio-file"
              />
            ) : getValue(record, "file_type", "") === "document" ? (
              <img
                src={getFileIcon(
                  `.${
                    getValue(record, "url", "")
                      .split(".")
                      .pop()
                      ?.toLowerCase() || ""
                  }`
                )}
                width={30}
                height={30}
                alt="document-file"
              />
            ) : null}
          </div>
          <Tooltip title={name}>{concatString(name, 30)}</Tooltip>
        </div>
      ),
    },
    {
      title: "SIZE",
      dataIndex: "size",
      key: "size",
      render: (size: number, record: any) => {
        return formatFileSize(getValue(record, `file.size`, 0));
      },
    },
    {
      title: "FILE TYPE",
      dataIndex: "file_type",
      key: "file_type",
      render: (file_type: string, record: any) => (
        <Tag
          color={
            file_type === "image"
              ? "blue"
              : file_type === "video"
              ? "green"
              : file_type === "audio"
              ? "purple"
              : "orange"
          }
        >
          {file_type}
        </Tag>
      ),
    },
    {
      title: "ADDED BY",
      dataIndex: "created_by",
      key: "created_by",
      render: (text: string, record: any) => (
        <p>
          {getValue(record, "created_by.first_name", "")}{" "}
          {getValue(record, "created_by.last_name", "")}
        </p>
      ),
    },
    {
      title: "UPDATED ON",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (updated_at: any) => convertCurrentDate(updated_at),
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (text: string, record: any) => (
        <Dropdown
          menu={{
            items: [
              {
                key: "1",
                label: "Download",
                icon: <Download size={16} />,
                onClick: () =>
                  handleDownloadOnClick(
                    `https://${config.URL}/${getValue(record, `url`, "")}`,
                    getValue(record, `name`, "")
                  ),
              },
              permissions.includes("delete")
                ? {
                    key: "2",
                    label: "Delete",
                    icon: <Trash2 size={16} />,
                    onClick: () =>
                      handleDeleteModal(
                        getValue(record, "id", ""),
                        getValue(record, "name", "")
                      ),
                  }
                : null,
            ].filter(Boolean),
          }}
        >
          <MoreVertical size={18} className="cursor-pointer" />
        </Dropdown>
      ),
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={fileList}
      pagination={{
        current: page_no,
        pageSize: limit,
        total: totalCount,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50"],
        onChange: (page_no, pageSize) => {
          handleChangePagination(page_no, pageSize);
        },
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
      }}
      scroll={{ y: "calc(100vh - 324px)", x: "max-content" }}
      loading={isLoading}
    />
  );
};

export default DocumentsListTable;
