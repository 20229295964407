import { QueryRequestHelper } from "@common/query-request-helper";
import Loader from "@components/common/Loader/loading";
import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import {
  Table,
  Tag,
  Typography,
  Input,
  Row,
  Col,
  Pagination,
  Button,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { getAllCallReports } from "@services/calls.service";
import { formatString } from "@common/text-helpers";
import { convertCurrentDateWithTime } from "@common/date-helpers";

interface CallReportItem {
  id: number;
  type: string;
  source_number: string;
  destination_number: string;
  dial_whom_number: string;
  call_duration: string;
  coins: string;
  status: string;
  start_time: string;
  end_time: string;
  call_sid: string;
  call_recording_url: string;
  direction: string;
  talk_duration: string;
  call_group: string;
  receiver_name: string;
  error_code: string;
  leg_a_picked_time: string;
  leg_b_start_time: string;
  leg_c_picked_time: string | null;
  key_press: string;
  hangup_cause: string;
  phone_id: string;
  contact_id: string;
  owner_id: string;
  org_id: string;
  created_at: string;
  updated_at: string;
  owner: {
    id: string;
    first_name: string;
    last_name: string;
    phone: string;
    user: {
      email: string;
    };
  };
  contact: {
    id: string;
    first_name: string | null;
    last_name: string | null;
    email: string | null;
    phone_number: string;
    mobile_number: string;
  };
  phone: {
    id: string;
    provider: string;
  };
}

function ContactCallsHistory(props: any) {
  const [callReports, setCallReports] = useState<CallReportItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    getData();
  }, [currentPage, pageSize, searchQuery]);

  const params = useParams();
  const getData = async () => {
    try {
      setIsLoading(true);
      const payload = {
        search: searchQuery,
        page_no: currentPage,
        page_size: pageSize,
        contact_id: getValue(props, `contact_id`, "")
          ? getValue(props, `contact_id`, "")
          : getValue(params, `id`, ""),
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getAllCallReports(queryRequest);
      if (resp) {
        setIsLoading(false);
        setCallReports(getValue(resp, `data.call_reports`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching call reports:", error);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchQuery(value);
    setCurrentPage(1); // Reset to first page when searching
  };

  const handlePageChange = (page: number, size: number) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  // Format date to display in a more readable format
  const formatDate = (dateString: string) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  // Calculate duration in minutes and seconds
  const formatDuration = (seconds: string) => {
    if (!seconds) return "-";
    const totalSeconds = parseInt(seconds);
    const minutes = Math.floor(totalSeconds / 60);
    const remainingSeconds = totalSeconds % 60;
    return `${minutes}m ${remainingSeconds}s`;
  };

  const getCallerInfo = (record: CallReportItem) => {
    if (
      record.contact &&
      (record.contact.first_name || record.contact.last_name)
    ) {
      return `${record.contact.first_name || ""} ${
        record.contact.last_name || ""
      }`.trim();
    }
    return record.source_number;
  };

  // Listen to recording
  const playRecording = (url: string) => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  const columns: ColumnsType<CallReportItem> = [
    {
      title: "CALLER",
      key: "caller",
      render: (_, record) => getCallerInfo(record),
    },
    {
      title: "SOURCE",
      dataIndex: "source_number",
      key: "source_number",
    },
    {
      title: "DESTINATION",
      dataIndex: "destination_number",
      key: "destination_number",
    },
    {
      title: "AGENT",
      key: "agent",
      render: (_, record) => (
        <span>
          {record.owner
            ? `${record.owner.first_name} ${record.owner.last_name}`
            : "-"}
        </span>
      ),
    },
    {
      title: "DIRECTION",
      dataIndex: "direction",
      key: "direction",
      render: (text) => (
        <span style={{ textTransform: "capitalize" }}>{text}</span>
      ),
    },
    {
      title: "DURATION",
      key: "duration",
      render: (_, record) => formatDuration(record.call_duration),
    },
    {
      title: "START TIME",
      dataIndex: "start_time",
      key: "start_time",
      render: (text) => convertCurrentDateWithTime(text),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color = "blue";
        if (status === "Completed") color = "green";
        if (
          status === "AbandonedCall" ||
          status.includes("Failed") ||
          status.includes("cancel")
        )
          color = "red";
        if (status === "Busy" || status === "NoAnswer") color = "orange";

        return <Tag color={color}>{formatString(status)}</Tag>;
      },
    },
    {
      title: "RECORDING",
      key: "recording",
      render: (_, record) =>
        record.call_recording_url ? (
          <Button
            type="link"
            onClick={() => playRecording(record.call_recording_url)}
          >
            Play
          </Button>
        ) : (
          <span>-</span>
        ),
    },
  ];

  return (
    <div>
      <>
        <Row
          gutter={[16, 16]}
          style={{ marginBottom: "16px", justifyContent: "space-between" }}
        >
          <Col xs={24} sm={12} md={8} lg={12}>
            <Input
              placeholder="Search Calls..."
              prefix={<SearchOutlined />}
              value={searchQuery}
              onChange={handleSearch}
              allowClear
            />
          </Col>
        </Row>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Table
              columns={columns}
              dataSource={callReports}
              rowKey={(record) => record.id.toString()}
              pagination={false}
              bordered
              scroll={{ x: "max-content" }}
            />
          </>
        )}
        <Row justify="end" style={{ marginTop: "16px" }}>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalCount}
            showSizeChanger
            showQuickJumper
            showTotal={(total) => `Total ${total} items`}
            onChange={handlePageChange}
            onShowSizeChange={handlePageChange}
          />
        </Row>
      </>
    </div>
  );
}

export default ContactCallsHistory;
