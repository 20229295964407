import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";
import InputRuleForm from "@components/InputRuleForm/form";
import SubmitContainer from "@components/common/ButtonContainer/SubmitContainer";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import UpdateFieldFormBuilder from "./update-field-form-builder";
import { updatesRequestObj } from "../workflow-request";
import { toast } from "sonner";
import { handleObjectValidation } from "@components/custom/validation";
import { useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Checkbox, Drawer } from "antd";
import AntSubmitContainer from "@components/common/ButtonContainer/NewSubmitContainer";
import AntSearchToolTip from "@components/custom/DebounceSelect";

function WorkflowUpdatesPopup(props: any) {
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const {
    isOpen,
    title,
    toggle,
    request,
    setRequest,
    submitLoading,
    mainRequest,
    setMainRequest,
    editIndex,
    setEditIndex,
  } = props;

  const handleAddEmailToList = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      if (editIndex) {
        let index = editIndex - 1;
        const updatedData = [...mainRequest.actions.updates];
        updatedData[index] = request;
        setMainRequest((prevState: any) => ({
          ...prevState,
          actions: {
            ...prevState.actions,
            updates: updatedData,
          },
        }));
        toggle();
        setRequest({ ...updatesRequestObj });
        setEditIndex(null);
      } else {
        if (getValue(request, `name`, "")) {
          mainRequest.actions.updates.push(request);
          setMainRequest({ ...mainRequest });
          setRequest({ ...updatesRequestObj });
          toggle();
        } else {
          toast.error("Please input required fields");
        }
      }
      hideErrorMessages();
    }
  };

  const hideErrorMessages = () => {
    simpleValidator.current.hideMessages();
    forceUpdate(0);
  };

  const getInputType = (id: string) => {
    let findType = getValue(props, `fileds`, []).find(
      (item: object) => getValue(item, `id`, "") === id
    );
    return findType;
  };

  const errors = handleObjectValidation(request, [
    "name",
    "moduleFieldId",
    // "valueToUse",
  ]);
  const hasErrors = errors.length > 0;
  return (
    <Drawer
      open={isOpen}
      title={title}
      onClose={() => {
        toggle();
        setRequest({ ...updatesRequestObj });
        setEditIndex(null);
        hideErrorMessages();
      }}
      width={"50%"}
      className="ant-drawer-container-footer"
      footer={
        <AntSubmitContainer
          handleBackButton={() => {
            toggle();
            setRequest({ ...updatesRequestObj });
            setEditIndex(null);
            hideErrorMessages();
          }}
          handleSubmit={() => handleAddEmailToList()}
          submitLoading={submitLoading}
          first_title={"Cancel"}
          second_title={editIndex ? "Update" : "Save"}
          disableValidation
          permissions={["create"]}
          // disabled={hasErrors ? true : false}
        />
      }
    >
      <div className=" p-3">
        <div className="">
          <InputRuleForm
            inputType="TEXT"
            placeholder="Enter Name"
            name="name"
            label={"Name"}
            value={getValue(request, `name`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                name: e.target.value,
              })
            }
            hasRequired
            validator={simpleValidator}
            validLeft
          />
        </div>

        <div className="d-flex align-items-center mt-3 w-100">
          <div className="w-50">
            <AntSearchToolTip
              label="Field"
              options={props.fileds}
              selectKey={"id"}
              onChange={(id: any) =>
                setRequest({
                  ...request,
                  moduleFieldId: id,
                  valueToUse: "",
                })
              }
              value={getValue(request, `moduleFieldId`, "")}
              required
              validator={simpleValidator}
            />
          </div>
          <h6 className="header_text__20 mx-3 mt-4">=</h6>
          <div className="w-50">
            {getValue(request, `moduleFieldId`, "") ? (
              <UpdateFieldFormBuilder
                item={getInputType(getValue(request, `moduleFieldId`, ""))}
                setRequest={setRequest}
                request={request}
                value={getValue(request, `valueToUse`, "")}
                key_value="valueToUse"
              />
            ) : (
              <div className="">
                <InputRuleForm
                  inputType="TEXT"
                  placeholder="Please select field"
                  name="name"
                  disabled
                />
              </div>
            )}
          </div>
        </div>
        <div className="mt-4">
          <Checkbox
            checked={getValue(request, `isEmpty`, false)}
            onChange={(e) =>
              setRequest({
                ...request,
                isEmpty: e.target.checked,
              })
            }
          >
            Is Empty
          </Checkbox>
        </div>
      </div>
    </Drawer>
  );
}

export default WorkflowUpdatesPopup;
