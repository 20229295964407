import LoginPage from "@pages/Public/Auth/Signin";
import RequestOtp from "@pages/Public/Auth/RequestOtp";
import Register from "@pages/Public/Auth/Signup";
import CreateOrgUserByInviteToken from "@pages/Public/Auth/create-org-user";
import SignInWithOTPScreen from "@pages/Public/Auth/SignWithOTP/signin-otp-screen";
import SignInWithOTPEmailScreen from "@pages/Public/Auth/SignWithOTP/signin-otp-email-screen";
import ForgotPasswordOTPScreen from "@pages/Public/Auth/ForgotPassword/forgot-pwd-otp-screen";
import ForgotPasswordEmailScreen from "@pages/Public/Auth/ForgotPassword/forgot-pwd-email-screen";
import NewSignup from "@pages/Public/Auth/NewSignup";

export const AuthRoutes = [
  { path: "/signin", name: "Login", component: LoginPage },
  { path: "/otp/:token", name: "Otp", component: RequestOtp },
  // { path: "/signup", name: "Register", component: Register },
  { path: "/signup", name: "Register", component: NewSignup },
  {
    path: "/user-signup/invite",
    name: "Register",
    component: CreateOrgUserByInviteToken,
  },

  //sign-in with OTP

  {
    path: "/signin-with-email",
    name: "Login",
    component: SignInWithOTPEmailScreen,
  },
  {
    path: "/signin-with-otp/:token",
    name: "Login",
    component: SignInWithOTPScreen,
  },

  //forgot password with OTP
  {
    path: "/forgot-password-email",
    name: "Login",
    component: ForgotPasswordEmailScreen,
  },
  {
    path: "/forgot-password-otp/:token",
    name: "Login",
    component: ForgotPasswordOTPScreen,
  },
];
