import { SearchOutlined } from "@ant-design/icons";
import {
  capitalizeFirstLetter,
  concatString,
  formatString,
} from "@common/text-helpers";
import {
  createBookmarkedModules,
  deleteBookmark,
} from "@services/bookmark.service";
import { getValue } from "@utils/lodash";
import { Badge, Button, Input, Popover, Tooltip } from "antd";
import { Menu, Shuffle, SlidersHorizontal, Star, X } from "lucide-react";

function ChatSearchHeader(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const {
    handleChangeSearch,
    clearSearch,
    toggleFilterView,
    filterCount,
    handleApplyUnReadFilter,
    filterRequest,
    searchText,
    selectedTab,
    toggleFilterDrawer,
    bookmarkedModulePath,
    bookmarkedModules,
    themeColor,
    bookmarkedModuleName,
    refreshBookmarks,
    rawStatusCounts,
    permissions,
    defaultConversationType,
    isBulkUpdateOpen,
    toggleBulkUpdateView,
    handleCancelBulkSelection,
  } = props;
  const matchedStatus = rawStatusCounts.all.find(
    (status: any) =>
      getValue(status, "status", "") === getValue(UrlParams, "convStatus", "")
  );
  const matchedUnreadStatus = rawStatusCounts.unread.find(
    (status: any) => getValue(status, "status", "") === selectedTab
  );
  const unreadCount = getValue(matchedUnreadStatus, "count", 0);
  const createBookmark = async () => {
    let resp = await createBookmarkedModules({
      name: capitalizeFirstLetter(bookmarkedModuleName),
      path: bookmarkedModulePath,
    });

    if (resp) {
      refreshBookmarks();
    }
  };

  const removeBookmark = async (id: string) => {
    let resp = await deleteBookmark(id);
    if (resp) {
      refreshBookmarks();
    }
  };

  return (
    <div className="">
      <div className="live-chat-header">
        {bookmarkedModulePath &&
          (bookmarkedModules.find(
            (bookmarkItem: any) => bookmarkItem.path === bookmarkedModulePath
          ) ? (
            <Tooltip title="Remove from bookmarks">
              <Button
                color="default"
                variant="text"
                onClick={() =>
                  removeBookmark(
                    bookmarkedModules.find(
                      (bookmarkItem: any) =>
                        bookmarkItem.path === bookmarkedModulePath
                    ).id
                  )
                }
                icon={<Star color={themeColor} fill={themeColor} size={18} />}
              />
            </Tooltip>
          ) : (
            <Tooltip title="Add to bookmarks">
              <Button
                color="primary"
                variant="text"
                onClick={createBookmark}
                icon={<Star size={18} color={themeColor} />}
              />
            </Tooltip>
          ))}
        <h6 className="submenu-name">Live Chat</h6>
      </div>
      <div className="d-flex align-items-center justify-content-between message_search">
        <Button
          color="primary"
          variant="text"
          onClick={toggleFilterDrawer}
          icon={<Menu size={18} />}
          className="d-flex align-items-center gap-2"
        >
          {getValue(props, `searchText`, "")
            ? "Searched list"
            : formatString(
                getValue(UrlParams, "convStatus", "") || defaultConversationType
              )}
          {/* {getValue(props, `searchText`, "")
            ? "Searched list"
            : concatString(
                formatString(
                  getValue(UrlParams, "convStatus", "") ||
                    defaultConversationType
                ),
                24
              )} */}
          {!getValue(props, `searchText`, "") && unreadCount > 0 && (
            <Badge color="#25d366" count={unreadCount} />
          )}
          {/* {getValue(UrlParams, "convStatus", "") ===
            defaultConversationType && (
            <Bookmark size={16} fill={themeColor} color={themeColor} />
          )} */}
        </Button>

        <div className="d-flex align-items-center gap-1">
          {getValue(props, `searchText`, "") && (
            <Button
              color="danger"
              variant="filled"
              icon={<X size={18} />}
              size="small"
              onClick={clearSearch}
            >
              Clear
            </Button>
          )}

          <Popover
            content={
              <Input
                type="search"
                name="search-form"
                id="search-form"
                className="contact-search"
                onChange={(e) => handleChangeSearch(e)}
                placeholder="Search user"
                value={searchText}
                allowClear
                autoFocus={true}
              />
            }
            title="Search User"
            trigger="click"
          >
            <Button
              color="default"
              variant="text"
              icon={<SearchOutlined size={18} />}
            />
          </Popover>
          {getValue(props, `permissions`, []).includes("allocation") && (
            <Tooltip title={"Bulk Update"}>
              <Button
                color="default"
                variant="text"
                onClick={toggleBulkUpdateView}
                icon={<Shuffle size={18} />}
              />
            </Tooltip>
          )}

          <Tooltip title={"Filter"}>
            <div className="position-relative">
              <Button
                color="default"
                variant="text"
                onClick={toggleFilterView}
                icon={<SlidersHorizontal size={18} />}
              />
              {filterCount > 0 && (
                <div className="filter_count__container">{filterCount}</div>
              )}
            </div>
          </Tooltip>
        </div>
      </div>
      {!getValue(props, `searchText`, "") && (
        <div className="d-flex gap-2 pb-1 px-4">
          <button
            onClick={() => handleApplyUnReadFilter(false)}
            className={`wa-filter-button ${
              filterRequest.only_unread === false ? "wa-filter-selected" : ""
            }`}
          >
            All
          </button>
          <button
            onClick={() => handleApplyUnReadFilter(true)}
            className={`wa-filter-button ${
              filterRequest.only_unread === true ? "wa-filter-selected" : ""
            }`}
          >
            Unread
          </button>
        </div>
      )}
    </div>
  );
}

export default ChatSearchHeader;
