import { useEffect, useRef, useState } from "react";
import ListLoader from "@components/common/Loader";
import SimpleReactValidator from "simple-react-validator";
import { updateOrganisations } from "@services/organisations.service";
import { toast } from "sonner";
import { useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import CompanyProfileSubHeader from "./components/CompanyProfileSubHeader";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import { useStateContext } from "@context/dataContext";
import useDynamicTitle from "@context/useDynamicTitle";
import CompanyPaymentForm from "./components/CompanyPaymentForm";
import {
  createPaymentIntegrationGateway,
  createPaymentIntegrationPortal,
  getAllPaymentIntegrationPortals,
  getSpecificPaymentIntegrationGateway,
  updatePaymentIntegrationGateway,
} from "@services/payment-integrations.service";
import { Button } from "antd";
import GatewayDrawer from "./components/gatway-popup";
import PaymentAdditionalSettings from "./components/PaymentAdditionalSettings";
import { QueryRequestHelper } from "@common/query-request-helper";
import { listAllModules } from "@services/modules.service";
import { sortJSONObjectArray } from "@common/text-helpers";
import { allPipelines } from "@services/pipeline.service";

interface AdditionalInfoProps {
  enable_template: boolean;
  enable_canned_response: boolean;
  wa_account_id?: string;
  template_id?: string;
  canned_response_id?: string;
  enable_deal_pipeline_stage: boolean;
  deal_pipeline_id?: string;
  deal_pipeline_stage_id?: string;
  enable_contact_pipeline_stage: boolean;
  contact_pipeline_id?: string;
  contact_pipeline_stage_id?: string;
}

export default function CompanyPayment(props: any) {
  const params = useParams();
  const { handleChangeOrgainization } = useStateContext();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [request, setRequest] = useState({
    merchant_id: "",
    merchant_key: "",
  });
  const [loading, setLoading] = useState(false);
  const [paymantInfo, setPaymentInfo] = useState({});

  const [additionalInfo, setAdditionalInfo] = useState<AdditionalInfoProps>({
    enable_template: false,
    enable_canned_response: false,
    enable_deal_pipeline_stage: false,
    enable_contact_pipeline_stage: false,
  });
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getConfig();
    getModules();
  }, []);

  useEffect(() => {
    if (getValue(paymantInfo, `payment_gateways[${0}].id`, "")) {
      getData(getValue(paymantInfo, `payment_gateways[${0}].id`, ""));
    }
  }, [paymantInfo]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getConfig = async () => {
    try {
      setLoading(true);
      const resp = await getAllPaymentIntegrationPortals();
      if (resp) {
        setPaymentInfo(getValue(resp, `data`, {}));
        if (getValue(resp, `data.payment_gateways[${0}].id`, "")) {
          getData(getValue(resp, `data.payment_gateways[${0}].id`, ""));
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const [editId, setEditId] = useState("");
  const getData = async (id: string) => {
    try {
      let resp = await getSpecificPaymentIntegrationGateway(id);
      if (resp) {
        setEditId(getValue(resp, `data.id`, ""));
        setRequest({
          ...request,
          merchant_key: getValue(resp, `data.merchant_key`, ""),
          merchant_id: getValue(resp, `data.merchant_id`, ""),
        });

        let additionalData = getValue(resp, `data`, {});

        let filteredAdditionalData = {
          ...(additionalData.enable_template !== null && {
            enable_template: additionalData.enable_template,
          }),
          ...(additionalData.enable_canned_response !== null && {
            enable_canned_response: additionalData.enable_canned_response,
          }),
          ...(additionalData.wa_account_id !== null && {
            wa_account_id: additionalData.wa_account_id,
          }),
          ...(additionalData.template_id !== null && {
            template_id: additionalData.template_id,
          }),
          ...(additionalData.canned_response_id !== null && {
            canned_response_id: additionalData.canned_response_id,
          }),
          ...(additionalData.enable_deal_pipeline_stage !== null && {
            enable_deal_pipeline_stage:
              additionalData.enable_deal_pipeline_stage,
          }),
          ...(additionalData.deal_pipeline_id !== null && {
            deal_pipeline_id: additionalData.deal_pipeline_id,
          }),
          ...(additionalData.deal_pipeline_stage_id !== null && {
            deal_pipeline_stage_id: additionalData.deal_pipeline_stage_id,
          }),
          ...(additionalData.enable_contact_pipeline_stage !== null && {
            enable_contact_pipeline_stage:
              additionalData.enable_contact_pipeline_stage,
          }),
          ...(additionalData.contact_pipeline_id !== null && {
            contact_pipeline_id: additionalData.contact_pipeline_id,
          }),
          ...(additionalData.contact_pipeline_stage_id !== null &&
            additionalData.contact_pipeline_stage_id !== "" && {
              contact_pipeline_stage_id:
                additionalData.contact_pipeline_stage_id,
            }),
        };

        setAdditionalInfo({
          ...additionalInfo,
          ...filteredAdditionalData,
        });
      }
    } catch (error) {
      console.error("Error fetching payment integration gateway:", error);
    }
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      const updateRequest = {
        ...request,
        ...additionalInfo,
      };
      try {
        setSubmitLoading(true);
        let resp = editId
          ? await updatePaymentIntegrationGateway(editId, updateRequest)
          : await createPaymentIntegrationGateway({
              ...request,
              gateway: "razorpay",
              payment_portal_id: getValue(paymantInfo, `id`, ""),
            });
        if (resp) {
          handleChangeOrgainization();
          toast.success("Updated successfully");
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  useDynamicTitle("Company Payment");

  const [isOpen, setIsOpen] = useState(false);
  const handleOpenDrawer = () => {
    setIsOpen(!isOpen);
  };

  const [cLoading, setCLoading] = useState(false);
  const ConfigureGateway = async (name: string) => {
    try {
      setCLoading(true);
      const resp = await createPaymentIntegrationPortal({ name: name });
      if (resp) {
        getConfig();
        setCLoading(false);
      } else {
        setCLoading(false);
      }
    } catch (error) {
      setCLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [allModules, setAllModules] = useState([]);
  const getModules = async () => {
    try {
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await listAllModules(queryRequest);
      if (resp) {
        let list = getValue(resp, `data`, []).map((item: object) => ({
          ...item,
          value: getValue(item, `name`, ""),
        }));
        setAllModules(sortJSONObjectArray(list, "seq_num"));
      }
    } catch (error) {}
  };

  const [pipelineList, setPipelineList] = useState({
    contacts: [],
    deals: [],
  });
  const [pipelineDetails, setPipelineDetails] = useState({
    contacts: "",
    deals: "",
  });
  const getAllPipelineList = async (moduleId: string, moduleName: string) => {
    let moudleName = allModules.filter(
      (item: object) => getValue(item, `id`, "") === moduleId
    );
    try {
      let payload = {
        module_name: getValue(moudleName, `[${0}].name`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await allPipelines(moduleId, queryRequest);
      if (resp) {
        if (moduleName === "contacts") {
          setPipelineDetails((prevState: any) => ({
            ...prevState,
            contacts: getValue(resp, `data[0]`, ""),
          }));
        }
        if (moduleName === "deals") {
          setPipelineDetails((prevState: any) => ({
            ...prevState,
            deals: getValue(resp, `data[0]`, ""),
          }));
        }
        setPipelineList((prevState: any) => ({
          ...prevState,
          [moduleName]: getValue(resp, `data[0].stages`, [])
            .filter((item: object) => getValue(item, `archived`, "") === false)
            .map((item: object) => ({
              name: getValue(item, `api_name`, ""),
              label: getValue(item, `label`, ""),
              id: getValue(item, `id`, ""),
              value: getValue(item, `id`, ""),
            })),
        }));
      }
    } catch (error) {}
  };

  return (
    <CompanyProfileDetailsLayout
      {...props}
      classname="my-0"
      isLoading={isLoading}
    >
      <CompanyProfileSubHeader
        isLoading={submitLoading}
        handleSubmit={handleSubmit}
        showSubmit
        title="Company Payment"
        settings
        icon="company-payment"
      />
      {isLoading || loading ? (
        <ListLoader />
      ) : getValue(paymantInfo, `id`, "") ? (
        <>
          <CompanyPaymentForm
            request={request}
            setRequest={setRequest}
            simpleValidator={simpleValidator}
          />
          <PaymentAdditionalSettings
            additionalInfo={additionalInfo}
            setAdditionalInfo={setAdditionalInfo}
            allModules={allModules}
            getAllPipelineList={getAllPipelineList}
            pipelineList={pipelineList}
            pipelineDetails={pipelineDetails}
          />
        </>
      ) : (
        <div className="m-5">
          <h6 className="header_text__16">Configure Payment Gateway</h6>
          <div className="border mt-4 w-20 border-radius__5">
            <img src="/images/razorpay.webp" height={200} width={200} />
          </div>
          <Button
            className="mt-4"
            onClick={() => ConfigureGateway("razorpay")}
            disabled={cLoading}
            loading={cLoading}
          >
            Config
          </Button>
        </div>
      )}
      <GatewayDrawer isOpen={isOpen} handleOpenDrawer={handleOpenDrawer} />
    </CompanyProfileDetailsLayout>
  );
}
