import { convertCurrentDateToTime } from "@common/date-helpers";
import { replaceDefaultPlaceholders } from "@common/markdown/markdown-helper";
import { getValue } from "@utils/lodash";
import { Button } from "antd";
import React, { useState } from "react";

const TextPreview = ({ item, getReadIcon }: any) => {
  const [isExpanded, setIsExpanded] = useState(false);
  // Simple function to check URL
  const isValidURL = (() => {
    try {
      const text = getValue(item, "text", "");
      if (!text) return false;
      return text.startsWith("http://") || text.startsWith("https://");
    } catch {
      return false;
    }
  })();

  const text = getValue(item, `text_body`, "") || getValue(item, `text`, "");
  const isLongText = getValue(text, `length`, 0) > 400;
  const displayText = isExpanded ? text : text && text.slice(0, 400);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const processText = (text: any) => {
    // Split the text by newlines or spaces
    const urlArray = text
      .split(/[\n\s]+/)
      .filter(
        (url: any) =>
          url.trim() !== "" &&
          (url.startsWith("http://") || url.startsWith("https://"))
      );
    return urlArray;
  };

  return (
    <div className="d-flex gap-2 justify-content-between">
      {isValidURL ? (
        <div>
          {processText(getValue(item, `text`, "")).map((url: any) => (
            <a
              href={url}
              target="blank"
              className="small_text__14 px-2 pb-2 message-line-height header_blue_text__14"
            >
              {url}
              <br />
              <br />
            </a>
          ))}
        </div>
      ) : (
        <div className="text-black small_text__14 px-2 pb-2">
          <p
            dangerouslySetInnerHTML={{
              __html: replaceDefaultPlaceholders(displayText),
            }}
            className="message-line-height mb-1"
          />
          {isLongText && (
            <div className="d-flex justify-content-start">
              <Button
                color="primary"
                variant="link"
                onClick={toggleExpand}
                className=" p-0 m-0"
              >
                {isExpanded ? "Show Less" : "Show More"}
              </Button>
            </div>
          )}
        </div>
      )}
      <p className="send-time d-flex align-items-end mb-1">
        {convertCurrentDateToTime(getValue(item, `created_at`, ""))}
        {!getValue(item, `message`, "") &&
          getValue(item, "is_private", "") === false && (
            <span className="ms-2">{getReadIcon(item)}</span>
          )}
      </p>
    </div>
  );
};

export default TextPreview;
