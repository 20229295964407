import * as React from "react";
import { Alert, Button, Modal, Popover, Radio, Switch } from "antd";
import { getValue } from "@utils/lodash";
import InputRuleForm from "@components/InputRuleForm/form";
import EmojiPicker from "emoji-picker-react";
import { useMemo } from "react";
const CreateAgentStatus = (props: any) => {
  const { request, setRequest, editId, validator } = props;

  const [showEmojiPicker, setShowEmojiPicker] = React.useState(false);
  const toggleEmojiPicker = () => setShowEmojiPicker(!showEmojiPicker);

  const handleEmojiClick = (event: any) => {
    setRequest({
      ...request,
      emoji: event.emoji,
    });
    setShowEmojiPicker(false);
  };

  const statusOptions = [
    {
      value: "online",
      label: "Online",
      description: "Available and ready to handle customer inquiries",
    },
    {
      value: "busy",
      label: "Busy",
      description:
        "Choose this when agents are engaged in work-related activities. eg: Meeting, Training, etc.",
    },
    {
      value: "away",
      label: "Away",
      description:
        "Choose this when agents are unable to work. eg: Lunch, Personal work, etc.",
    },
  ];
  const list = useMemo(() => {
    return (
      getValue(props, `agentStatuses.length`, 0) > 0 &&
      getValue(props, `agentStatuses`, []).map((item: object) =>
        getValue(item, `name`, "").toLowerCase()
      )
    );
  }, [props.agentStatuses]);

  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      onCancel={() => {
        props.handleModal();
        props.reset();
        setShowEmojiPicker(false);
      }}
      onOk={props.handleSubmit}
      okText="Submit"
      title={editId ? "Edit Agent Status" : "Create Agent Status"}
      okButtonProps={
        {
          // disabled: !getValue(request, `name`, ""),
          // list.includes(getValue(request, `name`, "").toLowerCase())
        }
      }
    >
      <section>
        <div className=" mb-3">
          <div className="d-flex align-items- gap-2">
            <div>
              <label className="form-label-nowidth mt-2">Emoji</label>
              <Popover
                open={showEmojiPicker}
                content={
                  <EmojiPicker
                    onEmojiClick={(event: any) => {
                      handleEmojiClick(event);
                      setShowEmojiPicker(false);
                    }}
                  />
                }
                // trigger="click"
              >
                <Button
                  size="large"
                  color="default"
                  variant="text"
                  onClick={toggleEmojiPicker}
                  className="d-flex align-items-center gap-1 "
                  style={{ fontSize: "24px" }}
                  icon={getValue(request, `emoji`, "🙂")}
                >
                  {/* <ChevronDown size={16} /> */}
                </Button>
              </Popover>
            </div>
            <InputRuleForm
              inputType={"TEXT"}
              value={getValue(request, `name`, "")}
              onChange={(e: any) =>
                setRequest({ ...request, name: e.target.value })
              }
              label="Name"
              placeholder="Enter Name"
              maxLength={50}
              validator={validator}
              validLeft
            />
          </div>
          <Alert
            showIcon
            message="You cannot change the status type after the status has been created."
            type="info"
            className="mt-3"
          />
          <div className="mb-3 mt-2">
            <label className="form-label">Type</label>
            <Radio.Group
              value={getValue(request, `type`, "")}
              onChange={(e) => setRequest({ ...request, type: e.target.value })}
              disabled={editId}
            >
              {statusOptions.map((option) => (
                <div key={option.value} className="mb-2">
                  <Radio value={option.value}>
                    <div>
                      <div className="font-weight-bold">{option.label}</div>
                      <div className="text-muted small">
                        {option.description}
                      </div>
                    </div>
                  </Radio>
                </div>
              ))}
            </Radio.Group>
          </div>
          <div className="mt-2">
            <label className="form-label">Status</label>
            <div>
              <Switch
                size="small"
                checked={getValue(request, `is_active`, false)}
                onChange={() =>
                  setRequest({
                    ...request,
                    is_active: !getValue(request, `is_active`, false),
                  })
                }
              />
            </div>
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default CreateAgentStatus;
