import { getValue } from "@utils/lodash";
import { Button, ColorPicker, Table } from "antd";
import { Trash2 } from "lucide-react";

function TagTable({
  list,
  handleEdit,
  handleDeleteModal,
  mainPermissions,
  page_no,
  limit,
  totalCount,
  handleChangePagination,
}: any) {
  const excludedModules = ["calls", "meetings"];

  const validTabKeys = mainPermissions?.map(
    (perm: any) => perm.tabKey.split("_")[1]
  );

  const moduleColumns = Object.keys(list[0]?._count || {})
    .filter(
      (key) => validTabKeys?.includes(key) && !excludedModules.includes(key)
    )
    .map((key) => ({
      title: (key.charAt(0).toUpperCase() + key.slice(1)).toUpperCase(),
      dataIndex: `_count.${key}`,
      key,
      width: 100,
      render: (count: any) => (count > 0 ? count : "-"),
    }));

  const columns = [
    {
      title: "NAME",
      dataIndex: "label",
      key: "label",
      render: (label: any, record: any) => (
        <div
          className="header_blue_text__14 cursor-pointer"
          onClick={() => handleEdit(record)}
        >
          {label}
        </div>
      ),
    },
    {
      title: "COLOR",
      dataIndex: "color_code",
      key: "color_code",
      width: 60,
      render: (color_code: any) => (
        <ColorPicker value={color_code} disabled size="small" />
      ),
    },
    {
      title: "MODULES",
      dataIndex: "modules",
      key: "modules",
      render: (_: any, record: any) => {
        const modules = Object.entries(record)
          .filter(([key]) => key.startsWith("is_"))
          .filter(([_, value]) => value === true)
          .map(
            ([key]) =>
              key.replace("is_", "").charAt(0).toUpperCase() +
              key.replace("is_", "").slice(1)
          )
          .join(", ");
        return modules;
      },
    },
    ...moduleColumns,
    {
      title: "ACTION",
      dataIndex: "is_active",
      key: "is_active",
      width: 100,
      render: (_: any, record: any) => (
        <div className="d-flex gap-3 align-items-center">
          <Button
            size="small"
            variant="text"
            color="danger"
            onClick={() => handleDeleteModal(getValue(record, `id`, ""))}
            icon={<Trash2 size={16} />}
          />
        </div>
      ),
    },
  ];

  return (
    <Table
      size="middle"
      className="p-4"
      bordered
      columns={columns}
      dataSource={list}
      rowKey="id"
      pagination={{
        size: "default",
        current: page_no,
        pageSize: limit,
        total: totalCount,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50"],
        onChange: (page_no, pageSize) => {
          handleChangePagination(page_no, pageSize);
        },
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
      }}
      scroll={{ y: "calc(100vh - 260px)", x: "max-content" }}
    />
  );
}

export default TagTable;
