import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllSaleItems = (queryRequest: string) =>
  get(`${config.API_URL}/sale-items?${queryRequest}`);

export const getSpecificSaleItem = (id: string) =>
  get(`${config.API_URL}/sale-items/${id}`);

export const createSaleItem = (payload: object) =>
  post(`${config.API_URL}/sale-items`, payload);

export const updateSaleItem = (id: string, payload: object) =>
  patch(`${config.API_URL}/sale-items/${id}`, payload);

export const deleteSaleItem = (id: string) =>
  Delete(`${config.API_URL}/sale-items/${id}`);

export const listAllSaleItems = (queryRequest: string) =>
  get(`${config.API_URL}/sale-items/list?${queryRequest}`);

  export const bulkUpdateSaleItem = ( payload: object) =>
  patch(`${config.API_URL}/sale-items/bulk/active-inactive`, payload);
