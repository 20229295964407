import { DeleteOutlined } from "@ant-design/icons";
import { capitalizeFirstLetter } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Alert, Button, Table } from "antd";
import { Plus } from "lucide-react";
import React from "react";

const ShiftAgents = (props: any) => {
  const {
    isLoading2,
    agentsData,
    setSelectedAgentIds,
    params,
    selectedAgentIds,
    handleDeleteAgent,
    showModal,
  } = props;

  const columns = [
    {
      title: "FIRST NAME",
      dataIndex: "first_name",
      key: "first_name",
      render: (first_name: any, text: any) => (
        <>
          {/* <h6 className="header_text__14"> */}
          {capitalizeFirstLetter(first_name)}
          {/* </h6> */}
        </>
      ),
    },
    {
      title: "LAST NAME",
      dataIndex: "last_name",
      key: "last_name",
      render: (last_name: any, text: any) => (
        <>
          {/* <h6 className="header_text__14"> */}
          {capitalizeFirstLetter(last_name)}
          {/* </h6> */}
        </>
      ),
    },
    {
      title: "GROUPS",
      dataIndex: "group_agent",
      key: "group",
      render: (group: any, text: any) => (
        <>
          {Array.isArray(group) && group.length > 0 ? (
            group.map((groups: any) => (
              <div key={groups.group_id}>
                {getValue(groups, "group.name", "")}
              </div>
            ))
          ) : (
            <div>No groups available</div>
          )}
        </>
      ),
    },
  ];

  const handleRowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      const updatedAgents = selectedRowKeys.map((key) => ({
        id: key as string,
      }));
      setSelectedAgentIds(updatedAgents);
    },
  };

  return (
    <div>
      <div className="d-flex justify-content-end gap-2">
        {selectedAgentIds.length > 0 && (
          <Button
            danger
            type="primary"
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteAgent()}
          >
            Delete Agents
          </Button>
        )}
        <Button icon={<Plus size={16} />} onClick={() => showModal()}>
          Add Agents
        </Button>
      </div>
      {agentsData.agents.length === 0 && (
        <Alert
          showIcon
          message="Create a group with Automatic Ticket Assignment enabled, and ensure an agent is added to facilitate shift allocation."
          type="info"
          action={
            <a
              href={`/${getValue(
                params,
                "orgId",
                ""
              )}/settings/helpdesk/groups`}
              className="ms-3"
              target="_blank"
              rel="noreferrer"
            >
              Go to groups
            </a>
          }
          className="w-fit-content"
        />
      )}
      <Table
        size="middle"
        bordered
        columns={columns}
        dataSource={getValue(agentsData, "agents", [])}
        rowKey="id"
        loading={isLoading2}
        className="mt-3"
        rowSelection={{
          type: "checkbox",
          ...handleRowSelection,
        }}
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
        scroll={{
          y: "calc(100vh - 278px)",
        }}
      />
    </div>
  );
};

export default ShiftAgents;
