import { ArrowLeft, ArrowRight, Pencil, Tags, X, XCircle } from "lucide-react";
import ContentEditor from "./Articles/ContentEditor";
import { Empty, Select, Tag } from "antd";
import { getValue } from "@utils/lodash";

const CategoryDetailsRightSide = (props: any) => {
  const {
    params,
    navigate,
    articleRequest,
    setArticleRequest,
    handleSubmitArticle,
    previousArticle,
    nextArticle,
    isTagsVisible,
    handleChange,
    toggleTags,
  } = props;

  const options = articleRequest.tags
    ? articleRequest.tags.map((tag: string) => ({
        value: tag,
        label: tag,
      }))
    : [];

  return (
    <div className="w-100">
      {Object.keys(props.articleDetails).length > 0 ? (
        <div className="content-editor">
          <div className="editor-scrollable">
            <div className="content-inputs-wrapper">
              {isTagsVisible ? (
                <div className="d-flex align-items-center gap-3 pb-2">
                  <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    placeholder="Select or Add Tags"
                    onChange={handleChange}
                    options={options}
                    value={getValue(articleRequest, "tags", [])}
                  />
                  <XCircle
                    size={16}
                    className="cursor-pointer"
                    onClick={() => toggleTags()}
                  />
                </div>
              ) : getValue(articleRequest, "tags.length", 0) > 0 ? (
                <div className="pb-2">
                  {getValue(articleRequest, "tags", []).map(
                    (item: string, index: number) => (
                      <span key={index}>
                        <Tag color="purple">
                          <Tags size={16} /> {item}
                        </Tag>
                      </span>
                    )
                  )}
                  <Pencil
                    size={16}
                    className="cursor-pointer"
                    onClick={() => toggleTags()}
                  />
                </div>
              ) : null}

              <div className="article-heading-wrapper">
                <input
                  type="text"
                  value={getValue(articleRequest, "title", "")}
                  onChange={(e) =>
                    setArticleRequest((prev: any) => ({
                      ...prev,
                      title: e.target.value,
                    }))
                  }
                  onBlur={() => {
                    if (getValue(articleRequest, "title", "") !== "") {
                      handleSubmitArticle({
                        folder_id: getValue(articleRequest, "folder_id", ""),
                        title: getValue(articleRequest, "title", ""),
                      });
                    }
                  }}
                  className="article-heading-input"
                  placeholder="Untitled page"
                  maxLength={144}
                />
                {getValue(articleRequest, "tags.length", 0) === 0 && (
                  <div
                    className={`${
                      isTagsVisible ? "display-none" : "article-tags-wrapper"
                    } small_text__13`}
                    onClick={() => toggleTags()}
                  >
                    <Tags size={16} />
                    Add Tags
                  </div>
                )}
              </div>
              {/* <input
               type="textarea"
               value={getValue(articleRequestCopy, "title", "")}
               onChange={(e) =>
                 setArticleRequestCopy((prev) => ({
                   ...prev,
                   title: e.target.value,
                 }))
               }
               className="article-description-input mt-2"
               placeholder="Page description) optional"
               limit={144}
             /> */}
            </div>
            <ContentEditor {...props} />
            <div
              className={`article-nav-buttons-wrapper ${
                previousArticle && nextArticle ? "grid-cols-2" : ""
              }`}
            >
              {previousArticle && (
                <div
                  className="article-nav-buttons"
                  onClick={() =>
                    navigate(
                      `/${getValue(params, "orgId", "")}/solutions/${getValue(
                        params,
                        "id",
                        ""
                      )}/folders/${getValue(
                        params,
                        "folderId",
                        ""
                      )}/article/${getValue(previousArticle, "id", "")}`
                    )
                  }
                >
                  <ArrowLeft />
                  <div className="d-flex flex-column gap-2">
                    <span className="text-end">Previous</span>
                    <p className="header_text__15 text-end">
                      {getValue(previousArticle, "title", "")}
                    </p>
                  </div>
                </div>
              )}
              {nextArticle && (
                <div
                  className="article-nav-buttons"
                  onClick={() =>
                    navigate(
                      `/${getValue(params, "orgId", "")}/solutions/${getValue(
                        params,
                        "id",
                        ""
                      )}/folders/${getValue(
                        params,
                        "folderId",
                        ""
                      )}/article/${getValue(nextArticle, "id", "")}`
                    )
                  }
                >
                  <div className="d-flex flex-column gap-2">
                    <span>Next</span>
                    <p className="header_text__15">
                      {getValue(nextArticle, "title", "")}
                    </p>
                  </div>
                  <ArrowRight />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Empty
          description="Selected an article to continue"
          className="rounded-2 px-4 py-2 mt-5"
        />
      )}
    </div>
  );
};

export default CategoryDetailsRightSide;
