import { InfoCircleFilled } from "@ant-design/icons";
import AccountCircleSvgComponent from "@assets/svg/account-circle";
import CameraSvgComponent from "@assets/svg/camera";
import DocumentSvgComponent from "@assets/svg/document";
import GlobeSvgComponent from "@assets/svg/globe";
import MicSvgComponent from "@assets/svg/mic";
import VideoSvgComponent from "@assets/svg/video";
import WhatsappSvgComponent from "@assets/svg/whastapp";
import { getColorCode } from "@common/colors";
import { formatDatetoDay } from "@common/date-helpers";
import {
  replaceDefaultPlaceholdersBySingleLine,
  replaceDefaultPlaceholdersBySingleLineWithoutHyperlink,
} from "@common/markdown/markdown-helper";
import {
  concatString,
  concatStringWithPhoneSupport,
  formatString,
  getFirstLetterOfWord,
  getOnlyFirstLetterOfWord,
} from "@common/text-helpers";
import Loader from "@components/common/Loader/loading";
import ProfileCircle from "@components/common/ProfileCircle";
import { getValue } from "@utils/lodash";
import { Badge, Button, Checkbox, Empty, Tooltip } from "antd";
import { Merge, X, MapPin, UserPlus, Info, Sticker } from "lucide-react";

function InboxSearchContacts(props) {
  const {
    isLoading,
    conversations,
    selectedConversation,
    handleSelectConvesation,
    handleRemovePipelineInfo,
    isBulkUpdateOpen,
    selectedInboxIds,
    setSelectedInboxIds,
    handleCancelBulkSelection,
    toggleOpenBulkUpdateModal,
  } = props;

  const handleSelectInbox = (id) => {
    if (selectedInboxIds.includes(id)) {
      setSelectedInboxIds(selectedInboxIds.filter((inboxId) => inboxId !== id));
    } else {
      setSelectedInboxIds(selectedInboxIds ? [...selectedInboxIds, id] : [id]);
    }
  };

  return (
    <div className="chat-lits-wrapper">
      {isBulkUpdateOpen && (
        <div className="d-flex align-items-center justify-content-between gap-1 my-3 ps-4 pe-3">
          <Checkbox
            checked={
              selectedInboxIds.length > 0 &&
              selectedInboxIds.length === conversations.length
            }
            indeterminate={
              selectedInboxIds.length > 0 &&
              selectedInboxIds.length < conversations.length
            }
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedInboxIds(
                  conversations.map((item) => getValue(item, "id", ""))
                );
              } else {
                setSelectedInboxIds([]);
              }
            }}
          >
            Select All{" "}
            <Badge
              count={getValue(selectedInboxIds, `length`, 0)}
              showZero
              color="#1677ff"
              className="ms-1"
            />
          </Checkbox>
          <div className="d-flex align-items-center gap-1">
            {isBulkUpdateOpen && (
              <Button
                color="danger"
                variant="filled"
                icon={<X size={16} />}
                size="small"
                onClick={handleCancelBulkSelection}
              >
                Cancel
              </Button>
            )}
            <Button
              size="small"
              color="primary"
              variant="filled"
              onClick={toggleOpenBulkUpdateModal}
              icon={<Merge size={16} />}
              disabled={selectedInboxIds.length === 0}
            >
              Update
            </Button>
          </div>
        </div>
      )}
      {isLoading ? (
        <Loader small />
      ) : getValue(conversations, `length`, 0) > 0 ? (
        conversations.map((item, j) => {
          return (
            <div
              key={j}
              className={`chat-list cursor-pointer ${
                selectedConversation === getValue(item, `id`, "")
                  ? "active"
                  : ""
              }`}
              onClick={() => {
                if (isBulkUpdateOpen) {
                  handleSelectInbox(getValue(item, `id`, ""));
                } else {
                  if (selectedConversation !== getValue(item, `id`, "")) {
                    handleRemovePipelineInfo();
                    handleSelectConvesation(item);
                  }
                }
              }}
            >
              {isBulkUpdateOpen && (
                <Checkbox
                  checked={selectedInboxIds.includes(getValue(item, `id`, ""))}
                  onChange={() => handleSelectInbox(getValue(item, `id`, ""))}
                />
              )}
              <div className="nonselected-profile-image">
                {getValue(item, `contact.first_name`, "") ||
                getValue(item, `contact.last_name`, "") ||
                // getValue(item, `source`, "") === "webchat" &&
                getValue(item, `web_name`, "") ? (
                  <ProfileCircle
                    value={
                      getValue(item, `contact.first_name`, "") ||
                      (getValue(item, `source`, "") === "webchat"
                        ? getValue(item, `web_name`, "")
                        : "")
                    }
                    value2={getValue(item, `contact.last_name`, "")}
                  />
                ) : getValue(item, `wa_profile_name`, "") &&
                  !parseInt(getValue(item, `wa_profile_name`, "")) ? (
                  <ProfileCircle
                    value={getValue(item, `wa_profile_name`, "")}
                  />
                ) : (
                  <AccountCircleSvgComponent color={"#dfe5e7"} size={40} />
                )}
              </div>

              <div className="w-100 d-flex flex-column gap-1">
                <div className="d-flex justify-content-between align-items-center">
                  {getValue(item, `contact.first_name`, "") ||
                  getValue(item, `contact.last_name`, "") ||
                  getValue(item, `web_name`, "") ? (
                    // &&getValue(item, `source`, "") === "webchat"
                    <p className="small_text__16">
                      {getValue(item, `web_name`, "")
                        ? concatString(
                            formatString(
                              getValue(item, `web_name`, "")
                                ? getValue(item, `web_name`, "")
                                : ""
                            ),
                            18
                          )
                        : concatString(
                            formatString(
                              getValue(item, `contact.first_name`, "")
                                ? getValue(item, `contact.first_name`, "")
                                : ""
                            ),
                            8
                          ) +
                          " " +
                          concatString(
                            formatString(
                              getValue(item, `contact.last_name`, "")
                                ? getValue(item, `contact.last_name`, "")
                                : ""
                            ),
                            8
                          )}
                    </p>
                  ) : (
                    <p className="small_text__16">
                      {concatStringWithPhoneSupport(
                        formatString(getValue(item, `wa_profile_name`, "")),
                        26
                      )}
                    </p>
                  )}
                  <div className="d-flex align-items-center">
                    {getValue(item, `source`, "") === "webchat" ? (
                      <GlobeSvgComponent size={15} color={"#408dfb"} />
                    ) : (
                      <WhatsappSvgComponent size={15} color={"#58d68d"} />
                    )}
                    <span className="time-noti ms-1">
                      {formatDatetoDay(getValue(item, `updated_at`, ""))}
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p>
                    {getValue(item, `last_msg.text`, "") ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: replaceDefaultPlaceholdersBySingleLineWithoutHyperlink(
                            concatString(
                              getValue(item, `last_msg.text`, "")
                                ? getValue(item, `last_msg.text`, "")
                                : "",
                              30
                            )
                          ),
                        }}
                        className="desc_text small_text__12"
                      ></p>
                    ) : getValue(item, `last_msg.type`, "") === "image" ? (
                      <div className="d-flex align-items-center">
                        <CameraSvgComponent size={18} color={"gray"} />
                        <p className="small_text__12 ms-1">
                          {getValue(item, `last_msg.media_caption`, "")
                            ? concatString(
                                formatString(
                                  getValue(item, `last_msg.media_caption`, "")
                                ),
                                30
                              )
                            : "Image"}
                        </p>
                      </div>
                    ) : getValue(item, `last_msg.type`, "") === "audio" ? (
                      <div className="d-flex align-items-center">
                        <MicSvgComponent size={18} color={"gray"} />
                        <p className="small_text__12 ms-1">
                          {getValue(item, `last_msg.media_caption`, "")
                            ? concatString(
                                formatString(
                                  getValue(item, `last_msg.media_caption`, "")
                                ),
                                30
                              )
                            : "Audio"}
                        </p>
                      </div>
                    ) : getValue(item, `last_msg.type`, "") === "video" ? (
                      <div className="d-flex align-items-center">
                        <VideoSvgComponent size={18} color={"gray"} />
                        <p className="small_text__12 ms-1">
                          {getValue(item, `last_msg.media_caption`, "")
                            ? concatString(
                                formatString(
                                  getValue(item, `last_msg.media_caption`, "")
                                ),
                                30
                              )
                            : "Video"}
                        </p>
                      </div>
                    ) : getValue(item, `last_msg.type`, "") === "document" ? (
                      <div className="d-flex align-items-center">
                        <DocumentSvgComponent size={18} color={"gray"} />
                        <p className="small_text__12 ms-1">
                          {getValue(item, `last_msg.media_caption`, "")
                            ? concatString(
                                formatString(
                                  getValue(item, `last_msg.media_caption`, "")
                                ),
                                30
                              )
                            : "Document"}
                        </p>
                      </div>
                    ) : getValue(item, `last_msg.type`, "") === "location" ? (
                      <div className="d-flex align-items-center">
                        <MapPin size={16} color={"gray"} />
                        <p className="small_text__12 ms-1">Location</p>
                      </div>
                    ) : getValue(item, `last_msg.type`, "") === "contacts" ? (
                      <div className="d-flex align-items-center">
                        <UserPlus size={16} color={"gray"} />
                        <p className="small_text__12 ms-1">Contact</p>
                      </div>
                    ) : getValue(item, `last_msg.type`, "") === "sticker" ? (
                      <div className="d-flex align-items-center">
                        <Sticker size={16} color={"gray"} />
                        <p className="small_text__12 ms-1">Sticker</p>
                      </div>
                    ) : getValue(item, `last_msg.type`, "") ===
                      "unsupported" ? (
                      <div className="d-flex align-items-center">
                        <InfoCircleFilled
                          size={14}
                          color={"gray"}
                          style={{
                            color: "gray",
                          }}
                        />
                        <p className="small_text__12 ms-1">
                          Unsupported message: Possibly an event or poll
                        </p>
                      </div>
                    ) : (
                      <p className="desc_text small_text__12">
                        {getValue(item, `source`, "") === "webchat"
                          ? ""
                          : "Hey there! I am using WhatsApp"}
                      </p>
                    )}
                  </p>
                  <div className="d-flex align-items-center">
                    {getValue(item, `unread_count`, 0) > 0 && (
                      <Badge
                        color="#25d366"
                        count={getValue(item, `unread_count`, 0)}
                      />
                    )}
                    {(getValue(item, `org_user.first_name`, "") ||
                      getValue(item, `org_user.last_name`, "")) && (
                      <div className="ms-1">
                        <Tooltip
                          title={`${getValue(
                            item,
                            `org_user.first_name`,
                            ""
                          )}${" "}${getValue(item, `org_user.last_name`, "")}`}
                        >
                          <Badge
                            color={getColorCode(
                              getOnlyFirstLetterOfWord(
                                getValue(item, `org_user.first_name`, "")
                                  ? getValue(item, `org_user.first_name`, "")
                                  : getValue(item, `org_user.last_name`, "")
                              )
                            )}
                            count={`${getFirstLetterOfWord(
                              getValue(item, `org_user.first_name`, "")
                            )}${getFirstLetterOfWord(
                              getValue(item, `org_user.last_name`, "")
                            )}`}
                          />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <Empty image={null} description=" No Conversations Found" />
      )}
    </div>
  );
}

export default InboxSearchContacts;
