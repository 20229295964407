import { DeleteOutlined } from "@ant-design/icons";
import { handleBooleanChange } from "@common/handleChange";
import { handleNumberChange, handleTextChange } from "@common/handleChange";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import InputRuleForm from "@components/InputRuleForm/form";
import Loader from "@components/common/Loader/loading";
import { deleteAIChatbot } from "@services/AIBot/settings.service";
import {
  deleteChatbot,
  deleteChatbotConversations,
} from "@services/chatbot.service";
import { getValue } from "@utils/lodash";
import { Button, Switch } from "antd";
import { Trash2 } from "lucide-react";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

function SettingsGeneral(props: any) {
  const { request, setRequest, isLoading } = props;
  const params = useParams();
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                              Delete Section                                */
  /* -------------------------------------------------------------------------- */
  const [deleteId, setDeleteId] = useState("");
  const [deleteValue, setDeleteValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleDeleteView = (id: string) => {
    setDeleteId(id);
    handleModal();
  };

  const handleDelete = async () => {
    try {
      let resp = await deleteChatbot(deleteId);
      if (resp) {
        toast.success("Deleted Successfully");
        handleModal();
        setDeleteId("");
        navigate(`/${getValue(params, `orgId`, "")}/chatbot`);
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                     Delete Conversation Section                            */
  /* -------------------------------------------------------------------------- */
  const [deleteId1, setDeleteId1] = useState("");
  const [deleteValue1, setDeleteValue1] = useState("");
  const [isOpen1, setIsOpen1] = useState(false);

  const handleModal1 = () => {
    setIsOpen1(!isOpen1);
  };

  const handleDeleteView1 = (id: string) => {
    setDeleteId1(id);
    handleModal1();
  };

  const handleDelete1 = async () => {
    try {
      let resp = await deleteChatbotConversations(deleteId1);
      if (resp) {
        toast.success("Conversations deleted successfully");
        handleModal1();
        setDeleteId("");
        navigate(
          `/${getValue(params, `orgId`, "")}/chatbot/${getValue(
            params,
            `chatbotId`,
            ""
          )}/ai/detail/settings`
        );
      }
    } catch (error) {}
  };
  return isLoading ? (
    <Loader />
  ) : (
    <div className="w-80">
      <div className="border rounded-3 p-4 ">
        <h6 className="header_text__18">General</h6>

        <div className="my-3">
          <label className="form-label">Chatbot ID</label>
          <h6 className="header_text__18">
            {getValue(params, `chatbotId`, "")}
          </h6>
        </div>
        {/* <div className="my-3">
          <label className="form-label">Number of characters</label>
          <h6 className="header_text__18">
            {getValue(request, `num_of_characters`, 0)}
          </h6>
        </div> */}
        <InputRuleForm
          inputType="TEXT"
          name="name"
          value={getValue(request, "name", "")}
          placeholder="Enter a Name"
          required
          label="Name"
          onChange={(e: any) => handleTextChange(e, request, setRequest)}
          validLeft
        />
        {/* <div className="my-3">
          <InputRuleForm
            inputType="NUMBER"
            name="credits_limit"
            value={getValue(request, "credits_limit", "")}
            placeholder="enable to add limit"
            required
            label="Credit Limit"
            onChange={(e: any) => handleNumberChange(e, request, setRequest)}
            validLeft
          />
        </div>
        <div className="d-flex align-items-center gap-2">
          <Switch
            size="small"
            value={getValue(request, "enable_credits_limit", false)}
            onChange={(e: any) =>
              handleBooleanChange(
                "enable_credits_limit",
                !getValue(request, "enable_credits_limit", false),
                request,
                setRequest
              )
            }
          />
          <p className="small_text__14">
            Maximum credits to be used by this chatbot from the credits
            available on the team.
          </p>
        </div> */}
        {/* 
      <div className="mt-5">
        <h6>Delete All Conversations</h6>
        <p>
          Once you delete all your conversations, there is no going back. Please
          be certain. All the conversations on this chatbot will be deleted.
          This action is not reversible
        </p>
        <Button>Delete</Button>
      </div> */}
      </div>
      <div className="mt-4 d-flex align-items-center gap-4 justify-content-between border p-4 border-radius__5">
        <div>
          <h6 className="header_text__16 text-danger">
            Delete All Conversations
          </h6>
          <p className="small_text__14 text-gray mt-2">
            Once you delete your chatbot, there is no going back. Please be
            certain. All your uploaded data will be deleted. This action is not
            reversible
          </p>
        </div>
        <Button
          danger
          size="large"
          type="primary"
          icon={<Trash2 size={16} />}
          className=""
          onClick={() => handleDeleteView1(getValue(params, `chatbotId`, ""))}
        >
          Delete
        </Button>
      </div>
      <div className="mt-4 d-flex align-items-center gap-4 justify-content-between border p-4 border-radius__5">
        <div>
          <h6 className="header_text__16 text-danger">Delete Chatbot</h6>
          <p className="small_text__14 text-gray mt-2">
            Once you delete your chatbot, there is no going back. Please be
            certain. All your uploaded data will be deleted. This action is not
            reversible
          </p>
        </div>
        <Button
          danger
          size="large"
          type="primary"
          icon={<Trash2 size={16} />}
          className=""
          onClick={() => handleDeleteView(getValue(params, `chatbotId`, ""))}
        >
          Delete
        </Button>
      </div>

      <DeleteModal
        isOpen={isOpen}
        handleModal={handleModal}
        handleSubmit={handleDelete}
        deleteValue={deleteValue}
      />
      <DeleteModal
        isOpen={isOpen1}
        handleModal={handleModal1}
        handleSubmit={handleDelete1}
        deleteValue={"All Conversations"}
      />
    </div>
  );
}

export default SettingsGeneral;
