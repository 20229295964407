import React from "react";
import "./index.scss";
import { useEffect, useState } from "react";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import { useNavigate, useParams } from "react-router-dom";
import { getAllWebForms } from "@services/web-forms.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import FormCreatorTable from "./components/form-creator-table";
import FormCreatorInfoPopup from "./components/form-creator-info-popup";
import { updateWebForm } from "@services/web-forms.service";
import { toast } from "sonner";
import useDynamicTitle from "@context/useDynamicTitle";

import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import { Plus } from "lucide-react";

function SettingsFormCreator(props: any) {
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    navigate(`/${getValue(params, `orgId`, "")}/settings/crm/forms/create`);
  };
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getData();
    }
  }, []);
  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      if (UrlParams.page_size) {
        setLimit(parseInt(UrlParams.page_size));
      }
      getData();
    }
  }, [window.location.href]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [formList, setFormList] = useState([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        page_size: getValue(UrlParams, `limit`, "")
          ? getValue(UrlParams, `limit`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
        search: getValue(UrlParams, `search`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllWebForms(queryRequest);
      if (resp) {
        setFormList(getValue(resp, `data.forms`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setPage_no(getValue(resp, `data.pagination.page`, 1));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleChangeStatus = async (id: string, status: boolean) => {
    try {
      let resp = await updateWebForm(id, { is_active: status });
      if (resp) {
        getData();
        toast.success("Updated successfully");
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  useDynamicTitle("Forms");

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: any, limit: any) => {
    setPage_no(page_no);
    setLimit(limit);

    let payload = {
      ...UrlParams,
      page_no: page_no,
      page_size: limit,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    setPage_no(page_no);
  };
  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        title="Web Forms"
        icon="forms"
        submitIcon={<Plus size={20} />}
        addForm
        toggle={toggle}
      />
      <div className="manageFieldRightSettings">
        <FormCreatorTable
          list={formList}
          isLoading={isLoading}
          handleChangeStatus={handleChangeStatus}
          page_no={page_no}
          limit={limit}
          totalCount={totalCount}
          handleChangePagination={handleChangePagination}
        />
      </div>
      <FormCreatorInfoPopup isOpen={isOpen} toggle={toggle} />
    </CompanyProfileDetailsLayout>
  );
}

export default SettingsFormCreator;
