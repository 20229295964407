import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import { useEffect, useRef, useState } from "react";
import InputRuleForm from "@components/InputRuleForm/form";
import { handleTextChange } from "@common/handleChange";
import FormDescription from "@components/custom/FormDescrption/FormDescription";
import SLATargetTable from "./components/SLATargetTable";
import SLATicketCondition from "./components/SLATicketCondition";
import SLADueTime from "./components/SLADueTime";
import { toast } from "sonner";
import {
  createSlaPolicy,
  getSlaPolicyConditionFields,
  getSpecificSlaPolicy,
  updateSlaPolicy,
} from "@services/sla-policy.services";
import { useStateContext } from "@context/dataContext";
import SimpleReactValidator from "simple-react-validator";
import { getPrioritiesList } from "@services/priorities.service";
import { QueryRequestHelper } from "@common/query-request-helper";

const CreateSLAPolicy = ({ props }: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const [editId, seteditId] = useState("");
  const [isDefault, setIsDefault] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [request, setRequest] = useState({
    name: "",
    description: "",
    sla_conditions: [
      {
        field_name: "",
        value: [],
      },
    ],
    is_default: false,
  });

  const [slaTarget, setSlaTarget] = useState([]);
  const [slaEscalation, setSlaEscalation] = useState<any>({});

  const [conditionList, setConditionList] = useState([]);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  /* -------------------------------------------------------------------------- */
  /*                            useEffect Section                               */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (params) {
      seteditId(getValue(params, "id", ""));
    }
  }, [params]);

  useEffect(() => {
    if (editId) {
      getSpecificPolicy(true);
    } else {
      handleGetPriorityList(true);
    }
  }, [editId]);

  useEffect(() => {
    handleGetConditionList(true);
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const handleSubmit = async () => {
    // const formValid = simpleValidator.current.allValid();
    // if (!formValid) {
    //   // toast.error(`Please fill all the mandatory fields`);
    //   simpleValidator.current.showMessages();
    //   forceUpdate(1);
    // } else {
      setIsLoading(true);
      try {
        const combinedData = {
          ...request,
          sla_priorities: slaTarget,
          escalation: { ...slaEscalation },
        };

        let payload = JSON.parse(JSON.stringify(combinedData));
        const resp = editId
          ? await updateSlaPolicy(editId, payload)
          : await createSlaPolicy(payload);

        if (resp) {
          toast.success(`${editId ? "Updated" : "Created"} Successfully`);

          setRequest({
            name: "",
            description: "",
            sla_conditions: [
              {
                field_name: "",
                value: [],
              },
            ],
            is_default: false,
          });
          setSlaEscalation({});
          navigate(
            `/${getValue(params, "orgId", "")}/settings/helpdesk/sla-policy`
          );
          setIsLoading(false);
        } else {
          toast.error(
            `Failed to ${editId ? "editing" : "creating"} SLA Policy`
          );
        }
      } catch (error) {
        toast.error(`Error ${editId ? "editing" : "creating"} SLA Policy`);
      } finally {
        setIsLoading(false);
      }
    // }
  };

  const handleGetConditionList = async (status: boolean) => {
    try {
      setIsLoading1(status);
      const resp = await getSlaPolicyConditionFields();
      if (resp) {
        const conditions = getValue(resp, "data", []).map((item: any) => ({
          ...item,
          value: getValue(item, "fieldName", ""),
          label: getValue(item, "fieldLabel", ""),
        }));
        setConditionList(conditions);
      }
    } catch (error) {
      toast.error("Failed to load conditions.");
    } finally {
      setIsLoading1(false);
    }
  };

  const handleGetPriorityList = async (status: boolean) => {
    try {
      setIsLoading3(status);
      const payload = {
        page_no: 1,
        page_size: 10,
      };
      let queryRequest = QueryRequestHelper(payload);
      const resp = await getPrioritiesList(queryRequest);
      if (resp) {
        const modifiedResp = getValue(resp, "data", []).map((item: any) => ({
          priority_id: getValue(item, "id", ""),
          priority_name: getValue(item, "name", ""),
          first_response_time: "PT0H15M",
          resolution_due_time: "PT0H15M",
          every_response_time: "PT0M",
          business_hours: true,
          escalation_enabled: true,
        }));
        setSlaTarget(modifiedResp);

        setIsLoading3(false);
      }
    } catch (error) {
      toast.error("Failed to load conditions.");
      setIsLoading3(false);
    } finally {
      setIsLoading3(false);
    }
  };

  const getSpecificPolicy = async (status: boolean) => {
    try {
      setIsLoading2(status);
      const resp = await getSpecificSlaPolicy(editId);

      if (resp) {
        setRequest({
          name: getValue(resp, "data.name", ""),
          description: getValue(resp, "data.description", ""),
          sla_conditions: getValue(resp, "data.sla_conditions", []),
          is_default: getValue(resp, "data.is_default", false),
        });
        setSlaTarget(getValue(resp, "data.sla_priorities", ""));
        setSlaEscalation({
          reminder_response: getValue(resp, "data.reminder_response", null),
          reminder_resolution: getValue(resp, "data.reminder_resolution", null),
          reminder_next_response: getValue(
            resp,
            "data.reminder_next_response",
            null
          ),
          response: getValue(resp, "data.response", null),
          resolution_level_1: getValue(resp, "data.resolution_level_1", null),
          next_response: getValue(resp, "data.next_response", null),
        });
        setIsDefault(getValue(resp, "data.is_default", false));
      }
    } catch (error) {
      toast.error("Failed to get Policy");
    } finally {
      setIsLoading2(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Other Section                              */
  /* -------------------------------------------------------------------------- */

  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        backButton
        // titleLoading={isLoading1}
        title={`${editId ? "Edit" : "New"} SLA Policy`}
        showSubmit
        submitText={`${editId ? "Update" : "Create"} Policy`}
        isLoading={isLoading}
        handleSubmit={() => handleSubmit()}
        showCancel
        handleCancel={() =>
          navigate(
            `/${getValue(params, "orgId", "")}/settings/helpdesk/sla-policy`
          )
        }
      />
      <div className="manageFieldRightSettings overflow-y-auto p-4">
        <div className="w-50">
          <InputRuleForm
            inputType="TEXT"
            name="name"
            value={getValue(request, "name", "")}
            placeholder="Enter Name"
            required
            label="Name (For Own Reference)"
            onChange={(e: any) => handleTextChange(e, request, setRequest)}
            validator={simpleValidator}
            validLeft
          />
        </div>
        <FormDescription
          request={request}
          textClassname="mb-2 mt-3 w-50"
          inputClassname="w-50 my-2"
          input={
            <InputRuleForm
              inputType="TEXTAREA"
              name="description"
              value={getValue(request, `description`, "")}
              placeholder="Enter Description"
              label="Description"
              onChange={(e: any) => handleTextChange(e, request, setRequest)}
            />
          }
        />
        {/* <div className="w-50">
          <label className="form-label">Pipeline</label>
          <Select
            onChange={(value) => {
              setTicketsPipelineId(value);
            }}
            value={ticketsPipelineId}
            placeholder="Choose Condition"
            options={allPipelines}
            className="w-100"
            showSearch
            allowClear
            size="large"
          />
        </div> */}
        {!isDefault && (
          <SLATicketCondition
            request={request}
            setRequest={setRequest}
            editId={editId}
            isLoading1={isLoading1}
            conditionList={conditionList}
            validator={simpleValidator}
          />
        )}
        <SLATargetTable request={slaTarget} setRequest={setSlaTarget} />
        <SLADueTime
          mainRequest={request}
          priorityRequest={slaTarget}
          request={slaEscalation}
          setRequest={setSlaEscalation}
          editId={editId}
        />
      </div>
    </CompanyProfileDetailsLayout>
  );
};

export default CreateSLAPolicy;
