import { QueryRequestHelper } from "@common/query-request-helper";
import Loader from "@components/common/Loader/loading";
import { getRazorpayPayment } from "@services/payment-integrations.service";
import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import {
  Table,
  Tag,
  Typography,
  Input,
  Row,
  Col,
  Pagination,
  Button,
  Select,
  notification,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { SearchOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import PaymentLinkForm from "./components/create-payment-link";
import { formatString } from "@common/text-helpers";
import Pusher from "pusher-js";
import { config } from "../../../../../../env";

interface PaymentItem {
  payment_id: string;
  customer_name: string;
  customer_phone: string;
  method: string;
  currency: string;
  razorpay_amount: string | number;
  amount: string | number;
  captured: boolean;
  status: string;
  short_url?: string;
}

const { Option } = Select;

const selectBefore = (
  <Select defaultValue="http://">
    <Option value="http://">₹</Option>
    <Option value="https://">$</Option>
  </Select>
);

function DealPaymentHistory(props: any) {
  console.log(props, "props");
  const [paymentList, setPaymentList] = useState<PaymentItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const params = useParams();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getData(true);
  }, [currentPage, pageSize, searchQuery, props.paymentListTrigger]);

  useEffect(() => {
    if (props.deal_id) {
      getData(true);
    }
  }, [props.deal_id]);


  const getData = async (status: boolean) => {
    try {
      setIsLoading(status);
      const payload = {
        search: searchQuery,
        page_no: currentPage,
        page_size: pageSize,
        deal_id: getValue(props, `deal_id`, "")
          ? getValue(props, `deal_id`, "")
          : getValue(params, `id`, ""),
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getRazorpayPayment(queryRequest);
      if (resp) {
        setIsLoading(false);
        setPaymentList(getValue(resp, `data.razorpay`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        if (props.setTriggerPaymentList) {
          props.setTriggerPaymentList(false);
        }
        // if (props.getSpecificContactInfo) {
        //   props.getSpecificContactInfo(getValue(props, `contactInfo.id`, ""));
        // }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching payment data:", error);
    }
  };
  // Initialize Pusher connection and subscribe to channel
  useEffect(() => {
    const dealId = getValue(props, `deal_id`, "") || getValue(params, `id`, "");
    if (!dealId) return;

    // Initialize Pusher
    const pusher = new Pusher(config.PUSHER_APP_KEY, {
      cluster: config.PUSHER_APP_CLUSTER,
    });
    console.log(pusher, "pusher");
    // Subscribe to channel with deal ID
    const channel = pusher.subscribe(dealId);
    console.log(channel, "channel");
    // Bind to the razorpay payment notification event
    channel.bind("razorpay-payment-notification", (data: any) => {
      console.log(data, "data");
      notification.success({
        message: "Payment Update",
        description: `New payment ${data.status || "update"} received from ${
          data.customer_name || "customer"
        }`,
      });
      getData(false); // Show notification to user

      // // Update payment list with the new data
      // setPaymentList((prevList: any[]) => {
      //   // Find the index of the existing payment using a unique identifier
      //   const existingIndex = prevList.findIndex(
      //     (payment) =>
      //       payment.id === data.id ||
      //       payment.payment_id === data.payment_id ||
      //       payment.transaction_id === data.transaction_id
      //   );
      //   if (existingIndex >= 0) {
      //     // If payment exists, create a new array with updated payment
      //     const updatedList = [...prevList];
      //     updatedList[existingIndex] = {
      //       ...updatedList[existingIndex],
      //       ...data,
      //       // Ensure critical fields are updated
      //       status: data.status || updatedList[existingIndex].status,
      //       amount: data.amount || updatedList[existingIndex].amount,
      //     };
      //     return updatedList;
      //   }
      //   // If it's a new payment, add it to the top of the list
      //   return [data, ...prevList];
      // });
    });

    // Clean up subscription when component unmounts
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
      pusher.disconnect();
    };
  }, [props.deal_id, params.id]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchQuery(value);
    setCurrentPage(1); // Reset to first page when searching
  };

  const handlePageChange = (page: number, size: number) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const columns: ColumnsType<PaymentItem> = [
    {
      title: "PAYMENT URL",
      dataIndex: "short_url",
      key: "short_url",
      render: (text) => <Typography.Text copyable>{text}</Typography.Text>,
    },
    {
      title: "CUSTOMER ",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      title: "PHONE",
      dataIndex: "customer_phone",
      key: "customer_phone",
    },
    {
      title: "METHOD",
      dataIndex: "payment_method",
      key: "payment_method",
      render: (payment_method) => <span>{formatString(payment_method)}</span>,
    },
    {
      title: "AMOUNT",
      key: "amount",
      render: (_, record) => (
        <span>
          {record.currency} {record.amount}
        </span>
      ),
    },
    {
      title: "CAPTURE STATUS",
      key: "captured",
      render: (_, record) => (
        <Tag color={record.captured ? "green" : "red"}>
          {record.captured ? "Captured" : "Not Captured"}
        </Tag>
      ),
    },
    {
      title: "PAYMENT STATUS",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color = "blue";
        if (status === "successful" || status === "completed") color = "green";
        if (status === "failed") color = "red";
        if (status === "pending") color = "orange";
        return <Tag color={color}>{formatString(status)}</Tag>;
      },
    },
  ];
  return (
    <div className="product-management-antd">
      <>
        <Row
          gutter={[16, 16]}
          style={{ marginBottom: "16px", justifyContent: "space-between" }}
        >
          <Col xs={24} sm={12} md={8} lg={12}>
            <Input
              placeholder="Search payments..."
              prefix={<SearchOutlined />}
              value={searchQuery}
              onChange={handleSearch}
              allowClear
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={3} className="mx-4">
            <Button type="primary" onClick={showModal}>
              Add Payment
            </Button>
          </Col>
        </Row>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Table
              columns={columns}
              dataSource={paymentList}
              rowKey={(record) => record.payment_id}
              pagination={false}
              bordered
              scroll={{ x: "max-content" }}
            />
          </>
        )}
        <Row justify="end" style={{ marginTop: "16px" }}>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalCount}
            showSizeChanger
            showQuickJumper
            showTotal={(total) => `Total ${total} items`}
            onChange={handlePageChange}
            onShowSizeChange={handlePageChange}
          />
        </Row>
      </>
      <PaymentLinkForm
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        selectBefore={selectBefore}
        data={props.data}
        getData={getData}
        fields={props.fields}
        deal_id={props.deal_id}
      />
    </div>
  );
}

export default DealPaymentHistory;
