import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";
import "./home.scss";
import {
  BookMarked,
  BookOpenCheck,
  CalendarDays,
  CirclePlay,
  ExternalLink,
  FileText,
  Play,
} from "lucide-react";
import { usePermissionContext } from "@context/permissionContext";
import { useNavigate, useParams } from "react-router-dom";
import { useStateContext } from "@context/dataContext";
import GiftAnimation from "@components/common/Loader/gift";
import { Button, Modal, Tag } from "antd";
import ConnectPages from "./components/ConnectPages";
import { WhatsAppOutlined } from "@ant-design/icons";
import Iframe from "react-iframe";
import OrgIntro from "./components/OrgIntro";
import { capitalizeFirstLetter } from "@common/text-helpers";
import SidebarLayout from "@layouts/SidebarLayout/SidebarLayout";
import useDynamicTitle from "@context/useDynamicTitle";

const gridItems = [
  {
    header: "Book Free Consultation",
    description:
      "Talk to our product experts and understand how Appzo can help!",
    subHeader: "Mon - Fri | 9:30 AM to 6:00 PM",
    icon: <CalendarDays size={16} />,
    footerText: "Schedule",
    button: "schedule",
  },
  {
    header: "Watch Our Product Demo Video",
    description:
      "Dive into Appzo and discover its powerful features! Learn how to leverage the platform for your business",
    icon: <CirclePlay size={16} />,
    footerText: "Watch video",
    link: "https://appzo.gitbook.io/appzo",
  },
  {
    header: "Documentation",
    description:
      "Explore Appzo and its features, learn how to use it for your business, access API docs, and more.",
    icon: <BookMarked size={16} />,
    footerText: "Appzo docs",
    link: "https://appzo.gitbook.io/appzo",
  },
];

const caseStudies = [
  {
    image: "/home/blog-3.webp",
    tag: "WHATSAPP",
    color: "green",
    heading: "Guide on Obtaining WhatsApp Green Badge",
    link: "https://www.appzo.ai/blog/guide-on-obtaining-whatsApp-green-badge",
  },
  {
    image: "/home/social.webp",
    tag: "WHATSAPP",
    color: "green",
    heading: "Pricing and WhatsApp calculation",
    link: "https://www.appzo.ai/blog/pricing-and-whatsapp-calculation",
  },
  {
    image: "/home/blog-2.webp",
    tag: "WHATSAPP",
    color: "green",
    heading: "Message limits and Quality rating",
    link: "https://www.appzo.ai/blog/message-limits-and-quality-rating",
  },
];

const videoResources = [
  {
    image: "/property/1.jpg",
    title: "How to create a bot",
  },
  {
    image: "/property/2.webp",
    title: "How to broadcast on WhatsApp",
  },
  {
    image: "/property/3.webp",
    title: "How to do workflow automation",
  },
];

const knowledgeHub = [
  {
    heading: "Bots & Automation:",
    links: [
      {
        text: "What are the basics of building a Chatbot?",
        link: "https://appzo.gitbook.io/appzo",
      },
      {
        text: "How do I create, test, and deploy a chatbot?",
        link: "https://appzo.gitbook.io/appzo",
      },
      {
        text: "Can I use Appzo to build my own WhatsApp shop?",
        link: "https://appzo.gitbook.io/appzo",
      },
    ],
  },
  {
    heading: "Broadcasts & Sequences:",
    links: [
      {
        text: "Can I send a broadcast message to 1 lakh contacts in excel. ",
        link: "https://appzo.gitbook.io/appzo",
      },
      {
        text: "How can I schedule a message to a specific segment of my contacts? ",
        link: "https://appzo.gitbook.io/appzo",
      },
      {
        text: "How do I create a drip marketing campaign using sequences?",
        link: "https://appzo.gitbook.io/appzo",
      },
    ],
  },
  {
    heading: "Pricing & Setup:",
    links: [
      {
        text: "Do you offer a free trial of Appzo?",
        link: "https://appzo.gitbook.io/appzo",
      },
      {
        text: "What requirements are needed to connect my business number and send messages from it?",
        link: "https://appzo.gitbook.io/appzo",
      },
      {
        text: "What are your pricing plans?",
        link: "https://appzo.gitbook.io/appzo",
      },
    ],
  },
];

const HomeSetup = () => {
  useDynamicTitle("Home");
  const params = useParams();
  const navigate = useNavigate();
  const { orgInfo, profileInfo, refreshOrgInfo } = useStateContext();
  const { tabPermissionList, subTabPermissionList } = usePermissionContext();
  const [openSchedule, setOpenSchedule] = useState(false);
  const [openIntro, setOpenIntro] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getTimeOfDayWithEmoji = () => {
    const hour = new Date().getHours();

    if (hour >= 5 && hour < 12) {
      return "⛅ Good Morning";
    } else if (hour >= 12 && hour < 17) {
      return "🌞 Good Afternoon";
    } else if (hour >= 17 && hour < 21) {
      return "🌇 Good Evening";
    } else {
      return "🌙 Good Night";
    }
  };

  useEffect(() => {
    if (getValue(orgInfo, "is_intro", "") === false) {
      setOpenIntro(true);
    }
  }, [orgInfo]);

  return (
    <SidebarLayout>
      <div className="home-container-wrapper">
        <div className="custom-container my-5 align-items-baseline flex-column justify-content-start">
          <p className="small_text__22">
            <b>{getTimeOfDayWithEmoji()},</b>{" "}
            {capitalizeFirstLetter(getValue(profileInfo, "first_name", ""))}!
          </p>
          <div className="home-banner-card">
            <div className="d-flex align-items-center gap-3">
              <GiftAnimation />
              <div>
                <h6 className="text-white header_text__18">
                  Explore Appzo with a 15-Day Free Trial
                </h6>
                <p className="light-grayed-text">
                  Did you know? For the price of one product, you can access
                  <span className="dashed-underline">multiple products!</span>
                </p>
              </div>
            </div>
            {tabPermissionList.includes("settings") &&
              subTabPermissionList.includes("universal_inbox") && (
                <Button
                  size="large"
                  onClick={() =>
                    navigate(
                      `/${getValue(params, "orgId", "")}/settings/chat/messages`
                    )
                  }
                >
                  Connect your number
                </Button>
              )}
          </div>
          <div className="three-grid-container">
            {gridItems.map((item, index) => (
              <div key={index} className="home-grid-card">
                <div className="home-grid-header mb-1">
                  <h6 className="header_text__16">{item.header}</h6>
                  <p className="small_text__13">{item.description}</p>
                  {item.subHeader && (
                    <h6 className="header_text__14 mt-4">{item.subHeader}</h6>
                  )}
                </div>
                {item.link ? (
                  <a
                    className="home-grid-footer"
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.icon}
                    {item.footerText}
                  </a>
                ) : (
                  <div
                    className="home-grid-footer"
                    onClick={() => {
                      if (item.button === "schedule") {
                        setOpenSchedule(!openSchedule);
                      }
                    }}
                  >
                    {item.icon}
                    {item.footerText}
                  </div>
                )}
              </div>
            ))}
          </div>
          {tabPermissionList.includes("settings") && (
            <ConnectPages
              tabPermissionList={tabPermissionList}
              subTabPermissionList={subTabPermissionList}
            />
          )}
          <div className="my-5">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h6 className="header_text__18">Case Studies</h6>
              <a
                href="https://www.appzo.ai/blog"
                className="d-flex align-items-center gap-2 header_blue_text__14"
                target="__blank"
              >
                View All Case Studies
                <ExternalLink size={18} />
              </a>
            </div>
            <div className="three-grid-container gap-3">
              {caseStudies.map((item, index) => (
                <a
                  href={item.link}
                  key={index}
                  className="border rounded-3 p-3 shadow-sm"
                  target="__blank"
                >
                  <img
                    src={item.image}
                    height={160}
                    className="w-100 rounded-3 mb-3 object-fit-cover"
                    alt=""
                  />
                  <Tag color={item.color} className="my-2">
                    {item.tag}
                  </Tag>
                  <h6 className="header_text__16">{item.heading}</h6>
                </a>
              ))}
            </div>
          </div>
          {/* <div className="w-100">
            <div className="d-flex align-items-center justify-content-between mb-3 mt-4">
              <h6 className="header_text__18">Video Resources</h6>
              <p className="small_text__13">3 videos</p>{" "}
            </div>
            <div className="three-grid-container gap-3">
              {videoResources.map((item, index) => (
                <div
                  key={index}
                  className="border rounded-3 p-3 shadow-sm position-relative cursor-pointer"
                >
                  <img
                    src={item.image}
                    height={160}
                    className="object-cover w-100 rounded-3 "
                    alt=""
                  />
                  <h6 className="header_text__16 mt-2">{item.title}</h6>
                  <div className="video-play-button">
                    <Play color="#fff" />
                  </div>
                </div>
              ))}
            </div>
          </div> */}
          <div className="w-100 my-5">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h6 className="header_text__18">Appzo Knowledge Hub</h6>
              <p className="small_text__13">20 articles</p>{" "}
            </div>
            <div className="border rounded-3 p-4 shadow-sm bg-white">
              <div className="grid-container gap-4 ">
                {knowledgeHub.map((item, index) => (
                  <div key={index} className="d-flex flex-column">
                    <h6 className="header_text__16 mb-2">{item.heading}</h6>
                    {item.links.map((redirect, i) => (
                      <a
                        href={redirect.link}
                        key={i}
                        target="__blank"
                        className="d-flex align-items-center gap-2 py-1 redirect-link"
                      >
                        <FileText size={16} style={{ width: "16px" }} />
                        {redirect.text}
                      </a>
                    ))}
                  </div>
                ))}
              </div>
              <div className="mt-3 d-flex align-items-center justify-content-end gap-3">
                <Button>
                  <a
                    href="https://www.appzo.ai/contact-us"
                    className="d-flex align-items-center gap-2"
                    target="__blank"
                  >
                    <WhatsAppOutlined />
                    Chat with us
                    <ExternalLink size={16} />
                  </a>
                </Button>
                <Button>
                  <a
                    href="https://appzo.gitbook.io/appzo"
                    className="d-flex align-items-center gap-2"
                    target="__blank"
                  >
                    <BookOpenCheck size={16} />
                    More resources
                    <ExternalLink size={16} />
                  </a>
                </Button>
              </div>
            </div>
          </div>
          {/* <div className="w-100 border rounded-3 shadow-sm d-flex align-items-center justify-content-between gap-4 p-4">
              <div className="">
                <h6 className="header_text__18">API Documentation</h6>
                <p className="small_text__14">
                  Learn how to use the Appzo REST APIs to accomplish various
                  actions.
                </p>
              </div>

              <Button>
                API Docs
                <ExternalLink size={16} />
              </Button>
            </div> */}
        </div>
      </div>
      <Modal
        title="Schedule Appointment"
        open={openSchedule}
        onOk={() => setOpenSchedule(false)}
        onCancel={() => setOpenSchedule(false)}
        width={800}
        footer={null}
      >
        <Iframe
          url="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2ZoVtsMFBfLxZnNoG0sQI3_5S8rcWSH1ZqJyNcjj5OzvZ1nSOMBo4AhwffRl3kcMLOMe-4sRYV?gv=true"
          width="100%"
          height="460px"
          display="block"
        />
      </Modal>
      <OrgIntro
        open={openIntro}
        onOk={() => setOpenIntro(false)}
        onCancel={() => setOpenIntro(false)}
        isLoading={isLoading}
        orgInfo={orgInfo}
        params={params}
        profileInfo={profileInfo}
        setIsLoading={setIsLoading}
        navigate={navigate}
        setOpenIntro={setOpenIntro}
        refreshOrgInfo={refreshOrgInfo}
      />
    </SidebarLayout>
  );
};

export default HomeSetup;
