import Layout from "@layouts/Layout";
import React, { useEffect, useRef, useState } from "react";
import {
  createOrganisations,
  listOfOrganisations,
} from "@services/organisations.service";
import { getValue } from "@utils/lodash";
import { useNavigate } from "react-router-dom";
import { convertCurrentDate, getStartOfMonthDate } from "common/date-helpers";
import Nodata from "@components/common/NoData/NoData";
import { useStateContext } from "@context/dataContext";
import "./organisation.scss";
import {
  capitalizeFirstLetter,
  concatString,
  getOnlyFirstLetterOfWord,
} from "@common/text-helpers";
import TooltipOnHover from "@components/common/Tooltip/tooltip-on-hover";
import ListLoader from "@components/common/Loader";
import { getModernColorCode } from "@common/colors";
import { Avatar, Input, Modal } from "antd";
import { Plus, Search } from "lucide-react";
import { allParams } from "@common/url-params-helper";
import OrganisationModal from "./components/OrganisationModal";
import SimpleReactValidator from "simple-react-validator";
interface IOrganisationsProps {}

const Organisations: React.FunctionComponent<IOrganisationsProps> = (props) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  useEffect(() => {
    getOrganisations();
  }, []);
  const { userInfo, handleChangeOrgainization }: any = useStateContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [isLoading1, setIsLoading1] = useState(false);
  const [request, setRequest] = useState({
    name: "",
    currency: "",
    time_zone: "",
    date_format: "DD/MM/YYYY",
    country: "",
    phone_code: "",
    phone_no: "",
  });

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(true);
  const [organisations, setOrgList] = useState([]);
  const getOrganisations = async () => {
    try {
      setIsLoading(true);
      let resp = await listOfOrganisations("");
      if (resp) {
        if (getValue(resp, `data.length`, 0) === 0) {
          navigate(`/organisations/create?${allParams()}`);
        }
        setOrgList(getValue(resp, `data`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setIsLoading1(true);
        let resp = await createOrganisations(request);
        if (resp) {
          setIsModalOpen(false);
          if (getValue(UrlParams, `path`, "") === "shared") {
            window.location.href = `/${getValue(
              resp,
              `data.code`,
              ""
            )}/crm/leads?path=shared`;
          } else {
            window.location.href = `/${getValue(
              resp,
              `data.code`,
              ""
            )}/home?${allParams()}`;
          }
          setIsLoading1(false);
        } else {
          setIsLoading1(false);
        }
      } catch (error) {
        setIsLoading1(false);
      }
    }
  };

  const handleNavigate = (code: string) => {
    let date_range = getStartOfMonthDate();
    // localStorage.setItem("X-Org-Code", code);
    // navigate(`/${code}/contacts`);
    handleChangeOrgainization();
    // window.location.href = `/${code}/dashboard?date_range=${date_range}`;
    if (getValue(UrlParams, `path`, "") === "shared") {
      // window.location.href = `/BTS-2024-exhibitor-directory?orgId=${code}`;
      window.location.href = `/${code}/crm/leads?path=shared`;
    } else {
      window.location.href = `/${code}/home`;
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                             Use State Section                              */
  /* -------------------------------------------------------------------------- */

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    handleSubmit();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setRequest(() => ({
      name: "",
      currency: "",
      time_zone: "",
      date_format: "DD/MM/YYYY",
      country: "",
      phone_code: "",
      phone_no: "",
    }));
  };
  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearch, setShowSearch] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);

  const toggleSearch = () => {
    setShowSearch(!showSearch);
    if (showSearch === true) {
      setSearchInput("");
      setFilteredOrganizations(organisations);
    }
  };
  const handleSearch = (e: any) => {
    const query = e.target.value.toLowerCase();
    setSearchInput(query);
    const filteredData = organisations.filter((org: any) =>
      org.name.toLowerCase().includes(query)
    );
    setFilteredOrganizations(filteredData);
  };

  useEffect(() => {
    setFilteredOrganizations(organisations);
  }, [organisations]);

  return (
    <Layout>
      <div className={`d-flex w-100`}>
        <div className={"organisations-wrapper__main-section-wrapper"}>
          <div className={`organisations-wrapper__main-section`}>
            <div className={`organisations-create`}>
              <Input
                placeholder="Search organization"
                onChange={handleSearch}
                value={searchInput}
                style={{ width: 240 }}
                // size="large"
                className="search-org-input"
                prefix={<Search size={16} color="#717171" />}
              />
              <div
                className={`organisations-create_button`}
                onClick={() => showModal()}
              >
                <Plus size={20} /> Create New Organisation
              </div>
            </div>
            <div className={`separator`}></div>

            {getValue(organisations, `length`, 0) > 0 && (
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5 className={`headertext d-flex align-items-center`}>
                    Hi{" "}
                    <p className="text_blue__18 mx-2">
                      {getValue(userInfo, `first_name`, "")}{" "}
                      {getValue(userInfo, `last_name`, "")},{" "}
                    </p>
                  </h5>
                  <p className={"desctext"}>
                    You belong to the following organisations. Please select the
                    organisation you wish to access now
                  </p>
                </div>
              </div>
            )}
            {isLoading ? (
              <ListLoader />
            ) : (
              <>
                {getValue(organisations, `length`, 0) > 0 ? (
                  <div>
                    {filteredOrganizations.map(
                      (item: object, index: number) => {
                        return (
                          <div
                            className={`organisations-box_container row`}
                            key={index}
                          >
                            <div
                              className={`organisations-left_container col-md-7 align-items-center`}
                            >
                              {getValue(item, `logo.url`, "") ? (
                                <Avatar
                                  src={getValue(item, `logo.url`, "")}
                                  size={64}
                                />
                              ) : (
                                <Avatar
                                  style={{
                                    backgroundColor:
                                      getModernColorCode(
                                        getOnlyFirstLetterOfWord(
                                          getValue(item, `name`, "")
                                        )
                                      ) || "#fde3cf",
                                  }}
                                  size={64}
                                >
                                  <h6 className="header_text__22 text-white">
                                    {getOnlyFirstLetterOfWord(
                                      getValue(item, `name`, "")
                                    )}
                                  </h6>
                                </Avatar>
                              )}
                              <div
                                className={"organisations-left_text_container"}
                              >
                                <TooltipOnHover
                                  header={
                                    <p className={`organisations-title`}>
                                      {capitalizeFirstLetter(
                                        concatString(
                                          getValue(item, `name`, ""),
                                          30
                                        )
                                      )}
                                    </p>
                                  }
                                  text={getValue(item, `name`, "")}
                                />
                                <p className={`organisations-primary_title`}>
                                  Organisation ID : {getValue(item, `code`, "")}
                                </p>
                                <p className={`organisations-secondary_title`}>
                                  Organisation created on :{" "}
                                  {convertCurrentDate(
                                    getValue(item, `created_at`, "")
                                  )}
                                </p>
                              </div>
                            </div>
                            <div
                              className={`organisations-right_container col-md-5`}
                            >
                              <h6
                                className={`organisations-right_header_text`}
                                onClick={() =>
                                  handleNavigate(getValue(item, `code`, ""))
                                }
                              >
                                Go to this Organisation
                              </h6>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                ) : (
                  <div>
                    <Nodata />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        title="Create Organisation"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Create"
        okButtonProps={{
          loading: isLoading1,
        }}
      >
        <OrganisationModal
          request={request}
          setRequest={setRequest}
          simpleValidator={simpleValidator}
        />
      </Modal>
    </Layout>
  );
};

export default Organisations;
