import React from "react";
import { getValue } from "@utils/lodash";
import { useNavigate } from "react-router-dom";
import { QueryRequestHelper } from "@common/query-request-helper";
import useDynamicTitle from "@context/useDynamicTitle";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import WebMessageNavViews from "./components/nav-views";
import WebchatBotsPage from "./bot";

function WebchatMessages(props: any) {
  useDynamicTitle("Webchat");
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               Tab Section                                  */
  /* -------------------------------------------------------------------------- */
  const activeTab = getValue(UrlParams, `active_tab`, "1");
  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      delete UrlParams.search_text;
      let payload = {
        ...UrlParams,
        active_tab: tab,
        page_no: 1,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    }
  };
  useDynamicTitle("Whatsapp Config");
  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader settings icon="webchat" title="Webchat" />
      <div className={`manageFieldRightSettings p-4 overflow-y-auto`}>
        {/* <WebMessageNavViews activeTab={activeTab} toggle={toggle} /> */}
        {activeTab === "1" && <WebchatBotsPage {...props} />}
      </div>
    </CompanyProfileDetailsLayout>
  );
}

export default WebchatMessages;
