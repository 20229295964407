import { GlobalOutlined, WhatsAppOutlined } from "@ant-design/icons";
import AddSvgComponent from "@assets/svg/add";
import CopySvgComponents from "@assets/svg/copy";
import EditSvgComponent from "@assets/svg/edit";
import ModulesAndFieldsSvgComponent from "@assets/svg/modules-fields";
import { QueryRequestHelper } from "@common/query-request-helper";
import { useStateContext } from "@context/dataContext";
import { chatbotDocumentList } from "@services/chatbot-document.service";
import { getValue } from "@utils/lodash";
import { Button, Tooltip } from "antd";
import {
  Files,
  MessageSquareText,
  Pencil,
  Settings2,
  SparklesIcon,
} from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

const BotTableData = (props: any) => {
  const { list, toggleBotTypeModal } = props;
  const { getChatbotInfoCallback, urls } = useStateContext();
  const navigate = useNavigate();
  const params = useParams();

  const handleNavigate = (id: string) => {
    navigate(`/${getValue(params, `orgId`, "")}/chatbot/logs/${id}`);
  };

  const handleNavigateAIBot = async (id: string) => {
    const payload = {
      chatbot_id: id,
    };
    const queryRequest = QueryRequestHelper(payload);
    const links = await chatbotDocumentList(queryRequest);
    if (getValue(links, `data.length`, 0) > 0) {
      navigate(
        `/${getValue(params, `orgId`, "")}/chatbot/${id}/ai/detail/playground`
      );
    } else {
      navigate(
        `/${getValue(params, `orgId`, "")}/chatbot/${id}/ai/detail/sources`
      );
    }
    getChatbotInfoCallback(id);
  };

  const handleEditBot = (id: string) => {
    navigate(
      `/${getValue(params, `orgId`, "")}/chatbot/builder/create-flow/${id}`
    );
  };

  return (
    <div className=" w-100 add-overflow-y mt-3 bot_container">
      {/* {getValue(list, `length`, 0) > 0 ? ( */}
      <div className="bot-list-wrapper p-4 gap-4 pb-5">
        {getValue(props, `permissions`, []).includes("create") && (
          <div
            onClick={toggleBotTypeModal}
            // onClick={() =>
            //   navigate(`/${getValue(params, `orgId`, "")}/chatbot/builder`)
            // }
            className="bot-card-add-container p-4"
          >
            <AddSvgComponent />
            <h6 className="header_text__18 ">Create bot</h6>
          </div>
        )}
        {list.map((item: object, key: number) => {
          return (
            <div className="bot-card-container p-4" key={key}>
              <div
                className=" touchable-text d-flex align-items-center gap-3"
                onClick={() =>
                  getValue(item, `type`, "") === "ai-chatbot"
                    ? handleNavigateAIBot(getValue(item, `id`, ""))
                    : handleNavigate(getValue(item, `id`, ""))
                }
              >
                <img
                  src={
                    getValue(item, `avatar`, "")
                      ? getValue(item, "avatar", "")
                      : "/images/bot-loader.gif"
                  }
                  width={50}
                  height={50}
                  className="rounded-5"
                  style={{ background: "#e7e7e7" }}
                />
                <div>
                  <h6 className="header_blue_text__16 ">
                    {getValue(item, `name`, "")}
                  </h6>
                  <p className="small_text__13">
                    {getValue(item, `description`, "")}
                  </p>
                </div>
              </div>
              <div className="d-flex gap-3 mt-4 justify-content-between align-items-end">
                {getValue(item, `platform`, "") === "WHATSAPP" && (
                  <Tooltip title="Whatsapp BOT">
                    <WhatsAppOutlined style={{ color: "#25D366" }} />
                  </Tooltip>
                )}
                {getValue(item, `type`, "") !== "ai-chatbot" &&
                  getValue(item, `platform`, "") === "WEB" && (
                    <Tooltip title="Web BOT">
                      <GlobalOutlined style={{ color: "#1DA1F2" }} />
                    </Tooltip>
                  )}
                {getValue(item, `type`, "") === "ai-chatbot" && (
                  <Tooltip title="AI Bot">
                    <SparklesIcon style={{ color: "#ff8c24" }} size={18} />
                  </Tooltip>
                )}
                {getValue(item, `source`, "") !== "ai-chatbot" && (
                  <div className="d-flex align-items-center gap-1">
                    {getValue(props, `permissions`, []).includes("update") && (
                      <Tooltip title="Edit">
                        <Button
                          color="default"
                          variant="text"
                          onClick={() =>
                            handleEditBot(getValue(item, `id`, ""))
                          }
                          icon={<Pencil size={16} />}
                        />
                      </Tooltip>
                    )}
                    <Tooltip title="Responses">
                      <Button
                        color="default"
                        variant="text"
                        onClick={() => handleNavigate(getValue(item, `id`, ""))}
                        icon={<MessageSquareText size={16} />}
                      />
                    </Tooltip>
                    <Tooltip title="Clone">
                      <Button
                        color="default"
                        variant="text"
                        onClick={() =>
                          props.handleClone(getValue(item, `id`, ""))
                        }
                        className="cursor-pointer"
                        icon={<Files size={16} />}
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {/* ) : (
        <BotNoDataPage />
      )} */}
    </div>
  );
};

export default BotTableData;
