import DraftJSRefEditor from "@components/custom/DraftJSEditor/draft-ref-editor";
import { getValue } from "@utils/lodash";
import { Button, Modal } from "antd";
import { Maximize2, Minimize2, Plus } from "lucide-react";
import React from "react";

const EmailEditorContent = ({
  request,
  setRequest,
  handleChangeEditor,
  toggleEmailModal,
  isModal,
}: any) => (
  <div className="ticket-email-editor position-relative">
    <Button
      color="default"
      type="text"
      icon={isModal ? <Minimize2 size={16} /> : <Maximize2 size={16} />}
      onClick={toggleEmailModal}
      className="email-editor-size-button"
    />
    <div className={`d-flex align-items-center ${"template_container"}`}>
      <div className={`${"template_container_section__1"}`}>
        <p className="small_text__14 text-right">
          Template Name <span className="form-error small_text__14">*</span>
        </p>
      </div>
      <div className={`${"template_container_section__2"}`}>
        <input
          type="text"
          className={`${"template_input"} ms-2 w-90`}
          placeholder="Enter Template Name"
          name="name"
          value={getValue(request, `name`, "")}
          onChange={(e) =>
            setRequest({
              ...request,
              name: e.target.value,
            })
          }
        />
      </div>
    </div>
    <div className={`d-flex align-items-center ${"template_container"}`}>
      <div className={`${"template_container_section__1"}`}>
        <p className="small_text__14 text-right">
          Subject <span className="form-error small_text__14">*</span>
        </p>
      </div>
      <div className={`${"template_container_section__2"}`}>
        <input
          type="text"
          className={`${"template_input"} ms-2 w-90`}
          placeholder="Enter Subject"
          name="subject"
          value={getValue(request, `subject`, "")}
          onChange={(e) =>
            setRequest({
              ...request,
              subject: e.target.value,
            })
          }
        />
      </div>
    </div>

    <div className="">
      <DraftJSRefEditor
        editorState={getValue(request, "content", "")}
        handleChangeEditor={handleChangeEditor}
        name="content"
        placeholder="Enter Content here"
        editorStyle={{
          background: "white",
          paddingLeft: "30px",
          minHeight: "300px",
        }}
      />
    </div>
  </div>
);

const TicketEmailEditor = ({
  request,
  setRequest,
  handleChangeEditor,
  toggleEmailModal,
  isModal,
  onClose,
}: any) => {
  return (
    <>
      {!isModal && (
        <EmailEditorContent
          request={request}
          setRequest={setRequest}
          handleChangeEditor={handleChangeEditor}
          toggleEmailModal={toggleEmailModal}
        />
      )}

      <Modal
        open={isModal}
        onCancel={onClose}
        width={1000}
        footer={null}
        centered
        closeIcon={null}
      >
        <EmailEditorContent
          request={request}
          setRequest={setRequest}
          handleChangeEditor={handleChangeEditor}
          toggleEmailModal={toggleEmailModal}
          isModal={true}
        />
      </Modal>
    </>
  );
};

export default TicketEmailEditor;
