import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import React, { useEffect, useRef, useState } from "react";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import {
  convertNumbersToSquareBrackets,
  formatString,
  sortJSONObjectArray,
} from "@common/text-helpers";
import InputRuleForm from "@components/InputRuleForm/form";
import { handleTextChange } from "@common/handleChange";
import { listAllModules } from "@services/modules.service";
import {
  createAutomation,
  getAllAutomationsUpdateFields,
  getAutomationsConditionFields,
  getSpecificAutomations,
  getAutomationEmailKeys,
  getAutomationsHourlyConditionFields,
} from "@services/automations.service";
import ConditionFields from "./components/conditions/ConditionFields";
import OptionFields from "./components/actions/OptionFields";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "sonner";
import EventFields from "./components/events/EventFields";
import { Badge, message, Tag, Timeline } from "antd";
import { HourlyTriggerEnum } from "./helpers/enum";
import FormDescription from "@components/custom/FormDescrption/FormDescription";

type RequestState = {
  id?: string;
  name: string;
  type: string;
  module_id: string;
  pattern: string;
  agents?: any[];
  description: string;
  conditions: {
    action: string;
    fieldId: string;
    operator: string;
    value?: any[];
    index: number;
    field_name?: string;
  }[];
  events?: {
    id?: string;
    action: string;
    fieldId: string;
    index: number;
    from: string;
    to: string;
  };
  actions: {
    updates: {
      action: string;
      fieldId: string;
      value: any[];
      isEmpty: boolean;
    }[];
    notes?: {
      html: string;
      to: any[];
      is_private: boolean;
      attachments: any[];
      id?: string;
      action: string;
    }[];
    emails?: {
      id?: string;
      action: string;
      field_name?: string;
      email_to?: string;
      email_body: string;
      email_subject: string;
      email_agent_id?: string;
      email_group_id?: string;
    }[];
  };
  performer_type?: string;
};

const TicketsAutomationDetails = ({ props }: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const [editId, setEditId] = useState("");
  const [isTemplate, setIsTemplate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [ticketKeys, setTicketsKeys] = useState(false);

  const initialRequest = {
    name: "",
    type: "ticket_creation_rules",
    module_id: "",
    pattern: "",
    description: "",
    conditions: [
      {
        action: "add",
        fieldId: "",
        operator: "{IS}",
        value: [],
        index: 1,
      },
    ],
    actions: {
      updates: [
        {
          action: "add",
          fieldId: "",
          value: [],
          isEmpty: false,
        },
      ],
      // notes: [
      //   {
      //     to: [],
      //     html: "",
      //     attachments: [],
      //     is_private: true,
      //     action: "add",
      //   },
      // ],
    },
  };
  const [allConditions, setAllConditions] = useState(false);
  const [specificData, setSpecificData] = useState([]);

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const [request, setRequest] = useState<RequestState>(initialRequest);
  const [valueTypes, setValueTypes] = useState<any>([]);

  useEffect(() => {
    if (UrlParams.type) {
      setRequest((prevRequest) => ({
        ...prevRequest,
        type: UrlParams.type,
      }));
    }
    getAllModules();
    getConditions();
    getUpdateFields();
    handleGetTicketKeys();
  }, []);

  useEffect(() => {
    if (UrlParams.type === "ticket_update_rules") {
      setRequest((prev: any) => ({
        ...prev,
        events: [
          {
            action: "add",
            fieldId: "",
            from: "",
            to: "",
          },
        ],
        performer_type: "agent",
        agents: [],
      }));
    }
  }, [UrlParams.type]);

  useEffect(() => {
    if (getValue(params, "id", "")) {
      if (getValue(UrlParams, "template", "") === "true") setIsTemplate(true);
      else setEditId(getValue(params, "id", ""));
      handleGetSpecificAutomation(getValue(params, "id", ""));
    }
  }, [params]);

  // useEffect(() => {
  //   if (UrlParams.type === "time_trigger_rules") {
  //     getHourlyConditionFields();
  //   }
  // }, [UrlParams.type]);

  // useEffect(() => {
  //   if (specificData.length > 0) {
  //     setRequest((prevRequest) => ({
  //       ...prevRequest,
  //       ...specificData,
  //     }));
  //   }
  // }, [specificData]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const [currentModule, setCurrentModule] = useState(null);
  const getAllModules = async () => {
    try {
      const resp = await listAllModules("");
      if (resp) {
        const ticketsModule = getValue(resp, `data`, []).find(
          (module: any) => module.api_name === "tickets"
        );

        if (ticketsModule) {
          setCurrentModule(ticketsModule);
          setRequest((prevRequest) => ({
            ...prevRequest,
            type: UrlParams.type,
            module_id: ticketsModule.id,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching modules:", error);
    }
  };

  // const [conditionFields, setConditionFields] = useState([]);
  // const handleGetConditionFields = async () => {
  //   try {
  //     const resp = await getAutomationsConditionFields();
  //     if (resp) {
  //       setConditionFields(getValue(resp, "data", []));
  //     }
  //   } catch (error) {
  //     console.error("Error fetching modules:", error);
  //   }
  // };

  const getHourlyConditionFields = async () => {
    const resp = await getAutomationsHourlyConditionFields();
    if (resp) {
      const updatedConditionFields = getValue(resp, `data`, []).map(
        (item: object) => ({
          id: getValue(item, `fieldId`, ""),
          fieldId: getValue(item, `fieldId`, ""),
          fieldLabel: getValue(item, `fieldLabel`, ""),
          label: getValue(item, `fieldLabel`, ""),
          fieldName: getValue(item, `fieldName`, ""),
          name: getValue(item, `fieldName`, ""),
          fieldType: getValue(item, `fieldType`, ""),
          inputType: getValue(item, `fieldType`, ""),
          conditions: getValue(item, `conditions[0]`, []),
          value: getValue(item, `fieldName`, []),
        })
      );
      return updatedConditionFields;
    }
    return [];
  };

  const [automationConditions, setAutomationCondtions] = React.useState<any>(
    []
  );
  const [automationOptions, setAutomationOptions] = React.useState<any>([]);
  const getConditions = async () => {
    setAutomationOptions([]);
    try {
      let hourlyTriggers: any[] = [];
      if (UrlParams.type === "time_trigger_rules") {
        hourlyTriggers = await getHourlyConditionFields();
      }
      let resp = await getAutomationsConditionFields();
      if (resp) {
        const removeDuplicates = (
          existingArray: any,
          newArray: any,
          uniqueKey = "id"
        ) => {
          const existingIds = new Set(
            existingArray.map((item: any) => item[uniqueKey])
          );
          return [
            ...existingArray,
            ...newArray.filter(
              (newItem: any) => !existingIds.has(newItem[uniqueKey])
            ),
          ];
        };

        const processedWorkflowOptions = getValue(resp, `data`, []).map(
          (item: object) => ({
            id: getValue(item, `fieldId`, ""),
            label: getValue(item, `fieldLabel`, ""),
            inputType: getValue(item, `fieldType`, ""),
            value: getValue(item, `fieldId`, ""),
          })
        );

        const WorkflowOptions = getValue(resp, `data`, []).map(
          (item: object) => ({
            ...item,
            inputType:
              getValue(item, `fieldId`, "") === "pipeline_stage_id"
                ? "select"
                : getValue(item, `fieldType`, ""),
            fieldType:
              getValue(item, `fieldId`, "") === "pipeline_stage_id"
                ? "select"
                : getValue(item, `fieldType`, ""),
          })
        );
        // Use the newly fetched hourlyTriggers
        setAutomationOptions((prevOptions: any) =>
          removeDuplicates(
            prevOptions,
            [...processedWorkflowOptions, ...hourlyTriggers],
            "id"
          )
        );

        setAutomationCondtions((prevConditions: any) =>
          removeDuplicates(
            prevConditions,
            [...WorkflowOptions, ...hourlyTriggers],
            "id"
          )
        );
      }
    } catch (error) {
      console.error("Error fetching conditions:", error);
    }
  };

  const [updateFields, setUpdateFields] = React.useState<any>([]);
  const getUpdateFields = async () => {
    try {
      let resp = await getAllAutomationsUpdateFields();
      if (resp) {
        const updatedArray = getValue(resp, "data", []).map((item: any) => {
          const { ...rest } = item;
          return {
            label: item.fieldLabel,
            value: item.fieldId,
            ...rest,
          };
        });
        setUpdateFields(updatedArray);
      }
    } catch (error) {
      console.error("Error fetching options", error);
    }
  };

  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setIsLoading(true);
      try {
        let payload = JSON.parse(JSON.stringify(request));
        payload["pattern"] = convertNumbersToSquareBrackets(
          getValue(payload, `pattern`, "")
        );
        const resp = await createAutomation(payload);
        if (resp) {
          // toast.success(`${editId ? "Updated" : "Created"} Successfully`);
          message.success(`${editId ? "Updated" : "Created"} Successfully`);
          setRequest(initialRequest);
          navigate(
            `/${getValue(
              params,
              "orgId",
              ""
            )}/settings/helpdesk/tickets?active_tab=${getValue(
              resp,
              "data.type",
              ""
            )}`
          );
          setIsLoading(false);
        } else {
          toast.error(
            `Failed to ${editId ? "editing" : "creating"} Automation`
          );
        }
      } catch (error) {
        toast.error(`Error ${editId ? "editing" : "creating"} Automation`);
      } finally {
        setIsLoading(false);
      }
    }
  };
  function extractOperators(input: string) {
    const operators = [];
    operators.push("WHEN"); // Add WHEN as the first element
    const keywords = input.match(/[A-Z]+/g); // Extract uppercase words
    if (keywords) {
      keywords.forEach((word) => {
        operators.push(word);
      });
    }
    return operators;
  }

  function convertExpression(input: string) {
    return input
      .replace(/\[/g, "")
      .replace(/\]/g, "")
      .replace(/\sAND\s/g, " ")
      .replace(/\(/g, "( ")
      .replace(/\)/g, " )");
  }
  const handleGetSpecificAutomation = async (id: string) => {
    try {
      setIsLoading1(true);
      const resp = await getSpecificAutomations(id);
      if (resp) {
        setSpecificData(getValue(resp, "data", ""));
        let conditionsTypes = extractOperators(
          getValue(resp, `data.pattern`, "")
        );
        let conditions =
          getValue(resp, `data.conditions.length`, 0) > 0
            ? sortJSONObjectArray(
                getValue(resp, `data.conditions`, []),
                "index"
              ).map((item: object, index: number) => ({
                pattern:
                  getValue(conditionsTypes, `length`, 0) > 0
                    ? conditionsTypes[index]
                    : "",
                value: [getValue(item, `value`, "")],
                fieldId: getValue(item, `module_field`, "")
                  ? getValue(item, `module_field.id`, "")
                  : getValue(item, `column`, ""),
                operator: getValue(item, `operator`, ""),
                id: getValue(item, `id`, ""),
                action: "update",
                index: getValue(item, `index`, null),
                column: getValue(item, `column`, null),
              }))
            : [];

        let updateActions =
          getValue(resp, `data.update_actions.length`, 0) > 0
            ? getValue(resp, `data.update_actions`, []).map((item: object) => ({
                action: "update",
                id: getValue(item, `id`, ""),
                value: [getValue(item, `value`, "")],
                isEmpty: getValue(item, `is_empty`, false),
                ...(getValue(item, `field_name`, "")
                  ? { field_name: getValue(item, `field_name`, "") }
                  : { fieldId: getValue(item, `module_field.id`, "") }),
              }))
            : null;

        const updatedNotes =
          getValue(resp, "data.note_actions.length", 0) > 0
            ? getValue(resp, "data.note_actions", []).map((item: object) => ({
                ...item,
                action: "update",
                html: getValue(item, "html", ""),
              }))
            : null;
        const updatedEmails =
          getValue(resp, "data.email_actions.length", 0) > 0
            ? getValue(resp, "data.email_actions", [])
                .filter((item: object | null) => item !== null)
                .map((item: Record<string, any>) => {
                  const updatedItem = Object.fromEntries(
                    Object.entries(item)
                      .filter(([_, value]) => value !== null)
                      .concat([["action", "update"]])
                  );

                  if (item?.email_agent) {
                    updatedItem.email_agent_id = item.email_agent.id;
                    delete updatedItem.email_agent;
                  }

                  if (item?.email_group) {
                    updatedItem.email_group_id = item.email_group.id;
                    delete updatedItem.email_group;
                  }

                  return updatedItem;
                }) || null
            : null;

        let updateValuesTypes =
          getValue(resp, `data.update_actions.length`, 0) > 0
            ? getValue(resp, `data.update_actions`, []).map((item: object) => ({
                fieldType: getValue(item, `module_field.input_type`, ""),
                value: getValue(item, `value`, ""),
                lookupModule: getValue(item, `module_field.lookup`, null),
                dropDownOpts: [],
              }))
            : null;
        setValueTypes(updateValuesTypes);
        setAllConditions(
          getValue(resp, `data.conditions.length`, 0) > 0 ? true : false
        );

        const updatedAgents =
          getValue(resp, "data.agents.length", 0) > 0
            ? getValue(resp, "data.agents", []).map((item: any) =>
                getValue(item, "id", "")
              ) || null
            : null;

        let updatedEvents =
          getValue(resp, `data.events.length`, 0) > 0
            ? sortJSONObjectArray(
                getValue(resp, `data.events`, []),
                "index"
              ).map((item: object, index: number) => ({
                fieldId: getValue(item, `module_field.id`, ""),
                id: getValue(item, `id`, ""),
                action: "update",
                index: 1,
                from: getValue(item, `from`, ""),
                to: getValue(item, `to`, ""),
              }))
            : [];

        setRequest({
          id: !getValue(resp, "data.is_template", "")
            ? getValue(resp, "data.id", "")
            : null,
          name: !getValue(resp, "data.is_template", "")
            ? getValue(resp, "data.name", "")
            : null,
          type: getValue(resp, "data.type", ""),
          description: getValue(resp, "data.description", ""),
          module_id: getValue(resp, "data.module_id", ""),
          pattern: convertExpression(getValue(resp, `data.pattern`, "")),
          conditions: conditions,
          actions: {
            emails: updatedEmails,
            notes: updatedNotes,
            updates: updateActions,
            // tasks: taskActions,
            // whatsapp: whatsappActions,
          },
          performer_type:
            getValue(resp, "data.type", "") === "ticket_update_rules"
              ? getValue(resp, "data.performer_type", "")
              : null,
          agents: updatedAgents,
          events: updatedEvents,
        });
      }
    } catch (error) {
      toast.error("Failed to load response.");
    } finally {
      setIsLoading1(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const handleGetTicketKeys = async () => {
    try {
      const resp = await getAutomationEmailKeys();
      if (resp) {
        setTicketsKeys(getValue(resp, "data.keys", []));
        setIsLoading1(false);
      }
    } catch (error) {
      toast.error("Failed to load Keys.");
    } finally {
    }
  };

  // console.log(" specificData", specificData);
  // console.log(" valueTypes", valueTypes);

  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        backButton
        title={`${editId ? "Edit" : "New"} ${formatString(
          getValue(request, "type", "")
        )}`}
        showSubmit
        submitText={`${editId ? "Update" : "Create"} Automation`}
        isLoading={isLoading}
        titleLoading={isLoading1}
        handleSubmit={() => handleSubmit()}
        showCancel
        handleCancel={() =>
          navigate(
            `/${getValue(params, "orgId", "")}/settings/helpdesk/tickets`
          )
        }
        disabled={
          // getValue(request, "conditions.length", 0) < 2 ||
          getValue(request, `actions.updates.length`, 0) === 0 &&
          getValue(request, `actions.notes.length`, 0) === 0 &&
          getValue(request, `actions.emails.length`, 0) === 0
        }
      />
      <div className="manageFieldRightSettings overflow-y-auto p-4">
        {editId && (
          <div className="border rounded-3 px-4 pt-4 mb-3">
            <h6 className="header_text__16">Summary</h6>
            {/* <p
              dangerouslySetInnerHTML={{
                __html: getValue(specificData, "summary_name", ""),
              }}
              className="small_text__14 summary-conditions pt-2"
              style={{ marginRight: "4px" }}
            /> */}

            <div>
              <Tag color="default" className="mb-2 mt-3">
                Events
              </Tag>
              <Timeline
                className="ps-3"
                items={
                  getValue(specificData, "summary_events.length", 0) > 0
                    ? getValue(specificData, "summary_events", []).map(
                        (item: any) => ({
                          children: (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: formatString(item),
                              }}
                              className="small_text__14 summary-conditions"
                              style={{ marginRight: "4px" }}
                            />
                          ),
                        })
                      )
                    : [
                        {
                          children: (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: formatString(
                                  getValue(specificData, "summary_name", "")
                                ),
                              }}
                              className="small_text__14 summary-conditions"
                              style={{ marginRight: "4px" }}
                            />
                          ),
                        },
                      ]
                }
              />
            </div>
            <div>
              <Tag color="default" className="mb-2">
                Condition
              </Tag>
              <Timeline
                className="ps-3"
                items={getValue(specificData, "summary_conditions", []).map(
                  (item: any) => ({
                    children: (
                      <p
                        dangerouslySetInnerHTML={{ __html: formatString(item) }}
                        className="small_text__14 summary-conditions"
                        style={{ marginRight: "4px" }}
                      />
                    ),
                  })
                )}
              />
            </div>
            <div>
              <Tag color="default" className="mb-2">
                Action
              </Tag>
              <Timeline
                className="ps-3"
                items={getValue(specificData, "summary_actions", []).map(
                  (item: any) => ({
                    children: (
                      <p
                        dangerouslySetInnerHTML={{ __html: formatString(item) }}
                        className="small_text__14 summary-conditions"
                        style={{ marginRight: "4px" }}
                      />
                    ),
                  })
                )}
              />
            </div>
          </div>
        )}
        <InputRuleForm
          inputType="TEXT"
          name="name"
          value={getValue(request, "name", "")}
          placeholder="Enter Rule Name"
          required
          label="Rule Name (For Own Reference)"
          onChange={(e: any) => handleTextChange(e, request, setRequest)}
          validLeft
          mandatory
          validator={simpleValidator}
          style={{
            maxWidth: "640px",
          }}
        />
        <FormDescription
          request={request}
          textClassname="mb-2 mt-3"
          inputClassname="w-50 mt-3"
          input={
            <InputRuleForm
              inputType="TEXTAREA"
              name="description"
              value={getValue(request, `description`, "")}
              placeholder="Enter Description"
              label="Description"
              onChange={(e: any) => handleTextChange(e, request, setRequest)}
            />
          }
        />
        <div>
          <Timeline
            className="mt-5"
            items={[
              {
                dot: <Badge count={1} style={{ backgroundColor: "#52c41a" }} />,
                children: (
                  <EventFields
                    request={request}
                    setRequest={setRequest}
                    workflowConditions={automationConditions}
                    workflowOptions={automationOptions}
                    validator={simpleValidator}
                  />
                ),
              },
              {
                dot: <Badge count={2} style={{ backgroundColor: "#ff942f" }} />,
                children: (
                  <ConditionFields
                    request={request}
                    setRequest={setRequest}
                    workflowConditions={automationConditions}
                    workflowOptions={automationOptions}
                    validator={simpleValidator}
                    isTemplate={isTemplate}
                  />
                ),
              },
              {
                dot: <Badge count={3} style={{ backgroundColor: "#1677ff" }} />,
                children: (
                  <OptionFields
                    request={request}
                    setRequest={setRequest}
                    updateFields={updateFields}
                    validator={simpleValidator}
                    setValueTypes={setValueTypes}
                    ticketKeys={ticketKeys}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </CompanyProfileDetailsLayout>
  );
};

export default TicketsAutomationDetails;
