import InputRuleForm from "@components/InputRuleForm/form";
import AntSearchToolTip from "@components/custom/DebounceSelect";
import NewSearchSelect from "@components/custom/Dropdown/AntDropDown";
import { getValue } from "@utils/lodash";
import { Badge, Button, Card, Checkbox, Radio } from "antd";
import { useState } from "react";
import { Tooltip } from "react-tooltip";
import FormAgentModal from "./form-agent-modal";
import { handleAntCheckboxChange } from "@common/handleChange";

function ManageFormStep2(props: any) {
  const {
    params,
    setRequest,
    request,
    allPipelinesList,
    allPipelinesStageList,
    visitor_see,
    setVisitor_see,
    info,
    getData,
    assignmentTypeRequest,
    setAssignmentTypeRequest,
    agentRequest,
    droppedFormFields,
  } = props;

  const handleChangeRouteName = (e: any) => {
    const value = e.target.value.toLowerCase();
    if (value) {
      // Regular expression pattern to allow only alphabetical characters and underscore
      const pattern = /^[a-zA-Z-]+$/;
      if (pattern.test(value)) {
        setRequest({
          ...request,
          route_name: value,
        });
      }
    } else {
      setRequest({
        ...request,
        route_name: "",
      });
    }
  };

  const assignmentType = [
    {
      label: "Assign to a fixed user",
      value: "assign_to_fixed_user",
    },
    {
      label: "Round Robin with agent availability",
      value: "round_robin_with_agent_availability",
    },
    {
      label: "Unassign",
      value: "unassign",
    },
  ];

  const [showAgentModal, setShowAgentModal] = useState(false);
  const toggleAgentModal = () => {
    setShowAgentModal(!showAgentModal);
  };

  return (
    <div className="manageFieldRightSettings  add-overflow-y">
      <div className=" grayed-background d-flex justify-content-center ">
        <div className="bg-white w-75 form-details-container pb-5">
          <h6 className="header_text__18 text-center mt-4 mb-3">
            Form Details
          </h6>
          <div className="d-flex justify-content-c align-items-center flex-column">
            {/* <div className="d-flex justify-content-center align-items-center">
                <h6 className="header_text__14 w-25">FormLink</h6>:
                <div className="w-25  ms-3 mb-2 d-flex align-items-center">
                  <p>{window.location.origin}/forms/</p> <InputRuleForm
                    inputType="TEXTAREA"
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        thank_you_message: e.target.value,
                      })
                    }
                    value={getValue(request, `thank_you_message`, "")}
                    placeholder="Enter Thankyou Message"
                  />
                </div>
              </div> */}
            <div className="w-80">
              <div>
                {getValue(info, `org_id`, "") ? (
                  <div className="d-flex align-items-center">
                    <div
                      className={`small_text__16 mt-2 my-template-${getValue(
                        info,
                        `org_id`,
                        ""
                      )}`}
                    >
                      <p className="small_text__16">
                        /{getValue(info, `org_id`, "")}/settings/web-forms/
                      </p>
                      <Tooltip
                        className="tooltip"
                        anchorSelect={`.my-template-${getValue(
                          info,
                          `org_id`,
                          ""
                        )}`}
                      >
                        /{getValue(info, `org_id`, "")}/settings/web-forms/
                        {getValue(request, "title", "")}
                      </Tooltip>
                    </div>

                    <div>
                      <InputRuleForm
                        inputType="TEXT"
                        value={getValue(request, "title", "")}
                        placeholder="Enter Route Name"
                        required
                        disabled
                      />
                    </div>
                  </div>
                ) : (
                  <InputRuleForm
                    inputType="TEXT"
                    value={getValue(request, "route_name", "")}
                    placeholder="Enter Route Name"
                    name="route_name"
                    onChange={handleChangeRouteName}
                    required
                    label={`Route Name`}
                    validLeft
                    validator={props.validator}
                  />
                )}
              </div>
              {/* <div className="my-3">
                <NewSearchSelect
                  options={allPipelinesList}
                  value={getValue(request, `pipeline_id`, "")}
                  onChange={(id: string) => {
                    props.getUniquePipeline(id);
                    setRequest({
                      ...request,
                      pipeline_id: id,
                      pipeline_stage_id: "",
                      update_pipeline_id: id,
                      update_pipeline_stage_id: "",
                    });
                  }}
                  placeholder="Choose Pipeline"
                  required
                  label="Pipeline"
                  validator={props.validator}
                  selectKey={"id"}
                />
              </div> */}

              <NewSearchSelect
                options={allPipelinesStageList}
                value={getValue(request, `pipeline_stage_id`, "")}
                onChange={(value: string) =>
                  setRequest({
                    ...request,
                    pipeline_stage_id: value,
                  })
                }
                placeholder="Choose Pipeline Status"
                label="Pipeline Status"
                required
                validator={props.validator}
                selectKey={"id"}
              />

              <Card title="How should the form be assigned?" className="mt-3">
                <Radio.Group
                  name="assignment_type"
                  value={assignmentTypeRequest}
                  onChange={(e: any) => {
                    const newValue = e.target.value;
                    setAssignmentTypeRequest(newValue);
                  }}
                >
                  {assignmentType.map((item) => (
                    <Radio value={item.value}>{item.label}</Radio>
                  ))}
                </Radio.Group>
                {assignmentTypeRequest === "assign_to_fixed_user" && (
                  <div className="mt-3">
                    <AntSearchToolTip
                      lookup_api={"org_users"}
                      value={getValue(request, `owner_id`, "")}
                      onChange={(id: object) =>
                        setRequest({
                          ...request,
                          owner_id: id,
                        })
                      }
                      selectKey={"id"}
                      placeholder="Choose Owner"
                      required
                      label="Owner"
                      validator={
                        assignmentTypeRequest === "assign_to_fixed_user" &&
                        props.validator
                      }
                    />
                    <div className="d-flex align-items-center gap-2 mt-3">
                      <Checkbox
                        checked={getValue(request, `notify_owner`, false)}
                        onChange={(e) => {
                          setRequest({
                            ...request,
                            notify_owner: e.target.checked,
                          });
                        }}
                      >
                        Notify Owner
                      </Checkbox>
                    </div>
                  </div>
                )}
                {assignmentTypeRequest ===
                  "round_robin_with_agent_availability" && (
                  <div className="mt-3">
                    <Button onClick={toggleAgentModal}>
                      Add Agents{" "}
                      <Badge
                        count={getValue(agentRequest, `length`, 0)}
                        color="blue"
                      />
                    </Button>
                    {assignmentTypeRequest ===
                      "round_robin_with_agent_availability" &&
                      getValue(request, "agents", []).length < 1 && (
                        <div className="text-danger mt-2">
                          {props.validator.message("agents", null, "required", {
                            messages: {
                              required: "At least one agent must be selected",
                            },
                          })}
                        </div>
                      )}
                  </div>
                )}
              </Card>
            </div>

            {/* <div className="d-flex justify-content-center align-items-center">
                <h6 className="header_text__14 w-25">Tag</h6>:
                <div className="w-25 ms-3 mb-2">
                  <MultiSearchTooltip
                    data={allTags}
                    value={getValue(request, `tags`, "")}
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        tags: handleUpdateArray(getValue(e, `value`, ""), []),
                      })
                    }
                    label="label"
                    placeholder="Choose Tag"
                    selectKey={"value"}
                  />
                </div>
              </div> */}
            <Card
              title={
                <Checkbox
                  checked={getValue(request, `skip_duplicates`, true)}
                  onChange={(e: any) => {
                    setRequest({
                      ...request,
                      skip_duplicates: e.target.checked,
                      // ...(e.target.checked
                      //   ? {}
                      //   : {
                      //       module_field_id: "",
                      //       update_pipeline_stage_id: "",
                      //     }),
                    });
                  }}
                  disabled={
                    droppedFormFields.filter((item: any) =>
                      ["tel", "email"].includes(
                        getValue(item, "input_type", "")
                      )
                    ).length === 0
                  }
                >
                  Duplicate Validation
                </Checkbox>
              }
              className="mt-3 w-80"
            >
              {getValue(request, `skip_duplicates`, true) === false ? (
                <p className="small_text__14">
                  Duplicate entries will be allowed and a new record will be
                  created
                </p>
              ) : (
                <div>
                  <NewSearchSelect
                    options={droppedFormFields
                      .filter((item: any) =>
                        ["tel", "email"].includes(
                          getValue(item, "input_type", "")
                        )
                      )
                      .map((item: any) => ({
                        label: getValue(item, `label`, ""),
                        value: getValue(item, `id`, ""),
                      }))}
                    value={getValue(request, `module_field_id`, "")}
                    onChange={(value: string) =>
                      setRequest({
                        ...request,
                        module_field_id: value,
                      })
                    }
                    placeholder="Choose type"
                    label="Skip duplicate entries that match existing records"
                    // required
                    // validator={
                    //   getValue(request, `skip_duplicates`, true)
                    //     ? props.validator
                    //     : null
                    // }
                  />
                  <div className="mt-3">
                    <NewSearchSelect
                      options={allPipelinesStageList}
                      value={getValue(request, `update_pipeline_stage_id`, "")}
                      onChange={(value: string) =>
                        setRequest({
                          ...request,
                          update_pipeline_stage_id: value,
                        })
                      }
                      placeholder="Choose Pipeline Status"
                      label="Status if duplicate value is found"
                      // required
                      // validator={
                      //   getValue(request, `skip_duplicates`, true)
                      //     ? props.validator
                      //     : null
                      // }
                      selectKey={"id"}
                    />
                  </div>
                </div>
              )}
            </Card>

            <Card
              title="What should the visitor see when they submit this form?"
              className="mt-3 w-80"
            >
              <Radio.Group
                value={visitor_see}
                onChange={(e) => {
                  setVisitor_see(e.target.value);
                  if (e.target.value) {
                    setRequest({
                      ...request,
                      thank_you_message: "",
                    });
                  }
                }}
              >
                <Radio value={false}>Thank You Message</Radio>
                <Radio value={true}>Redirect them to a Page</Radio>
              </Radio.Group>

              {!visitor_see ? (
                <div className="w-100 mb-2">
                  <InputRuleForm
                    inputType="TEXTAREA"
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        thank_you_message: e.target.value,
                      })
                    }
                    value={getValue(request, `thank_you_message`, "")}
                    placeholder="Enter Thankyou Message"
                  />
                </div>
              ) : (
                <div className="w-100 mb-2">
                  <InputRuleForm
                    inputType="TEXT"
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        redirect_url: e.target.value,
                      })
                    }
                    value={getValue(request, `redirect_url`, "")}
                    placeholder="Enter Redirecting URL"
                    required
                    label="Redirect URL"
                  />
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>
      <FormAgentModal
        params={params}
        showAgentModal={showAgentModal}
        toggleAgentModal={toggleAgentModal}
        agentRequest={agentRequest}
        getData={getData}
      />
    </div>
  );
}

export default ManageFormStep2;
