"use client";

import type React from "react";
import { useState } from "react";
import { Table, Button, Tooltip, Input, Space } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import type { ColumnsType, TableProps } from "antd/es/table";
import "./templates-meta.scss";
import { formatString } from "@common/text-helpers";

interface DataType {
  key: string;
  name: string;
  category: string;
  status: string;
  language: string;
  messageRead: string;
  topBlockReason: string;
  lastEdited: string;
  isAvailable: boolean;
  sync_status: string;
}

const TemplatesFromMeta: React.FC<{
  templateList: any[];
  handleSubmit: (payload: any) => void;
}> = ({ templateList, handleSubmit }) => {
  // Column sorting icons
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(templateList);

  // Info icon with tooltip
  const renderInfoIcon = () => (
    <></>
    // <Tooltip title="More information">
    //   <InfoCircleOutlined className="info-icon" />
    // </Tooltip>
  );

  const handleSearch = (value: string) => {
    setSearchText(value);
    const filtered = templateList.filter((item) =>
      Object.values(item).some((val) =>
        String(val).toLowerCase().includes(value.toLowerCase())
      )
    );
    setFilteredData(filtered);
  };

  const handleTableChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters
    // sorter
  ) => {
    // Handle sorting here if needed
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "Template name",
      dataIndex: "name",
      key: "name",
      render: (text: string) => (
        <div>
          <div>{formatString(text)}</div>
        </div>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: string) => (
        <span className="status-approved">APPROVED</span>
      ),
    },
    {
      title: <span>Language {renderInfoIcon()}</span>,
      dataIndex: "language",
      key: "language",
    },
    {
      title: "",
      key: "action",
      render: (_, record) =>
        record.sync_status === "AVAILABLE" ? (
          <Button type="text" className="copy-button-disabled" disabled>
            Already Synced
          </Button>
        ) : (
          <Button
            type="text"
            className="copy-button"
            onClick={() => handleSubmit(record)}
          >
            Copy to Appzo
          </Button>
        ),
    },
  ];
  const data = templateList;

  return (
    <div className="templates-meta-container">
      <div className="templates-content">
        <Table
          dataSource={data}
          columns={columns}
          pagination={false}
          rowClassName={(record) => (record.isAvailable ? "available-row" : "")}
        />
      </div>
    </div>
  );
};

export default TemplatesFromMeta;
