import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import Pusher from "pusher-js";
import { config } from "../env";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getLiveChatStatusCount } from "@services/live-chat.service";
import { useStateContext } from "./dataContext";

const SocketContextRef = createContext();

const STATUS_MAPPINGS = {
  my_open_chats: "assignedMe",
  my_waiting_on_customer_chats: "waitingOnCustomer",
  my_waiting_on_us_chats: "waitingOnUs",
  my_on_hold_chats: "onHold",
  my_resolved_chats: "resolved",
  all_unassigned_chats: "unAssigned",
  all_open_chats: "assigned",
  all_resolved_chats: "resolved",
  all_bot_conversations: "assignedChatbot",
  all_waiting_on_customer_chats: "allWaitingOnCustomer",
  all_waiting_on_us_chats: "allWaitingOnUs",
  all_on_hold_chats: "allOnHold",
};

const INITIAL_STATUS_COUNTS = {
  assigned: 0,
  unAssigned: 0,
  resolved: 0,
  assignedChatbot: 0,
  assignedMe: 0,
  waitingOnCustomer: 0,
  waitingOnUs: 0,
  onHold: 0,
  allWaitingOnCustomer: 0,
  allWaitingOnUs: 0,
  allOnHold: 0,
};

function SocketProvider({ children }) {
  const [chatInfo, setChatInfo] = useState({});
  const [statusCounts, setStatusCounts] = useState({
    all: INITIAL_STATUS_COUNTS,
    unread: INITIAL_STATUS_COUNTS,
  });
  const [rawStatusCounts, setRawStatusCounts] = useState({
    all: [],
    unread: [],
  });

  // const getAllCount = useCallback(async () => {
  const getAllCount = async () => {
    try {
      const urlParams = Object.fromEntries(
        new URLSearchParams(window.location.search)
      );

      const [allResponse, unreadResponse] = await Promise.all([
        getLiveChatStatusCount(
          QueryRequestHelper({
            source: getValue(urlParams, "source", ""),
            assigned_user_id: getValue(urlParams, "forOrgUser", ""),
          })
        ),
        getLiveChatStatusCount(
          QueryRequestHelper({
            source: getValue(urlParams, "source", ""),
            only_unread: true,
            assigned_user_id: getValue(urlParams, "forOrgUser", ""),
          })
        ),
      ]);
      if (allResponse && unreadResponse) {
        const processResponse = (response) =>
          getValue(response, `data`, []).reduce(
            (acc, { status, count }) => {
              const normalizedStatus = STATUS_MAPPINGS[status] || status;
              acc[normalizedStatus] = count;
              return acc;
            },
            { ...INITIAL_STATUS_COUNTS }
          );
        setStatusCounts({
          all: processResponse(allResponse),
          unread: processResponse(unreadResponse),
        });

        setRawStatusCounts({
          all: getValue(allResponse, `data`, []),
          unread: getValue(unreadResponse, `data`, []),
        });
      }
    } catch (error) {
      console.error("Error fetching counts:", error);
    }
  };
  useEffect(() => {
    // if (getValue(chatInfo, "unread_count", 0) > 0) {
    getAllCount();
    // }
  }, []);

  useEffect(() => {
    const path = window.location.pathname;
    const orgID = path.split("/")[1];

    const pusher = new Pusher(config.PUSHER_APP_KEY, {
      cluster: config.PUSHER_APP_CLUSTER,
    });

    const chatChannel = pusher.subscribe(`inbox-${orgID}`);
    const paymentChannel = pusher.subscribe(`payment-${orgID}`);

    // const handleWebchatChange = (data) => {
    //   console.log("NEW GLOBAL COUNT EVENT RECEIVED :::", { data });
    //   if (getValue(data, `unread_count`, 0) > 0) {
    //     getAllCount();
    //   }
    //   setChatInfo(data);
    //   if (
    //     getValue(data, "managed_by") === "chatbot" &&
    //     getValue(data, "managed_by") === "ai-chabot"
    //   ) {
    //     return;
    //   }
    //   if (
    //     getValue(data, "managed_by") === "team" &&
    //     getValue(data, `last_msg.status`, false) === "received"
    //   ) {
    //     playNotificationSound();
    //   }
    // };

    const handleWebchatChange = (data) => {
      // Get the CURRENT URL parameters every time this function runs
      const currentUrlParams = new URLSearchParams(window.location.search);
      const currentUrlParamsObj = Object.fromEntries(
        currentUrlParams.entries()
      );

      if (getValue(data, `unread_count`, 0) > 0) {
        getAllCount();
      }
      setChatInfo(data);

      // Skip notifications for chatbot managed conversations
      if (
        getValue(data, "managed_by") === "chatbot" ||
        getValue(data, "managed_by") === "ai-chatbot"
      ) {
        return;
      }

      // Get current conversation status from URL parameters
      const currentConvStatus = getValue(currentUrlParamsObj, `convStatus`, "");

      // Define your specific notification conditions
      const notificationStatuses = [
        "my_open_chats",
        "my_waiting_on_customer_chats",
        "my_waiting_on_us_chats",
        "my_on_hold_chats",
        "my_resolved_chats",
      ];
      // Get additional data for condition checking
      const isTeamManaged = getValue(data, "managed_by") === "team";
      const userId = getValue(data, "contact.owner.id", "");
      const isReceivedMessage =
        getValue(data, `last_msg.status`, false) === "received";
      const conversationStatusValue = getValue(data, "conversation_status", "");
      const assignedUserId = getValue(data, "assigned_user_id", "");
      const isAssigned = Boolean(assignedUserId);
      // Define conditions matching your message bucket logic
      const shouldNotify = {
        my_open_chats: isTeamManaged && isAssigned && userId === assignedUserId,

        my_waiting_on_customer_chats:
          isTeamManaged && isAssigned && userId === assignedUserId,
        // &&conversationStatusValue === "my_waiting_on_customer_chats",

        my_waiting_on_us_chats:
          isTeamManaged && isAssigned && userId === assignedUserId,
        // &&conversationStatusValue === "my_waiting_on_us_chats",

        my_on_hold_chats:
          isTeamManaged && isAssigned && userId === assignedUserId,
        // &&conversationStatusValue === "my_on_hold_chats",

        my_resolved_chats:
          isTeamManaged && isAssigned && userId === assignedUserId,
        // &&conversationStatusValue === "closed",
      };

      // Play notification sound only if:
      // 1. Current status is in our notification list
      // 2. Message meets the basic requirements (team managed, received)
      // 3. Specific condition for that status is true
      if (
        // notificationStatuses.includes(currentConvStatus) &&
        isTeamManaged &&
        isReceivedMessage &&
        shouldNotify[currentConvStatus]
      ) {
        // playNotificationSound();
      }
    };

    const handlePaymentChange = (data) => {
      // console.log("PAYMENT NOTIFICATION RECEIVED :::", { data });
    };
    chatChannel.bind("inbox-notification", handleWebchatChange);
    paymentChannel.bind("razorpay-payment-notification", handlePaymentChange);
    return () => {
      chatChannel.unbind_all();
      chatChannel.unsubscribe();
      paymentChannel.unbind_all();
      paymentChannel.unsubscribe();
      pusher.disconnect();
    };
  }, []);

  const playNotificationSound = () => {
    new Audio("/notification.mp3")
      .play()
      .catch((error) =>
        console.error("Failed to play notification sound:", error)
      );
  };

  const totalInboxCount = () =>
    Object.values(statusCounts.all).reduce(
      (sum, count) => sum + (count || 0),
      0
    );

  const totalUnreadInboxCount = () =>
    Object.values(statusCounts.unread).reduce(
      (sum, count) => sum + (count || 0),
      0
    );

  return (
    <SocketContextRef.Provider
      value={{
        chatInfo,
        setChatInfo,
        totalInboxCount,
        totalUnreadInboxCount,
        getAllCount,
        statusCounts,
        rawStatusCounts,
      }}
    >
      {children}
    </SocketContextRef.Provider>
  );
}

export default SocketProvider;

export const useSocketContext = () => useContext(SocketContextRef);
