import { getValue } from "@utils/lodash";
import React from "react";
import { handleChangeNumber, handleUpdateArray } from "common/handleChange";
import DateMonthYearPicker from "@components/custom/Forms/Date";
import TextArea from "@components/custom/Forms/textarea";
import { capitalizeFirstLetter } from "common/text-helpers";
import TimePicker from "@components/custom/Forms/DateTime";
import MainTimePicker from "@components/custom/Forms/Time";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import AlarmPicker from "@components/custom/Forms/AlarmPicker";
import BooleanOptions from "@components/custom/Forms/boolean_options";
import MultiSearchTooltip from "@components/custom/Dropdown/MultiSearchTooltip";
import Input from "@components/custom/Forms/Input";
import moment from "moment";
import { convertDateTime } from "@common/date-helpers";
import { Checkbox } from "antd";
import NewSearchSelect from "@components/custom/Dropdown/AntDropDown";
import AntSearchToolTip from "@components/custom/DebounceSelect";

function UpdateFieldFormBuilder(props: any) {
  const {
    setFields,
    index,
    label,
    item,
    request,
    setRequest,
    key_value,
    value,
  } = props;

  const renderForm = (item: any) => {
    // let required = false;
    switch (getValue(item, `input_type`, "")) {
      case "text":
        return (
          <div className="">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setRequest({
                  ...request,
                  [key_value]: e.target.value,
                })
              }
              value={value}
              type={"text"}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              label={`${
                getValue(item, `name`, "") === "name"
                  ? capitalizeFirstLetter(label)
                  : ""
              }${" "}${capitalizeFirstLetter(getValue(item, `label`, ""))}`}
              removeBottom
            />
          </div>
        );
      case "email":
        return (
          <div className="">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setRequest({
                  ...request,
                  [key_value]: e.target.value,
                })
              }
              type={"email"}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              label={`${
                getValue(item, `name`, "") === "name" ? label : ""
              }${" "}${getValue(item, `label`, "")}`}
              removeBottom
            />
          </div>
        );

      case "url":
        return (
          <div className="">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setRequest({
                  ...request,
                  [key_value]: e.target.value,
                })
              }
              value={value}
              type={"url"}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              label={`${
                getValue(item, `name`, "") === "name" ? label : ""
              }${" "}${getValue(item, `label`, "")}`}
              removeBottom
            />
          </div>
        );

      case "currency":
      case "percent":
      case "integer":
      case "decimal":
      case "auto_number":
      case "autonumber":
      case "bigint":
      case "tel":
        return (
          <div className="">
            <Input
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setRequest({
                  ...request,
                  [key_value]: handleChangeNumber(e),
                })
              }
              value={value}
              type={"text"}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              label={`${getValue(item, `label`, "")}`}
              removeBottom
            />
          </div>
        );
      case "textarea":
        return (
          <div className="">
            <TextArea
              {...item}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setRequest({
                  ...request,
                  [key_value]: e.target.value,
                })
              }
              value={value}
              type={"textarea"}
              label={`${getValue(item, `label`, "")}`}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              rows={5}
            />
          </div>
        );

      case "date":
        return (
          <div className="">
            <DateMonthYearPicker
              {...item}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  [key_value]: moment(e).format("YYYY-MM-DD"),
                })
              }
              value={value}
              type={"text"}
              label={`${getValue(item, `label`, "")}`}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
            />
          </div>
        );
      case "time":
        return (
          <div className="">
            <MainTimePicker
              {...item}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  [key_value]: convertDateTime(e),
                })
              }
              value={value}
              type={"text"}
              label={`${getValue(item, `label`, "")}`}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              showTimeInput
            />
          </div>
        );
      case "datetime":
        return (
          <div className="">
            <TimePicker
              {...item}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  [key_value]: convertDateTime(e),
                })
              }
              value={value}
              type={"text"}
              label={`${getValue(item, `label`, "")}`}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              showTimeInput
            />
          </div>
        );
      case "datetime_alarm":
        return (
          <div className="">
            <AlarmPicker
              {...item}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  [key_value]: getValue(e, `value`, ""),
                })
              }
              value={value}
              label={`${getValue(item, `label`, "")}`}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              showTimeInput
            />
          </div>
        );

      case "boolean_checkbox":
        return (
          <div className="">
            <Checkbox
              {...item}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  [key_value]: !getValue(item, `value`, false),
                })
              }
              type={"checkbox"}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              label={`${
                getValue(item, `name`, "") === "name" ? label : ""
              }${" "}${getValue(item, `label`, "")}`}
            />
          </div>
        );
      case "boolean_radio":
        return (
          <div className="">
            <BooleanOptions
              {...item}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  [key_value]: e,
                })
              }
              value={value}
              data={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
              type={"radio"}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              label={`${
                getValue(item, `name`, "") === "name" ? label : ""
              }${" "}${getValue(item, `label`, "")}`}
            />
          </div>
        );
      case "boolean_select":
        return (
          <div className="">
            <SearchToolTip
              {...item}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  [key_value]: getValue(e, `value`, ""),
                })
              }
              value={value}
              label={`label`}
              data={[
                // { value: null, label: "None" },
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
              selectKey={"value"}
              name={getValue(item, `label`, "")}
            />
          </div>
        );
      case "select_multiple":
        return (
          <div className="">
            <MultiSearchTooltip
              {...item}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  [key_value]: handleUpdateArray(
                    getValue(e, `value`, ""),
                    value
                  ),
                })
              }
              value={value}
              label={`label`}
              data={
                getValue(item, `dropdown_options.length`, 0) > 0
                  ? getValue(item, `dropdown_options`, []).map(
                      (item: object, key: number) => ({
                        ...item,
                        label: getValue(item, `label`, ""),
                        index: key,
                        name: getValue(item, `label`, ""),
                        id: getValue(item, `id`, ""),
                      })
                    )
                  : getValue(item, `picklist_options.length`, 0) > 0
                  ? getValue(item, `picklist_options`, []).map(
                      (item: object, key: number) => ({
                        ...item,
                        label: getValue(item, `label`, ""),
                        index: key,
                        name: getValue(item, `label`, ""),
                        id: getValue(item, `id`, ""),
                      })
                    )
                  : []
              }
              selectKey={"value"}
              name={getValue(item, `label`, "")}
            />
          </div>
        );

      case "picklist":
      case "select":
        return (
          <div className="">
            <NewSearchSelect
              {...item}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              onChange={(e: any, data: any) =>
                setRequest({
                  ...request,
                  [key_value]: getValue(data, `value`, ""),
                })
              }
              options={
                getValue(item, `dropdown_options.length`, 0) > 0
                  ? getValue(item, `dropdown_options`, []).map(
                      (item: object, key: number) => ({
                        ...item,
                        label: getValue(item, `label`, ""),
                        index: key,
                        name: getValue(item, `label`, ""),
                        id: getValue(item, `id`, ""),
                      })
                    )
                  : getValue(item, `picklist_options.length`, 0) > 0
                  ? getValue(item, `picklist_options`, []).map(
                      (item: object, key: number) => ({
                        ...item,
                        label: getValue(item, `label`, ""),
                        index: key,
                        name: getValue(item, `label`, ""),
                        id: getValue(item, `id`, ""),
                      })
                    )
                  : []
              }
              value={value}
              selectKey={"value"}
              label={getValue(item, `label`, "")}
              // mandatory={required}
            />
          </div>
        );
      case "lookup":
        return (
          <div className="">
            <AntSearchToolTip
              {...item}
              placeholder={`Enter ${getValue(item, `label`, "")}`}
              onChange={(e: any, data: any) =>
                setRequest({
                  ...request,
                  [key_value]: getValue(data, `id`, ""),
                })
              }
              label={getValue(item, `label`, "")}
              value={value}
              lookup_api={getValue(item, `lookup.api_name`, "")}
            />
          </div>
        );
      default:
        return null;
    }
  };
  return <div>{renderForm(props.item)}</div>;
}

export default UpdateFieldFormBuilder;
