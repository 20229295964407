import BackSvgComponent from "@assets/svg/back-link";
import ButtonComponent from "@components/Form/Button/Button";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue, setValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  getAllRoomType,
  getSpecificRoomType,
} from "@services/properties/room-type.service";
import { getTimeSlotSettings } from "@services/properties/settings/time-slot.service";
import { getAllRatePlan } from "@services/properties/settings/rate-plan.service";
import {
  createPropertyBooking,
  getPropertyBookingprice,
} from "@services/properties/property-booking.service";
import moment from "moment";
import { convertDateTime } from "@common/date-helpers";
import { handleSelectChange } from "@common/handleChange";
import { mediaUpload } from "@services/upload.service";
import { config } from "../../../../../../env";
import { toast } from "sonner";
import BookingInfoForm from "../WalkIn/forms/booking-info-form";
import CheckinOutForm from "../WalkIn/forms/check-in-out-form";
import CompanyInfoForm from "../WalkIn/forms/company-info-form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { allParams } from "@common/url-params-helper";
import CounterButtons from "../WalkIn/components/count-buttons";
import CarouselNavViews from "@pages/Private/Broadcast/components/template-messages/components/carousel-nav-views";
import { cleanObject } from "@common/object-helper";
import { Button, Steps } from "antd";
import { Tabs } from "antd";
import { BuildFilled, CheckOutlined, PlusOutlined } from "@ant-design/icons";
import { formatString } from "@common/text-helpers";
import BedRoomSvgComponent from "@assets/svg/bedroom";
import SidebarLayout from "@layouts/SidebarLayout/SidebarLayout";
const { TabPane } = Tabs;

const CreateGroupBooking = (props: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);
  const [activeChildCarouselIndex, setActiveChildCarouselIndex] = useState(0);
  const [activeRoomIndex, setActiveRoomIndex] = useState(0);

  const bookinginfo_obj = {
    full_name: "",
    mobile: "",
    email: "",
    pincode: 0,
    address: "",
    city: "",
    state: "",
    country: "",
    identity_type: "passport",
    identity_value: "",
    identity_upload_type: "device_upload",
    identity_front_img: "",
    identity_back_img: "",
    is_primary: true,
    dob: "",
    gender: "male",
    nationality: "",
    occupation: "",
    emergency_contact: "",
    emergency_contact_name: "",
    type: "adult",
    relationship: "self",
  };

  const initialState: any = {
    checkin: "",
    checkout: "",
    room_data: [
      // {
      //   adults: 1,
      //   children: 0,
      //   room_type_id: 0,
      //   room_id: 0,
      //   rate_plan_id: "",
      //   user_booking_info: [bookinginfo_obj],
      //   child_user_booking_info: [],
      //   is_same_as_primary_user: true,
      //   payment_method: "cash",
      //   booking_amount: 0,
      //   payment_remarks: "",
      // },
    ],
    primary_user_info: [
      // bookinginfo_obj
    ],
    company_booking_info: {
      company_name: "",
      company_phone: "",
      company_email: "",
      gstin_no: "",
      address: "",
    },
    segment: "direct",
    sub_segment: "walkin",
    is_enquiry: true,
    property_id: "",
    // is_group_booking: false,
  };

  const [ratePlanRequest, setRatePlanRequest] = useState({
    rate_plan_price: "",
    sub_total: "",
    total: "",
    total_after_tax: "",
    total_before_tax: "",
    total_tax: "",
    taxes: "",
  });

  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getAllRoomList();
    getSlotList();
    getRatePlanList();
    const newDate = moment(getValue(params, `date`, "")).add(1, "day").toDate();
    setRequest((object: any) => {
      return {
        ...object,
        property_id: getValue(params, `id`, ""),
        checkin_date: new Date(getValue(params, `date`, "")),
        checkout_date: newDate,
        ["room_data"]: object["room_data"].map((x: any, index: number) => {
          return {
            ...x,
          };
        }),
      };
    });
  }, []);
  const [request, setRequest] = useState<any>(initialState);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (
      (getValue(request, `room_data[0].children`, 0) ||
        getValue(request, "checkout_date", "") ||
        getValue(request, "checkout_time", "")) &&
      getValue(params, `typeId`, "") &&
      getValue(params, `id`, "") &&
      getValue(request, "checkin_date", "") &&
      getValue(request, "checkin_time", "") &&
      getValue(request, `room_data[0].adults`, 0) &&
      getValue(request, `room_data[0].rate_plan_id`, 0)
    ) {
      getPropertyPriceInfo();
    }
    getData();
  }, [
    getValue(params, `typeId`, ""),
    getValue(params, `id`, ""),
    getValue(request, "checkin_date", ""),
    getValue(request, "checkout_date", ""),
    getValue(request, "checkin_time", ""),
    getValue(request, "checkout_time", ""),
    getValue(request, `room_data[${activeRoomIndex}].adults`, 0),
    getValue(request, `room_data[${activeRoomIndex}].children`, 0),
    getValue(request, `room_data[${activeRoomIndex}].rate_plan_id`, 0),
  ]);
  const getPropertyPriceInfo = async () => {
    let payload = {
      checkin: convertDateString(
        getValue(request, "checkin_date", ""),
        getValue(request, "checkin_time", "")
      ),
      checkout: convertDateString(
        getValue(request, "checkout_date", ""),
        getValue(request, "checkout_time", "")
      ),
      room_type_id: parseInt(getValue(params, `typeId`, "")),
      rate_plan_id: getValue(
        request,
        `room_data[${activeRoomIndex}].rate_plan_id`,
        0
      ),
      property_id: getValue(params, `id`, ""),
      num_of_adult: parseInt(
        getValue(request, `room_data[${activeRoomIndex}].adults`, 0)
      ),
      num_of_children: parseInt(
        getValue(request, `room_data[${activeRoomIndex}].children`, 0)
      ),
    };
    try {
      let resp = await getPropertyBookingprice(payload);
      if (resp) {
        setRatePlanRequest((prevState: any) => ({
          ...prevState,
          rate_plan_price: getValue(resp, `data.rate_plan_price`, ""),
          sub_total: getValue(resp, `data.sub_total`, ""),
          total: getValue(resp, `data.total`, ""),
          total_after_tax: getValue(resp, `data.total_after_tax`, []),
          total_before_tax: getValue(resp, `data.total_before_tax`, ""),
          total_tax: getValue(resp, `data.total_tax`, ""),
          taxes: getValue(resp, `data.taxes`, []),
        }));
      }
    } catch (error) {}
  };

  const [specificRoomDetails, setSpecificRoomDetails] = useState([]);
  const [subRooms, setSubRooms] = useState<any>([]);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const getData = async () => {
    try {
      setDetailsLoading(true);
      let resp = await getSpecificRoomType(getValue(params, `typeId`, ""));
      if (resp) {
        setDetailsLoading(false);
        setSpecificRoomDetails(getValue(resp, `data`, []));
        setSubRooms(
          getValue(resp, `data.room`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `room_id`, ""),
            label: `Room - ${getValue(item, `room_id`, "")}`,
          }))
        );
      } else {
        setDetailsLoading(false);
      }
    } catch (error) {
      setDetailsLoading(false);
      console.error(error);
    }
  };

  const convertDateString = (date: any, time: any) => {
    const dateStr = date;
    const timeStr = time;
    // Parse the date string into a moment object
    const dateMoment = moment(dateStr);
    // Parse the time string and set it to the date object
    const timeMoment = moment(timeStr, "h a");
    // Combine the date and time
    const combinedMoment = dateMoment.set({
      hour: timeMoment.hour(),
      minute: timeMoment.minute(),
      second: timeMoment.second(),
    });
    return convertDateTime(combinedMoment);
  };

  /* -----------------------------  Rooms Section ---------------------------- */

  const [isLoading, setIsLoading] = useState(false);
  const [rooms, setRooms] = useState([]);
  const getAllRoomList = async () => {
    try {
      setIsLoading(true);
      let payload = {
        property_id: getValue(params, `id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllRoomType(queryRequest);
      if (resp) {
        setIsLoading(false);

        setRooms(
          getValue(resp, `data.properties`, []).map((item: object) => ({
            ...item,
            label: getValue(item, `name`, ""),
            value: getValue(item, `id`, ""),
          }))
        );
        let arr = getValue(resp, `data.properties`, []).map(
          (item: object, index: number) => ({
            ...item,
            adults: 1,
            children: 0,
            rooms: 0,
            index: index,
          })
        );
        setItems(arr);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -----------------------------  Slot Section ---------------------------- */
  const [isLoading1, setIsLoading1] = useState(false);
  const [timeSlotsInfo, setTimeSlotsInfo] = useState([]);
  const [checkinSlots, setCheckinSlots] = useState<any>([]);
  const [checkoutSlots, setCheckoutSlots] = useState<any>([]);
  const getSlotList = async () => {
    try {
      setIsLoading1(true);
      let payload = {
        property_id: getValue(params, `id`, ""),
        slot_type: "24_hrs",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getTimeSlotSettings(queryRequest);
      if (resp) {
        setTimeSlotsInfo(getValue(resp, `data.timeSlots`, []));
        setCheckinSlots(
          getValue(resp, `data.timeSlots`, []).map((item: object) => ({
            value: getValue(item, `checkin`, ""),
            label: getValue(item, `checkin`, ""),
            name: getValue(item, `checkin`, ""),
          }))
        );
        setCheckoutSlots(
          getValue(resp, `data.timeSlots`, []).map((item: object) => ({
            value: getValue(item, `checkout`, ""),
            name: getValue(item, `checkout`, ""),
            label: getValue(item, `checkout`, ""),
          }))
        );

        setRequest((prevState: any) => ({
          ...prevState,
          checkin_time: getValue(resp, `data.timeSlots[${0}].checkin`, ""),
          checkout_time: getValue(resp, `data.timeSlots[${0}].checkout`, ""),
        }));
        setIsLoading1(false);
      } else {
        setIsLoading1(false);
      }
    } catch (error) {
      setIsLoading1(false);
    }
  };

  /* -----------------------------  Rate Plan Section ------------------------- */

  const [isLoading2, setIsLoading2] = useState(false);
  const [rateplanList, setRatePlanList] = useState([]);
  const getRatePlanList = async () => {
    try {
      setIsLoading2(true);
      let payload = {
        property_id: getValue(params, `id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllRatePlan(queryRequest);
      if (resp) {
        setRatePlanList(
          getValue(resp, `data.ratePlans`, []).map((item: object) => ({
            ...item,
            label: getValue(item, `rate_name`, ""),
            value: getValue(item, `id`, ""),
          }))
        );
        setRequest((prevState: any) => ({
          ...prevState,
          room_data: prevState.room_data.map((room: any, index: any) =>
            index === 0
              ? {
                  ...room,
                  rate_plan_id: getValue(resp, `data.ratePlans[${0}].id`, ""),
                }
              : room
          ),
        }));

        setIsLoading2(false);
      } else {
        setIsLoading2(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      const {
        room_data = [],
        company_booking_info = {},
        checkin_date,
        checkin_time,
        checkout_date,
        checkout_time,
        ...rest
      } = request;

      const combinedUserBookingInfo: any = [
        // ...(roomData.user_booking_info || []),
        // ...(roomData.child_user_booking_info || []),
      ];
      const updatedPayload = {
        ...rest,
        checkin: convertDateString(checkin_date, checkin_time),
        checkout: convertDateString(checkout_date, checkout_time),
        is_group_booking: false,
        primary_user_info: combinedUserBookingInfo.find((item: any) =>
          getValue(item, `is_primary`, false)
        ),
        company_booking_info: {
          company_name: company_booking_info.company_name,
          company_phone: company_booking_info.company_phone,
          company_email: company_booking_info.company_email,
          gstin_no: company_booking_info.gstin_no,
          address: company_booking_info.address,
        },
        room_data: getValue(request, `room_data`, []).map((item: object) => ({
          adults: getValue(item, `adults`, 0),
          children: 0,
          room_id: parseInt(getValue(params, `roomName`, "")),
          room_type_id: parseInt(getValue(params, `typeId`, "")),
          rate_plan_id: getValue(item, `rate_plan_id`, ""),
          is_same_as_primary_user: getValue(
            item,
            `is_same_as_primary_user`,
            false
          ),
          user_booking_info: combinedUserBookingInfo.filter(
            (item: any) => !getValue(item, `is_primary`, false)
          ),
        })),
      };
      const cleanedPayload = cleanObject(updatedPayload);
      const resp = await createPropertyBooking(cleanedPayload);
      if (resp) {
        toast.success("Booking created successfully");
        navigate(
          `/${getValue(params, "orgId", "")}/property/details/${getValue(
            params,
            "id",
            ""
          )}/reservations`
        );
      }
    } catch (error) {
      // Handle any error that occurs during submission
      console.error("Error creating booking:", error);
    } finally {
      // Stop the loading spinner
      setSubmitLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const [uploadLoading, setUploadLoading] = useState<{
    [key: string]: boolean;
  }>({
    identity_front_img: false,
    identity_back_img: false,
  });

  const handleUploadDocuments = async (
    index: number,
    childIndex: number,
    e: React.ChangeEvent<HTMLInputElement>,
    key: string,
    name: string,
    subName: string,
    setObject: any
  ) => {
    if (!e.target.files) return;

    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("filename", file.name);
    formData.append("is_public", "true");
    formData.append("collection", "public-images");

    try {
      setUploadLoading((prev) => ({
        ...prev,
        [`${name}-${subName}`]: true,
      }));

      const resp = await mediaUpload(formData);

      if (resp) {
        const imageUrl = `https://${config.ASSETS_CDN_ENDPOINT}/${getValue(
          resp,
          `data.key`,
          ""
        )}`;

        setObject((object: any) => {
          return {
            ...object,
            [key]: object[key].map((x: any, i: number) => {
              if (i !== index) return x;
              return {
                ...x,
                [name]: x[name].map((y: any, cIndex: number) => {
                  if (cIndex !== childIndex) return y;
                  return {
                    ...y,
                    [subName]: imageUrl,
                  };
                }),
              };
            }),
          };
        });
      }
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setUploadLoading((prev) => ({
        ...prev,
        [`${name}-${subName}`]: false,
      }));
    }
  };

  const handleAddBookingInfo = (
    type: string,
    cat: string,
    index: number,
    info: any
  ) => {
    const maxAdult = getValue(info, "max_adult", "0");
    const maxChildren = getValue(info, "max_children", "0");

    if (type === "adult") {
      if (cat === "add") {
        if (
          getValue(request, `room_data[${index}].user_booking_info.length`, 0) <
          maxAdult
        ) {
          getValue(request, `room_data[${index}].user_booking_info`, []).push({
            ...bookinginfo_obj,
            is_primary: false,
          });
          setValue(
            request,
            `room_data[${index}].adults`,
            getValue(request, `room_data[${index}].user_booking_info.length`, 0)
          );
          setRequest({ ...request });
        } else {
          toast.error(`Maximum ${maxAdult} adults allowed`);
        }
      } else {
        if (
          getValue(request, `room_data[${index}].user_booking_info.length`, 0) >
          1
        ) {
          let filtered = getValue(
            request,
            `room_data[${index}].user_booking_info`,
            []
          ).slice(0, -1);
          setValue(
            request,
            `room_data[${index}].adults`,
            getValue(filtered, `length`, 0)
          );
          setRequest((object: any) => {
            return {
              ...object,
              ["room_data"]: object["room_data"].map(
                (x: any, index: number) => {
                  return {
                    ...x,
                    user_booking_info: filtered,
                  };
                }
              ),
            };
          });
        } else {
          toast.error("At least 1 adult is required");
        }
      }
    } else if (type === "children") {
      if (cat === "add") {
        if (
          getValue(
            request,
            `room_data[${index}].child_user_booking_info.length`,
            0
          ) < maxChildren
        ) {
          getValue(
            request,
            `room_data[${index}].child_user_booking_info`,
            []
          ).push({
            ...bookinginfo_obj,
            is_primary: false,
          });
          setValue(
            request,
            `room_data[${index}.children`,
            getValue(
              request,
              `room_data[${index}].child_user_booking_info.length`,
              0
            )
          );
          setRequest({ ...request });
        } else {
          toast.error(`Maximum ${maxAdult} child allowed`);
        }
      } else {
        if (
          getValue(
            request,
            `room_data[${index}].child_user_booking_info.length`,
            0
          ) > 0
        ) {
          let filtered = getValue(
            request,
            `room_data[${index}].child_user_booking_info`,
            []
          ).slice(0, -1);
          setValue(request, `room_data[${index}.children`, filtered.length);
          setRequest((object: any) => {
            return {
              ...object,
              ["room_data"]: object["room_data"].map(
                (x: any, index: number) => {
                  return {
                    ...x,
                    child_user_booking_info: filtered,
                  };
                }
              ),
            };
          });
        }
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                  Tab section                               */
  /* -------------------------------------------------------------------------- */
  const [current, setCurrent] = useState(0);
  const onChange = (value: number) => {
    if (selectedRoomTypes.length > 0) {
      setCurrent(value);
    } else {
      toast.error("Please select room types");
    }
  };
  const CustomLabel = (item: any) => {
    return (
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <BuildFilled style={{ color: "#408dfb" }} />
            <h6 className="header_text__16 color_primary ms-2">
              {formatString(item?.name)}
            </h6>
          </div>
          <h6 className="header_text__14">
            Starting at
            <span className="color_primary ms-2">
              Rs.{getValue(item, `min_price`, "")}
            </span>
          </h6>
        </div>
      </div>
    );
  };
  const CustomContent = (item: any) => {
    return (
      <div>
        <h6 className="mt-4 header_text__16">
          Number of Rooms : {getValue(item, `room.length`, 0)}
        </h6>
        <div className="d-flex flex-wrap gap-1 mt-2">
          {getValue(item, `room`, []).map((items: object) => {
            return (
              <>
                <div
                  className={`room_booking_container ${
                    selectedRooms.includes(getValue(items, `id`, ""))
                      ? "active"
                      : ""
                  }`}
                  onClick={() => handleSelectRooms(getValue(items, `id`, ""))}
                >
                  {getValue(items, `room_id`, "")}
                </div>
              </>
            );
          })}
        </div>
      </div>
    );
  };
  const [items, setItems]: any = useState([]);

  const [selectedRooms, setSelectedRooms] = useState<any>([]);
  /* -------------------------------------------------------------------------- */
  /*                              Room Type section                             */
  /* -------------------------------------------------------------------------- */
  const [activeRoomTypeTab, setActiveRoomTypeTab] = useState(selectedRooms[0]);
  const handleChangeRoomTypeTab = (key: any) => {
    setActiveRoomTypeTab(key);
  };
  const findRoomTypeInfo = (id: any) => {
    let info = rooms.find((item: object) => getValue(item, `id`, "") === id);
    return info;
  };

  const [selectedRoomTypes, setSelectedRoomTypes] = useState<any>([]);
  const handleSelectRoomTypes = (id: string) => {
    let info = selectedRoomTypes.find((item: any) => item == id);
    if (info) {
      let filtred = selectedRoomTypes.filter((item: any) => item !== id);
      setSelectedRoomTypes(filtred);
    } else {
      selectedRoomTypes.push(id);
      setSelectedRoomTypes([...selectedRoomTypes]);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                                  Room section                              */
  /* -------------------------------------------------------------------------- */

  const [activeRoomTab, setActiveRoomTab] = useState<any>(null);
  const handleChangeRoomTab = (key: string) => {
    setActiveRoomTab(key);
  };
  const findRoomListInfo = (room: any, roomTypeInfo: any) => {
    let info = roomTypeInfo.room.find(
      (item: object) => getValue(item, `id`, "") === room
    );
    return info;
  };

  const handleSelectRooms = (id: string) => {
    let info = selectedRooms.find((item: any) => item == id);
    if (info) {
      let filtred = selectedRooms.filter((item: any) => item !== id);
      setSelectedRooms(filtred);
    } else {
      selectedRooms.push(id);
      request.room_data.push({
        adults: 1,
        children: 0,
        room_type_id: 0,
        room_id: 0,
        rate_plan_id: "",
        user_booking_info: [
          {
            ...bookinginfo_obj,
            is_primary: false,
          },
        ],
        child_user_booking_info: [],
        is_same_as_primary_user: true,
        payment_method: "cash",
        booking_amount: 0,
        payment_remarks: "",
      });
      setRequest({ ...request });
      setSelectedRooms([...selectedRooms]);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                                  Guest section                             */
  /* -------------------------------------------------------------------------- */
  const handleCarouselTab = (tab: number) => {
    setActiveCarouselIndex(tab);
  };
  const handleChildCarouselTab = (tab: number) => {
    setActiveChildCarouselIndex(tab);
  };
  const [activeTab, setActiveTab] = useState("1");

  const views = [
    { id: "1", name: "Adult details" },
    { id: "2", name: "Child details" },
    // { id: "3", name: "Company Booking Info" },
  ];
  const handleChangeTab = (key: string) => {
    setActiveTab(key);
  };

  return (
    <SidebarLayout props={props}>
      <div className={"company-profile-subheader-wrapper"}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <div
              onClick={() =>
                navigate(
                  `/${getValue(
                    params,
                    "orgId",
                    ""
                  )}/property/details/${getValue(
                    params,
                    "id",
                    ""
                  )}/reservations?${allParams()}`
                )
              }
            >
              <BackSvgComponent />
            </div>
            <div className="small_text__16 ms-3 ">Create Group Booking</div>
          </div>
          <ButtonComponent
            buttonType="primary"
            buttonText={
              submitLoading
                ? "Please wait..."
                : current === 0
                ? "Save & Next"
                : "Create Group Booking"
            }
            disabled={
              getValue(selectedRoomTypes, `length`, 0) === 0 ? true : false
            }
            onClickHandler={() =>
              current === 0 ? setCurrent(1) : handleSubmit()
            }
          />
        </div>
      </div>
      <div className="w-50 mt-4 m-auto">
        <Steps
          current={current}
          onChange={onChange}
          items={[
            {
              title: "Select Rooms",
              // description: "Select Rooms",
            },
            {
              title: "Finish Booking",
              // description: "Finish Booking",
            },
          ]}
        />
      </div>
      <div className="manageFieldRight__groupbooking w-100 p-5 overflow-y-auto">
        {current == 0 ? (
          <>
            <div className="ms-5">
              <CheckinOutForm
                request={request}
                setRequest={setRequest}
                checkinSlots={checkinSlots}
                checkoutSlots={checkoutSlots}
                width={"200px"}
              />
            </div>
            <div className="d-flex justify-content-center mt-3 flex-column align-items-center">
              {getValue(items, `length`, 0) > 0 &&
                items.map((item: object) => {
                  return (
                    <div className="border border-radius__5 p-3  mb-3 w-75">
                      {CustomLabel(item)}
                      {CustomContent(item)}
                      <div className="d-flex justify-content-between">
                        <div></div>
                        <Button
                          type="primary"
                          icon={
                            selectedRoomTypes.includes(
                              getValue(item, `id`, "")
                            ) ? (
                              <CheckOutlined style={{ color: "white" }} />
                            ) : (
                              <PlusOutlined style={{ color: "white" }} />
                            )
                          }
                          onClick={() =>
                            handleSelectRoomTypes(getValue(item, `id`, ""))
                          }
                        >
                          <span style={{ color: "white" }}>
                            {selectedRoomTypes.includes(
                              getValue(item, `id`, "")
                            )
                              ? "Added"
                              : "Add"}
                          </span>
                        </Button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        ) : (
          <>
            <Tabs
              activeKey={activeRoomTypeTab}
              onChange={handleChangeRoomTypeTab}
              type="card"
            >
              {Array.from(selectedRoomTypes).map((item: any, index) => {
                const info: any = findRoomTypeInfo(item);
                return (
                  <TabPane tab={getValue(info, "name", "")} key={index}>
                    <div className="w-100 overflow-y-auto">
                      <div className="d-flex flex-wrap mb-2 gap-2">
                        {getValue(info, "room", []).map(
                          (room: object, roIndex: number) => (
                            <div
                              key={getValue(room, "id", "")}
                              className={`room_booking_container ${
                                selectedRooms.includes(getValue(room, "id", ""))
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => {
                                setActiveRoomTab(roIndex.toString());
                                handleSelectRooms(getValue(room, "id", ""));
                              }}
                            >
                              <BedRoomSvgComponent
                                color={
                                  selectedRooms.includes(
                                    getValue(room, "id", "")
                                  )
                                    ? "white"
                                    : ""
                                }
                              />
                              <span className="ms-2"></span>
                              Room - {getValue(room, "room_id", "")}
                            </div>
                          )
                        )}
                      </div>

                      {getValue(request, `room_data`, []).length > 0 ? (
                        <Tabs
                          activeKey={activeRoomTab}
                          onChange={handleChangeRoomTab}
                        >
                          {getValue(request, `room_data`, []).map(
                            (room: any, roomIndex: number) => {
                              const roomInfo = findRoomListInfo(room, info);
                              return (
                                <TabPane
                                  tab={`Room ${getValue(
                                    roomInfo,
                                    "room_id",
                                    ""
                                  )}`}
                                  key={roomIndex}
                                >
                                  <div className="py-4">
                                    <div className="d-flex align-items-center">
                                      <CounterButtons
                                        label="Adults"
                                        value={getValue(room, `adults`, "")}
                                        maxValue={getValue(
                                          info,
                                          "max_adult",
                                          ""
                                        )}
                                        onAdd={() =>
                                          handleAddBookingInfo(
                                            "adult",
                                            "add",
                                            roomIndex,
                                            info
                                          )
                                        }
                                        onRemove={() =>
                                          handleAddBookingInfo(
                                            "adult",
                                            "remove",
                                            roomIndex,
                                            info
                                          )
                                        }
                                        disableRemove={
                                          getValue(room, `adults`, "") === 1
                                        }
                                        disableAdd={
                                          getValue(room, `adults`, "") ===
                                          getValue(info, `max_adult`, 0)
                                        }
                                      />
                                      <CounterButtons
                                        label="Children"
                                        value={getValue(room, `children`, "")}
                                        maxValue={getValue(
                                          info,
                                          "max_children",
                                          ""
                                        )}
                                        onAdd={() =>
                                          handleAddBookingInfo(
                                            "children",
                                            "add",
                                            roomIndex,
                                            info
                                          )
                                        }
                                        onRemove={() =>
                                          handleAddBookingInfo(
                                            "children",
                                            "remove",
                                            roomIndex,
                                            info
                                          )
                                        }
                                        disableRemove={
                                          getValue(room, `children`, "") === 0
                                        }
                                        disableAdd={
                                          getValue(room, `children`, "") ===
                                          getValue(info, `max_children`, 0)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="grid-containe d-flex gap-3 w-100">
                                    <div className="w-70">
                                      <Tabs
                                        activeKey={activeTab}
                                        onChange={handleChangeTab}
                                        type="card"
                                      >
                                        {views.map((item, viewIndex) => (
                                          <TabPane
                                            tab={item.name}
                                            key={item.id}
                                          >
                                            {item.id === "1" && (
                                              <>
                                                <div className="mt-3">
                                                  <CarouselNavViews
                                                    length={getValue(
                                                      room,
                                                      `adults`,
                                                      0
                                                    )}
                                                    activeTab={
                                                      activeCarouselIndex
                                                    }
                                                    handleTab={
                                                      handleCarouselTab
                                                    }
                                                    showName
                                                    addHeading="Guest"
                                                  />
                                                </div>
                                                <div className="add-box-shadow">
                                                  <BookingInfoForm
                                                    index={roomIndex}
                                                    childIndex={
                                                      activeCarouselIndex
                                                    }
                                                    item={getValue(
                                                      room,
                                                      `user_booking_info[${activeCarouselIndex}]`,
                                                      {}
                                                    )}
                                                    name="user_booking_info"
                                                    key_name="room_data"
                                                    setRequest={setRequest}
                                                    handleUploadDocuments={
                                                      handleUploadDocuments
                                                    }
                                                    uploadLoading={
                                                      uploadLoading
                                                    }
                                                  />
                                                </div>
                                              </>
                                            )}
                                            {item.id === "2" && (
                                              <>
                                                {getValue(
                                                  room,
                                                  `children`,
                                                  ""
                                                ) <= 0 ? (
                                                  <p className="small_text__14 my-3 text-center py-4">
                                                    Please add a child before
                                                    providing additional details
                                                  </p>
                                                ) : (
                                                  <>
                                                    <div className="mt-3">
                                                      <CarouselNavViews
                                                        length={getValue(
                                                          room,
                                                          `children`,
                                                          0
                                                        )}
                                                        activeTab={
                                                          activeChildCarouselIndex
                                                        }
                                                        handleTab={
                                                          handleChildCarouselTab
                                                        }
                                                        showName
                                                        addHeading="Child"
                                                      />
                                                    </div>
                                                    <div className="add-box-shadow">
                                                      <BookingInfoForm
                                                        index={roomIndex}
                                                        childIndex={
                                                          activeChildCarouselIndex
                                                        }
                                                        item={getValue(
                                                          room,
                                                          `child_user_booking_info[${activeChildCarouselIndex}]`,
                                                          {}
                                                        )}
                                                        name="child_user_booking_info"
                                                        key_name="room_data"
                                                        setRequest={setRequest}
                                                        handleUploadDocuments={
                                                          handleUploadDocuments
                                                        }
                                                        uploadLoading={
                                                          uploadLoading
                                                        }
                                                      />
                                                    </div>
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </TabPane>
                                        ))}
                                      </Tabs>
                                    </div>
                                    <div className="add-box-shadow w-30 mt-5">
                                      <h3 className="header_text__18">
                                        Price details
                                      </h3>
                                      <div className="d-flex flex-column gap-3 my-3">
                                        <div>
                                          <SearchToolTip
                                            label="label"
                                            data={rateplanList}
                                            selectKey="value"
                                            value={getValue(
                                              request,
                                              `room_data[${activeRoomIndex}].rate_plan_id`,
                                              ""
                                            )}
                                            onChange={(e: any) =>
                                              handleSelectChange(
                                                e,
                                                "rate_plan_id",
                                                request,
                                                setRequest
                                              )
                                            }
                                            name="Rate Plan"
                                          />
                                          <div className="mx-1 mt-2">
                                            <InputRuleForm
                                              inputType="TEXT"
                                              name="rate_plan_price"
                                              value={getValue(
                                                ratePlanRequest,
                                                `rate_plan_price`,
                                                ""
                                              )}
                                              placeholder="Enter Rate Plan Price"
                                              label="Rate Plan Price"
                                              disabled
                                            />
                                          </div>
                                          <div className="mx-1">
                                            <InputRuleForm
                                              inputType="TEXT"
                                              name="total_tax"
                                              value={getValue(
                                                ratePlanRequest,
                                                `total_tax`,
                                                ""
                                              )}
                                              placeholder="Enter Total Tax"
                                              label="Total Tax"
                                              disabled
                                            />
                                          </div>
                                          <div className="mx-1">
                                            <InputRuleForm
                                              inputType="TEXT"
                                              name="total_after_tax"
                                              value={getValue(
                                                ratePlanRequest,
                                                `total_after_tax`,
                                                ""
                                              )}
                                              placeholder="Enter Total After tax"
                                              label="Total After tax"
                                              disabled
                                            />
                                          </div>
                                          <div className="mx-1">
                                            <InputRuleForm
                                              inputType="TEXT"
                                              name="total_tax"
                                              value={getValue(
                                                ratePlanRequest,
                                                `total_tax`,
                                                ""
                                              )}
                                              placeholder="Enter Total tax"
                                              label="Total tax"
                                              disabled
                                            />
                                          </div>
                                          <div className="mx-1">
                                            <InputRuleForm
                                              inputType="TEXT"
                                              name="sub_total"
                                              value={getValue(
                                                ratePlanRequest,
                                                `sub_total`,
                                                ""
                                              )}
                                              placeholder="Enter Sub Total"
                                              label="Sub Total"
                                              disabled
                                            />
                                          </div>
                                          <div className="mx-1">
                                            <InputRuleForm
                                              inputType="TEXT"
                                              name="total"
                                              value={getValue(
                                                ratePlanRequest,
                                                `total`,
                                                ""
                                              )}
                                              placeholder="Enter Total"
                                              label="Total"
                                              disabled
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </TabPane>
                              );
                            }
                          )}
                        </Tabs>
                      ) : (
                        <p className="text-center mt-5">
                          Please select a room to continue
                        </p>
                      )}
                    </div>
                  </TabPane>
                );
              })}
            </Tabs>
            <h6 className="header_text__6">Company Booking Info</h6>
            <CompanyInfoForm request={request} setRequest={setRequest} />
          </>
        )}
      </div>
    </SidebarLayout>
  );
};

export default CreateGroupBooking;
