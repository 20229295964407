import React from 'react';
import { Card, Typography, Space, Tag, Button, Tooltip, Divider } from 'antd';
import { CopyOutlined, DeleteOutlined, ArrowRightOutlined } from '@ant-design/icons';
import BrandingSvgComponent from "assets/svg/branding";
import PhoneSvgComponent from "assets/svg/custom/phone";
import RemoveCircleSvgComponent from "assets/svg/remove-circle";
import CopySvgComponents from "assets/svg/copy";
import { CopyToClipboard } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import PersonSvgComponent from "assets/svg/person";
import GraphSvgComponent from "assets/svg/graph";
import MailSvgComponent from "assets/svg/mail";
import DateSvgComponent from "@assets/svg/custom/date";
import { convertCurrentDate } from "@common/date-helpers";
import CurrencySvgComponent from "@assets/svg/custom/currency";
import { useStateContext } from "@context/dataContext";


const { Title, Text } = Typography;

function DescriptionInfo(props: any) {
  const { index, item } = props;
  const navigate = useNavigate();
  const params = useParams();
  const { orgInfo } = useStateContext();

  const handleNavigate = (id: string) => {
    navigate(
      `/${getValue(params, `orgId`, "")}/crm/${getValue(
        props,
        `module`,
        ""
      )}/detail/${id}`
    );
  };

  const getModuleIcon = (module: string) => {
    switch (module) {
      case "contacts":
        return <PersonSvgComponent size={22} color={"#1890ff"} />;
      case "sales":
      case "deals":
        return <GraphSvgComponent size={22} color={"#1890ff"} />;
      default:
        return <BrandingSvgComponent size={20} color={"#1890ff"} />;
    }
  };

  const module = getValue(props, `module`, "");
  const id = getValue(item, `id`, "");
  const firstName = getValue(item, `first_name`, "");
  const lastName = getValue(item, `last_name`, "");
  const name = getValue(item, `name`, "");
  const email = getValue(item, `email`, "");
  const phoneNumber = getValue(item, `phone_number`, "");
  const mobileNumber = getValue(item, `mobile_number`, "");
  const closingDate = getValue(item, `closing_date`, "");
  const amount = getValue(item, `amount`, "");
  const currency = getValue(orgInfo, `currency`, "");

  const displayName = name || (firstName || lastName ? `${firstName} ${lastName}`.trim() : "Unknown");
  
  const getModuleTag = () => {
    switch(module) {
      case "contacts":
        return <Tag color="blue">Contact</Tag>;
      case "sales":
        return <Tag color="green">Sale</Tag>;
      case "deals":
        return <Tag color="purple">Deal</Tag>;
      default:
        return <Tag color="default">{module}</Tag>;
    }
  };

  return (
    <Card 
      className="description-info-card"
      key={`pending-${index}`}
      actions={[
        <Tooltip title="View Details">
          <Button 
            type="link" 
            icon={<ArrowRightOutlined />} 
            onClick={() => handleNavigate(id)}
          >
            Details
          </Button>
        </Tooltip>,
        <Tooltip title="Remove">
          <Button 
            type="link" 
            // danger
            icon={<DeleteOutlined />} 
            onClick={() => {
              props.setRollsListPopupVisisbility(false);
              props.handleDeleteView(id, displayName);
            }}
          >
            Remove
          </Button>
        </Tooltip>
      ]}
    >
      <div className="description-info-header">
        <Space>
          {getModuleIcon(module)}
          <Title level={5} className="description-info-title" onClick={() => handleNavigate(id)}>
            {displayName}
          </Title>
        </Space>
        {getModuleTag()}
      </div>

      {(email || phoneNumber || mobileNumber || closingDate || amount) && <Divider className="description-info-divider" />}

      <div className="description-info-content">
        {email && (
          <div className="info-item">
            <Space>
              <MailSvgComponent size={16} color={"#1890ff"} />
              <Text type="secondary">{email}</Text>
              <Tooltip title="Copy Email">
                <Button 
                  type="text" 
                  size="small" 
                  icon={<CopyOutlined />} 
                  onClick={() => CopyToClipboard(email)}
                />
              </Tooltip>
            </Space>
          </div>
        )}

        {phoneNumber && (
          <div className="info-item">
            <Space>
              <PhoneSvgComponent size={16} color={"#1890ff"} />
              <Text type="secondary">{phoneNumber}</Text>
            </Space>
          </div>
        )}

        {mobileNumber && (
          <div className="info-item">
            <Space>
              <PhoneSvgComponent size={16} color={"#1890ff"} />
              <Text type="secondary">{mobileNumber}</Text>
            </Space>
          </div>
        )}

        {closingDate && (
          <div className="info-item">
            <Space>
              <DateSvgComponent size={16} color={"#1890ff"} />
              <Text type="secondary">Closing: </Text>
              <Text strong>{convertCurrentDate(closingDate)}</Text>
            </Space>
          </div>
        )}

        {amount && (
          <div className="info-item">
            <Space>
              <CurrencySvgComponent size={16} color={"#1890ff"} />
              <Text strong>{currency} {amount}</Text>
            </Space>
          </div>
        )}
      </div>
    </Card>
  );
}

export default DescriptionInfo;