import "./SubHeader.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BackSvgComponent from "@assets/svg/back-link";
import { ChevronFirst, ChevronLast, Star } from "lucide-react";
import { Button, Segmented, Select, Tooltip } from "antd";
import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import { getValue } from "@utils/lodash";
import {
  createBookmarkedModules,
  deleteBookmark,
} from "@services/bookmark.service";
import { capitalizeFirstLetter } from "@common/text-helpers";

export default function TicketSubHeader({
  subHeaderListTitle,
  isRightArrowVisible,
  subMenuArray,
  children,
  showBack,
  handleNavigateBack,
  addButton,
  toggleSidebar,
  hideView,
  params,
  bookmarkedModules,
  themeColor,
  refreshBookmarks,
  bookmarkedModuleName,
  sidebar,
  tabs,
  isSelected,
  UrlParams,
  handleNaviagteView,
  hideBookmark,
  hideTicketView,
}: any) {
  const navigate = useNavigate();
  const location = useLocation();

  const currentView = location.pathname.includes("kanban") ? "kanban" : "card";

  const createBookmark = async () => {
    let resp = await createBookmarkedModules({
      name: capitalizeFirstLetter(bookmarkedModuleName),
      path: bookmarkedModuleName,
    });
    if (resp) {
      refreshBookmarks();
    }
  };

  const removeBookmark = async (id: string) => {
    let resp = await deleteBookmark(id);
    if (resp) {
      refreshBookmarks();
    }
  };
  return (
    <div className={`sub-header-wrapper`}>
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="d-flex align-items-center">
          {showBack && (
            <ul onClick={handleNavigateBack}>
              <li className={`gap d-flex align-items-center cursor-pointer`}>
                <BackSvgComponent />
              </li>
            </ul>
          )}
          <ul>
            <li className={`gap d-flex align-items-center `}>
              {!hideBookmark &&
                (bookmarkedModuleName &&
                bookmarkedModules.find(
                  (bookmarkItem: any) =>
                    bookmarkItem.path === bookmarkedModuleName
                ) ? (
                  <Tooltip title="Remove from bookmarks">
                    <Button
                      color="default"
                      variant="text"
                      onClick={() =>
                        removeBookmark(
                          bookmarkedModules.find(
                            (bookmarkItem: any) =>
                              bookmarkItem.path === bookmarkedModuleName
                          ).id
                        )
                      }
                      icon={
                        <Star color={themeColor} fill={themeColor} size={18} />
                      }
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Add to bookmarks">
                    <Button
                      color="primary"
                      variant="text"
                      onClick={createBookmark}
                      icon={<Star size={18} color={themeColor} />}
                    />
                  </Tooltip>
                ))}

              <h6 className={`submenu-name ${showBack ? "ms-3" : ""}`}>
                {subHeaderListTitle}
              </h6>

              {isRightArrowVisible && (
                <img
                  src="/images/header/right-arrow.svg"
                  className="img-fluid"
                  alt="right-arrow"
                />
              )}
            </li>
          </ul>
          {getValue(UrlParams, `kanban`, "") ? (
            <Select
              size="large"
              style={{ width: 280 }}
              placeholder="Select View"
              options={tabs}
              value={isSelected}
              onChange={(value: any) => {
                handleNaviagteView("filter_status", value);
              }}
            />
          ) : (
            !hideTicketView && (
              <Button
                color="primary"
                variant="text"
                onClick={() => toggleSidebar()}
                icon={
                  sidebar ? (
                    <ChevronLast size={18} />
                  ) : (
                    <ChevronFirst size={18} />
                  )
                }
                iconPosition="end"
              >
                Ticket View
              </Button>
            )
          )}
          {subMenuArray?.length >= 0 && (
            <ul className={"header-wrapper__navigation-list"}>
              {subMenuArray.map((subMenuObj: any, index: any) => {
                return (
                  <li
                    className={"sub-header-wrapper__navigation-list-items"}
                    key={`subMenu-${index}`}
                  >
                    <Link
                      to={subMenuObj.linkUrl}
                      className={`sub-header-wrapper__sub-navigation-title ${
                        window.location.pathname === subMenuObj.linkUrl
                          ? `active`
                          : ""
                      }`}
                    >
                      {subMenuObj.linkText}
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        {children}
        {!hideView && (
          <Segmented
            value={currentView}
            options={[
              {
                value: "card",
                icon: (
                  <Tooltip title="Card View" placement="bottom">
                    <BarsOutlined />
                  </Tooltip>
                ),
              },
              {
                value: "kanban",
                icon: (
                  <Tooltip title="Kanban View" placement="bottom">
                    <AppstoreOutlined />
                  </Tooltip>
                ),
              },
            ]}
            onChange={(value: any) => {
              if (value === "card") {
                navigate(`/${getValue(params, "orgId", "")}/tickets`);
              } else {
                navigate(`/${getValue(params, "orgId", "")}/tickets/kanban`);
              }
            }}
          />
        )}
      </div>
      {addButton && addButton}
    </div>
  );
}
