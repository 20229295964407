import AccountCircleSvgComponent from "@assets/svg/account-circle";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  formatString,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import { getAllChatbots } from "@services/chatbot.service";
import { updateLiveChatConversation } from "@services/live-chat.service";
import { getOrganisationUsers } from "@services/organisations.service";
import { getValue } from "@utils/lodash";
import { Badge, Button, Dropdown, MenuProps, Select } from "antd";
import { ChevronsUpDown, Phone } from "lucide-react";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import QuickCallModel from "./quick-call-model";
import { useSocketContext } from "@context/socketContext";

function ChatRightHeader(props: any) {
  const {
    handleToggleWidth,
    selectedContact,
    selectedConversationInfo,
    expanded,
    stageList,
  } = props;
  const { getAllCount, chatInfo } = useSocketContext();
  /* -------------------------------------------------------------------------- */
  /*                           Dropdown  Listing Section                        */
  /* -------------------------------------------------------------------------- */
  /* ---------------------------  OrgUsers section ---------------------------- */
  useEffect(() => {
    getActiveUsersData();
    getChatbotData();
  }, []);
  const [orgUsers, setOrgUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const getActiveUsersData = async () => {
    if (getValue(orgUsers, `length`, 0) === 0) {
      let payload = {
        page_size: 100,
        page_no: 1,
      };
      let queryRequest = QueryRequestHelper(payload);
      try {
        setLoading(true);
        let resp = await getOrganisationUsers(queryRequest);
        if (resp) {
          setOrgUsers(
            getValue(resp, `data.org_users.length`, 0) > 0 &&
              getValue(resp, `data.org_users`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `id`, ""),
                name:
                  getValue(item, `first_name`, "") +
                  " " +
                  getValue(item, `last_name`, ""),
                label:
                  getValue(item, `first_name`, "") +
                  " " +
                  getValue(item, `last_name`, ""),
              }))
          );
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };
  /* ---------------------------  ChatbotList section ---------------------------- */

  const [chatbotList, setChatbotList] = useState([]);
  const [AiAgents, setAiAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const getChatbotData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        page_size: 100,
        page_no: 1,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllChatbots(queryRequest);
      if (resp) {
        const list1 =
          getValue(resp, `data.chatbots.length`, 0) > 0
            ? getValue(resp, `data.chatbots`, []).filter(
                (item: object) => getValue(item, `platform`, "") === "WHATSAPP"
              )
            : [];

        const list2 =
          getValue(resp, `data.chatbots.length`, 0) > 0
            ? getValue(resp, `data.chatbots`, []).filter(
                (item: object) => getValue(item, `type`, "") === "ai-chatbot"
              )
            : [];
        setAiAgents(
          list2.map((item: object) => ({
            ...item,
            label: getValue(item, `name`, ""),
            value: getValue(item, `id`, ""),
          }))
        );
        setChatbotList(
          list1.map((item: object) => ({
            ...item,
            label: getValue(item, `name`, ""),
            value: getValue(item, `id`, ""),
          }))
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                           Status Update Section                            */
  /* -------------------------------------------------------------------------- */

  const generateCommonFields = () => {
    const source = getValue(selectedConversationInfo, `source`, "");
    const commonFields: Record<string, any> = {
      contact_id: getValue(selectedConversationInfo, `contact_id`, ""),
      lead_id: getValue(selectedConversationInfo, `lead_id`, ""),
    };
    if (source === "webchat") {
      commonFields.web_name = getValue(
        selectedConversationInfo,
        `web_name`,
        ""
      );
      commonFields.web_phone = getValue(
        selectedConversationInfo,
        `web_phone`,
        ""
      );
      commonFields.web_email = getValue(
        selectedConversationInfo,
        `web_email`,
        ""
      );
      commonFields.web_is_guest = getValue(
        selectedConversationInfo,
        `web_is_guest`,
        false
      );
    }
    return commonFields;
  };
  const generatePayload = (value: string, id: string) => {
    const commonFields = generateCommonFields();
    switch (value) {
      case "team":
        return {
          ...commonFields,
          conversation_status: "new",
          chatbot_id: "",
          assigned_user_id:
            id !== "team"
              ? id || getValue(selectedConversationInfo, `assigned_user_id`, "")
              : "",
          managed_by: "team",
        };

      case "reopen":
        return {
          ...commonFields,
          conversation_status: "new",
          // assigned_user_id:
          //   id || getValue(selectedConversationInfo, `assigned_user_id`, ""),
          // managed_by: getValue(selectedConversationInfo, `managed_by`, "")
          //   ? getValue(selectedConversationInfo, `managed_by`, "")
          //   : "team",
        };

      case "bot":
        return {
          ...commonFields,
          // conversation_status: "assigned-chatbot",
          chatbot_id:
            id || getValue(selectedConversationInfo, `chatbot_id`, ""),
          managed_by: "chatbot",
        };

      case "ai":
        return {
          ...commonFields,
          // conversation_status: "assigned-chatbot",
          chatbot_id:
            id || getValue(selectedConversationInfo, `chatbot_id`, ""),
          managed_by: "ai-chatbot",
        };
      case "user":
        return {
          ...commonFields,
          // conversation_status: "assigned",
          chatbot_id: "",
          assigned_user_id:
            id || getValue(selectedConversationInfo, `assigned_user_id`, ""),
          managed_by: "team",
        };
      case "resolved":
        return {
          ...commonFields,
          conversation_status: "closed",
          // chatbot_id: getValue(selectedConversationInfo, `chatbot_id`, ""),
          // assigned_user_id: getValue(
          //   selectedConversationInfo,
          //   `assigned_user_id`,
          //   ""
          // ),
          // managed_by: getValue(selectedConversationInfo, `managed_by`, "")
          //   ? getValue(selectedConversationInfo, `managed_by`, "")
          //   : "team",
        };
      default:
        return {
          ...commonFields,
          conversation_status: value,
        };
    }
  };
  const handleChangeMultiLevel = async (level: any, value: any, e: any) => {
    const actualLevel = level.split("-")[0];

    try {
      let resp = await updateLiveChatConversation(
        getValue(selectedConversationInfo, `id`, ""),
        removeNullOrUndefinedProperties(generatePayload(actualLevel, value))
      );
      if (resp) {
        toast.success("Assigned successfully");
        props.getData(false);
        handleeResetConversation();
        getAllCount()
      }
    } catch (error) {}
  };
  const handleUpdateConvesation = async (status: string) => {
    try {
      let resp = await updateLiveChatConversation(
        getValue(selectedConversationInfo, `id`, ""),
        removeNullOrUndefinedProperties(generatePayload(status, ""))
      );
      if (resp) {
        toast.success(`updated successfully`);
        props.getData(false);
        handleeResetConversation();
        // if (getValue(chatInfo, "unread_count", 0) > 0) {
          getAllCount();
        // }
        // props.handleClickFilter(status);
      }
    } catch (error) {}
  };
  const handleChatbotConvesation = async (status: string) => {
    try {
      let resp = await updateLiveChatConversation(
        getValue(selectedConversationInfo, `id`, ""),
        removeNullOrUndefinedProperties(generatePayload(status, ""))
      );
      if (resp) {
        // props.setSelectedConversationInfo({});
        // props.setSelectedConversation("");
        // props.setSelectedContact({});
        toast.success(`updated successfully`);
        props.getData(false);
        handleeResetConversation();
        getAllCount();
      }
    } catch (error) {}
  };
  const determineInitialSelectedId = (conversationInfo: any) => {
    const managedBy = getValue(conversationInfo, "managed_by", "");
    const orgUserId = getValue(conversationInfo, "assigned_user_id", "");
    const chatBotId = getValue(conversationInfo, "chatbot_id", "");
    // Priority order for selection
    switch (true) {
      case managedBy === "team" && !orgUserId:
        return "team";

      case managedBy === "team" && orgUserId:
        return "orgUserId";

      case managedBy === "ai-chatbot":
      case managedBy === "chatbot":
        return chatBotId;
      case !!orgUserId:
        return orgUserId;
      case !managedBy:
        return "team";
      default:
        return "";
    }
  };

  const handleeResetConversation = () => {
    props.setSelectedConversation("");
    props.removeDependentState();
  };

  const { Option, OptGroup } = Select;

  const [isModalVisible, setIsModalVisible] = useState(false);
  // Show modal handler
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Handle modal close
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const findStageColor = (stage: string) => {
    let color =
      stageList.length > 0
        ? stageList.find((item: any) => getValue(item, `id`, "") === stage)
        : {};
    return getValue(color, `color_code`, "");
  };
  const findStageLabel = (stage: string) => {
    let label =
      stageList.length > 0
        ? stageList.find((item: any) => getValue(item, `id`, "") === stage)
        : {};
    return getValue(label, `label`, "");
  };
  const items = [
    {
      key: "waiting_on_us",
      label: "Move to Waiting on Us",
    },
    {
      key: "waiting_on_customer",
      label: "Move to Waiting on Customer",
    },
    {
      key: "on_hold",
      label: "Move to On Hold",
    },
  ];

  const onMenuClick: MenuProps["onClick"] = (e) => {
    // console.log("click", e);
  };

  return (
    <div className="conversation-header">
      <button className="header-right-section" onClick={handleToggleWidth}>
        <div className="header-name">
          <AccountCircleSvgComponent color={"#dfe5e7"} size={40} />
          <div className="header-profile-details">
            {getValue(selectedConversationInfo, `contact.first_name`, "") ||
            getValue(selectedConversationInfo, `contact.last_name`, "") ||
            getValue(selectedConversationInfo, `web_name`, "") ? (
              <p
                className="small_text__14 color_black"
                style={{ textAlign: "left" }}
              >
                {formatString(
                  getValue(selectedConversationInfo, `contact.first_name`, "")
                )}{" "}
                {formatString(
                  getValue(selectedConversationInfo, `contact.last_name`, "")
                )}{" "}
                {formatString(
                  getValue(selectedConversationInfo, `web_name`, "")
                )}
              </p>
            ) : (
              <p
                className="small_text__14 color_black"
                style={{ textAlign: "left" }}
              >
                {formatString(
                  getValue(selectedConversationInfo, `wa_profile_name`, "")
                )}
              </p>
            )}
            <p className="small_text__12 mt-1 color_desc d-flex align-items-center gap-2">
              {getValue(selectedConversationInfo, `wa_id`, "")
                ? getValue(selectedConversationInfo, `wa_id`, "")
                : "Click here for more contact info"}

              <div
                className="d-flex align-items-center gap-2"
                style={{
                  color: findStageColor(
                    getValue(
                      selectedConversationInfo,
                      "contact.pipeline_stage_id",
                      ""
                    ) ||
                      getValue(selectedConversationInfo, "lead.pipeline_id", "")
                  ),
                }}
              >
                <Badge
                  color={findStageColor(
                    getValue(
                      selectedConversationInfo,
                      "contact.pipeline_stage_id",
                      ""
                    ) ||
                      getValue(selectedConversationInfo, "lead.pipeline_id", "")
                  )}
                />
                {findStageLabel(
                  getValue(
                    selectedConversationInfo,
                    "contact.pipeline_stage_id",
                    ""
                  ) ||
                    getValue(selectedConversationInfo, "lead.pipeline_id", "")
                )}
              </div>
            </p>
          </div>
        </div>
      </button>
      <div
        className={`d-flex align-items-center gap-2 header-right-section-collapsed`}
      >
        {(getValue(selectedConversationInfo, `contact.mobile_number`, "") ||
          getValue(selectedConversationInfo, `lead.mobile_number`, "") ||
          getValue(selectedConversationInfo, `contact.phone_number`, "") ||
          getValue(selectedConversationInfo, `lead.phone_number`, "")) && (
          <Button
            color="primary"
            variant="text"
            icon={<Phone size={18} />}
            onClick={showModal}
          />
        )}
        <Select
          suffixIcon={<ChevronsUpDown size={16} />}
          variant="borderless"
          dropdownStyle={{ minWidth: 280 }}
          placeholder="Select an option"
          value={determineInitialSelectedId(selectedConversationInfo)}
          onChange={(value: any, option: any) =>
            handleChangeMultiLevel(getValue(option, `key`, ""), value, option)
          }
          disabled={
            !getValue(props, `permissions`, []).includes("allocation") ||
            !getValue(props, `permissions`, []).includes("global_access")
          }
          className={`${expanded ? "chat-assign-select" : ""}`}
        >
          {[
            {
              title:
                "Assign to Team- This will move the conversation to unassigned status",
              key: "team",
              data: [{ id: "team", value: "team", label: "Team" }],
            },
            orgUsers.length > 0 && {
              title: "Assign to User",
              key: "user",
              data: orgUsers,
            },
            chatbotList.length > 0 && {
              title: "Assign to Bot",
              key: "bot",
              data: chatbotList,
            },
            AiAgents.length > 0 && {
              title: "Assign to AI Agent",
              key: "ai",
              data: AiAgents,
            },
          ]
            .filter((section) => section !== false)
            .map((section) => (
              <OptGroup
                key={getValue(section, `key`, "")}
                label={getValue(section, `title`, "")}
              >
                {getValue(section, `data`, []).map((item: any) => (
                  <Option
                    key={`${getValue(section, `key`, "")}-${item.id}`}
                    value={item.value}
                    data-section-key={getValue(section, `key`, "")}
                  >
                    {item.label}
                  </Option>
                ))}
              </OptGroup>
            ))}
        </Select>
        {getValue(selectedConversationInfo, `conversation_status`, "") ===
        "new" ? (
          <Dropdown.Button
            menu={{
              items,
              onClick: (e: any) => {
                // e.stopPropagation();
                getValue(selectedConversationInfo, `managed_by`, "") ===
                "ai-chatbot"
                  ? handleChatbotConvesation(getValue(e, `key`, ""))
                  : handleUpdateConvesation(getValue(e, `key`, ""));
              },
            }}
            onClick={(e: any) => {
              e.stopPropagation();
              getValue(selectedConversationInfo, `managed_by`, "") ===
              "ai-chatbot"
                ? handleChatbotConvesation("resolved")
                : handleUpdateConvesation("resolved");
            }}
          >
            Mark as Resolved
          </Dropdown.Button>
        ) : getValue(selectedConversationInfo, `conversation_status`, "") ===
          "closed" ? (
          <Button
            color="default"
            variant="outlined"
            onClick={(e: any) => {
              e.stopPropagation();
              getValue(selectedConversationInfo, `managed_by`, "") ===
              "ai-chatbot"
                ? handleChatbotConvesation("reopen")
                : handleUpdateConvesation("reopen");
            }}
            disabled={
              getValue(selectedConversationInfo, `source`, "") === "webchat"
            }
          >
            Reopen
          </Button>
        ) : (
          <Dropdown.Button
            menu={{
              items,
              onClick: (e: any) => {
                getValue(selectedConversationInfo, `managed_by`, "") ===
                "ai-chatbot"
                  ? handleChatbotConvesation(getValue(e, `key`, ""))
                  : handleUpdateConvesation(getValue(e, `key`, ""));
              },
            }}
            onClick={(e: any) => {
              e.stopPropagation();
              getValue(selectedConversationInfo, `managed_by`, "") ===
              "ai-chatbot"
                ? handleChatbotConvesation("resolved")
                : handleUpdateConvesation("resolved");
            }}
          >
            Mark as Resolved
          </Dropdown.Button>
        )}

        {/* <MultiLevelSearchToolTip
            label={"label"}
            width={"300px"}
            showAsButton
            dataSections={[
              {
                title: "Assign to Team",
                description:
                  "This will move the conversation to unassigned status",
                key: "team",
                data: [{ value: "team", label: "Team", id: "team" }],
              },
              {
                title: "Assign to User",
                key: "user",
                data: orgUsers,
                description: "Optional description",
              },
              {
                title: "Assign to Bot",
                key: "bot",
                data: chatbotList,
                description: "Optional description",
              },
              {
                title: "Assign to AI Agent",
                key: "ai",
                data: AiAgents,
                description: "Optional description",
              },
            ]}
            initialSelectedId={determineInitialSelectedId(
              selectedConversationInfo
            )}
            selectKey={"value"}
            onChange={(e: object, key: string) =>
              handleChangeMultiLevel(key, getValue(e, `id`, ""), e)
            }
          /> */}
      </div>
      <QuickCallModel
        isModalVisible={isModalVisible}
        handleCancel={handleCancel}
        showModal={showModal}
        selectedConversationInfo={selectedConversationInfo}
      />
    </div>
  );
}

export default ChatRightHeader;
