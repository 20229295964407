import React, { useState } from "react";
import "../pipeline.scss";
import { getValue } from "@utils/lodash";
import FormBuilder from "@components/Builder/FormBuilder";
import { formHandleLookeupArrayChangeByKey } from "@common/handleChange";
import ProductForm from "./product-form";
import NewSearchSelect from "@components/custom/Dropdown/AntDropDown";
import AntSearchToolTip from "@components/custom/DebounceSelect";
import { Button, Modal } from "antd";
import { SquareArrowOutUpRight } from "lucide-react";
function CreateSubPipeline(props: any) {
  const {
    module,
    handleOpenForm,
    products,
    setProducts,
    divRef,
    handleKeyDown,
    setValidPhone,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    if (productsCount === 0) {
      addProduct();
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const addProduct = () => {
    const newProduct = {
      action: "add",
      list_price: 0,
      quantity: 1,
      discount: 0,
      total: 0,
      sale_item_id: "",
    };
    setProducts([...products, newProduct]);
    setProductsCount(products.length + 1);
  };

  const [productsCount, setProductsCount] = useState(0);
  return (
    <div
      className={"subpipeline- mb-4"}
      tabIndex={0}
      ref={divRef}
      onKeyDown={handleKeyDown}
    >
      <>
        <div className="form_wrapper_builder-pipeline">
          {module !== "tasks" &&
            module !== "calls" &&
            module !== "tickets" &&
            module !== "meetings" && (
              <NewSearchSelect
                placeholder={`Choose Status`}
                validator={props.simpleValidator}
                options={getValue(props, `stageList`, [])}
                value={getValue(props, `staticFieldRequest.stage`, "")}
                onChange={(id: object) =>
                  props.setStaticFieldRequest({
                    ...props.staticFieldRequest,
                    stage: id,
                  })
                }
                selectKey={"id"}
                label={"Status"}
                required={true}
              />
            )}
        </div>
        <FormBuilder
          fields={getValue(props, `fields`, [])}
          setFields={props.setFields}
          simpleValidator={props.simpleValidator}
          singular_label={props.singular_label}
          module={props.module}
          duplicateAPINames={props.duplicateAPINames}
          setDuplicateAPINames={props.setDuplicateAPINames}
          pipeline_id={props.pipeline_id}
          setValidPhone={setValidPhone}
          // hideValidation={props.hideValidation}
        />
        {getValue(props, `association`, "") && (
          <div className={`association_container mt-5`}>
            <hr />
            <h6 className="header_text mb-4">
              Associate {getValue(props, `module`, "")} with
            </h6>

            {getValue(props, `associations`, []).map(
              (item: object, index: number) => {
                return (
                  <div className="mb-3" key={index}>
                    <div className="d-flex align-items-center justify-content-between">
                      <label className="form-label">
                        {getValue(item, `name`, "")}
                      </label>
                      <h6
                        className="header_blue_text__16"
                        onClick={() =>
                          handleOpenForm(getValue(item, `module`, ""))
                        }
                      >
                        Add {getValue(item, `name`, "")}
                      </h6>
                    </div>
                    <AntSearchToolTip
                      placeholder={`Enter ${getValue(item, `name`, "")}`}
                      // onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      //   formHandleLookeupArrayChange(index, e, setFields)
                      // }
                      onChange={(e: any, data: any) => {
                        formHandleLookeupArrayChangeByKey(
                          index,
                          "record_id",
                          data,
                          props.setAssociations
                        );
                      }}
                      value={getValue(item, `record_id`, "")}
                      label={getValue(item, `label`, "")}
                      lookup_api={getValue(item, `api_name`, "")}
                    />
                  </div>
                );
              }
            )}
          </div>
        )}
        {getValue(props, `showProducts`, false) &&
          getValue(props, `staticFieldRequest.pipeline_id`, "") &&
          (module === "deals" || module === "sales") && (
            <Button
              type="primary"
              onClick={showModal}
              className="ms-4 mt-2"
              icon={<SquareArrowOutUpRight size={16} />}
            >
              {productsCount > 0
                ? `${
                    productsCount > 1
                      ? `${productsCount} Products`
                      : "1 Product"
                  }`
                : "Add Products"}
            </Button>
          )}

        <Modal
          title="Products"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1000}
          footer={
            <Button type="primary" onClick={handleOk}>
              Save
            </Button>
          }
        >
          <ProductForm
            products={products}
            setProducts={setProducts}
            setProductsCount={setProductsCount}
            addProduct={addProduct}
          />
        </Modal>
      </>
    </div>
  );
}

export default CreateSubPipeline;
