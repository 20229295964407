import BackSvgComponent from "@assets/svg/back-link";
import ButtonComponent from "@components/Form/Button/Button";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";

const BotBuilderHeader = (props: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const { activeTab, setActiveTab, isLoading, handleSubmit } = props;
  return (
    <div>
      {activeTab !== 4 && (
        <div className={"company-profile-subheader-wrapper"}>
          <div className="d-flex align-items-center justify-content-between w-100">
            <div className="d-flex align-items-center">
              <div
                onClick={() =>
                  navigate(`/${getValue(params, `orgId`, "")}/chatbot`)
                }
              >
                <BackSvgComponent />
              </div>
              <p className="small_text__16 ms-3">Bot builder</p>
            </div>
            <div className="d-flex align-items-center">
              <>
                <ButtonComponent
                  buttonType="secondary"
                  buttonText={"Back"}
                  onClickHandler={() =>
                    navigate(`/${getValue(params, `orgId`, "")}/chatbot`)
                  }
                  // disabled={isLoading}
                />
                <ButtonComponent
                  buttonType="primary"
                  buttonText={isLoading ? "Please wait..." : "Create"}
                  onClickHandler={() => handleSubmit()}
                  // disabled={isLoading}
                />
              </>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BotBuilderHeader;
