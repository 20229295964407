import * as React from "react";
import { Button, Modal } from "antd";
import { getValue } from "@utils/lodash";

const BotCloneConfirmModal = (props: any) => {
  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      onCancel={props.handleModal}
      onOk={props.handleSubmit}
      footer={[
        <Button
          key="cancel"
          onClick={props.handleModal}
          className="cancel__button"
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={props.handleSubmit}
          className="submit__button"
        >
          Submit
        </Button>,
      ]}
    >
      <section className="">
        <div className="">
          <div className="m-3 p-3">
            <img src="/images/info-delete.png" className="delete-icon" />
            <p className="delete_text mt-1">Are you sure ?</p>
            <p className="small_text__16 mt-2">
              you want to clone
            </p>
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default BotCloneConfirmModal;
