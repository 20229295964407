import { getValue } from "@utils/lodash";
import { useState } from "react";
import { formatString } from "@common/text-helpers";
import { useNavigate, useParams } from "react-router-dom";

function SettingModuleNavbar(props: any) {
  const navigate = useNavigate();
  const params = useParams();
  const [tabs] = useState([
    {
      value: "Fields",
      name: "fields",
    },
    {
      value: "Status",
      name: "stages",
    },
  ]);

  const [tasktabs] = useState([
    {
      value: "Fields",
      name: "fields",
    },
  ]);
  return (
    <div className="position-relative">
      <ul
        className="d-flex align-items-center justify-content-between mt-4"
        style={{ borderBottom: "1px solid #efefef" }}
      >
        <div className="d-flex align-items-center justify-content-start ms-3">
          {
            // getValue(props, `code`, "") === "tickets" ||
            (getValue(props, `code`, "") === "tasks" ||
            getValue(props, `code`, "") === "calls" ||
            getValue(props, `code`, "") === "meetings"
              ? tasktabs
              : tabs
            ).map((linkObj: object, index: number) => {
              return (
                <li
                  key={`pending-${index}`}
                  className={`${
                    getValue(props, `tab`, "") == getValue(linkObj, `name`, "")
                      ? "activebar__main-body-list_active"
                      : "activebar__main-body-list"
                  }`}
                  onClick={() => {
                    getValue(props, `code`, "") === "tickets"
                      ? getValue(linkObj, `name`, "") === "fields"
                        ? navigate(
                            `/${getValue(
                              params,
                              `orgId`,
                              ""
                            )}/settings/helpdesk/ticket-fields`
                          )
                        : navigate(
                            `/${getValue(
                              params,
                              `orgId`,
                              ""
                            )}/settings/helpdesk/ticket-fields/status`
                          )
                      : props.handleNavigate(getValue(linkObj, `name`, ""));
                  }}
                >
                  <a className={"activebar__main-body-link p-3 "}>
                    {formatString(getValue(linkObj, `value`, ""))}
                  </a>
                </li>
              );
            })
          }
        </div>
      </ul>
    </div>
  );
}

export default SettingModuleNavbar;
