import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import { MoreVertical, Plus, ScrollText } from "lucide-react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getValue } from "@utils/lodash";
import {
  Alert,
  Button,
  Dropdown,
  MenuProps,
  Modal,
  Pagination,
  Select,
  Switch,
  Tabs,
  TabsProps,
} from "antd";
import { useEffect, useState } from "react";
import { QueryRequestHelper } from "@common/query-request-helper";
import { toast } from "sonner";
import {
  createAutomationRule,
  deleteAutomation,
  getAllAutomation,
  getAutomationRule,
  getAutomationTemplates,
  updateAutomationPosition,
  updateAutomationStatus,
} from "@services/automations.service";
import ListLoader from "@components/common/Loader";
import "./automations.scss";
import { capitalizeFirstLetter, formatString } from "@common/text-helpers";
import { formatDateToHoursAgo } from "@common/date-helpers";
import SettingsPageDetails from "@components/Pages/PageDescription/SettingsPageDetails";
import { automationDetails } from "@common/Data/page-details-data";
import AutomationsNoDataPage from "@components/common/NoData/automation-nodata";
import DragIndicatorSvgComponent from "@assets/svg/drag-indicator";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import useDynamicTitle from "@context/useDynamicTitle";
import TemplateModal from "./components/TemplateModal";

const items: TabsProps["items"] = [
  {
    key: "ticket_creation_rules",
    label: "Ticket Creation Rules",
  },
  {
    key: "ticket_update_rules",
    label: "Ticket Update Rules",
  },
  {
    key: "time_trigger_rules",
    label: "Time Trigger Rules",
  },
];

const TicketsAutomation = ({ props }: any) => {
  useDynamicTitle("Tickets Automation");
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isLoading4, setIsLoading4] = useState(false);
  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);
  const [automationRule, setAutomationRule] = useState("");
  const [templateOpen, setTemplateOpen] = useState(false);
  const [templates, setTemplate] = useState([]);

  // Centralize searchParams parsing
  const searchParams = new URLSearchParams(location.search);
  const UrlParams = Object.fromEntries(searchParams.entries());
  const activeTab = searchParams.get("active_tab") || "ticket_creation_rules";

  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getAutomationList(true);
    }
    handleGetRules();
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) setPage_no(parseInt(UrlParams.page_no));
      if (UrlParams.limit) setLimit(parseInt(UrlParams.limit));
      getAutomationList(true);
    }
  }, [location.search]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getAutomationList = async (status: boolean) => {
    try {
      setIsLoading(status);
      const payload = {
        page_size: UrlParams.page_size || limit,
        page_no: UrlParams.page_no || page_no,
        type: activeTab,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getAllAutomation(queryRequest);
      if (resp) {
        setList(getValue(resp, "data.automations", []));
        setPage_no(getValue(resp, "data.pagination.page", 0));
        setTotalCount(getValue(resp, "data.pagination.total", 0));
      }
    } catch (error) {
      toast.error("Failed to load Automations");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateStatus = async (id: string, status: boolean) => {
    setIsLoading2(true);
    try {
      const payload = {
        ids: [id],
        is_active: status,
      };
      const resp = await updateAutomationStatus(payload);
      if (resp) {
        // toast.success("Updated successfully");
        getAutomationList(true);
      } else {
        toast.error("Failed to update");
      }
    } catch (error) {
      toast.error("Error updating");
    } finally {
      setIsLoading2(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination Section                           */
  /* -------------------------------------------------------------------------- */
  const handleChangePagination = (page_no: number, limit: number) => {
    setPage_no(page_no);
    setLimit(limit);
    const updatedParams = {
      ...UrlParams,
      page_no,
      page_size: limit,
    };
    const queryRequest = QueryRequestHelper(updatedParams);
    navigate(`${location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Tab Change Handler                           */
  /* -------------------------------------------------------------------------- */
  const handleTabChange = (key: string) => {
    const orgId = getValue(params, "orgId", "");
    navigate(`/${orgId}/settings/helpdesk/tickets?active_tab=${key}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Handle New Rule                              */
  /* -------------------------------------------------------------------------- */
  const handleSubmit = () => {
    const orgId = getValue(params, "orgId", "");
    navigate(`/${orgId}/settings/helpdesk/tickets/create?type=${activeTab}`, {
      replace: true,
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                               Delete Automation                            */
  /* -------------------------------------------------------------------------- */
  const handleDeleteModal = (id: string, name: string) => {
    Modal.confirm({
      title: `Are you sure you want to delete automation  - ${name}?`,
      content: "Once deleted, this cannot be recovered.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await deleteAutomation(id);
          toast.success("Automation deleted successfully");
          getAutomationList(true);
        } catch (error) {
          toast.error("Error deleting automation");
        }
      },
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                               Delete Automation                            */
  /* -------------------------------------------------------------------------- */
  const handleUpdatePosition = async (
    updates: { id: string; position: number }[]
  ) => {
    setIsLoading1(true);
    try {
      const payload = { swap_fields: updates };
      const resp = await updateAutomationPosition(payload);
      if (resp) {
        getAutomationList(true);
      } else {
        toast.error("Failed to update position");
      }
    } catch (error) {
      toast.error("Error updating position");
    } finally {
      setIsLoading1(false);
    }
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedList = Array.from(list);
    const [removed] = reorderedList.splice(result.source.index, 1);
    reorderedList.splice(result.destination.index, 0, removed);

    setList(reorderedList);
    const updates = reorderedList.map((item, index) => ({
      id: getValue(item, "id", ""),
      position: index + 1,
    }));

    handleUpdatePosition(updates);
  };

  /* -------------------------------------------------------------------------- */
  /*                            Manage rule Section                              */
  /* -------------------------------------------------------------------------- */
  const handleGetRules = async () => {
    try {
      const resp = await getAutomationRule();
      if (resp) {
        setAutomationRule(getValue(resp, "data.automation_creation", ""));
      }
    } catch (error) {
      toast.error("Failed to load Automations");
    } finally {
    }
  };

  const handleUpdateRule = async (rule: string) => {
    setIsLoading3(true);
    try {
      const payload = { automation_creation: rule };
      const resp = await createAutomationRule(payload);
      if (resp) {
        handleGetRules();
      } else {
        toast.error("Failed to update rule");
      }
    } catch (error) {
      toast.error("Error updating rule");
    } finally {
      setIsLoading3(false);
    }
  };

  const ruleOptions = [
    {
      label: "Executing first matching rule",
      optionLabel: "Execute first matching rule",
      value: "first_rule",
      description: "Only the first rule that matches will be executed.",
    },
    {
      label: "Executing all matching rules",
      optionLabel: "Execute all matching rules",
      value: "all_rules",
      description:
        "All matching rules will be executed sequentially, one after the other, in the order which they are configured.",
    },
  ];

  /* -------------------------------------------------------------------------- */
  /*                            Templates Section                               */
  /* -------------------------------------------------------------------------- */

  const toggleTemplate = () => {
    setTemplateOpen((prev) => !prev);
    if (templates.length === 0) {
      handleGetTemplates();
    }
  };

  const handleGetTemplates = async () => {
    try {
      setIsLoading4(true);

      const resp = await getAutomationTemplates();
      if (resp) {
        setTemplate(getValue(resp, "data.automations", ""));
      }
    } catch (error) {
      toast.error("Failed to load Automations");
    } finally {
      setIsLoading4(false);
    }
  };

  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        icon="tickets-automation"
        title="Tickets Automation"
        showSubmit
        submitIcon={<Plus size={18} />}
        submitText={`New ${formatString(activeTab)}`}
        handleSubmit={handleSubmit}
        addChildren={
          <Button
            size="large"
            icon={<ScrollText size="16" />}
            onClick={() => toggleTemplate()}
          >
            Templates
          </Button>
        }
      />
      <div className="sla-policy-wrapper">
        <div className="w-70 p-4 agent-shifts-card-container">
          <div className="d-flex align-items-center gap-3">
            <p className="small_text__15 pb-3">Rules that run on:</p>
            <Tabs
              activeKey={activeTab}
              onChange={handleTabChange}
              items={items}
            />
          </div>
          <p className="small_text__14 pb-3">
            {activeTab === "ticket_creation_rules"
              ? "These rules automate next steps as soon as a customer writes to you. For example, set the priority based on urgency, assign it to the right group, etc. The rules run sequentially."
              : activeTab === "ticket_update_rules"
              ? "These rules automate whenever there's an update to a ticket. For example, notify agents of customer responses, send surveys when a ticket is closed, etc. The rules run sequentially."
              : "These rules automate based on the age of a ticket. For example, send reminders to customers, escalate unattended tickets, etc. The rules run sequentially."}
          </p>
          <div className="mb-4 d-flex justify-content-end">
            {activeTab === "ticket_creation_rules" ? (
              <Select
                size="large"
                placeholder="Select Rule"
                loading={isLoading3}
                disabled={isLoading3}
                value={automationRule}
                onChange={handleUpdateRule}
                options={ruleOptions}
                style={{ width: "340px" }}
                optionRender={(option) => (
                  <div>
                    <h6 className="header_text__14 mb-0 text-black">
                      {getValue(option, "data.optionLabel", "")}
                    </h6>
                    <p className="small_text__12 text-wrap">
                      {getValue(option, "data.description", "")}
                    </p>
                  </div>
                )}
              />
            ) : (
              <Alert
                message={
                  activeTab === "ticket_update_rules"
                    ? "Executes all matching rules"
                    : "Executes all matching rules for tickets updated within 30 days."
                }
                type="info"
              />
            )}
          </div>
          {isLoading ? (
            <ListLoader />
          ) : list.length > 0 ? (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="slaPolicies">
                {(provided) => (
                  <div
                    className="automations-card-wrapper"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {list.map((item, index) => {
                      const menuItems: MenuProps["items"] = [
                        {
                          key: "1",
                          label: "Delete",
                          onClick: () =>
                            handleDeleteModal(
                              getValue(item, "id", ""),
                              getValue(item, "name", "")
                            ),
                          disabled: getValue(item, "is_default", false),
                        },
                      ];

                      return (
                        <Draggable
                          key={getValue(item, "id", "")}
                          draggableId={getValue(item, "id", "")}
                          index={index}
                        >
                          {(provided: any) => (
                            <div
                              key={index}
                              className="automations-card"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <div className="d-flex align-items-center justify-content-between gap-3 pt-4 p-4">
                                <div className="d-flex align-items-center gap-3">
                                  {list.length > 1 && (
                                    <div
                                      className="d-flex justify-content-center cursor-grabber"
                                      {...provided.dragHandleProps}
                                    >
                                      <DragIndicatorSvgComponent
                                        color={"#aeaeae"}
                                      />
                                    </div>
                                  )}
                                  <div
                                    className="cursor-pointer"
                                    onClick={() =>
                                      navigate(
                                        `/${getValue(
                                          params,
                                          "orgId",
                                          ""
                                        )}/settings/helpdesk/tickets/edit/${getValue(
                                          item,
                                          "id",
                                          ""
                                        )}?type=${activeTab}`
                                      )
                                    }
                                  >
                                    <h1 className="header_text__16">
                                      {getValue(item, "position", 0)}.{" "}
                                      {capitalizeFirstLetter(
                                        getValue(item, "name", "")
                                      )}
                                    </h1>
                                    {getValue(item, "description", "") && (
                                      <p className="small_text__14 py-2">
                                        {capitalizeFirstLetter(
                                          getValue(item, "description", "")
                                        )}
                                      </p>
                                    )}
                                    <div className="pt-1">
                                      {getValue(
                                        item,
                                        "summary_conditions",
                                        []
                                      ).map((summary: any, index: number) => (
                                        <span
                                          key={index}
                                          className="small_text__14 summary-conditions"
                                          style={{ marginRight: "4px" }}
                                          dangerouslySetInnerHTML={{
                                            __html: formatString(summary),
                                          }}
                                        />
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                  <Switch
                                    size="small"
                                    checked={getValue(item, "is_active", "")}
                                    onChange={() =>
                                      handleUpdateStatus(
                                        getValue(item, "id", ""),
                                        getValue(item, "is_active", "")
                                          ? false
                                          : true
                                      )
                                    }
                                  />
                                  <Dropdown menu={{ items: menuItems }}>
                                    <MoreVertical size={18} />
                                  </Dropdown>
                                </div>
                              </div>
                              <div className="card-bottom-section">
                                <p className="small_text__13">
                                  Last Modified :{" "}
                                  <span className="header_text__13  ">
                                    {formatDateToHoursAgo(
                                      getValue(item, "updated_at", "")
                                    )}
                                  </span>
                                </p>
                                <p className="small_text__13">
                                  By :{" "}
                                  <span className="header_text__13">
                                    {capitalizeFirstLetter(
                                      getValue(
                                        item,
                                        "created_by.first_name",
                                        ""
                                      )
                                    )}{" "}
                                    {capitalizeFirstLetter(
                                      getValue(item, "created_by.last_name", "")
                                    )}
                                  </span>
                                </p>
                                <p className="small_text__13">
                                  Impacted tickets (Last 7 days) :{" "}
                                  <span className="header_text__13">
                                    {getValue(item, "impacted_tickets", 0)}
                                  </span>
                                </p>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <AutomationsNoDataPage title={formatString(activeTab)} />
          )}
          {totalCount > 10 && (
            <Pagination
              showSizeChanger
              align="center"
              className="mt-4"
              total={totalCount}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              defaultPageSize={limit}
              defaultCurrent={page_no}
              onChange={handleChangePagination}
            />
          )}
        </div>
        <div className="w-30 p-4 sla-policy-right-details">
          <SettingsPageDetails data={automationDetails} />
        </div>
      </div>
      <TemplateModal
        isLoading={isLoading4}
        templateOpen={templateOpen}
        toggleTemplate={toggleTemplate}
        templates={templates}
        navigate={navigate}
        params={params}
      />
    </CompanyProfileDetailsLayout>
  );
};

export default TicketsAutomation;
