import { convertCurrentDateToTime } from "@common/date-helpers";
import { getValue } from "@utils/lodash";

const LocationPreview = ({ item, getReadIcon }: any) => {
  return (
    <>
      <div
        className="d-flex align-items-center gap-2 p-3 bg-light rounded-3 justify-content-between cursor-pointer"
        onClick={() => {
          const lat = getValue(item, "location_latitude", "");
          const lng = getValue(item, "location_longitude", "");
          if (lat && lng) {
            window.open(
              `https://www.google.com/maps?q=${lat},${lng}`,
              "_blank"
            );
          }
        }}
      >
        <div className="d-flex align-items-center gap-2">
          <img
            src={`/images/icons/map-location.png`}
            alt="File"
            width={26}
            height={26}
          />

          <p>Location</p>
        </div>
      </div>

      <p className="send-time p-1 mt-1 d-flex justify-content-end">
        {convertCurrentDateToTime(getValue(item, `created_at`, ""))}
        {getValue(item, "is_private", "") === false && (
          <span className="ms-2">{getReadIcon(item)}</span>
        )}
      </p>
    </>
  );
};

export default LocationPreview;
