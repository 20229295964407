import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import React, { useEffect, useRef, useState } from "react";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import {
  convertNumbersToSquareBrackets,
  formatString,
  removeNullOrUndefinedProperties,
  sortJSONObjectArray,
} from "@common/text-helpers";
import InputRuleForm from "@components/InputRuleForm/form";
import { handleTextChange } from "@common/handleChange";
import ConditionFields from "./components/conditions/ConditionFields";
import OptionFields from "./components/actions/OptionFields";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "sonner";
import EventFields from "./components/events/EventFields";
import { Badge, Timeline } from "antd";
import FormDescription from "@components/custom/FormDescrption/FormDescription";
import {
  createPhoneAssignment,
  getAllPhoneAssignmentConditionFields,
  getSpecificPhoneAssignment,
} from "@services/phone-assignment.service";
import { allPipelines } from "@services/pipeline.service";
import { useStateContext } from "@context/dataContext";
import { QueryRequestHelper } from "@common/query-request-helper";

type RequestState = {
  id: string;
  name: string;
  description: string;
  type: string;
  event: string;
  pattern: any;
  conditions: {
    id: string;
    action: string;
    fieldId: string;
    operator: string;
    value: any[];
    index: number;
  }[];
  actions: {
    updates?: {
      id: string;
      action: string;
      field_name: string;
      value?: string;
      module_pipeline_id?: string;
      module_pipeline_stage_id?: string;
    }[];
  };
};

const PhoneAssignmentDetails = ({ props }: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const [editId, setEditId] = useState("");
  const [isTemplate, setIsTemplate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const { selectedModuleId } = useStateContext();

  const initialRequest = {
    id: "",
    name: "",
    description: "",
    type: "",
    event: "incoming_call",
    pattern: "any",
    conditions: [
      {
        id: "",
        action: "add",
        fieldId: "call_status",
        operator: "{IS}",
        value: [],
        index: 0,
      },
    ],
    actions: {
      updates: [
        // {
        //   id: "",
        //   action: "add",
        //   field_name: "status",
        //   module_pipeline_id: "",
        //   module_pipeline_stage_id: "",
        // },
        // {
        //   id: "",
        //   action: "add",
        //   field_name: "owner",
        //   value: "",
        // },
      ],
    },
  };

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const [request, setRequest] = useState<RequestState>(initialRequest);

  useEffect(() => {
    if (UrlParams.type) {
      setRequest((prevRequest) => ({
        ...prevRequest,
        type: UrlParams.type,
      }));
    }
    getConditions();
    getAllPipelines();
  }, []);

  useEffect(() => {
    if (getValue(params, "id", "")) {
      if (getValue(UrlParams, "template", "") === "true") setIsTemplate(true);
      else setEditId(getValue(params, "id", ""));
      handleGetSpecificPhoneAssignment(getValue(params, "id", ""));
    }
  }, [params]);

  const [automationConditions, setAutomationCondtions] = React.useState<any>(
    []
  );
  const [automationOptions, setAutomationOptions] = React.useState<any>([]);
  const getConditions = async () => {
    setAutomationOptions([]);
    try {
      let resp = await getAllPhoneAssignmentConditionFields();
      if (resp) {
        const removeDuplicates = (
          existingArray: any,
          newArray: any,
          uniqueKey = "id"
        ) => {
          const existingIds = new Set(
            existingArray.map((item: any) => item[uniqueKey])
          );
          return [
            ...existingArray,
            ...newArray.filter(
              (newItem: any) => !existingIds.has(newItem[uniqueKey])
            ),
          ];
        };

        const processedWorkflowOptions = getValue(resp, `data`, []).map(
          (item: object) => ({
            id: getValue(item, `fieldId`, ""),
            label: getValue(item, `fieldLabel`, ""),
            inputType: getValue(item, `fieldType`, ""),
            value: getValue(item, `fieldId`, ""),
          })
        );

        const WorkflowOptions = getValue(resp, `data`, []).map(
          (item: object) => ({
            ...item,
            inputType:
              getValue(item, `fieldId`, "") === "pipeline_stage_id"
                ? "select"
                : getValue(item, `fieldType`, ""),
            fieldType:
              getValue(item, `fieldId`, "") === "pipeline_stage_id"
                ? "select"
                : getValue(item, `fieldType`, ""),
          })
        );

        setAutomationOptions((prevOptions: any) =>
          removeDuplicates(prevOptions, [...processedWorkflowOptions], "id")
        );

        setAutomationCondtions((prevConditions: any) =>
          removeDuplicates(prevConditions, [...WorkflowOptions], "id")
        );
      }
    } catch (error) {
      console.error("Error fetching conditions:", error);
    }
  };

  const [isPipelineLoading, setIsPipelineLoading] = useState(false);
  const [pipelineStageOptions, setPipelineStageOptions] = useState([]);
  const [pipelineDetails, setPipelineDetails] = useState([]);
  const getAllPipelines = async () => {
    try {
      setIsPipelineLoading(true);
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await allPipelines(
        await selectedModuleId("contacts"),
        queryRequest
      );
      if (resp) {
        let list =
          getValue(resp, `data.0.stages.length`, 0) > 0
            ? getValue(resp, `data.0.stages`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `id`, ""),
                label: getValue(item, `label`, ""),
              }))
            : [];
        setPipelineStageOptions(list);
        setIsPipelineLoading(false);
        setPipelineDetails(getValue(resp, `data.0`, {}));
        // setRequest((prevRequest) => ({
        //   ...prevRequest,
        //   actions: {
        //     updates: [
        //       {
        //         ...(prevRequest.actions?.updates?.[0] || {
        //           id: "",
        //           action: "add",
        //           field_name: "status",
        //         }),
        //         module_pipeline_id: getValue(resp, `data.0.id`, ""),
        //       },
        //       {
        //         ...(prevRequest.actions?.updates?.[1] ||),
        //       },
        //     ],
        //   },
        // }));
      } else {
        setIsPipelineLoading(false);
      }
    } catch (error) {
      setIsPipelineLoading(false);
    }
  };

  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else if (getValue(request, "actions.updates.length", 0) === 0) {
      toast.error("Please add at least one action.");
    } else {
      setIsLoading(true);
      try {
        let payload = JSON.parse(JSON.stringify(request));
        payload["pattern"] = convertNumbersToSquareBrackets(
          getValue(payload, `pattern`, "")
        );
        const updatePayload = removeNullOrUndefinedProperties(payload);
        const resp = await createPhoneAssignment(updatePayload);
        if (resp) {
          toast.success(`${editId ? "Updated" : "Created"} Successfully`);
          navigate(
            `/${getValue(
              params,
              "orgId",
              ""
            )}/settings/chat/phone-assignment?active_tab=${getValue(
              resp,
              "data.type",
              ""
            )}`
          );
          setIsLoading(false);
        } else {
          toast.error(
            `Failed to ${editId ? "editing" : "creating"} Phone Assignment`
          );
        }
      } catch (error) {
        toast.error(
          `Error ${editId ? "editing" : "creating"} Phone Assignment`
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleGetSpecificPhoneAssignment = async (id: string) => {
    try {
      setIsLoading1(true);
      const resp = await getSpecificPhoneAssignment(id);
      if (resp) {
        let conditions =
          getValue(resp, `data.conditions.length`, 0) > 0
            ? sortJSONObjectArray(
                getValue(resp, `data.conditions`, []),
                "index"
              ).map((item: object, index: number) => ({
                value: getValue(item, `value`, "").split("|||"),
                fieldId: getValue(item, `module_field`, "")
                  ? getValue(item, `module_field.id`, "")
                  : getValue(item, `column`, ""),
                operator: getValue(item, `operator`, ""),
                id: getValue(item, `id`, ""),
                action: "update",
                index: getValue(item, `index`, null),
                column: getValue(item, `column`, null),
              }))
            : [];

        let updateActions =
          getValue(resp, `data.update_actions.length`, 0) > 0
            ? getValue(resp, `data.update_actions`, []).map((item: object) => ({
                action: "update",
                id: getValue(item, `id`, ""),
                value: getValue(item, `value`, ""),
                isEmpty: getValue(item, `is_empty`, false),
                ...(getValue(item, `field_name`, "")
                  ? {
                      field_name: getValue(item, `field_name`, ""),
                      module_pipeline_id: getValue(
                        item,
                        `module_pipeline_id`,
                        ""
                      ),
                      module_pipeline_stage_id: getValue(
                        item,
                        `module_pipeline_stage_id`,
                        ""
                      ),
                    }
                  : { fieldId: getValue(item, `module_field.id`, "") }),
              }))
            : null;

        setRequest({
          id: getValue(resp, "data.id", ""),
          name: getValue(resp, "data.name", ""),
          type: getValue(resp, "data.type", ""),
          description: getValue(resp, "data.description", ""),
          event: getValue(resp, "data.event", ""),
          pattern: getValue(resp, "data.pattern", ""),
          conditions: conditions,
          actions: {
            updates: updateActions,
          },
        });
      }
    } catch (error) {
      toast.error("Failed to load response.");
    } finally {
      setIsLoading1(false);
    }
  };

  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        backButton
        title={`${editId ? "Edit" : "Add"} for ${formatString(
          getValue(request, "type", "")
        )}`}
        showSubmit
        submitText={`${editId ? "Update" : "Create"} Phone Assignment`}
        isLoading={isLoading}
        titleLoading={isLoading1}
        handleSubmit={() => handleSubmit()}
        showCancel
        handleCancel={() =>
          navigate(
            `/${getValue(params, "orgId", "")}/settings/chat/phone-assignment`
          )
        }
      />
      <div className="p-4 manageFieldRightSettings overflow-y-auto">
        <InputRuleForm
          inputType="TEXT"
          name="name"
          value={getValue(request, "name", "")}
          placeholder="Enter Rule Name"
          required
          label="Rule Name (For Own Reference)"
          onChange={(e: any) => handleTextChange(e, request, setRequest)}
          validLeft
          mandatory
          validator={simpleValidator}
          style={{
            maxWidth: "640px",
          }}
        />
        <FormDescription
          request={request}
          textClassname="mb-2 mt-3"
          inputClassname="w-50 mt-3"
          input={
            <InputRuleForm
              inputType="TEXTAREA"
              name="description"
              value={getValue(request, `description`, "")}
              placeholder="Enter Description"
              label="Description"
              onChange={(e: any) => handleTextChange(e, request, setRequest)}
            />
          }
        />
        <div>
          <Timeline
            className="mt-5"
            items={[
              {
                dot: <Badge count={1} style={{ backgroundColor: "#52c41a" }} />,
                children: (
                  <EventFields request={request} setRequest={setRequest} />
                ),
              },
              {
                dot: <Badge count={2} style={{ backgroundColor: "#ff942f" }} />,
                children: (
                  <ConditionFields
                    request={request}
                    setRequest={setRequest}
                    workflowConditions={automationConditions}
                    workflowOptions={automationOptions}
                    validator={simpleValidator}
                    isTemplate={isTemplate}
                  />
                ),
              },
              {
                dot: <Badge count={3} style={{ backgroundColor: "#1677ff" }} />,
                children: (
                  <OptionFields
                    request={request}
                    setRequest={setRequest}
                    validator={simpleValidator}
                    editId={editId}
                    pipelineStageOptions={pipelineStageOptions}
                    pipelineDetails={pipelineDetails}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </CompanyProfileDetailsLayout>
  );
};

export default PhoneAssignmentDetails;
