import { getValue } from "@utils/lodash";
import DocumentFormatPreviews from "./doc-formats-preview";
import DocumentSvgComponent from "@assets/svg/document";
import ArrowdownSvgComponent from "@assets/svg/arrowdown";
import DocumentFormatReplyPreviews from "./doc-formats-reply-preview";
import { handleDownloadOnClick } from "@common/downloadfile";
import { config } from "env";
import WhatsappIconChatViewPage from "@pages/Private/Broadcast/components/common/whatsapp-icon-chat";
import { Dropdown, Space } from "antd";
import AccountCircleSvgComponent from "@assets/svg/account-circle";
import { formatStatusText } from "@common/text-helpers";
import { formatMarkdownText } from "@components/MarkdownEditor/helpers/textFormatter";
import { PhoneIncoming, PhoneMissed, Plus } from "lucide-react";
import ProfileCircle from "@components/common/ProfileCircle";
import { convertCurrentDateToTime } from "@common/date-helpers";
import { getStatusColor } from "../helpers/live-chat-helpers";
import { formatDuration } from "@common/time-helper";
import TickSvgComponent from "@assets/svg/tick";
import DoubleTickSvgComponent from "@assets/svg/double-tick";
import AccessTimeSvgComponent from "@assets/svg/accesstime";

function ChatSenderSection(props) {
  const { item, setSelectedReplyMessage, handleAddToDocuments } = props;
  const statusColor =
    getValue(item, "status", "") === "failed" ? "#ffc8c8" : "#d9fdd4";

  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={() => setSelectedReplyMessage(item)}
          className=" small_text__13 d-flex gap-2 align-items-center"
        >
          <img src="/images/icons/whatsapp-reply.svg" />
          Reply
        </div>
      ),
    },
    ...(item.type === "image" ||
    item.type === "video" ||
    item.type === "document"
      ? [
          {
            type: "divider",
          },
          {
            key: "2",
            label: (
              <div
                className="small_text__13 d-flex gap-2 align-items-center"
                onClick={() =>
                  handleDownloadOnClick(
                    `https://${config.URL}/${item.upload_file.key}`,
                    item.upload_file.filename
                  )
                }
              >
                <img
                  src="/images/icons/whatsapp-download.svg"
                  alt="Download"
                  className="download-icon"
                  width={16}
                  height={16}
                />
                Download
              </div>
            ),
          },
          {
            type: "divider",
          },
          {
            key: "3",
            icon: <Plus size={16} color="#434d5f" />,
            label: (
              <div
                className="small_text__13 d-flex gap-2 align-items-center"
                onClick={() =>
                  handleAddToDocuments(
                    getValue(item, "upload_file.id", ""),
                    getValue(item, "inbox.contact_id", "")
                      ? getValue(item, "inbox.contact_id", "")
                      : getValue(item, "inbox.lead_id", ""),
                    getValue(item, "inbox.contact_id", "")
                      ? "contact_id"
                      : "lead_id"
                  )
                }
              >
                Add to Documents
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      {getValue(item, `call_report`, "") ? (
        getValue(item, `call_report.direction`, "") === "outgoing" && (
          <div className="sender-details-wrapper">
            <div className="sender-details">
              <div className="sender-left-section">
                <p className="sender-call-notification">
                  <div className="sender-call-notification-container">
                    <div className="sender-call-notification-icon">
                      {getValue(item, "call_report.status", "") === "ANSWER" ? (
                        <PhoneIncoming size={18} color="none" fill="#25d366" />
                      ) : (
                        <PhoneMissed
                          size={18}
                          color="none"
                          fill={getStatusColor(
                            getValue(item, "call_report.status", "")
                          )}
                        />
                      )}
                    </div>
                    <div>
                      <p className="sender-call-notification-text">
                        {formatStatusText(
                          getValue(item, "call_report.status", "")
                        )}
                      </p>
                      {getValue(item, "call_report.call_duration", "") && (
                        <p className="sender-call-notification-duration-text">
                          {formatDuration(
                            getValue(item, "call_report.call_duration", "")
                          )}
                        </p>
                      )}
                    </div>
                    <p className="send-time d-flex align-items-end mt-4 pt-1">
                      {convertCurrentDateToTime(
                        getValue(item, `created_at`, "")
                      )}
                    </p>
                  </div>
                </p>
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="sender-details-wrapper">
          <div className="sender-details">
            <div className="w-100">
              {getValue(item, "msg_user.first_name", "") && (
                <p className="sender-user-name">
                  {getValue(item, "msg_user.first_name", "")}
                </p>
              )}
              <div className="sender-left-section">
                <p
                  className={
                    getValue(item, "is_private", "") === true
                      ? "sender-private-message"
                      : "sender-message"
                  }
                  style={{ "--status-color": statusColor }}
                >
                  <div className="arrow-container">
                    <Dropdown menu={{ items }} placement="bottomRight">
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          <ArrowdownSvgComponent color={"gray"} class="arrow" />
                        </Space>
                      </a>
                    </Dropdown>
                  </div>
                  {getValue(item, `type`, "") === "template" &&
                    // !getValue(item, `text`, "") &&
                    !getValue(item, `replied_message.id`, "") &&
                    getValue(item, `wa_template.body`, "") && (
                      <WhatsappIconChatViewPage
                        request={{
                          ...getValue(item, `wa_template`, ""),
                          is_read: getValue(item, `is_read`, false),
                          is_delivered: getValue(item, `is_delivered`, false),
                          is_sent: getValue(item, `is_sent`, false),
                          is_received: getValue(item, `is_received`, false),
                          is_delivered: getValue(item, `is_delivered`, false),
                          is_sent: getValue(item, `is_sent`, false),
                          is_received: getValue(item, `is_received`, false),
                        }}
                        item={item}
                      />
                    )}
                  {getValue(item, `replied_message.id`, "") && (
                    <div className="reply_message_container mb-2">
                      <div className="d-flex align-items-center ps-2 justify-content-between replayed-background w-100">
                        {getValue(item, `replied_message.type`, "") ===
                        "template" ? (
                          <WhatsappIconChatViewPage
                            request={getValue(
                              item,
                              `replied_message.wa_template`,
                              ""
                            )}
                          />
                        ) : (
                          <div className="d-flex align-items-center justify-content-between">
                            <DocumentSvgComponent />
                            <p
                              dangerouslySetInnerHTML={{
                                __html: formatMarkdownText(
                                  getValue(item, `replied_message.text`, "")
                                    ? getValue(item, `replied_message.text`, "")
                                    : getValue(
                                        item,
                                        `replied_message.media_caption`,
                                        ""
                                      )
                                    ? getValue(
                                        item,
                                        `replied_message.media_caption`,
                                        ""
                                      )
                                    : getValue(
                                        item,
                                        `replied_message.text_body`,
                                        ""
                                      ) ||
                                      getValue(item, `replied_message.text`, "")
                                ),
                              }}
                              className="small_text__14 px-2 py-1"
                            ></p>
                          </div>
                        )}
                        {getValue(
                          item,
                          `replied_message.upload_file.id`,
                          ""
                        ) && (
                          <DocumentFormatReplyPreviews
                            item={getValue(item, `replied_message`, {})}
                            upload_file={getValue(
                              item,
                              `replied_message.upload_file`,
                              ""
                            )}
                          />
                        )}
                      </div>
                    </div>
                  )}
                  {/* {getValue(item, `text`, "") && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: formatMarkdownText(getValue(item, `text`, "")),
                      }}
                      className="small_text__14 px-2 py-1 text-start"
                    ></p>
                  )} */}
                  {(getValue(item, `broadcast_id`, "") ||
                    getValue(item, `template.id`, "")) && (
                    <div>
                      <WhatsappIconChatViewPage
                        request={getValue(item, `template`, {})}
                      />
                    </div>
                  )}
                  {getValue(item, `type`, "") !== "template" && (
                    <DocumentFormatPreviews item={item} group={props.group} />
                  )}
                </p>

                {getValue(item, "msg_user.first_name", "") ? (
                  <ProfileCircle
                    value={getValue(item, `msg_user.first_name`, "")}
                  />
                ) : (
                  <AccountCircleSvgComponent color={"#dfe5e7"} size={36} />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ChatSenderSection;
