import CategoryFolders from "@pages/Private/Solutions";

export const SolutionsRoutes = [
  {
    path: "/:orgId/solutions",
    name: "solutions_knowledge_base",
    component: CategoryFolders,
  },
  {
    path: "/:orgId/solutions/:id/folders/:folderId/article/:articleId",
    name: "solutions_knowledge_base",
    component: CategoryFolders,
  },
];
