import React, { useEffect, useState } from "react";
import { isValidPhoneNumber } from "libphonenumber-js";
import { getValue } from "@utils/lodash";
import countries from "@json/dialcodes.json";
import Input from "./Input";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import CountrySearchToolTip from "../Dropdown/CountrySearchTooltip";
import { formHandlePhoneArrayChange } from "@common/handleChange";
import { getLastTenDigits } from "@common/text-helpers";
import { getDigitsExceptLastTen } from "@common/text-helpers";
import { handleChangeNumber } from "@common/handleChange";
import { QueryRequestHelper } from "@common/query-request-helper";
import { checkModuleDuplicates } from "@services/common.service";
import timezones from "@common/json/standardized-timezone-data.json";
import countryTimezones from "@common/json/standardized-timezone-data.json";

import { useStateContext } from "@context/dataContext";
import NewCountrySelect from "../Dropdown/NewCountrySelect";

function DialcodePhoneInputComponent(props: any) {
  const {
    value,
    label,
    placeholder,
    hideLabel,
    validator,
    showWhatsapp,
    index,
    setFields,
    mandatory,
    api_name,
    phoneInline,
    codeSelectorWdith,
    phoneInputWdith,
    disabled,
    setValidPhone,
    validPhone,
  } = props;

  useEffect(() => {
    if (!value) {
      // setCountryCode("");
      setNationalNumber("");
    }
  }, [value]);

  useEffect(() => {
    if (!value) {
      setNationalNumber("");
    }
  }, [!value]);

  const { orgInfo } = useStateContext();
  const [countryCode, setCountryCode] = useState("");
  const [nationalNumber, setNationalNumber] = useState("");
  const findTimezone: any = (timezone: string) => {
    return timezones.find(
      (item: object) => getValue(item, `value`, "") === timezone
    );
  };

  const findCountryCode: any = (timezone: string) => {
    let info: any = countryTimezones.find(
      (item: object) => getValue(item, `value`, "") === timezone
    );
    return getValue(info, `phoneCode`, "");
  };
  useEffect(() => {
    // If countryCode already exists, prefer user selection or org phone_code
    if (countryCode) {
      // Only update if org has a phone_code that differs from current selection
      // This ensures user manual changes aren't overridden unnecessarily
      if (
        getValue(orgInfo, "phone_code", "") &&
        getValue(orgInfo, "phone_code", "") !== countryCode
      ) {
        setCountryCode(getValue(orgInfo, "phone_code", ""));
      }
      return;
    }

    // No countryCode set yet - determine default

    // First priority: Use org's phone_code if available
    if (getValue(orgInfo, "phone_code", "")) {
      setCountryCode(getValue(orgInfo, "phone_code", ""));
      return;
    }

    // Second priority: Use org's timezone to determine country code
    if (getValue(orgInfo, "timezone", "")) {
      const timezoneCode = findCountryCode(getValue(orgInfo, "timezone", ""));
      if (timezoneCode) {
        setCountryCode(timezoneCode);
        return;
      }
    }

    // Third priority: Use browser's timezone
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    // Handle special case for India (Calcutta → Kolkata)
    if (timeZone === "Asia/Calcutta") {
      const kolkataCode = findCountryCode("Asia/Kolkata");
      setCountryCode(kolkataCode || "+91");
      return;
    }

    // Try to match browser timezone
    if (findTimezone(timeZone)) {
      const timezoneCode = findCountryCode(timeZone);
      if (timezoneCode) {
        setCountryCode(timezoneCode);
        return;
      }
    }

    // Final fallback: Use India's code
    const kolkataCode = findCountryCode("Asia/Kolkata");
    setCountryCode(kolkataCode || "+91");
  }, [orgInfo]); // Only depend on orgInfo changes, not countryCode

  const handleInputChange = (input: any) => {
    if (input) {
      if (
        (getValue(input, `length`, 0) === 10 ||
          getValue(input, `length`, 0) < 10) &&
        !input.startsWith("+")
      ) {
        setNationalNumber(input);
      } else if (getValue(input, `length`, 0) > 10) {
        let lastTenDigits = getLastTenDigits(input);
        let digitsExceptLastTen = getDigitsExceptLastTen(input);
        setCountryCode(digitsExceptLastTen);
        setNationalNumber(lastTenDigits);
      } else {
        const parsedNumber = parsePhoneNumberFromString(input);
        if (parsedNumber) {
          setNationalNumber(parsedNumber.nationalNumber);
        } else {
          setCountryCode("");
          setNationalNumber("");
        }
      }
    }
  };
  useEffect(() => {
    handleInputChange(value);
  }, []);

  const getPhoneNumber = (input: any) => {
    if (input) {
      if (input.startsWith("+")) {
        const parsedNumber = parsePhoneNumberFromString(input);
        if (parsedNumber) {
          setNationalNumber(parsedNumber.nationalNumber);
        } else {
          return null;
        }
      } else {
        return setNationalNumber(input);
      }
    } else {
      return null;
    }
  };

  // const handleChangeCountryCode = (obj: object) => {
  //   let input = getValue(obj, `value`, "");
  //   setCountryCode(getValue(obj, `value`, ""));
  //   formHandlePhoneArrayChange(index, input + nationalNumber, setFields);
  // };

  const handleChangeCountryCode = (obj: any) => {
    let input = getValue(obj, `dial_code`, "");
    setCountryCode(input);
    formHandlePhoneArrayChange(index, input + nationalNumber, setFields);
  };

  const handleChangePhoneNumber = (e: any) => {
    let input = handleChangeNumber(e);
    setNationalNumber(input);
    formHandlePhoneArrayChange(index, countryCode + input, setFields);
  };

  const handleBlur = async (e: any) => {
    if (props.module) {
      const value = countryCode + nationalNumber;
      let outputString = value.replace(/[^+\d]/g, "").trim();
      if (api_name && outputString) {
        let payload = {
          [api_name]: encodeURIComponent(outputString),
        };
        let queryRequest = QueryRequestHelper(payload);
        let resp = await checkModuleDuplicates(
          props.module,
          queryRequest,
          props.pipeline_id
        );
        if (resp) {
          if (getValue(resp, `data.id`, "")) {
            if (!props.duplicateAPINames.includes(api_name)) {
              props.duplicateAPINames.push(api_name);
              props.setDuplicateAPINames([...props.duplicateAPINames]);
            }
          } else {
            let filtered = props.duplicateAPINames.filter(
              (item: string) => api_name !== item
            );
            props.setDuplicateAPINames(filtered);
          }
        }
      }
    }
  };

  const handleNoBlur = () => {
    props.setDuplicateAPINames([]);
  };

  useEffect(() => {
    if (setValidPhone) {
      if (countryCode) {
        const fullNumber = countryCode + (nationalNumber || ""); // Ensure it handles empty nationalNumber
        const isValid = isValidPhoneNumber(fullNumber);

        setValidPhone((prevPhones: any) => {
          if (isValid || !nationalNumber) {
            // Remove the object if it's valid OR if nationalNumber is empty
            return prevPhones.filter(
              (item: any) => !item.hasOwnProperty(props.api_name)
            );
          } else {
            // Check if the object with props.api_name already exists
            const existingIndex = prevPhones.findIndex((item: any) =>
              item.hasOwnProperty(props.api_name)
            );

            if (existingIndex !== -1) {
              // Update existing object
              return prevPhones.map((item: any, index: any) =>
                index === existingIndex
                  ? { [props.api_name]: fullNumber }
                  : item
              );
            } else {
              // Add new object
              return [...prevPhones, { [props.api_name]: fullNumber }];
            }
          }
        });
      }
    }
  }, [countryCode, nationalNumber, props.api_name]);

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        {!hideLabel && <label className={`form-label`}>{label} </label>}
        {showWhatsapp && isValidPhoneNumber(value) && (
          <img
            src="/images/whatsapp.png"
            height={25}
            width={25}
            onClick={() =>
              isValidPhoneNumber(value)
                ? window.open(
                    `https://api.whatsapp.com/send?text=Hi&phone=+91${value}`
                  )
                : null
            }
          />
        )}
      </div>
      <div className="d-flex">
        {/* <div className={codeSelectorWdith ? codeSelectorWdith : "w-20"}> */}
        {/* <CountrySearchToolTip
            label={"label"}
            data={countries.map((item: object) => ({
              ...item,
              label: getValue(item, "dial_code", ""),
              value: getValue(item, "dial_code", ""),
            }))}
            selectKey="dial_code"
            value={countryCode}
            onChange={handleChangeCountryCode}
            required={mandatory}
            phoneInline={phoneInline}
            disabled={disabled}
          /> */}
        <NewCountrySelect
          value={countryCode}
          onChange={(obj: any) => handleChangeCountryCode(obj)}
          required={mandatory}
          disabled={disabled}
        />
        {/* </div> */}
        <div className={`${phoneInputWdith ? phoneInputWdith : "w-80"} ms-1`}>
          <Input
            {...props}
            hideLabel
            inline
            value={nationalNumber}
            onChange={handleChangePhoneNumber}
            placeholder={placeholder}
            mandatory={false}
            validator={null}
            onBlur={
              getValue(props, `unique`, false) ? handleBlur : handleNoBlur
            }
          />
        </div>
      </div>
      {(validator || nationalNumber) && (
        <p style={{ color: "#d93025", fontSize: "12px" }} className="mt-2">
          {value && !isValidPhoneNumber(countryCode + nationalNumber)
            ? `Please enter valid ${label}`
            : validator &&
              validator.current.message(
                label,
                isValidPhoneNumber(countryCode + nationalNumber)
                  ? nationalNumber
                  : "",
                "required"
              )}
        </p>
      )}
    </div>
  );
}

export default DialcodePhoneInputComponent;
