import { PlusCircle, Trash, Trash2, Zap } from "lucide-react";
import { Checkbox, Dropdown, MenuProps, Tag } from "antd";
import NewSearchSelect from "@components/custom/Dropdown/AntDropDown";
import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";

interface Update {
  id: string;
  action: string;
  field_name: string;
  value?: string;
  module_pipeline_id?: string;
  module_pipeline_stage_id?: string;
}

interface Request {
  actions: {
    updates?: Update[];
  };
}

interface OptionFieldsProps {
  request: Request;
  setRequest: (req: any) => void;
  validator: any;
  editId: string;
  pipelineStageOptions: any[];
  pipelineDetails: any;
}

const OptionFields = ({
  request,
  setRequest,
  validator,
  editId,
  pipelineStageOptions,
  pipelineDetails,
}: OptionFieldsProps) => {
  const [checkboxStates, setCheckboxStates] = useState({
    checkbox1: true,
    checkbox2: false,
  });

  // Sync checkbox states from request
  useEffect(() => {
    if (request.actions.updates) {
      if (request.actions.updates.length > 0) {
        setCheckboxStates({
          checkbox1: request.actions.updates.some(
            (update: Update) => update.field_name === "status"
          ),
          checkbox2: request.actions.updates.some(
            (update: Update) => update.field_name === "owner"
          ),
        });
      }
    }
  }, [request]);

  const handleAddAction = (action: string) => {
    setRequest((prev: Request) => {
      const updatedActions = prev.actions?.updates || [];
      const newUpdate =
        action === "status"
          ? {
              id: "",
              action: "add",
              field_name: "status",
              module_pipeline_id: getValue(pipelineDetails, "id", ""),
              module_pipeline_stage_id: "",
            }
          : {
              id: "",
              action: "add",
              field_name: "owner",
              value: "",
            };

      return {
        ...prev,
        actions: {
          ...prev.actions,
          updates: [...updatedActions, newUpdate], // Add new update to the array
        },
      };
    });
  };

  const handleRemoveAction = (index: number) => {
    setRequest((prev: Request) => {
      const updatedActions = (prev.actions?.updates || [])
        .map((update: Update, i: number) =>
          i === index
            ? update.id
              ? { ...update, action: "remove" }
              : null
            : update
        )
        .filter(Boolean) as Update[];

      return {
        ...prev,
        actions: {
          ...prev.actions,
          updates: updatedActions,
        },
      };
    });
  };

  const options = [
    { value: "call_agent", label: "Call Agent" },
    { value: "unassigned", label: "Unassigned Agent" },
    ...(editId
      ? [{ value: "existing_contact_owner", label: "Existing Contact Owner" }]
      : []),
  ];

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Set Status",
      onClick: () => handleAddAction("status"),
      disabled: request.actions?.updates?.some(
        (update: Update) => update.field_name === "status"
      ),
    },
    {
      key: "2",
      label: "Set Owner",
      onClick: () => handleAddAction("owner"),
      disabled: request.actions?.updates?.some(
        (update: Update) => update.field_name === "owner"
      ),
    },
  ];

  return (
    <>
      <div className="mt-5">
        <h6 className="header_text__18 mb-3 d-flex align-items-center gap-1">
          <Tag color="blue" bordered={false}>
            <Zap size={16} />
          </Tag>
          Actions
        </h6>
      </div>

      <div className="mt-1 sla-condition-card-wrapper">
        {getValue(request, "actions.updates", []).map(
          (update: Update, index: number) => {
            const currentAction = getValue(update, "action", "");
            return (
              currentAction !== "remove" && (
                <div className="sla-condition-card p-4 border-bottom">
                  <div className="w-9 d-flex align-items-center gap-3 w-100">
                    <p>
                      Set{" "}
                      <b>
                        {update.field_name === "status" ? "Status" : "Owner"}
                      </b>{" "}
                      to
                    </p>
                    <NewSearchSelect
                      options={
                        update.field_name === "status"
                          ? pipelineStageOptions
                          : options
                      }
                      onChange={(value) => {
                        setRequest((prev: Request) => ({
                          ...prev,
                          actions: {
                            ...prev.actions,
                            updates: (prev.actions?.updates || []).map(
                              (item: Update) =>
                                item.field_name === update.field_name
                                  ? {
                                      ...item,
                                      ...(update.field_name === "status"
                                        ? { module_pipeline_stage_id: value }
                                        : { value }),
                                    }
                                  : item
                            ),
                          },
                        }));
                      }}
                      value={
                        update.field_name === "status"
                          ? update.module_pipeline_stage_id
                          : update.value
                      }
                      placeholder={
                        update.field_name === "status"
                          ? "Select Status"
                          : "Select Owner"
                      }
                      label={
                        update.field_name === "status" ? "Status" : "Owner"
                      }
                      validator={validator}
                      hideLabel
                      width={260}
                    />
                    {update.field_name === "status" && <p>in Contacts</p>}
                  </div>
                  {(request.actions?.updates || []).length > 1 && (
                    <Trash2
                      size={16}
                      className="delete-sla-condition"
                      onClick={() => handleRemoveAction(index)}
                    />
                  )}
                </div>
              )
            );
          }
        )}
        <div className="d-flex align-items-center px-4 py-3 gap-2">
          <Dropdown menu={{ items }}>
            <div className="header_blue_text__15 d-flex align-items-center gap-1 cursor-pointer">
              <PlusCircle size={18} /> Add Action
            </div>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default OptionFields;
