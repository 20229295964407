export const formatDuration = (seconds: string) => {
  if (!seconds) return "-";

  const totalSeconds = parseInt(seconds, 10);
  if (isNaN(totalSeconds) || totalSeconds <= 0) return "-";

  const minutes = Math.floor(totalSeconds / 60);
  const remainingSeconds = totalSeconds % 60;

  return minutes > 0
    ? `${minutes} min${minutes > 1 ? "s" : ""} ${remainingSeconds} sec${
        remainingSeconds > 1 ? "s" : ""
      }`
    : `${remainingSeconds} sec${remainingSeconds > 1 ? "s" : ""}`;
};
