import { convertCurrentDateWithFormat } from "@common/date-helpers";
import { getValue } from "@utils/lodash";
import { Tag } from "antd";
import React from "react";
import DashboardMoreOptions from "../../components/dashboard-more-options";
import { capitalizeFirstLetter } from "@common/text-helpers";
import { Eye } from "lucide-react";

function KPIRankingsComponents(props: any) {
  const isMaximized = getValue(props, "isMaximized", false);
  const graphData = getValue(props, "graph", []);
  const displayedData = isMaximized ? graphData : graphData.slice(0, 7);

  return (
    <div className={`${isMaximized ? "" : "dashboard_border"}`}>
      <p
        className="dashboard-card-heading"
        onClick={() => props.toggleChartDetails(props)}
      >
        {capitalizeFirstLetter(getValue(props, `name`, ""))}
        <Eye size={16} className="dashboard-card-heading-eye" />
      </p>
      <table className="w-100">
        {displayedData.map((item: object, i: number) => (
          <tr
            key={i}
            className={`w-100 ${
              i !== displayedData.length - 1 ? "border-bottom" : ""
            }`}
          >
            <td className="60 py-2">
              {typeof getValue(item, `key`, "") === "string"
                ? convertCurrentDateWithFormat(
                    getValue(item, `key`, ""),
                    "DD-MM-YYYY"
                  )
                : convertCurrentDateWithFormat(
                    getValue(item, `key.created_at`, ""),
                    "DD-MM-YYYY"
                  )}
            </td>
            <td className="w-20 small_text__16 py-2">
              {getValue(item, `count`, "")}
            </td>
            <td className="w-20 text-right small_text__16 py-2">
              <Tag
                color={
                  getValue(item, "percentageToCurrent", "") !== "positive"
                    ? "#4cc792"
                    : "#f88"
                }
                className="me-0"
              >
                {getValue(item, `percentageToCurrent`, "")}
              </Tag>
            </td>
          </tr>
        ))}
      </table>
      <DashboardMoreOptions
        props={props}
        id={getValue(props, "id", "")}
        confirmDeleteAnalyticsComponent={props.confirmDeleteAnalyticsComponent}
        handleEditChart={props.handleEditChart}
      />
    </div>
  );
}

export default KPIRankingsComponents;
