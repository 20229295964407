import { getValue } from "@utils/lodash";
import ChatReceiverSection from "./chat-receiver";
import ChatSenderSection from "./chat-sender";
import { convertCurrentDateToTime, groupByDate } from "@common/date-helpers";
import { memo } from "react";
import { formatStatusInSentence } from "@common/text-helpers";

function ConversationMessages(props: any) {
  const { conversation, setSelectedReplyMessage, handleAddToDocuments } = props;
  const groupedData = groupByDate(conversation);
  return (
    <div>
      {Object.keys(groupedData).map((group: any, x: number) => {
        return (
          <div key={x}>
            <div className="chat-date-container">
              <p className="chat-date-header">{group}</p>
            </div>
            {getValue(groupedData, `[${group}].length`, 0) > 0 &&
              getValue(groupedData, `[${group}]`, []).map(
                (item: object, key: number) => {
                  if (
                    getValue(item, `type`, "") !== "request_welcome"
                    //  &&
                    // getValue(item, `type`, "") !== "sticker"
                  )
                    return getValue(item, `status`, "") === "note" ? (
                      <div className="chat-message-container" key={key}>
                        <p className="chat-message-header">
                          {formatStatusInSentence(getValue(item, `text`, ""))}{" "}
                          at{" "}
                          {convertCurrentDateToTime(
                            getValue(item, `updated_at`, "")
                          )}
                        </p>
                      </div>
                    ) : (
                      <div key={key}>
                        {!getValue(item, `business_initiated`, false) ? (
                          <ChatReceiverSection
                            item={item}
                            setSelectedReplyMessage={setSelectedReplyMessage}
                            handleAddToDocuments={handleAddToDocuments}
                            group={group}
                          />
                        ) : (
                          <ChatSenderSection
                            item={item}
                            setSelectedReplyMessage={setSelectedReplyMessage}
                            handleAddToDocuments={handleAddToDocuments}
                            group={group}
                          />
                        )}
                      </div>
                    );
                }
              )}
          </div>
        );
      })}
    </div>
  );
}

export default memo(ConversationMessages);
