import { SyncOutlined } from "@ant-design/icons";
import InputRuleForm from "@components/InputRuleForm/form";
import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";
import SidebarLayout from "@layouts/SidebarLayout/SidebarLayout";
import {
  getWaAccountTemplates,
  syncWaAccountTemplates,
} from "@services/fb.service";
import { Button, Modal, Skeleton, Spin, Table } from "antd";
import { useEffect, useRef, useState } from "react";
import TemplatesFromMeta from "./list";
import { toast } from "sonner";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { getStringContentLength } from "@common/markdown/html-to-text-parser";
import { listAllPipelines } from "@services/pipeline.service";
import { useStateContext } from "@context/dataContext";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  getAllAvailableTemplateTags,
  getloggedInuserTemplateTags,
} from "@services/email-config.service";
import data from "./data.json";
import _ from "lodash";
import MarkdownEditor from "@components/MarkdownEditor/Editor";
import { Info } from "lucide-react";
import { formatMarkdownText } from "@components/MarkdownEditor/helpers/textFormatter";
import MarkdownPreview from "@uiw/react-markdown-preview";
import AddWhatsappVariable from "../components/template-messages/add-variable-popup";
import { formatCamelString, formatString } from "@common/text-helpers";

function SyncTemplates(props: any) {
  const { selectedModuleId } = useStateContext();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    getTags();
  }, []);
  const [loading, setLoading] = useState<boolean>(false);
  const [whatsappAccount, setWhatsappAccount] = useState<string>("");
  const [templateList, setTemplateList] = useState<any>([]);

  const handleFetchTemplates = async () => {
    if (!whatsappAccount) {
      toast.error("Please enter a Whatsapp Account");
      return;
    }
    setLoading(true);
    const response = await getWaAccountTemplates(whatsappAccount);
    if (response) {
      setTemplateList(response.data);
      setLoading(false);
    }
  };

  const [syncing, setSyncing] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const [isOpenSync, setIsOpenSync] = useState(false);
  const handleModelSync = () => {
    setIsOpenSync(!isOpenSync);
  };

  const handleOpenTemplate = (payload: any) => {
    const hasPlaceholders = hasBodyWithPlaceholders(payload);
    setRequest(payload);
    processJson(payload);
    if (hasPlaceholders) {
      setIsOpenSync(true);
      return;
    }else{
      setIsOpenSync(true);
    }
  };
  const handleSubmit = async (payload: any) => {
    try {
      setSyncing(true);
      const response = await syncWaAccountTemplates(payload);
      if (response) {
        handleFetchTemplates();
        setSyncing(false);
        setIsOpenSync(false);
      } else {
        setSyncing(false);
      }
      toast.success("Check other");
    } catch (error) {
      setSyncing(false);
    }
  };

  const [request, setRequest] = useState<any>({});
  const hasBodyWithPlaceholders = (jsonData: any) => {
    // Check if jsonData and components array exist
    if (
      !jsonData ||
      !jsonData.components ||
      !Array.isArray(jsonData.components)
    ) {
      return false;
    }
    // Regex to match placeholders like {{1}}, {{2}}, etc.
    const placeholderRegex = /{{[0-9]+}}/g;

    // Iterate through components to find BODY with placeholders
    for (const component of jsonData.components) {
      if (
        component.type === "BODY" &&
        component.text &&
        placeholderRegex.test(component.text)
      ) {
        return true; // Found a BODY component with placeholders
      }
    }

    return false; // No BODY component with placeholders found
  };

  // const processJson = (data: any) => {
  //   let foundBodyWithPlaceholders = false;
  //   const updatedComponents = data.components.map((component: any) => {
  //     if (component.type === "BODY" && component.text) {
  //       // Check if text contains {{number}} placeholders using regex
  //       const placeholderRegex = /{{[0-9]+}}/g;
  //       if (placeholderRegex.test(component.text)) {
  //         foundBodyWithPlaceholders = true;
  //         // Replace all {{number}} with [[]]
  //         // const updatedText = component.text.replace(placeholderRegex, "[[]]");
  //         const updatedText = component.text;
  //         return { ...component, text: updatedText };
  //       }
  //     }
  //     return component;
  //   });

  //   setRequest({ ...data, components: updatedComponents });
  //   return foundBodyWithPlaceholders;
  // };
  const [placeholders, setPlaceholders] = useState<any>([]);
  const processJson = (data: any) => {
    let foundBodyWithPlaceholders = false;
    let placeholdersArray: { value: string; label: string }[] = []; // Array to store placeholders
    const updatedComponents = data.components.map((component: any) => {
      if (component.type === "BODY" && component.text) {
        // Check if text contains {{number}} placeholders using regex
        const placeholderRegex = /{{[0-9]+}}/g;
        const matches = component.text.match(placeholderRegex); // Get all matches
        if (matches) {
          foundBodyWithPlaceholders = true;
          // Populate placeholdersArray with {value: "{{number}}", label: ""}
          placeholdersArray = matches.map((match: string) => ({
            value: match,
            label: "",
          }));
          // Optionally replace placeholders in text (uncomment if needed)
          // const updatedText = component.text.replace(placeholderRegex, "[[]]");
          const updatedText = component.text; // Keeping original text as per your example
          return { ...component, text: updatedText };
        }
      }
      return component;
    });
    setPlaceholders(placeholdersArray);
    setRequest({ ...data, components: updatedComponents });
    return foundBodyWithPlaceholders;
  };

  const handleAddTags = (item: any) => {
    // Early return if no placeholder is selected
    if (!selectedPlaceholder) return;
    // Mark parent as changed
    setParentChage(true);
    // Get the current body text
    const currentText = getBodyText(request);
    // Replace the placeholder with the new value

    const existingPlaceholder = placeholders.find(
      (placeholder: any) => placeholder.value === selectedPlaceholder
    );

    let newEditorState;
    if (
      getValue(existingPlaceholder, `label`, "") &&
      currentText.includes(getValue(existingPlaceholder, `label`, ""))
    ) {
      newEditorState = currentText.replace(
        getValue(existingPlaceholder, `label`, ""),
        getValue(item, "placeHolder", "")
      );
    } else {
      newEditorState = currentText.replace(
        selectedPlaceholder,
        getValue(item, "placeHolder", "")
      );
    }

    // Update placeholders list
    const updatedPlaceholders = placeholders.map((placeholder: any) =>
      placeholder.value === selectedPlaceholder
        ? { ...placeholder, label: getValue(item, "placeHolder", "") }
        : placeholder
    );
    setPlaceholders(updatedPlaceholders);

    // Update components array
    const updatedComponents = request.components.map((component: any) =>
      component.type === "BODY"
        ? { ...component, text: newEditorState }
        : component
    );

    // Update the entire request object
    setRequest({
      ...request,
      components: updatedComponents,
    });
  };

  const insertText = (value: any) => {
    // Get the current text from the BODY component
    const currentText = getBodyText(request); // Assuming getBodyText is defined as shown earlier

    // Insert the new value at the cursor position
    const newEditorState = currentText.replace(selectedPlaceholder, value);
    setPlaceholders(
      placeholders.map((item: any) =>
        item.value !== selectedPlaceholder ? item : { ...item, label: value }
      )
    );
    // const newEditorState =
    //   currentText.slice(0, start) + value + currentText.slice(end);
    return newEditorState;
  };

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const [parentChage, setParentChage] = useState(false);
  const handleCursorMovement = (textarea: any) => {
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    setEnd(end);
    setStart(start);
    // Add logic to handle cursor movement
  };

  const [adminTags, setAdminTags] = useState([]);
  const [allTags, setAllTags] = useState<any[]>([]);
  const [moduleId, setModuleId] = useState("");
  const [pipelineId, setPipelineId] = useState("");
  const [allPipelinesList, setAllPipelineList] = useState([]);

  const getTags = async () => {
    try {
      let allPipelines = await listAllPipelines(
        await selectedModuleId("contacts"),
        ""
      );
      setModuleId(await selectedModuleId("contacts"));
      setPipelineId(getValue(allPipelines, `data.[${0}].id`, ""));
      let list = getValue(allPipelines, `data`, []).map((item: object) => ({
        ...item,
        value: getValue(item, `id`, ""),
        label: getValue(item, `label`, ""),
      }));
      setAllPipelineList(list);
      tagsByPipelineID(getValue(allPipelines, `data.[${0}].id`, ""));
    } catch (error) {}
  };
  const tagsByPipelineID = async (id: string) => {
    try {
      let payload = {
        pipelineId: id,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllAvailableTemplateTags(
        await selectedModuleId("contacts"),
        queryRequest
      );
      let response = await getloggedInuserTemplateTags();
      // Transform `response` data
      let responseList = getValue(response, `data`, []).map((item: object) => ({
        ...item,
        placeHolderOld: getValue(item, `placeHolder`, ""),
        placeHolder: `[[${getValue(item, `name`, "")}]]`,
      }));
      setAdminTags(responseList);

      if (resp) {
        let list = getValue(resp, `data`, []).map((item: object) => ({
          ...item,
          placeHolderOld: getValue(item, `placeHolder`, ""),
          placeHolder: `[[${getValue(item, `name`, "")}]]`,
        }));
        const uniqueData: any = _.uniqBy(list, "fieldId");
        setAllTags(uniqueData);
      }
    } catch (error) {}
  };

  const [findInfo, setFindInfo] = useState({});
  const newfun = (arr1: any, arr2: any, arr3: any) => {
    const arr3Dict = arr3.reduce((acc: any, item: any) => {
      acc[item.name] = item;
      return acc;
    }, {});
    const newResult = arr1
      .map((item: any) => {
        const placeholderName = item.placeholder
          .replace("[[", "")
          .replace("]]", "");

        if (arr3Dict[placeholderName]) {
          const arr2Item = arr2.find(
            (x: any) => x.placeholder === item.placeholder
          );
          const fieldId = arr2Item ? arr2Item.fieldId : "";
          const sample = arr2Item ? arr2Item.sample : "";
          const sampleInfo = getValue(findInfo, `tags`, []).find(
            (item: object) => getValue(item, `module_field_id`, "") === fieldId
          );
          return {
            type: item.type,
            label: arr3Dict[placeholderName].label,
            name: arr3Dict[placeholderName].name,
            sample: sample ? sample : getValue(sampleInfo, `sample`, ""),
            placeholder: item.placeholder,
            fieldId: fieldId
              ? fieldId
              : arr3Dict[placeholderName].fieldId
              ? arr3Dict[placeholderName].fieldId
              : getValue(sampleInfo, `module_field_id`, ""),
          };
        }
        return null;
      })
      .filter((item: any) => item !== null);

    return newResult;
  };

  const getBodyText = (request: any) => {
    if (!request || !request.components || !Array.isArray(request.components)) {
      return "";
    }

    const bodyComponent = request.components.find(
      (component: any) => component.type === "BODY"
    );
    return bodyComponent && bodyComponent.text ? bodyComponent.text : "";
  };

  const handleChangeEditor = (text: any) => {
    let value = text; // No need for formatMarkdownText since it's commented out
    setParentChage(true);

    // Check if the text length is within the limit
    if (getStringContentLength(value) <= 1024) {
      // Update the components array by finding the BODY component and updating its text
      const updatedComponents = request.components.map((component: any) => {
        if (component.type === "BODY") {
          return { ...component, text: value };
        }
        return component;
      });

      // Update the request with the new components array
      let updatedRequest = {
        ...request,
        components: updatedComponents,
      };

      // Extract placeholders (e.g., {{1}}, {{2}}) and convert them to [[]] format
      const placeholderRegex = /{{[0-9]+}}/g;
      const matches = value.match(placeholderRegex);

      if (matches) {
        // Replace placeholders in the text with [[]]
        const updatedText = value.replace(placeholderRegex, "[[]]");
        updatedRequest = {
          ...request,
          components: request.components.map((component: any) => {
            if (component.type === "BODY") {
              return { ...component, text: updatedText };
            }
            return component;
          }),
        };

        // Create custom tags for each placeholder
        const newCustomTags = matches.map((match: any) => ({
          type: "body",
          sample: "",
          placeholder: "[[]]", // Since we're replacing all {{number}} with [[]]
          fieldId: "",
        }));

        // Update custom tags using your newfun function
        const tags = newfun(newCustomTags, request.customTags, allTags);
        updatedRequest = {
          ...updatedRequest,
          customTags: tags,
        };
      } else {
        // No placeholders found, clear custom tags
        updatedRequest = {
          ...updatedRequest,
          customTags: [],
        };
      }

      // Update the request state
      setRequest(updatedRequest);
    }
  };

  const replacePlaceholderHelper = (text: string) => {
    // let text = replaceDefaultPlaceholders(val);
    if (text)
      getValue(request, `customTags`, []).forEach((item: object) => {
        text =
          text &&
          text.replace(
            getValue(item, `placeholder`, ""),
            getValue(item, `sample`, "")
              ? getValue(item, `sample`, "")
              : getValue(item, `placeholder`, "")
          );
      });
    return formatMarkdownText(text);
  };

  const [selectedPlaceholder, setSelectedPlaceholder] = useState<any>(null);
  const handleSelectPlaceholder = (record: any) => {
    setSelectedPlaceholder(getValue(record, `value`, ""));
    handleModal();
  };

  const columns = [
    {
      title: "Placeholder",
      dataIndex: "value",
    },
    {
      title: "Label",
      dataIndex: "label",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text: any, record: any) => (
        <Button type="primary" onClick={() => handleSelectPlaceholder(record)}>
          Select Variable
        </Button>
      ),
    },
  ];

  return (
    <SidebarLayout {...props}>
      <PipelineSubHeader
        subHeaderListTitle="Sync Templates"
        showBack
        handleNavigateBack={() => {
          navigate(
            `/${getValue(params, `orgId`, "")}/broadcast?activeTab=3&page_no=1`
          );
        }}
      />
      <div className="templates-meta-container">
        <div className="d-flex gap-2 align-items-center">
          <InputRuleForm
            label="Whatsapp Account"
            inputType="TEXT"
            value={whatsappAccount}
            name="whatsappAccount"
            placeholder="Enter Whatsapp Account"
            onChange={(e: any) => setWhatsappAccount(e.target.value)}
          />
          <Button
            type="primary"
            icon={<SyncOutlined />}
            className="mt-3"
            onClick={handleFetchTemplates}
          >
            Fetch Templates{" "}
          </Button>
        </div>
        {loading ? (
          <div className="container">
            <Spin />
          </div>
        ) : (
          <div className="templates-content">
            <TemplatesFromMeta
              templateList={templateList}
              handleSubmit={handleOpenTemplate}
            />
          </div>
        )}
      </div>

      <Modal
        open={isOpenSync}
        onCancel={() => setIsOpenSync(false)}
        width={1000}
        title={formatCamelString(getValue(request, `name`, ""))}
        footer={
          <div className="d-flex justify-content-end">
            <Button
              type="primary"
              onClick={() => handleSubmit(request)}
              disabled={syncing}
              loading={syncing}
            >
              Copy to Appzo
            </Button>
          </div>
        }
      >
        <div className="mb-2 d-flex gap-2 align-items-center text-muted bg-light p-2 rounded">
          <Info size={12} />
          <p>
            Make sure to add the variables to the template before copying it to
            Appzo. You will be able to edit it after saving in Appzo.
          </p>
        </div>
        <div className="d-flex gap-2">
          <div className="w-50">
            {/* <div className="d-flex justify-content-end">
              <Button type="primary" onClick={() => handleModal()}>
                Add variable
              </Button>
            </div> */}
            <MarkdownEditor
              handleChangeEditor={handleChangeEditor}
              handleCursorMovement={handleCursorMovement}
              value={getBodyText(request)}
              parentChage={parentChage}
              setParentChage={setParentChage}
              style={{
                minHeight: "40vh",
                overflowY: "auto",
                resize: "vertical",
              }}
              removeEnterAction
              maxLength={1024}
              showMaxLength
              disabled
            />

            <div>
              <Table
                dataSource={placeholders}
                columns={columns}
                pagination={false}
              />
            </div>
          </div>

          <div
            className="mobile-template"
            style={{
              textAlign: "left",
              fontFamily:
                '"SF Pro Text", "SF Pro Icons", system, -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", "Arial", "Lucida Grande", "Kohinoor Devanagari", sans-serif',
            }}
          >
            <div className="mobile-border">
              <div className="dark-green-header"></div>
              <div className="green-header"></div>
              <div className="mobile-screen">
                <div className="d-flex justify-content-center">
                  <p className="meta-message">
                    <Info size={12} /> This business uses a secure service from
                    Meta to manage this chat. Tap to learn more
                  </p>
                </div>
                <div className="message-box sdsd ">
                  <MarkdownPreview
                    // source={replaceDefaultPlaceholdersForView(
                    //   getValue(request, `body`, "")
                    // )}
                    source={replacePlaceholderHelper(getBodyText(request))}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <AddWhatsappVariable
        isOpen={isOpen}
        handleModal={handleModal}
        handleAddTags={handleAddTags}
        allTags={allTags}
        adminTags={adminTags}
        tagsByPipelineID={tagsByPipelineID}
        allPipelinesList={allPipelinesList}
        pipelineId={pipelineId}
      />
    </SidebarLayout>
  );
}

export default SyncTemplates;
