import SidebarLayout from "@layouts/SidebarLayout/SidebarLayout";
import React, { useEffect, useState } from "react";
import {
  getChatAssignmentStatusDashboard,
  getChatDashboard,
} from "@services/dashboard.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { dateFilters } from "@common/Date-Helpers/date-filters-helpers";
import { getValue } from "@utils/lodash";
import { usePermissionContext } from "@context/permissionContext";
import { useLocation } from "react-router-dom";
import DashboardHeader from "../../CRM/components/header";
import TeamChart from "./components/team-chart";
import ChatbotChart from "./components/chatbot-chart";
import AichatbotChart from "./components/aichatbot-chart";
import ChatAssignmentChart from "./components/chat-assignment-chart";
import { useStateContext } from "@context/dataContext";

function ChatDashboard(props: any) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const location = useLocation();
  const { view, setView, setChatView, viewList } = props;
  const { permissionAPITriggered } = usePermissionContext();
  const { refreshBookmarks, bookmarkedModules, themeColor } = useStateContext();
  const [dashboardLoading, setDashboardLoading] = useState(false);
  useEffect(() => {
    if (Object.keys(UrlParams).length === 0 && permissionAPITriggered) {
      getTeamData(teamSource);
      getChatbotData(chatbotSource);
      getAIChatbitData(aiChatbotSource);
      getChatAssignmentStatusData();
    }
  }, [permissionAPITriggered]);
  useEffect(() => {
    if (Object.keys(UrlParams).length > 0 && permissionAPITriggered) {
      getTeamData(teamSource);
      getChatbotData(chatbotSource);
      getAIChatbitData(aiChatbotSource);
      getChatAssignmentStatusData();
    }
  }, [
    window.location.href,
    getValue(location, `key`, ""),
    permissionAPITriggered,
  ]);

  const [teamDashboardData, setTeamDashboardData] = useState([]);
  const [chatbotDashboardData, setChatbotDashboardData] = useState([]);
  const [aiChatbotDashboardData, setAiChatbotDashboardData] = useState([]);
  const [
    chatAssignmentStatusDashboardData,
    setChatAssignmentStatusDashboardData,
  ] = useState([]);

  const [teamSource, setTeamSource] = useState("");
  const getTeamData = async (teamSource: string) => {
    try {
      setDashboardLoading(true);
      const payload = {
        managed_by: "team",
        source: teamSource,
        filter_messaged_at: getValue(UrlParams, `date_range`, "")
          ? getValue(UrlParams, `date_range`, "")
          : getValue(dateFilters, `[${0}].value`, ""),
      };
      const queryRequest = QueryRequestHelper(payload);
      const response = await getChatDashboard(queryRequest);
      if (response) {
        setTeamDashboardData(getValue(response, `data`, []));
      }
    } catch (error) {}
  };

  const [chatbotSource, setChatbotSource] = useState("");
  const getChatbotData = async (chatbotSource: string) => {
    try {
      setDashboardLoading(true);
      const payload = {
        managed_by: "chatbot",
        source: chatbotSource,
        filter_messaged_at: getValue(UrlParams, `date_range`, "")
          ? getValue(UrlParams, `date_range`, "")
          : getValue(dateFilters, `[${0}].value`, ""),
      };
      const queryRequest = QueryRequestHelper(payload);
      const response = await getChatDashboard(queryRequest);
      if (response) {
        setChatbotDashboardData(getValue(response, `data`, []));
      }
    } catch (error) {}
  };

  const [aiChatbotSource, setAiChatbotSource] = useState("");
  const getAIChatbitData = async (aiChatbotSource: string) => {
    try {
      setDashboardLoading(true);
      const payload = {
        managed_by: "ai-chatbot",
        source: aiChatbotSource,
        filter_messaged_at: getValue(UrlParams, `date_range`, "")
          ? getValue(UrlParams, `date_range`, "")
          : getValue(dateFilters, `[${0}].value`, ""),
      };
      const queryRequest = QueryRequestHelper(payload);
      const response = await getChatDashboard(queryRequest);
      if (response) {
        setAiChatbotDashboardData(getValue(response, `data`, []));
      }
    } catch (error) {}
  };
  const getChatAssignmentStatusData = async () => {
    try {
      const payload = {
        filter_messaged_at: getValue(UrlParams, `date_range`, "")
          ? getValue(UrlParams, `date_range`, "")
          : getValue(dateFilters, `[${0}].value`, ""),
      };
      const queryRequest = QueryRequestHelper(payload);
      const response = await getChatAssignmentStatusDashboard(queryRequest);
      if (response) {
        setChatAssignmentStatusDashboardData(getValue(response, `data`, []));
      }
    } catch (error) {}
  };
  return (
    <SidebarLayout {...props} isLoading={dashboardLoading}>
      <DashboardHeader
        hideCloseDate={false}
        allModules={[]}
        setSelectedModule={() => {}}
        setSelectedPipeline={() => {}}
        setSelectedDateRange={() => {}}
        setSelectedFilter={() => {}}
        setSelectedView={() => {}}
        setSelectedUser={() => {}}
        showSourceFilter={true}
        view={view}
        setView={setView}
        setChatView={setChatView}
        refreshBookmarks={refreshBookmarks}
        bookmarkedModules={bookmarkedModules}
        themeColor={themeColor}
        bookmarkedModuleName="dashboard"
        bookmarkedModulePath="dashboard"
        viewList={viewList}
        {...props}
      />
      <div className="manageFieldRight overflow-y-auto p-4 chat-overview-container">
        <TeamChart
          data={teamDashboardData}
          setSource={setTeamSource}
          source={teamSource}
          getDashboardData={getTeamData}
        />
        <ChatbotChart
          data={chatbotDashboardData}
          setSource={setChatbotSource}
          source={chatbotSource}
          getDashboardData={getChatbotData}
        />

        <AichatbotChart
          data={aiChatbotDashboardData}
          setSource={setAiChatbotSource}
          source={aiChatbotSource}
          getDashboardData={getAIChatbitData}
        />

        <ChatAssignmentChart
          data={chatAssignmentStatusDashboardData}
          getDashboardData={getChatAssignmentStatusData}
        />
      </div>
    </SidebarLayout>
  );
}

export default ChatDashboard;
