import { getValue } from "@utils/lodash";

export const GeneralNavigationList = (params: any) => [
  {
    title: "ORGANISATION",
    name: "settings",
    linkArray: [
      {
        linkTitle: "Company Profile",
        linkUrl: `/${getValue(
          params,
          `orgId`,
          ""
        )}/settings/general/company-profile`,
        path: "company-profile",
        image_url: "/images/company_profile.svg",
        name: "settings_organisation_profile",
      },
      // {
      //   linkTitle: "Branding",
      //   linkUrl: `/${getValue(params, `orgId`, "")}/settings/general/branding`,
      //   path: "branding",
      //   image_url: "/images/branding.svg",
      //   name: "settings_organisation_branding",
      // },
      {
        linkTitle: "Company Payment",
        linkUrl: `/${getValue(
          params,
          `orgId`,
          ""
        )}/settings/general/company-payment`,
        path: "company-payment",
        image_url: "/images/company_payment.svg",
        name: "settings_organisation_branding",
      },
    ],
  },
  {
    title: "TEAM",
    name: "settings",
    linkArray: [
      {
        linkTitle: "Users",
        linkUrl: `/${getValue(params, `orgId`, "")}/settings/general/users`,
        path: "users",
        image_url: "/images/users.svg",
        name: "settings_team_users",
      },
      {
        linkTitle: "Roles",
        linkUrl: `/${getValue(params, `orgId`, "")}/settings/general/roles`,
        path: "roles",
        image_url: "/images/users.svg",
        name: "settings_team_roles",
      },
      {
        linkTitle: "Agent Status",
        linkUrl: `/${getValue(
          params,
          `orgId`,
          ""
        )}/settings/general/agent-status`,
        path: "agent-status",
        image_url: "/images/users.svg",
        name: "settings_team_roles",
      },
    ],
  },
  {
    title: "TEMPLATES",
    name: "settings",
    linkArray: [
      {
        linkTitle: "Emails",
        linkUrl: `/${getValue(
          params,
          `orgId`,
          ""
        )}/settings/general/emails/templates`,
        path: "emails",
        image_url: "/images/emails.svg",
        name: "settings_templates_email",
      },
      {
        linkTitle: "Canned Response",
        linkUrl: `/${getValue(
          params,
          `orgId`,
          ""
        )}/settings/general/canned-response`,
        path: "canned",
        image_url: "/images/emails.svg",
        name: "settings_templates_canned_responses",
      },
      {
        linkTitle: "Media Library",
        linkUrl: `/${getValue(
          params,
          `orgId`,
          ""
        )}/settings/general/media-library`,
        path: "media-library",
        image_url: "/images/emails.svg",
        name: "settings_templates_canned_responses",
      },
      {
        linkTitle: "Tags",
        linkUrl: `/${getValue(params, `orgId`, "")}/settings/general/tags`,
        path: "tags",
        image_url: "/images/modules_fields.svg",
        name: "settings_templates_canned_responses",
      },
    ],
  },
  // {
  //   title: "Market Place",
  //   name: "settings",
  //   linkArray: [
  //     {
  //       linkTitle: "Apps",
  //       linkUrl: `/${getValue(params, `orgId`, "")}/settings/emails/config`,
  //       path: "emails",
  //       image_url: "/images/emails.svg",
  //       name: "settings_templates_email",
  //     },
  //   ],
  // },
];
export const CRMNavigationList = (params: any) => [
  {
    title: "LEAD CAPTURE",
    name: "settings",
    linkArray: [
      {
        linkTitle: "Web Forms",
        linkUrl: `/${getValue(params, `orgId`, "")}/settings/crm/forms`,
        path: "forms",
        image_url: "/images/forms.svg",
        name: "settings_lead_capture_web_forms",
      },
      {
        linkTitle: "Ad Leads",
        linkUrl: `/${getValue(params, `orgId`, "")}/settings/crm/ads`,
        path: "ads",
        image_url: "/images/ads.svg",
        name: "settings_lead_capture_ad_leads",
      },
    ],
  },
  {
    title: "AUTOMATION",
    name: "settings",
    linkArray: [
      {
        linkTitle: "Workflows",
        linkUrl: `/${getValue(params, `orgId`, "")}/settings/crm/workflows`,
        path: "workflows",
        image_url: "/images/emails.svg",
        name: "settings_automation_workflows",
      },
    ],
  },
  {
    title: "CUSTOMIZATION",
    name: "settings",
    linkArray: [
      {
        linkTitle: "Modules and Fields",
        linkUrl: `/${getValue(
          params,
          `orgId`,
          ""
        )}/settings/crm/module-and-field`,
        path: "module-and-field",
        image_url: "/images/modules_fields.svg",
        name: "settings_customisation_modules_and_fields",
      },
      {
        linkTitle: "Sale Items",
        linkUrl: `/${getValue(params, `orgId`, "")}/settings/crm/sale-items`,
        path: "sale-items",
        image_url: "/images/modules_fields.svg",
        name: "settings_customisation_modules_and_fields",
      },
    ],
  },
  {
    title: "REPORTS",
    name: "settings",
    linkArray: [
      {
        linkTitle: "Bulk Download",
        linkUrl: `/${getValue(
          params,
          `orgId`,
          ""
        )}/settings/crm/data-administration-exports`,
        path: "data-administration-exports",
        image_url: "/images/emails.svg",
        name: "settings_reports_bulk_download",
      },
    ],
  },
];
export const HelpdestNavigationList = (params: any) => [
  {
    title: "CONFIGURATION",
    name: "settings",
    linkArray: [
      // {
      //   linkTitle: "Agents",
      //   linkUrl: `/${getValue(params, `orgId`, "")}/settings/helpdesk/agents`,
      //   path: "agents",
      //   image_url: "/images/emails.svg",
      //   name: "settings_reports_bulk_download",
      // },
      {
        linkTitle: "Ticket Fields",
        linkUrl: `/${getValue(
          params,
          `orgId`,
          ""
        )}/settings/helpdesk/ticket-fields`,
        path: "fields",
        image_url: "/images/emails.svg",
        name: "settings_reports_bulk_download",
      },
      {
        linkTitle: "Groups",
        linkUrl: `/${getValue(params, `orgId`, "")}/settings/helpdesk/groups`,
        path: "groups",
        image_url: "/images/emails.svg",
        name: "settings_reports_bulk_download",
      },
      {
        linkTitle: "Business Hours",
        linkUrl: `/${getValue(
          params,
          `orgId`,
          ""
        )}/settings/helpdesk/business-hours`,
        path: "business-hours",
        image_url: "/images/emails.svg",
        name: "settings_reports_bulk_download",
      },
      {
        linkTitle: "Agent Shifts",
        linkUrl: `/${getValue(
          params,
          `orgId`,
          ""
        )}/settings/helpdesk/agent-shifts`,
        path: "agent-shifts",
        image_url: "/images/emails.svg",
        name: "settings_reports_bulk_download",
      },
      {
        linkTitle: "Assignment Rules",
        linkUrl: `/${getValue(
          params,
          `orgId`,
          ""
        )}/settings/helpdesk/assignment-rules`,
        path: "skills",
        image_url: "/images/emails.svg",
        name: "settings_reports_bulk_download",
      },
    ],
  },
  {
    title: "AUTOMATION",
    name: "settings",
    linkArray: [
      {
        linkTitle: "SLA Policy",
        linkUrl: `/${getValue(
          params,
          `orgId`,
          ""
        )}/settings/helpdesk/sla-policy`,
        path: "sla-policy",
        image_url: "/images/emails.svg",
        name: "settings_reports_bulk_download",
      },
      {
        linkTitle: "Tickets",
        linkUrl: `/${getValue(params, `orgId`, "")}/settings/helpdesk/tickets`,
        path: "tickets-automation",
        image_url: "/images/emails.svg",
        name: "settings_reports_bulk_download",
      },
    ],
  },
  {
    title: "CUSTOMIZATION",
    name: "settings",
    linkArray: [
      {
        linkTitle: "Products",
        linkUrl: `/${getValue(params, `orgId`, "")}/settings/helpdesk/products`,
        path: "products",
        image_url: "/images/emails.svg",
        name: "settings_reports_bulk_download",
      },
      // {
      //   linkTitle: "Ticket Fields",
      //   linkUrl: `/${getValue(params, `orgId`, "")}/settings/helpdesk/tickets`,
      //   path: "tickets",
      //   image_url: "/images/emails.svg",
      //   name: "settings_reports_bulk_download",
      // },
    ],
  },
];
export const ChatNavigationList = (params: any) => [
  {
    title: "CHANNEL",
    name: "settings",
    linkArray: [
      {
        linkTitle: "WhatsApp",
        linkUrl: `/${getValue(params, `orgId`, "")}/settings/chat/messages`,
        path: "whatsapp",
        image_url: "/images/emails.svg",
        name: "settings_channels_whatsapp",
      },
      {
        linkTitle: "WebChat",
        linkUrl: `/${getValue(params, `orgId`, "")}/settings/chat/webchat`,
        path: "webchat",
        image_url: "/images/globe.svg",
        name: "settings_channels_whatsapp",
      },
      {
        linkTitle: "Phone",
        linkUrl: `/${getValue(params, `orgId`, "")}/settings/chat/phone`,
        path: "phone",
        image_url: "/images/globe.svg",
        name: "settings_channels_whatsapp",
      },
    ],
  },
  // {
  //   title: "Widget",
  //   name: "settings",
  //   linkArray: [
  //     {
  //       linkTitle: "Setup",
  //       linkUrl: `/${getValue(params, `orgId`, "")}/settings`,
  //       path: "whatsapp",
  //       image_url: "/images/emails.svg",
  //       name: "settings_channels_whatsapp",
  //     },
  //   ],
  // },
  {
    title: "AUTOMATION",
    name: "settings",
    linkArray: [
      {
        linkTitle: "Phone Assignment",
        linkUrl: `/${getValue(
          params,
          `orgId`,
          ""
        )}/settings/chat/phone-assignment`,
        path: "phone-assignment",
        image_url: "/images/emails.svg",
        name: "settings_channels_whatsapp",
      },
    ],
  },
];
export const AINavigationList = (params: any) => [
  {
    title: "Co-Pilot",
    name: "settings",
    linkArray: [
      {
        linkTitle: "Organisation",
        linkUrl: `/${getValue(params, `orgId`, "")}/settings`,
        path: "whatsapp",
        image_url: "/images/emails.svg",
        name: "settings_channels_whatsapp",
      },
      {
        linkTitle: "CRM",
        linkUrl: `/${getValue(params, `orgId`, "")}/settings`,
        path: "whatsapp",
        image_url: "/images/emails.svg",
        name: "settings_channels_whatsapp",
      },
      {
        linkTitle: "Desk",
        linkUrl: `/${getValue(params, `orgId`, "")}/settings`,
        path: "whatsapp",
        image_url: "/images/emails.svg",
        name: "settings_channels_whatsapp",
      },
      {
        linkTitle: "Chat",
        linkUrl: `/${getValue(params, `orgId`, "")}/settings`,
        path: "whatsapp",
        image_url: "/images/emails.svg",
        name: "settings_channels_whatsapp",
      },
    ],
  },
];
export const filterNavigationListNew = (
  tabPermissionList: any,
  subTabPermissionList: any,
  navigationList: any[]
) => {
  // Filter the main tabs where `name` matches any value in `tabPermissionList`
  const filteredTabs = navigationList.filter((tab) =>
    tabPermissionList.includes(tab.name)
  );
  // For each filtered tab, filter its `linkArray` based on `subTabPermissionList`
  const updatedTabs = filteredTabs.map((tab) => ({
    ...tab,
    linkArray: tab.linkArray.filter((link: any) =>
      subTabPermissionList.includes(link.name)
    ),
  }));
  // Remove any tabs that end up with an empty linkArray
  return updatedTabs.filter((tab) => tab.linkArray.length > 0);
};
