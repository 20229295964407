import { Tabs } from "antd";
import { getValue } from "@utils/lodash";
import { concatString, formatString } from "@common/text-helpers";
import { useStateContext } from "@context/dataContext";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;

function NavViews(props: any) {
  const navigate = useNavigate();
  const { userId } = useStateContext();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  let views = [
    {
      id: "",
      value: "",
      name: `All ${formatString(getValue(props, `plural_label`, ""))}`,
      key: "view_all",
    },
    {
      id: userId,
      name: `My ${formatString(getValue(props, `plural_label`, ""))}`,
    },
    {
      id: "NO_OWNER",
      name: `Unassigned ${formatString(getValue(props, `plural_label`, ""))}`,
    },
  ];

  return (
    <div className="ms-4 mt-3">
      <Tabs
        activeKey={
          !getValue(props, `permissions`, []).includes("view_all")
            ? getValue(params, `filter_owner_id`, "") != "NO_OWNER"
              ? userId
              : getValue(params, `filter_owner_id`, "")
            : getValue(params, `filter_owner_id`, "")
        }
        onChange={(activeKey) => {
          props.handleNaviagteView("filter_owner_id", activeKey);
        }}
        type="card"
      >
        {getValue(props, `plural_label`, "") && getValue(views, `length`, 0) > 0
          ? views.map((item: any) => {
              // Conditionally render the "All" tab only if the key matches
              if (getValue(item, `key`, "") === "view_all") {
                if (getValue(props, `permissions`, []).includes("view_all")) {
                  return (
                    <TabPane
                      tab={
                        <p className="d-flex justify-content-between align-items-center">
                          {concatString(getValue(item, `name`, ""), 20)}
                        </p>
                      }
                      key={getValue(item, `id`, "")}
                    />
                  );
                }
              } else {
                // Always render other tabs
                return (
                  <TabPane
                    tab={
                      <p className="d-flex justify-content-between align-items-center">
                        {concatString(getValue(item, `name`, ""), 20)}
                      </p>
                    }
                    key={getValue(item, `id`, "")}
                  />
                );
              }
              return null;
            })
          : null}
      </Tabs>
    </div>
  );
}

export default NavViews;
