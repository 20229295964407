import { Table, Button, Typography } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { getValue } from "@utils/lodash";
import InputRuleForm from "@components/InputRuleForm/form";
import AntSearchToolTip from "@components/custom/DebounceSelect";

const { Text } = Typography;

interface Product {
  key: string;
  action: string;
  name: string;
  list_price: number;
  quantity: number;
  discount: number;
  total: number;
  sale_item_id: string;
}

export default function ProductForm(props: any) {
  const { products, setProducts, setProductsCount, addProduct } = props;

  const removeProduct = (index: number) => {
    const updatedProducts = [...products];
    updatedProducts.splice(index, 1);
    setProducts(updatedProducts);
    setProductsCount(updatedProducts.length);
  };

  const updateProductSelect = (index: number, field: any, e: any) => {
    setProducts((prevProducts: Product[]) => {
      return prevProducts.map((product: any, i: number) => {
        if (i !== index) return product;
        // Create updated product with new field value
        const updatedProduct = {
          ...product,
          sale_item_id: getValue(e, `id`, ""),
          list_price: getValue(e, `unit_price`, 0),
          quantity: getValue(e, `quantity`, 1) ? getValue(e, `quantity`, 1) : 1,
          discount: getValue(e, `discount`, 0),
          total:
            (getValue(e, `unit_price`, 0) > 0
              ? parseInt(getValue(e, `unit_price`, 0))
              : 1) *
            (getValue(e, `quantity`, 0) > 0
              ? parseInt(getValue(e, `quantity`, 0))
              : 1) *
            (1 -
              (getValue(e, `discount`, 0) > 0
                ? parseInt(getValue(e, `discount`, 0))
                : 0) /
                100),
        };
        return updatedProduct;
      });
    });
  };

  const updateProduct = (index: number, field: any, value: number | string) => {
    setProducts((prevProducts: Product[]) => {
      return prevProducts.map((product: Product, i: number) => {
        if (i !== index) return product;
        // Create updated product with new field value
        const updatedProduct = {
          ...product,
          [field]: value,
        };
        // Recalculate total if price-affecting fields change
        if (["list_price", "quantity", "discount"].includes(field)) {
          updatedProduct.total =
            updatedProduct.list_price *
            updatedProduct.quantity *
            (1 - updatedProduct.discount / 100);
        }
        return updatedProduct;
      });
    });
  };

  const calculateGrandTotal = () => {
    return products.reduce((sum: any, product: any) => sum + product.total, 0);
  };

  const columns: ColumnsType<Product> = [
    {
      title: "PRODUCT DETAILS",
      key: "details",
      width: 350,
      render: (_, record, index) => (
        <AntSearchToolTip
          label={"label"}
          hideLabel
          lookup_api={"sale_items"}
          value={getValue(record, `sale_item_id`, "")}
          selectKey={"id"}
          onChange={(e: any, data: any) => {
            updateProductSelect(index, "sale_item_id", data);
          }}
        />
      ),
    },
    {
      title: "LIST PRICE",
      key: "list_price",
      width: 150,
      render: (_, record, index) => (
        <InputRuleForm
          inputType="NUMBER"
          min={0}
          value={record.list_price}
          onChange={(e: any) =>
            updateProduct(index, "list_price", e.target.value)
          }
          hideLabel
        />
      ),
    },
    {
      title: "QUANTITY",
      key: "quantity",
      width: 120,
      render: (_, record, index) => (
        <InputRuleForm
          inputType="NUMBER"
          min={1}
          value={record.quantity}
          onChange={(e: any) =>
            updateProduct(index, "quantity", e.target.value)
          }
          hideLabel
        />
      ),
    },
    {
      title: "DISCOUNT",
      key: "discount",
      width: 120,
      render: (_, record, index) => (
        <InputRuleForm
          inputType="NUMBER"
          min={0}
          max={100}
          value={record.discount}
          onChange={(e: any) =>
            updateProduct(index, "discount", e.target.value)
          }
          hideLabel
        />
      ),
    },
    {
      title: "TOTAL",
      key: "total",
      width: 150,
      render: (_, record) => record.total.toFixed(2),
    },
    {
      title: "ACTION",
      key: "action",
      width: 80,
      render: (_, record, index) => (
        <Button
          type="text"
          icon={<MinusCircleOutlined />}
          onClick={() => removeProduct(index)}
        />
      ),
    },
  ];

  return (
    <div className="">
      <div className="mb-3 d-flex justify-content-end">
        <Button type="primary" icon={<PlusOutlined />} onClick={addProduct}>
          Add Product
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={products}
        pagination={false}
        bordered
        summary={() => (
          <Table.Summary>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={4}>
                <Text strong>Grand Total</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <Text strong type="success">
                  {calculateGrandTotal().toFixed(2)}
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
        scroll={{ x: "max-content", y: "calc(100vh - 300px)" }}
        tableLayout="fixed"
      />
    </div>
  );
}
