import { getValue } from "@utils/lodash";
import { Checkbox } from "antd";
import React, { ChangeEvent } from "react";
type Props = {
  disabled: boolean;
  name: string;
  type: string;
  value: any;
  onChange: any;
  maxLength: number;
  placeholder: string;
  validator: any;
  label: string;
  mandatory: boolean;
  hideLabel: boolean;
  validatoryType: string;
  data: any;
};

export default function InlineCheckbox({
  disabled,
  name,
  type,
  value,
  onChange,
  maxLength,
  placeholder,
  validator,
  label,
  mandatory,
  hideLabel,
  validatoryType,
  data,
}: Props) {
  return (
    <div
    // className={`signup-form-wrapper__form-group ${
    //   validator && validator.current.message(label, value, "required")
    //     ? "signup-form-wrapper__form-group--error"
    //     : ""
    // } form-group mb-3`}
    >
      {/* {!hideLabel && (
        <label className={`form-label ms-2 mb-2`}>
          {label} {mandatory && "*"}
        </label>
      )}
      <br /> */}
      <div className="ms-2 mt-2">
        <div className="">
          <div className="d-flex align-items-center mb-2">
            <Checkbox
              // className="custom-form-control-checkbox"
              disabled={false || disabled}
              name={name}
              type={"checkbox"}
              checked={value ? true : false} // Check for null directly
              onChange={onChange}
              // placeholder={placeholder}
            >
              {label}
            </Checkbox>
          </div>
        </div>
      </div>
      {validator && (
        <p className={`error-text`}>
          {validator.current.message(
            label,
            value,
            validatoryType ? validatoryType : "required"
          )}
        </p>
      )}
    </div>
  );
}
