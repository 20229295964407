import InputRuleForm from "@components/InputRuleForm/form";
import WhatsappIconViewPage from "@pages/Private/Broadcast/components/common/whatsapp-icon";
import { getValue } from "@utils/lodash";
import { config } from "../../../../../../env";
import { Button, Modal, Radio, Select, Space } from "antd";
import AntSearchToolTip from "@components/custom/DebounceSelect";
import NewSearchSelect from "@components/custom/Dropdown/AntDropDown";
function MessageOptOutPopup(props: any) {
  const {
    request,
    setRequest,
    templateList,
    messageTypeEnum,
    submitLoading,
    handleFileChange,
  } = props;

  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      onOk={props.handleSubmit}
      onCancel={props.handleModal}
      width={1000}
      footer={[
        <Button
          key="cancel"
          onClick={props.handleModal}
          className="cancel__button"
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={props.handleSubmit}
          className="submit__button"
        >
          Submit
        </Button>,
      ]}
    >
      {/* <div className="d-flex align-items-center justify-content-between p-3 px-3 py-3 border-bottom"> */}
      <div>
        <h6 className="header_text__16">Configure Message</h6>
        <p className="small_text__14 mt-3">
          Send template message from one of your pre approved templates. You can
          also opt to send regular message to active users.
        </p>
      </div>
      {/* <div onClick={props.handleModal}>
          <CloseMainSvgComponent size={24} />
        </div>
      </div> */}
      {/* <ModalBody> */}
      <div className="px-3 mt-3">
        <Radio.Group
          onChange={(e) =>
            setRequest({
              ...request,
              responseType: e.target.value,
            })
          }
          value={getValue(request, `responseType`, "")}
        >
          <Space direction="vertical">
            <Radio value="template" className="common_radio">
              <p className="small_text__14 ">Pre-approved template message</p>
            </Radio>

            <Radio value="message" className="common_radio">
              <p className="small_text__14 ">Regular Message</p>
            </Radio>
            <Radio value="pipeline_stage" className="common_radio">
              Pipeline Status
            </Radio>
          </Space>
        </Radio.Group>

        <div className="mt-3">
          {getValue(request, `responseType`, "") === "template" && (
            <div className="d-flex align-items-start justify-content-between w-100">
              <div className="w-50">
                <h6 className="header_text__16">Template Name</h6>
                <p className="small_text__14">
                  Please choose a WhatsApp template message from your approved
                  list
                </p>
                <div className="mt-3">
                  <AntSearchToolTip
                    label="Select Template Message"
                    options={templateList}
                    selectKey={"id"}
                    lookup_api={"templates"}
                    value={getValue(request, `templateId`, "")}
                    onChange={(e: any) =>
                      setRequest({
                        ...request,
                        templateId: e,
                      })
                    }
                    placeholder="Choose template message"
                  />
                </div>
              </div>
              {getValue(request, `templateId`, "") && (
                <div className="template-wrapper">
                  <div className=" template-container">
                    <WhatsappIconViewPage
                      request={templateList.find(
                        (item: object) =>
                          getValue(item, `id`, "") ===
                          getValue(request, `templateId`, "")
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
          )}

          {getValue(request, `responseType`, "") === "message" && (
            <div className="d-flex">
              <div className="w-50">
                <div className="">
                  <h6 className="header_text__16">
                    Message Type{" "}
                    {/* {getValue(request, `autoResponseMsgType`, "").toUpperCase()} */}
                  </h6>

                  <div className="mt-1">
                    <label className="form-label">
                      Select one of available message types
                    </label>
                    <Select
                      size="large"
                      style={{ width: "100%" }}
                      placeholder="Choose message type"
                      value={getValue(request, "autoResponseMsgType", "")}
                      onChange={(value) =>
                        setRequest({
                          ...request,
                          autoResponseMsgType: value,
                        })
                      }
                      options={messageTypeEnum}
                    />
                  </div>
                </div>

                <div className="mt-3">
                  {getValue(request, `autoResponseMsgType`, "") === "text" && (
                    <>
                      <h6 className="header_text__16">Message</h6>

                      <div>
                        <InputRuleForm
                          inputType="TEXTAREA"
                          maxLength={4096}
                          placeholder="Your message goes here"
                          value={getValue(request, `autoResponseMsgTxt`, "")}
                          onChange={(e: any) =>
                            setRequest({
                              ...request,
                              autoResponseMsgTxt: e.target.value,
                            })
                          }
                          label="Your message can be upto 4096 characters long."
                        />
                      </div>
                    </>
                  )}

                  {getValue(request, `autoResponseMsgType`, "") === "image" && (
                    <div>
                      <p
                        // htmlFor="file-upload"
                        className="custom-file-upload mb-2 requirement-message"
                      >
                        Choose a{" "}
                        <span className="required-file-format">
                          .jpg or .png
                        </span>{" "}
                        file
                      </p>
                      <input
                        type="file"
                        onChange={(e) => handleFileChange(e, "image")}
                        className="custom-upload-filename"
                        accept={".jpg,.jpeg,.png"}
                      />
                    </div>
                  )}

                  {getValue(request, `autoResponseMsgType`, "") === "video" && (
                    <div>
                      <p
                        // htmlFor="file-upload"
                        className="custom-file-upload mb-2 requirement-message"
                      >
                        Choose a{" "}
                        <span className="required-file-format">.mp4</span> file
                      </p>
                      <input
                        type="file"
                        onChange={(e) => handleFileChange(e, "video")}
                        className="custom-upload-filename"
                        accept={".mp4"}
                      />
                    </div>
                  )}

                  {getValue(request, `autoResponseMsgType`, "") ===
                    "document" && (
                    <div>
                      <p
                        // htmlFor="file-upload"
                        className="custom-file-upload mb-2 requirement-message"
                      >
                        Choose a{" "}
                        <span className="required-file-format">.pdf</span> file
                      </p>
                      <input
                        type="file"
                        onChange={(e) => handleFileChange(e, "document")}
                        className="custom-upload-filename"
                        accept={".pdf"}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="w-50 ms-3">
                {getValue(request, `msg_type`, "") == "image" && (
                  <div className="">
                    <img
                      src={
                        getValue(request, `mediaId.key`, "") ||
                        getValue(request, `mediaId.url`, "")
                          ? `https://${config.URL}/${
                              getValue(request, `mediaId.key`, "")
                                ? getValue(request, `mediaId.key`, "")
                                : getValue(request, `mediaId.url`, "")
                            }`
                          : `https://${config.URL}/${
                              getValue(request, `mediaId.key`, "")
                                ? getValue(request, `mediaId.key`, "")
                                : getValue(request, `mediaId.url`, "")
                            }`
                      }
                      alt="Selected"
                      style={{
                        height: "250px",
                        width: "250px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {getValue(request, `responseType`, "") === "pipeline_stage" && (
            <div className="mt-2">
              <h6 className="header_text__16">Pipeline Stage</h6>
              <NewSearchSelect
                placeholder={`Choose Status`}
                validator={props.simpleValidator}
                options={getValue(props, `stageList`, [])}
                value={getValue(request, `pipelineStageId`, "")}
                onChange={(id: object) =>
                  setRequest({
                    ...request,
                    pipelineStageId: id,
                  })
                }
                selectKey={"id"}
                label={"Status"}
                required={true}
              />
            </div>
          )}
        </div>
      </div>
      {/* </ModalBody>
      <ModalFooter> */}
      {/* <div className="popup-submit-container">
          <ButtonComponent
            onClickHandler={props.handleModal}
            buttonType={"scecondary"}
            buttonText="Cancel"
          />
          <ButtonComponent
            buttonType={"primary"}
            buttonText={submitLoading ? "Please wait..." : "Save"}
            onClickHandler={() => props.handleSubmit()}
          />
        </div> */}
      {/* </ModalFooter> */}
    </Modal>
  );
}

export default MessageOptOutPopup;
