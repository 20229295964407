import { QueryRequestHelper } from "@common/query-request-helper";
import { capitalizeFirstLetter } from "@common/text-helpers";
import { getUsers } from "@services/users.service";
import { getValue } from "@utils/lodash";
import { Select, Alert, Checkbox } from "antd";
import { useEffect, useState } from "react";
import { toast } from "sonner";

type Condition = {
  value: string;
  label: string;
  target: string;
  violated: string;
  isSelected: any;
  isVioldatedSelected: any;
};

interface Request {
  [key: string]: {
    escalation_time?: number;
  };
}

interface PriorityDetails {
  [key: string]: string;
}

const SLADueTime = (props: any) => {
  const { mainRequest, priorityRequest, request, setRequest, editId } = props;

  const [conditions, setConditions] = useState<Condition[]>([
    {
      value: "first_response_time",
      label: "First response target",
      target: "reminder_response",
      violated: "response",
      isSelected: false,
      isVioldatedSelected: false,
    },
    {
      value: "every_response_time",
      label: "Next response target",
      target: "reminder_next_response",
      violated: "next_response",
      isSelected: false,
      isVioldatedSelected: false,
    },
    {
      value: "resolution_due_time",
      label: "Resolution target",
      target: "reminder_resolution",
      violated: "resolution_level_1",
      isSelected: false,
      isVioldatedSelected: false,
    },
  ]);

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (editId) {
      const modifiedRequest = request;

      setConditions((prevConditions) =>
        prevConditions.map((condition) => {
          const isSelected = !!modifiedRequest[condition.target];
          const isVioldatedSelected = !!modifiedRequest[condition.violated];

          return {
            ...condition,
            isSelected,
            isVioldatedSelected,
          };
        })
      );
    }
  }, [editId, mainRequest]);

  const [users, setUsers] = useState([]);
  const [isLoading3, setIsLoading3] = useState(false);

  const getAllUsers = async () => {
    try {
      setIsLoading3(true);
      const resp = await getUsers();
      if (resp) {
        const users = getValue(resp, "data", "").map((user: any) => ({
          value: getValue(user, "id", ""),
          label:
            capitalizeFirstLetter(getValue(user, "first_name", "")) +
            " " +
            capitalizeFirstLetter(getValue(user, "last_name", "")),
        }));
        setUsers(users);
      } else {
      }
    } catch (error) {
      toast.error("Failed to load users.");
      setIsLoading3(false);
    } finally {
      setIsLoading3(false);
    }
  };

  const handleCheckbox = (
    e: any,
    index: number,
    target: string,
    violated: string,
    field: keyof Condition
  ) => {
    const isChecked = e.target.checked;

    setConditions((prevConditions) => {
      const updatedConditions = [...prevConditions];
      updatedConditions[index][field] = isChecked;
      return updatedConditions;
    });

    if (isChecked) {
      setRequest((request: any) => ({
        ...request,
        [field === "isSelected" ? target : violated]: {
          escalation_time: 1800,
          is_assigned_agent: true,
          agents: [],
        },
      }));
    } else {
      setRequest((request: any) => {
        const updatedRequest = { ...request };
        delete updatedRequest[field === "isSelected" ? target : violated];
        return updatedRequest;
      });
    }
  };

  const handleAgentChange = (e: any, target: string) => {
    setRequest((request: any) => ({
      ...request,
      [target]: {
        ...request[target],
        is_assigned_agent: getValue(e, "target.checked", false),
      },
    }));
  };

  const handleUsers = (selectedAgents: string[], target: string) => {
    setRequest((prevRequest: any) => ({
      ...prevRequest,
      [target]: {
        ...prevRequest[target],
        agents: selectedAgents.map((agent) => ({ id: agent })),
      },
    }));
  };

  const handleChangeTime = (value: any, target: string) => {
    setRequest((request: any) => ({
      ...request,
      [target]: {
        ...request[target],
        escalation_time: value,
      },
    }));
  };

  function comparePrioritiesWithEscalation(
    priorityRequest: any[],
    request: Request,
    passedKey: string
  ): string[] {
    function parseDurationToSeconds(duration: string): number {
      const regex = /P(?:(\d+)D)?T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
      const match = duration.match(regex);

      if (!match) return 0;

      const [_, days, hours, minutes, seconds] = match.map((value) =>
        parseInt(value || "0", 10)
      );

      return days * 86400 + hours * 3600 + minutes * 60 + seconds;
    }

    const condition = conditions.find((c) => c.value === passedKey);

    if (!condition?.target) {
      return [];
    }

    const escalationTime = request[condition.target]?.escalation_time;

    if (typeof escalationTime !== "number") {
      return [];
    }

    const results: string[] = [];

    for (const priority of priorityRequest) {
      const priorityTime = parseDurationToSeconds(priority[passedKey]);

      if (priorityTime <= escalationTime) {
        results.push(priority.priority_name);
      }
    }

    return results;
  }

  return (
    <div className="mt-5 mb-5">
      <h6 className="header_text__18">
        Remind agents when the SLA due time approaches
      </h6>
      <div className="mt-2">
        {conditions.map((item, index) => (
          <>
            <div key={index} className={`sla-due-time-condition-card p-4 `}>
              <div className="d-flex align-items-baseline gap-2">
                <Checkbox
                  style={{ width: "50px" }}
                  checked={getValue(item, "isSelected", false)}
                  onChange={(e) =>
                    handleCheckbox(
                      e,
                      index,
                      getValue(item, "target", ""),
                      getValue(item, "violated", ""),
                      "isSelected"
                    )
                  }
                />

                <div className="w-100">
                  <div className="d-flex align-items-center gap-2 w-100 flex-wrap">
                    <p className="small_text__14">
                      If <b>{getValue(item, "label", "")}</b> breaches send
                      reminder to{" "}
                    </p>
                    <Checkbox
                      checked={getValue(
                        request,
                        `${getValue(item, "target", "")}.is_assigned_agent`,
                        true
                      )}
                      onChange={(e) =>
                        handleAgentChange(e, getValue(item, "target", ""))
                      }
                      disabled={!getValue(item, "isSelected", false)}
                    >
                      Assigned agent
                    </Checkbox>

                    <p className="small_text__14">and</p>
                    <Select
                      mode="multiple"
                      placeholder="Select one or more agents"
                      options={users}
                      className="w-50"
                      value={getValue(
                        request,
                        `${getValue(item, "target", "")}.agents`,
                        []
                      ).map((agent: { id: string }) => agent.id)}
                      onChange={(selectedValues) => {
                        handleUsers(
                          selectedValues,
                          getValue(item, "target", "")
                        );
                      }}
                      disabled={!getValue(item, `isSelected`, false)}
                      maxTagCount="responsive"
                      loading={isLoading3}
                    />
                    <p className="small_text__14">before</p>
                    <Select
                      value={getValue(
                        request,
                        `${getValue(item, "target", "")}.escalation_time`,
                        1800
                      )}
                      onChange={(value) => {
                        handleChangeTime(value, getValue(item, "target", ""));
                      }}
                      placeholder="Select Time"
                      options={[
                        { value: 28800, label: "8 hours" },
                        { value: 14400, label: "4 hours" },
                        { value: 7200, label: "2 hours" },
                        { value: 3600, label: "1 hour" },
                        { value: 1800, label: "30 minutes" },
                      ]}
                      disabled={!getValue(item, `isSelected`, false)}
                      className="w-25"
                    />
                  </div>
                  {mainRequest.name &&
                    (() => {
                      const matchedPriorities = comparePrioritiesWithEscalation(
                        priorityRequest,
                        request,
                        getValue(item, "value", "")
                      );
                      if (matchedPriorities && matchedPriorities.length > 0) {
                        return (
                          <Alert
                            showIcon
                            message={`Reminders won't be sent for ${matchedPriorities.join(
                              ", "
                            )} priority tickets.`}
                            type="warning"
                            className="w-fit-content mt-2"
                          />
                        );
                      }
                      return null;
                    })()}
                </div>
              </div>
              <div className="d-flex align-items-baseline gap-2">
                <Checkbox
                  style={{ width: "50px" }}
                  checked={getValue(item, "isVioldatedSelected", false)}
                  onChange={(e) =>
                    handleCheckbox(
                      e,
                      index,
                      getValue(item, "target", ""),
                      getValue(item, "violated", ""),
                      "isVioldatedSelected"
                    )
                  }
                />
                <div className="w-100">
                  <div className="d-flex align-items-center gap-2 w-100 flex-wrap">
                    <p className="small_text__14">
                      If violated, then escalate to{" "}
                    </p>

                    <Checkbox
                      checked={getValue(
                        request,
                        `${getValue(item, "violated", "")}.is_assigned_agent`,
                        true
                      )}
                      onChange={(e) =>
                        handleAgentChange(e, getValue(item, "violated", ""))
                      }
                      disabled={!getValue(item, `isVioldatedSelected`, false)}
                    >
                      Assigned agent
                    </Checkbox>
                    <p className="small_text__14">and</p>
                    <Select
                      mode="multiple"
                      placeholder="Select one or more agents"
                      options={users}
                      className="w-50"
                      value={getValue(
                        request,
                        `${getValue(item, "violated", "")}.agents`,
                        []
                      ).map((agent: { id: string }) => agent.id)}
                      onChange={(selectedValues) =>
                        handleUsers(
                          selectedValues,
                          getValue(item, "violated", "")
                        )
                      }
                      disabled={!getValue(item, `isVioldatedSelected`, false)}
                      maxTagCount="responsive"
                      loading={isLoading3}
                    />
                    <p className="small_text__14">after</p>
                    <Select
                      value={getValue(
                        request,
                        `${getValue(item, "violated", "")}.escalation_time`,
                        1800
                      )}
                      onChange={(value) => {
                        handleChangeTime(value, getValue(item, "violated", ""));
                      }}
                      placeholder="Select Time"
                      options={[
                        { value: 28800, label: "8 hours" },
                        { value: 14400, label: "4 hours" },
                        { value: 7200, label: "2 hours" },
                        { value: 3600, label: "1 hour" },
                        { value: 1800, label: "30 minutes" },
                      ]}
                      disabled={!getValue(item, `isVioldatedSelected`, false)}
                      className="w-25"
                    />
                  </div>

                  {/* {getValue(item, "violated", "") === "next_response" &&
                    getValue(item, `isVioldatedSelected`, false) && (
                      <Alert
                        showIcon
                        message="Escalations won't be sent for Urgent, High, Medium, Low priority tickets."
                        type="warning"
                        className="w-fit-content mt-2"
                      />
                    )} */}
                </div>
              </div>
            </div>
            {index < 2 && (
              <div className="due-time-and-condition-button border">AND</div>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default SLADueTime;
