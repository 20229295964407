import { getValue } from "@utils/lodash";
import CommonAttachments from "./Attachments";
import TaskCompanies from "./TaskCompanies/TaskCompanies";
import TaskSales from "./TaskSales/TaskSales";
import TaskContacts from "./TaskContacts/TaskContacts";
import TaskLeads from "./TaskLeads/TaskLeads";

export default function CommonModulesDetailRightPagePage(props: any) {
  return (
    <>
      {getValue(props, `module`, "") === "contacts" ? (
        <>
          <TaskLeads {...props} create_module={"leads"} />
          <TaskCompanies {...props} create_module={"companies"} />
          <TaskSales {...props} create_module={"sales"} />
          <CommonAttachments {...props} create_module={"contacts"} />
        </>
      ) : null}
      {getValue(props, `module`, "") === "companies" ? (
        <>
          <TaskLeads {...props} create_module={"leads"} />
          <TaskContacts {...props} create_module={"contacts"} />
          <TaskSales {...props} create_module={"sales"} />
          <CommonAttachments {...props} create_module={"companies"} />
        </>
      ) : null}
      {getValue(props, `module`, "") === "sales" ||
      getValue(props, `module`, "") === "deals" ? (
        <>
          <TaskLeads {...props} create_module={"leads"} />
          <TaskContacts {...props} create_module={"contacts"} />
          <TaskCompanies {...props} create_module={"companies"} />
          <CommonAttachments {...props} create_module={"deals"} />
        </>
      ) : null}
      {getValue(props, `module`, "") === "leads" ? (
        <>
          <TaskContacts {...props} create_module={"contacts"} />
          <TaskCompanies {...props} create_module={"companies"} />
          <TaskSales {...props} create_module={"sales"} />
          <CommonAttachments {...props} create_module={"tasks"} />
        </>
      ) : null}
      {getValue(props, `module`, "") === "tasks" ? (
        <>
          <TaskLeads {...props} create_module={"leads"} />
          <TaskContacts {...props} create_module={"contacts"} />
          <TaskCompanies {...props} create_module={"companies"} />
          <TaskSales {...props} create_module={"sales"} />
          <CommonAttachments {...props} create_module={"tasks"} />
        </>
      ) : null}
      {getValue(props, `module`, "") === "tickets" ? (
        <>
          <TaskContacts {...props} create_module={"contacts"} />
        </>
      ) : null}
    </>
  );
}
