import { getValue } from "@utils/lodash";
import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";
import SubmitContainer from "@components/common/ButtonContainer/SubmitContainer";
import ActivityLoader from "@components/common/Loader/activity-loader";
import FormBuilder from "@components/Builder/FormBuilder";
import { Drawer } from "antd";
import AntSubmitContainer from "@components/common/ButtonContainer/NewSubmitContainer";

function CommonDetailTaskPopup(props: any) {
  const { isRollsPopupVisibile, setRollsPopupVisisbility } = props;
  return (
    <div>
      <Drawer
        open={isRollsPopupVisibile}
        onClose={() => {
          setRollsPopupVisisbility(!isRollsPopupVisibile);
        }}
        title={getValue(props, `title`, "")}
        width={"580px"}
        // okText and onOk are not valid props for Drawer component
        // confirmLoading={props.formSubmitLoading}
        footer={
          <AntSubmitContainer
            handleBackButton={() => {
              setRollsPopupVisisbility(false);
            }}
            handleSubmit={() => props.handleSubmit()}
            submitLoading={props.formSubmitLoading}
            first_title={"Cancel"}
            second_title={"Save"}
            fromTaskPopup
            disabled={props.disabled}
            disableValidation={props.disableValidation}
            permissions={getValue(props, `permissions`, [])}
          />
        }
        className="ant-drawer-container-footer"
      >
        {props.formLoading ? (
          <div>
            <ActivityLoader />
          </div>
        ) : (
          <>
            <div className={"task_wrapper"}>
              <FormBuilder
                fields={getValue(props, `form`, [])}
                setFields={props.setForm}
                simpleValidator={props.simpleValidator}
                singular_label={props.singular_label}
                module={"tasks"}
              />
            </div>
            {/* <FormBuilder
              fields={getValue(props, `form`, [])}
              setFields={props.setForm}
              simpleValidator={props.simpleValidator}
              singular_label={props.singular_label}
            /> */}
          </>
        )}
      </Drawer>
    </div>
  );
}

export default CommonDetailTaskPopup;
