import { ReloadOutlined } from "@ant-design/icons";
import { getValue } from "@utils/lodash";
import { Button, Popconfirm } from "antd";
import { Info, Trash2 } from "lucide-react";
import React from "react";

function TextRightSection(props: any) {
  const { findStaticUrl, scrapeInfo, linkURLs, textInfo } = props;
  return (
    <div className="stats-sidebar">
      <div className="stats-card">
        {getValue(textInfo, `status`, "") && (
          <div className="d-flex align-items-center gap-2">
            <span
              className={
                getValue(textInfo, `status`, "") == "failed"
                  ? "chatbot_status__error"
                  : getValue(textInfo, `status`, "") == "untrained"
                  ? "chatbot_status__untrained"
                  : "chatbot_status__success"
              }
            >
              <Info size={14} style={{ marginRight: 4 }} />
              {getValue(textInfo, `status`, "") === "untrained"
                ? props.disableTrain
                  ? "training..."
                  : getValue(textInfo, `status`, "")
                : getValue(textInfo, `status`, "")}
            </span>
          </div>
        )}
        <div className="stat-item">
          <div className="label">
            {/* {getValue(linkURLs, `length`, 0)} Links */}
            {/* (71,630 detected chars) */}
          </div>
        </div>
        <div className="stat-item">
          {/* <div className="label">Total detected characters</div> */}
          {/* <div className="value">71,630</div> */}
          <div className="label">Total character Limit</div>
          <div className="limit">11,000,000</div>
        </div>
      </div>
      <div className="d-flex gap-2 mt-3">
        {(!getValue(scrapeInfo, `status`, "") ||
          getValue(scrapeInfo, `status`, "") === "scrapping" ||
          getValue(scrapeInfo, `status`, "") === "completed" ||
          findStaticUrl() ||
          getValue(linkURLs, `length`, 0) === 0) && (
          <Button
            color="default"
            variant="solid"
            onClick={props.handleTrainLink}
            className="w-100"
            size="large"
            disabled={getValue(props.texts_source, `texts.length`, 0) === 0}
          >
            {props.activeTab == 1
              ? `${
                  getValue(linkURLs, `length`, 0) === 0 ? "Train" : "Retrain"
                } Chatbot`
              : props.activeTab == 2 || props.activeTab == 3
              ? `Save`
              : "Retrain Chatbot"}
          </Button>
        )}
        {getValue(scrapeInfo, `status`, "") &&
          getValue(linkURLs, `length`, 0) > 0 &&
          getValue(scrapeInfo, `status`, "") !== "completed" &&
          !findStaticUrl() && (
            <Button
              size="large"
              color="default"
              variant="solid"
              // className="retrain-button"
              onClick={props.ScrapedDocumentStatus}
              icon={<ReloadOutlined />}
            />
          )}

        <div className="cursor-pointer">
          <Popconfirm
            title="Are you sure to delete this text?"
            onConfirm={props.handleDeleteText}
          >
            <Trash2 size={18} />
          </Popconfirm>
        </div>
      </div>
    </div>
  );
}

export default TextRightSection;
