import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllTicketStatus = (queryRequest: string) =>
  get(`${config.API_URL}/ticket-status?${queryRequest}`);

export const getTicketStatusById = (id: string) =>
  get(`${config.API_URL}/ticket-status/${id}`);

export const getTicketStatusList = (queryRequest: string) =>
  get(`${config.API_URL}/ticket-status/list?${queryRequest}`);

export const createTicketStatus = (payload: object) =>
  post(`${config.API_URL}/ticket-status`, payload);

export const updateTicketStatus = (id: string, payload: object) =>
  patch(`${config.API_URL}/ticket-status/${id}`, payload);

export const updateTicketStatusStatus = (payload: object) =>
  patch(`${config.API_URL}/ticket-status/bulk/active-inactive`, payload);

export const updateTicketStatusPosition = (payload: object) =>
  patch(`${config.API_URL}/ticket-status/seq-num/swap`, payload);

export const deleteTicketStatus = (id: string) =>
  Delete(`${config.API_URL}/ticket-status/${id}`);
