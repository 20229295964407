import AiPage from "@pages/Private/AIChatbot";
import AIAgentActions from "@pages/Private/AIChatbot/Details/Actions";
import CustomAction from "@pages/Private/AIChatbot/Details/Actions/CustomAction";
import FormAction from "@pages/Private/AIChatbot/Details/Actions/FormAction";
import WebSearchAction from "@pages/Private/AIChatbot/Details/Actions/WebSearchAction";
import AIAgentMapToChannel from "@pages/Private/AIChatbot/Details/MapToChannel";
import AIAgentPlayground from "@pages/Private/AIChatbot/Details/Playground";
import AIAgentChatLogs from "@pages/Private/AIChatbot/Details/Chatlogs";
import { AIAgentSettings } from "@pages/Private/AIChatbot/Details/Settings";
import AIAgentShare from "@pages/Private/AIChatbot/Details/Share";
import AIAgentSources from "@pages/Private/AIChatbot/Details/Sources";
import AIAgentTrainingData from "@pages/Private/AIChatbot/Details/TrainingData";

//------------- Ai configure -----------//
export const AiChatbotRoutes = [
  {
    path: "/:orgId/chatbot/:chatbotId/ai",
    name: "dashboard_crm_overview",
    component: AiPage,
  },

  {
    path: "/:orgId/chatbot/:chatbotId/ai/detail/playground",
    name: "dashboard_crm_overview",
    component: AIAgentPlayground,
  },
  {
    path: "/:orgId/chatbot/:chatbotId/ai/detail/chat-logs",
    name: "dashboard_crm_overview",
    component: AIAgentChatLogs,
  },
  {
    path: "/:orgId/chatbot/:chatbotId/ai/detail/training-data",
    name: "dashboard_crm_overview",
    component: AIAgentTrainingData,
  },
  {
    path: "/:orgId/chatbot/:chatbotId/ai/detail/map-to-channel",
    name: "dashboard_crm_overview",
    component: AIAgentMapToChannel,
  },
  {
    path: "/:orgId/chatbot/:chatbotId/ai/detail/share",
    name: "dashboard_crm_overview",
    component: AIAgentShare,
  },
  {
    path: "/:orgId/chatbot/:chatbotId/ai/detail/settings",
    name: "dashboard_crm_overview",
    component: AIAgentSettings,
  },
  {
    path: "/:orgId/chatbot/:chatbotId/ai/detail/sources",
    name: "dashboard_crm_overview",
    component: AIAgentSources,
  },
  {
    path: "/:orgId/chatbot/:chatbotId/ai/detail/actions",
    name: "dashboard_crm_overview",
    component: AIAgentActions,
  },
  // Custom Action Agent

  {
    path: "/:orgId/chatbot/:chatbotId/ai/detail/custom-action/create",
    name: "dashboard_crm_overview",
    component: CustomAction,
  },

  // Form Action Agent

  {
    path: "/:orgId/chatbot/:chatbotId/ai/detail/form-action/create",
    name: "dashboard_crm_overview",
    component: FormAction,
  },

  // Web Search Action Agent

  {
    path: "/:orgId/chatbot/:chatbotId/ai/detail/web-search-action/create",
    name: "dashboard_crm_overview",
    component: WebSearchAction,
  },
];
