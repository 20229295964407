import { getValue } from "@utils/lodash";
import { memo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import FormBuilder from "@components/Builder/FormBuilder";
import {
  FormRequestHelper,
  generatePayload,
} from "@components/helpers/request-helper";
import Loader from "@components/common/Loader/loading";
import { createSubPipeline } from "@services/common.service";
import { toast } from "sonner";
import SimpleReactValidator from "simple-react-validator";
import { linkWhatsappWeb } from "@services/fb.service";
import {
  capitalizeFirstLetter,
  formatString,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import AccountCircleSvgComponent from "@assets/svg/account-circle";
import BackSvgComponent from "@assets/svg/back-link";
import { Alert, Button, Select, Space, Tabs, Tooltip } from "antd";
import { updateWebchatInfo } from "@services/inbox.service";
import { updateContact } from "@services/contacts.service";
import ProfileInlineFormBuilder from "./components/ContactInfoTab/ProfileDetails/ProfileInlineFormBuilder";
import { updateLead } from "@services/leads.service";
import UserDealDetails from "./components/ContactInfoTab/DealDetails/UserDealDetails";
import UserDocuments from "./components/ContactInfoTab/UserDocuments/UserDocuments";
import BackButton from "@components/custom/BrowserBack";
import TextHeaderTooltip from "@components/custom/Dropdown/TextHeaderTooltip";
import { ExternalLink, X } from "lucide-react";
import { handleSelectChange } from "@common/handleChange";

function ChatContactInfo(props: any) {
  const {
    handleToggleWidth,
    contactInfo,
    moduleDetails,
    allPipelines,
    stages,
    fields,
    setFields,
    getFormFields,
    isFormLoading,
    staticFieldRequest,
    setStaticFieldRequest,
    selectedConversation,
    selectedContact,
    userDetails,
    toggleUserDetails,
    getSpecificContactInfo,
    selectedType,
    getSpecificLeadInfo,
    isLeadShow,
    selectedConversationInfo,
    contactFields,
    setConatctFields,
    selectedModule,
    dirtyFields,
    isOpenDrawer,
    setIsOpenDrawer,
    selectedDrawerInfo,
    setSelectedDrawerInfo,
    updateConversation,
    stageRequest,
    setStageRequest,
    contactInfoLoading,
    defaultFields,
  } = props;
  const params = useParams();

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const hideValidation = () => {
    simpleValidator.current.hideMessages();
    simpleValidator.current.fields = {};
    simpleValidator.current.errorMessages = {};
    forceUpdate(0);
  };
  const showValidation = () => {
    simpleValidator.current.showMessages();
    forceUpdate(1);
  };
  const [listCreateLoading, setListCreateLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      showValidation();
    } else {
      try {
        let payload = generatePayload(selectedType, fields, staticFieldRequest);
        let resp = await createSubPipeline(payload, selectedType);
        if (resp) {
          toast.success("Created Successfully");
          let response;
          if (
            getValue(props, `selectedConversationInfo.source`, "") === "webchat"
          ) {
            response = await updateWebchatInfo(
              getValue(selectedConversationInfo, `id`, ""),
              {
                is_active: getValue(
                  selectedConversationInfo,
                  `is_active`,
                  false
                ),
                status: getValue(selectedConversationInfo, `status`, ""),
                chatbot_id: getValue(
                  selectedConversationInfo,
                  `chatbot_id`,
                  ""
                ),
                org_user_id: getValue(
                  selectedConversationInfo,
                  `org_user_id`,
                  ""
                ),
                contact_id: getValue(resp, `data.id`, ""),
                name: getValue(selectedConversationInfo, `name`, ""),
                phone: getValue(selectedConversationInfo, `phone`, ""),
                email: getValue(selectedConversationInfo, `email`, ""),
                is_guest: getValue(selectedConversationInfo, `is_guest`, false),
              }
            );
          } else {
            response = await linkWhatsappWeb({
              conversationId: selectedConversation,
              [selectedType === "leads" ? "leadId" : "contactId"]: getValue(
                resp,
                `data.id`,
                ""
              ),
            });
          }

          if (response) {
            if (selectedType === "leads") {
              getSpecificLeadInfo(getValue(resp, `data.id`, ""));
            } else {
              getSpecificContactInfo(getValue(resp, `data.id`, ""));
            }
            toast.success("Created successfully");
            setListCreateLoading(false);
            hideValidation();
          }
        } else {
          setListCreateLoading(false);
          hideValidation();
        }
      } catch (error) {
        hideValidation();
      } finally {
        setListCreateLoading(false);
        if (selectedModule === "contacts") {
          getSpecificContactInfo(getValue(contactInfo, `id`, ""));
        } else {
          getSpecificLeadInfo(getValue(contactInfo, `id`, ""));
        }
      }
    }
  };

  const [currentTab, setCurrentTab] = useState("1");

  /* -------------------------------------------------------------------------- */
  /*                                  API Section                               */
  /* -------------------------------------------------------------------------- */

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmitContact = async () => {
    try {
      let fieldReq = JSON.parse(JSON.stringify(contactFields));
      let payloadNew = FormRequestHelper(fieldReq, true);
      let combinedPayload: any = { ...payloadNew };
      if (
        getValue(
          selectedConversationInfo,
          `${selectedModule}.pipeline_stage_id`,
          ""
        ) !== getValue(stageRequest, `stage`, "")
      ) {
        combinedPayload = {
          ...payloadNew,
          pipeline_stage_id: getValue(stageRequest, `stage`, ""),
        };
      }
      let requestPayload: any = { ...combinedPayload };
      setSubmitLoading(true);
      let resp =
        selectedModule === "contacts"
          ? await updateContact(
              getValue(contactInfo, `id`, ""),
              removeNullOrUndefinedProperties(requestPayload)
            )
          : await updateLead(
              getValue(contactInfo, `id`, ""),
              removeNullOrUndefinedProperties(requestPayload)
            );
      if (resp) {
        toast.success("Updated successfully");
        if (selectedModule === "contacts") {
          getSpecificContactInfo(getValue(contactInfo, `id`, ""));
        } else {
          getSpecificLeadInfo(getValue(contactInfo, `id`, ""));
        }
        setStageRequest({
          stage: getValue(resp, `data.pipeline_stage_id`, ""),
        });
        updateConversation();
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  return (
    <>
      <div
        className="px-3"
        style={{
          height: "calc(100vh - 44px)",
          overflowY: "auto",
        }}
      >
        {getValue(contactInfo, `id`, "") ? (
          <>
            <Tabs
              defaultActiveKey="1"
              className="user-profile-tabs"
              onChange={(key) => setCurrentTab(key)}
              tabBarExtraContent={{
                left: (
                  <BackButton
                    handleBack={handleToggleWidth}
                    className="me-3 mt-2"
                    icon={<X size={16} />}
                  />
                ),
                right: (
                  <Tooltip title="View in CRM" placement="left">
                    <Button
                      size="small"
                      color="default"
                      variant="text"
                      shape="circle"
                    >
                      <a
                        href={`/${getValue(
                          params,
                          `orgId`,
                          ""
                        )}/crm/${selectedModule}/detail/${getValue(
                          contactInfo,
                          `id`,
                          ""
                        )}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <ExternalLink size={16} color="#0d6efd" />
                      </a>
                    </Button>
                  </Tooltip>
                ),
              }}
              items={[
                {
                  label: "Profile Details",
                  key: "1",
                  children: (
                    <div className=" mb-5">
                      <div className="profile-details-buttons">
                        {
                          // getValue(props, `permissions`, []).includes(
                          //   "update"
                          // ) &&
                          getValue(contactInfo, `id`, "") &&
                          currentTab === "1" ? (
                            <Button
                              type="primary"
                              onClick={handleSubmitContact}
                              loading={submitLoading}
                            >
                              Update
                            </Button>
                          ) : (
                            !getValue(contactInfo, `id`, "") &&
                            userDetails && (
                              <Button
                                type="primary"
                                onClick={handleSubmit}
                                disabled={listCreateLoading}
                                loading={listCreateLoading}
                              >
                                Save
                              </Button>
                            )
                          )
                        }
                      </div>
                      <div
                        className={`form-container_desc d-flex align-items-center`}
                      >
                        <p className={"small_text__14"}>
                          Status <span className="ms-1">:</span>{" "}
                        </p>
                        <div className={`ms-2 mt-2 mb-1`}>
                          <TextHeaderTooltip
                            width={"300px"}
                            title={`Status`}
                            value={getValue(stageRequest, `stage`, "")}
                            placeholder={`Choose Status`}
                            onChange={(e: any) =>
                              handleSelectChange(
                                e,
                                "stage",
                                stageRequest,
                                setStageRequest
                              )
                            }
                            label={`label`}
                            data={
                              getValue(props, `stageList.length`, 0) > 0
                                ? getValue(props, `stageList`, []).map(
                                    (item: object) => ({
                                      ...item,
                                      label: getValue(item, `label`, ""),
                                      display_text: getValue(item, `label`, ""),
                                      value: getValue(item, `id`, ""),
                                    })
                                  )
                                : []
                            }
                            selectKey={"id"}
                            editInline
                          />
                        </div>
                      </div>
                      {contactInfoLoading ? (
                        <Loader />
                      ) : (
                        <ProfileInlineFormBuilder
                          fields={contactFields}
                          setFields={setConatctFields}
                          module={props.module}
                          width={"100%"}
                          duplicateAPINames={props.duplicateAPINames}
                          setDuplicateAPINames={props.setDuplicateAPINames}
                          isChanged={props.isChanged}
                        />
                      )}
                    </div>
                  ),
                },
                {
                  label: `${capitalizeFirstLetter(
                    getValue(moduleDetails, "linkText", "")
                  )} `,
                  key: "3",
                  children: (
                    <div className="conversation_info_scroller">
                      <UserDealDetails
                        contactInfo={contactInfo}
                        moduleDetails={moduleDetails}
                        selectedModule={selectedModule}
                        getSpecificLeadInfo={getSpecificLeadInfo}
                        getSpecificContactInfo={getSpecificContactInfo}
                        isOpenDrawer={isOpenDrawer}
                        setIsOpenDrawer={setIsOpenDrawer}
                        selectedDrawerInfo={selectedDrawerInfo}
                        setSelectedDrawerInfo={setSelectedDrawerInfo}
                        defaultFields={defaultFields}
                      />
                    </div>
                  ),
                },
                {
                  label: "Documents",
                  key: "5",
                  children: (
                    <div className="conversation_info_scroller">
                      <UserDocuments
                        contactInfo={contactInfo}
                        selectedModule={selectedModule}
                        getSpecificLeadInfo={getSpecificLeadInfo}
                        getSpecificContactInfo={getSpecificContactInfo}
                      />
                    </div>
                  ),
                },
              ]}
            />
          </>
        ) : (
          <div>
            {!userDetails ? (
              <motion.div
                key="userDetails"
                initial={{ x: "-100%", opacity: 0.5 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: "-100%", opacity: 0.5 }}
                transition={{ duration: 0.5 }}
                className="d-flex justify-content-center mb-2 flex-column align-items-center"
              >
                <AccountCircleSvgComponent color={"#dfe5e7"} size={100} />

                <div>
                  {getValue(selectedContact, `contact.first_name`, "") ||
                  getValue(selectedContact, `contact.last_name`, "") ? (
                    <p
                      className="small_text__16 color_black text-center"
                      style={{ textAlign: "left" }}
                    >
                      {formatString(
                        getValue(selectedContact, `contact.first_name`, "")
                      )}{" "}
                      {formatString(
                        getValue(selectedContact, `contact.last_name`, "")
                      )}
                    </p>
                  ) : (
                    <p
                      className="small_text__16 color_black text-center"
                      style={{ textAlign: "left" }}
                    >
                      {formatString(
                        getValue(selectedContact, `wa_profile_name`, "")
                      )}
                    </p>
                  )}
                </div>
                <p className="small_text__14 text-center mt-1">
                  {getValue(selectedContact, "wa_id", "")}
                </p>
                <p className="text-center small_text__14 mt-4 mx-4">
                  This is a new contact and not part of your current contact.
                  Would you like to add?
                </p>
                {getValue(props, `permissions`, []).includes("update") && (
                  <Space>
                    {isLeadShow() && (
                      <Button
                        size="middle"
                        type="primary"
                        onClick={() => {
                          toggleUserDetails("leads");
                        }}
                        className="mt-3"
                      >
                        Add to Leads
                      </Button>
                    )}
                    <Button
                      size="middle"
                      type="primary"
                      onClick={() => {
                        toggleUserDetails("contacts");
                      }}
                      className="mt-3"
                    >
                      Add to Contacts
                    </Button>
                  </Space>
                )}
              </motion.div>
            ) : (
              <motion.div
                key="otherComponent"
                initial={{ x: "100%", opacity: 0.5 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: "-100%", opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div>
                  <div className="mb-3 d-flex justify-content-between align-items-center px-3 mt-2">
                    <div onClick={toggleUserDetails}>
                      <BackSvgComponent size={16} />
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className=" mb-3">
                      <p className="form-label">Pipeline</p>
                      <Select
                        size="large"
                        showSearch
                        placeholder="Select Pipeline"
                        optionFilterProp="label"
                        value={getValue(staticFieldRequest, "pipeline_id", "")}
                        onChange={(value) => {
                          setStaticFieldRequest({
                            ...staticFieldRequest,
                            pipeline_id: value,
                          });
                          getFormFields(value);
                        }}
                        options={allPipelines.map((pipeline: any) => ({
                          value: pipeline.id,
                          label: pipeline.label,
                        }))}
                        className="w-100 cursor-pointer"
                      />
                    </div>
                  </div>
                </div>
                {isFormLoading ? (
                  <Loader />
                ) : getValue(fields, `length`, 0) > 0 ? (
                  <>
                    <div className="d-flex justify-content-center">
                      <div className="">
                        <SearchToolTip
                          label="label"
                          data={stages}
                          name="Status"
                          value={getValue(staticFieldRequest, `stage`, "")}
                          onChange={(e: object) => {
                            setStaticFieldRequest({
                              ...staticFieldRequest,
                              stage: getValue(e, `id`, ""),
                            });
                          }}
                        />
                      </div>
                    </div>
                    <FormBuilder
                      fields={fields}
                      setFields={setFields}
                      phoneInline
                    />
                  </>
                ) : (
                  <div className="d-flex justify-content-center">
                    <Alert
                      message="Please select a pipeline to continue"
                      type="info"
                    />
                  </div>
                )}
              </motion.div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default memo(ChatContactInfo);
