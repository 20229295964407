import PhoneAssignment from "@pages/Private/Settings/PhoneAssignment";
import PhoneAssignmentDetails from "@pages/Private/Settings/PhoneAssignment/PhoneAssignmentDetails";

export const SettingsPhoneAssignmentRoutes = [
  {
    path: "/:orgId/settings/chat/phone-assignment",
    name: "settings_channels_whatsapp",
    component: PhoneAssignment,
  },
  {
    path: "/:orgId/settings/chat/phone-assignment/create",
    name: "settings_channels_whatsapp",
    component: PhoneAssignmentDetails,
  },
  {
    path: "/:orgId/settings/chat/phone-assignment/edit/:id",
    name: "settings_channels_whatsapp",
    component: PhoneAssignmentDetails,
  },
];
