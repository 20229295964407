import { config } from "../env";
import { get, post } from "./helpers/http-handler";

export const getDashboardOverview = (queryRequest: string) =>
  get(`${config.API_URL}/dashboards/overview?${queryRequest}`);

export const getDashboardModule = (queryRequest: string) =>
  get(`${config.API_URL}/dashboards/modules?${queryRequest}`);

export const getDashboardContact = (queryRequest: string) =>
  get(`${config.API_URL}/dashboards/modules/contacts?${queryRequest}`);

export const getDashboardLeads = (queryRequest: string) =>
  get(`${config.API_URL}/dashboards/modules/leads?${queryRequest}`);

//chat dashboard
export const getChatDashboard = (queryRequest: string) =>
  get(
    `${config.API_URL}/inbox-dashboards/chat-conversation-status?${queryRequest}`
  );

export const getChatAssignmentStatusDashboard = (queryRequest: string) =>
  get(
    `${config.API_URL}/inbox-dashboards/chat-assignment-status?${queryRequest}`
  );

//chat agent dashboard
export const getAgentAvailabilityDashboard = (queryRequest: string) =>
  get(`${config.API_URL}/inbox-dashboards/agent-availability?${queryRequest}`);

export const getChatAgentsDashboard = (queryRequest: string) =>
  get(`${config.API_URL}/inbox-dashboards/chat-agents?${queryRequest}`);

export const clickToCall = (payload: object) =>
  post(`${config.API_URL}/phones/click-to-call`, payload);

export const getAgentAnalysisDashboard = (queryRequest: string) =>
  get(`${config.API_URL}/inbox-dashboards/chat-agents-performance?${queryRequest}`);
