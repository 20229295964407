import { useStateContext } from "@context/dataContext";
import { getValue } from "@utils/lodash";
import React from "react";

function InputIcon(props: any) {
  const { orgInfo } = useStateContext();
  const { hideLabel, label, mandatory } = props;
  return (
    <div className={``}>
      {!hideLabel && (
        <label className={`form-label`}>
          {label}{" "}
          {/* {mandatory && <span className="color_danger small_text__16">*</span>} */}
        </label>
      )}
      <div className="position-relative">
        <input
          type={"text"}
          placeholder={props.placeholder}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          className={
            mandatory
              ? `custom-form-control-right_icon__required`
              : `custom-form-control-right_icon`
          }
          disabled={props.disabled}
          // autoComplete="current-password"
          // autoComplete="off"
        />
        <p className={"right_visibility"}>
          {getValue(orgInfo, `currency`, "")}
        </p>
      </div>
      {props.simpleValidator && (
        <p className={`form-error d-flex`}>
          {/* <InfoSvgComponent color="#d93025" size="12px"/> */}
          {props.simpleValidator.current.message(
            getValue(props, `label`, ""),
            getValue(props, `value`, ""),
            "required"
          )}
        </p>
      )}
    </div>
  );
}

export default InputIcon;
