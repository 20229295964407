import { Badge, Button, Drawer, Input, Tooltip } from "antd";
import { Bookmark, ChevronDown, ChevronUp, Search } from "lucide-react";
import { motion } from "framer-motion";
import "../../messages.scss";
import { getValue } from "@utils/lodash";
import { useState, useMemo } from "react";

const ConversationTypeFilter = ({
  open,
  onClose,
  chatStatuses,
  params,
  navigate,
  permissions,
  selectedTab,
  handleClickFilter,
  rawStatusCounts,
  userId,
  defaultConversationType,
  handleDefaultConversationType,
  themeColor,
  isBookmarkOpen,
  setIsBookmarkOpen,
}: any) => {
  const [openAccordion, setOpenAccordion] = useState(true);
  const [searchText, setSearchText] = useState("");

  const toggleAccordion = () => setOpenAccordion(!openAccordion);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchText(e.target.value);

  const handleNavigateView = (value: string, label: string) => {
    // navigate(`/live-chat?convStatus=${value}`);
    onClose();
  };

  const tabsWithPermissions = useMemo(
    () =>
      chatStatuses.filter((tab: any) =>
        getValue(tab, "permissions", []).some((perm: string) =>
          permissions.includes(perm)
        )
      ),
    [chatStatuses, permissions]
  );

  const filteredTabs = useMemo(
    () =>
      tabsWithPermissions.filter((tab: any) =>
        getValue(tab, "label", "")
          .toLowerCase()
          .includes(searchText.toLowerCase())
      ),
    [tabsWithPermissions, searchText]
  );

  return (
    <Drawer
      title="Conversation View"
      onClose={onClose}
      open={open}
      placement="left"
      width={400}
    >
      <div className="position-sticky top-0 bg-white w-100 mb-3 d-flex align-items-center justify-content-between gap-3">
        <Input
          allowClear
          placeholder="Search for a view"
          className="search-ticket-view"
          value={searchText}
          onChange={handleSearch}
          prefix={<Search size={16} color="#717171" />}
        />
        <Tooltip title="Bookmark View">
          <Button
            type="text"
            onClick={() => setIsBookmarkOpen(!isBookmarkOpen)}
            icon={<Bookmark size={16} color={themeColor} />}
          />
        </Tooltip>
      </div>
      <div className="live-chat-left-view-wrapper">
        <div
          className="d-flex align-items-center justify-content-between cursor-pointer"
          onClick={toggleAccordion}
        >
          <span className="header_text__16">Default</span>
          <motion.div
            animate={{ rotate: openAccordion ? 0 : 180 }}
            transition={{ duration: 0.3 }}
          >
            {openAccordion ? (
              <ChevronDown size={16} />
            ) : (
              <ChevronUp size={16} />
            )}
          </motion.div>
        </div>
        {openAccordion && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
            className="d-grid gap-1 my-3"
          >
            {filteredTabs.map((item: any, index: number) => {
              const label = getValue(item, "label", "");
              const value = getValue(item, "value", "");
              const isSelected = selectedTab === value;

              const matchedStatus = rawStatusCounts.all.find(
                (status: any) => getValue(status, "status", "") === value
              );
              const matchedUnreadStatus = rawStatusCounts.unread.find(
                (status: any) => getValue(status, "status", "") === value
              );
              const count = getValue(matchedStatus, "count", 0);
              const unreadCount = getValue(matchedUnreadStatus, "count", 0);

              return (
                <div
                  key={value}
                  className={`${
                    isSelected && !isBookmarkOpen
                      ? "live-chat-view-tab--selected"
                      : "live-chat-view-tab"
                  } ${
                    isBookmarkOpen ? "" : "cursor-pointer"
                  } d-flex justify-content-between align-items-center gap-3 `}
                  onClick={() => {
                    if (!isBookmarkOpen) {
                      handleClickFilter(value, "");
                      handleNavigateView(value, label);
                    }
                  }}
                >
                  <div className={`d-flex align-items-center gap-2`}>
                    <p className="d-flex align-items-center gap-2">
                      {isBookmarkOpen && (
                        <Button
                          type="text"
                          onClick={() => {
                            handleDefaultConversationType(value);
                          }}
                          icon={
                            <Bookmark
                              size={16}
                              fill={
                                defaultConversationType === value
                                  ? themeColor
                                  : "#ffffff00"
                              }
                              color={
                                defaultConversationType === value
                                  ? themeColor
                                  : "#434d5f"
                              }
                            />
                          }
                          disabled={defaultConversationType === value}
                          // className="chat-type-bookmark-btn"
                        />
                      )}
                      {label}

                      {defaultConversationType === value && !isBookmarkOpen && (
                        <Bookmark
                          size={16}
                          fill={themeColor}
                          color={themeColor}
                        />
                      )}
                      {unreadCount !== 0 && (
                        <Badge color="#25d366" count={unreadCount} />
                      )}
                    </p>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <p className="">{count}</p>
                  </div>
                </div>
              );
            })}
          </motion.div>
        )}
      </div>
    </Drawer>
  );
};

export default ConversationTypeFilter;
