import SaleProductForm from "./components/product-form";

function SaleItem(props: any) {
  return (
    <div>
      <SaleProductForm
        data={props.data}
        getData={props.getData}
        // productsCount={productsCount}
      />
    </div>
  );
}

export default SaleItem;
