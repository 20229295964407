import { convertCurrentDateWithTime } from "@common/date-helpers";
import { capitalizeFirstLetter, formatString } from "@common/text-helpers";
import CannedResponseNoDataPage from "@components/common/NoData/canned-response-nodata";
import { getValue } from "@utils/lodash";
import { Table } from "antd";
import { Trash2 } from "lucide-react";
import { useState } from "react";

const CannedTable = ({
  list,
  tableData,
  isTableLoading,
  handleDeletCannedResponse,
  page_no,
  limit,
  totalCount,
  handleChangePagination,
  handleOpenSlider,
}: any) => {
  const [selectionType, setSelectionType] = useState<any>("checkbox");
  const rowSelection = {
    // selectedRowKeys: selectedIds,
    // onChange: (selectedRowKeys: any) => {
    //   setSelectedIds(selectedRowKeys);
    // },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const columns = [
    {
      title: "RESPONSE",
      dataIndex: "title",
      key: "title",
      render: (title: any, record: any) => (
        <div
          className="cursor-pointer header_blue_text__14"
          // onClick={() => handleOpenSlider(getValue(record, `id`, ""))}
          onClick={() => handleOpenSlider(record)}
        >
          {capitalizeFirstLetter(title)}
        </div>
      ),
    },
    // {
    //   title: "VISIBLE TO",
    //   dataIndex: "visibility",
    //   key: "visibility",
    //   render: (record: any, text: any) =>
    //     formatString(getValue(text, `visibility`, "")),
    // },
    // {
    //   title: "CREATED AT",
    //   dataIndex: "created_at",
    //   key: "created_at",
    //   render: (record: any, text: any) =>
    //     convertCurrentDateWithTime(getValue(text, `created_at`, "")),
    // },
    {
      title: "LAST MODIFIED BY",
      dataIndex: "updated_by",
      key: "updated_by",
      render: (record: any, text: any) =>
        getValue(text, `updated_by.first_name`, "") +
        " " +
        getValue(text, `updated_by.last_name`, ""),
    },
    {
      title: "ACTION",
      dataIndex: "is_active",
      key: "is_active",
      render: (is_active: any, record: any) => (
        <div className="d-flex justify-content-center align-items-center">
          <Trash2
            className="cursor-pointer"
            color="red"
            size={18}
            onClick={() =>
              handleDeletCannedResponse(getValue(record, "id", ""))
            }
          />
        </div>
      ),
      width: 100,
    },
  ];

  return (
    <div className="col-9 canned-table-wrapper p-4">
      {getValue(list, "folders.length", "") < 0 ? (
        <CannedResponseNoDataPage />
      ) : (
        <Table
          size="middle"
          bordered
          columns={columns}
          dataSource={getValue(tableData, "canned_responses", "")}
          rowKey="id"
          loading={isTableLoading}
          // rowSelection={{
          //   type: selectionType,
          //   ...rowSelection,
          // }}
          pagination={{
            current: page_no,
            pageSize: limit,
            total: totalCount,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50"],
            onChange: (page_no, pageSize) => {
              handleChangePagination(page_no, pageSize);
            },
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          scroll={{
            y: `calc(100vh - 90px)`,
          }}
        />
      )}
    </div>
  );
};

export default CannedTable;
