import { config } from "../env";
import { get, patch, post, postWithPayloadCode } from "./helpers/http-handler";

//----- Configuration  -----//

export const getOAuthLink = (provider: string, queryRequest: string) =>
  get(`${config.API_URL}/inbox-manager/oauth/${provider}/link?${queryRequest}`);

export const oAuthLinkCallback = (
  provider: string,
  payload: object,
  code: string
) =>
  postWithPayloadCode(
    `${config.API_URL}/inbox-manager/oauth/${provider}/callback`,
    payload,
    code
  );

//----- Email  -----//

export const getEmailConfig = () =>
  get(`${config.API_URL}/inbox-manager/inbox/config`);

export const getEmailList = (id: string, queryRequest: string) =>
  get(`${config.API_URL}/inbox-manager/inbox/${id}/list?${queryRequest}`);

export const sendEmail = (provider: string, payload: object) =>
  post(`${config.API_URL}/inbox-manager/inbox/${provider}/send`, payload);

export const sendWebhook = (provider: string, payload: object) =>
  post(`${config.API_URL}/inbox-manager/inbox/${provider}/webhook`, payload);

export const getSpecificEmailThread = (id: string, queryRequest: string) =>
  get(
    `${config.API_URL}/inbox-manager/inbox/list/thread/${id}?${queryRequest}`
  );

//----- Drafts  -----//

export const getDraftEmailList = (id: string, queryRequest: string) =>
  get(`${config.API_URL}/inbox-manager/inbox/draft/${id}/list?${queryRequest}`);

export const getSpecificDraftEmail = (
  id: string,
  draftId: string,
  queryRequest: string
) =>
  get(
    `${config.API_URL}/inbox-manager/inbox/draft/${id}/list/${draftId}?${queryRequest}`
  );

export const sendDraftEmail = (provider: string, payload: object) =>
  post(`${config.API_URL}/inbox-manager/inbox/draft/${provider}/send`, payload);

export const saveDraftEmail = (payload: object) =>
  post(`${config.API_URL}/inbox-manager/inbox/draft/save`, payload);

export const updateDraftEmail = (id: string, payload: object) =>
  patch(`${config.API_URL}/inbox-manager/inbox/draft/${id}`, payload);

//----- Deliverability  -----//

export const getInboxDomains = (queryRequest: string) =>
  get(`${config.API_URL}/inbox-manager/domain?${queryRequest}`);

export const addInboxDomain = (payload: object) =>
  post(`${config.API_URL}/inbox-manager/domain/add`, payload);

export const setDefaultInboxDomain = (id: string, payload: object) =>
  patch(`${config.API_URL}/inbox-manager/domain/${id}/set-default`, payload);

export const validateInboxDomain = (id: string) =>
  get(`${config.API_URL}/inbox-manager/domain/${id}/check/is-validated`);

//----- Email Templates  -----//

export const getAllTemplates = (queryRequest: string) =>
  get(`${config.API_URL}/templates?${queryRequest}`);

export const getListAllTemplates = (queryRequest: string) =>
  get(`${config.API_URL}/templates/list?${queryRequest}`);

export const addTemplates = (payload: object) =>
  post(`${config.API_URL}/templates`, payload);

export const updateTemplates = (id: string, payload: object) =>
  patch(`${config.API_URL}/templates/${id}`, payload);

export const getTemplatesContexts = (id: string, context_id: string) =>
  get(`${config.API_URL}/templates/list/${id}/context/${context_id}`);

export const getAllAvailableTemplateTags = (
  module_id: string,
  queryRequest: string
) =>
  get(
    `${config.API_URL}/templates/${module_id}/available-tags?${queryRequest}`
  );

export const getloggedInuserTemplateTags = () =>
  get(`${config.API_URL}/templates/user-tags`);

//----- Org Emails  -----//

export const getAllOrgEmails = (queryRequest: string) =>
  get(`${config.API_URL}/inbox-manager/org-emails?${queryRequest}`);

export const addOrgEmails = (payload: object) =>
  post(`${config.API_URL}/inbox-manager/org-emails/manage`, payload);
