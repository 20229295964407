import SettingsProducts from "@pages/Private/Settings/Products";
import CreateProducts from "@pages/Private/Settings/Products/CreateProducts";
import SaleItemsPage from "@pages/Private/Settings/SaleItems";

export const SettingsProductsRoutes = [
  {
    path: "/:orgId/settings/helpdesk/products",
    name: "settings_templates_business_hours",
    component: SettingsProducts,
  },
  {
    path: "/:orgId/settings/helpdesk/products/create",
    name: "settings_templates_business_hours",
    component: CreateProducts,
  },
  {
    path: "/:orgId/settings/helpdesk/products/edit/:id",
    name: "settings_templates_business_hours",
    component: CreateProducts,
  },
  {
    path: "/:orgId/settings/crm/sale-items",
    name: "settings_templates_business_hours",
    component: SaleItemsPage,
  },
];
