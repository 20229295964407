import React from "react";
import { formatString } from "@common/text-helpers";

const ChatAssignmentStatus = (props: any) => {
  const { data } = props;
  // Calculate total
  const total: any = Object.values(data).reduce(
    (sum: any, value: any) => sum + (Number(value) || 0),
    0
  );
  // Progress bar colors
  const colors: any = {
    assigned: "#4DA0FF",
    assigned_and_not_replied: "#36D399",
    overdue: "#FFB74D",
    unassigned: "#F87171",
  };

  // Nice labels for display
  const labels: any = {
    assigned: "Assigned conversations",
    assigned_and_not_replied: "Assigned and not replied",
    overdue: "Overdue",
    unassigned: "Unassigned",
  };

  return (
    <div className=" dashboard_border">
      <h6 className="header_text__16 pb-3">Chat Assignment Status</h6>

      <div className="mb-8">
        <div className="d-flex items-baseline justify-content-between p-2">
          <p className="small_text__14">Total Chat Conversations</p>
          <p className="small_text__15">{total}</p>
        </div>
      </div>

      <div className="border-t border-gray-200 pt-6">
        {Object.entries(data).map(([key, value]: any) => (
          <div key={key} className="mb-6">
            <div className="flex justify-between items-center mb-1">
              <div className="d-flex items-baseline justify-content-between p-2">
                <p className="small_text__14">{formatString(key)}</p>
                <p className="small_text__15">{value}</p>
              </div>
            </div>
            <div className="h-2 w-full bg-gray-100 rounded-full">
              <div
                className="h-2 rounded-full"
                style={{
                  width: `${total > 0 ? (Number(value) / total) * 100 : 0}%`,
                  backgroundColor: colors[key],
                }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatAssignmentStatus;
