import { concatString, formatString } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Badge, Button, Switch, Table } from "antd";
import { Trash2 } from "lucide-react";

function SaleitemTable(props: any) {
  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      render: (name: any, record: any) => (
        <div
          className="header_blue_text__14 cursor-pointer"
          onClick={() => props.handleEdit(record)}
        >
          {formatString(name)}
        </div>
      ),
    },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
      key: "description",
      render: (description: any) => concatString(description, 50),
    },
    {
      title: "CODE",
      dataIndex: "code",
      key: "code",
      render: (code: any) => formatString(code),
    },
    {
      title: "UNIT PRICE",
      dataIndex: "unit_price",
      key: "unit_price",
      render: (unit_price: any) => formatString(unit_price),
    },
    {
      title: "STATUS",
      dataIndex: "is_active",
      key: "is_active",
      render: (is_active: any, record: any) => (
        <Switch
          size="small"
          checked={is_active}
          onChange={() =>
            props.handleStatusChange(
              getValue(record, `id`, ""),
              getValue(record, `is_active`, false)
            )
          }
        />
      ),
    },
    // {
    //   title: "STATUS",
    //   dataIndex: "is_active",
    //   key: "is_active",
    //   render: (is_active: any) => (
    //     <div className="d-flex align-items-center gap-2">
    //       <Badge status={is_active === true ? "success" : "error"} />
    //       {is_active === true ? "Active" : "Inactive"}
    //     </div>
    //   ),
    // },
    {
      title: "ACTION",
      dataIndex: "is_active",
      key: "is_active",
      render: (is_active: any, record: any) => {
        return (
          <div className="d-flex gap-3 align-items-center">
            <Button
              size="small"
              variant="text"
              color="danger"
              onClick={() =>
                props.handleDeleteModal(getValue(record, `id`, ""))
              }
              icon={<Trash2 size={16} />}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Table
      size="middle"
      className="p-4"
      bordered
      columns={columns}
      dataSource={getValue(props, `list`, [])}
      rowKey="id"
      pagination={{
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} items`,
      }}
    />
  );
}

export default SaleitemTable;
