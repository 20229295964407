import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { getSpecificChatbot, updateChatbot } from "@services/chatbot.service";
import { toast } from "sonner";
import ChatbotNavviews from "./chatbot-navviews";
import BotBuilderStepOne from "../components/bot-builder-step-1";
import BotBuilderStepTwo from "../components/bot-builder-step-2";
import BotBuilderStepThree from "../components/bot-builder-step-3";
import { geChatbotAvatar } from "@services/fb.service";
import { chatbotAvatarmediaUpload } from "@services/upload.service";
import BotDemo from "../components/bot-demo";
import ChatbotSubHeader from "./subheader";
import ListLoader from "@components/common/Loader";
import { Button } from "antd";
import SettingsChatInterface from "@pages/Private/AIChatbot/Details/Settings/ChatInterface";
import SidebarLayout from "@layouts/SidebarLayout/SidebarLayout";

const ChatbotSettings = (props: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  const initialState = {
    name: "",
    platform: "WEB",
    description: "",
    instructions_type: "custom_prompt",
    instructions: "",
    temp: 1,
    retraining_interval: "never",
    initial_messages: ["Hi! What can I help you with?"],
    suggested_messages: [""],
    message_placeholder: "",
    collect_user_feedback: true,
    regenerate_messages: true,
    footer: "",
    theme: "",
    position: "RIGHT_BOTTOM",
    channels: ["WEBSITE"],
    supported_languages: [],
    response_interval: 0,
    idle_chat: true,
    idle_chat_time: 0,
    idle_chat_response: "",
    avatar: "",
    theme_color: "",
    enable_email_notification: true,
    send_email_notification_to: "",
    type: "",
  };
  const [request, setRequest] = useState(initialState);

  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    getAvatarList();
    if (Object.keys(UrlParams).length === 0) {
      getData();
    }
  }, []);
  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      getData();
    }
  }, [window.location.href]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [info, setInfo] = useState({});
  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await getSpecificChatbot(getValue(params, `id`, ""));
      if (resp) {
        setInfo(getValue(resp, `data`, {}));
        setRequest({
          ...request,
          name: getValue(resp, `data.name`, ""),
          platform: getValue(resp, `data.platform`, ""),
          position: getValue(resp, `data.position`, ""),
          description: getValue(resp, `data.description`, ""),
          channels: getValue(resp, `data.channels`, []),
          supported_languages: getValue(resp, `data.supported_languages`, []),
          response_interval: getValue(resp, `data.response_interval`, ""),
          idle_chat_time: getValue(resp, `data.idle_chat_time`, ""),
          idle_chat_response: getValue(resp, `data.idle_chat_response`, ""),
          avatar: getValue(resp, `data.avatar`, ""),
          type: getValue(resp, `data.type`, ""),
          theme_color: getValue(resp, `data.theme_color`, ""),
          idle_chat: getValue(resp, `data.idle_chat`, false),
          enable_email_notification: getValue(
            resp,
            `data.enable_email_notification`,
            false
          ),
          send_email_notification_to:
            typeof getValue(resp, `data.send_email_notification_to`, "") ===
            "string"
              ? getValue(resp, `data.send_email_notification_to`, "").split(",")
              : getValue(resp, `data.send_email_notification_to`, []),

          instructions_type: getValue(resp, `data.instructions_type`, ""),
          instructions: getValue(resp, `data.instructions`, ""),
          temp: getValue(resp, `data.temp`, 0.1),
          retraining_interval: getValue(resp, `data.retraining_interval`, ""),
          initial_messages:
            getValue(resp, `data.initial_messages.length`, 0) > 0
              ? getValue(resp, `data.initial_messages`, [])
              : ["Hi! What can I help you with?"].join("\n"),
          suggested_messages: getValue(
            resp,
            `data.suggested_messages`,
            []
          ).join("\n"),
          message_placeholder: getValue(resp, `data.message_placeholder`, ""),
          collect_user_feedback: getValue(
            resp,
            `data.collect_user_feedback`,
            false
          ),
          regenerate_messages: getValue(
            resp,
            `data.regenerate_messages`,
            false
          ),
          footer: getValue(resp, `data.footer`, ""),
        });

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [avatarList, setAvatarlist] = useState([]);
  const getAvatarList = async () => {
    try {
      let resp = await geChatbotAvatar("");
      if (resp) {
        setAvatarlist(getValue(resp, `data`, []));
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const handleNavigateBack = () => {
    navigate(`/${getValue(params, `orgId`, "")}/chatbot`);
  };

  const [createLoading, setCreateLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setCreateLoading(true);
      let payload = JSON.parse(JSON.stringify(request));
      payload["send_email_notification_to"] =
        typeof getValue(payload, `send_email_notification_to`, "") === "string"
          ? getValue(request, `send_email_notification_to`, "").split(",")
          : getValue(request, `send_email_notification_to`, []);
      payload["initial_messages"] =
        typeof getValue(payload, `initial_messages`, "") === "string"
          ? getValue(request, `initial_messages`, "").split(",")
          : getValue(request, `initial_messages`, []);
      payload["suggested_messages"] =
        typeof getValue(payload, `suggested_messages`, "") === "string"
          ? getValue(request, `suggested_messages`, "").split(",")
          : getValue(request, `suggested_messages`, []);

      let resp = await updateChatbot(getValue(params, `id`, ""), payload);
      if (resp) {
        toast.success("Updated Successfully");
        setCreateLoading(false);
      } else {
        setCreateLoading(false);
      }
    } catch (error) {
      setCreateLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                           Upload Avatar section                            */
  /* -------------------------------------------------------------------------- */

  const [uploadLoading, setUploadLoading] = useState(false);
  const handleUploadDocuments = async (e: any) => {
    Array.from(e.target.files).forEach(async (item: any, index: number) => {
      let formData = new FormData();
      formData.append("file", item);
      formData.append("filename", item.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");
      try {
        setUploadLoading(true);
        let resp = await chatbotAvatarmediaUpload(formData);
        if (resp) {
          getAvatarList();
        } else {
          e = null;
          setUploadLoading(false);
        }
      } catch (error) {
        e = null;
        setUploadLoading(false);
      }
    });
  };
  return (
    <SidebarLayout {...props}>
      <ChatbotSubHeader
        subHeaderListTitle={getValue(info, `name`, "")}
        platform={getValue(info, `platform`, "")}
        isRightArrowVisible={true}
        isSubmenuListAvailable={true}
        hideClose={true}
        pipeline={false}
        showBack={true}
        handleNavigateBack={handleNavigateBack}
        params={params}
        permissions={getValue(props, `permissions`, [])}
      />
      <div className="d-flex">
        <div className="w-100">
          <ChatbotNavviews />
          <div className="w-100 d-flex">
            {isLoading ? (
              <ListLoader />
            ) : (
              <>
                <div
                  className={`w-50 px-4 py-3 add-overflow-y chatbot_settings_container`}
                >
                  <BotBuilderStepOne
                    request={request}
                    setRequest={setRequest}
                    edit
                  />
                  {getValue(info, `platform`, "") !== "WHATSAPP" && (
                    <SettingsChatInterface
                      request={request}
                      setRequest={setRequest}
                    />
                  )}
                  <BotBuilderStepTwo
                    request={request}
                    setRequest={setRequest}
                  />
                  <BotBuilderStepThree
                    request={request}
                    setRequest={setRequest}
                    avatarList={avatarList}
                    handleUploadDocuments={handleUploadDocuments}
                    platform={getValue(info, `platform`, "")}
                  />
                  {getValue(props, `permissions`, []).includes("update") && (
                    <Button
                      type="primary"
                      size="large"
                      loading={createLoading}
                      onClick={() => handleSubmit()}
                      // disabled={isLoading}
                    >
                      Update
                    </Button>
                  )}
                </div>
              </>
            )}
            <BotDemo request={request} changeHeight />
          </div>
        </div>
      </div>
    </SidebarLayout>
  );
};

export default ChatbotSettings;
