import RemoveCircleSvgComponent from "@assets/svg/remove-circle";
import { formatString } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { BadgeCheck } from "lucide-react";
import { secondaryIntialState } from "../request-helper";
import DraftJSRefEditor from "@components/custom/DraftJSEditor/draft-ref-editor";
import { Dropdown, Menu, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";

function MergeStep2(props: any) {
  const {
    editNote,
    isMerge,
    note,
    primary_id,
    setPrimaryId,
    setEditNote,
    setRequest,
    mainNote,
    setNoteInPrimary,
  } = props;
  const handleChangeEditor = (name: string, value: string) => {
    setRequest({
      ...note,
      [name]: value,
    });
  };
  const handleMenuClick = (e: any) => {
    setRequest({
      ...note,
      is_private: e.key,
    });
  };
  const menu = (
    <Menu
      onClick={handleMenuClick}
      selectedKeys={[note.is_private ? note.is_private : ""]}
    >
      <Menu.Item key="private">Private</Menu.Item>
      <Menu.Item key="public">Public</Menu.Item>
    </Menu>
  );
  return (
    <div>
      <div className="d-flex justify-content-center align-items-center w-100">
        {!editNote ? (
          <div className="ticket_note_container">
            {isMerge && (
              <h6
                className="header_blue_text__14 note_edit"
                onClick={() => setEditNote(true)}
              >
                Edit Note
              </h6>
            )}
            {!isMerge && (
              <div
                className="note_edit"
                onClick={() => {
                  if (primary_id !== getValue(note, `ticketInfo.id`, "")) {
                    setPrimaryId(getValue(note, `ticketInfo.id`, ""));
                    setRequest({
                      ...mainNote,
                      html: `This ticket is closed and merged into ticket ${getValue(
                        note,
                        "ticketInfo.record_num",
                        ""
                      ).toString()} is merged into this ticket.`,
                    });
                    setNoteInPrimary({
                      ...note,
                      html: `Tickets with IDs ${getValue(
                        mainNote,
                        "ticketInfo.record_num",
                        ""
                      ).toString()} is merged into this ticket. `,
                    });
                  }
                }}
              >
                <BadgeCheck
                  style={{
                    color:
                      primary_id === getValue(note, `ticketInfo.id`, "")
                        ? "#1a73e8"
                        : "lightgray",
                  }}
                />
                <p
                  className={
                    primary_id === getValue(note, `ticketInfo.id`, "")
                      ? `header_blue_text__14`
                      : `small_text__14`
                  }
                >
                  primary
                </p>
              </div>
            )}
            <div className="d-flex align-items-center">
              <>
                <div
                  className=""
                  onClick={() => {
                    if (primary_id !== getValue(note, `ticketInfo.id`, "")) {
                      setRequest(secondaryIntialState);
                    }
                  }}
                >
                  <RemoveCircleSvgComponent
                    color={
                      primary_id !== getValue(note, `ticketInfo.id`, "")
                        ? "#f27475"
                        : "gray"
                    }
                  />
                </div>
              </>
              <div className="ms-3">
                <p>
                  Ticket ID : #{getValue(note, `ticketInfo.record_num`, "")}
                </p>
                <p>
                  Ticket Name :{getValue(note, `ticketInfo.ticket_name`, "")}
                </p>
                <p>
                  Ticket Description :
                  {getValue(note, `ticketInfo.ticket_description`, "")}
                </p>
                <p>
                  Ticket Status :
                  {formatString(getValue(note, `ticketInfo.status`, ""))}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="ticket_note_container">
            <h6
              className="header_blue_text__14 note_edit"
              onClick={() => setEditNote(false)}
            >
              Back
            </h6>

            <DraftJSRefEditor
              editorState={getValue(note, `html`, "")}
              handleChangeEditor={handleChangeEditor}
              name="html"
              placeholder={""}
              editorStyle={{
                background: "white",
                paddingLeft: "20px",
                paddingTop: "20px",
                height: "30vh",
              }}
              focusColor={"#3fd1a9"}
              toolbar={{
                options: ["inline", "emoji"],
                inline: {
                  options: ["bold", "italic", "underline", "strikethrough"],
                },
              }}
              focus
              autoFocus
            />
            <div className="mt-4">
              <Dropdown overlay={menu} trigger={["click"]}>
                <a>
                  <Space>
                    {getValue(note, `is_private`, "") === "private"
                      ? "Not visible to contact"
                      : "Visible to contact"}
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MergeStep2;
