import {
  ChevronDown,
  Eye,
  Mail,
  NotepadText,
  OctagonAlert,
  PlusCircle,
  Trash2,
  Zap,
} from "lucide-react";
import { getValue, setValue } from "@utils/lodash";
import { Alert, Dropdown, MenuProps, Tag } from "antd";
import DefaultActions from "./default/DefaultActions";
import NotesAction from "./notes/NotesAction";
import EmailAction from "./email/EmailAction";
import { useState } from "react";

interface Update {
  action: string;
  fieldId: string;
  value: any[];
  isEmpty: boolean;
  field_name?: string;
}
interface Notes {
  html: string;
  to: any[];
  is_private: boolean;
  attachments: any[];
  id?: string;
  action: string;
}
interface Emails {
  id?: string;
  action: string;
  field_name?: string;
  email_to?: string;
  email_body: string;
  email_subject: string;
  email_agent_id?: string;
  email_group_id?: string;
}

interface Request {
  actions: {
    notes?: Notes[];
    emails?: Emails[];
    updates?: Update[];
  };
}

type ValueTypes = {
  [key: number]: any;
};

interface OptionFieldsProps {
  request: Request;
  setRequest: (req: any) => void;
  updateFields: any[];
  validator: any;
  setValueTypes: any;
  ticketKeys: any;
}

const OptionFields = ({
  request,
  setRequest,
  updateFields,
  validator,
  setValueTypes,
  ticketKeys,
}: OptionFieldsProps) => {
  const handleAddOption = (): void => {
    setRequest((prev: Request) => ({
      ...prev,
      actions: {
        ...prev.actions,
        updates: [
          ...(prev.actions.updates || []),
          { action: "add", fieldId: "", value: [], isEmpty: false },
        ],
      },
    }));
  };

  const handleAddActions = (type: "delete" | "spam" | "watcher") => {
    setRequest((prev: Request) => {
      const newAction = {
        action: "add",
        field_name: type,
        isEmpty: true,
        ...(type === "watcher" && { value: [], isEmpty: false }),
      };

      return {
        ...prev,
        actions: {
          ...prev.actions,
          updates: [...(prev.actions.updates || []), newAction],
        },
      };
    });
  };

  const handleAddNotes = () => {
    setRequest((prev: Request) => ({
      ...prev,
      actions: {
        ...prev.actions,
        notes: [
          ...(prev.actions.notes || []),
          {
            html: "",
            to: [],
            is_private: true,
            attachments: [],
            action: "add",
          },
        ],
      },
    }));
  };

  const handleAddMoreOptions = (
    type:
      | "send_email_to_agent"
      | "send_email_to_group"
      | "send_email_to_requester"
  ) => {
    setRequest((prev: Request) => {
      const email: Emails = {
        action: "add",
        email_body: "",
        email_subject: "",
        field_name: type,
      };
      return {
        ...prev,
        actions: {
          ...prev.actions,
          emails: [...(prev.actions.emails || []), email],
        },
      };
    });
  };

  const handleDeleteOption = (index: number, key: string): void => {
    setRequest((prev: Request) => ({
      ...prev,
      actions: {
        ...prev.actions,
        [key as keyof Request["actions"]]: (
          prev.actions[key as keyof Request["actions"]] || []
        ).filter((_: any, i: number) => i !== index),
      },
    }));

    setValueTypes((prev: ValueTypes) => {
      const newTypes: ValueTypes = {};
      Object.keys(prev).forEach((key) => {
        const idx = parseInt(key, 10);
        if (idx < index) {
          newTypes[idx] = prev[idx];
        } else if (idx > index) {
          newTypes[idx - 1] = prev[idx];
        }
      });
      return newTypes;
    });
  };

  const handleIfCondition = (index: number, selectedOption: any): void => {
    setRequest((prev: Request) => ({
      ...prev,
      actions: {
        ...prev.actions,
        updates: (prev.actions.updates || []).map((item, i) =>
          i === index ? { ...item, fieldId: selectedOption.value } : item
        ),
      },
    }));
    setValueTypes((prev: ValueTypes) => ({
      ...prev,
      [index]: selectedOption,
    }));
  };

  const findInputType = (id: string) => {
    let filteredConditions = updateFields.find(
      (item: object) => getValue(item, `fieldId`, "") === id
    );
    let index = updateFields.findIndex(
      (item: object) => getValue(item, "fieldId", "") === id
    );
    return { index: index, ...filteredConditions };
  };

  const removeConditionUpdate = (index: number, key: string) => {
    setValue(request, `actions.${key}[${index}].action`, "remove");
    setRequest({ ...request });
  };

  const filteredActions = () => {
    let list =
      getValue(request, `actions.updates.length`, 0) > 0
        ? getValue(request, `actions.updates`, []).filter(
            (item: object) => getValue(item, `action`, "") !== "remove"
          )
        : [];
    return list;
  };

  /* -------------------------------------------------------------------------- */
  /*                               Notes Section                                */
  /* -------------------------------------------------------------------------- */

  const filteredNotes = () => {
    let list =
      getValue(request, `actions.notes.length`, 0) > 0
        ? getValue(request, `actions.notes`, []).filter(
            (item: object) => getValue(item, `action`, "") !== "remove"
          )
        : [];
    return list;
  };

  /* -------------------------------------------------------------------------- */
  /*                               Email Section                                */
  /* -------------------------------------------------------------------------- */

  const filteredEmails = () => {
    let list =
      getValue(request, `actions.emails.length`, 0) > 0
        ? getValue(request, `actions.emails`, []).filter(
            (item: object) => getValue(item, `action`, "") !== "remove"
          )
        : [];
    return list;
  };

  /* -------------------------------------------------------------------------- */
  /*                            More Actions Section                            */
  /* -------------------------------------------------------------------------- */

  const items: MenuProps["items"] = [
    {
      type: "group",
      label: "Actions",
      children: [
        {
          key: "1",
          icon: <PlusCircle size={16} />,
          label: "New action",
          onClick: handleAddOption,
        },
        {
          key: "2",
          icon: <Trash2 size={16} />,
          label: "Delete ticket",
          disabled: (request.actions.updates || []).some(
            (update: any) => update.field_name === "delete"
          ),
          onClick: () => handleAddActions("delete"),
        },
        {
          key: "3",
          icon: <OctagonAlert size={16} />,
          label: "Mark as spam",
          disabled: (request.actions.updates || []).some(
            (update: any) => update.field_name === "spam"
          ),
          onClick: () => handleAddActions("spam"),
        },
        {
          key: "4",
          icon: <Eye size={16} />,
          label: "Add watcher",
          disabled: (request.actions.updates || []).some(
            (update: any) => update.field_name === "watcher"
          ),
          onClick: () => handleAddActions("watcher"),
        },
      ],
    },
    {
      type: "group",
      label: "Notes",
      children: [
        {
          key: "5",
          icon: <NotepadText size={16} />,
          label: "Add note",
          disabled: getValue(request, "actions.notes.length", 0) === 1,
          onClick: () => handleAddNotes(),
        },
      ],
    },
    {
      type: "group",
      label: "Send Email",
      children: [
        {
          key: "6",
          icon: <Mail size={16} />,
          label: "To group",
          onClick: () => handleAddMoreOptions("send_email_to_group"),
        },
        {
          key: "7",
          icon: <Mail size={16} />,
          label: "To agents",
          onClick: () => handleAddMoreOptions("send_email_to_agent"),
        },
        {
          key: "8",
          icon: <Mail size={16} />,
          label: "To requester",
          onClick: () => handleAddMoreOptions("send_email_to_requester"),
        },
      ],
    },
  ];

  return (
    <>
      <div className="mt-5">
        <h6 className="header_text__18 mb-3 d-flex align-items-center gap-1">
          <Tag color="blue" bordered={false}>
            <Zap size={16} />
          </Tag>
          Actions
        </h6>
      </div>

      <div className="mt-1 sla-condition-card-wrapper">
        {filteredActions().length > 0 && (
          <h6 className="px-4 py-2 header_text__14">Update Actions</h6>
        )}
        {filteredActions().map((item: Update, index: number) => (
          <DefaultActions
            index={index}
            item={item}
            request={request}
            setRequest={setRequest}
            updateFields={updateFields}
            handleIfCondition={handleIfCondition}
            validator={validator}
            findInputType={findInputType}
            removeConditionUpdate={removeConditionUpdate}
            handleDeleteOption={handleDeleteOption}
          />
        ))}
        {filteredNotes().length > 0 && (
          <h6 className="px-4 py-2 header_text__14">Notes</h6>
        )}
        {filteredNotes().map((item: Update, index: number) => (
          <NotesAction
            index={index}
            item={item}
            request={request}
            setRequest={setRequest}
            removeConditionUpdate={removeConditionUpdate}
            handleDeleteOption={handleDeleteOption}
            validator={validator}
          />
        ))}
        {filteredEmails().length > 0 && (
          <h6 className="px-4 py-2 header_text__14">Emails</h6>
        )}
        {filteredEmails().map((item: Update, index: number) => (
          <EmailAction
            index={index}
            item={item}
            request={request}
            setRequest={setRequest}
            removeConditionUpdate={removeConditionUpdate}
            handleDeleteOption={handleDeleteOption}
            validator={validator}
            ticketKeys={ticketKeys}
          />
        ))}
        <div className="d-flex align-items-center px-4 py-3 gap-2">
          <div
            onClick={handleAddOption}
            className="header_blue_text__14 d-flex align-items-center gap-1 cursor-pointer "
          >
            <PlusCircle size={18} />
            Add New Action
          </div>
          <Dropdown menu={{ items }}>
            <div className="header_blue_text__14 d-flex align-items-center gap-1 cursor-pointer ">
              <ChevronDown size={18} />
            </div>
          </Dropdown>
        </div>
      </div>
      {getValue(request, `actions.updates.length`, 0) === 0 &&
        getValue(request, `actions.notes.length`, 0) === 0 &&
        getValue(request, `actions.emails.length`, 0) === 0 && (
          <Alert
            showIcon
            type="info"
            message="Add min 1 action."
            className="w-fit-content mt-2"
          />
        )}
    </>
  );
};

export default OptionFields;
