import DragIndicatorSvgComponent from "@assets/svg/drag-indicator";
import { QueryRequestHelper } from "@common/query-request-helper";
import { capitalizeFirstLetter } from "@common/text-helpers";
import ListLoader from "@components/common/Loader";
import DynamicNoDataPage from "@components/common/NoData/dynamic-nodata";
import { getSpecificSkillAgents } from "@services/skills.service";
import { getValue } from "@utils/lodash";
import {
  Drawer,
  Dropdown,
  Empty,
  MenuProps,
  Modal,
  Pagination,
  Switch,
  Table,
  Tooltip,
} from "antd";
import { LockKeyhole, MoreVertical } from "lucide-react";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

const ListingHoc = ({
  url,
  apiData,
  module,
  deleteApi,
  listApi,
  updatePositionApi,
  updateSatusApi,
  isSkills,
  showAgents,
}: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [list, setList] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isAgentsOpen, setIsAgentsOpen] = useState(false);
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getSlaPolicyList(true);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getSlaPolicyList(true);
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getSlaPolicyList = async (status: boolean) => {
    try {
      setIsLoading(status);
      const payload = {
        page_size: getValue(UrlParams, `page_size`, "")
          ? getValue(UrlParams, `page_size`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await listApi(queryRequest);
      if (resp) {
        setList(getValue(resp, `data.${apiData}`, []));
        setPage_no(getValue(resp, "data.pagination.page", 0));
        setTotalCount(getValue(resp, "data.pagination.total", 0));
      }
    } catch (error) {
      toast.error(`Failed to load ${module}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdatePosition = async (
    updates: { id: string; seq_num: number }[]
  ) => {
    setIsLoading1(true);
    try {
      const payload = { swap_fields: updates };
      const resp = await updatePositionApi(payload);
      if (resp) {
        getSlaPolicyList(true);
      } else {
        toast.error("Failed to update position");
      }
    } catch (error) {
      toast.error("Error updating position");
    } finally {
      setIsLoading1(false);
    }
  };

  const handleUpdateStatus = async (id: string, status: boolean) => {
    setIsLoading2(true);
    try {
      const payload = {
        ids: [id],
        is_active: status,
      };
      const resp = await updateSatusApi(payload);
      if (resp) {
        toast.success("Updated successfully");
        getSlaPolicyList(true);
      } else {
        toast.error("Failed to update");
      }
    } catch (error) {
      toast.error("Error updating");
    } finally {
      setIsLoading2(false);
    }
  };

  const handleDeleteModal = (id: string) => {
    Modal.confirm({
      title: `Are you sure you want to delete?`,
      content: "Once deleted, this cannot be recovered.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await deleteApi(id);
          toast.success("Deleted successfully");
          getSlaPolicyList(true);
        } catch (error) {
          toast.error("Error deleting");
        }
      },
    });
  };

  const handleGetAllAgents = async (id: string) => {
    try {
      setIsLoading3(true);
      const resp = await getSpecificSkillAgents(id);
      if (resp) {
        setAgents(getValue(resp, "data.agents", []));
      }
    } catch (error) {
      toast.error("Failed to load Agents.");
    } finally {
      setIsLoading3(false);
    }
  };

  const handleChangePagination = (page_no: any, limit: any) => {
    setPage_no(page_no);
    setLimit(limit);

    let payload = {
      ...UrlParams,
      page_no: page_no,
      page_size: limit,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    setPage_no(page_no);
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedList = Array.from(list);
    const [removed] = reorderedList.splice(result.source.index, 1);
    reorderedList.splice(result.destination.index, 0, removed);

    setList(reorderedList);
    const updates = reorderedList.map((item, index) => ({
      id: getValue(item, "id", ""),
      seq_num: index + 1,
    }));

    handleUpdatePosition(updates);
  };

  //Agents
  const toggleAgentSlider = () => {
    setIsAgentsOpen(!isAgentsOpen);
  };

  const handleShowAgents = (id: string) => {
    setIsAgentsOpen(true);
    handleGetAllAgents(id);
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "first_name",
      key: "first_name",
      render: (first_name: string, text: any) => (
        <>
          <h6 className="header_text__14">
            {capitalizeFirstLetter(first_name) +
              " " +
              capitalizeFirstLetter(getValue(text, "last_name", ""))}
          </h6>
        </>
      ),
    },
    {
      title: "EMAIL",
      dataIndex: "agent",
      key: "email",
      render: (agent: any) => getValue(agent, "email", "-"),
    },
  ];

  return isLoading ? (
    <ListLoader />
  ) : list.length > 0 ? (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="slaPolicies">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="d-grid gap-3"
            >
              {list.map((listItem, index) => {
                const items: MenuProps["items"] = [
                  {
                    key: "1",
                    label: "Delete",
                    onClick: () =>
                      handleDeleteModal(getValue(listItem, "id", "")),
                    disabled: getValue(listItem, "is_default", ""),
                  },
                  ...(showAgents
                    ? [
                        {
                          key: "2",
                          label: "Show Agents",
                          onClick: () =>
                            handleShowAgents(getValue(listItem, "id", "")),
                        },
                      ]
                    : []),
                ];

                return (
                  <Draggable
                    key={getValue(listItem, "id", "")}
                    draggableId={getValue(listItem, "id", "")}
                    index={index}
                  >
                    {(provided: any) => (
                      <div
                        key={index}
                        className="border p-4 rounded-3 sla-policy-card bg-white"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <div className="d-flex justify-content-between align-items-center gap-3">
                          {list.length > 1 && (
                            <div
                              className="d-flex justify-content-center cursor-grabber"
                              {...provided.dragHandleProps}
                            >
                              <DragIndicatorSvgComponent color={"#aeaeae"} />
                            </div>
                          )}
                          <div
                            className="cursor-pointer w-100"
                            onClick={() =>
                              navigate(
                                `/${getValue(
                                  params,
                                  "orgId",
                                  ""
                                )}/settings/helpdesk/${url}/edit/${getValue(
                                  listItem,
                                  "id",
                                  ""
                                )}`
                              )
                            }
                          >
                            <h6 className="header_text__16 d-flex align-items-center gap-2">
                              {index + 1}.{" "}
                              {capitalizeFirstLetter(
                                getValue(listItem, "name", "")
                              )}
                              {getValue(listItem, "is_default", "") && (
                                <Tooltip title="Default SLA Policy">
                                  <LockKeyhole size={14} />
                                </Tooltip>
                              )}
                            </h6>
                            {getValue(listItem, "description", "") && (
                              <p className="small_text__14 pb-1 pt-2">
                                {getValue(listItem, "description", "")}
                              </p>
                            )}
                          </div>
                          <div className="d-flex align-items-center gap-3">
                            {!isSkills && (
                              <Switch
                                size="small"
                                checked={getValue(listItem, "is_active", "")}
                                disabled={getValue(listItem, "is_default", "")}
                                onChange={() =>
                                  handleUpdateStatus(
                                    getValue(listItem, "id", ""),
                                    getValue(listItem, "is_active", "")
                                      ? false
                                      : true
                                  )
                                }
                              />
                            )}
                            <Dropdown menu={{ items }}>
                              <MoreVertical size={18} />
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {totalCount > 10 && (
        <Pagination
          align="center"
          className="mt-4"
          total={totalCount}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          defaultPageSize={limit}
          defaultCurrent={page_no}
          onChange={handleChangePagination}
        />
      )}
      <Drawer
        title="Agents"
        onClose={() => {
          toggleAgentSlider();
        }}
        open={isAgentsOpen}
        width="600px"
        className="chart-details-table-drawer"
        loading={isLoading3}
      >
        {agents.length > 0 ? (
          <Table
            size="middle"
            bordered
            columns={columns}
            dataSource={agents}
            rowKey={(record: any) => record.agent_id}
            loading={isLoading2}
            className="p-4"
            pagination={false}
            scroll={{ y: "calc(100vh - 156px)" }}
          />
        ) : (
          <Empty className="py-4" />
        )}
      </Drawer>
    </>
  ) : (
    <DynamicNoDataPage module={module} />
  );
};

export default ListingHoc;
