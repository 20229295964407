import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ConfigProvider, FloatButton, theme } from "antd";
import { Wifi, WifiOff } from "lucide-react";
import { usePermissionContext } from "@context/permissionContext";
import { useStateContext } from "@context/dataContext";
import { PublicRoutes } from "routes/public.routes";
import { PrivateRoutes } from "routes/private.routes";
import NotFoundPage from "@pages/Public/404";
import ComfirmOrganization from "@pages/Public/UserInvitation/confirm-org";
import SettingsPreviewForm from "@pages/Private/Settings/FormCreator/preview-form";
import SettingsPreviewFormThankyou from "@pages/Private/Settings/FormCreator/preview-form-thankyou";
import GenerateWhatsappLink from "@pages/Private/Temp/Whatsapp/generate-whatsapp-link";
import DuplicateImports from "@pages/Public/DuplicateImports";
import SharedImportData from "@pages/Public/ImportData/shared-data";
import { getValue } from "@utils/lodash";
import { motion, AnimatePresence } from "framer-motion";

function App() {
  const { themeColor, appTheme } = useStateContext();
  const {
    tabPermissionList,
    subTabPermissionList,
    permissionAPITriggered,
    mainPermissions,
  } = usePermissionContext();

  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  const [showOffline, setShowOffline] = useState(isOffline);

  const handleOnline = useCallback(() => {
    setIsOffline(false);
    setTimeout(() => setShowOffline(false), 2000);
  }, []);

  const handleOffline = useCallback(() => {
    setIsOffline(true);
    setShowOffline(true);
  }, []);

  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [handleOnline, handleOffline]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: themeColor || "#ab4fee",
        },
        components: {
          Button: {
            fontSizeLG: 14,
          },
          Input: {
            borderRadius: 4,
            borderRadiusLG: 4,
          },
          Segmented: {
            trackBg: themeColor ? `${themeColor}20` : "#ab4fee20",
          },
        },
      }}
    >
      <AnimatePresence>
        {showOffline && (
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ opacity: { duration: 2 } }}
          >
            <FloatButton
              shape="circle"
              type="primary"
              style={{
                backgroundColor: "#e41a2a",
                position: "fixed",
                right: 24,
                bottom: 24,
              }}
              icon={isOffline ? <WifiOff size={18} /> : <Wifi size={18} />}
              className={isOffline ? "offline-button" : "online-button"}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <BrowserRouter>
        <Routes>
          {PublicRoutes.map((item, key) => {
            const Element = item.component;
            return (
              <Route
                key={key}
                path={item.path}
                element={
                  !localStorage.getItem("accessToken") ? (
                    <Element />
                  ) : (
                    <Navigate to="/organisations" />
                  )
                }
              />
            );
          })}
          {PrivateRoutes.map((item, key) => {
            const Element = item.component;
            let permissions = mainPermissions.filter(
              (items: any) => getValue(items, "tabKey", "") === item.name
            );
            return (
              <Route
                key={key}
                path={item.path}
                element={
                  localStorage.getItem("accessToken") ? (
                    <Element
                      permissions={
                        getValue(permissions, "length", 0) > 0
                          ? getValue(permissions, "[0].permissions", [])
                          : []
                      }
                      name={getValue(item, "name", "")}
                      tabPermissionList={tabPermissionList}
                      subTabPermissionList={subTabPermissionList}
                      permissionAPITriggered={permissionAPITriggered}
                    />
                  ) : window.location.pathname === "/organisations/join" ? (
                    <Navigate to={`/signin${window.location.search}`} />
                  ) : (
                    <Navigate to="/signin" />
                  )
                }
              />
            );
          })}
          <Route
            path="/BTS-2024-exhibitor-directory"
            element={<SharedImportData />}
          />
          <Route path="/duplicate-imports" element={<DuplicateImports />} />
          <Route
            path="/generate-whatsapp-button"
            element={<GenerateWhatsappLink />}
          />
          <Route path="/organisations/join" element={<ComfirmOrganization />} />
          <Route
            path="/:orgId/settings/web-forms/:slug"
            element={<SettingsPreviewForm />}
          />
          <Route
            path="/:orgId/settings/web-forms/:slug/thankyou"
            element={<SettingsPreviewFormThankyou />}
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
