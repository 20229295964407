import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllMediaLibrary = (queryRequest: string) =>
  get(`${config.API_URL}/resources?${queryRequest}`);

// export const getListAllMediaLibrary = (queryRequest: string) =>
//   get(`${config.API_URL}/resources/list?${queryRequest}`);

export const getSpecificMediaLibrary = (id: string) =>
  get(`${config.API_URL}/resources/${id}`);

export const createMediaLibrary = (payload: object) =>
  post(`${config.API_URL}/resources`, payload);

export const updateMediaLibrary = (id: string, payload: object) =>
  patch(`${config.API_URL}/resources/${id}`, payload);

export const deleteMediaLibrary = (id: string) =>
  Delete(`${config.API_URL}/resources/${id}`);
