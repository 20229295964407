import { handleSelectChange, handleTextChange } from "@common/handleChange";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import { PhoneInput } from "react-international-phone";
import timezones from "@common/json/standardized-timezone-data.json";
import countries from "@common/json/contry.json";
import currencies from "@common/json/currency.json";

const OrganisationModal = ({ request, setRequest, simpleValidator }: any) => {
  const [currencyList, setCurrencyList] = useState([]);
  useEffect(() => {
    let codes: any = currencies.map((item: object) => ({
      ...item,
      label: `${getValue(item, `cc`, "")} - ${getValue(item, `name`, "")}`,
      value: `${getValue(item, `cc`, "")}`,
    }));
    setCurrencyList(codes);
  }, []);

  const handleSelectCountry = (e: object) => {
    let timezone: any = timezones.find(
      (item: object) =>
        getValue(item, `countryCode`, "") === getValue(e, `code`, "")
    );
    setRequest((prevSta: any) => ({
      ...prevSta,
      phone_code: getValue(e, `dial_code`, ""),
      country: getValue(e, `code`, ""),
      currency: getValue(e, `currency_code`, ""),
      time_zone: getValue(timezone, `value`, ""),
      phone_no: getValue(e, `dial_code`, ""),
    }));
  };

  return (
    <div className="mx-4 mb-4 d-grid gap-2">
      <div>
        <InputRuleForm
          inputType="TEXT"
          label={"Organisation Name"}
          placeholder="Enter Organisation Name"
          name={"name"}
          value={getValue(request, `name`, "")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleTextChange(e, request, setRequest)
          }
          validator={simpleValidator}
          //   style={{ height: "40px", width: "365px" }}
          marginZero
          required
          validLeft
        />
      </div>
      <div>
        <SearchToolTip
          placeholder={`Select Country`}
          onChange={(e: any) => handleSelectCountry(e)}
          label={`label`}
          data={countries.map((item: object) => ({
            ...item,
            value: getValue(item, `code`, ""),
            label: getValue(item, `name`, ""),
          }))}
          value={getValue(request, `country`, "")}
          selectKey={"value"}
          name={"Country"}
          validator={simpleValidator}
          required
        />
      </div>
      <div>
        <SearchToolTip
          placeholder={`Select Currency`}
          onChange={(e: object) =>
            handleSelectChange(e, "currency", request, setRequest)
          }
          label={`label`}
          data={currencyList}
          value={getValue(request, `currency`, "")}
          selectKey={"value"}
          name={"Currency"}
          validator={simpleValidator}
          required
        />
      </div>
      <div>
        <SearchToolTip
          placeholder={`Select Time Zone`}
          onChange={(e: object) =>
            handleSelectChange(e, "time_zone", request, setRequest)
          }
          label={`label`}
          data={timezones}
          value={getValue(request, `time_zone`, "")}
          selectKey={"value"}
          name={"Time Zone"}
          validator={simpleValidator}
          required
        />
      </div>
      <div>
        <p className="form-label">Phone Number *</p>
        <PhoneInput
          key={request.country}
          defaultCountry={request.country.toLowerCase() || "in"}
          value={getValue(request, `phone_no`, "")}
          onChange={(e: any) => {
            setRequest({
              ...request,
              phone_no: e,
            });
          }}
          placeholder={"Enter Phone Number"}
          className=""
        />
        {simpleValidator && (
          <p className={"form-error"}>
            {simpleValidator.current.message(
              "Phone Number",
              getValue(request, `phone_no`, ""),
              "required"
            )}
          </p>
        )}
      </div>
    </div>
  );
};

export default OrganisationModal;
