import React, { useEffect, useState } from "react";
import { getValue } from "@utils/lodash";
import TaskDeatilCompaniesList from "./CompaniesList";
import DescriptionInfo from "../../common/DescInfo";
import ConfirmModal from "@components/Dialogs/Modals/confirmModal";
import TaskPendingLayout from "../../../components/TaskPendingLayout/TaskPendingLayout";
import PendingTaskCommonDescription from "../../common/Description";

export default function TaskCompanies(props: any) {
  const [isActive2, setIsActive2] = useState(false);
  useEffect(() => {
    if (getValue(props, `data.companies.length`, 0) > 0) {
      setIsActive2(true);
    }
  }, [getValue(props, `data.companies`, [])]);
  const [isRollsPopupVisibile, setRollsPopupVisisbility] = useState(false);
  const handleOpenForm = () => {
    props.getForm("companies");
    setRollsPopupVisisbility(true);
  };
  const [isRollsListPopupVisibile, setRollsListPopupVisisbility] =
    useState(false);
  //delete section
  const [deleteId, setDeleteId] = useState("");
  const [deleteValue, setDeleteValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const handleDeleteView = (id: string, value: string) => {
    setDeleteId(id);
    setDeleteValue(value);
    handleModal();
  };
  // const handleSubmit = async () => {
  //    props.handleSubmit(props.create_module);
  // };

  return (
    <>
      <TaskPendingLayout>
        {/* <PendingTaskCommonTitle
          commonTitle="Companies"
          count={
            getValue(props, `data.companies.length`, 0) < 9 &&
            getValue(props, `data.companies.length`, 0) !== 0
              ? `${getValue(props, `data.companies.length`, 0)}`
              : getValue(props, `data.companies.length`, 0)
          }
          isLinkVisible={true}
          handleToggle={() => {
            setIsActive2(!isActive2);
          }}
          triggerAddPopupSlidingFromRight={() => {
            handleOpenForm();
          }}
        /> */}
        <div className="showdiv">
          <PendingTaskCommonDescription description="See the businesses or organizations associated with this record." />
          <div>
            <ul>
              {getValue(props, `data.companies`, []).map(
                (item: object, index: number) => {
                  if (index < 2)
                    return (
                      <DescriptionInfo
                        index={index}
                        item={item}
                        module={"companies"}
                        handleDeleteView={handleDeleteView}
                      />
                    );
                }
              )}
            </ul>
            {getValue(props, `data.companies.length`, 0) > 2 ? (
              <p
                className={"pending-task_more_info_text"}
                onClick={() => setRollsListPopupVisisbility(true)}
              >
                View More Companies
              </p>
            ) : null}
          </div>
        </div>
      </TaskPendingLayout>
      {/* ----------------- Contact Form Popup ------------------ */}

      {/* ----------------- Companies Form List ------------------ */}
      <TaskDeatilCompaniesList
        isRollsListPopupVisibile={isRollsListPopupVisibile}
        setRollsListPopupVisisbility={setRollsListPopupVisisbility}
        companies={getValue(props, `data.companies`, [])}
        handleDeleteView={handleDeleteView}
        module={"companies"}
      />
      <ConfirmModal
        isOpen={isOpen}
        handleModal={handleModal}
        handleSubmit={() => {
          props.handleDisAssociate(deleteId, "companies");
          setTimeout(() => {
            handleModal();
          }, 300);
        }}
        // deleteValue={deleteValue}
      />
    </>
  );
}
