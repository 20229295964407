import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import { Alert } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { Plus } from "lucide-react";
import SettingsPageDetails from "@components/Pages/PageDescription/SettingsPageDetails";
import "./sla-policy.scss";
import { slaPolicyDetails } from "@common/Data/page-details-data";
import {
  deleteSlaPolicy,
  getAllSlaPolicy,
  updateSlaPolicySeqNum,
  updateSlaPolicyStatus,
} from "@services/sla-policy.services";
import ListingHoc from "./CreateSLAPolicy/common/ListingHoc";
import useDynamicTitle from "@context/useDynamicTitle";

const SLAPolicy = ({ props }: any) => {
  useDynamicTitle("SLA Policy");
  const navigate = useNavigate();
  const params = useParams();

  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        icon="sla-policy"
        title="SLA Policies"
        showSubmit
        submitIcon={<Plus size={18} />}
        submitText="New Policy"
        handleSubmit={() =>
          navigate(
            `/${getValue(
              params,
              "orgId",
              ""
            )}/settings/helpdesk/sla-policy/create`
          )
        }
      />

      <div className="sla-policy-wrapper">
        <div className="w-70 p-4 sla-policy-card-container">
          <p className="w-70 small_text__14 pb-4">
            Service Level Agreement(SLA) policies help you setup and maintain
            targets for the duration within which your teams respond and resolve
            tickets.
          </p>
          <Alert
            type="info"
            showIcon
            message="
                The first matching SLA policy will be applied to tickets with
                matching conditions"
            className="w-fit-content mb-4"
          />
          <ListingHoc
            url="sla-policy"
            apiData="sla_policies"
            module="SLA Policy"
            deleteApi={deleteSlaPolicy}
            listApi={getAllSlaPolicy}
            updatePositionApi={updateSlaPolicySeqNum}
            updateSatusApi={updateSlaPolicyStatus}
            detailsData={slaPolicyDetails}
          />
        </div>
        <div className="w-30 p-4 sla-policy-right-details">
          <SettingsPageDetails data={slaPolicyDetails} />
        </div>
      </div>
    </CompanyProfileDetailsLayout>
  );
};

export default SLAPolicy;
