import { useStateContext } from "@context/dataContext";
import { getModuleFields } from "@services/module-fields.service";
import { getValue } from "@utils/lodash";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useDraggableInPortal from "@common/useDraggableInPortal";
import { QueryRequestHelper } from "@common/query-request-helper";
import { allPipelines, getSpecificPipeline } from "@services/pipeline.service";
import {
  removeNullOrUndefinedProperties,
  removeNullValuesFromObjectArray,
} from "@common/text-helpers";
import {
  createWebForm,
  getSpecificWebForm,
  updateWebForm,
} from "@services/web-forms.service";
import { toast } from "sonner";
import FormCreatorEditFied from "../components/form-creator-edit-field";
import ManageFormStep1 from "./step1";
import ManageFormStep2 from "./step2";
import ManageFormStep3 from "./step3";
import PreviewScreen from "./preview";
import SimpleReactValidator from "simple-react-validator";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import { Button, Tooltip } from "antd";
import { EyeOutlined } from "@ant-design/icons";

const devices = [
  {
    width: 46,
    backgroundWidth: 100,
    icon: "/images/icons/desktop.png",
    className: "desktop-device",
    label: "Desktop",
  },
  {
    width: 90,
    backgroundWidth: 50,
    icon: "/images/icons/tablet.png",
    className: "tablet-device",
    label: "Tablet",
  },
  {
    width: 90,
    backgroundWidth: 30,
    icon: "/images/icons/smartphone.png",
    className: "mobile-device",
    label: "Mobile",
  },
];

type AssignmentType =
  | "assign_to_fixed_user"
  | "round_robin_with_agent_availability"
  | "unassign";

function SettingsFormCreatorManage(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const intialState = {
    title: "",
    module_id: "",
    pipeline_id: "",
    pipeline_stage_id: "",
    owner_id: "",
    notify_owner: false,
    thank_you_message: "Thanks for submitting the form.",
    redirect_url: "",
    source_format: "",
    javascript_format: "",
    iframe_format: "",
    has_file_upload: true,
    has_captcha: true,
    has_privacy_policy: true,
    privacy_policy_text: "",
    tags: [""],
    domains: [""],
    route_name: "",
    assignment_type: "unassign" as AssignmentType,
    skip_duplicates: false,
    module_field_id: "",
    update_pipeline_id: "",
    update_pipeline_stage_id: "",
    agents: [],
  };
  const [request, setRequest] = useState(intialState);
  const [assignmentType, setAssignmentType] = useState(
    intialState.assignment_type
  );
  const [agentRequest, setAgentRequest] = useState([]);
  const [visitor_see, setVisitor_see] = useState(false);
  const { selectedModuleId, allModules } = useStateContext();
  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (getValue(params, `id`, "")) {
      getData();
    } else {
      // if (
      //   getValue(request, `module_id`, "") === "" &&
      //   allModules.length > 0 &&
      //   selectedModule === ""
      // ) {
      //   const selectedModule = allModules.filter(
      //     (items: object) => getValue(items, `type`, "") === "PIPELINE"
      //   );
      //   if (selectedModule.length > 0) {
      //     handleSelectModule(selectedModule[0]);
      //   }
      //   getAllFields(selectedModule[0], []);
      // }
      getAllFields(selectedModule, []);
    }
  }, []);

  // useEffect(() => {
  //   if (getValue(request, `module_id`, "") === "" && allModules.length > 0) {
  //     const selectedModule = allModules.filter(
  //       (items: object) => getValue(items, `type`, "") === "PIPELINE"
  //     );

  //     if (selectedModule.length > 0) {
  //       handleSelectModule(selectedModule[0]); // Select the first available pipeline
  //     }
  //   }
  // }, [request.module_id]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedModule, setSelectedModule] = useState("contacts");
  const [selectedFormFields, setSelectedFormFields] = useState([]);

  const getData = async () => {
    try {
      setLoading(true);
      let resp = await getSpecificWebForm(getValue(params, `id`, ""));
      if (resp) {
        setSelectedModule(getValue(resp, `data.module.api_name`, ""));
        setInfo(getValue(resp, `data`, {}));
        formatData(getValue(resp, `data`, {}));
        setSelectedFormFields(getValue(resp, `data.form_fields`, []));
        getAllPipelines(
          getValue(resp, `data.module_id`, ""),
          getValue(resp, `data.pipeline_id`, "")
        );
        // setAssignmentType(getValue(resp, `data.assignment_type`, ""));
        setLoading(false);
        setAgentRequest(getValue(resp, `data.agents`, []));
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const formatData = (data: any) => {
    getAllFields(
      getValue(data, `module.api_name`, ""),
      getValue(data, `form_fields`, [])
    );
    if (activeTab === 2) {
      setRequest({
        ...request,
        title: getValue(data, `title`, ""),
        module_id: getValue(data, `module_id`, ""),
        pipeline_id: getValue(data, `pipeline_id`, ""),
        pipeline_stage_id: getValue(data, `pipeline_stage_id`, ""),
        owner_id: getValue(data, `owner_id`, ""),
        notify_owner: getValue(data, `notify_owner`, false),
        thank_you_message: getValue(data, `thank_you_message`, ""),
        route_name: getValue(data, `route_name`, ""),
        redirect_url: getValue(data, `redirect_url`, ""),
        skip_duplicates: getValue(data, `skip_duplicates`, false),
        module_field_id: getValue(data, `module_field_id`, ""),
        update_pipeline_id: getValue(data, `update_pipeline_id`, ""),
        update_pipeline_stage_id: getValue(
          data,
          `update_pipeline_stage_id`,
          ""
        ),
        agents: getValue(data, `agents`, []),
      });
    } else {
      setRequest({
        ...request,
        title: getValue(data, `title`, ""),
        module_id: getValue(data, `module_id`, ""),
        pipeline_id: getValue(data, `pipeline_id`, ""),
        pipeline_stage_id: getValue(data, `pipeline_stage_id`, ""),
        owner_id: getValue(data, `owner_id`, ""),
        notify_owner: getValue(data, `notify_owner`, false),
        thank_you_message: getValue(data, `thank_you_message`, ""),
        route_name: getValue(data, `route_name`, ""),
        redirect_url: getValue(data, `redirect_url`, ""),
        skip_duplicates: getValue(data, `skip_duplicates`, false),
        module_field_id: getValue(data, `module_field_id`, ""),
        update_pipeline_id: getValue(data, `update_pipeline_id`, ""),
        update_pipeline_stage_id: getValue(
          data,
          `update_pipeline_stage_id`,
          ""
        ),
        agents: getValue(data, `agents`, []),

        assignment_type: getValue(
          data,
          `assignment_type`,
          "assign_to_fixed_user"
        ),
      });
      setAssignmentType(getValue(data, `assignment_type`, ""));
    }

    setVisitor_see(getValue(data, `redirect_url`, "") ? true : false);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [formFields, setFormFields] = useState<any>([]);
  const [droppedFormFields, setDroppedFormFields] = useState<any>([]);
  const [allFormFields, setAllFormFields] = useState<any>([]);
  const getAllFields = async (module: string, selectedFields: any) => {
    let fields: any = selectedFields.map((item: object) =>
      getValue(item, `module_field.id`, "")
    );
    let id = await selectedModuleId(module);
    try {
      setIsLoading(true);
      let resp = await getModuleFields(id);
      if (resp) {
        if (getValue(fields, `length`, 0) === 0) {
          setRequest({
            ...request,
            module_id: id,
          });
        }
        let list =
          getValue(resp, `data.module_fields.length`, 0) > 0
            ? getValue(resp, `data.module_fields`, []).map((item: object) => ({
                ...item,
                display_label: getValue(item, `label`, ""),
                required: getValue(item, `system_required`, false),
              }))
            : [];
        let filtered =
          getValue(fields, `length`, 0) > 0
            ? list.filter(
                (item: object) => !fields.includes(getValue(item, `id`, ""))
              )
            : list;

        let filtered1 =
          getValue(fields, `length`, 0) > 0
            ? list.filter((item: object) =>
                fields.includes(getValue(item, `id`, ""))
              )
            : [];

        let actualDroppedFields = filtered1.map((item: object) => ({
          ...item,
          edit_id: getSelectedFieldInfo(selectedFields, item, "id"),
          required: getSelectedFieldInfo(selectedFields, item, "required"),
          description: getSelectedFieldInfo(
            selectedFields,
            item,
            "description"
          ),
          seq_num: getSelectedFieldInfo(selectedFields, item, "seq_num"),
          display_label: getSelectedFieldInfo(
            selectedFields,
            item,
            "display_label"
          ),
        }));
        setFormFields(filtered);
        setAllFormFields(filtered);
        setDroppedFormFields(actualDroppedFields);
        getAllPipelines(id, "");
        setIsLoading(false);
        getAllTagsList(id);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getSelectedFieldInfo = (
    selectedFields: any,
    item: object,
    key: string
  ) => {
    let data = selectedFields.find(
      (sel: object) =>
        getValue(sel, `module_field.id`, "") === getValue(item, `id`, "")
    );
    return data[key];
  };

  const [allPipelinesList, setAllPipelinesList] = useState([]);
  const [allPipelinesStageList, setAllPipelinesStageList] = useState([]);

  const getAllPipelines = async (id: string, pipeline_id: string) => {
    try {
      setIsLoading(true);
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await allPipelines(id, queryRequest);
      if (resp) {
        let list =
          getValue(resp, `data.length`, 0) > 0
            ? getValue(resp, `data`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `api_name`, ""),
                label: getValue(item, `label`, ""),
              }))
            : [];

        let findStageInfo = getValue(resp, `data`, []).find(
          (item: object) => getValue(item, `id`, "") === pipeline_id
        );
        if (pipeline_id) {
          setAllPipelinesList(list);
          let stageList =
            getValue(findStageInfo, `stages.length`, 0) > 0
              ? getValue(findStageInfo, `stages`, []).map((item: object) => ({
                  ...item,
                  value: getValue(item, `id`, ""),
                  label: getValue(item, `label`, ""),
                }))
              : [];
          setAllPipelinesStageList(stageList);
          setRequest((prev: any) => ({
            ...prev,
            pipeline_id: getValue(resp, "data[0].id", ""),
            pipeline_stage_id: getValue(resp, "data[0].stages[0].id", ""),
          }));
        } else {
          let stageList =
            getValue(resp, `data[${0}].stages.length`, 0) > 0
              ? getValue(resp, `data[${0}].stages`, []).map((item: object) => ({
                  ...item,
                  value: getValue(item, `id`, ""),
                  label: getValue(item, `label`, ""),
                }))
              : [];
          setAllPipelinesList(list);
          setAllPipelinesStageList(stageList);
          setRequest((prev: any) => ({
            ...prev,
            pipeline_id: getValue(resp, "data[0].id", ""),
            pipeline_stage_id: getValue(resp, "data[0].stages[0].id", ""),
          }));
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getUniquePipeline = async (id: string) => {
    let module_id = await selectedModuleId(module);
    try {
      let resp = await getSpecificPipeline(module_id, id);
      if (resp) {
        let stageList =
          getValue(resp, `data.stages.length`, 0) > 0
            ? getValue(resp, `data.stages`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `id`, ""),
                label: getValue(item, `label`, ""),
              }))
            : [];
        setAllPipelinesStageList(stageList);
      }
    } catch (error) {}
  };

  const handleSelectModule = (e: any) => {
    setDroppedFormFields([]);
    setRequest({
      ...request,
      module_id: getValue(e, `id`, ""),
      pipeline_id: "",
      pipeline_stage_id: "",
      update_pipeline_id: "",
      update_pipeline_stage_id: "",
    });
    getAllFields(getValue(e, `api_name`, ""), selectedFormFields);
    getAllPipelines(getValue(e, `id`, ""), "");
  };

  /* ---------------------------- Tags Section  ---------------------------- */

  const [selectedTags, setSelectedTags] = useState<any>([]);
  const [allTags, setAllTags] = useState([]);
  const getAllTagsList = async (id: string) => {
    // try {
    //   let resp = await getAllTags(id);
    //   if (resp) {
    //     let tags = getValue(resp, `data`, []);
    //     setAllTags(
    //       tags.map((item: object) => ({
    //         ...item,
    //         value: getValue(item, `value`, ""),
    //         label: getValue(item, `label`, ""),
    //       }))
    //     );
    //   }
    // } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                          Drag and Drop section                             */
  /* -------------------------------------------------------------------------- */
  const renderDraggable: any = useDraggableInPortal();
  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    border: isDragging && "1px solid #dadce0",
    borderRadius: isDragging && "5px",
    padding: isDragging && "10px",

    // styles we need to apply on draggables
    ...draggableStyle,
  });
  const handleAddField = (id: number) => {
    const selectedField = formFields.find((item: any) => item.id === id);
    if (selectedField) {
      setFormFields((prevFormFields: any) =>
        prevFormFields.filter((item: any) => item.id !== id)
      );
      setAllFormFields((prevFormFields: any) =>
        prevFormFields.filter((item: any) => item.id !== id)
      );
      setDroppedFormFields((prevDroppedFormFields: any) => [
        ...prevDroppedFormFields,
        selectedField,
      ]);
    }
  };
  const handleRemoveField = (id: number) => {
    const removedField = droppedFormFields.find((item: any) => item.id === id);
    if (getValue(removedField, `edit_id`, "")) {
      setDroppedFormFields((options: any) => {
        return options.map((x: object, index: number) => {
          if (getValue(x, `id`, "") !== id) return x;
          return {
            ...x,
            action: "delete",
          };
        });
      });

      setFormFields((prevFormFields: any) => [...prevFormFields, removedField]);
      setAllFormFields((prevFormFields: any) => [
        ...prevFormFields,
        removedField,
      ]);
    } else {
      setDroppedFormFields((prevDroppedFormFields: any) =>
        prevDroppedFormFields.filter((item: any) => item.id !== id)
      );
      setFormFields((prevFormFields: any) => [...prevFormFields, removedField]);
      setAllFormFields((prevFormFields: any) => [
        ...prevFormFields,
        removedField,
      ]);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                              Onchange section                              */
  /* -------------------------------------------------------------------------- */
  const [activeTab, setActiveTab] = useState(1);
  const handleSave = (tab: number) => {
    if (tab > 0 && tab < 5) {
      if (tab < 2) {
        setActiveTab(tab);
      }
      if (tab === 2) {
        handleSubmit();

        if (!getValue(request, `route_name`, "")) {
          setRequest({
            ...request,
            route_name: getValue(request, `title`, "")
              .toLowerCase()
              .replace(/ /g, "-"),
          });
        }
      }
      if (tab === 3) {
        handleSubmit();
      }
      if (tab === 4) {
        navigate(`/${getValue(params, `orgId`, "")}/settings/crm/forms`);
      }
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                            Search Section                                  */
  /* -------------------------------------------------------------------------- */
  const [searchText, setSearchText] = useState("");
  const handleSearch = (e: any) => {
    const text = e.target.value;
    setSearchText(text);
    const search = text.toLowerCase();
    if (text) {
      const searchedData = allFormFields.filter((item: object) =>
        getValue(item, `label`, "").toLowerCase().includes(search)
      );
      setFormFields(searchedData);
    } else {
      setFormFields(allFormFields);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                            Submit Section                                  */
  /* -------------------------------------------------------------------------- */
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      let fields = droppedFormFields.map((item: object, index: number) => ({
        id: getValue(item, `edit_id`, ""),
        action: getValue(item, `action`, "")
          ? getValue(item, `action`, "")
          : getValue(item, `edit_id`, "")
          ? "update"
          : "add",
        field_id: getValue(item, `id`, ""),
        display_label: getValue(item, `display_label`, ""),
        seq_num: index + 1,
        required: getValue(item, `required`, false)
          ? getValue(item, `required`, false)
          : getValue(item, `system_required`, false),
        description: getValue(item, `description`, ""),
      }));
      let allFields = removeDuplicates(fields);
      let payload = {
        title: getValue(request, `title`, ""),
        module_id: getValue(request, `module_id`, ""),
        pipeline_id: getValue(request, `pipeline_id`, ""),
        pipeline_stage_id: getValue(request, `pipeline_stage_id`, ""),
        owner_id: getValue(request, `owner_id`, ""),
        notify_owner: getValue(request, `notify_owner`, false),
        thank_you_message: getValue(request, `thank_you_message`, ""),
        route_name: getValue(request, `route_name`, ""),
        redirect_url: getValue(request, `redirect_url`, ""),
        fields: removeNullValuesFromObjectArray(allFields),
        assignment_type: assignmentType,
        skip_duplicates: getValue(request, `skip_duplicates`, false),
        module_field_id: getValue(request, `module_field_id`, ""),
        update_pipeline_id: getValue(request, `update_pipeline_id`, ""),
        update_pipeline_stage_id: getValue(
          request,
          `update_pipeline_stage_id`,
          ""
        ),
      };
      try {
        setSubmitLoading(true);
        let resp;
        if (getValue(params, `id`, "")) {
          resp = await updateWebForm(
            getValue(params, `id`, ""),
            removeNullOrUndefinedProperties(payload)
          );
        } else {
          resp = await createWebForm(removeNullOrUndefinedProperties(payload));
        }
        if (resp) {
          toast.success(
            `${getValue(params, `id`, "") ? "Updated" : "Created"} successfully`
          );

          setInfo(getValue(resp, `data`, {}));
          setAssignmentType(getValue(resp, `data.assignment_type`, ""));
          setSubmitLoading(false);
          simpleValidator.current.hideMessages();
          simpleValidator.current.fields = {};
          forceUpdate(0);
          if (activeTab + 1 === 2) {
            navigate(
              `/${getValue(
                params,
                `orgId`,
                ""
              )}/settings/crm/forms/edit/${getValue(resp, `data.id`, "")}`
            );
          }
          setActiveTab(activeTab + 1);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  const removeDuplicates = (data: any) => {
    const lastOccurrences: any = {};
    for (let obj of data) {
      lastOccurrences[obj.display_label] = obj;
    }
    const filteredData = Object.values(lastOccurrences);
    return filteredData;
  };
  const handleDone = () => {
    navigate(`/${getValue(params, `orgId`, "")}/settings/crm/forms`);
  };

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;

    const { source, destination } = result;
    const sourceArrayCopy: any = [...droppedFormFields];

    // Moving from same area in top to botomm direction

    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === "source") {
        const [removed] = sourceArrayCopy.splice(source.index, 1);
        sourceArrayCopy.splice(destination.index, 0, removed);
        setDroppedFormFields(
          sourceArrayCopy.map((item: object, index: number) => ({
            ...item,
            seq_num: index + 1,
          }))
        );
      }
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                        Edit Field Section                                  */
  /* -------------------------------------------------------------------------- */
  const [fieldInfo, setFieldInfo] = useState({});
  const [editFieldId, setEditFieldId] = useState("");
  const handleEditField = (item: object) => {
    setEditFieldId(getValue(item, `id`, ""));
    setFieldInfo(item);
    handleModal();
  };
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const resetEdit = () => {
    setFieldInfo({});
    setEditFieldId("");
  };

  const getFields = () => {
    let fields =
      getValue(droppedFormFields, `length`, 0) > 0
        ? droppedFormFields.filter(
            (item: object) => getValue(item, `action`, "") !== "delete"
          )
        : [];
    return getValue(fields, `length`, 0);
  };

  const fieldCount = getValue(droppedFormFields, `length`, 0);

  const [width, setWidth] = useState(46);
  const [backgroundWidth, setBackgroundWidth] = useState(100);

  const handleDeviceChange = (newWidth: number, newBackgroundWidth: number) => {
    setWidth(newWidth);
    setBackgroundWidth(newBackgroundWidth);
  };

  console.log("request", request);
  console.log("activeTab", activeTab);
  console.log("simpleValidator", simpleValidator.current);
  return (
    <>
      <CompanyProfileDetailsLayout {...props}>
        <CompanyProfileSubHeader
          settings
          title={activeTab === 0 ? "Preview" : "Forms"}
          backButton
          showCancel={activeTab > 1}
          cancelText="Previous"
          handleCancel={() => setActiveTab(activeTab - 1)}
          showSubmit={activeTab !== 0}
          submitText={
            activeTab === 1
              ? `Save & Next`
              : activeTab === 2
              ? !getValue(params, `id`, "")
                ? "Save"
                : "Update"
              : "Done"
          }
          titleLoading={isLoading}
          isLoading={isLoading}
          disabled={
            activeTab === 1 &&
            (getFields() <= 0 ||
              submitLoading ||
              !getValue(request, `title`, ""))
          }
          handleSubmit={() => handleSave(activeTab + 1)}
          handleBack={() =>
            activeTab === 0
              ? setActiveTab(1)
              : activeTab === 2 && !getValue(params, "id", "")
              ? setActiveTab(activeTab - 1)
              : navigate(`/${getValue(params, "orgId", "")}/settings/crm/forms`)
          }
          addChildren={
            getFields() > 0 &&
            activeTab !== 0 && (
              <Button
                size="large"
                onClick={() => setActiveTab(0)}
                icon={<EyeOutlined />}
              >
                Preview
              </Button>
            )
          }
          centeredContent={
            activeTab === 0 && (
              <div className="d-flex gap-3 align-items-center">
                {devices.map((device, index) => (
                  <Tooltip title={getValue(device, "label", "")}>
                    <button
                      key={index}
                      onClick={() =>
                        handleDeviceChange(device.width, device.backgroundWidth)
                      }
                      className={`responsive-devices`}
                    >
                      <img src={device.icon} alt={device.label} />
                    </button>
                  </Tooltip>
                ))}
              </div>
            )
          }
        />
        {activeTab === 0 && (
          <PreviewScreen
            formFields={droppedFormFields}
            setFormFields={setDroppedFormFields}
            setActiveTab={setActiveTab}
            width={width}
            backgroundWidth={backgroundWidth}
            handleDeviceChange={handleDeviceChange}
          />
        )}
        {/* {activeTab !== 0 && (
          <FormCreatorHeader
            route={`/${getValue(params, `orgId`, "")}/settings/crm/forms`}
            showSubmit
            handleSubmit={handleSave}
            handleDone={handleDone}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            isLoading={submitLoading}
            showPreview={getFields() > 0 ? true : false}
            fieldCount={fieldCount}
          />
        )} */}
        <div className="create_form_container">
          {activeTab === 1 && (
            <ManageFormStep1
              allModules={allModules}
              request={request}
              setRequest={setRequest}
              handleSelectModule={handleSelectModule}
              searchText={searchText}
              handleSearch={handleSearch}
              isLoading={isLoading}
              formFields={formFields}
              handleAddField={handleAddField}
              handleOnDragEnd={handleOnDragEnd}
              droppedFormFields={droppedFormFields}
              renderDraggable={renderDraggable}
              getItemStyle={getItemStyle}
              handleRemoveField={handleRemoveField}
              handleEditField={handleEditField}
            />
          )}
          {activeTab === 2 && (
            <ManageFormStep2
              params={params}
              setRequest={setRequest}
              request={request}
              allPipelinesList={allPipelinesList}
              allPipelinesStageList={allPipelinesStageList}
              visitor_see={visitor_see}
              setVisitor_see={setVisitor_see}
              info={info}
              validator={simpleValidator}
              getUniquePipeline={getUniquePipeline}
              getData={getData}
              assignmentTypeRequest={assignmentType}
              setAssignmentTypeRequest={setAssignmentType}
              agentRequest={agentRequest}
              droppedFormFields={droppedFormFields}
            />
          )}
          {activeTab === 3 && (
            <ManageFormStep3
              params={params}
              request={request}
              droppedFormFields={droppedFormFields}
              info={info}
            />
          )}

          <FormCreatorEditFied
            isOpen={isOpen}
            handleModal={handleModal}
            fieldInfo={fieldInfo}
            resetEdit={resetEdit}
            editFieldId={editFieldId}
            droppedFormFields={droppedFormFields}
            setDroppedFormFields={setDroppedFormFields}
          />
        </div>
      </CompanyProfileDetailsLayout>
    </>
  );
}

export default SettingsFormCreatorManage;
