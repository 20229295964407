import { useEffect, useRef, useState } from "react";
import "./profile.scss";
import {
  getSpecificProfile,
  initiateEmailVerification,
  updateProfile,
  updateProfileEmailVerify,
} from "@services/organisations.service";
import { getValue } from "@utils/lodash";
import { getFirstLetterOfWord } from "@common/text-helpers";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { handleSelectChange } from "@common/handleChange";
import timezones from "@common/json/standardized-timezone-data.json";
import countries from "@common/json/country.flag.json";
import ButtonComponent from "@components/Form/Button/Button";
import { toast } from "sonner";
import ProfileNavViews from "./components/nav-views";
import DialcodePhoneInputCommon from "@components/custom/Forms/DialCodeInputCommon";
import { useStateContext } from "@context/dataContext";
import SidebarLayout from "@layouts/SidebarLayout/SidebarLayout";
import { Check, Moon, Pencil, Sun } from "lucide-react";
import { Button } from "antd";
import EmailVerifyModal from "./components/EmailVerifyModal";
import SimpleReactValidator from "simple-react-validator";

function ProfilePage(props: any) {
  const { getUserInfoCallback } = useStateContext();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [request, setRequest] = useState<any>({
    country: "",
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    timezone: "",
    theme: "",
    app_mode: "light",
  });
  const [countriesList, setCountriesList] = useState([]);

  useEffect(() => {
    getOrganizationUser();
    let list: any = countries.map((item: object) => ({
      ...item,
      label: getValue(item, `name`, ""),
      value: getValue(item, `code`, ""),
    }));
    setCountriesList(list);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    window.location.href = `/signin`;
  };

  const getOrganizationUser = async () => {
    let resp = await getSpecificProfile();
    if (resp) {
      setRequest({
        ...request,
        country: getValue(resp, `data.country`, ""),
        email: getValue(resp, `data.email`, ""),
        first_name: getValue(resp, `data.first_name`, ""),
        last_name: getValue(resp, `data.last_name`, ""),
        phone: getValue(resp, `data.phone`, ""),
        timezone: getValue(resp, `data.timezone`, ""),
        theme: getValue(resp, `data.theme`, ""),
        app_mode: getValue(resp, `data.app_mode`, ""),
      });
    }
  };
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      let resp = await updateProfile(request);
      if (resp) {
        localStorage.setItem("timezone", getValue(request, `timezone`, ""));
        toast.success("Updated successfully");
        getOrganizationUser();
        getUserInfoCallback();
        setSubmitLoading(false);
        // window.location.reload();
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [isInitiateVerification, setIsInitiateVerification] = useState(false);
  const [isVerifyEmail, setIsVerifyEmail] = useState(false);
  const [otp, setOtp] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [currentStep, setCurrentStep] = useState(1);

  const handleInitiateEmailVerification = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setIsEditingEmail(true);
      try {
        setIsInitiateVerification(true);
        let resp = await initiateEmailVerification({
          email: newEmail,
        });
        if (resp) {
          setVerificationCode(getValue(resp, `data.verification_id`, ""));
          setIsInitiateVerification(false);
          setCurrentStep(2);
          forceUpdate(0);
          simpleValidator.current.hideMessages();
        }
      } catch (error) {
        console.log(error);
        setIsInitiateVerification(false);
      }
    }
  };

  const handleSubmitOtp = async () => {
    try {
      setIsVerifyEmail(true);
      let resp = await updateProfileEmailVerify({
        code: otp,
        verification_id: verificationCode,
      });
      if (resp) {
        toast.success("Email updated successfully, please login again");
        setIsEditingEmail(false);
        setIsVerifyEmail(false);
        setTimeout(() => {
          handleLogout();
        }, 1500);
      }
    } catch (error) {
      console.log(error);
      setIsVerifyEmail(false);
    }
  };

  const handleCancel = () => {
    setIsEditingEmail(false);
    setIsVerifyEmail(false);
    setCurrentStep(1);
    setOtp("");
    setNewEmail("");
    simpleValidator.current.hideMessages();
    forceUpdate(0);
  };

  const themeColors: any = [
    { label: "Theme Purple", value: "#9e21ff" },
    { label: "Theme Green", value: "#3fd1a9" },
    { label: "Theme Red", value: "#E74C3C" },
    { label: "Theme Blue", value: "#276ef2" },
    { label: "Theme Orange", value: "#fd7445" },
  ];

  const appModes = [
    {
      label: "Light",
      value: "light",
      icon: <Sun size={18} />,
    },
    {
      label: "Dark",
      value: "dark",
      icon: <Moon size={18} />,
    },
  ];
  return (
    <SidebarLayout>
      <ProfileNavViews />
      <div className="d-flex justify-content-center align-items-center mt-3">
        <div className="profile_circle_section text-uppercase d-flex justify-content-center align-items-center">
          <h6>
            {getFirstLetterOfWord(getValue(request, `first_name`, ""))}
            {getFirstLetterOfWord(getValue(request, `last_name`, ""))}
          </h6>
        </div>
      </div>
      <div className="mt-3 px-5 profile-form-container">
        <div className="d-flex align-items-center gap">
          <div className="w-50">
            <InputRuleForm
              inputType="TEXT"
              label="Fist Name"
              value={getValue(request, `first_name`, "")}
              name="first_name"
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  first_name: e.target.value,
                })
              }
            />
          </div>
          <div className="w-50">
            <InputRuleForm
              inputType="TEXT"
              label="Last Name"
              value={getValue(request, `last_name`, "")}
              name="last_name"
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  last_name: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="d-flex align-items-center gap">
          <div className="w-50 d-flex align-items-center gap-2">
            <div className="w-100">
              <InputRuleForm
                inputType="TEXT"
                label="Email"
                value={getValue(request, `email`, "")}
                name="email"
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    email: e.target.value,
                  })
                }
                disabled
              />
            </div>
            <Button
              shape="circle"
              color="default"
              type="text"
              icon={<Pencil size={18} />}
              onClick={() => setIsEditingEmail(true)}
              className="mt-4"
              // disabled
            />
          </div>
          <div className="w-50 mt-3">
            {/* <InputRuleForm
              inputType="TEXT"
              label="Phone Number"
              value={getValue(request, `phone`, "")}
              name="phone"
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  phone: handleChangeNumber(e),
                })
              }
            /> */}
            <DialcodePhoneInputCommon
              placeholder="Enter phone number"
              value={getValue(request, `phone`, "")}
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  phone: e,
                })
              }
              label={"Phone Number"}
              removeValidation
            />
          </div>
        </div>
        <div className="d-flex align-items-center gap">
          <div className="w-50">
            <SearchToolTip
              inputType="TEXT"
              label="label"
              name={"Country"}
              value={getValue(request, `country`, "")}
              onChange={(e: object) =>
                handleSelectChange(e, "country", request, setRequest)
              }
              selectKey={"value"}
              data={countriesList}
            />
          </div>
          <div className="w-50">
            <SearchToolTip
              placeholder={`Enter Time Zone`}
              onChange={(e: object) =>
                handleSelectChange(e, "timezone", request, setRequest)
              }
              label={`label`}
              //   width={"365px"}
              data={timezones}
              value={getValue(request, `timezone`, "")}
              selectKey={"value"}
              name={"Time Zone"}
              concatLength={100}
            />
          </div>
        </div>
        <div className="d-flex align-items-center gap-3 mt-3">
          <div className="w-50">
            <label className="form-label">Theme</label>
            <div className="d-flex align-items-center gap-2">
              {themeColors.map((item: any) => (
                <div
                  key={item.value}
                  className={`theme-color-box ${
                    getValue(request, `theme`, "") ===
                    getValue(item, `value`, "")
                      ? "theme-color-box-selected"
                      : ""
                  }`}
                  style={{ backgroundColor: item.value }}
                  onClick={() =>
                    setRequest({
                      ...request,
                      theme: getValue(item, `value`, ""),
                    })
                  }
                >
                  {getValue(request, `theme`, "") ===
                    getValue(item, `value`, "") && (
                    <Check size={16} className="text-white" />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="w-50">
            {/* <label className="form-label">App Mode</label>
            <Segmented
              options={appModes}
              onChange={(value: any) =>
                setRequest({ ...request, app_mode: value })
              }
              value={getValue(request, `app_mode`, "")}
              size="middle"
            /> */}
          </div>
        </div>
        <div className="mt-3 d-flex justify-content-end">
          <ButtonComponent
            buttonType={submitLoading ? "scecondary" : "primary"}
            buttonText={"Update"}
            onClickHandler={() => handleSubmit()}
            disabled={submitLoading}
          />
        </div>
      </div>
      <EmailVerifyModal
        open={isEditingEmail}
        onCancel={() => handleCancel()}
        isInitiateVerification={isInitiateVerification}
        isVerifyEmail={isVerifyEmail}
        setOtp={setOtp}
        handleSubmitOtp={handleSubmitOtp}
        currentStep={currentStep}
        newEmail={newEmail}
        setNewEmail={setNewEmail}
        handleInitiateEmailVerification={handleInitiateEmailVerification}
        validator={simpleValidator}
        currentEmail={getValue(request, `email`, "")}
      />
    </SidebarLayout>
  );
}

export default ProfilePage;
