import { PlusOutlined } from "@ant-design/icons";
import AddSvgComponent from "@assets/svg/add";
import CloseSvgComponent from "@assets/svg/close";
import { formatString } from "@common/text-helpers";
import ButtonComponent from "@components/Form/Button/Button";
import { getValue } from "@utils/lodash";
import { Button } from "antd";

function AdvancedFilterViewStep(props: any) {
  const {
    filter_groups,
    setActiveTab,
    setFilterGroupIndex,
    request,
    setRequest,
    setFilterIndex,
    handleRemoveAND,
    handleRemoveOR,
    handleCloneOR,
    setIsEdit,
    handleResetSelectedOptions,
    smartFilterFields,
    setSelectedFilterOption,
  } = props;

  const getFieldInfo = (name: string) => {
    let info =
      getValue(smartFilterFields, `length`, 0) > 0
        ? smartFilterFields.find(
            (item: object) => getValue(item, `api_name`, "") === name
          )
        : {};
    return info;
  };

  return (
    <div className="advance_filter_main_container ">
      <div className="advance_filter_container mb-5 pb-5">
        <h6 className="header_text__18 mt-3">
          Advanced filters ({getValue(filter_groups, `length`, 0)})
        </h6>
        {getValue(filter_groups, `length`, 0) === 0 ? (
          <>
            <p className="text-center mt-4 small_text__14">
              This view has no advanced filters.
            </p>
            <div className="d-flex justify-content-center mt-4">
              <Button
                size="large"
                type={"primary"}
                onClick={() => setActiveTab(1)}
                icon={<PlusOutlined />}
              >
                Add Filter
              </Button>
            </div>
          </>
        ) : (
          <div className="mt-3">
            {filter_groups.map((item: object, index: number) => {
              return (
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div></div>
                    <div className="d-flex align-items-center">
                      <h6
                        className="import_header_text mb-2"
                        onClick={() => {
                          handleCloneOR(item);
                          handleResetSelectedOptions();
                        }}
                      >
                        Clone
                      </h6>

                      <h6
                        className="import_header_text mb-2 ms-2"
                        onClick={() => handleRemoveOR(index)}
                      >
                        Delete
                      </h6>
                    </div>
                  </div>
                  <div className="border border-radius__5 p-2">
                    {getValue(item, `filters`, []).map(
                      (fil: any, fil_index: number) => {
                        return (
                          <>
                            <div className="cursor-pointer border border-radius__5  p-2 mb-2 d-flex align-items-center justify-content-between">
                              <div className="d-flex align-items-center">
                                <h6
                                  className="import_header_text color_primary"
                                  onClick={() => {
                                    setIsEdit(true);
                                    setRequest({
                                      ...request,
                                      api_name: getValue(fil, `api_name`, ""),
                                      display_label: getValue(
                                        fil,
                                        `display_label`,
                                        ""
                                      ),
                                      input_value_label: getValue(
                                        fil,
                                        `input_value_label`,
                                        ""
                                      ),
                                      input_type: getValue(
                                        fil,
                                        `input_type`,
                                        ""
                                      ),
                                      operator: getValue(fil, `operator`, ""),
                                      input_value: getValue(
                                        fil,
                                        `input_value`,
                                        ""
                                      ),
                                      input_value_1: getValue(
                                        fil,
                                        `input_value_1`,
                                        ""
                                      ),
                                      timezone: getValue(fil, `timezone`, ""),
                                    });
                                    setFilterGroupIndex(fil_index);
                                    setFilterIndex(index);
                                    setActiveTab(2);
                                    setSelectedFilterOption(
                                      getFieldInfo(
                                        getValue(fil, `api_name`, "")
                                      )
                                    );
                                  }}
                                >
                                  {getValue(fil, `display_label`, "")}
                                </h6>
                                <p className="small_text__14 ms-3">
                                  {formatString(getValue(fil, `operator`, ""))}
                                </p>
                                <h6 className="header_text__14 ms-3">
                                  {getValue(fil, `input_value_label`, "")
                                    ? getValue(fil, `input_value_label`, "")
                                    : getValue(fil, `input_value`, "")}
                                </h6>

                                {getValue(fil, `input_value_label_1`, "") &&
                                  getValue(fil, `operator`, "") ===
                                    "BETWEEN" && (
                                    <>
                                      <p className="small_text_14">and</p>
                                      <h6 className="header_text__14 ms-3">
                                        {getValue(
                                          fil,
                                          `input_value_label_1`,
                                          ""
                                        )
                                          ? getValue(
                                              fil,
                                              `input_value_label_1`,
                                              ""
                                            )
                                          : getValue(fil, `input_value`, "")}
                                      </h6>
                                    </>
                                  )}
                              </div>
                              <div className="d-flex align-items-center notes_action_container">
                                {/* <div className=" image_hover_background">
                                  <EditSvgComponent color={"#408dfb"} />
                                </div> */}
                                <div
                                  className="image_hover_background"
                                  onClick={() =>
                                    handleRemoveAND(index, fil_index)
                                  }
                                >
                                  <CloseSvgComponent size={10} />
                                </div>
                              </div>
                            </div>
                            {fil_index ===
                            getValue(item, `filters.length`, 0) - 1 ? (
                              <div
                                className="condition_view p-2 mt-2 border-radius__5 mb-1"
                                onClick={() => {
                                  setRequest({
                                    ...request,
                                    api_name: "",
                                    display_label: "",
                                    input_value_label: "",
                                    input_type: "text",
                                    operator: "EQUAL_TO",
                                    input_value: "",
                                    input_value_1: "",
                                    custom: false,
                                    timezone: "",
                                  });
                                  setFilterGroupIndex(fil_index);
                                  setFilterIndex(index);
                                  setActiveTab(1);
                                }}
                              >
                                AND
                              </div>
                            ) : (
                              <p className="small_text__14 mt-2 mb-2 ms-2">
                                and
                              </p>
                            )}
                          </>
                        );
                      }
                    )}
                  </div>
                  {index === getValue(filter_groups, `length`, 0) - 1 ? (
                    <div
                      className="condition_view p-2 mt-2 border-radius__5"
                      onClick={() => {
                        handleResetSelectedOptions();
                        setFilterIndex(
                          getValue(filter_groups, `length`, 0) - 1
                        );
                        setActiveTab(1);
                      }}
                    >
                      OR
                    </div>
                  ) : (
                    <h6 className="header_text__14 mt-2 mb-2 ms-2">OR</h6>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default AdvancedFilterViewStep;
