import AntSearchToolTip from "@components/custom/DebounceSelect";
import {
  whatsappManagerAttachAgent,
  whatsappManagerDettachAgent,
} from "@services/fb.service";
import { getValue } from "@utils/lodash";
import { List, Button } from "antd";

function SettingsPopupAgent(props: any) {
  const { agentsList, getFbConfig, request } = props;

  const attachAgents = async (id: any) => {
    try {
      const resp = await whatsappManagerAttachAgent(
        getValue(request, `waAccountId`, ""),
        {
          agents: [
            {
              id: id,
            },
          ],
        }
      );
      if (resp) {
        getFbConfig();
      }
    } catch (error) {}
  };

  const detachAgents = async (id: string) => {
    try {
      const resp = await whatsappManagerDettachAgent(
        getValue(request, `waAccountId`, ""),
        {
          agents: [
            {
              id: id,
            },
          ],
        }
      );
      if (resp) {
        getFbConfig();
      }
    } catch (error) {}
  };

  return (
    <>
      <AntSearchToolTip
        label="Select Team"
        lookup_api={"org_users"}
        selectKey={"id"}
        value={getValue(request, `agentId`, "")}
        onChange={(id: string) => {
          attachAgents(id);
        }}
      />
      <List
        className="agents-list"
        itemLayout="horizontal"
        dataSource={agentsList}
        renderItem={(item: any) => (
          <List.Item
            actions={[
              <Button
                key="detach"
                type="link"
                onClick={() => detachAgents(getValue(item, "agent.id", ""))}
              >
                Detach
              </Button>,
            ]}
          >
            <List.Item.Meta
              title={`${getValue(item, `agent.first_name`, "")}${" "}${getValue(
                item,
                `agent.last_name`,
                ""
              )}`}
              description={
                <span>{getValue(item, `agent.user.email`, "")}</span>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
}

export default SettingsPopupAgent;
