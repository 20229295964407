import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Input, Modal } from "antd";

const MediaLibraryUrlModal = ({
  editId,
  request,
  setRequest,
  validator,
  isUrlModalOpen,
  handleUrlOk,
  handleUrlCancel,
  isLoading,
}: {
  editId: string | null;
  request: any;
  setRequest: any;
  validator: any;
  isUrlModalOpen: boolean;
  handleUrlOk: () => void;
  handleUrlCancel: () => void;
  isLoading: boolean;
}) => {
  return (
    <Modal
      title={`${editId ? "Edit" : "Add"} URL`}
      open={isUrlModalOpen}
      onOk={handleUrlOk}
      onCancel={handleUrlCancel}
      okText={editId ? "Update" : "Save"}
      okButtonProps={{ loading: isLoading }}
    >
      <InputRuleForm
        inputType="TEXT"
        name="name"
        value={getValue(request, "name", "")}
        placeholder="Enter Name"
        required
        label="Name"
        onChange={(e: any) =>
          setRequest({
            ...request,
            name: e.target.value,
          })
        }
        validator={validator}
        validLeft
        onKeyDown={(e: any) => {
          if (e.key === "Enter") {
            handleUrlOk();
          }
        }}
      />
      {/* <div>
        <label className="form-label">
          URL <span className=" small_text__16 text-danger">*</span>
        </label>
        <Input
          addonBefore="https://"
          defaultValue="mysite"
          size="large"
          value={getValue(request, "url", "")}
          onChange={(e: any) =>
            setRequest({
              ...request,
              url: e.target.value,
            })
          }
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              handleUrlOk();
            }
          }}
        />
        {validator &&
          validator.current.message(
            "url",
            getValue(request, "url", ""),
            "required"
          ) && (
            <p className={`error-text`}>
              {validator.current.message(
                "url",
                getValue(request, "url", ""),
                "required"
              )}
            </p>
          )}
      </div> */}
      <div className="my-3">
        <InputRuleForm
          inputType="TEXT"
          name="url"
          value={getValue(request, "url", "")}
          placeholder="https://www.example.com"
          required
          label="URL"
          onChange={(e: any) =>
            setRequest({
              ...request,
              url: e.target.value,
            })
          }
          validator={validator}
          validLeft
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              handleUrlOk();
            }
          }}
        />
      </div>
    </Modal>
  );
};

export default MediaLibraryUrlModal;
