import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackSvgComponent from "@assets/svg/back-link";
import ButtonComponent from "@components/Form/Button/Button";
import {
  getAllPropertyImage,
  removePropertyImage,
  updatePropertyImage,
} from "@services/properties/property.service";
import { toast } from "sonner";
import DragAndDropFileComponent from "@components/custom/FileUpload/file-drag-drop";
import { mediaUpload } from "@services/upload.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { config } from "../../../../../env";
import DeleteSvgComponent from "@assets/svg/delete";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import SidebarLayout from "@layouts/SidebarLayout/SidebarLayout";

const PropertyImages = (props: any) => {
  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    if (getValue(params, `id`, "")) {
      getData();
    }
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */
  const [images, setImages] = useState<any>([]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getData = async () => {
    try {
      let payload = {
        property_id: getValue(params, `id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllPropertyImage(queryRequest);
      if (resp) {
        setImages(getValue(resp, `data.property_images`, []));
      }
    } catch (error) {}
  };
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    navigate(
      `/${getValue(params, `orgId`, "")}/property/amenities/${getValue(
        params,
        `id`,
        ""
      )}`
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                            Upload Section                                  */
  /* -------------------------------------------------------------------------- */
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadedFileInfo, setUploadedFileInfo] = useState({});
  const handleUploadDocuments = async (e: any) => {
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      formData.append("filename", item.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");
      try {
        setUploadLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          setUploadedFileInfo(getValue(resp, `data`, {}));
          let response = await updatePropertyImage(getValue(params, `id`, ""), {
            images: [
              {
                file_upload_id: getValue(resp, `data.id`, ""),
                order: getValue(images, `length`, 0) + 1,
              },
            ],
          });
          if (response) {
            getData();
          }
          e = null;
          setUploadLoading(false);
        } else {
          e = null;
          setUploadLoading(false);
        }
      } catch (error) {
        e = null;
        setUploadLoading(false);
      }
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                              Delete Section                                */
  /* -------------------------------------------------------------------------- */
  const [deleteId, setDeleteId] = useState("");
  const [deleteValue, setDeleteValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleDeleteView = (id: string) => {
    setDeleteId(id);
    handleModal();
  };
  const handleDeleteNote = async () => {
    try {
      let resp = await removePropertyImage(deleteId);
      if (resp) {
        toast.success("Deleted Successfully");
        handleModal();
        getData();
        setDeleteId("");
        // props.getData(false);
      }
    } catch (error) {}
  };

  return (
    <SidebarLayout props={props}>
      <div className={"company-profile-subheader-wrapper"}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center">
            <div
              onClick={() =>
                navigate(
                  `/${getValue(
                    params,
                    "orgId",
                    ""
                  )}/property/address/${getValue(params, "id", "")}`
                )
              }
            >
              <BackSvgComponent />
            </div>
            <p className="small_text__16 ms-3">{"Property images"}</p>
          </div>
          <div className="d-flex align-items-center">
            <ButtonComponent
              buttonType="primary"
              buttonText={"Next"}
              onClickHandler={() => handleSubmit()}
              disabled={submitLoading || getValue(images, `length`, 0) < 1}
            />
          </div>
        </div>
      </div>
      <div className={`w-100 grid-container   add-overflow-y`}>
        <div className="w-100 px-5 py-5 add-overflow-y manageFieldRight">
          <h6 className="small_text__22 mb-1 text-center">
            Add photos to enrich user experience!{" "}
          </h6>
          <p className="text-center small_text__14">
            Publish at least 1 images to get your property more appealing. You
            can edit them later in dashboard.
          </p>
          <div className="d-flex justify-content-center mt-3">
            <div className="w-60">
              <DragAndDropFileComponent
                uploadFile={handleUploadDocuments}
                name="file"
                classes="bulk-upload_drag-n-drop-wrapper position-absolute "
                isLoading={uploadLoading}
                supportedFormats=".jpg, .png, .jpeg"
              />
            </div>
          </div>
        </div>
        <div className="w-100 add-overflow-y manageFieldRight p-5">
          <h6 className="small_text__22 mb-1 text-center">Make it stand out</h6>
          {getValue(images, `length`, 0) > 0 ? (
            <div className="property_image_container w-100">
              {images.map((item: any) => {
                return (
                  <div
                    className="position-relative"
                    key={getValue(item, `id`, "")}
                  >
                    <div
                      className="property_delete_icon"
                      onClick={() => handleDeleteView(getValue(item, `id`, ""))}
                    >
                      <DeleteSvgComponent />
                    </div>
                    <img
                      src={
                        getValue(item, `url`, "") ||
                        `https://${config.ASSETS_CDN_ENDPOINT}/${getValue(
                          item,
                          `file_upload.key`,
                          ""
                        )}`
                      }
                      className="property_image mb-3"
                      alt="Property"
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <p className="text-center small_text__14">
              All images added will be shown here
            </p>
          )}
        </div>
      </div>
      <DeleteModal
        isOpen={isOpen}
        handleModal={handleModal}
        handleSubmit={handleDeleteNote}
        deleteValue={deleteValue}
      />
    </SidebarLayout>
  );
};

export default PropertyImages;
