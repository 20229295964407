import { useEffect, useRef, useState } from "react";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import {
  changeWorkflowStatus,
  getAllWorkflows,
} from "@services/workflow.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getValue } from "@utils/lodash";
import { allPipelines } from "@services/pipeline.service";
import { listAllModules } from "@services/modules.service";
import WorkflowInfoPopup from "./components/info-popup";
import SimpleReactValidator from "simple-react-validator";
import { useNavigate, useParams } from "react-router-dom";
import WorkflowTable from "./components/table";
import { useStateContext } from "@context/dataContext";
import { sortJSONObjectArray } from "@common/text-helpers";
import WorkflowssNoDataPage from "@components/common/NoData/workflows-nodata";
import useDynamicTitle from "@context/useDynamicTitle";
import ListLoader from "@components/common/Loader";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import { Plus } from "lucide-react";

function SettingsWorkflows(props: any) {
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const { selectedModuleId } = useStateContext();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [request, setRequest] = useState({
    moduleName: "",
    moduleId: "",
    pipelineId: "",
    name: "",
    description: "",
  });

  const resetRequest = () => {
    setRequest({
      ...request,
      moduleName: "",
      moduleId: "",
      pipelineId: "",
      name: "",
      description: "",
    });
    simpleValidator.current.hideMessages();
    simpleValidator.current.fields = {};
    simpleValidator.current.errorMessages = {};
  };

  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getWorkflowList(true);
    getModules();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [list, setList] = useState([]);
  const getWorkflowList = async (status: boolean) => {
    try {
      setIsLoading(status);
      let payload = {
        page_no: 1,
        page_size: 100,
        // moduleName:'sales'
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllWorkflows(queryRequest);
      if (resp) {
        setList(getValue(resp, `data`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [allModules, setAllModules] = useState([]);
  const getModules = async () => {
    try {
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await listAllModules(queryRequest);
      if (resp) {
        let list = getValue(resp, `data`, []).map((item: object) => ({
          ...item,
          value: getValue(item, `name`, ""),
        }));
        setAllModules(sortJSONObjectArray(list, "seq_num"));
      }
    } catch (error) {}
  };

  const [pipelineList, setPipelineList] = useState([]);
  const getAllPipelineList = async (module: string) => {
    let moudleName = allModules.filter(
      (item: object) => getValue(item, `id`, "") === module
    );
    try {
      let payload = {
        module_name: getValue(moudleName, `[${0}].name`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await allPipelines(module, queryRequest);
      if (resp) {
        setPipelineList(
          getValue(resp, `data`, []).map((item: object) => ({
            name: getValue(item, `api_name`, ""),
            label: getValue(item, `label`, ""),
            id: getValue(item, `id`, ""),
            value: getValue(item, `id`, ""),
          }))
        );
        setRequest((prevState: any) => ({
          ...prevState,
          pipelineId: getValue(resp, `data[${0}].id`, ""),
        }));
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                                Modal section                               */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      let payload = request;
      let queryRequest = QueryRequestHelper(payload);
      navigate(
        `/${getValue(
          params,
          `orgId`,
          ""
        )}/settings/crm/workflows/create?${queryRequest}`
      );
    }
  };
  const mainQueryRequest = () => {
    let payload = {
      ...urlParams,
    };
    let queryRequest = QueryRequestHelper(payload);
    return queryRequest;
  };

  /* -------------------------------------------------------------------------- */
  /*                          Update Status section                             */
  /* -------------------------------------------------------------------------- */
  const handleChangeWorkflowStatus = async (id: string, status: boolean) => {
    try {
      let payload = {
        id: [id],
        status: status,
      };
      let resp = await changeWorkflowStatus(payload);
      if (resp) {
        getWorkflowList(false);
        // toast.success("Updated successfully");
      }
    } catch (error) {}
  };

  const getTypes = (module: string) => {
    let modules: any =
      getValue(allModules, `length`, 0) > 0
        ? allModules.find(
            (item: object) => getValue(item, `api_name`, "") === module
          )
        : [];
    return getValue(modules, `type`, "");
  };
  useDynamicTitle("Workflows");
  return (
    <CompanyProfileDetailsLayout {...props} isLoading={isLoading}>
      <CompanyProfileSubHeader
        settings
        icon="workflows"
        submitIcon={<Plus size={18} />}
        title="Workflows"
        addWorkflow
        toggle={toggle}
      />
      <div className="w-100">
        {isLoading ? (
          <ListLoader />
        ) : getValue(list, `length`, 0) > 0 ? (
          <WorkflowTable
            list={list}
            mainQueryRequest={mainQueryRequest()}
            handleChangeWorkflowStatus={handleChangeWorkflowStatus}
          />
        ) : (
          <div className="d-flex align-items-center justify-content-center mt-5">
            <WorkflowssNoDataPage />
          </div>
        )}
      </div>
      <WorkflowInfoPopup
        allModules={allModules}
        getAllPipelineList={getAllPipelineList}
        pipelineList={pipelineList}
        request={request}
        setRequest={setRequest}
        isOpen={isOpen}
        toggle={toggle}
        handleSubmit={handleSubmit}
        getTypes={getTypes}
        simpleValidator={simpleValidator}
        resetRequest={resetRequest}
      />
    </CompanyProfileDetailsLayout>
  );
}

export default SettingsWorkflows;
