import React, { useEffect, useRef, useState } from "react";
import DropdownCloseSvgComponent from "@assets/svg/dropdown-close";
import { getValue } from "@utils/lodash";
import { config } from "../../../../../env";
import ResendSvgComponent from "@assets/svg/resend";
import { Button, Spin } from "antd";
import { Trash2 } from "lucide-react";
import MarkdownEditor from "@components/LiveChatMarkdownEditor/Editor";
import { getFileIcon } from "@common/document-format-helpers";

function ChatLibraryDocumentPreview(props: any) {
  const {
    showEmojiPicker,
    handleEmojiClick,
    toggleEmojiPicker,
    reference,
    setDocExpanded,
    docExpanded,
    handleUploadDocument,
    documentRequest,
    setDocumentRequest,
    handleSendDocumentMessages,
    isSendLoading,
    setSelectedMediaLibraryFilesIds,
    themeColor,
  } = props;
  const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(0);
  useEffect(() => {
    setSelectedDocumentIndex(0);
  }, []);

  const handleRemoveDocuemnt = (index: number) => {
    let filtered = documentRequest.filter(
      (item: object, i: number) => i !== index
    );
    setDocumentRequest(filtered);
    setSelectedDocumentIndex(0);
  };
  const editorRef = useRef(null);

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendDocumentMessages();
    }
  };
  const fileType = (() => {
    const url = getValue(
      documentRequest,
      `[${selectedDocumentIndex ?? 0}].url`,
      ""
    );
    const extension = url?.split(".").pop()?.toLowerCase();
    return extension ? `.${extension}` : "";
  })();

  return (
    <div>
      <div className="chat-doc-header d-flex align-items-center justify-content-between px-4">
        <div
          onClick={() => {
            setDocExpanded(!docExpanded);
            setDocumentRequest([]);
            setSelectedMediaLibraryFilesIds([]);
          }}
        >
          <DropdownCloseSvgComponent />
        </div>
        <div>
          <h6 className="header_text__16">
            {getValue(
              documentRequest,
              `[${selectedDocumentIndex}]fileId.filename`,
              ""
            )}
          </h6>
        </div>
        <div></div>
      </div>

      <div className=" d-flex justify-content-center align-items-center position-relative doc-preview-container">
        {getValue(documentRequest, `length`, 0) > 0 && (
          <Button
            color="danger"
            variant="text"
            className="chat_cross"
            onClick={() => handleRemoveDocuemnt(selectedDocumentIndex)}
            icon={<Trash2 size={16} />}
          ></Button>
        )}
        {(fileType === ".jpg" ||
          fileType === ".png" ||
          fileType === ".jpeg" ||
          fileType === ".svg") && (
          <img
            src={
              getValue(
                documentRequest,
                `[${selectedDocumentIndex ?? 0}]url`,
                ""
              ) &&
              `https://${config.URL}/${getValue(
                documentRequest,
                `[${selectedDocumentIndex ?? 0}]url`,
                ""
              )}`
            }
            alt="Selected"
            className="doc_preview_main_image"
          />
        )}

        {fileType === ".mp4" && (
          <video controls className="doc_preview_main_image">
            <source
              src={
                getValue(
                  documentRequest,
                  `[${selectedDocumentIndex ? selectedDocumentIndex : 0}]url`,
                  ""
                ) &&
                `https://${config.URL}/${getValue(
                  documentRequest,
                  `[${selectedDocumentIndex ? selectedDocumentIndex : 0}]url`,
                  ""
                )}`
              }
              type="video/mp4"
            />
          </video>
        )}

        {fileType === ".mp3" && (
          <audio controls className="sender-message">
            <source
              src={
                getValue(
                  documentRequest,
                  `[${selectedDocumentIndex ? selectedDocumentIndex : 0}]url`,
                  ""
                ) &&
                `https://${config.URL}/${getValue(
                  documentRequest,
                  `[${selectedDocumentIndex ? selectedDocumentIndex : 0}]url`,
                  ""
                )}`
              }
              type="audio/ogg"
            />
          </audio>
        )}

        {(fileType === ".pdf" ||
          fileType === ".docx" ||
          fileType === ".csv" ||
          fileType === ".txt" ||
          fileType === ".xls" ||
          fileType === ".xlsx" ||
          fileType === ".zip") && (
          <img
            src={getFileIcon(fileType)}
            alt="Selected"
            className="doc_preview_main_image p-5"
            width={300}
            height={300}
          />
        )}
      </div>

      <div
        className="chat-doc-footer"
        onKeyDown={handleKeyDown}
        ref={editorRef}
      >
        <div>
          <div className="message-bottom-section p-2">
            <div className="d-flex justify-content-around align-items-center gap-3">
              <div className="w-100 d-flex align-items-center gap-2">
                <MarkdownEditor
                  type="text"
                  name={"textBody"}
                  ref={reference}
                  value={getValue(
                    documentRequest,
                    `[${selectedDocumentIndex}].textBody`,
                    ""
                  )}
                  handleChangeEditor={(e: any) =>
                    setDocumentRequest((value: any) => {
                      return value.map((x: object, index: number) => {
                        if (index !== selectedDocumentIndex) return x;
                        return {
                          ...x,
                          textBody: e,
                        };
                      });
                    })
                  }
                  // parentChage={parentChage}
                  // setParentChage={setParentChage}
                  handleUploadDocument={handleUploadDocument}
                  // showMaxLength={showMaxLength}
                  maxLength={100}
                  allowedFileTypes=".jpg,.jpeg,.png,.gif,.mp4,.mov,.avi,.pdf,.docx,.doc,.xls,.xlsx,.csv"
                />
              </div>
              <Button
                color="primary"
                variant="text"
                onClick={handleSendDocumentMessages}
                className={` ${isSendLoading && "opacity-25"}`}
                disabled={isSendLoading || documentRequest.length === 0}
                icon={
                  isSendLoading ? (
                    <Spin />
                  ) : (
                    <ResendSvgComponent
                      color={
                        documentRequest.length > 0 ? themeColor : "#8696a0"
                      }
                    />
                  )
                }
              ></Button>
            </div>
          </div>
        </div>
      </div>
      <div className="doc_thumnail">
        <div className="d-flex align-items-center gap-3 flex-wrap justify-content-center ">
          {getValue(documentRequest, `length`, 0) > 0 &&
            documentRequest.map((item: object, key: number) => {
              const fileType = `.${
                getValue(item, "url", "").split(".").pop()?.toLowerCase() || ""
              }`;
              return (
                <div
                  key={key}
                  className="cursor-pointer"
                  onClick={() => setSelectedDocumentIndex(key)}
                >
                  {(fileType === ".jpg" ||
                    fileType === ".png" ||
                    fileType === ".jpeg" ||
                    fileType === ".svg") && (
                    <div>
                      <img
                        src={
                          getValue(item, `url`, "") &&
                          `https://${config.URL}/${getValue(item, `url`, "")}`
                        }
                        alt="Selected"
                        className={`doc_preview_image ${
                          selectedDocumentIndex === key ? "active" : ""
                        }`}
                      />
                    </div>
                  )}

                  {fileType === ".mp4" && (
                    <div>
                      <video
                        className={`doc_preview_image ${
                          selectedDocumentIndex === key ? "active" : ""
                        }`}
                      >
                        <source
                          src={
                            getValue(item, `url`, "") &&
                            `https://${config.URL}/${getValue(item, `url`, "")}`
                          }
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  )}

                  {fileType === ".mp3" && (
                    <div>
                      <audio
                        controls
                        className={`doc_preview_image ${
                          selectedDocumentIndex === key ? "active" : ""
                        }`}
                      >
                        <source
                          src={
                            getValue(item, `url`, "") &&
                            `https://${config.URL}/${getValue(item, `url`, "")}`
                          }
                          type="audio/ogg"
                        />
                      </audio>
                    </div>
                  )}

                  {(fileType === ".pdf" ||
                    fileType === ".docx" ||
                    fileType === ".csv" ||
                    fileType === ".txt" ||
                    fileType === ".xls" ||
                    fileType === ".xlsx" ||
                    fileType === ".zip") && (
                    <>
                      <div>
                        <img
                          src={
                            fileType === ".pdf"
                              ? "/images/icons/pdf_file.png"
                              : fileType === ".csv"
                              ? "/images/icons/contact-book.png"
                              : fileType === ".txt"
                              ? "/images/icons/text-file.png"
                              : fileType === ".xls" || ".xls" || ".xlsx"
                              ? "/images/icons/excel.svg"
                              : fileType === ".zip"
                              ? "/images/icons/zip.png"
                              : "/images/icons/file.png"
                          }
                          alt="Selected"
                          className={`doc_preview_image p-2 ${
                            selectedDocumentIndex === key ? "active" : ""
                          }`}
                        />
                      </div>
                    </>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default ChatLibraryDocumentPreview;
