import React from "react";

const WhatsappMessageLoader = ({ width, height, icon }: any) => {
  return (
    <div className="whatsapp-image-loader-container">
      <span
        className="whatsapp-image-loader-icon"
        style={{
          width: width ? width : "38px",
          height: height ? height : "38px",
        }}
      ></span>
      {icon && <div className="whatsapp-image-loader-type-icon">{icon}</div>}
    </div>
  );
};

export default WhatsappMessageLoader;
