import React, { useState } from "react";
import { Button, Modal, Tabs } from "antd";
import { getValue } from "@utils/lodash";
import SettingsPopupAgent from "./settings-config/agents";
import AssignConfigSettings from "./settings-config/assign";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import { whatsappManagerChatbotAccess } from "@services/fb.service";
import { toast } from "sonner";

const { TabPane } = Tabs;

function ChatSettingsAssignPopup(props: any) {
  const {
    setAssignRequest,
    chatbotList,
    aiAgentList,
    request,
    setRequest,
    reset,
    getAllPipelineList,
    pipelineList,
    stageList,
    getFbConfig,
    agentsList,
    handleModal,
    activeTab,
    setActiveTab,
  } = props;


  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      const resp = await whatsappManagerChatbotAccess(
        removeNullOrUndefinedProperties(request)
      );
      if (resp) {
        getFbConfig();
        setSubmitLoading(false);
        handleModal();
        toast.success("Updated successfully");
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  return (
    <Modal
      title="Settings"
      open={getValue(props, "isOpen", false)}
      // onOk={props.handleSubmit}
      onCancel={() => {
        props.handleModal();
        reset();
        setAssignRequest((preState: any) => ({
          ...preState,
          waAccountId: "",
          managedBy: "team",
          chatBotId: "",
        }));
      }}
      footer={[
        <>
          {activeTab === "1" && (
            <div style={{ marginTop: "24px", textAlign: "right" }}>
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={submitLoading}
                disabled={submitLoading}
              >
                Save Changes
              </Button>
            </div>
          )}
        </>,
      ]}
      width={800}
      className="chat-settings-modal"
    >
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <TabPane tab="Rules" key="1">
          <AssignConfigSettings
            chatbotList={chatbotList}
            aiAgentList={aiAgentList}
            request={request}
            setRequest={setRequest}
            reset={reset}
            getAllPipelineList={getAllPipelineList}
            pipelineList={pipelineList}
            stageList={stageList}
            getFbConfig={getFbConfig}
          />
        </TabPane>

        <TabPane tab="Agents" key="2">
          <SettingsPopupAgent
            agentsList={agentsList}
            getFbConfig={getFbConfig}
            request={request}
          />
        </TabPane>
      </Tabs>
    </Modal>
  );
}

export default ChatSettingsAssignPopup;
