import {
  handleRadioChange,
  handleSelectArrayChange,
  handleUpdateArray,
} from "@common/handleChange";
import MultiSearchLookupTooltip from "@components/custom/Dropdown/MultiSearchLookupTooltip";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue, setValue } from "@utils/lodash";
import { Radio, Tag } from "antd";
import { Clock, Flag, PlusCircle, Trash2 } from "lucide-react";
import EventsFormBuilder from "./EventsFormBuilder";

const EventFields = (props: any) => {
  const { request, setRequest, validator, workflowConditions } = props;
  const options = [
    { value: "agent", label: "Agent" },
    { value: "requester", label: "Requester" },
    { value: "agent_or_requester", label: "Agent or Requester" },
  ];

  const handleRadioRequest = (value: string) => {
    setRequest((prev: any) => {
      if (value !== "agent") {
        const updatedEvents =
          prev.events && prev.events.length === 0
            ? [
                {
                  action: "add",
                  fieldId: "string",
                  from: "string",
                  to: "string",
                },
              ]
            : prev.events;

        return {
          ...prev,
          events: updatedEvents,
        };
      } else {
        const { agents, ...rest } = prev;
        return {
          ...rest,
        };
      }
    });
  };

  const handleFilterChange = (value: any, fieldName: string) => {
    setRequest((prev: any) => ({
      ...prev,
      [fieldName]: handleUpdateArray(
        getValue(value, `id`, ""),
        getValue(request, `${fieldName}`, [])
      ),
    }));
  };

  const handleAddOption = (): void => {
    setRequest((prev: any) => ({
      ...prev,
      events: [
        ...prev.events,
        {
          action: "add",
          fieldId: "",
          from: "",
          to: "",
        },
      ],
    }));
  };

  const removeConditionUpdate = (index: number) => {
    setValue(request, `events[${index}].action`, "remove");
    setRequest({ ...request });
  };

  const handleDeleteOption = (index: number) => {
    setRequest((prev: any) => {
      return {
        ...prev,
        events: prev.events.filter((_: any, i: number) => i !== index),
      };
    });
  };

  const findInputType = (id: string) => {
    let filteredConditions = workflowConditions.find(
      (item: object) => getValue(item, `fieldId`, "") === id
    );
    let index = workflowConditions.findIndex(
      (item: object) => getValue(item, "fieldId", "") === id
    );
    return { index: index, ...filteredConditions };
  };

  const filteredEvents = () => {
    let list =
      getValue(request, `events.length`, 0) > 0
        ? getValue(request, `events`, []).filter(
            (item: object) => getValue(item, `action`, "") !== "remove"
          )
        : [];
    return list;
  };

  return (
    <div>
      {" "}
      <div className="mt-4">
        <h6 className="header_text__18 d-flex align-items-center gap-2 mb-2">
          <Tag color="green" bordered={false}>
            <Flag size={16} />
          </Tag>
          Events
        </h6>
      </div>
      {getValue(request, "type", "") === "ticket_update_rules" ? (
        <div className="sla-condition-card-wrapper ">
          <div className="d-flex align-items-center gap-3 py-2 px-4">
            <p className="small_text__14">Action performed by</p>
            <Radio.Group
              onChange={(e) => {
                handleRadioChange(e, "performer_type", request, setRequest);
                handleRadioRequest(getValue(e, "target.value", ""));
              }}
              value={getValue(request, "performer_type", "")}
              options={options}
            />
          </div>
          {getValue(request, "performer_type", "") === "agent" && (
            <div className="sla-condition-card p-4 border-bottom">
              <div className="w-70">
                <MultiSearchLookupTooltip
                  lookup_api={"org_users"}
                  label={`label`}
                  value={getValue(request, `agents`, [])}
                  selectKey={"id"}
                  onChange={(value: any) => {
                    handleFilterChange(value, "agents");
                  }}
                  name="Select Agent"
                  validator={validator}
                />
              </div>
            </div>
          )}
          {filteredEvents().map((item: object, index: number) => {
            return (
              <div className="sla-condition-card p-4 border-bottom" key={index}>
                <div className="d-flex align-items-center gap-3 flex-wrap">
                  <SearchToolTip
                    width={220}
                    label="label"
                    selectKey={"id"}
                    placeholder="Choose Event"
                    data={getValue(props, `workflowOptions`, [])}
                    value={getValue(item, `fieldId`, "")}
                    onChange={(e: any) => {
                      handleSelectArrayChange(
                        index,
                        e,
                        "fieldId",
                        "events",
                        setRequest
                      );
                      setRequest((prev: any) => ({
                        ...prev,
                        events: prev.events.map((condition: any, i: number) => {
                          if (i === index) {
                            return {
                              ...condition,
                              index: i + 1,
                            };
                          }
                          return condition;
                        }),
                      }));
                    }}
                  />
                  {getValue(item, `fieldId`, "") && (
                    <>
                      <p className="small_text__14">is changed from</p>
                      <div className="">
                        <EventsFormBuilder
                          item={findInputType(getValue(item, `fieldId`, ""))}
                          automationInfo={item}
                          parentIndex={index}
                          request={request}
                          setRequest={setRequest}
                          value={getValue(item, `from`, "")}
                          valueKey={"from"}
                        />
                      </div>

                      <p className="small_text__14">to</p>
                      <div className="">
                        <EventsFormBuilder
                          item={findInputType(getValue(item, `fieldId`, ""))}
                          automationInfo={item}
                          parentIndex={index}
                          request={request}
                          setRequest={setRequest}
                          value={getValue(item, `to`, "")}
                          valueKey={"to"}
                        />
                      </div>
                    </>
                  )}
                  {validator &&
                    (validator.current.message(
                      "events",
                      getValue(item, `fieldId`, ""),
                      "required"
                    ) ||
                      validator.current.message(
                        "from",
                        getValue(item, `from`, ""),
                        "required"
                      ) ||
                      validator.current.message(
                        "to",
                        getValue(item, `to`, ""),
                        "required"
                      )) && (
                      <p className={`error-text ps-2 pb-2`}>
                        All fields are required
                      </p>
                    )}
                </div>
                <Trash2
                  size={18}
                  className="delete-sla-condition"
                  onClick={() =>
                    getValue(item, `id`, "")
                      ? removeConditionUpdate(index)
                      : handleDeleteOption(index)
                  }
                />
              </div>
            );
          })}
          <div className="d-flex align-items-center px-4 py-3 gap-2">
            <div
              onClick={handleAddOption}
              className="header_blue_text__14 d-flex align-items-center gap-1 cursor-pointer "
            >
              <PlusCircle size={18} />
              Add New Event
            </div>
          </div>
        </div>
      ) : (
        <div className="sla-condition-card-wrapper p-3 d-flex align-items-center gap-2 small_text__14">
          <Clock size={18} /> When a ticket is being created
        </div>
      )}
    </div>
  );
};

export default EventFields;
