import { config } from "../env";
import {
  Delete,
  DeleteWithPayload,
  get,
  patch,
  post,
} from "./helpers/http-handler";

export const getAllSkills = (queryRequest: string) =>
  get(`${config.API_URL}/skills?${queryRequest}`);

export const getSpecificSkill = (id: string) =>
  get(`${config.API_URL}/skills/${id}`);

export const getSkillAgents = (id: string) =>
  get(`${config.API_URL}/skills/${id}/agents`);

export const getSkillsConditionFields = () =>
  get(`${config.API_URL}/skills/condition-fields`);

export const getSpecificSkillAgents = (id: string) =>
  get(`${config.API_URL}/skills/${id}/assigned-agents`);

export const getSkillList = () => get(`${config.API_URL}/skills/list`);

export const createSkill = (payload: object) =>
  post(`${config.API_URL}/skills`, payload);

export const updateSkill = (id: string, payload: object) =>
  patch(`${config.API_URL}/skills/${id}`, payload);

export const updateSkillAgents = (id: string, payload: object) =>
  patch(`${config.API_URL}/skills/${id}/agents`, payload);

export const updateSkillAgentsSkills = (id: string, payload: object) =>
  patch(`${config.API_URL}/skills/agents/${id}/attach-skills`, payload);

export const updateSkillStatus = (payload: object) =>
  patch(`${config.API_URL}/skills/bulk/active-inactive`, payload);

export const updateSkillSeqNum = (payload: object) =>
  patch(`${config.API_URL}/skills/seq-num/swap`, payload);

export const deleteSkill = (id: string) =>
  Delete(`${config.API_URL}/skills/${id}`);

export const deleteSkillAgents = (id: string, payload: object) =>
  DeleteWithPayload(`${config.API_URL}/skills/${id}/agents`, payload);
