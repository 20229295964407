import React, { useState } from "react";
import { Modal, Button } from "antd";
import { parsePhoneNumber } from "libphonenumber-js";
import { get } from "lodash";
import { clickToCall } from "@services/dashboard.service";
import { toast } from "sonner";
import { PhoneCall } from "lucide-react";
import { formatString } from "@common/text-helpers";

interface QuickCallModelProps {
  isModalVisible: boolean;
  handleCancel: () => void;
  selectedConversationInfo: {
    contact?: {
      phone_number?: string;
      mobile_number?: string;
    };
    lead?: {
      phone_number?: string;
      mobile_number?: string;
    };
    wa_id?: string;
  };
}

const QuickCallModel = (props: any) => {
  const [phoneLoading, setPhoneLoading] = useState(false);
  const [mobileLoading, setMobileLoading] = useState(false);
  const { isModalVisible, handleCancel, selectedConversationInfo } = props;
  // Function to get national format phone number (without country code)
  const getNationalNumber = (phoneNumber: string): string => {
    if (!phoneNumber) return "";

    try {
      const parsedNumber = parsePhoneNumber(phoneNumber);
      return parsedNumber.nationalNumber;
    } catch (error) {
      console.error("Error parsing phone number:", error);
      return phoneNumber; // Return original if parsing fails
    }
  };

  // Get phone number from conversation info
  const getPhoneNumber = (): string => {
    return (
      get(selectedConversationInfo, "contact.phone_number", "") ||
      get(selectedConversationInfo, "lead.phone_number", "")
      // ||get(selectedConversationInfo, "wa_id", "")
    );
  };

  // Get mobile number from conversation info
  const getMobileNumber = (): string => {
    return (
      get(selectedConversationInfo, "contact.mobile_number", "") ||
      get(selectedConversationInfo, "lead.mobile_number", "")
      //  ||get(selectedConversationInfo, "wa_id", "")
    );
  };

  // console.log(selectedConversationInfo);

  // Handle API call when clicking a number
  const handleNumberClick = async (numberType: string, number: string) => {
    const isPhone = numberType === "phone_number";
    if ((isPhone ? phoneLoading : mobileLoading) || !number) return;

    isPhone ? setPhoneLoading(true) : setMobileLoading(true);
    try {
      // Get national format of the number (without country code)
      const nationalNumber = getNationalNumber(number);

      // Replace this URL with your actual API endpoint
      const response = await clickToCall({
        phone_number: nationalNumber,
      });
      toast.success(`Successfully processed ${formatString(numberType)}!`);
      handleCancel();
      // console.log("API Response:", response.data);
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      isPhone ? setPhoneLoading(false) : setMobileLoading(false);
    }
  };

  const phoneNumber = getPhoneNumber();
  const mobileNumber = getMobileNumber();

  return (
    <Modal
      title="Quick Call"
      open={isModalVisible}
      onCancel={handleCancel}
      footer={[]}
    >
      <div className="py-3 d-flex flex-column gap-3">
        {phoneNumber && (
          <div className="d-flex align-items-center gap-3">
            Phone Number:
            <Button
              icon={<PhoneCall size={16} />}
              className="number-container"
              onClick={() => handleNumberClick("phone_number", phoneNumber)}
              loading={phoneLoading}
            >
              {phoneNumber}
            </Button>
          </div>
        )}

        {mobileNumber && (
          <div className="d-flex align-items-center gap-3">
            Mobile Number:
            <Button
              icon={<PhoneCall size={16} />}
              className="number-container"
              onClick={() => handleNumberClick("mobile_number", mobileNumber)}
              loading={mobileLoading}
            >
              {mobileNumber}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default QuickCallModel;
