import { convertCurrentDateToTime } from "@common/date-helpers";
import WhatsappMessageLoader from "@components/common/Loader/whatsapp-message-loader";
import { getValue } from "@utils/lodash";
import { Play } from "lucide-react";

const VideoPreview = ({ item, getReadIcon, url }: any) => {
  const getVideoType = (url: string) => {
    if (url?.endsWith(".mp4")) return "video/mp4";
    if (url?.endsWith(".webm")) return "video/webm";
    if (url?.endsWith(".ogg")) return "video/ogg";
    return "video/mp4"; // Default to a common type
  };

  return (
    <>
      <div>
        {url ? (
          <video controls className="whatsapp_file">
            <source src={url} type={getVideoType(url)} />
            Your browser does not support the video element.
          </video>
        ) : (
          <div className="whatsapp-video-loader">
            <WhatsappMessageLoader
              icon={<Play size={20} fill="#7e7e7e" color="#7e7e7e" />}
            />
          </div>
        )}
      </div>
      {getValue(item, `media_caption`, "") && (
        <p className="small_text__14 mt-1 text-start">
          {getValue(item, `media_caption`, "")}
        </p>
      )}
      <p className="send-time p-1 mt-1 d-flex justify-content-end">
        {convertCurrentDateToTime(getValue(item, `created_at`, ""))}
        {getValue(item, "is_private", "") === false && (
          <span className="ms-2">{getReadIcon(item)}</span>
        )}
      </p>
    </>
  );
};

export default VideoPreview;
