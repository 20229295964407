import "./nodata.scss";
import { getValue } from "@utils/lodash";

function FiltersNoDataPage(props: any) {
  return (
    <div className="n_container mt-4">
      <div className="nodata_container">
        <div className="d-flex justify-content-between align-items-center">
          <div className="nodata_container_main">
            <h6 className="nodata_container_header">
              No {getValue(props, `module`, "")} match the current filters.
            </h6>
            <p className="nodata_container_desc mt-2">
              Expecting to see new {getValue(props, `module`, "")}? Try again in
              a few seconds as the system catches up.
            </p>
          </div>
          <div className="w-10"></div>
          <img
            src={"/nodata/filter.svg"}
            alt="SVG Image"
            className="nodata_container_image"
          />
        </div>
      </div>
    </div>
  );
}

export default FiltersNoDataPage;
