import React, { useState } from "react";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import "@pages/Private/Settings/Apps/components/AppSettings.scss";
import SwitchMain from "@components/Form/Switch/SwitchComponent";
import useDynamicTitle from "@context/useDynamicTitle";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";

const originalCategories = [
  {
    category: "CRM",
    headings: [
      {
        title: "Contacts",
        image: "/images/whatsapp.png",
        description:
          "Engage with your leads on Whatsapp and send automated follow-up.",
        ischecked: true,
      },
      {
        title: "Companies",
        image: "/images/whatsapp.png",
        description: "Automate your CRM workflows.",
        ischecked: false,
      },
      {
        title: "Deals",
        image: "/images/whatsapp.png",
        description: "Automate your CRM workflows.",
        ischecked: true,
      },
    ],
  },
  {
    category: "Marketing",
    headings: [
      {
        title: "Tasks",
        image: "/images/whatsapp.png",
        description:
          "Engage with your leads on Whatsapp and send automated follow-up.",
        ischecked: false,
      },
      {
        title: "Calls",
        image: "/images/whatsapp.png",
        description: "Automate your CRM workflows.",
        ischecked: true,
      },
      {
        title: "Meetings",
        image: "/images/whatsapp.png",
        description: "Automate your CRM workflows.",
        ischecked: false,
      },
      {
        title: "Broadcast",
        image: "/images/whatsapp.png",
        description: "Broadcast messages to your audience.",
        ischecked: true,
      },
      {
        title: "Inbox",
        image: "/images/whatsapp.png",
        description: "Manage your inbox efficiently.",
        ischecked: false,
      },
    ],
  },
  {
    category: "Bots",
    headings: [
      {
        title: "Bots",
        image: "/images/whatsapp.png",
        description: "Automate responses with bots.",
        ischecked: true,
      },
    ],
  },
  {
    category: "Property",
    headings: [
      {
        title: "Property",
        image: "/images/whatsapp.png",
        description: "Manage property listings.",
        ischecked: true,
      },
    ],
  },
];

const allHeadings = originalCategories.flatMap((cat) => cat.headings);

const categories = [
  {
    category: "All",
    headings: allHeadings,
  },
  ...originalCategories,
];

const AppSettings = (props: any) => {
  const [selectedCategory, setSelectedCategory] = useState("All");

  const handleCategoryClick = (category: any) => {
    setSelectedCategory(category);
  };

  const getHeadings = () => {
    const selected = categories.find(
      (cat) => cat.category === selectedCategory
    );
    return selected ? selected.headings : [];
  };
  useDynamicTitle("Apps");
  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader title="Apps" settings icon="app-branding" />
      <div className={`${"email_container"} w-100`}>
        <div className={`manageFieldRight p-4 w-100`}>
          <h6 className="header_text__20">Available Integrations</h6>
          <div>
            <div className="d-flex gap-3 my-3">
              {categories.map((category, index) => (
                <button
                  key={index}
                  onClick={() => handleCategoryClick(category.category)}
                  className={`app-category-buttons
                    ${
                      selectedCategory === category.category
                        ? "category-active"
                        : ""
                    }
                  `}
                >
                  {category.category}
                </button>
              ))}
            </div>
            <div className="app-category-wrapper">
              {getHeadings().map((heading, idx) => (
                <div key={idx} className="app-category-card">
                  <div className="d-flex justify-content-between align-items-center">
                    <img
                      src={heading.image}
                      width={40}
                      height={40}
                      alt={heading.title}
                    />
                    <SwitchMain checked={heading.ischecked} />
                  </div>
                  <h6 className="header_test__18 mt-1 mb-2">{heading.title}</h6>
                  <p className="small_text__14">{heading.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </CompanyProfileDetailsLayout>
  );
};

export default AppSettings;
