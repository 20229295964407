import { handleRadioChange } from "@common/handleChange";
import { getValue } from "@utils/lodash";
import { Radio, Tag } from "antd";
import { Flag } from "lucide-react";

const EventFields = (props: any) => {
  const { request, setRequest } = props;
  const options = [
    { value: "incoming_call", label: "Incoming Call" },
    { value: "outgoing_call", label: "Outgoing Call" },
    { value: "both_calls", label: "Both Calls" },
  ];

  return (
    <div>
      <div className="mt-4">
        <h6 className="header_text__18 d-flex align-items-center gap-2 mb-2">
          <Tag color="green" bordered={false}>
            <Flag size={16} />
          </Tag>
          Events
        </h6>
      </div>
      <div className="sla-condition-card-wrapper py-3 px-4">
        <Radio.Group
          onChange={(e) => {
            handleRadioChange(e, "event", request, setRequest);
          }}
          value={getValue(request, "event", "")}
          options={options}
        />
      </div>
    </div>
  );
};

export default EventFields;
