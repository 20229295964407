import AIStars from "@components/common/Loader/ai-stars";
import { BellRing, Cog, MessageSquareMore, ShieldCheck, Sparkle, Sparkles } from "lucide-react";

function SettingsSidebar(props: any) {
  const { activeTab, setActiveTab } = props;
  return (
    <aside className="sidebar">
      <div className="menu-items">
        <div
          className={`menu-item ${activeTab === 1 ? "active" : ""}`}
          onClick={() => setActiveTab(1)}
        >
          <Cog size={16} />
          <span>General</span>
        </div>
        <div
          className={`menu-item ${activeTab === 2 ? "active" : ""}`}
          onClick={() => setActiveTab(2)}
        >
          <div className="heart-beat-anim pe-1">
            <Sparkles size={17} />
          </div>
          <span>AI</span>
        </div>
        <div
          className={`menu-item ${activeTab === 3 ? "active" : ""}`}
          onClick={() => setActiveTab(3)}
        >
          <MessageSquareMore size={16} />
          <span>Chat Interface</span>
        </div>
        {/* <div
          className={`menu-item ${activeTab === 4 ? "active" : ""}`}
          onClick={() => setActiveTab(4)}
        >
          <ShieldCheck size={16} />
          <span>Security</span>
        </div> */}
        <div
          className={`menu-item ${activeTab === 5 ? "active" : ""}`}
          onClick={() => setActiveTab(5)}
        >
          <BellRing size={16} />
          <span>Notifications</span>
        </div>
      </div>
    </aside>
  );
}

export default SettingsSidebar;
