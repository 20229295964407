import { ExclamationCircleOutlined } from "@ant-design/icons";
import { convertCurrentDate } from "@common/date-helpers";
import { getFileIcon } from "@common/document-format-helpers";
import { formatFileSize } from "@common/file-type-helper";
import { capitalizeFirstLetter, concatString } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Badge, Checkbox, Image, Modal, Table, Tabs, Tag, Tooltip } from "antd";
import Search from "antd/lib/input/Search";
import { config } from "env";
import { Download } from "lucide-react";
import { useEffect, useMemo, useState } from "react";

const ChatMediaLibraryModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  mediaLibraryType,
  setMediaLibraryType,
  mediaLibraryList,
  mediaLibraryTotalCount,
  mediaLibraryLimit,
  mediaLibraryPage_no,
  handleChangeMediaLibraryPagination,
  isLoading,
  selectedMediaLibraryFiles,
  setSelectedMediaLibraryFiles,
  setMediaLibraryLimit,
  setMediaLibraryPage_no,
  selectedMediaLibraryFilesIds,
  setSelectedMediaLibraryFilesIds,
}: {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  mediaLibraryType: string;
  setMediaLibraryType: (type: string) => void;
  mediaLibraryList: any;
  mediaLibraryTotalCount: number;
  mediaLibraryLimit: number;
  mediaLibraryPage_no: number;
  handleChangeMediaLibraryPagination: (page_no: number, limit: number) => void;
  isLoading: boolean;
  selectedMediaLibraryFiles: any;
  setSelectedMediaLibraryFiles: (files: any) => void;
  setMediaLibraryLimit: (limit: number) => void;
  setMediaLibraryPage_no: (limit: number) => void;
  selectedMediaLibraryFilesIds: any;
  setSelectedMediaLibraryFilesIds: (files: any) => void;
}) => {
  const [searchText, setSearchText] = useState("");
  const [filteredList, setFilteredList] = useState(mediaLibraryList);
  const MAX_SELECTION_LIMIT = 10;

  useEffect(() => {
    if (searchText) {
      const filtered = mediaLibraryList.filter((item: any) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredList(filtered);
    } else {
      setFilteredList(mediaLibraryList);
    }
  }, [searchText, mediaLibraryList]);

  const columns = useMemo(
    () => [
      {
        title: (
          <Checkbox
            checked={
              filteredList.length > 0 &&
              selectedMediaLibraryFilesIds.length === filteredList.length
            }
            indeterminate={
              selectedMediaLibraryFilesIds.length > 0 &&
              selectedMediaLibraryFilesIds.length < filteredList.length
            }
            onChange={(e) => {
              if (e.target.checked) {
                const itemsToSelect = filteredList.slice(
                  0,
                  MAX_SELECTION_LIMIT
                );
                setSelectedMediaLibraryFiles(itemsToSelect);
                setSelectedMediaLibraryFilesIds(
                  itemsToSelect.map((item: any) => item.id)
                );
              } else {
                setSelectedMediaLibraryFiles([]);
                setSelectedMediaLibraryFilesIds([]);
              }
            }}
          />
        ),
        dataIndex: "id",
        key: "id",
        width: 50,
        render: (id: string, record: any) => (
          <Checkbox
            checked={selectedMediaLibraryFilesIds.includes(id)}
            disabled={
              !selectedMediaLibraryFilesIds.includes(id) &&
              selectedMediaLibraryFilesIds.length >= MAX_SELECTION_LIMIT
            }
            onChange={(e) => {
              if (e.target.checked) {
                if (selectedMediaLibraryFilesIds.length < MAX_SELECTION_LIMIT) {
                  setSelectedMediaLibraryFiles([
                    ...selectedMediaLibraryFiles,
                    record,
                  ]);
                  setSelectedMediaLibraryFilesIds([
                    ...selectedMediaLibraryFilesIds,
                    id,
                  ]);
                }
              } else {
                setSelectedMediaLibraryFiles(
                  selectedMediaLibraryFiles.filter(
                    (file: any) => file.id !== id
                  )
                );
                setSelectedMediaLibraryFilesIds(
                  selectedMediaLibraryFilesIds.filter(
                    (fileId: string) => fileId !== id
                  )
                );
              }
            }}
          />
        ),
      },
      {
        title: "NAME",
        dataIndex: "name",
        key: "name",
        render: (name: string, record: any) => (
          <div className="d-flex align-items-center gap-2">
            {getValue(record, "file_type", "") === "image" ? (
              <Image
                width={40}
                height={40}
                src={`https://${config.URL}/${getValue(record, `url`, "")}`}
              />
            ) : getValue(record, "file_type", "") === "video" ? (
              <video
                width={40}
                height={40}
                autoPlay
                muted
                loop
                src={`https://${config.URL}/${getValue(record, `url`, "")}`}
              />
            ) : getValue(record, "file_type", "") === "audio" ? (
              <img
                src="/images/icons/audio.png"
                width={30}
                height={30}
                alt="audio-file"
              />
            ) : getValue(record, "file_type", "") === "document" ? (
              <img
                src={getFileIcon(
                  `.${
                    getValue(record, "url", "")
                      .split(".")
                      .pop()
                      ?.toLowerCase() || ""
                  }`
                )}
                width={30}
                height={30}
                alt="document-file"
              />
            ) : null}
            <Tooltip title={name}>{concatString(name, 30)}</Tooltip>
          </div>
        ),
      },
      {
        title: "SIZE",
        dataIndex: "file_size",
        key: "file_size",
        width: 160,
        render: (file_size: string, record: any) =>
          formatFileSize(getValue(record, "file.size", "")),
      },
      {
        title: "ADDED BY",
        dataIndex: "created_by",
        key: "created_by",
        width: 160,
        render: (text: string, record: any) => (
          <Tooltip
            title={
              getValue(record, "created_by.first_name", "") +
              " " +
              getValue(record, "created_by.last_name", "")
            }
          >
            <p>
              {concatString(
                getValue(record, "created_by.first_name", "") +
                  " " +
                  getValue(record, "created_by.last_name", ""),
                24
              )}
            </p>
          </Tooltip>
        ),
      },
      {
        title: "UPDATED ON",
        dataIndex: "updated_at",
        key: "updated_at",
        width: 120,
        render: (updated_at: any) => convertCurrentDate(updated_at),
      },
      {
        title: "",
        dataIndex: "url",
        key: "url",
        width: 50,
        render: (url: any, record: any) => (
          <a
            href={`https://${config.URL}/${url}`}
            target="_blank"
            rel="noreferrer"
          >
            <Download size={18} />
          </a>
        ),
      },
    ],
    [filteredList, selectedMediaLibraryFiles, setSelectedMediaLibraryFiles]
  );

  const fileList = useMemo(
    () => (type: string) => {
      return (
        <Table
          columns={columns}
          dataSource={filteredList}
          pagination={{
            current: mediaLibraryPage_no,
            pageSize: mediaLibraryLimit,
            total: mediaLibraryTotalCount,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50"],
            onChange: (page_no, pageSize) => {
              handleChangeMediaLibraryPagination(page_no, pageSize);
            },
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
          scroll={{ y: "calc(100vh - 426px)", x: "max-content" }}
          loading={isLoading}
        />
      );
    },
    [
      columns,
      filteredList,
      mediaLibraryPage_no,
      mediaLibraryLimit,
      mediaLibraryTotalCount,
      handleChangeMediaLibraryPagination,
      isLoading,
    ]
  );

  const tabItems = useMemo(
    () => [
      {
        key: "image",
        label: "Images",
        children: fileList(mediaLibraryType),
      },
      {
        key: "video",
        label: "Videos",
        children: fileList(mediaLibraryType),
      },
      {
        key: "audio",
        label: "Audio",
        children: fileList(mediaLibraryType),
      },
      {
        key: "document",
        label: "Documents",
        children: fileList(mediaLibraryType),
      },
    ],
    [fileList, mediaLibraryType]
  );

  return (
    <Modal
      title="Media Library"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Select"
      width={800}
    >
      <Search
        placeholder={`Search By ${capitalizeFirstLetter(
          mediaLibraryType
        )} Name`}
        allowClear
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: 16 }}
      />
      <Tabs
        defaultActiveKey={mediaLibraryType}
        items={tabItems}
        onChange={(key) => {
          setMediaLibraryType(key);
          setMediaLibraryLimit(10);
          setMediaLibraryPage_no(1);
        }}
        tabBarExtraContent={{
          right: selectedMediaLibraryFiles.length > 0 && (
            <div className="d-flex align-items-center gap-2 small_text__14">
              {selectedMediaLibraryFilesIds.length === 10 && (
                <Tag color="processing" icon={<ExclamationCircleOutlined />}>
                  Max 10 files can be selected
                </Tag>
              )}
              <Badge count={selectedMediaLibraryFiles.length} color="blue" />
              Files selected
            </div>
          ),
        }}
      />
    </Modal>
  );
};

export default ChatMediaLibraryModal;
