import React from "react";
import { motion } from "framer-motion";
import DocumentSvgComponent from "@assets/svg/document";
import { getValue } from "@utils/lodash";
import CrossSvgComponent from "@assets/svg/cross";

function ConversationReply(props: any) {
  const { selectedReplyMessage, maxLength, setSelectedReplyMessage } = props;
  return (
    <div>
      {selectedReplyMessage && (
        <motion.div
          initial={{ y: 0, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <div className="d-flex  align-items-center replay-message-wrapper">
            <div className="reply_message_container d-flex justify-content-between align-items-center px-4 py-2 mx-4 my-2 w-100 ">
              <div className="d-flex align-items-center ms-2">
                <DocumentSvgComponent />
                <p className="small_text__14 ms-2">
                  {getValue(selectedReplyMessage, `media_caption`, "")
                    ? getValue(selectedReplyMessage, `media_caption`, "")
                        .length > maxLength
                      ? getValue(
                          selectedReplyMessage,
                          `media_caption`,
                          ""
                        ).substring(0, maxLength) + "..."
                      : getValue(selectedReplyMessage, `media_caption`, "")
                    : getValue(selectedReplyMessage, `text`, "")
                    ? getValue(selectedReplyMessage, `text`, "").length >
                      maxLength
                      ? getValue(
                          selectedReplyMessage,
                          `text`,
                          ""
                        ).substring(0, maxLength) + "..."
                      : getValue(selectedReplyMessage, `text`, "")
                    : getValue(selectedReplyMessage, `upload_file.filename`, "")
                    ? getValue(selectedReplyMessage, `upload_file.filename`, "")
                        .length > maxLength
                      ? getValue(
                          selectedReplyMessage,
                          `upload_file.filename`,
                          ""
                        ).substring(0, maxLength) + "..."
                      : getValue(
                          selectedReplyMessage,
                          `upload_file.filename`,
                          ""
                        )
                    : getValue(selectedReplyMessage, `wa_template.body`, "")
                    ? getValue(selectedReplyMessage, `wa_template.body`, "")
                        .length > maxLength
                      ? getValue(
                          selectedReplyMessage,
                          `wa_template.body`,
                          ""
                        ).substring(0, maxLength) + "..."
                      : getValue(selectedReplyMessage, `wa_template.body`, "")
                    : getValue(selectedReplyMessage, `type`, "")
                    ? getValue(selectedReplyMessage, `type`, "")
                    : "No"}
                </p>
              </div>
            </div>
            <div
              onClick={() => setSelectedReplyMessage(null)}
              className="cursor-pointer pe-3"
            >
              <CrossSvgComponent />
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
}

export default ConversationReply;
