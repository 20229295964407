import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllAgentStatus = (queryRequest: string) =>
  get(`${config.API_URL}/agent-statuses?${queryRequest}`);

export const getListAllAgentStatus = (queryRequest: string) =>
  get(`${config.API_URL}/agent-statuses/list?${queryRequest}`);

export const getSpecificAgentStatus = (id: string) =>
  get(`${config.API_URL}/agent-statuses/${id}`);

export const createAgentStatus = (payload: object) =>
  post(`${config.API_URL}/agent-statuses`, payload);

export const updateAgentStatus = (id: string, payload: object) =>
  patch(`${config.API_URL}/agent-statuses/${id}`, payload);

export const deleteAgentStatus = (id: string) =>
  Delete(`${config.API_URL}/agent-statuses/${id}`);

export const updateBulkAgentStatus = ( payload: object) =>
  patch(`${config.API_URL}/agent-statuses/bulk/active-inactive`, payload);
