import { getValue } from "@utils/lodash";
import DocumentFormatPreviews from "./doc-formats-preview";
import ArrowdownSvgComponent from "@assets/svg/arrowdown";
import DocumentFormatReplyPreviews from "./doc-formats-reply-preview";
import { AnimatePresence, motion } from "framer-motion";
import { handleDownloadOnClick } from "@common/downloadfile";
import { config } from "env";
import { Dropdown, Space } from "antd";
import { formatMarkdownText } from "@components/MarkdownEditor/helpers/textFormatter";
import { Forward, PhoneIncoming, PhoneMissed, Plus } from "lucide-react";
import WhatsappIconChatViewPage from "@pages/Private/Broadcast/components/common/whatsapp-icon-chat";
import { formatStatusText } from "@common/text-helpers";
import { convertCurrentDateToTime } from "@common/date-helpers";
import { getStatusColor } from "../helpers/live-chat-helpers";
import { formatDuration } from "@common/time-helper";
import { getFileIcon } from "@common/document-format-helpers";

function ChatReceiverSection(props: any) {
  const { item, setSelectedReplyMessage, handleAddToDocuments } = props;
  const items: any = [
    {
      key: "1",
      label: (
        <div
          onClick={() => setSelectedReplyMessage(item)}
          className=" small_text__13 d-flex gap-2 align-items-center"
        >
          <img src="/images/icons/whatsapp-reply.svg" />
          Reply
        </div>
      ),
    },
    ...(item.type === "image" ||
    item.type === "video" ||
    item.type === "document"
      ? [
          {
            type: "divider",
          },
          {
            key: "2",
            label: (
              <div
                className="small_text__13 d-flex gap-2 align-items-center"
                onClick={() =>
                  handleDownloadOnClick(
                    `https://${config.URL}/${item.upload_file.key}`,
                    item.upload_file.filename
                  )
                }
              >
                <img
                  src="/images/icons/whatsapp-download.svg"
                  alt="Download"
                  className="download-icon"
                  width={16}
                  height={16}
                />
                Download
              </div>
            ),
          },
          {
            type: "divider",
          },
          {
            key: "3",
            icon: <Plus size={16} color="#434d5f" />,
            label: (
              <div
                className="small_text__13 d-flex gap-2 align-items-center"
                onClick={() =>
                  handleAddToDocuments(
                    getValue(item, "upload_file.id", ""),
                    getValue(item, "inbox.contact_id", "")
                      ? getValue(item, "inbox.contact_id", "")
                      : getValue(item, "inbox.lead_id", ""),
                    getValue(item, "inbox.contact_id", "")
                      ? "contact_id"
                      : "lead_id"
                  )
                }
              >
                Add to Documents
              </div>
            ),
          },
        ]
      : []),
  ];

  return getValue(item, `call_report`, "") ? (
    getValue(item, `call_report.direction`, "") === "incoming" && (
      <div className="reciever-details-wrapper">
        <div className="reciever-details">
          <div className="reciever-right-section">
            <p className="reciever-call-notification">
              <div className="reciever-call-notification-container">
                <div className="reciever-call-notification-icon">
                  {getValue(item, "call_report.status", "") === "ANSWER" ? (
                    <PhoneIncoming size={18} color="none" fill="#25d366" />
                  ) : (
                    <PhoneMissed
                      size={18}
                      color="none"
                      fill={getStatusColor(
                        getValue(item, "call_report.status", "")
                      )}
                    />
                  )}
                </div>
                <div>
                  <p className="reciever-call-notification-text">
                    {formatStatusText(getValue(item, "call_report.status", ""))}
                  </p>
                  {getValue(item, "call_report.call_duration", "") && (
                    <p className="reciever-call-notification-duration-text">
                      {formatDuration(
                        getValue(item, "call_report.call_duration", "")
                      )}
                    </p>
                  )}
                </div>
                <p className="send-time d-flex align-items-end mt-4 pt-1">
                  {convertCurrentDateToTime(getValue(item, `created_at`, ""))}
                </p>
              </div>
            </p>
          </div>
        </div>
      </div>
    )
  ) : (
    <div className="reciever-details-wrapper">
      <div className="reciever-details">
        <div className="reciever-right-section">
          <p
            className={` ${
              getValue(item, `type`, "") === "sticker"
                ? "sticker-message"
                : "reciever-message"
            }`}
          >
            {getValue(item, `is_forwarded`, "") && (
              <div className="forwarded-message-container">
                <Forward size={16} color="#a4a4a4" />
                <p className="">
                  <i>Forwarded</i>
                </p>
              </div>
            )}
            <div className="arrow-container">
              <Dropdown menu={{ items }}>
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    <ArrowdownSvgComponent color={"gray"} class="arrow" />
                  </Space>
                </a>
              </Dropdown>
            </div>
            {getValue(item, `type`, "") === "unsupported" && (
              <p className="small_text__13 px-2 py-2 color_gray">
                Message format is not supported.
              </p>
            )}
            {getValue(item, `type`, "") === "template" &&
              // !getValue(item, `text`, "") &&
              getValue(item, `wa_template.body`, "") && (
                <WhatsappIconChatViewPage
                  request={getValue(item, `wa_template`, "")}
                  item={item}
                />
              )}
            {getValue(item, `replied_message.id`, "") && (
              <AnimatePresence mode="wait">
                <motion.section
                  key="content"
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: { opacity: 1, minHeight: "56px" },
                    collapsed: { opacity: 0 },
                  }}
                  transition={{
                    duration: 0.8,
                    ease: [0.04, 0.62, 0.23, 0.98],
                  }}
                >
                  <div className="reply_message_container mb-2 reciever-replay w-100">
                    <div className="d-flex align-items-center ms-2 justify-content-between w-100">
                      {getValue(item, `replied_message.type`, "") ===
                      "template" ? (
                        <WhatsappIconChatViewPage
                          request={getValue(
                            item,
                            `replied_message.wa_template`,
                            ""
                          )}
                        />
                      ) : (
                        <div className="d-flex align-items-center justify-content-between">
                          {/* <DocumentSvgComponent /> */}
                          <img
                            src={getFileIcon(
                              getValue(
                                item,
                                "replied_message.upload_file.extension",
                                ""
                              )
                            )}
                            width={16}
                            height={16}
                            alt="File Icon"
                          />
                          <p
                            dangerouslySetInnerHTML={{
                              __html: formatMarkdownText(
                                // getValue(item, `text`, "")
                                //   ? getValue(item, `text`, "")
                                getValue(
                                  item,
                                  `replied_message.media_caption`,
                                  ""
                                )
                                  ? getValue(
                                      item,
                                      `replied_message.media_caption`,
                                      ""
                                    )
                                  : getValue(
                                      item,
                                      `replied_message.text_body`,
                                      ""
                                    ) ||
                                      getValue(item, `replied_message.text`, "")
                              ),
                            }}
                            className="small_text__14 px-2 py-1"
                          ></p>
                        </div>
                      )}
                      {getValue(item, `replied_message.upload_file.id`, "") && (
                        <DocumentFormatReplyPreviews
                          item={getValue(item, `replied_message`, {})}
                          upload_file={getValue(
                            item,
                            `replied_message.upload_file`,
                            ""
                          )}
                        />
                      )}
                    </div>
                  </div>
                </motion.section>
              </AnimatePresence>
            )}
            <DocumentFormatPreviews item={item} receiver group={props.group} />
          </p>
        </div>
      </div>
    </div>
  );
}

export default ChatReceiverSection;
