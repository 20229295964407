import React, { ChangeEvent, memo, useCallback, useState } from "react";
import { getValue } from "@utils/lodash";
import "./input.scss";
import { useDebounce } from "hooks/useDebounce";

interface InlineInputProps {
  disabled?: boolean;
  name: string;
  type: string;
  value: any;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  maxLength?: number;
  placeholder?: string;
  validator?: any;
  label?: string;
  mandatory?: boolean;
  hideLabel?: boolean;
  validatoryType?: string;
  showWhatsapp?: boolean;
  onBlur?: (e: any) => void;
  api_name?: string;
}

const DebouncedInlineInput = memo(function InlineInput({
  disabled = false,
  name,
  type,
  value = "",
  onChange,
  maxLength,
  placeholder,
  label,
  mandatory,
  hideLabel,
  showWhatsapp,
  onBlur,
  api_name,
}: InlineInputProps) {
  // Local state for immediate UI updates
  const [localValue, setLocalValue] = useState(value);

  // Debounce the value changes
  const debouncedValue = useDebounce(localValue, 300);

  // Update parent when debounced value changes
  React.useEffect(() => {
    if (debouncedValue !== value) {
      const e = {
        target: {
          name,
          value: debouncedValue,
        },
      } as ChangeEvent<HTMLInputElement>;
      onChange(e);
    }
  }, [debouncedValue, name, onChange, value]);

  // Handle local input changes
  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
  }, []);

  return (
    <div>
      {!hideLabel && (
        <div className="d-flex justify-content-between align-items-center">
          <label className="form-label-inline">
            {label} {mandatory && "*"}
          </label>
          {showWhatsapp && getValue(localValue, "length", 0) === 10 && (
            <img
              src="/images/whatsapp.png"
              height={25}
              width={25}
              onClick={() =>
                window.open(
                  `https://api.whatsapp.com/send?text=Hi&phone=+91${localValue}`
                )
              }
              alt="WhatsApp"
            />
          )}
        </div>
      )}
      <div>
        <input
          className="inline_form_input"
          disabled={disabled}
          name={name}
          type={type}
          value={localValue}
          onChange={handleInputChange}
          placeholder={placeholder}
          maxLength={maxLength}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
});

export default DebouncedInlineInput;
