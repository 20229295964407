import { getValue } from "@utils/lodash";
import "./view.scss";
import InputRuleForm from "@components/InputRuleForm/form";
import { formatString } from "@common/text-helpers";
function AdvancedFilterViewStep1(props: any) {
  const {
    smartFilterFields,
    module,
    searchText,
    handleChangeSearch,
    setSelectedFilterOption,
    setActiveTab,
    setRequest,
    request,
  } = props;

  return (
    <>
      <div className="advance_filter_main_container pb-5 mb-5">
        <div className="advance_filter_container">
          <h6 className="mt-3 header_text__18">
            {formatString(module)} Properties
          </h6>
          <div className="mb-3">
            <InputRuleForm
              inputType="TEXT"
              placeholder={"Search here"}
              value={searchText}
              onChange={handleChangeSearch}
              name={"searchText"}
            />
          </div>
          {smartFilterFields.map((item: object) => {
            return (
              <div
                className={`field_container ${
                  getValue(item, `api_name`, "") ===
                  getValue(request, `api_name`, "")
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  setRequest({
                    ...request,
                    display_label: getValue(item, `label`, ""),
                    input_type: getValue(item, `input_type`, ""),
                    api_name: getValue(item, `api_name`, ""),
                    custom: getValue(item, `custom`, false),
                  });
                  setSelectedFilterOption(item);
                  setActiveTab(2);
                }}
              >
                <p className="text">{getValue(item, `label`, "")}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default AdvancedFilterViewStep1;
