import { Globe, HelpCircle, MessageCircle, MessageSquare } from "lucide-react";
import React from "react";

function AgentSidebar(props: any) {
  const { activeTab } = props;

  return (
    <div className="sidebar">
      {/* <div className="menu-item">
          <FileText size={20} /> Files
        </div> */}
      <div
        className={`menu-item ${activeTab == 1 ? "active" : ""}`}
        onClick={() => props.setActiveTab("1")}
      >
        <Globe size={16} /> Website
      </div>
      <div
        className={`menu-item ${activeTab == 2 ? "active" : ""}`}
        onClick={() => props.setActiveTab("2")}
      >
        <MessageSquare size={16} /> Text
      </div>

      <div
        className={`menu-item ${activeTab == 3 ? "active" : ""}`}
        onClick={() => props.setActiveTab("3")}
      >
        <MessageCircle size={16} /> Q&A
      </div>
      {/* <div className="menu-item">
          <Book size={20} /> Notion
        </div> */}
    </div>
  );
}

export default AgentSidebar;
