import ChatPhoneConfig from "@pages/Private/Settings/Chat/Phone";
import WebchatMessages from "@pages/Private/Settings/Chat/Webchat";
import WebChatManage from "@pages/Private/Settings/Chat/Webchat/bot/details/webchat-manage";
import WebChatSettings from "@pages/Private/Settings/Chat/Webchat/bot/details/webchat-settings";
import WebchatSharePage from "@pages/Private/Settings/Chat/Webchat/bot/details/webchat-share";
import MessagePages from "@pages/Private/Settings/Chat/Whatsapp";

export const SettingsChatRoutes = [
  {
    path: "/:orgId/settings/chat/messages",
    name: "settings_channels_whatsapp",
    component: MessagePages,
  },
  {
    path: "/:orgId/settings/chat/webchat",
    name: "settings_channels_whatsapp",
    component: WebchatMessages,
  },
  {
    path: "/:orgId/settings/chat/webchat/settings/:id",
    name: "chatbot",
    component: WebChatSettings,
  },
  {
    path: "/:orgId/settings/chat/webchat/share/:id",
    name: "chatbot",
    component: WebchatSharePage,
  },
  {
    path: "/:orgId/settings/chat/webchat/manage/:id",
    name: "chatbot",
    component: WebChatManage,
  },
  {
    path: "/:orgId/settings/chat/phone",
    name: "chatbot",
    component: ChatPhoneConfig,
  },

];
