import NoAccessComponent from "@layouts/HomeLayout/noaccess";
import "./sidebarLayout.scss";
import VerticalNav from "./components/VerticalNav";
import HorizontalNav from "./components/HorizontalNav";
import { useState } from "react";

export default function SidebarLayout({ children, ...restProps }: any) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <HorizontalNav isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="sidebar-layout">
        <VerticalNav isOpen={isOpen} setIsOpen={setIsOpen} />
        {restProps.isLoading || !restProps.permissionAPITriggered ? (
          <div className="sidebar-layout-content">{children}</div>
        ) : restProps.permissions.includes("read") ||
          restProps.permissions.includes("global_access") ||
          restProps.name === "universal_inbox" ? (
          <div className="sidebar-layout-content">{children}</div>
        ) : (
          <NoAccessComponent />
        )}
      </div>
    </>
  );
}
