import { convertDateTime } from "@common/date-helpers";
import { handleTextRequestArrayChange } from "@common/handleChange";
import DateMonthYearPicker from "@components/custom/Forms/Date";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Alert, Checkbox, Modal, Select, Tabs, TabsProps } from "antd";

const AddHolidayModal = ({
  open,
  handleOk,
  handleCancel,
  request,
  setRequest,
  item,
  currentModal,
  addAnother,
  handleAddAnotherChange,
}: any) => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Exclusive Holidays",
      children: (
        <div className="mb-4">
          <DateMonthYearPicker
            {...item}
            onChange={(e: any) => {
              const updatedDate = convertDateTime(e);
              setRequest({
                ...request,
                holidays: request.holidays.map((holiday: any, index: any) =>
                  index === currentModal - 1
                    ? { ...holiday, date: updatedDate }
                    : holiday
                ),
              });
            }}
            type={"text"}
            label="date"
            name="date"
            placeholder={`Select Date`}
            mandatory
            hideLabel
            value={getValue(request, `holidays[${currentModal - 1}].date`, "")}
          />

          <InputRuleForm
            inputType="TEXT"
            name="name"
            value={getValue(request, `holidays[${currentModal - 1}].name`, "")}
            placeholder="e.g. New Year's Day"
            required
            label="Name (For Own Reference)"
            onChange={(e: any) =>
              handleTextRequestArrayChange(
                currentModal - 1,
                e,
                "holidays",
                setRequest
              )
            }
          />
        </div>
      ),
    },
    // {
    //   key: "2",
    //   label: "Regional Holidays",
    //   children: (
    //     <div>
    //       <Alert
    //         showIcon
    //         message="Applying a regional holiday will overwrite the list of holidays you’ve added to this business calendar."
    //         type="info"
    //       />
    //       <div className="mt-2 mb-4">
    //         <label className="form-label">Choose a Holiday Calender</label>
    //         <Select
    //           showSearch
    //           placeholder="Select a Holiday Calender"
    //           optionFilterProp="label"
    //           className="w-100"
    //           size="large"
    //           // onChange={onChange}
    //           // onSearch={onSearch}
    //           options={[
    //             {
    //               value: "jack",
    //               label: "Jack",
    //             },
    //             {
    //               value: "lucy",
    //               label: "Lucy",
    //             },
    //             {
    //               value: "tom",
    //               label: "Tom",
    //             },
    //           ]}
    //         />
    //       </div>
    //     </div>
    //   ),
    // },
  ];
  return (
    <Modal
      open={open}
      title="Add Holidays"
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{
        disabled: (() => {
          const currentHoliday = request.holidays[currentModal - 1];
          return !currentHoliday?.name || !currentHoliday?.date;
        })(),
      }}
      okText="Add"
      footer={(_, { OkBtn, CancelBtn }) => (
        <div className="d-flex justify-content-between align-items-center">
          <Checkbox checked={addAnother} onChange={handleAddAnotherChange}>
            Add Another
          </Checkbox>
          <div className="d-flex gap-2 align-items-center">
            <CancelBtn />
            <OkBtn />
          </div>
        </div>
      )}
    >
      <Tabs defaultActiveKey="1" items={items} />
    </Modal>
  );
};

export default AddHolidayModal;
