export const getFileIcon = (extension?: string) => {
  if (!extension) return "/images/icons/file.png";

  switch (extension.toLowerCase()) {
    case ".pdf":
      return "/images/icons/pdf_file.png";
    case ".svg":
    case ".png":
    case ".jpg":
    case ".jpeg":
      return "/images/icons/image_file.png";
    case ".csv":
      return "/images/icons/contact-book.png";
    case ".txt":
      return "/images/icons/text-file.png";
    case ".xls":
    case ".xlsx":
      return "/images/icons/excel.svg";
    case ".zip":
      return "/images/icons/zip.png";
    case ".mp4":
      return "/images/icons/video-file.png";
    case ".mp3":
      return "/images/icons/audio.png";
    default:
      return "/images/icons/file.png";
  }
};
