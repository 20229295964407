import {
  handleEmailChange,
  handleSelectChange,
  handleTextChange,
} from "@common/handleChange";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import timezones from "@common/json/standardized-timezone-data.json";
import countries from "@common/json/contry.json";
import currencies from "@common/json/currency.json";
import { PhoneInput } from "react-international-phone";
import { Eye, EyeOff } from "lucide-react";
import { Button } from "antd";
import parsePhoneNumberFromString, { CountryCode } from "libphonenumber-js";

const SignupAllSteps = ({
  steps,
  request,
  setRequest,
  validator,
  password,
  passwordValidator,
  setPassword,
  OTP_INPUTS_PER_GROUP,
  otp,
  handleOtpInputChange,
  handlePaste,
  handleKeyDown,
  inputRefs,
  otpValidator,
  requestFour,
  requestFive,
  requestSix,
  setRequestFive,
  setRequestSix,
  setRequestFour,
  reqFourValidator,
  reqFiveValidator,
  reqSixValidator,
  handleSelectCountry,
  handleWrongEmail,
}: any) => {
  const [currencyList, setCurrencyList] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    let codes: any = currencies.map((item: object) => ({
      ...item,
      label: `${getValue(item, `cc`, "")} - ${getValue(item, `name`, "")}`,
      value: `${getValue(item, `cc`, "")}`,
    }));
    setCurrencyList(codes);
  }, []);

  const isValidPhoneNumber = (phone: string, country: string) => {
    const phoneNumber = parsePhoneNumberFromString(
      phone,
      country.toUpperCase() as CountryCode
    );
    return phoneNumber
      ? phoneNumber.isValid() && !!phoneNumber.nationalNumber
      : false;
  };

  return (
    <div className="new-signup-form">
      {steps === 1 ? (
        <>
          <div className="row g-3">
            <div className="col-md-6">
              <input
                type="text"
                placeholder="First Name"
                className="signup-input"
                name="first_name"
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^[A-Za-z\s]*$/.test(value)) {
                    handleTextChange(e, request, setRequest);
                  }
                }}
                value={getValue(request, "first_name", "")}
                onKeyDown={handleKeyDown}
                maxLength={50}
              />
              {validator.current.message(
                "First name",
                getValue(request, "first_name", ""),
                "required"
              ) && (
                <p className={`text-danger small_text__12 pt-1`}>
                  {validator.current.message(
                    "First name",
                    getValue(request, "first_name", ""),
                    "required"
                  )}
                </p>
              )}
            </div>
            <div className="col-md-6">
              <input
                type="text"
                placeholder="Last Name"
                className="signup-input"
                name="last_name"
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^[A-Za-z\s]*$/.test(value)) {
                    handleTextChange(e, request, setRequest);
                  }
                }}
                value={getValue(request, "last_name", "")}
                onKeyDown={handleKeyDown}
                maxLength={50}
              />
              {validator.current.message(
                "Last name",
                getValue(request, "last_name", ""),
                "required"
              ) && (
                <p className={`text-danger small_text__12 pt-1`}>
                  {validator.current.message(
                    "Last name",
                    getValue(request, "last_name", ""),
                    "required"
                  )}
                </p>
              )}
            </div>
          </div>
          <div>
            <input
              type="email"
              placeholder="Email Address"
              className="signup-input"
              name="identifier"
              onChange={(e) => handleEmailChange(e, request, setRequest)}
              value={getValue(request, "identifier", "")}
              onKeyDown={handleKeyDown}
              maxLength={254}
            />
            {validator.current.message(
              "Email",
              getValue(request, "identifier", ""),
              "required|email"
            ) && (
              <p className={`text-danger small_text__12 pt-1`}>
                {validator.current.message(
                  "Email",
                  getValue(request, "identifier", ""),
                  "required|email"
                )}
              </p>
            )}
          </div>
        </>
      ) : steps === 2 ? (
        <>
          <input
            placeholder="Email Address"
            className="signup-input"
            value={getValue(request, "identifier", "")}
            disabled
          />
          <div className="position-relative">
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Create Password"
              className="signup-input"
              name="password"
              onChange={(e) => handleTextChange(e, password, setPassword)}
              value={getValue(password, "password", "")}
              onKeyDown={handleKeyDown}
              style={{ paddingRight: "44px" }}
            />
            <Button
              color="default"
              variant="text"
              className="password-toggle-button"
              onClick={() => setShowPassword(!showPassword)}
              icon={showPassword ? <Eye size={16} /> : <EyeOff size={16} />}
            />
            {passwordValidator.current.message(
              "Password",
              getValue(password, "password", ""),
              "required"
            ) && (
              <p className={`text-danger small_text__12 pt-1`}>
                {passwordValidator.current.message(
                  "Password",
                  getValue(password, "password", ""),
                  "required"
                )}
              </p>
            )}
          </div>
          <p className="small_text__12">
            By clicking{" "}
            <span className="text-black">
              <b>Signup for Free</b>
            </span>
            , you agree to our terms and acknowledge reading our{" "}
            <a href="https://www.appzo.ai/policy" target="_blank">
              privacy notice
            </a>
          </p>
        </>
      ) : steps === 3 ? (
        <>
          <div className="d-flex justify-content-center">
            <div className={`custom-form-group form-group`}>
              <div className="d-flex justify-content-between">
                {[...Array(otp.length)].map((_, idx) => {
                  const groupIdx = Math.floor(idx / OTP_INPUTS_PER_GROUP);
                  const localIdx = idx % OTP_INPUTS_PER_GROUP;
                  const isLastInGroup = localIdx === OTP_INPUTS_PER_GROUP - 1;
                  const isFirstInGroup = localIdx === 0 && idx !== 0;
                  return (
                    <React.Fragment key={idx}>
                      <input
                        type="text"
                        inputMode="numeric"
                        autoComplete="one-time-code"
                        pattern="\d{1}"
                        name={`otp-${idx}`}
                        onChange={(e) =>
                          handleOtpInputChange(
                            groupIdx,
                            localIdx,
                            e.target.value
                          )
                        }
                        onPaste={handlePaste}
                        onKeyDown={handleKeyDown}
                        maxLength={1}
                        className={`otp-input ${
                          localIdx === 0 ? "first-input" : ""
                        } ${isLastInGroup ? "last-input" : ""}`}
                        value={otp[idx]}
                        ref={(input) => (inputRefs.current[idx] = input)}
                      />
                      {isLastInGroup && idx < otp.length - 1 && (
                        <span
                          style={{
                            marginLeft: "10px",
                            marginRight: "10px",
                          }}
                          className="mt-2"
                        >
                          _
                        </span>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
          {otpValidator.current.message("OTP", otp, "required|number") && (
            <p className={`form-error text-center`}>
              {otpValidator.current.message("OTP", otp, "required|number")}
            </p>
          )}
        </>
      ) : steps === 4 ? (
        <>
          <div>
            <input
              type="text"
              placeholder="Enter Organisation Name"
              className="signup-input"
              name={"name"}
              value={getValue(requestFour, `name`, "")}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleTextChange(e, requestFour, setRequestFour)
              }
              onKeyDown={handleKeyDown}
              maxLength={100}
            />
            {reqFourValidator.current.message(
              "name",
              getValue(requestFour, "name", ""),
              "required"
            ) && (
              <p className={`text-danger small_text__12 pt-1`}>
                {reqFourValidator.current.message(
                  "name",
                  getValue(requestFour, "name", ""),
                  "required"
                )}
              </p>
            )}
          </div>
          <div className="signup-search-tooltip">
            <SearchToolTip
              placeholder={`Select Country`}
              onChange={(e: any) => handleSelectCountry(e)}
              label={`label`}
              data={countries.map((item: object) => ({
                ...item,
                value: getValue(item, `code`, ""),
                label: getValue(item, `name`, ""),
              }))}
              value={getValue(requestFour, `country`, "")}
              selectKey={"value"}
              name={"Country"}
              validator={reqFourValidator}
              required
              hideLabel
            />
          </div>
        </>
      ) : steps === 5 ? (
        <>
          <div className="signup-search-tooltip">
            <SearchToolTip
              placeholder={`Select Currency`}
              onChange={(e: object) =>
                handleSelectChange(e, "currency", requestFive, setRequestFive)
              }
              label={`label`}
              data={currencyList}
              value={getValue(requestFive, `currency`, "")}
              selectKey={"value"}
              name={"Currency"}
              validator={reqFiveValidator}
              hideLabel
              hideBorder={true}
            />
          </div>
          <div className="signup-search-tooltip">
            <SearchToolTip
              placeholder={`Select Time Zone`}
              onChange={(e: object) =>
                handleSelectChange(e, "time_zone", requestFive, setRequestFive)
              }
              label={`label`}
              data={timezones}
              value={getValue(requestFive, `time_zone`, "")}
              selectKey={"value"}
              name={"Time Zone"}
              validator={reqFiveValidator}
              hideLabel
              hideBorder={true}
            />
          </div>
        </>
      ) : steps === 6 ? (
        <>
          <div className="signup-search-number-input">
            <PhoneInput
              key={requestFour.country}
              defaultCountry={requestFour.country.toLowerCase() || "in"}
              value={getValue(requestSix, `phone_no`, "")}
              onChange={(e: any) => {
                setRequestSix({
                  ...requestSix,
                  phone_no: e,
                });
                if (isValidPhoneNumber(e, requestFour.country)) {
                  reqSixValidator.current.hideMessageFor("Phone Number");
                }
              }}
              placeholder={"Enter Phone Number"}
              className=""
            />
            {reqSixValidator &&
              reqSixValidator.current &&
              reqSixValidator.current.message(
                "Phone Number",
                isValidPhoneNumber(
                  getValue(requestSix, `phone_no`, ""),
                  requestFour.country
                )
                  ? getValue(requestSix, `phone_no`, "")
                  : "",
                "required"
              ) && (
                <p className={"form-error"}>
                  {reqSixValidator.current.message(
                    "Phone Number",
                    isValidPhoneNumber(
                      getValue(requestSix, `phone_no`, ""),
                      requestFour.country
                    )
                      ? getValue(requestSix, `phone_no`, "")
                      : "",
                    "required"
                  )}
                </p>
              )}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default SignupAllSteps;
