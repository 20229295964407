import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllSettingsTags = (queryRequest: string) =>
  get(`${config.API_URL}/tags?${queryRequest}`);

export const getSpecificSettingsTag = (id: string) =>
  get(`${config.API_URL}/tags/${id}`);

export const listAllSettingsTags = (queryRequest: string) =>
  get(`${config.API_URL}/tags/list?${queryRequest}`);

export const createSettingsTag = (payload: object) =>
  post(`${config.API_URL}/tags`, payload);

export const updateSettingsTag = (id: string, payload: object) =>
  patch(`${config.API_URL}/tags/${id}`, payload);

export const updateSettingsTagStatus = (payload: object) =>
  patch(`${config.API_URL}/tags/status`, payload);

export const deleteSettingsTag = (id: string) =>
  Delete(`${config.API_URL}/tags/${id}`);
