import React, { useRef } from "react";
import { Button, Divider, Input, Modal, Select } from "antd";
import type { InputRef } from "antd";
import { getValue } from "@utils/lodash";
import { ChevronsUpDown, Plus } from "lucide-react";

const SelectDropdown = (props: any) => {
  const {
    placeholder,
    data,
    value,
    selectKey,
    nameKey,
    handleAddPipeline,
    isLoading,
    onSelect,
    isModalOpen,
    setIsModalOpen,
    name,
    setName,
    onAdd,
  } = props;
  const inputRef = useRef<InputRef>(null);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const findLabel = (value: string) => {
    let info =
      getValue(data, `length`, 0) > 0
        ? data.find(
            (item: object) => getValue(item, `[${selectKey}]`, "") === value
          )
        : {};
    return getValue(info, `label`, "");
  };

  const handleSelect = (value: string, option: any) => {
    onSelect(option);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Select
        // style={{ width: 280, height: 40 }}
        placeholder={`Select ${placeholder}`}
        value={findLabel(value) ? findLabel(value) : ""}
        onChange={handleSelect}
        bordered={false}
        suffixIcon={<ChevronsUpDown size={16} />}
        dropdownStyle={{ minWidth: 280 }}
        dropdownRender={(menu) => {
          return isLoading ? (
            <p className="small_text__12 text-center p-3">Loading...</p>
          ) : (
            <>
              <p className="py-1 px-3 small_text__12">List</p>
              <div
                className="p-1"
                // onClick={() => onSelect()}
              >
                {menu}
              </div>
              <Divider style={{ margin: "4px 0" }} />
              <div className="w-100 p-1">
                <Button
                  type="text"
                  onClick={() => {
                    showModal();
                    setName("");
                  }}
                  icon={<Plus size={18} />}
                  className="w-100 d-flex justify-content-start"
                >
                  Create {nameKey}
                </Button>
              </div>
            </>
          );
        }}
        options={data}
      />

      <Modal
        title={`New ${nameKey}`}
        open={isModalOpen}
        onOk={() => {
          if (name.trim()) {
            if (handleAddPipeline) {
              handleAddPipeline(name);
              handleCancel();
            }
            if (onAdd) {
              onAdd(name);
              handleCancel();
            }
          }
        }}
        onCancel={handleCancel}
        okText="Create"
        okButtonProps={{ disabled: !name }}
      >
        <p>
          Create a new data list for different product lines or business needs.
          This record table can be assigned to users, with access managed as
          needed.
        </p>
        <label className="mt-4">{nameKey}</label>
        <Input
          placeholder={`Enter ${placeholder} Name`}
          ref={inputRef}
          value={name}
          onChange={onNameChange}
          onKeyDown={(e) => e.stopPropagation()}
          size="large"
          className="mt-2 mb-3"
        />
      </Modal>
    </>
  );
};

export default SelectDropdown;
