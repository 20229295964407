import {
  Bot,
  Component,
  Gauge,
  LayoutPanelTop,
  ListTodo,
  Mail,
  MessageSquareShare,
  Plug2,
  RadioTower,
  UserRoundCog,
  UserRoundPlus,
} from "lucide-react";
import { WhatsAppOutlined } from "@ant-design/icons";
import { Button, Collapse, CollapseProps } from "antd";
import { Link, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { getStartOfMonthDate } from "@common/date-helpers";

const PanelContent = ({ data, subTabPermissionList }: any) => (
  <div className="d-grid gap-4">
    {data.map((item: any, index: any) => {
      if (subTabPermissionList.includes(item.permission)) {
        return (
          <div
            key={index}
            className="d-flex justify-content-between gap-5 my-1"
          >
            <div className="d-flex justify-content-center align-items-center gap-3">
              <div
                style={{
                  borderRadius: "50%",
                  width: "40px",
                  height: "40px",
                  background: item.backgroundColor,
                }}
                className="d-flex justify-content-center align-items-center"
              >
                {item.icon}
              </div>
              <div>
                <h6 className="header_text__16">{item.heading}</h6>
                <p className="small_text__13">{item.description}</p>
              </div>
            </div>
            <Button>
              <Link to={item.link}>{item.linkName}</Link>
            </Button>
          </div>
        );
      }
    })}
  </div>
);

const ConnectPages = ({ tabPermissionList, subTabPermissionList }: any) => {
  const params = useParams();
  let date_range = getStartOfMonthDate();

  const crmData = [
    {
      icon: <UserRoundPlus color="#c08c41" size={18} />,
      backgroundColor: "#FEFCBF",
      heading: "Invite your sales agents to your CRM",
      description:
        "Easily onboard your agents to Appzo for effective collaboration and handling customer conversations.",
      link: `/${getValue(params, "orgId", "")}/settings/general/users`,
      linkName: "Invite",
      permission: "settings_organisation_users",
    },
    {
      icon: <LayoutPanelTop size={18} color="#2F855A" />,
      backgroundColor: "#C6F6D5",
      heading: "Organize Your Lead and Contact Forms with Custom Fields",
      description:
        "Customize your lead and contact forms by adding tailored fields to capture the exact information you need.",
      link: `/${getValue(params, "orgId", "")}/broadcast?activeTab=2&page_no=1`,
      linkName: "Create",
      permission: "settings_customisation_modules_and_fields",
    },
    {
      icon: <ListTodo size={18} color="#C05621" />,
      backgroundColor: "#FEEBC8",
      heading: "Manage Your Daily and Recurring Tasks Efficiently",
      description:
        "Easily add and organize your daily or recurring tasks to stay on top of your schedule and improve productivity.",
      link: `/${getValue(params, "orgId", "")}/crm/tasks`,
      linkName: "Create",
      permission: "crm_tasks",
    },
  ];

  const leadConversion = [
    {
      icon: <UserRoundPlus color="#c08c41" size={18} />,
      backgroundColor: "#FEFCBF",
      heading: "Invite your sales agents",
      description:
        "Easily onboard your agents to Appzo for effective collaboration and handling customer conversations.",
      link: `/${getValue(params, "orgId", "")}/settings/general/users`,
      linkName: "Invite",
      permission: "settings_organisation_users",
    },
    {
      icon: <RadioTower color="#2b6cb0" size={18} />,
      backgroundColor: "#bee3f8",
      heading: "Create and get your message template approved from Meta",
      description:
        " Design and submit your message template for Meta's approval, including variables, media, and more.",
      link: `/${getValue(params, "orgId", "")}/broadcast?activeTab=2&page_no=1`,
      linkName: "Create",
      permission: "campaigns_whatsapp_broadcast",
    },
    {
      icon: <Bot color="#00A3C4" size={18} />,
      backgroundColor: "#C4F1F9",
      heading: "Build a Web Bot or WhatsApp Bot to automatically qualify leads",
      description:
        "Develop an intelligent bot to screen and qualify leads automatically.",
      link: `/${getValue(params, "orgId", "")}/chatbot`,
      linkName: "Set-up",
      permission: "chatbot",
    },
    {
      icon: <LayoutPanelTop size={18} color="#2F855A" />,
      backgroundColor: "#C6F6D5",
      heading:
        "Create a Custom Website Form to Capture Leads from Your Landing Page",
      description:
        "Design and embed a form on your landing page to seamlessly capture valuable leads and customer information.",
      link: `/${getValue(params, "orgId", "")}/settings/crm/forms`,
      linkName: "Build",
      permission: "settings_marketing_web_forms",
    },
    {
      icon: <Plug2 size={18} color="#6B46C1" />,
      backgroundColor: "#E9D8FD",
      heading: "Capture Leads from Meta Ads, Instagram, and Facebook",
      description:
        "Automatically gather and manage leads from your Meta ads, Instagram, and Facebook campaigns directly into your CRM.",
      link: `/${getValue(params, "orgId", "")}/settings/crm/ads`,
      linkName: "Integrate",
      permission: "settings_marketing_ad_leads",
    },
  ];

  const marketingCampaigns = [
    {
      icon: <RadioTower color="#2b6cb0" size={18} />,
      backgroundColor: "#bee3f8",
      heading: "Create and get your message template approved from Meta",
      description:
        "Design and submit your offers and promotions as a message template for Meta's approval, including variables, media, and more.",
      link: `/${getValue(params, "orgId", "")}/broadcast?activeTab=2&page_no=1`,
      linkName: "Create",
      permission: "campaigns_whatsapp_broadcast",
    },
    {
      icon: <WhatsAppOutlined style={{ color: "#2F855A" }} size={18} />,
      backgroundColor: "#C6F6D5",
      heading: "First WhatsApp Broadcast Campaign",
      description:
        "Launch your campaign and send messages in bulk to your customers.",
      link: `/${getValue(params, "orgId", "")}/broadcast`,
      linkName: "Schedule",
      permission: "campaigns_whatsapp_broadcast",
    },
    {
      icon: <Bot size={18} color="#C05621" />,
      backgroundColor: "#FEEBC8",
      heading: "Create a bot to handle response of your campaigns",
      description:
        "Develop a bot to manage responses from your campaigns and hand over qualified customers to your team.",
      link: `/${getValue(params, "orgId", "")}/chatbot`,
      linkName: "Build",
      permission: "chatbot",
    },
    {
      icon: <WhatsAppOutlined style={{ color: "#2F855A" }} size={18} />,
      backgroundColor: "#C6F6D5",
      heading: "Add WhatsApp Widget to your Website",
      description:
        "Incorporate a WhatsApp widget into your website to drive traffic to your WhatsApp number.",
      link: "https://www.appzo.ai/generate-whatsapp-button",
      linkName: "Add",
      permission: "chatbot",
    },
  ];

  const automateWorkflows = [
    {
      icon: <MessageSquareShare size={18} color="#2F855A" />,
      backgroundColor: "#C6F6D5",
      heading: "Set Up Automatic WhatsApp Alerts for Customers",
      description:
        "Automatically send personalized WhatsApp messages, such as “Thank you for calling” or “Thanks for your interest,” whenever a customer is added or updated in the CRM.",
      link: `/${getValue(
        params,
        "orgId",
        ""
      )}/settings/chat/messages?active_tab=3&page_no=1`,
      linkName: "Create",
      permission: "settings_inbox_whatsapp",
    },
    {
      icon: <Plug2 size={18} color="#6B46C1" />,
      backgroundColor: "#E9D8FD",
      heading: "Create Intelligent Workflows in Three Easy Steps",
      description:
        "Build seamless workflows that automatically create new records or update field values with minimal effort.",
      link: `/${getValue(params, "orgId", "")}/settings/crm/workflows`,
      linkName: "Create",
      permission: "settings_automation_workflows",
    },
    {
      icon: <Mail size={18} color="#c08c41" />,
      backgroundColor: "#FEFCBF",
      heading: "Set Up Automatic Email Alerts for Key Updates",
      description:
        "Trigger email notifications when specific statuses change or field values match during the creation or update of records.",
      link: `/${getValue(params, "orgId", "")}/settings/crm/workflows`,
      linkName: "Set-up",
      permission: "settings_templates_email",
    },
  ];

  const universalInbox = [
    {
      icon: <WhatsAppOutlined style={{ color: "#2F855A" }} size={18} />,
      backgroundColor: "#C6F6D5",
      heading: "WhatsApp Inbox for Team Collaboration",
      description:
        "Seamlessly allocate chats among team members, update the status of each conversation, and take over bot interactions when needed.",
      link: `/${getValue(params, "orgId", "")}/live-chat`,
      linkName: "Chat",
      permission: "universal_inbox",
    },
    {
      icon: <MessageSquareShare size={18} color="#2F855A" />,
      backgroundColor: "#C6F6D5",
      heading: "Auto-Responses for Instant WhatsApp Replies",
      description:
        "Activate automatic WhatsApp replies based on keyword detection to ensure timely responses to customer inquiries.",
      link: `/${getValue(
        params,
        "orgId",
        ""
      )}/settings/chat/messages?active_tab=3&page_no=1`,
      linkName: "Create",
      permission: "settings_inbox_whatsapp",
    },
  ];

  const workspaceManagement = [
    {
      icon: <UserRoundPlus color="#c08c41" size={18} />,
      backgroundColor: "#FEFCBF",
      heading: "Invite Your Team Members to Collaborate",
      description:
        "Easily onboard your team members to Appzo for seamless teamwork and customer management.",
      link: `/${getValue(params, "orgId", "")}/settings/general/users`,
      linkName: "Invite",
      permission: "settings_organisation_users",
    },
    {
      icon: <UserRoundCog size={18} color="#2b6cb0" />,
      backgroundColor: "#bee3f8",
      heading: "Set Permissions and Assign Roles to Team Members",
      description:
        "Control access by setting custom permissions and assigning roles tailored to each team member’s responsibilities.",
      link: `/${getValue(params, "orgId", "")}/settings/general/roles`,
      linkName: "Assign",
      permission: "settings_organisation_roles",
    },
    {
      icon: <Component size={18} color="#B83280" />,
      backgroundColor: "#FED7E2",
      heading: "Update Your Branding Across Appzo",
      description:
        "Personalize Appzo by updating your branding elements, ensuring consistency across all customer touchpoints.",
      link: `/${getValue(params, "orgId", "")}/settings/general/branding`,
      linkName: "Modify",
      permission: "settings_general_branding",
    },
    {
      icon: <Gauge size={18} color="#00A3C4" />,
      backgroundColor: "#C4F1F9",
      heading: "Manage Your Dashboard and Analyze Performance",
      description:
        "Keep track of key metrics with an intuitive dashboard and gain actionable insights through detailed analytics.",
      link: `/${getValue(
        params,
        "orgId",
        ""
      )}/dashboard?date_range=${date_range}&view=crm`,
      linkName: "View",
      permission: "dashboard_crm",
    },
  ];

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "Customise your CRM",
      children: (
        <PanelContent
          data={crmData}
          subTabPermissionList={subTabPermissionList}
        />
      ),
      id: "settings_organisation_users,settings_customisation_modules_and_fields,crm_tasks",
    },
    {
      key: "2",
      label: "Automate Lead Conversion",
      children: (
        <PanelContent
          data={leadConversion}
          subTabPermissionList={subTabPermissionList}
        />
      ),
      id: "settings_organisation_users,campaigns_whatsapp_broadcast,chatbot,settings_marketing_web_forms,settings_marketing_ad_leads",
    },
    {
      key: "3",
      label: "Personalised Marketing Campaigns",
      children: (
        <PanelContent
          data={marketingCampaigns}
          subTabPermissionList={subTabPermissionList}
        />
      ),
      id: "campaigns_whatsapp_broadcast,chatbot",
    },
    {
      key: "4",
      label: "Automate workflows",
      children: (
        <PanelContent
          data={automateWorkflows}
          subTabPermissionList={subTabPermissionList}
        />
      ),
      id: "settings_inbox_whatsapp,settings_automation_workflows,settings_templates_email",
    },
    {
      key: "5",
      label: "Universal Inbox",
      children: (
        <PanelContent
          data={universalInbox}
          subTabPermissionList={subTabPermissionList}
        />
      ),
      id: "universal_inbox,settings_inbox_whatsapp",
    },
    {
      key: "6",
      label: "Workspace Management",
      children: (
        <PanelContent
          data={workspaceManagement}
          subTabPermissionList={subTabPermissionList}
        />
      ),
      id: "settings_organisation_users,settings_organisation_roles,settings_general_branding,dashboard_crm",
    },
  ];

  const hasPermission = (id: string) => {
    return id
      .split(",")
      .some((permission) => subTabPermissionList.includes(permission));
  };

  const filteredItems = items.filter(
    (item) => item.id && hasPermission(item.id)
  );

  return (
    <Collapse
      accordion
      size="large"
      items={filteredItems}
      defaultActiveKey="1"
      className="home-accordion"
      style={{ backgroundColor: "#fff" }}
    />
  );
};

export default ConnectPages;
