import { Button, Typography, message } from "antd";
import { CopyOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import { CopyToClipboard } from "@common/text-helpers";
import CopySvgComponents from "@assets/svg/copy";
import { Files, SquareArrowOutUpRight } from "lucide-react";

const { Title, Text } = Typography;

export default function AIShareURL() {
  const params = useParams();
  const chatbotUrl = `https://chatbot-iframe.onrender.com/?id=${getValue(
    params,
    `chatbotId`,
    ""
  )}`;

  return (
    <div className="share-container">
      <h6 className="header_text__20 mb-4">www.appzo.ai</h6>

      <p className="small_text__14 mb-3">
        To add the chatbot any where on your website, add this iframe to your
        html code
      </p>

      <div className="d-flex align-items-center gap-3">
        <div className="shareable-code m-0">
          <code className="small_text__14 ">{chatbotUrl}</code>
        </div>
        <div className="action-buttons">
          <Button
            icon={<SquareArrowOutUpRight size={16} />}
            onClick={() => window.open(chatbotUrl, "_blank")}
            className="action-button"
          ></Button>
          <Button
            icon={<Files size={16} />}
            onClick={() => CopyToClipboard(chatbotUrl)}
            className="action-button"
          ></Button>
        </div>
      </div>
    </div>
  );
}
