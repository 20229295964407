import LoginLayout from "layout/LoginLayout";
import SupportForm from "./supportForm";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRef, useState } from "react";
import { getValue } from "@utils/lodash";
import SimpleReactValidator from "simple-react-validator";
import { signInWithPassword } from "services/auth.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  listOfOrganisations,
  listOfOrganisationsToken,
} from "@services/organisations.service";
import { getStartOfMonthDate } from "@common/date-helpers";
import { useStateContext } from "@context/dataContext";
import { allParams } from "@common/url-params-helper";

export default function LoginPage() {
  const navigate = useNavigate();
  const params = useParams();
  const simpleValidator = useRef(new SimpleReactValidator());
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const { handleChangeOrgainization } = useStateContext();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [request, setRequest] = useState({
    identifier_type: "EMAIL",
    identifier: "",
    password: "",
  });
  const [, forceUpdate] = useState(0);

  /* -------------------------------------------------------------------------- */
  /*                                  API Section                               */
  /* -------------------------------------------------------------------------- */
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setSubmitLoading(true);
      try {
        let resp = await signInWithPassword(request);
        if (resp) {
          localStorage.setItem(
            "accessToken",
            getValue(resp, `data.access_token`, "")
          );
          localStorage.setItem("timezone", getValue(resp, `data.timezone`, ""));
          if (getValue(UrlParams, `invite_token`, "")) {
            window.location.href = `/organisations/join?invite_token=${getValue(
              UrlParams,
              `invite_token`,
              ""
            )}`;
          } else {
            getOrganisations(getValue(resp, `data.access_token`, ""));
          }
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  const getOrganisations = async (token: string) => {
    try {
      let resp = await listOfOrganisationsToken(token);
      if (resp) {
        if (getValue(resp, `data.length`, 0) === 0) {
          navigate(`/organisations/create?${allParams()}`);
        }
        if (getValue(resp, `data.length`, 0) === 1) {
          if (getValue(UrlParams, `path`, "") === "shared") {
            window.location.href = `/BTS-2024-exhibitor-directory?orgId=${getValue(
              resp,
              `data[${0}].code`,
              ""
            )}`;
          } else {
            handleNavigateOrg(getValue(resp, `data[${0}].code`, ""));
          }
        } else {
          window.location.href = `/organisations?${allParams()}`;
        }
      }
    } catch (error) {}
  };

  const handleNavigateOrg = (code: string) => {
    let date_range = getStartOfMonthDate();
    handleChangeOrgainization();
    // window.location.href = `/${code}/dashboard?date_range=${date_range}`;
    window.location.href = `/${code}/home?${allParams()}`;
  };

  const handleNavigate = () => {
    let payload = {
      ...UrlParams,
    };
    let queryRequest = QueryRequestHelper(payload);
    if (getValue(UrlParams, `token`, "")) {
      return `/user-signup/invite?${allParams()}`;
    } else {
      return `/signup?${allParams()}`;
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };
  return (
    <>
      <LoginLayout>
        <div
          className="d-flex justify-content-center align-items-baseline login-mobile-responsive height-adjust"
          onKeyDown={handleKeyDown}
        >
          <div className="login_card">
            <div className={`support-login-wrapper__details`}>
              {/* <div className="d-flex justify-content-center ">
                <a className={`mt-4`} href="https://appzo.ai/">
                  <img
                    src="/appzo-logo.svg"
                    className={`login-header-wrapper__logo-image`}
                    alt="appzo-logo"
                  />
                </a>
              </div> */}
              <h4 className={"header_text__26 text-center"}>
                Sign in to access Appzo
              </h4>

              <SupportForm
                request={request}
                loading={false}
                handleSubmit={handleSubmit}
                setRequest={setRequest}
                submitLoading={submitLoading}
                simpleValidator={simpleValidator}
              />
              <h6
                className={
                  "header_text__16 d-flex align-items-center justify-content-center mt-3 text-center"
                }
              >
                Don't have a Appzo account?
                <Link to={handleNavigate()} className="ms-1">
                  Sign Up Now
                </Link>
              </h6>
            </div>
          </div>
        </div>
      </LoginLayout>
    </>
  );
}
