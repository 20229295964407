import { formatString } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Button, Collapse, Drawer, Tag, Timeline } from "antd";
import { CollapseProps } from "antd/lib";
import { Flag, Settings, Zap } from "lucide-react";

const TemplateModal = ({
  isLoading,
  templateOpen,
  toggleTemplate,
  templates,
  navigate,
  params,
}: any) => {
  const items: CollapseProps["items"] = templates?.map(
    (template: any, index: number) => ({
      key: String(index + 1),
      label: (
        <button className="header_text__18 button-unset-all">
          {getValue(template, "name", "")}
        </button>
      ),
      children: (
        <div className="border-bottom pb-4 mb-2 ps-4">
          {getValue(template, "description", "") && (
            <p className="small_text__14">
              {getValue(template, "description", "No description provided")}
            </p>
          )}
          <div className="automation-template-card-wrapper">
            <div className="automation-template-card-header">
              Preview
              <Button
                type="primary"
                onClick={() =>
                  navigate(
                    `/${getValue(
                      params,
                      "orgId",
                      ""
                    )}/settings/helpdesk/tickets/edit/${getValue(
                      template,
                      "id",
                      ""
                    )}?type=${getValue(template, "type", "")}&template=true`
                  )
                }
              >
                Use Template
              </Button>
            </div>
            <div className="px-4 pb-3 pt-4 d-flex flex-column gap-3">
              <div>
                <h6 className="header_text__14 d-flex align-items-center gap-2 mb-2">
                  <Tag color="green" bordered={false}>
                    <Flag size={16} />
                  </Tag>
                  Events
                </h6>
                <Timeline
                  className="pb-0 ps-5"
                  items={
                    getValue(template, "summary_events.length", 0) > 0
                      ? getValue(template, "summary_events", []).map(
                          (item: any) => ({
                            children: (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: formatString(item),
                                }}
                                className="small_text__14 summary-conditions"
                                style={{ marginRight: "4px" }}
                              />
                            ),
                          })
                        )
                      : [
                          {
                            children: (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: formatString(
                                    getValue(template, "summary_name", "")
                                  ),
                                }}
                                className="small_text__14 summary-conditions"
                                style={{ marginRight: "4px" }}
                              />
                            ),
                          },
                        ]
                  }
                />
              </div>
              <div>
                <h6 className="header_text__14 mb-2 d-flex align-items-center gap-2">
                  <Tag color="orange" bordered={false}>
                    <Settings size={16} />
                  </Tag>
                  Conditions
                </h6>

                <Timeline
                  className="ps-5"
                  items={getValue(template, "summary_conditions", []).map(
                    (item: any) => ({
                      children: (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: formatString(item),
                          }}
                          className="small_text__14 summary-conditions"
                          style={{ marginRight: "4px" }}
                        />
                      ),
                    })
                  )}
                />
              </div>
              <div>
                <h6 className="header_text__14 mb-2 d-flex align-items-center gap-1">
                  <Tag color="blue" bordered={false}>
                    <Zap size={16} />
                  </Tag>
                  Actions
                </h6>
                <Timeline
                  className="ps-5"
                  items={getValue(template, "summary_actions", []).map(
                    (item: any) => ({
                      children: (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: formatString(item),
                          }}
                          className="small_text__14 summary-conditions"
                          style={{ marginRight: "4px" }}
                        />
                      ),
                    })
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      ),
    })
  );

  return (
    <Drawer
      title=" Templates"
      onClose={toggleTemplate}
      open={templateOpen}
      width={600}
      loading={isLoading}
    >
      <Collapse ghost items={items} defaultActiveKey={["1"]} />
    </Drawer>
  );
};

export default TemplateModal;
