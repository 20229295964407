import { convertCurrentDateToTime } from "@common/date-helpers";
import WhatsappMessageLoader from "@components/common/Loader/whatsapp-message-loader";
import { getValue } from "@utils/lodash";
import { Sticker as StickerIcon } from "lucide-react";
const StickerPreview = ({ item, getReadIcon, imageUrl }: any) => {
  return (
    <>
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="sticker"
          className="whatsapp_file"
          width={160}
          height={160}
        />
      ) : (
        <div className="whatsapp-sticker-loader">
          <WhatsappMessageLoader
            icon={<StickerIcon size={20} color="#7e7e7e" />}
          />
        </div>
      )}
      <p className="sticker-preview-time p-2 mt-1">
        {convertCurrentDateToTime(getValue(item, `created_at`, ""))}
        {getValue(item, "is_private", "") === false && (
          <span className="ms-2">{getReadIcon(item)}</span>
        )}
      </p>
    </>
  );
};

export default StickerPreview;
