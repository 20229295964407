import { getValue } from "@utils/lodash";
import React from "react";
import { Button, Modal, Select } from "antd";
import InputRuleForm from "@components/InputRuleForm/form";
import NewSearchSelect from "@components/custom/Dropdown/AntDropDown";

function WorkflowInfoPopup(props: any) {
  const {
    request,
    setRequest,
    allModules,
    getAllPipelineList,
    pipelineList,
    getTypes,
    simpleValidator,
  } = props;
  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      onOk={props.handleSubmit}
      title="Create New Workflow"
      onCancel={props.toggle}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            props.toggle();
            props.resetRequest();
          }}
          className="cancel__button"
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => props.handleSubmit()}
          className="submit__button"
          disabled={!getValue(request, `name`, "")}
        >
          Submit
        </Button>,
      ]}
    >
      <div className="p-2 d-grid gap-2">
        <div className="">
          <InputRuleForm
            inputType="TEXT"
            label={"Workflow Name"}
            placeholder="Enter Workflow Name"
            name={"name"}
            value={getValue(request, `name`, "")}
            onChange={(e: any) =>
              setRequest({
                ...request,
                name: getValue(e, `target.value`, ""),
              })
            }
            validator={simpleValidator}
            validLeft
            maxLength={50}
            mandatory
            required
          />
        </div>
        <NewSearchSelect
          label="Module"
          size="large"
          placeholder="Select Module"
          value={request.moduleId || null} // Direct access to moduleId
          onChange={(value: any, option: any) => {
            const selectedModule = allModules.find(
              (item: any) => getValue(item, "id", "") === value
            );
            if (selectedModule) {
              getAllPipelineList(value); // Assuming `value` is the module id
              setRequest({
                ...request,
                moduleId: value,
                moduleName: getValue(selectedModule, "api_name", ""),
              });
            } else {
              setRequest({
                ...request,
                moduleId: "",
                moduleName: "",
                pipelineId: "",
              });
            }
          }}
          options={allModules
            .filter(
              (item: any) =>
                getValue(item, "api_name", "") !== "calls" &&
                getValue(item, "api_name", "") !== "meetings" &&
                getValue(item, "api_name", "") !== "tickets" &&
                getValue(item, "api_name", "") !== "tasks"
            )
            .map((item: any) => ({
              value: getValue(item, "id", ""), // Value used for selection
              label: getValue(item, "label", ""), // Displayed label
            }))}
          className="w-100"
          validator={simpleValidator}
        />

        {/* {getTypes(getValue(request, `moduleName`, "")) !== "ACTIVITY" &&
            getValue(request, `moduleName`, "") !== "contacts" &&
            getValue(request, `moduleName`, "") !== "companies" && (
              <SearchToolTip
                width={450}
                name="Pipelines"
                data={pipelineList}
                label="label"
                selectKey={"id"}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    pipelineId: getValue(e, `id`, ""),
                  })
                }
                value={getValue(request, `pipelineId`, "")}
              />
            )} */}
        {/* {request.moduleId &&
          getTypes(getValue(request, `moduleName`, "")) !== "ACTIVITY" &&
          getValue(request, `moduleName`, "") !== "contacts" &&
          getValue(request, `moduleName`, "") !== "companies" && (
            <NewSearchSelect
              label="Pipeline"
              size="large"
              placeholder="Select Pipeline"
              value={getValue(request, `pipelineId`, "")}
              onChange={(value: any, option: any) => {
                setRequest({
                  ...request,
                  pipelineId: value,
                });
              }}
              options={pipelineList.map((item: any) => ({
                value: getValue(item, `id`, ""),
                label: getValue(item, `label`, ""),
              }))}
              className="w-100"
            />
          )} */}

        {/* <div className="mt-2">
              <InputRuleForm
                inputType="TEXTAREA"
                label={"Description"}
                placeholder="Enter Description"
                rows={4}
                name={"description"}
                value={getValue(request, `description`, "")}
                onChange={(e: any) =>
                  setRequest({
                    ...request,
                    description: getValue(e, `target.value`, ""),
                  })
                }
                //   validator={simpleValidator}
              />
            </div> */}
      </div>
    </Modal>
  );
}

export default WorkflowInfoPopup;
