import { memo, useCallback, useState } from "react";
import { deleteCategoryFolder } from "@services/solutions.service";
import { getValue } from "@utils/lodash";
import { Dropdown } from "antd";
import {
  ChevronRight,
  EllipsisVertical,
  Pencil,
  PlusCircle,
  Trash2,
} from "lucide-react";
import RenderArticles from "../Articles/RenderArticles";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";

const RenderFolders = ({
  category,
  selectedFolder,
  handleCreateArticle,
  handleFolderEdit,
  handleDeleteModal,
  articleRequest,
  selectedArticle,
  setPreviousArticle,
  setNextArticle,
}: any) => {
  const params = useParams();
  const [folderExpandedItems, setFolderExpandedItems] = useState<string[]>([
    selectedFolder,
  ]);

  const handleFolderAccordionChange = (expanded: string[]) => {
    setFolderExpandedItems(expanded);
  };

  const renderFolderMenu = useCallback(
    (categoryId: string, folderId: string, name: any) => [
      {
        key: "new",
        icon: <PlusCircle size={14} />,
        label: "New Article",
        onClick: () => handleCreateArticle(categoryId, folderId),
      },
      {
        key: "edit",
        icon: <Pencil size={14} />,
        label: "Edit",
        onClick: () => handleFolderEdit(folderId),
      },
      {
        key: "delete",
        icon: <Trash2 size={14} />,
        label: "Delete",
        onClick: () =>
          handleDeleteModal("Folder", name, deleteCategoryFolder, folderId),
      },
    ],
    [handleCreateArticle, handleFolderEdit]
  );

  const folders = getValue(category, "folders", []);
  return (
    <div className="folders-container" id="folder">
      {folders.length === 0 ? (
        <p className="small_text__13 pt-2 text-center">No folders available</p>
      ) : (
        <Accordion
          allowZeroExpanded
          allowMultipleExpanded
          onChange={handleFolderAccordionChange}
          preExpanded={[getValue(params, "folderId", "")]}
        >
          {folders.map((folder: any) => {
            const folderId = getValue(folder, "id", "");
            const folderName = getValue(folder, "name", "");
            const isFolderExpanded = folderExpandedItems.includes(folderId);
            return (
              <AccordionItem
                key={folderId}
                uuid={folderId}
                className={`folder ${selectedFolder ? "selected" : ""}`}
              >
                <AccordionItemHeading>
                  <AccordionItemButton className="solution-folder-card d-flex align-items-center justify-content-between gap-2">
                    <div className="d-flex align-items-center gap-2 header_text__14">
                      <motion.div
                        animate={{
                          rotate: isFolderExpanded ? 90 : 0,
                        }}
                        transition={{ ease: "linear", duration: 0.2 }}
                      >
                        <ChevronRight size={16} />
                      </motion.div>
                      {folderName}
                    </div>
                    <Dropdown
                      menu={{
                        items: renderFolderMenu(
                          getValue(category, "id", ""),
                          folderId,
                          folderName
                        ),
                      }}
                      className="more-options"
                    >
                      <EllipsisVertical size={14} className="cursor-pointer" />
                    </Dropdown>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <motion.div
                    key="article"
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.1, ease: "easeIn" }}
                  >
                    <RenderArticles
                      folder={folder}
                      category={category}
                      selectedFolder={selectedFolder}
                      articleRequest={articleRequest}
                      handleCreateArticle={handleCreateArticle}
                      selectedArticle={selectedArticle}
                      setPreviousArticle={setPreviousArticle}
                      setNextArticle={setNextArticle}
                    />
                  </motion.div>
                </AccordionItemPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      )}
    </div>
  );
};

export default memo(RenderFolders);
