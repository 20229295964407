import InfoSvgComponent from "@assets/svg/info";
import InputRuleForm from "@components/InputRuleForm/form";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import PropertyDetailsLayout from "@layouts/PropertyLayout/property-details-layout";
import PropertySettingsLayout from "@layouts/PropertyLayout/property-settings-layout";
import { Tooltip } from "react-tooltip";

const folioInputs = [
  {
    label: "Booking folio prefix",
    tooltipId: "dashboard",
    tooltipContent: "The prefix that you want your booking folios to have",
    input: "string",
    placeholder: "booking folio prefix",
  },
  {
    label: "Booking folio sequence start",
    tooltipId: "Sequence_Start",
    tooltipContent: "The starting sequence number for the bookings folios",
    input: "string",
    placeholder: "booking folio sequence start",
  },
  {
    label: "Booking folio suffix",
    tooltipId: "folio_suffix",
    tooltipContent: "The suffix that you want your booking folios to have",
    input: "string",
    placeholder: "booking folio suffix",
  },
  {
    label: "Folio Display Phone Number",
    tooltipId: "Phone_Number",
    tooltipContent:
      "This is the contact phone number that will be displayed in all the guest communications including folio and registration card.",
    input: "number",
    placeholder: "phone number",
  },
  {
    label: "Folio Display Email",
    tooltipId: "email",
    tooltipContent:
      "This is the contact email that will be displayed in all the guest communications including folio and registration card.",
    input: "string",
    placeholder: "Email address",
  },
  {
    label: "Booking Folio Header",
    tooltipId: "header",
    tooltipContent:
      "This is the header that will show on the booking folios. For e.g 'Tax Invoice'",
    input: "string",
    placeholder: "booking folio header",
  },
  {
    label: "Choose the folio header color",
    tooltipId: "header",
    tooltipContent:
      "Black color will be selected by default. It will affect the PDF(s) for invoices' header.",
    input: "string",
    placeholder: "folio header color",
  },
  {
    label: "Choose the folio content color",
    tooltipId: "content",
    tooltipContent:
      "Black color will be selected by default. It will affect the PDF(s) for invoices' content.",
    input: "string",
    placeholder: "folio content color",
  },
  {
    label: "Choose the logo size",
    tooltipId: "logo_size",
    tooltipContent: "Choose the logo width when downloading the invoices.(px)",
    input: "number",
    placeholder: "logo size (in px)",
  },
  {
    label: "Choose the invoice PDF font size",
    tooltipId: "font_size",
    tooltipContent:
      "Choose the content size when downloading the types of invoices.",
    input: "number",
    placeholder: "font size (in px)",
  },
  {
    label: "Choose the margin top",
    tooltipId: "margin_top",
    tooltipContent:
      "Choose the margin top when downloading the types of invoices.",
    input: "number",
    placeholder: "margin top (in px)",
  },
  {
    label: "Invoice date rule",
    tooltipId: "date_rule",
    tooltipContent:
      "Configure what type of date you want to display while printing the invoice.",
    input: "search",
    placeholder: "Select date rule",
  },
  {
    label: "Choose the reg. card PDF font size",
    tooltipId: "pdf_font_size",
    tooltipContent:
      "Choose the content size when downloading the reg. card. (in px)",
    input: "number",
    placeholder: "Select cancellation policy",
  },
  {
    label: "Generate custom id for cancelled and no show bookings",
    tooltipId: "Generate_custom_id",
    tooltipContent:
      "Configure YES to generate custom invoice id for bookings with payment and having cancelled or no show as booking status.",
    input: "switch",
    placeholder: "Select yes or no",
  },
  {
    label: "Show cancellation policy in folio",
    tooltipId: "Show_cancellation",
    tooltipContent: "Configure to YES to show cancellation policy in folio.",
    input: "switch",
    placeholder: "Select yes or no",
  },
  {
    label: "Show signature",
    tooltipId: "Show_Signature",
    tooltipContent:
      "Configure to YES to show hotel signature and guest signature in folio and registration card. NO to hide in both.",
    input: "switch",
    placeholder: "Select yes or no",
  },
  {
    label: "Folio logo",
    tooltipId: "Folio_logo",
    tooltipContent:
      "Configure to YES to show hotel logo in folio. NO to not show.",
    input: "switch",
    placeholder: "Select yes or no",
  },
  {
    label: "Folio Terms and Condition",
    tooltipId: "Folio_tc",
    tooltipContent:
      "Configure to YES to show terms and condition in folio. NO to not show.",
    input: "switch",
    placeholder: "Select yes or no",
  },

  {
    label: "Hide Appzo BookingID",
    tooltipId: "hide_id",
    tooltipContent:
      "When set to YES, it will hide Appzo Booking ID in reservation card and folio pdf prints. Useful when you only want to show custom folio number",
    input: "switch",
    placeholder: "Select yes or no",
  },
  {
    label: "Hide room booking charges in registration card for OTA bookings",
    tooltipId: "hide_room_booking_charge",
    tooltipContent:
      "When set to YES, it will show Room Charges to 0 in guest registration card for OTA bookings",
    input: "switch",
    placeholder: "Select yes or no",
  },
  {
    label: "Show terms on guest emails",
    tooltipId: "terms_on_email",
    tooltipContent:
      "Configure to YES to show hotel terms and conditions on guest booking confirmation email",
    input: "switch",
    placeholder: "Select yes or no",
  },
  {
    label: "Show actual checkin and checkout",
    tooltipId: "Show_actual_time",
    tooltipContent: "Show actual checkin and checkout on the folio",
    input: "switch",
    placeholder: "Select yes or no",
  },

  {
    label: "Show cancellation policy on registration card",
    tooltipId: "cancellation_policy",
    tooltipContent:
      "Configure YES to show cancellation policy on the registration card.",
    input: "switch",
    placeholder: "Select cancellation policy",
  },
  {
    label: "Show terms & conditions on registration card",
    tooltipId: "terms_condition",
    tooltipContent:
      "Configure YES to show terms and conditions on the registration card.",
    input: "switch",
    placeholder: "Select cancellation policy",
  },
  {
    label: "Show room tariff on registration card",
    input: "switch",
  },
  {
    label: "Show registration card number",
    input: "switch",
  },
];

const FolioConfiguration = (props: any) => {
  return (
    <PropertyDetailsLayout>
      <PropertySettingsLayout>
        <div className=" w-100 overflow-y-auto">
          <div className="d-flex justify-content-between align-items-center border-bottom pb-3">
            <h3 className="header_text__20">Folio configuration</h3>
          </div>
          <p className="my-3 small_text__16">
            This page allows customizing the appearance of PDF or printouts of
            folio with settings like font size, page layout, headers, footers,
            and color schemes. It helps create professional looking and visually
            appleaing documents.
          </p>
        </div>
        <div
          className="grid-container row-gap-3 column-gap-3  w-100"
          style={{ gridTemplateColumns: "repeat(3, 1fr)" }}
        >
          {folioInputs.map((details, index) => (
            <div
              key={index}
              className="d-flex flex-column justify-content-center"
            >
              <div className=" form-label d-flex align-items-center justify-content-between gap-2">
                {details.label}
                <div className="d-flex align-items-center gap-2">
                  {details.tooltipId && (
                    <div
                      data-tooltip-id={details.tooltipId}
                      data-tooltip-content={details.tooltipContent}
                    >
                      <InfoSvgComponent size="18" color="#f1bb00" />
                      <Tooltip id={details.tooltipId} className="tooltip" />
                    </div>
                  )}
                  {details.input === "switch" && (
                    <label className={"switch mt-2"}>
                      <input type="checkbox" />
                      <span className={`${"slider"} ${`round`} d-flex`}></span>
                    </label>
                  )}
                </div>
              </div>
              {details.input === "string" && (
                <InputRuleForm
                  inputType="TEXT"
                  name="name"
                  //   value={getValue(request, `name`, "")}
                  placeholder={`Enter ${details.placeholder}`}
                  hideFormLabel
                />
              )}

              {details.input === "search" && (
                <SearchToolTip placeholder={`${details.placeholder}`} />
              )}
              {details.input === "number" && (
                <InputRuleForm
                  inputType="NUMBER"
                  name="number"
                  //   value={getValue(request, `name`, "")}
                  placeholder={`Enter ${details.placeholder}`}
                  hideFormLabel
                  marginZero
                />
              )}
            </div>
          ))}
        </div>
      </PropertySettingsLayout>
    </PropertyDetailsLayout>
  );
};

export default FolioConfiguration;
