import { useParams, NavLink, useLocation, Link } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { getStartOfMonthDate } from "@common/date-helpers";
import { useStateContext } from "@context/dataContext";
import { Menu, Badge, Button, Tooltip, Divider, ConfigProvider } from "antd";
import { usePermissionContext } from "@context/permissionContext";
import { useSocketContext } from "@context/socketContext";
import {
  Bookmark,
  BookOpen,
  Bot,
  LayoutDashboard,
  MapPinHouse,
  Megaphone,
  MessageSquare,
  Moon,
  PanelRightClose,
  PanelRightOpen,
  Star,
  Sun,
  Ticket,
  Users,
} from "lucide-react";
import CustomDropdown from "./customDropdown";
import { useEffect } from "react";
import { deleteBookmark } from "@services/bookmark.service";

interface Module {
  label_singular: string;
  label: string;
  link: string;
  type: string;
  linkText: string;
  linkUrl: string;
  id: string;
}

export default function VerticalNav(props: any) {
  const { isOpen, setIsOpen } = props;
  const params = useParams();
  const location = useLocation();
  const {
    modules,
    currentCRMUrl,
    themeColor,
    bookmarkedModules,
    appTheme,
    setAppTheme,
    refreshBookmarks,
  } = useStateContext();
  const { totalUnreadInboxCount, rawStatusCounts } = useSocketContext();
  let date_range = getStartOfMonthDate();

  const { tabPermissionList, subTabPermissionList } = usePermissionContext();
  const navItems = [
    {
      name: "Dashboard",
      icon: <LayoutDashboard size={18} />,
      link: `${
        getValue(params, `orgId`, "")
          ? `/${getValue(
              params,
              `orgId`,
              ""
            )}/dashboard?date_range=${date_range}&view=crm`
          : "#"
      }`,
      permTab: "dashboard",
    },
    {
      name: "CRM",
      icon: <Users size={18} />,
      link: `/${getValue(params, "orgId", "")}/crm/leads`,
      isDropdown: true,
      isNewFeature: false,
      permTab: "crm",
    },
    {
      name: "Tickets",
      icon: <Ticket size={18} />,
      link: `/${getValue(params, "orgId", "")}/tickets`,
      isDropdown: false,
      isNewFeature: false,
      permTab: "helpdesk",
    },
    {
      name: "Knowledge Base",
      icon: <BookOpen size={18} />,
      link: `/${getValue(params, "orgId", "")}/solutions`,
      isDropdown: false,
      isNewFeature: false,
      permTab: "solutions",
    },
    {
      name: "Broadcast",
      icon: <Megaphone size={18} />,
      link: `/${getValue(params, "orgId", "")}/broadcast`,
      permTab: "campaigns",
    },
    {
      name: "Live Chat",
      icon: <MessageSquare size={18} />,
      link: `/${getValue(params, "orgId", "")}/live-chat`,
      permTab: "universal_inbox",
    },
    {
      name: "Ai Agents",
      icon: <Bot size={18} />,
      link: `/${getValue(params, "orgId", "")}/chatbot`,
      isNewFeature: false,
      permTab: "chatbot",
    },
    ...(getValue(params, "orgId", "") === "f8f02ee2ac5d" ||
    getValue(params, "orgId", "") === "5ee8131e8b38"
      ? [
          {
            name: "Properties",
            icon: <MapPinHouse size={18} />,
            link: `/${getValue(params, "orgId", "")}/property`,
            isNewFeature: false,
            permTab: "chatbot",
          },
        ]
      : []),
  ];

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--global-child-width",
      isOpen ? "250px" : "64px"
    );
  }, [isOpen]);

  const renderDropdownMenu = (allItems: Module[]) => {
    let items =
      getValue(allItems, `length`, 0) > 0
        ? allItems.filter(
            (item: object) =>
              getValue(item, `api_name`, "") !== "calls" &&
              getValue(item, `api_name`, "") !== "meetings"
          )
        : [];
    const pipelineItems = items.filter(
      (item: any) =>
        item.type === "PIPELINE" &&
        subTabPermissionList.includes(
          `crm_${
            getValue(item, `api_name`, "") === "companies"
              ? "company"
              : getValue(item, `api_name`, "")
          }`
        )
    );
    const activityItems = items.filter(
      (item: any) =>
        item.type === "ACTIVITY" &&
        subTabPermissionList.includes(`crm_${getValue(item, `api_name`, "")}`)
    );
    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: themeColor ? themeColor : "#ab4fee",
            borderRadius: 6,
            controlHeight: 40,
          },
          components: {
            Menu: {
              itemHeight: 40,
              itemBorderRadius: 8,
              itemColor: "#1F1F1F",
              // itemHoverColor: themeColor ? themeColor : "#ab4fee",
              // itemHoverBg: themeColor ? themeColor : "#ab4fee",
              itemSelectedBg: themeColor ? themeColor : "#ab4fee",
            },
          },
        }}
      >
        <Menu className="submenu-dropdown">
          {pipelineItems.length > 0 && (
            <>
              <h6 className="ps-3 small_text__13 pt-2">CRM</h6>
              <Menu.ItemGroup className="navbar-dropdown-menu">
                {pipelineItems.map((dropdownItem) => {
                  return (
                    <Menu.Item
                      key={dropdownItem.id}
                      className={
                        location.pathname === dropdownItem.linkUrl
                          ? "sidebar-submenu-active"
                          : "sidebar-submenu"
                      }
                    >
                      <NavLink
                        to={dropdownItem.linkUrl}
                        className={`subnav_dropdown_item ${
                          location.pathname === dropdownItem.linkUrl
                            ? "active"
                            : ""
                        }`}
                      >
                        {dropdownItem.linkText}
                      </NavLink>
                    </Menu.Item>
                  );
                })}
              </Menu.ItemGroup>
              <Menu.Divider className="navbar-dropwdown-divider" />
            </>
          )}
          {activityItems.length > 0 && (
            <Menu.ItemGroup className="navbar-dropdown-menu">
              {activityItems.map((dropdownItem) => (
                <Menu.Item
                  key={dropdownItem.id}
                  className={
                    location.pathname === dropdownItem.linkUrl
                      ? "sidebar-submenu-active"
                      : "sidebar-submenu"
                  }
                >
                  <NavLink
                    to={dropdownItem.linkUrl}
                    className={`subnav_dropdown_item ${
                      location.pathname === dropdownItem.linkUrl ? "active" : ""
                    }`}
                  >
                    {dropdownItem.linkText}
                  </NavLink>
                </Menu.Item>
              ))}
            </Menu.ItemGroup>
          )}
        </Menu>
      </ConfigProvider>
    );
  };

  const handleSidebarToggle = () => {
    setIsOpen(!isOpen);
  };

  const removeBookmark = async (id: string) => {
    let resp = await deleteBookmark(id);
    if (resp) {
      refreshBookmarks();
    }
  };

  return (
    <header
      className="side-header-wrapper"
      style={{
        minWidth: isOpen ? "250px" : "64px",
      }}
    >
      <nav
        className={` ${
          isOpen ? "sidebar_items__wrapper-open" : "sidebar_items__wrapper "
        }`}
      >
        <div className={"bookmark-item"}>
          <CustomDropdown
            trigger={
              <div className={`d-flex align-items-center gap-2`}>
                <Star size={18} />
                {isOpen && <div>Bookmarks</div>}
              </div>
            }
            bookmarkDropdown
            menu={
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: themeColor ? themeColor : "#ab4fee",
                    borderRadius: 6,
                    controlHeight: 40,
                  },
                  components: {
                    Menu: {
                      itemHeight: 40,
                      itemBorderRadius: 8,
                      itemColor: "#1F1F1F",
                      itemSelectedBg: themeColor ? themeColor : "#ab4fee",
                    },
                  },
                }}
              >
                <Menu className="submenu-dropdown">
                  <Menu.ItemGroup className="navbar-dropdown-menu">
                    <h6
                      className="ps-3 ms-1 header_text__13 pt-2"
                      style={{
                        maxWidth: 176,
                      }}
                    >
                      {bookmarkedModules.length > 0
                        ? "Bookmarks"
                        : "Add your first bookmark for quick access"}
                    </h6>
                    {bookmarkedModules.length > 0 ? (
                      bookmarkedModules.map((bookmark: object, i: number) => (
                        <div
                          key={i}
                          className={
                            location.pathname.includes(
                              getValue(bookmark, "path", "")
                            )
                              ? "custom-sidebar-submenu-active"
                              : "custom-sidebar-submenu"
                          }
                          onClick={(event: any) => {
                            if (event && event.stopPropagation) {
                              event.stopPropagation();
                            }
                          }}
                        >
                          <NavLink
                            to={`/${getValue(params, "orgId", "")}/${
                              getValue(bookmark, "key", "")
                                ? getValue(bookmark, "key", "") + "/"
                                : ""
                            }${getValue(bookmark, "path", "")}`}
                            className={`subnav_dropdown_item ${
                              location.pathname ===
                              `/${getValue(params, "orgId", "")}/${
                                getValue(bookmark, "key", "")
                                  ? getValue(bookmark, "key", "") + "/"
                                  : ""
                              }${getValue(bookmark, "path", "")}`
                                ? "active"
                                : ""
                            }`}
                          >
                            {getValue(bookmark, "name", "")}
                          </NavLink>

                          <button
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              removeBookmark(getValue(bookmark, "id", ""));
                            }}
                            className="bookmark-remove-btn"
                            style={{
                              background: "none",
                              border: "none",
                              cursor: "pointer",
                            }}
                          >
                            <Bookmark size={16} />
                          </button>
                        </div>
                      ))
                    ) : (
                      <div className="no-bookmark-item mt-3">
                        <div className="bookmark-demo-item mb-2">
                          <Star size={14} />
                          <div className="bookmark-demo-item-text"></div>
                        </div>
                        <div
                          className="small_text__13 text-center"
                          style={{
                            maxWidth: 146,
                            lineHeight: 1.2,
                            width: "100%",
                          }}
                        >
                          Select the bookmark icon next to module to add it to
                          your bookmarks
                        </div>
                      </div>
                    )}
                  </Menu.ItemGroup>
                </Menu>
              </ConfigProvider>
            }
            isOpen={isOpen}
            sidebarWidth={isOpen ? 380 : 234}
            // forceOpen={true}
          />
        </div>

        <Divider className="m-0" />
        {navItems.map(
          (item: any, index) =>
            tabPermissionList.includes(getValue(item, `permTab`, "")) && (
              <div
                key={index}
                className={` ${
                  item.isDropdown && location.pathname.includes("crm")
                    ? "navbar_item_active "
                    : item.isDropdown
                    ? "navbar_dropdown_item "
                    : location.pathname.includes(item.linkUrl)
                    ? "navbar_item_active"
                    : "navbar_item"
                }`}
              >
                {item.isDropdown ? (
                  <CustomDropdown
                    trigger={
                      <NavLink
                        to={
                          getValue(item, `name`, "") === "CRM"
                            ? currentCRMUrl
                              ? currentCRMUrl
                              : item.link
                            : item.link
                        }
                        className={`navbar_nav__link ${
                          item.isDropdown && location.pathname.includes("crm")
                            ? "navbar_nav__link-active"
                            : ""
                        }`}
                      >
                        {getValue(item, "icon", "")}
                        {isOpen && getValue(item, "name", "")}
                      </NavLink>
                    }
                    menu={renderDropdownMenu(modules)}
                    isOpen={isOpen}
                    sidebarWidth={isOpen ? 380 : 234}
                  />
                ) : (
                  <Tooltip title={!isOpen ? item.name : ""} placement="right">
                    <NavLink
                      to={item.link}
                      className={`sidebar-nav-link position-relative`}
                    >
                      {item.name === "Ai Agents" ? (
                        <div className="heart-beat-anim ">
                          <img
                            src="/images/icons/ai-icon.svg"
                            alt="ai-stars"
                            width={28}
                            height={28}
                            style={{
                              top: "4px",
                              left: "6px",
                            }}
                            className="position-absolute"
                          />
                        </div>
                      ) : (
                        // <div className="heart-beat-anim">
                        //   <AIStars />
                        // </div>
                        getValue(item, "icon", "")
                      )}
                      {isOpen && (
                        <div
                          className={`${
                            item.name === "Ai Agents" ? "ms-3" : "me-2"
                          }`}
                        >
                          {getValue(item, "name", "")}
                        </div>
                      )}
                      {item.name === "Live Chat" && (
                        <>
                          {rawStatusCounts.unread.map((item: any) => {
                            return (
                              getValue(item, "status", "") ===
                                "my_open_chats" &&
                              getValue(item, "count", 0) > 0 && (
                                <div className="whatsapp-msg-notification">
                                  <Badge
                                    color="#25d366"
                                    count={getValue(item, "count", 0)}
                                    size="small"
                                  />
                                </div>
                              )
                            );
                          })}
                        </>
                      )}
                      {item.isNewFeature && (
                        <div className="new-feature-tag">New</div>
                      )}
                    </NavLink>
                  </Tooltip>
                )}
              </div>
            )
        )}
      </nav>
      <div
        className={`d-flex flex-column gap-2 ${
          isOpen ? "align-items-end me-3" : "align-items-center"
        }`}
      >
        {/* <Button
          color="default"
          variant="text"
          icon={
            appTheme === "light" ? (
              <Sun size={20} color="#1F1F1F" />
            ) : (
              <Moon size={20} color="#1F1F1F" />
            )
          }
          className="w-100"
          onClick={() => setAppTheme(appTheme === "light" ? "dark" : "light")}
        >
          {isOpen && (appTheme === "light" ? "Light" : "Dark")}
        </Button> */}
        <Button
          color="default"
          variant="text"
          icon={
            isOpen ? (
              <PanelRightOpen size={20} color="#1F1F1F" />
            ) : (
              <PanelRightClose size={20} color="#1F1F1F" />
            )
          }
          onClick={handleSidebarToggle}
        />
      </div>
    </header>
  );
}
