import CreatePipeline from "./components/pipeline-main";
import {
  generatePipelinePayload,
  intialPipelineState,
} from "../helpers/create-pipeline-helper";
import { useState } from "react";
import { createPipeline } from "@services/pipeline.service";
import { toast } from "sonner";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "@common/query-request-helper";
import { useNavigate } from "react-router-dom";
import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";
import { useStateContext } from "@context/dataContext";

function CommonAddPipeline(props: any) {
  const {
    isOpen,
    toggle,
    simpleValidator,
    forceUpdate,
    module_id,
    title,
    module,
    request,
    setRequest,
    from_module_edit,
  } = props;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(1);
  const [pipelineLoading, setPipelineLoading] = useState(false);
  const { selectedModuleId } = useStateContext();

  const handleCreatePipeline = async () => {
    try {
      const payload = generatePipelinePayload(request, module);
      setPipelineLoading(true);
      let id = (await selectedModuleId())
        ? await selectedModuleId()
        : module_id;
      let resp = await createPipeline(module_id, payload);
      if (resp) {
        setPipelineLoading(false);
        toast.success("Created successfully");
        setRequest(intialPipelineState);
        simpleValidator.current.hideMessages();
        forceUpdate(0);
        toggle();
        setActiveStep(1);
        let payload = {
          pipeline: getValue(resp, `data.id`, ""),
        };
        let queryRequest = QueryRequestHelper(payload);
        navigate(`${window.location.pathname}?${queryRequest}`);
      } else {
        setPipelineLoading(false);
      }
    } catch (error) {
      setPipelineLoading(false);
    }
  };
  return (
    <div>
      <SlideFromRightPopup
        isPopupVisible={isOpen}
        addCustomClass={"rolls-popup"}
        toggle={() => {
          toggle();
        }}
        title={title}
        width={"600px"}
      >
        <CreatePipeline
          title={title}
          handleBackButton={() => {
            simpleValidator.current.hideMessages();
            forceUpdate(0);
            toggle();
          }}
          forceUpdate={forceUpdate}
          isAddExisting={true}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          request={request}
          setRequest={setRequest}
          onSubmit={handleCreatePipeline}
          isLoading={pipelineLoading}
          simpleValidator={simpleValidator}
          module_id={module_id}
        />
      </SlideFromRightPopup>
    </div>
  );
}

export default CommonAddPipeline;
