import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import { updateAgent } from "@services/agent-actions.service";
import { getValue } from "@utils/lodash";
import { Button, Checkbox, Radio, Table } from "antd";
import React, { useState, useEffect } from "react";
import { toast } from "sonner";

const CaDataAccess = (props: any) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const { request, setRequest, getActionData } = props;
  const [full_data_access, setFullDataAccess] = useState("full");

  // Check initial state on component mount
  useEffect(() => {
    const fields = getValue(request, "config.return_fields", []);
    const areAllChosen = fields.every((field: any) =>
      getValue(field, "chosen", false)
    );
    setFullDataAccess(areAllChosen ? "full" : "limited");
  }, []); // Empty dependency array means this runs once on mount

  const handleRadioChange = (e: any) => {
    setFullDataAccess(e.target.value);
    // If switched to "full", select all fields
    if (e.target.value === "full") {
      setRequest((prev: any) => ({
        ...prev,
        config: {
          ...prev.config,
          return_fields: prev.config.return_fields.map((field: any) => ({
            ...field,
            chosen: true,
          })),
        },
      }));
    }
  };

  const toggleChosen = (index: number) => {
    setRequest((prev: any) => {
      const updatedFields = [...prev.config.return_fields];
      updatedFields[index] = {
        ...updatedFields[index],
        chosen: !updatedFields[index].chosen,
      };

      // Check if all fields are still chosen after toggle
      const areAllChosen = updatedFields.every((field) => field.chosen);
      if (!areAllChosen) {
        setFullDataAccess("limited");
      }

      return {
        ...prev,
        config: {
          ...prev.config,
          return_fields: updatedFields,
        },
      };
    });
  };

  const toggleAll = () => {
    const currentFields = getValue(request, "config.return_fields", []);
    const areAllSelected = currentFields.every((field: any) =>
      getValue(field, "chosen", false)
    );

    setRequest((prev: any) => ({
      ...prev,
      config: {
        ...prev.config,
        return_fields: prev.config.return_fields.map((field: any) => ({
          ...field,
          chosen: !areAllSelected,
        })),
      },
    }));

    // Update full_data_access based on new selection state
    setFullDataAccess(!areAllSelected ? "full" : "limited");
  };

  const areAllSelected = getValue(request, "config.return_fields", []).every(
    (field: any) => getValue(field, "chosen", false)
  );

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSave = async () => {
    try {
      setSubmitLoading(true);
      let reqPayload: any = JSON.parse(JSON.stringify(request));
      delete reqPayload["config"]["is_live_response"];
      let payload = {
        ...reqPayload,
        config: removeNullOrUndefinedProperties(
          getValue(reqPayload, `config`, {})
        ),
      };
      let resp;
      if (getValue(UrlParams, `action_id`, "")) {
        resp = await updateAgent(getValue(UrlParams, `action_id`, ""), payload);
      } else {
      }
      if (resp) {
        toast.success(
          `Action ${
            getValue(UrlParams, `action_id`, "") ? "updated" : "created"
          } successfully`
        );
        getActionData();
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const columns = [
    {
      title: (
        <Checkbox
          checked={areAllSelected}
          onChange={toggleAll}
          disabled={full_data_access === "full"}
        />
      ),
      dataIndex: "chosen",
      render: (chosen: boolean, _: any, index: number) => (
        <Checkbox
          checked={chosen}
          onChange={() => toggleChosen(index)}
          disabled={full_data_access === "full"}
        />
      ),
      width: 50,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Test Response",
      dataIndex: "value",
      key: "value",
    },
  ];

  const dataSource = getValue(request, "config.return_fields", []).map(
    (item: any, index: number) => ({
      key: index,
      ...item,
    })
  );

  return (
    <div>
      <Radio.Group
        onChange={handleRadioChange}
        value={full_data_access}
        className="d-grid gap-2"
      >
        <Radio value="full">
          <h6 className="header_text__16">Full data access</h6>
          <p className="small_text__14">
            Allow the AI agent to access all available information, ensuring
            comprehensive responses based on complete data.
          </p>
        </Radio>
        <Radio value="limited">
          <h6 className="header_text__16">Limited data access</h6>
          <p className="small_text__14">
            Limit the information the AI agent can access, providing more
            controlled and specific replies while protecting sensitive data.
          </p>
        </Radio>
      </Radio.Group>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        className="my-3"
      />

      <Button
        onClick={() => handleSave()}
        size="large"
        type="primary"
        disabled={submitLoading}
      >
        Save and Continue
      </Button>
    </div>
  );
};

export default CaDataAccess;
