import TicketDetailsPage from "@pages/Private/Tickets/Detail";
import Tickets from "@pages/Private/Tickets";

export const TicketRoutes = [
  {
    path: "/:orgId/tickets",
    name: "helpdesk_tickets",
    component: Tickets,
  },
  {
    path: "/:orgId/tickets/detail/:id",
    name: "helpdesk_tickets",
    component: TicketDetailsPage,
  },
];
