import {
  cannedResponseSearchableDropdown,
  companiesSearchableDropdown,
  contactsSearchableDropdown,
  emailTemplatesSearchableDropdown,
  groupsSearchableDropdown,
  leadsSearchableDropdown,
  lifeCycleStageSearchableDropdown,
  prioritiesSearchableDropdown,
  productsSearchableDropdown,
  saleItemsSearchableDropdown,
  // productsSearchableDropdown,
  stageSearchableDropdown,
  tagsSearchableDropdown,
  ticketsSearchableDropdown,
  ticketsStatusSearchableDropdown,
  usersEmailSearchableDropdown,
  usersSearchableDropdown,
  whatsappTemplatesSearchableDropdown,
} from "./lookup-apis-request";

export const getLookupAPIs = (value: string, queryRequest: string) => {
  let stageReq = value.includes("stage") && value !== "lifecycle_stage";
  switch (stageReq ? "stage" : value) {
    case "org_user":
    case "org_users":
      return usersSearchableDropdown(queryRequest);
    case "org_user_mail":
      return usersEmailSearchableDropdown(queryRequest);
    case "contacts":
      return contactsSearchableDropdown(queryRequest);
    case "leads":
      return leadsSearchableDropdown(queryRequest);
    case "companies":
      return companiesSearchableDropdown(queryRequest);
    case "canned_response":
      return cannedResponseSearchableDropdown(queryRequest);
    case "email_templates":
      return emailTemplatesSearchableDropdown(queryRequest);
    case "templates":
      return whatsappTemplatesSearchableDropdown(queryRequest);
    case "stage":
      return stageSearchableDropdown(value, queryRequest);
    case "tags":
      return tagsSearchableDropdown(queryRequest);
    case "priority":
    case "priorities":
      return prioritiesSearchableDropdown(queryRequest);
    case "group":
    case "groups":
      return groupsSearchableDropdown(queryRequest);
    case "product":
    case "products":
      return productsSearchableDropdown(queryRequest);
    case "tickets":
      return ticketsSearchableDropdown(queryRequest);
    case "ticket_status":
      return ticketsStatusSearchableDropdown(queryRequest);
    case "sale_items":
      return saleItemsSearchableDropdown(queryRequest);
    case "lifecycle_stage":
      return lifeCycleStageSearchableDropdown();
    default:
      return null;
  }
};
