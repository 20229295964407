import { formHandleTextArrayChangeByName } from "@common/handleChange";
import { QueryRequestHelper } from "@common/query-request-helper";
import { concatString } from "@common/text-helpers";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import InputRuleForm from "@components/InputRuleForm/form";
import Loader from "@components/common/Loader/loading";
import NotFoundFile from "@components/common/NoData/NotFoundFile";
import {
  addChatbotDocumentLink,
  deletechatbotDocumentList,
} from "@services/chatbot-document.service";
import { chatbotDocumentList } from "@services/chatbot-document.service";
import { getValue } from "@utils/lodash";
import { Alert, Button, Input, Popconfirm } from "antd";
import { Check, Info, Newspaper, Plus, Trash2 } from "lucide-react";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import WebsiteRightSection from "./website-right";

function AIAgentSourceWebsite(props: any) {
  const { linkURLs, setLinkUrls, getUrlInfo } = props;
  const params = useParams();
  const navigate = useNavigate();

  const [src_url, setSrc_url] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const createLink = async () => {
    if (src_url.includes("http://") || src_url.includes("https://")) {
      try {
        setSubmitLoading(true);
        let payload = {
          src_url: src_url,
          chatbot_id: getValue(params, `chatbotId`, ""),
        };
        let queryRequest = QueryRequestHelper(payload);
        let resp = await chatbotDocumentList(queryRequest);
        if (resp) {
          props.websiteSocket();
          setSubmitLoading(false);
          toast.success("Submitted successfully");
          getUrlInfo();
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    } else {
      toast.error("URL should be valid");
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /*                                Delete section                              */
  /* -------------------------------------------------------------------------- */
  const [isOpen, setIsOpen] = React.useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const [deleteId, setDeleteId] = useState("");
  const handleDelete = (id: string) => {
    setDeleteId(id);
    handleModal();
  };
  const handleDeleteFunction = async () => {
    try {
      let resp = await deletechatbotDocumentList(
        getValue(params, `chatbotId`, ""),
        { ids: [deleteId] }
      );
      if (resp) {
        toast.success("Deleted Successfully");
        handleModal();
        getUrlInfo();
      }
    } catch (error) {}
  };

  const getStatus = (link: any) => {
    const linkStatus = getValue(link, "status", "");
    const scrapeStatus = getValue(props, "scrapeInfo.status", "");

    // Early return if status is neither failed nor untrained
    if (linkStatus !== "failed" && linkStatus !== "untrained") {
      return linkStatus;
    }

    // If there's no scrape status, return link status
    if (!scrapeStatus) {
      return linkStatus;
    }

    // Return 'training' if scraping is in progress
    if (
      getValue(link, "status", "") === "untrained" &&
      scrapeStatus === "scraping"
    ) {
      return "training";
    }
    if (scrapeStatus === "completed") {
      return "trained";
    }

    // Default return link status
    return linkStatus;
  };

  const [mainURLs, setMainURLs] = useState<any>([]);
  const handleAddUrl = () => {
    mainURLs.unshift({ url: "", status: "untrained", type: "text" });
    setMainURLs([...mainURLs]);
  };
  const handleRemoveUrl = (index: number) => {
    let filtered = mainURLs.filter((_: any, i: number) => i !== index);
    setMainURLs(filtered);
  };
  const handleSaveURL = async (index: number) => {
    if (getValue(mainURLs, `[${index}].url`, "")) {
      if (
        getValue(mainURLs, `[${index}].url`, "").includes("http://") ||
        getValue(mainURLs, `[${index}].url`, "").includes("https://")
      ) {
        try {
          let resp = await addChatbotDocumentLink({
            urls: [getValue(mainURLs, `[${index}].url`, "")],
            chatbot_id: getValue(params, `chatbotId`, ""),
          });
          if (resp) {
            const filtered = mainURLs.filter(
              (_item: object, i: number) => i !== index
            );
            setMainURLs(filtered);
            toast.success("Added Successfully");
            getUrlInfo();
          }
        } catch (error) {}
      } else {
        toast.error("URL Should be valid");
      }
    }
  };

  const handleDeleteAll = async () => {
    try {
      let resp = await deletechatbotDocumentList(
        getValue(params, `chatbotId`, ""),
        { ids: linkURLs.map((item: any) => getValue(item, `id`, "")) }
      );
      if (resp) {
        toast.success("Deleted Successfully");
        getUrlInfo();
      }
    } catch (error) {}
  };

  return (
    <>
      <div className="main-content">
        <h2 className="header_text__18">Website</h2>

        <div className="crawl-section">
          <div className="w-100">
            <label className="form-label">Crawl</label>
            <div className="d-flex align-items-center gap-2 justify-content-between w-100">
              <div className="w-100">
                <Input
                  placeholder="https://www.example.com"
                  value={src_url}
                  onChange={(e: any) => setSrc_url(e.target.value)}
                  className="w-100 mb-0"
                />
              </div>
              <Button
                type="primary"
                onClick={createLink}
                disabled={submitLoading || !src_url}
                loading={submitLoading}
                className=""
              >
                Fetch more links
              </Button>
            </div>
            <Alert
              showIcon
              message=" This will crawl all the links starting with the URL (not including
            files on the website)."
              className="mt-2 w-fit-content"
            />
          </div>
        </div>

        <div className="links-section mt-4">
          <h3 className="header_text__16">
            Included Links
            <div className="actions px-4">
              {/* <Button type="text">Delete all</Button> */}
              {/* {getValue(mainURLs, `length`, 0) < 1 && ( */}
              <Button
                type="text"
                icon={<Plus size={16} />}
                onClick={handleAddUrl}
              />
              {/* )} */}
            </div>
          </h3>

          <div className="d-flex align-items-center gap-2 justify-content-end">
            <Popconfirm
              title="Are you sure to delete all?"
              onConfirm={handleDeleteAll}
            >
              <Button type="text" icon={<Trash2 size={16} />}>
                Delete all
              </Button>
            </Popconfirm>
          </div>
          {getValue(mainURLs, `length`, 0) > 0
            ? mainURLs.map((link: any, index: any) => (
                <div className="link-item">
                  <div className="w-20">
                    <span className={"new"}>
                      <Newspaper size={14} style={{ marginRight: 4 }} />
                      {"New"}
                    </span>
                  </div>
                  <div className="w-80">
                    <InputRuleForm
                      inputType="TEXT"
                      value={getValue(link, `url`, "")}
                      name="url"
                      onChange={(e: any) =>
                        formHandleTextArrayChangeByName(index, e, setMainURLs)
                      }
                      placeholder="https://www.example.com"
                    />
                  </div>
                  <div className="w-10 ms-3 d-flex align-items-center gap-2">
                    <div
                      onClick={() => handleSaveURL(index)}
                      className="cursor-pointer"
                    >
                      <Check size={16} className="" />
                    </div>
                    <div
                      onClick={() => handleRemoveUrl(index)}
                      className="cursor-pointer"
                    >
                      <Trash2 size={16} className="delete" />
                    </div>
                  </div>
                </div>
              ))
            : ""}
          {getValue(props, `loading`, false) ? (
            <Loader />
          ) : getValue(linkURLs, `length`, 0) > 0 ? (
            linkURLs.map((link: any, index: any) => (
              <div key={index} className="link-item">
                <div className="w-20">
                  <span
                    className={
                      getValue(link, `status`, "") == "failed"
                        ? "error_status"
                        : getValue(link, `status`, "") == "untrained"
                        ? "untrained"
                        : "status"
                    }
                  >
                    <Info size={14} style={{ marginRight: 4 }} />
                    {getValue(link, `status`, "") === "untrained"
                      ? props.disableTrain
                        ? "training..."
                        : getValue(link, `status`, "")
                      : getValue(link, `status`, "")}
                  </span>
                </div>
                <div className="w-80">
                  <span className="link-url">{concatString(link.url, 50)}</span>
                  <span className="chars">{link.chars}</span>
                </div>
                <div className="w-10">
                  <div
                    onClick={() =>
                      !getValue(link, `id`, "")
                        ? handleRemoveUrl(index)
                        : handleDelete(getValue(link, `id`, ""))
                    }
                  >
                    <Trash2 size={16} className="delete" />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <NotFoundFile />
          )}
        </div>
        <DeleteModal
          isOpen={isOpen}
          handleModal={handleModal}
          handleSubmit={handleDeleteFunction}
          deleteValue=""
        />
      </div>
      <WebsiteRightSection
        handleSaveURL={handleSaveURL}
        findStaticUrl={props.findStaticUrl}
        handleTrainLink={props.handleTrainLink}
        scrapeInfo={props.scrapeInfo}
        ScrapedDocumentStatus={props.ScrapedDocumentStatus}
        linkURLs={linkURLs}
        mainURLs={mainURLs}
        activeTab={props.activeTab}
        disableTrain={props.disableTrain}
      />
    </>
  );
}

export default AIAgentSourceWebsite;
