import BrandingSvgComponent from "@assets/svg/branding";
import PhoneSvgComponent from "@assets/svg/custom/phone";
import EditSvgComponent from "@assets/svg/edit";
import MoneySvgComponent from "@assets/svg/money";
import PersonSvgComponent from "@assets/svg/person";
import CommonRightPopup from "@components/Dialogs/CommonRightPopup/CommonSlideFromRightPopup";
import ButtonComponent from "@components/Form/Button/Button";
import PropertySettingsLayout from "@layouts/PropertyLayout/property-settings-layout";
import { useEffect, useState } from "react";
import EditAmenities from "./components/edit-amenities";
import AddRoomTypePopup from "./components/add-room-type";
import PropertyDetailsLayout from "@layouts/PropertyLayout/property-details-layout";
import {
  getAllPropertyImage,
  getSpecificProperty,
  removeAssociatedAmenityFromProperty,
} from "@services/properties/property.service";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import { config } from "../../../../env";
import { getAllPropertyAmenities } from "@services/properties/master/property-amenities.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  getAllRoomType,
  getAllRoomTypeImage,
  getSpecificRoomTypeImage,
} from "@services/properties/room-type.service";
import { toast } from "sonner";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import Loader from "@components/common/Loader/loading";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { Navigation } from "swiper/modules";
import "swiper/css";
import ChevronCircleLeft from "@assets/svg/chevron-circle-left";
import ChevronCircleRight from "@assets/svg/chevron-circle-right";
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterOfEachWord,
} from "@common/text-helpers";
import { Carousel, Image } from "antd";
import ListLoader from "@components/common/Loader";

type RoomImage = {
  id: string;
  url: string;
};

const PropertySettingsDetails = (props: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const [rooms, setRooms] = useState<{ id: string }[]>([]);

  const [openEditDetails, setOpenEditDetails] = useState(false);
  const toggleEditDetails = () => {
    setOpenEditDetails(!openEditDetails);
  };

  const [openEditAmenities, setOpenEditAmenities] = useState(false);
  const toggleEditAmenities = () => {
    setOpenEditAmenities(!openEditAmenities);
  };

  const [openAddRoomType, setOpenAddRoomType] = useState(false);
  const toggleAddRoomType = () => {
    setOpenAddRoomType(!openAddRoomType);
  };

  // const [activeTab, setActiveTab] = useState(3);

  const handleClick = () => {};

  /* -------------------------------------------------------------------------- */
  /*                               Hooks Section                                */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
    getAllAmenities();
    getAllRoomList();
    getImages();
  }, []);

  useEffect(() => {
    getRoomImages();
  }, [rooms]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState({});
  const [images, setImages] = useState([]);

  const getData = async () => {
    try {
      setIsLoading(true);
      let resp = await getSpecificProperty(getValue(params, `id`, ""));
      if (resp) {
        setInfo(getValue(resp, `data`, {}));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getImages = async () => {
    try {
      let payload = {
        property_id: getValue(params, `id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllPropertyImage(queryRequest);
      if (resp) {
        setImages(getValue(resp, `data.property_images`, []));
      }
    } catch (error) {}
  };

  /* -------------------------------  Amenity Section  --------------------------- */

  const [amenityList, setAmenityList] = useState([]);
  const getAllAmenities = async () => {
    try {
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllPropertyAmenities(queryRequest);
      if (resp) {
        setAmenityList(getValue(resp, `data.amenities`, []));
      }
    } catch (error) {}
  };
  /* -------------------------------  Room Section  --------------------------- */

  const getAllRoomList = async () => {
    try {
      setIsLoading(true);
      let payload = {
        property_id: getValue(params, `id`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllRoomType(queryRequest);
      if (resp) {
        setIsLoading(false);
        setRooms(getValue(resp, `data.properties`, []));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------  Room Images Section  --------------------------- */

  const [roomImages, setRoomImages] = useState<RoomImage[]>([]);

  const getRoomImages = async () => {
    try {
      const roomIds = rooms.map((item) => item.id);

      let allImages: RoomImage[] = [];
      for (const id of roomIds) {
        let payload = {
          room_type_id: id,
        };
        let queryRequest = QueryRequestHelper(payload);
        let resp = await getAllRoomTypeImage(queryRequest);

        if (resp) {
          const images: RoomImage[] = getValue(resp, "data.roomTypeImages", []);
          allImages = [...allImages, ...images];
        }
      }

      setRoomImages(allImages);
    } catch (error) {
      console.error("Error fetching room images:", error);
    }
  };

  /* ------------------------------- Delete Room Section  --------------------------- */
  const [deleteId, setDeleteId] = useState("");
  const [deleteValue, setDeleteValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleDeleteView = (id: string) => {
    setDeleteId(id);
    handleModal();
  };
  const handleDeleteNote = async () => {
    try {
      let resp = await removeAssociatedAmenityFromProperty(
        getValue(params, `id`, ""),
        { amenity_ids: [deleteId] }
      );
      if (resp) {
        toast.success("Deleted Successfully");
        handleModal();
        getAllRoomList();
        setDeleteId("");
      }
    } catch (error) {}
  };

  const onInit = () => {};

  const contentStyle: React.CSSProperties = {
    margin: 0,
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  return (
    <PropertyDetailsLayout props={props}>
      <PropertySettingsLayout>
        {isLoading ? (
          <ListLoader />
        ) : (
          <>
            <div className="">
              <div className="position-relative">
                <button className="custom-button-prev d-flex justify-content-center align-items-center p-0">
                  <ChevronCircleLeft color="#111e3a" />
                </button>
                <button className="custom-button-next d-flex justify-content-center align-items-center p-0">
                  <ChevronCircleRight color="#111e3a" />
                </button>
                <Swiper
                  spaceBetween={10}
                  slidesPerView={7}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={{
                    prevEl: ".custom-button-prev",
                    nextEl: ".custom-button-next",
                  }}
                  modules={[Pagination, Navigation]}
                  className="mx-5 property-image-swiper"
                  breakpoints={{
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                  }}
                >
                  {images.map((item, index) => (
                    <SwiperSlide key={getValue(item, "id", "")}>
                      <Image
                        src={`https://${config.ASSETS_CDN_ENDPOINT}/${getValue(
                          item,
                          "file_upload.key",
                          ""
                        )}`}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                {/* <Image.PreviewGroup
                  preview={{
                    onChange: (current, prev) =>
                {}
                  }}
                >
                  {images.map((item, index) => (
                    <Image
                      src={`https://${config.ASSETS_CDN_ENDPOINT}/${getValue(
                        item,
                        "file_upload.key",
                        ""
                      )}`}
                      width={200}
                    />
                  ))}
                </Image.PreviewGroup> */}
              </div>
            </div>
            <div className="mt-4 ">
              <div className="d-flex justify-content-between align-items-baseline border-bottom pb-5">
                <div>
                  <h3 className="header_text__24 my-3">
                    {capitalizeFirstLetterOfEachWord(
                      getValue(info, `name`, "")
                    )}
                  </h3>
                  <div className="d-flex align-items-center gap-2 w-40">
                    <BrandingSvgComponent size="20" color="#3c4043" />
                    <p>{getValue(info, `address1`, "")}</p>
                  </div>
                  <div className="d-flex align-items-center gap-2 mt-2">
                    <PhoneSvgComponent size="20" />
                    <p>{getValue(info, `contact_no`, "")}</p>
                  </div>
                  <p
                    onClick={() =>
                      navigate(
                        `/${getValue(
                          params,
                          `orgId`,
                          ""
                        )}/property/edit/${getValue(params, `id`, "")}`
                      )
                    }
                    className="header_blue_text__16 touchable-text mt-4 mb-2 d-flex align-items-center gap-2"
                  >
                    <EditSvgComponent color={"#408dfb"} /> Edit Property Details
                  </p>
                  <p
                    onClick={toggleEditAmenities}
                    className="header_blue_text__16 touchable-text  d-flex align-items-center gap-2"
                  >
                    <EditSvgComponent color={"#408dfb"} /> Edit Property
                    Amenities
                  </p>
                </div>
                {/* <div className="d-flex align-items-center gap-2">
              <StarSvgComponent size="20" />
              <p className="header_text__20">4.6</p>
            </div> */}
              </div>
              <div className="mt-4 mb-5">
                <div className="d-flex justify-content-between ">
                  <p className="header_text__18">Manage room type</p>

                  <ButtonComponent
                    buttonType="primary"
                    buttonText={
                      getValue(rooms, `length`, "") === 0
                        ? "Add room"
                        : "Manage all rooms"
                    }
                    onClickHandler={() =>
                      navigate(
                        `/${getValue(
                          params,
                          `orgId`,
                          ""
                        )}/property/room-types/${getValue(
                          params,
                          `id`,
                          ""
                        )}?fromEdit=true`
                      )
                    }
                    // disabled={isLoading}
                  />
                </div>

                {getValue(rooms, `length`, 0) > 0 ? (
                  <div className="bed_type_container mt-3">
                    {rooms.map((bed, index) => {
                      const bedImages = roomImages.filter(
                        (item) =>
                          getValue(bed, "id", "") ===
                          getValue(item, "room_type_id", "")
                      );

                      return (
                        <div className="bed_type_details" key={index}>
                          <div className="bed_type-backgroun  gap-4">
                            <div className="w-100">
                              {bedImages.length > 0 ? (
                                <>
                                  <Carousel arrows>
                                    {bedImages.map((item, imgIndex) => (
                                      <div>
                                        <Image
                                          src={`https://${
                                            config.ASSETS_CDN_ENDPOINT
                                          }/${getValue(
                                            item,
                                            "file_upload.key",
                                            ""
                                          )}`}
                                          key={imgIndex}
                                          className=" room-type-image"
                                        />
                                      </div>
                                    ))}
                                  </Carousel>
                                </>
                              ) : (
                                <img
                                  src="/property/dummy-image.jpg"
                                  className=" room-type-image"
                                />
                              )}
                            </div>
                            <div className="p-4">
                              <div className="d-flex justify-content-between align-items-start gap-4">
                                <p className="small_text__16">
                                  {capitalizeFirstLetter(
                                    getValue(bed, `name`, "")
                                  )}
                                </p>
                              </div>
                              <div className="d-grid gap-1 mt-3">
                                <div className="d-flex align-items-center gap-2">
                                  <p>
                                    <MoneySvgComponent size="18" />
                                  </p>
                                  <span className="small_text__14 card-text-white">
                                    {getValue(bed, `default_price`, "")}
                                  </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center gap-2">
                                    <p>
                                      <PersonSvgComponent size="18" />
                                    </p>
                                    <span className="small_text__14 card-text-white">
                                      Max adults:{" "}
                                      {getValue(bed, `max_adult`, "")}
                                    </span>
                                  </div>
                                  <div className="d-flex align-items-center gap-2">
                                    <p>
                                      <PersonSvgComponent size="18" />
                                    </p>
                                    <span className="small_text__14 card-text-white">
                                      Max children:{" "}
                                      {getValue(bed, `max_children`, "")}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <p className="text-center small_text__14 mt-2">
                    You haven't added any. Please add room to manage.
                  </p>
                )}
              </div>
            </div>
            {/* <CommonRightPopup
          isPopupVisible={openEditDetails}
          toggle={toggleEditDetails}
          width="750px"
          title="Edit Hotel Details"
          hideNew
          second_title="Save"
        >
          <EditPropertyDetails />
        </CommonRightPopup> */}
            <CommonRightPopup
              isPopupVisible={openEditAmenities}
              toggle={toggleEditAmenities}
              width="600px"
              title="Edit Amenities"
              hideNew
              second_title="Save"
              hideSubmit
            >
              <EditAmenities
                info={info}
                amenityList={amenityList}
                getData={getData}
              />
            </CommonRightPopup>
            <CommonRightPopup
              isPopupVisible={openAddRoomType}
              toggle={toggleAddRoomType}
              width="750px"
              title="Add room type"
              hideNew
              second_title="Save"
            >
              <AddRoomTypePopup />
            </CommonRightPopup>

            <DeleteModal
              isOpen={isOpen}
              handleModal={handleModal}
              handleSubmit={handleDeleteNote}
              deleteValue={deleteValue}
            />
          </>
        )}
      </PropertySettingsLayout>
    </PropertyDetailsLayout>
  );
};

export default PropertySettingsDetails;
