import CompanyPayment from "@pages/Private/Settings/CompanyPayment";
import CompanyProfile from "@pages/Private/Settings/CompanyProfile";

export const SettingsAdminRoutes = [
  {
    path: "/:orgId/settings/general/company-profile",
    name: "settings_organisation_profile",
    component: CompanyProfile,
  },
  {
    path: "/:orgId/settings/general/company-payment",
    name: "settings_organisation_profile",
    component: CompanyPayment,
  },
  // {
  //   path: "/:orgId/settings/general/branding",
  //   name: "settings_organisation_branding",
  //   component: Branding,
  // },
  //   {
  //     path: "/:orgId/settings/app-branding",
  //     name: "Dashboard",
  //     component: AppBranding,
  //   },
];
