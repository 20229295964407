import AddCircleSvgComponent from "@assets/svg/add-circle";
import "../workflow.scss";
import { getValue, setValue } from "@utils/lodash";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import {
  handleSelectArrayChange,
  handleTextNestedArrayChange,
} from "@common/handleChange";
import RemoveCircleSvgComponent from "@assets/svg/remove-circle";
import WorkflowBuilder from "./workflow-builder";
import InputRuleForm from "@components/InputRuleForm/form";
import SwapVerticalSvgComponent from "@assets/svg/swap";
import { formatString } from "@common/text-helpers";
import { Radio } from "antd";
import NewSearchSelect from "@components/custom/Dropdown/AntDropDown";

function WorkflowDetailStep3(props: any) {
  const {
    request,
    setRequest,
    workflowConditions,
    allConditions,
    setAllConditions,
    validator,
  } = props;
  const type = [
    { value: "AND", label: "AND" },
    { value: "OR", label: "OR" },
  ];
  const type1 = [{ value: "WHEN", label: "When" }];
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const addCondition = () => {
    if (getValue(request, `conditions.length`, 0) < 10) {
      let obj = {
        action: "add",
        fieldId: "",
        operator: "",
        value: [{ key: "" }],
        pattern:
          getValue(request, `conditions.length`, 0) === 0 ? "WHEN" : "AND",
      };
      request.conditions.push(obj);
      setRequest({ ...request });
    }
  };
  const removeCondition = (index: number) => {
    let array = getValue(request, `conditions`, []);
    let filtered = array.filter((item: object, ind: number) => ind !== index);
    setRequest({
      ...request,
      conditions: filtered,
    });
  };
  const removeConditionUpdate = (index: number) => {
    setValue(request, `conditions[${index}].action`, "remove");
    setRequest({ ...request });
  };
  const getCriteria = () => {
    let condtions = getValue(request, `conditions`, []).filter(
      (item: object) => getValue(item, `action`, "") !== "remove"
    );
    return generateExpressions(condtions);
  };
  const generateExpressions = (array: any) => {
    let arr = getFilteredArray(array).map((item: any, index: number) => ({
      ...item,
      index: index,
    }));
    const logicalOperators: any = {
      AND: " AND ",
      OR: " OR ",
    };
    const stack: any = [];
    let expression = "";
    for (const obj of arr) {
      const name = obj.pattern;
      if (logicalOperators[name]) {
        let obje = { operator: logicalOperators[name], index: obj.index };
        stack.unshift(obje);
      } else {
        if (stack.length > 0) {
          const { operator, index } = stack.pop();
          const prevExpression = expression;
          expression = `( ${prevExpression} ${operator}${index + 1} )`;
        } else {
          expression += `${obj.index + 1}`;
        }
      }
    }
    while (stack.length > 0) {
      const { operator, index } = stack.pop();
      const prevExpression = expression;
      expression = `( ${prevExpression} ${operator}${index + 1} )`;
    }

    if (getValue(request, `pattern`, "") !== expression) {
      setRequest({
        ...request,
        pattern: expression,
      });
    }
    return expression;
  };
  const getFilteredArray = (array: any) => {
    if (getValue(array, `length`, 0) > 0) {
      let selectedArray = array.filter(
        (item: object) => getValue(item, `action`, "") !== "REMOVE"
      );
      return selectedArray;
    } else {
      return [];
    }
  };
  const findConditions = (id: string) => {
    let filteredConditions = workflowConditions.find(
      (item: object) => getValue(item, `fieldId`, "") === id
    );
    return getValue(filteredConditions, `conditions`, []).map(
      (item: object) => ({
        label: getValue(item, `label`, ""),
        value: getValue(item, `operator`, ""),
        operator: getValue(item, `operator`, ""),
      })
    );
  };
  const findInputType = (id: string) => {
    let filteredConditions = workflowConditions.find(
      (item: object) => getValue(item, `fieldId`, "") === id
    );
    let index = workflowConditions.findIndex(
      (item: object) => getValue(item, "fieldId", "") === id
    );
    return { index: index, ...filteredConditions };
  };
  const getFilteredConditions = () => {
    let condtions = getValue(request, `conditions`, []).filter(
      (item: object) => getValue(item, `action`, "") !== "remove"
    );
    return getValue(condtions, `length`, 0);
  };

  const filteredConditions = () => {
    let list =
      getValue(request, `conditions.length`, 0) > 0
        ? getValue(request, `conditions`, []).filter(
            (item: object) => getValue(item, `action`, "") !== "remove"
          )
        : [];
    return list;
  };
  return (
    <div className="">
      <div className="workflow_detail_container">
        <div className="">
          <h6 className="header_text__20 color_primary mb-3">2. Conditions</h6>
          <h6 className="header_text__18 mt-1 mb-2">
            Which{" "}
            {getValue(urlParams, `moduleName`, "")
              ? formatString(getValue(urlParams, `moduleName`, ""))
              : getValue(props, `info.module.label`, "")}{" "}
            would you like to apply the workflow to?
          </h6>
        </div>
      </div>
      <div className="d-flex align-items-center mb-3">
        <Radio.Group
          onChange={(e) => setAllConditions(e.target.value)}
          value={allConditions}
        >
          <Radio value={true}>
            <span className="small_text__14">
              {getValue(urlParams, `moduleName`, "")
                ? formatString(getValue(urlParams, `moduleName`, ""))
                : getValue(props, `info.module.label`, "")}{" "}
              matching certain conditions
            </span>
          </Radio>
          <Radio value={false}>
            <span className="small_text__14">
              All{" "}
              {getValue(urlParams, `moduleName`, "")
                ? formatString(getValue(urlParams, `moduleName`, ""))
                : getValue(props, `info.module.label`, "")}
            </span>
          </Radio>
        </Radio.Group>
      </div>
      {allConditions && (
        <div>
          {filteredConditions().map((item: object, index: number) => {
            return (
              <div>
                {index !== 0 && (
                  <div
                    className="mt-2 mb-2 d-flex align-items-center cursor-pointer"
                    onClick={() =>
                      setRequest((object: any) => {
                        return {
                          ...object,
                          ["conditions"]: object["conditions"].map(
                            (x: any, i: number) => {
                              if (i !== index) return x;
                              return {
                                ...x,
                                pattern:
                                  getValue(item, `pattern`, "") === "AND"
                                    ? "OR"
                                    : "AND",
                              };
                            }
                          ),
                        };
                      })
                    }
                  >
                    <h6 className="header_text__14">
                      {getValue(item, `pattern`, "")}
                    </h6>
                    <div className="ms-2">
                      <SwapVerticalSvgComponent />
                    </div>
                    {/* <SearchToolTip
                    data={index === 0 ? type1 : type}
                    label="label"
                    width={"200px"}
                    placeholder={"Select"}
                    value={getValue(item, `pattern`, "")}
                    selectKey={"value"}
                    onChange={(e: object) =>
                      handleSelectArrayChange(
                        index,
                        e,
                        "pattern",
                        "conditions",
                        setRequest
                      )
                    }
                    disabled={index === 0 ? true : false}
                  /> */}
                  </div>
                )}
                <div className="d-flex align-items-center condtion_container mb-2">
                  <div className="criteria">
                    <div className="criteria-count">{index + 1}</div>
                  </div>

                  <div className="ms-2">
                    <NewSearchSelect
                      width={220}
                      label="label"
                      hideLabel
                      selectKey={"id"}
                      options={getValue(props, `workflowOptions`, [])}
                      value={getValue(item, `fieldId`, "")}
                      placeholder={"Select Field"}
                      onChange={(e: any, data: any) => {
                        handleSelectArrayChange(
                          index,
                          data,
                          "fieldId",
                          "conditions",
                          setRequest
                        );
                        handleSelectArrayChange(
                          index,
                          "",
                          "operator",
                          "conditions",
                          setRequest
                        );
                        handleTextNestedArrayChange(
                          index,
                          "value",
                          0,
                          null,
                          "conditions",
                          setRequest
                        );
                      }}
                      validator={validator}
                    />
                  </div>
                  <div className="ms-2">
                    <NewSearchSelect
                      width={220}
                      label="label"
                      hideLabel
                      selectKey={"value"}
                      value={getValue(item, `operator`, "")}
                      options={findConditions(getValue(item, `fieldId`, ""))}
                      onChange={(e: any, data: any) => {
                        handleSelectArrayChange(
                          index,
                          data,
                          "operator",
                          "conditions",
                          setRequest
                        );
                      }}
                      disabled={!getValue(item, `fieldId`, "")}
                      placeholder={"Select Operator"}
                      validator={validator}
                    />
                  </div>
                  {getValue(item, `fieldId`, "") && (
                    <div className="ms-2">
                      <WorkflowBuilder
                        item={
                          getValue(item, `fieldId`, "")
                            ? findInputType(getValue(item, `fieldId`, ""))
                            : findInputType("pipeline_stage_id")
                        }
                        parentIndex={index}
                        request={request}
                        setRequest={setRequest}
                        value={getValue(item, `value`, [])}
                        validator={validator}
                      />
                    </div>
                  )}
                  <div className="cursor-pointer ms-4 d-flex align-items-center">
                    {(index !== 0 || getFilteredConditions() === 1) && (
                      <div
                        onClick={() =>
                          getValue(item, `id`, "")
                            ? removeConditionUpdate(index)
                            : removeCondition(index)
                        }
                        className="ms-2"
                      >
                        <RemoveCircleSvgComponent />
                      </div>
                    )}
                    {filteredConditions().length - 1 === index &&
                      filteredConditions().length < 5 && (
                        <div onClick={addCondition} className="ms-2">
                          <AddCircleSvgComponent />
                        </div>
                      )}
                  </div>
                </div>
              </div>
            );
          })}

          {getValue(request, `conditions.length`, 0) > 1 && (
            <p className="header_text__16 mb-3 mt-4">{getCriteria()}</p>
          )}
          {getValue(request, `conditions.length`, 0) === 0 && (
            <div
              className="d-flex align-items-center mb-2 cursor-pointer"
              onClick={addCondition}
            >
              <AddCircleSvgComponent color={"#1a73e8"} />
              <h6 className="header_blue_text__18 ms-2">Add Condition</h6>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default WorkflowDetailStep3;
