import React from "react";
import { Avatar, Card, Typography, Space, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { getColorCode, getTextColor } from "@common/colors";
import { convertCurrentDateWithTime } from "@common/date-helpers";
import { concatString, getOnlyFirstLetterOfWord } from "@common/text-helpers";
import { getValue } from "@utils/lodash";

const { Text, Title } = Typography;

function TicketNoteCard(props: any) {
  const firstName = getValue(props, `data.msg_user.first_name`, "");
  const lastName = getValue(props, `data.msg_user.last_name`, "");
  const createdAt = getValue(props, `data.created_at`, "");
  const htmlContent = getValue(props, "data.html", "");
  const noteData = getValue(props, `data`, {});
  const noteId = getValue(props, "data.id", "");

  const firstLetter = getOnlyFirstLetterOfWord(firstName);
  const backgroundColor = getColorCode(firstLetter);
  const textColor = getTextColor(backgroundColor);

  return (
    <Card className="my-1" bodyStyle={{ padding: 16 }}>
      <div style={{ display: "flex", gap: "16px" }}>
        <Avatar
          size={30}
          style={{
            backgroundColor,
            color: textColor,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {firstLetter}
        </Avatar>

        <div style={{ flex: 1 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 4,
            }}
          >
            <Title level={5} style={{ margin: 0 }}>
              {firstName} {lastName}
            </Title>

            <Text type="secondary" style={{ fontSize: 12 }}>
              {convertCurrentDateWithTime(createdAt)}
            </Text>
          </div>

          <div
            className="note-content"
            style={{ marginBottom: 10 }}
            dangerouslySetInnerHTML={{
              __html: htmlContent,
            }}
          />

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Space>
              <Tooltip title="Edit">
                <EditOutlined
                  style={{ cursor: "pointer" }}
                  onClick={() => props.handleEditNote(noteData)}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <DeleteOutlined
                  style={{ cursor: "pointer" }}
                  onClick={() => props.handleDeleteView(noteId, "")}
                />
              </Tooltip>
            </Space>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default TicketNoteCard;
