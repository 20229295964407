import { config } from "env";
import { Delete, DeleteWithPayload, get, patch, post } from "./helpers/http-handler";

export const chatbotDocumentList = (queryRequest: string) =>
  get(`${config.API_URL}/chatbot/documents/list-links?${queryRequest}`);

export const chatbotDocumentText = (queryRequest: string) =>
  get(`${config.API_URL}/chatbot/documents/text?${queryRequest}`);

export const chatbotDocumentqna = (queryRequest: string) =>
  get(`${config.API_URL}/chatbot/documents/qna?${queryRequest}`);

export const trainChatbotDocumentWebsite = (payload: object) =>
  post(`${config.API_URL}/chatbot/documents/train-website`, payload);

export const trainChatbotDocumentText = (payload: object) =>
  post(`${config.API_URL}/chatbot/documents/train-text`, payload);

export const trainChatbotDocumentQandA = (payload: object) =>
  post(`${config.API_URL}/chatbot/documents/train-qna`, payload);

export const addChatbotDocumentLink = (payload: object) =>
  post(`${config.API_URL}/chatbot/documents/add/link`, payload);

export const deletechatbotDocumentList = (chatbotId: string,payload:object) =>
  DeleteWithPayload(`${config.API_URL}/chatbot/documents/link/${chatbotId}`,payload);

export const deletechatbotDocumentText = (chatbotId: string,payload:object) =>
  DeleteWithPayload(`${config.API_URL}/chatbot/documents/text/${chatbotId}`,payload);

export const deletechatbotDocumentQna = (chatbotId: string,payload:object) =>
  DeleteWithPayload(`${config.API_URL}/chatbot/documents/qna/${chatbotId}`,payload);
