import { handleTextChange } from "@common/handleChange";
import InputRuleForm from "@components/InputRuleForm/form";
import Loader from "@components/common/Loader/loading";
import { getValue } from "@utils/lodash";
import {
  Alert,
  Button,
  Form,
  Select,
  Slider,
  SliderSingleProps,
  Tag,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";

function SettingsAI(props: any) {
  const { request, setRequest, isLoading, instructionList } = props;

  const marks: SliderSingleProps["marks"] = {
    0: "Reserved",
    1: "Creative",
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div className="border rounded-3 p-4 w-80">
        <div className="d-flex align-items-center justify-content-between">
          <h6 className="header_text__18">AI</h6>
          <Button
            onClick={() => {
              let info = instructionList.find(
                (item: object) => getValue(item, `value`, "") === "ai_chatbot"
              );
              setRequest({
                ...request,
                instructions_type: "ai_chatbot",
                instructions: getValue(info, `description`, ""),
              });
            }}
          >
            Reset
          </Button>
        </div>
        <div className="my-3">
          {/* <h6 className="header_text__16 mb-2">
            Model{" "}
            <Tag color="purple">Claude and Gemini models are now available</Tag>
          </h6> */}
          <Form.Item label="Select Model">
            <Select
              style={{ width: "100%" }}
              placeholder="Select a model"
              defaultValue={getValue(request, `model`, "")}
              onChange={(value: string) =>
                setRequest({
                  ...request,
                  model: value,
                })
              }
              options={props.aiModelOptions}
            />
          </Form.Item>
        </div>
        <div className="mt-4">
          <div className="d-flex justify-content-between align-items-center ">
            <h6 className="header_text__16">Instructions</h6>

            <Select
              style={{ width: "300px" }}
              defaultValue={getValue(request, `instructions_type`, "")}
              options={instructionList}
              showSearch
              onChange={(e: any) => {
                let info = instructionList.find(
                  (item: object) => getValue(item, `value`, "") === e
                );
                setRequest({
                  ...request,
                  instructions_type: e,
                  instructions: getValue(info, `description`, ""),
                });
              }}
            />
          </div>
          <InputRuleForm
            inputType="TEXTAREA"
            name="instructions"
            value={getValue(request, "instructions", "")}
            placeholder="Enter a Instructions"
            label=""
            onChange={(e: any) => handleTextChange(e, request, setRequest)}
            // disabled
            style={{ minHeight: "400px" }}
          />
        </div>
        <Alert
          showIcon
          message="The instructions allow you to customize your chatbot's personality and style. Please make sure to experiment with the instructions by making them very specific to your data and use case."
        />
        <div className="mt-4">
          <h6 className="header_text__16">Temperature</h6>
          <Slider
            marks={marks}
            value={getValue(request, `temp`, 0)}
            defaultValue={getValue(request, `temp`, 0)}
            min={0}
            max={1}
            step={0.1}
            onChange={(value: number) => {
              setRequest({
                ...request,
                temp: value,
              });
            }}
            className="mx-5"
          />
        </div>
      </div>
      <div className=" rounded-3 p-4 mt-4">
        <Alert
          message="Training"
          description="Last trained at December 21, 2024 at 02:46 PM"
          type="info"
          className=""
        />
        <div className="mt-3 d-flex flex-column mb-3">
          <label className="form-label">Retraining frequency</label>

          <Select
            style={{ width: "300px" }}
            defaultValue={getValue(request, `retraining_interval`, "")}
            options={[
              { label: "Never", value: null },
              { label: "Every 24 hours", value: 24 },
            ]}
            onChange={(e: any) => {
              setRequest({
                ...request,
                retraining_interval: e,
              });
            }}
          />
        </div>
      </div>
    </>
  );
}

export default SettingsAI;
