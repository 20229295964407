import { getValue } from "@utils/lodash";
import "../broadcastTemplate.scss";
import { Button, Modal } from "antd";
import NewSearchSelect from "@components/custom/Dropdown/AntDropDown";

function AddWhatsappVariable(props: any) {
  const {
    isOpen,
    handleModal,
    handleAddTags,
    allTags,
    tagsByPipelineID,
    allPipelinesList,
    pipelineId,
    adminTags,
  } = props;

  return (
    <Modal
      open={isOpen}
      onCancel={handleModal}
      footer={[
        <Button key="cancel" onClick={handleModal} className="cancel__button">
          Cancel
        </Button>,
      ]}
      title="Select Attribute"
    >
      <div className="d-flex gap-3 flex-wrap">
        <p className="sample--template-text mt-1">
          Use variable as placeholders. Variables are filled in automatically
          when they are sent
        </p>
      </div>
      <p className="note mt-3 mb-3 header_blue_text__16">Internal Variables</p>
      <div className="border-bottom"></div>
      <div className="d-flex gap-3 flex-wrap mt-3">
        {getValue(adminTags, `length`, 0) > 0 &&
          adminTags.map((item: any, index: number) => (
            <button
              key={index}
              className="dashboard-button  hover-text touchable-text-white px-4 py-2"
              onClick={() => {
                handleAddTags(item);
                handleModal();
              }}
            >
              {getValue(item, `label`, "")}
            </button>
          ))}
      </div>
      <p className="note mt-3 mb-3 header_blue_text__16">External Variables</p>
      <div className="border-bottom"></div>
      <div className="px-3 py-2">
        <NewSearchSelect
          size="large"
          optionsData={allPipelinesList}
          selectKey="value"
          value={pipelineId}
          onChange={(e: any) => tagsByPipelineID(e)}
          label="Contact Pipelines"
          placeholder="Choose Pipeline"
        />
      </div>
      <div className="d-flex gap-3 flex-wrap mt-3 px-3">
        {getValue(allTags, `length`, 0) > 0 &&
          allTags.map((item: any, index: number) => (
            <Button
              color="default"
              variant="dashed"
              key={index}
              className=""
              onClick={() => {
                handleAddTags(item);
                handleModal();
              }}
            >
              {getValue(item, `label`, "")}
            </Button>
          ))}
      </div>
    </Modal>
  );
}

export default AddWhatsappVariable;
