import { useState, useEffect } from "react";
import {
  Table,
  Button,
  Typography,
  Input,
  Row,
  Col,
  Pagination,
  Form,
  InputNumber,
  Tooltip,
  message,
} from "antd";
import {
  PlusOutlined,
  MinusCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { getValue } from "@utils/lodash";
import { getAllDealSaleItems, updateSale } from "@services/deals.service";
import { Save } from "lucide-react";
import { toast } from "sonner";
import { QueryRequestHelper } from "@common/query-request-helper";
import { useParams } from "react-router-dom";
import AntSearchToolTip from "@components/custom/DebounceSelect";

const { Text } = Typography;

interface Product {
  key?: string;
  action: string;
  name?: string;
  list_price: number;
  quantity: number;
  discount: number;
  total: number;
  sale_item_id: string;
  id?: string;
  temp_id: string;
}

export default function DealProductForm(props: any) {
  const params = useParams();
  const [form] = Form.useForm();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [availableProducts, setAvailableProducts] = useState<Product[]>([]);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  useEffect(() => {
    getData();
  }, [searchQuery, currentPage, pageSize]);

  // Generate a unique ID for temporary products
  const generateTempId = () =>
    `temp_${Date.now()}_${Math.random().toString(36).substring(2, 9)}`;

  const calculateTotal = (price: number, qty: number, disc: number): number => {
    const numPrice = Number(price) || 0;
    const numQty = Number(qty) || 1;
    const numDisc = Number(disc) || 0;
    return Number((numPrice * numQty * (1 - numDisc / 100)).toFixed(2));
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const payload = {
        search: searchQuery,
        page_no: currentPage,
        page_size: pageSize,
        deal_id: getValue(params, "id", ""),
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getAllDealSaleItems(
        getValue(params, "id", ""),
        queryRequest
      );
      if (resp) {
        const products = getValue(resp, "data.deal_sale_items", []);
        setAvailableProducts(products);

        const mappedProducts = products.map((product: any) => {
          const tempId = generateTempId();
          const unitPrice =
            Number(
              getValue(product, "list_price", 0) ||
                getValue(product, "unit_price", 0)
            ) || 0;
          const quantity = Number(getValue(product, "quantity", 1)) || 1;
          const discount = Number(getValue(product, "discount", 0)) || 0;
          return {
            ...product,
            id: getValue(product, "id", ""),
            sale_item_id: getValue(product, "sale_item_id", ""),
            name: getValue(product, "name", ""),
            list_price: unitPrice,
            quantity: quantity,
            discount: discount,
            total: calculateTotal(unitPrice, quantity, discount),
            action: "update",
            temp_id: tempId,
          };
        });

        setSelectedProducts(mappedProducts);

        const formValues: any = {};
        mappedProducts.forEach((product: Product) => {
          formValues[`list_price_${product.temp_id}`] = product.list_price;
          formValues[`quantity_${product.temp_id}`] = product.quantity;
          formValues[`discount_${product.temp_id}`] = product.discount;
          formValues[`sale_item_${product.temp_id}`] = product.sale_item_id;
        });
        form.setFieldsValue(formValues);

        setTotalCount(getValue(resp, "data.pagination.total", 0));
      }
    } catch (error) {
      toast.error("Failed to fetch products");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page: number, size: number) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const addProduct = () => {
    const tempId = generateTempId();
    const newProduct: Product = {
      action: "add",
      list_price: 0,
      quantity: 1,
      discount: 0,
      total: 0,
      sale_item_id: "",
      name: "",
      temp_id: tempId,
    };

    setSelectedProducts((prev) => [...prev, newProduct]);
    form.setFieldsValue({
      [`list_price_${tempId}`]: 0,
      [`quantity_${tempId}`]: 1,
      [`discount_${tempId}`]: 0,
      [`sale_item_${tempId}`]: "",
    });
  };

  const removeProduct = (tempId: string) => {
    setSelectedProducts((prev) => {
      const index = prev.findIndex((p) => p.temp_id === tempId);
      if (index === -1) return prev;

      const product = prev[index];
      if (product.id) {
        return prev.map((p) =>
          p.temp_id === tempId ? { ...p, action: "remove" } : p
        );
      } else {
        return prev.filter((p) => p.temp_id !== tempId);
      }
    });
  };

  const updateProductSelect = (tempId: string, value: any) => {
    const selectedSaleItemId = getValue(value, "id", "");

    const isDuplicate = selectedProducts.some(
      (p) =>
        p.sale_item_id === selectedSaleItemId &&
        p.temp_id !== tempId &&
        p.action !== "remove"
    );

    if (isDuplicate) {
      toast.error("This product is already in the list.");
      return;
    }

    setSelectedProducts((prev) =>
      prev.map((product) => {
        if (product.temp_id !== tempId) return product;

        const updatedProduct = {
          ...product,
          sale_item_id: selectedSaleItemId,
          name: getValue(value, "label", ""),
          list_price: Number(getValue(value, "unit_price", 0)) || 0,
          quantity: Number(getValue(value, "quantity", 1)) || 1,
          discount: Number(getValue(value, "discount", 0)) || 0,
          action: product.id ? "update" : "add",
        };

        updatedProduct.total = calculateTotal(
          updatedProduct.list_price,
          updatedProduct.quantity,
          updatedProduct.discount
        );

        form.setFieldsValue({
          [`list_price_${tempId}`]: updatedProduct.list_price,
          [`quantity_${tempId}`]: updatedProduct.quantity,
          [`discount_${tempId}`]: updatedProduct.discount,
          [`sale_item_${tempId}`]: selectedSaleItemId,
        });

        return updatedProduct;
      })
    );
  };

  const updateProduct = (
    tempId: string,
    field: string,
    value: number | null
  ) => {
    setSelectedProducts((prev) =>
      prev.map((product) => {
        if (product.temp_id !== tempId) return product;

        let numValue = Number(value) || 0;
        if (field === "discount") {
          numValue = Math.max(0, Math.min(100, numValue));
        } else if (field === "quantity") {
          numValue = Math.max(1, numValue);
        } else if (field === "list_price") {
          numValue = Math.max(0, numValue);
        }

        const updatedProduct = {
          ...product,
          [field]: numValue,
          action: product.id ? "update" : "add",
        };

        updatedProduct.total = calculateTotal(
          updatedProduct.list_price,
          updatedProduct.quantity,
          updatedProduct.discount
        );

        return updatedProduct;
      })
    );
  };

  const calculateGrandTotal = (): number => {
    return Number(
      selectedProducts
        .filter((item) => item.action !== "remove")
        .reduce((sum, product) => sum + product.total, 0)
        .toFixed(2)
    );
  };

  const validateProducts = () => {
    const activeProducts = selectedProducts.filter(
      (p) => p.action !== "remove"
    );

    if (activeProducts.length === 0) return false;

    const hasInvalidProduct = activeProducts.some(
      (product) =>
        !product.sale_item_id ||
        product.list_price === undefined ||
        product.list_price === null ||
        product.list_price < 0 ||
        product.quantity < 1 ||
        product.discount < 0 ||
        product.discount > 100
    );

    const productIds = new Set();
    const hasDuplicates = activeProducts.some((product) => {
      if (!product.sale_item_id) return false;
      if (productIds.has(product.sale_item_id)) return true;
      productIds.add(product.sale_item_id);
      return false;
    });

    return !hasInvalidProduct && !hasDuplicates;
  };

  const handleSubmit = async () => {
    try {
      const validationResult = validateProducts();
      if (!validationResult) {
        toast.error("Please fill all required fields correctly");
        return;
      }

      setSubmitLoading(true);
      const payload = {
        deal_sale_items: selectedProducts.map((item) => ({
          id: item.action === "add" ? "" : item.id || "",
          sale_item_id: item.sale_item_id,
          action: item.action,
          quantity: Number(item.quantity),
          discount: Number(item.discount),
          list_price: Number(item.list_price),
          total: Number(item.total),
        })),
        amount: calculateGrandTotal(),
      };

      const resp = await updateSale(getValue(params, "id", ""), payload);

      if (resp) {
        toast.success("Saved Successfully");
        await getData();
        props.getData?.(false);
      }
    } catch (error) {
      toast.error("Failed to save products");
    } finally {
      setSubmitLoading(false);
    }
  };

  const columns: ColumnsType<Product> = [
    {
      title: "PRODUCT",
      key: "product",
      render: (_, record) => (
        <Form.Item
          // name={`sale_item_${record.temp_id}`}
          rules={[{ required: true, message: "Please select a product" }]}
          style={{ marginBottom: 0 }}
        >
          <AntSearchToolTip
            value={record.sale_item_id}
            onChange={(value: any, data: any) =>
              updateProductSelect(record.temp_id, data)
            }
            lookup_api="sale_items"
            className="w-100"
            selectKey="id"
            style={{ maxWidth: "240px" }}
          />
        </Form.Item>
      ),
    },
    {
      title: "LIST PRICE (₹)",
      key: "list_price",
      width: 160,
      render: (_, record) => (
        <Form.Item
          name={`list_price_${record.temp_id}`}
          rules={[{ required: true, message: "Price required" }]}
          style={{ marginBottom: 0 }}
        >
          <InputNumber
            min={0}
            value={record.list_price}
            style={{ width: "100%" }}
            onChange={(value) =>
              updateProduct(record.temp_id, "list_price", value)
            }
          />
        </Form.Item>
      ),
    },
    {
      title: "QUANTITY",
      key: "quantity",
      width: 120,
      render: (_, record) => (
        <Form.Item
          name={`quantity_${record.temp_id}`}
          rules={[{ required: true, message: "Quantity required" }]}
          style={{ marginBottom: 0 }}
        >
          <InputNumber
            min={1}
            value={record.quantity}
            style={{ width: "100%" }}
            onChange={(value) =>
              updateProduct(record.temp_id, "quantity", value)
            }
          />
        </Form.Item>
      ),
    },
    {
      title: "DISCOUNT (%)",
      key: "discount",
      width: 120,
      render: (_, record) => (
        <Form.Item
          name={`discount_${record.temp_id}`}
          rules={[
            {
              validator: (_, value) => {
                if (value < 0 || value > 100) {
                  return Promise.reject("Discount must be between 0-100%");
                }
                return Promise.resolve();
              },
            },
          ]}
          style={{ marginBottom: 0 }}
        >
          <InputNumber
            min={0}
            max={100}
            value={record.discount}
            formatter={(value) => `${value}%`}
            parser={(value) => (value ? parseFloat(value.replace("%", "")) : 0)}
            style={{ width: "100%" }}
            onChange={(value) =>
              updateProduct(record.temp_id, "discount", value)
            }
          />
        </Form.Item>
      ),
    },
    {
      title: "TOTAL",
      key: "total",
      width: 160,
      render: (_, record) => (
        <Tooltip title="Calculated: Price × Quantity × (1 - Discount%)">
          <Text strong>{record.total.toFixed(2)}</Text>
        </Tooltip>
      ),
    },
    {
      title: "ACTION",
      key: "action",
      width: 80,
      render: (_, record) => (
        <Button
          type="text"
          icon={<MinusCircleOutlined />}
          onClick={() => removeProduct(record.temp_id)}
        />
      ),
    },
  ];

  return (
    <div className="product-management-antd">
      <Form form={form} layout="vertical">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <Col xs={24} sm={12} md={8} lg={6}>
            <Input
              placeholder="Search products..."
              prefix={<SearchOutlined />}
              value={searchQuery}
              onChange={handleSearch}
              allowClear
            />
          </Col>
          <div className="d-flex align-items-center gap-2">
            <Button icon={<PlusOutlined />} onClick={addProduct}>
              Add Product
            </Button>
            {!getValue(props, "hideSave", false) && (
              <Button
                type="primary"
                icon={<Save size={16} />}
                disabled={submitLoading || !validateProducts()}
                loading={submitLoading}
                onClick={handleSubmit}
              >
                Save
              </Button>
            )}
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={selectedProducts.filter((p) => p.action !== "remove")}
          pagination={false}
          bordered
          loading={isLoading}
          rowKey={(record) => record.temp_id}
          summary={() => (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={4}>
                  <Text strong>Grand Total</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <Text strong type="success">
                    {calculateGrandTotal().toFixed(2)}
                  </Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} />
              </Table.Summary.Row>
            </Table.Summary>
          )}
          scroll={{
            x: "maxScroll",
            y: "calc(100vh - 350px)",
          }}
        />

        {totalCount > 0 && (
          <Row justify="end" style={{ marginTop: "16px" }}>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={totalCount}
              showSizeChanger
              showQuickJumper
              showTotal={(total) => `Total ${total} items`}
              onChange={handlePageChange}
              onShowSizeChange={handlePageChange}
            />
          </Row>
        )}
      </Form>
    </div>
  );
}
