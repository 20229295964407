import React, { useState } from "react";
import { Button, ColorPicker, Modal } from "antd";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { handleTextChange } from "common/handleChange";

function TicketStatusModal(props: any) {
  const { isOpen, request, setRequest, handleSubmit, editId, onClose } = props;
  const [color, setColor] = useState(
    getValue(request, "color_code", "#1677ff")
  );

  const handleColorChange = (colorObj: any) => {
    const selectedColor = colorObj.toHexString();
    setColor(selectedColor);
    setRequest({ ...request, color_code: selectedColor });
  };

  return (
    <Modal
      open={isOpen}
      onOk={() => {
        handleSubmit();
      }}
      onCancel={() => {
        onClose();
      }}
      okText={editId ? "Update" : "Submit"}
      cancelText="Cancel"
      okButtonProps={{
        disabled:
          !getValue(request, `label`, "") ||
          !getValue(request, `color_code`, ""),
      }}
    >
      <h6 className="header_text__16">{`${
        editId ? "Update" : "Add"
      } Status`}</h6>
      <div
        className="popup-container_create_stage px-5 mb-3 mt-1"
        style={{ minHeight: "20vh" }}
      >
        <InputRuleForm
          inputType="TEXTAREA"
          label={"Name"}
          placeholder="Enter"
          name={"label"}
          value={getValue(request, `label`, "")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleTextChange(e, request, setRequest)
          }
          rows={3}
        />

        {/* Ant Design ColorPicker */}
        <ColorPicker
          value={getValue(request, "color_code", "")} // Set the current color
          size="large"
          showText
          onChange={handleColorChange}
        />
      </div>
    </Modal>
  );
}

export default TicketStatusModal;
