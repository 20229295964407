import React from "react";
import { Bold, Italic, Strikethrough, Type, Code } from "lucide-react";
import { Button, Tooltip } from "antd";

interface FormatButton {
  icon: React.ReactNode;
  format: string;
  label: string;
}

const formatButtons: FormatButton[] = [
  { icon: <Bold size={18} color="#606060" />, format: "*", label: "Bold" },
  { icon: <Italic size={18} color="#606060" />, format: "_", label: "Italic" },
  {
    icon: <Strikethrough size={18} color="#606060" />,
    format: "~",
    label: "Strikethrough",
  },
  // { icon: Code, format: "```", label: "Code" },
  // { icon: Type, format: "```", label: "Monospace" },
];

interface FormatToolbarProps {
  onFormat: (format: string) => void;
}

export default function FormatToolbar({ onFormat }: FormatToolbarProps) {
  return (
    <>
      {formatButtons.map((button) => (
        <Button
          type="text"
          shape="circle"
          key={button.label}
          onClick={() => onFormat(button.format)}
          title={button.label}
        >
          {button.icon}
        </Button>
      ))}
    </>
  );
}
