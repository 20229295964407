import { handleSelectArrayChange } from "@common/handleChange";
import { getListOfModules } from "@services/sla-policy.services";
import { getValue } from "@utils/lodash";
import { Alert, Select } from "antd";
import { PlusCircle, Trash2 } from "lucide-react";
import { useCallback, useEffect, useState, useMemo } from "react";
import { toast } from "sonner";

const ConditionManagerHoc = ({
  editId,
  request,
  setRequest,
  conditionList,
  isLoading1,
  validator,
  array,
  isSkills,
  matchType,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<any[][]>([]);

  const getLookupAPIs = (value: string) => {
    const lookupMap: { [key: string]: string } = {
      companies: "records/companies/list",
      group_id: "groups/list",
      product_id: "products/list",
      priority_id: "priorities/list",
    };
    return lookupMap[value] || "";
  };

  const fetchLookupOptions = async (fieldName: string) => {
    setIsLoading(true);
    try {
      const response = await getListOfModules(getLookupAPIs(fieldName));
      const data =
        (response as any).data?.map((item: any) => ({
          value: item.id,
          label: item.name,
        })) || [];
      return data;
    } catch (error) {
      toast.error("Failed to load options");
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const getOptionsForFieldType = useCallback(
    async (fieldName: string) => {
      const fieldObject = conditionList.find(
        (item: any) => item.fieldName === fieldName
      );

      if (!fieldObject) {
        console.error(
          `Field with name ${fieldName} not found in conditionList.`
        );
        return [];
      }
      const fieldType = getValue(fieldObject, "fieldType", "");
      if (fieldType === "lookup") {
        return await fetchLookupOptions(fieldName);
      }
      if (fieldType === "select") {
        return getValue(fieldObject, "dropDownOpts", []);
      }

      return [];
    },
    [conditionList]
  );

  const fetchAndSetOptionsForCondition = async (
    fieldName: string,
    index: number
  ) => {
    const fieldOptions = await getOptionsForFieldType(fieldName);
    setOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[index] = fieldOptions;
      return newOptions;
    });
  };

  const fetchOptions = async () => {
    const fieldName = getValue(request[array][0], "field_name", "");
    const fieldOptions = await getOptionsForFieldType(fieldName);
    setOptions([fieldOptions]);
  };

  useEffect(() => {
    if (editId) {
      // Fetch options for existing conditions when editId exists
      request[array]?.forEach((condition: any, index: number) => {
        if (condition.field_name) {
          fetchAndSetOptionsForCondition(condition.field_name, index);
        }
      });
    } else if (request[array]?.length) {
      // If no editId, but conditions exist, fetch options for those conditions
      request[array].forEach((condition: any, index: number) => {
        if (condition.field_name) {
          fetchAndSetOptionsForCondition(condition.field_name, index);
        }
      });
    } else {
      // Fetch default options if there is no editId and no conditions
      fetchOptions();
    }
  }, [editId, conditionList, request[array]]);

  const handleSelectCondition = (index: number, newValues: string[]) => {
    setRequest((prevRequest: any) => ({
      ...prevRequest,
      [array]: prevRequest[array]?.map((condition: any, idx: number) =>
        idx === index
          ? {
              ...condition,
              value: newValues,
            }
          : condition
      ),
    }));
  };

  const handleIfCondition = (index: number, value: string) => {
    handleSelectArrayChange(index, { value }, "field_name", array, setRequest);
    setRequest((prevRequest: any) => {
      const updatedConditions = [...prevRequest[array]];
      updatedConditions[index] = {
        ...updatedConditions[index],
        value: [],
      };
      return {
        ...prevRequest,
        [array]: updatedConditions,
      };
    });
    fetchAndSetOptionsForCondition(value, index);
  };

  const deleteConditionArray = (index: number) => {
    setRequest((prevRequest: any) => ({
      ...prevRequest,
      [array]: prevRequest[array]?.filter((_: any, i: number) => i !== index),
    }));
  };

  const addNewCondition = () => {
    setRequest((prevRequest: any) => {
      const newCondition = isSkills
        ? { field_name: "", operator: "is", value: [] }
        : { field_name: "", value: [] };
      const updatedConditions = [...prevRequest[array], newCondition];
      fetchAndSetOptionsForCondition("", updatedConditions.length - 1);
      return { ...prevRequest, [array]: updatedConditions };
    });
  };

  const memoizedOptions = useMemo(() => options, [options]);

  return (
    <>
      <div className="mt-2 sla-condition-card-wrapper">
        {getValue(request, array, []).map((condition: any, index: number) => (
          <div className={`sla-condition-card p-4 border-bottom`} key={index}>
            <div className="d-grid gap-2 w-90">
              <div className="d-flex align-items-center gap-2 w-100">
                {isSkills && (
                  <p>
                    In <b>Tickets</b>
                  </p>
                )}
                <div className="w-25">
                  <Select
                    prefix="If"
                    onChange={(value) => handleIfCondition(index, value)}
                    value={getValue(condition, "field_name", "")}
                    placeholder="Choose Condition"
                    options={conditionList}
                    loading={isLoading1}
                    className="w-100"
                  />
                  {!getValue(request, "is_default", false) &&
                    validator?.current?.message && (
                      <p className={`error-text`}>
                        {validator.current.message(
                          "If condition",
                          getValue(condition, "field_name", ""),
                          "required"
                        )}
                      </p>
                    )}
                </div>
                {isSkills && (
                  <Select
                    onChange={(value) =>
                      handleSelectArrayChange(
                        index,
                        { value },
                        "operator",
                        array,
                        setRequest
                      )
                    }
                    value={getValue(condition, "operator", "")}
                    placeholder="Choose Operator"
                    options={[
                      { value: "is", label: "Is" },
                      { value: "is_not", label: "Is not" },
                    ]}
                    className="w-25"
                  />
                )}
              </div>
              {getValue(condition, "field_name", "") && (
                <div className="w-100">
                  <Select
                    mode="multiple"
                    size="large"
                    showSearch
                    value={getValue(condition, "value", [])}
                    allowClear
                    onChange={(newValues: any) =>
                      handleSelectCondition(index, newValues)
                    }
                    placeholder="Select one or more values"
                    options={memoizedOptions[index] || []}
                    loading={isLoading}
                    className="w-100"
                  />
                  {!getValue(request, "is_default", false) &&
                    validator?.current?.message && (
                      <p className={`error-text`}>
                        {validator.current.message(
                          "Condition options",
                          getValue(condition, "value", ""),
                          "required"
                        )}
                      </p>
                    )}
                </div>
              )}
            </div>
            {request[array].length > 1 && (
              <Trash2
                size={18}
                className="delete-sla-condition"
                onClick={() => deleteConditionArray(index)}
              />
            )}
            {request[array].length > 1 &&
              index !== request[array].length - 1 && (
                <div className="and-condition-button border">
                  {matchType ? matchType : "AND"}
                </div>
              )}
          </div>
        ))}
        <div
          className="header_blue_text__15 d-flex align-items-center gap-1 cursor-pointer px-4 py-3"
          onClick={addNewCondition}
        >
          <PlusCircle size={18} /> Add Condition
        </div>
      </div>
      {request[array].length < 2 && (
        <Alert
          showIcon
          type="info"
          message="Choose at least one condition."
          className="w-fit-content mt-2"
        />
      )}
    </>
  );
};

export default ConditionManagerHoc;
