import "./AddPopup.scss";
import { Tabs } from "antd";
import AddExistingEmployee from "./AddExistingEmployee/AddExistingEmployee";
import React, { useEffect, useRef, useState } from "react";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "common/query-request-helper";
import { getListOfAllContacts } from "@services/contacts.service";
import { getListOfAllCompanies } from "@services/companies.service";
import { getListOfAllSales } from "@services/deals.service";
import FormBuilder from "@components/Builder/FormBuilder";
import SubmitButtonContainer from "@components/common/ButtonContainer/SubmitButtonContainer";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import ActivityLoader from "@components/common/Loader/activity-loader";
import { getListOfAllLeads } from "@services/leads.service";
import AntSubmitContainer from "@components/common/ButtonContainer/NewSubmitContainer";
import NewSearchSelect from "@components/custom/Dropdown/AntDropDown";

const { TabPane } = Tabs;

export default function AddPopup(props: any) {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setSearchText("");
      setList([]);
    }
  };

  const [search_text, setSearchText] = useState("");
  const [listLoading, setListLoading] = React.useState(false);
  const [list, setList] = useState([]);

  const getListOfContacts = async (e: string) => {
    setSearchText(e);
    try {
      let payload = {
        search: e,
        page_no: 1,
        page_size: 30,
      };
      setListLoading(true);
      let queryRequest = QueryRequestHelper(payload);
      let resp;
      if (props.module === "contacts") {
        resp = await getListOfAllContacts(queryRequest);
      } else if (props.module === "companies") {
        resp = await getListOfAllCompanies(queryRequest);
      } else if (props.module === "sales" || props.module === "deals") {
        resp = await getListOfAllSales(queryRequest);
      } else if (props.module === "leads") {
        resp = await getListOfAllLeads(queryRequest);
      }
      if (resp) {
        setList(getValue(resp, `data`, []));
        setListLoading(false);
      } else {
        setListLoading(false);
      }
    } catch (error) {
      setListLoading(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (!props.disabled) {
        props.handleSubmit(getValue(props, `module`, ""));
      }
    }
  };

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.focus();
    }
  }, []);
  return (
    <div>
      <Tabs activeKey={activeTab} onChange={toggle} className="px-4">
        <TabPane tab="Create New" key="1">
          {props.formLoading ? (
            <ActivityLoader />
          ) : (
            <>
              <div className="form_wrapper_builder-pipeline">
                <NewSearchSelect
                  placeholder={`Choose Status`}
                  validator={props.simpleValidator}
                  options={getValue(props, `stageList`, [])}
                  value={getValue(props, `staticFieldRequest.stage`, "")}
                  onChange={(id: string) =>
                    props.setStaticFieldRequest(
                      (prevStaticFieldRequest: any) => ({
                        ...prevStaticFieldRequest,
                        stage: id,
                      })
                    )
                  }
                  selectKey={"id"}
                  label={"Status"}
                  required={true}
                />
              </div>
              <div tabIndex={0} ref={divRef} onKeyDown={handleKeyDown}>
                <FormBuilder
                  fields={getValue(props, `form`, [])}
                  setFields={props.setForm}
                  simpleValidator={props.simpleValidator}
                  singular_label={props.singular_label}
                />
              </div>
              {/* <AntSubmitContainer
                  handleBackButton={() => {
                    props.toggle();
                  }}
                  handleSubmit={() => {
                    props.handleSubmit(getValue(props, `module`, ""));
                  }}
                  submitLoading={props.formSubmitLoading}
                  first_title={"Cancel"}
                  second_title={"Create"}
                  hideNew
                  disabled={props.disabled}
                  disableValidation={props.disableValidation}
                  permissions={getValue(props, `permissions`, [])}
                /> */}
            </>
          )}
        </TabPane>
        {!getValue(props, `hideList`, false) && (
          <TabPane tab="Add Existing" key="2">
            <AddExistingEmployee
              selectedList={props.selectedList}
              list={list}
              listLoading={listLoading}
              search_text={search_text}
              handleAssociate={props.handleAssociate}
              handleDisAssociate={props.handleDisAssociate}
              getListOfContacts={getListOfContacts}
              module={props.module}
            />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
}
