import Loader from "@components/common/Loader/loading";
import InfiniteScroll from "react-infinite-scroll-component";
import ConversationMessages from "./conversation-messages";
import { getValue } from "@utils/lodash";
import AccountCircleSvgComponent from "@assets/svg/account-circle";
import { formatString } from "@common/text-helpers";
import { Badge, Button, Progress, Space } from "antd";
import { ChevronDown, Upload as UploadIcon } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";

function ConversationScroll(props: any) {
  const {
    conversation,
    fetchMoreMessages,
    m_total_count,
    conversationLoading,
    setSelectedReplyMessage,
    handleAddToDocuments,
    selectedContact,
    contactInfo,
    handleAddContact,
    isLeadShow,
    selectedModule,
    messagesEndRef,
    broadcastEnable,
    showDownButton,
    scrollToBottom,
    divRef,
    unreadCountForScroll,
    handleUploadDocument,
    uploadLoading,
  } = props;

  const scrollDivRef = useRef<HTMLDivElement | null>(null);
  const [dragActive, setDragActive] = useState(false);

  // Handle paste events
  useEffect(() => {
    const handlePaste = (e: ClipboardEvent) => {
      const items = e.clipboardData?.items;
      if (!items) return;

      for (let i = 0; i < items.length; i++) {
        if (items[i].kind === "file") {
          const file = items[i].getAsFile();
          if (file) {
            handleUploadDocument([file]);
            e.preventDefault();
            break;
          }
        }
      }
    };

    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, [handleUploadDocument]);

  // Handle drag and drop
  const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer?.files && e.dataTransfer?.files.length > 0) {
      const filesArray = Array.from(e.dataTransfer?.files);
      handleUploadDocument(filesArray);
    }
  };

  // For manual file selection
  const uploadProps = {
    beforeUpload: (file: File) => {
      handleUploadDocument([file]);
      return false; // Prevent default upload behavior
    },
    showUploadList: false,
  };

  return (
    <>
      <div className="chat-section position-relative">
        {uploadLoading && (
          <>
            <Progress
              percent={100}
              status="active"
              strokeColor={{
                "0%": "#108ee9",
                "100%": "#87d068",
              }}
              showInfo={false} // Hide default percentage text
              size={[300, 30]}
              className="media-upload-progress"
            />
            <span className="media-upload-progress-text">
              <i>Uploading file...</i>
            </span>
          </>
        )}
        <AnimatePresence>
          {showDownButton && (
            <motion.div
              className="live-chat-scroll-bottom-button"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.3 }}
            >
              <div className="position-relative">
                {unreadCountForScroll > 0 && (
                  <Badge
                    color="#25d366"
                    count={unreadCountForScroll}
                    className="live-chat-scroll-bottom-button-badge"
                  />
                )}
                <button
                  onClick={scrollToBottom}
                  aria-label="Scroll to bottom"
                  className="live-chat-scroll-bottom-button-icon"
                >
                  <ChevronDown size={24} color="#212024" />
                </button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <div
          id="scrollableDiv"
          style={{
            maxHeight: "calc(100vh - 60px - 50px)",
            overflow: "auto",
            display: "flex",
            flexDirection: "column-reverse",
            position: "relative",
            overflowX: "hidden",
            height: "100%",
          }}
          ref={(el) => {
            divRef.current = el;
            scrollDivRef.current = el;
          }}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          className={dragActive ? "drag-active" : ""}
        >
          {dragActive && (
            <div className="file-drop-overlay">
              <div className="file-drop-container">
                <UploadIcon size={48} />
                <p>Drop files to upload</p>
              </div>
            </div>
          )}
          <InfiniteScroll
            dataLength={conversation.length}
            next={fetchMoreMessages}
            hasMore={conversation.length < m_total_count}
            loader={conversationLoading ? <Loader small /> : null}
            style={{
              display: "flex",
              flexDirection: "column-reverse",
            }}
            inverse={true} // Scroll from bottom to top
            scrollableTarget="scrollableDiv"
          >
            <ConversationMessages
              conversation={conversation}
              setSelectedReplyMessage={setSelectedReplyMessage}
              handleAddToDocuments={handleAddToDocuments}
            />
          </InfiniteScroll>
          <div className="d-flex justify-content-center">
            <div className="new-contact-preview">
              <AccountCircleSvgComponent color={"#dfe5e7"} size={100} />
              <p className="small_text__18 color_black text-center mb-1 mt-2">
                {getValue(selectedContact, "wa_id", "")}
              </p>
              {getValue(contactInfo, `first_name`, "") ||
              getValue(contactInfo, `last_name`, "") ? (
                <p
                  className="small_text__14 text-center"
                  style={{ textAlign: "left" }}
                >
                  ~{formatString(getValue(contactInfo, `first_name`, ""))}{" "}
                  {formatString(getValue(contactInfo, `last_name`, ""))}
                </p>
              ) : (
                <p
                  className="small_text__14 text-center"
                  style={{ textAlign: "left" }}
                >
                  ~
                  {getValue(selectedContact, `wa_profile_name`, "")
                    ? formatString(
                        getValue(selectedContact, `wa_profile_name`, "")
                      )
                    : getValue(selectedContact, `first_name`, "") ||
                      getValue(selectedContact, `last_name`, "")
                    ? formatString(getValue(selectedContact, `first_name`, ""))
                    : ""}
                </p>
              )}
              {!getValue(contactInfo, `id`, "") ? (
                <>
                  <p className="small_text__13 text-center mb-1 mt-2">
                    Not a contact- No groups in common
                  </p>
                  {getValue(props, `permissions`, []).includes("update") && (
                    <Space>
                      <Button
                        size="middle"
                        type="primary"
                        onClick={() => handleAddContact()}
                        className="mt-2"
                      >
                        Add to {isLeadShow() ? "Leads/" : ""}
                        Contacts
                      </Button>
                    </Space>
                  )}
                </>
              ) : (
                <>
                  <p className="small_text__13 text-center mb-1 mt-2">
                    The contact already associated to {selectedModule}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <div ref={messagesEndRef} />
      </div>
    </>
  );
}

export default ConversationScroll;
