import { Link, useNavigate, useParams } from "react-router-dom";
import "./CommonSubHeader.scss";
import { getValue } from "@utils/lodash";
// import CustomStaticSearchableDropdown from "@components/Custom/CustomSearchableDropdown/static-search";
import BackSvgComponent from "assets/svg/back-link";
import ButtonComponent from "@components/Form/Button/Button";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { useState } from "react";
import { useStateContext } from "@context/dataContext";
import TextHeaderTooltip from "@components/custom/Dropdown/TextHeaderTooltip";
import HeaderTooltip from "@components/custom/Dropdown/HeaderTooltip";
import EditSvgComponent from "@assets/svg/edit";
import SelectDropdown from "@components/custom/Dropdown/SelectDropdown";
import {
  createPipeline,
  getSpecificPipeline,
} from "@services/pipeline.service";
import {
  generatePipelinePayload,
  sortPipelineFieldList,
} from "@components/Pages/Pipeline/helpers/create-pipeline-helper";
import { plural, singular } from "pluralize";
import { snakeCase } from "lodash";
import { removeDuplicatesById } from "@components/helpers/request-helper";

export default function SettingsModuleSubHeader(props: any) {
  const {
    pipeline,
    options,
    handleOpenPopup,
    selectedPipeline,
    handleClickPipeline,
    isLoading,
  } = props;
  const mainParams = useParams();
  let main = window.location.pathname.split("/");
  let tab = main[main.length - 3];

  const { allModules } = useStateContext();
  const generateRequestArray = (array: any) => {
    let list = array.filter(
      (item: object) =>
        // getValue(item, `api_name`, "") !== "tasks" &&
        getValue(item, `api_name`, "") !== "dashboard"
    );

    return getValue(list, `length`, 0) > 0
      ? list.map((item: any) => ({
          ...item,
          value: getValue(item, `api_name`, ""),
          label: getValue(item, `linkText`, ""),
        }))
      : [];
  };
  const navigate = useNavigate();
  const handleChange = (value: any) => {
    navigate(
      `/${getValue(
        value,
        `orgID`,
        ""
      )}/settings/crm/module-and-field/manage-fields/fields/${getValue(
        value,
        `id`,
        ""
      )}/${getValue(value, `name`, "")}`
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pipeline section                             */
  /* -------------------------------------------------------------------------- */
  const [pipelineLoading, setPipelineLoading] = useState(false);
  const handleAddPipeline = async (name: string) => {
    getPipeline(name);
  };

  const getPipeline = async (name: string) => {
    try {
      let resp = await getSpecificPipeline(
        getValue(props, `moduleInfo.id`, ""),
        getValue(options, `[${0}].id`, "")
      );
      if (resp) {
        let list = sortPipelineFieldList(
          getValue(resp, `data.form_fields`, [])
        );
        let formList =
          getValue(list, `length`, 0) > 0
            ? list.filter((item: object) =>
                getValue(item, `form_default`, false)
              )
            : [];

        const openStages = getValue(resp, `data.stages`, []).filter(
          (item: object) => getValue(item, `type`, "") === "OPEN"
        );
        const closedWonStages = getValue(resp, `data.stages`, []).filter(
          (item: object) => getValue(item, `type`, "") === "CLOSED_WON"
        );
        const closedLostStages = getValue(resp, `data.stages`, []).filter(
          (item: object) => getValue(item, `type`, "") === "CLOSED_LOST"
        );
        let obj = {
          name: name,
          singular_label: singular(snakeCase(name)),
          plural_label: plural(snakeCase(name)),
          openStages: openStages.map((item: object) => ({
            name: getValue(item, `api_name`, ""),
            label: getValue(item, `label`, ""),
            type: getValue(item, `type`, ""),
            color_code: getValue(item, `color_code`, ""),
          })),
          closedWonStages: closedWonStages.map((item: object) => ({
            name: getValue(item, `api_name`, ""),
            label: getValue(item, `label`, ""),
            type: getValue(item, `type`, ""),
            color_code: getValue(item, `color_code`, ""),
          })),
          closedLostStages: closedLostStages.map((item: object) => ({
            name: getValue(item, `api_name`, ""),
            label: getValue(item, `label`, ""),
            type: getValue(item, `type`, ""),
            color_code: getValue(item, `color_code`, ""),
          })),
          fields: removeDuplicatesById(formList),
        };
        handleCreatePipeline(obj);
      }
    } catch (error) {}
  };

  const handleCreatePipeline = async (obj: object) => {
    try {
      setPipelineLoading(false);
      const payload = generatePipelinePayload(obj, "");
      let resp = await createPipeline(
        getValue(props, `moduleInfo.id`, ""),
        payload
      );
      if (resp) {
        setPipelineLoading(false);
        handleClickPipeline(getValue(resp, `data`, {}));
      } else {
        setPipelineLoading(false);
      }
    } catch (error) {
      setPipelineLoading(false);
    }
  };
  return (
    <div className={"common-subheader"}>
      <div className={"settings-header-wrapper"}>
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center gap-2">
            <Link to={props.linkAddress} className="dashboard-button p-0">
              <img
                src="/images/icons/back-icon.svg"
                width={24}
                height={24}
                alt="back"
              />
            </Link>

            {/* <h6 className={`${"common-subheader-wrapper__submenu-name"} mx-3`}>
              {props.subHeaderTitle}
            </h6> */}
            <TextHeaderTooltip
              data={
                getValue(allModules, `length`, 0) > 0
                  ? generateRequestArray(
                      allModules.filter(
                        (item: object) =>
                          getValue(item, `api_name`, "") !== "calls" &&
                          getValue(item, `api_name`, "") !== "meetings" &&
                          getValue(item, `api_name`, "") !== "tickets"
                      )
                    )
                  : []
              }
              label={"label"}
              title={getValue(props, `moduleInfo.label`, "")}
              value={getValue(props, `moduleInfo.api_name`, "")}
              onChange={handleChange}
              width={"270px"}
              selectKey={"value"}
              editInline
            />
            {props.isTitleVisible && (
              <>
                <div>
                  <img
                    src="/images/header/right-arrow.svg"
                    className="img-fluid"
                  />
                </div>
                <p className={"Service-text"}>Service</p>
              </>
            )}

            {pipeline && (
              <div>
                <SelectDropdown
                  placeholder={" Pipeline"}
                  data={options}
                  value={getValue(selectedPipeline, `id`, "")}
                  selectKey={"id"}
                  nameKey={"Pipeline"}
                  onAdd={handleAddPipeline}
                  isLoading={pipelineLoading}
                  onSelect={handleClickPipeline}
                />
                {/* <SearchToolTip
                label={"label"}
                value={getValue(selectedPipeline, `id`, "")}
                onChange={handleClickPipeline}
                width={"280px"}
                data={options}
                button_title={"Create Pipeline"}
                handleOpenPopup={handleOpenPopup}
                isLoading={isLoading}
                add
              /> */}
              </div>
            )}
          </div>
          {tab !== "stages" && (
            <div className="d-flex align-items-center">
              {props.isTitleVisible && (
                <button className={"preview-btn"}>Preview</button>
              )}
              {props.isSubmitVisible ? (
                <ButtonComponent buttonType="primary" buttonText="Submit" />
              ) : (
                props.isSubmitVisible && (
                  <ButtonComponent
                    buttonType="primary"
                    buttonText={
                      getValue(props, `submitLoading`, false)
                        ? "Please wait..."
                        : "Save"
                    }
                    onClickHandler={props.handleSubmit}
                    disabled={
                      getValue(props, `submitLoading`, false) ? true : false
                    }
                  />
                )
              )}
            </div>
          )}
          {/* {tab == "stages" && (
            <ButtonComponent
              style={{ border: "1px solid white" }}
            ></ButtonComponent>
          )} */}

          {getValue(props, `pipeline`, false) && (
            <div
              className=" d-flex align-items-center p-2 cursor-pointer"
              style={{ right: 10 }}
              onClick={props.toggle}
            >
              <EditSvgComponent color={"#408dfb"} />
              <p className="small_text__14 color_primary ms-2">Edit Info</p>
            </div>
          )}
          {/* <div className="" onClick={props.toggle}>
            <ButtonComponent
              buttonType={"secondary"}
              buttonText={"Edit Info"}
              onClickHandler={() => {
                props.toggle();
              }}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}
