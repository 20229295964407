import { handleAntSelectChange, handleTextChange } from "@common/handleChange";
import AntDropDown from "@components/custom/Dropdown/AntDropDown";
import FormDescription from "@components/custom/FormDescrption/FormDescription";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Modal } from "antd";

const order = [
  { value: "alphabetically", label: "Alphabetically (A-Z)" },
  {
    value: "creation_date_latest",
    label: "Creation Date (Latest to Oldest)",
  },
  {
    value: "creation_date_oldest",
    label: "Creation Date (Oldest to Latest)",
  },
  {
    value: "modified_date_latest",
    label: "Modified Date (Latest to Oldest)",
  },
  {
    value: "modified_date_oldest",
    label: "Modified Date (Oldest to Latest)",
  },
];

const visibility = [
  { value: "all_users", label: "All Users" },
  { value: "logged_in_users", label: "Logged in Users" },
  { value: "all_agents", label: "All Agents" },
  { value: "bot", label: "Bot" },
];

const SolutionsFolderModal = (props: any) => {
  const {
    openCreateFolder,
    request,
    setRequest,
    isLoading,
    handleCancelFolder,
    handleFolderSubmit,
    categoriesDropdownList,
    validator,
    hideCategory,
    editId,
  } = props;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleFolderSubmit();
    }
  };

  return (
    <Modal
      title={`${editId ? "Update" : "Create"} Folder`}
      open={openCreateFolder}
      onOk={editId ? handleFolderSubmit : handleFolderSubmit}
      okText={editId ? "Update" : "Create"}
      confirmLoading={isLoading}
      onCancel={handleCancelFolder}
      keyboard={true}
    >
      <div className="d-grid gap-2 mb-4" onKeyDown={handleKeyDown} tabIndex={0}>
        <InputRuleForm
          inputType="TEXT"
          name="name"
          value={getValue(request, "name", "")}
          placeholder="Enter Folder Name"
          required
          label="Folder Name (For Own Reference)"
          onChange={(e: any) => handleTextChange(e, request, setRequest)}
          validator={validator}
          validLeft
        />
        {/* <FormDescription
          request={request}
          textClassname=""
          inputClassname=""
          input={
            <InputRuleForm
              inputType="TEXTAREA"
              name="description"
              value={getValue(request, `description`, "")}
              placeholder="Enter Description"
              label="Description"
              onChange={(e: any) => handleTextChange(e, request, setRequest)}
            />
          }
        /> */}
        {!hideCategory && (
          <AntDropDown
            label="Category"
            placeholder="Select Category"
            value={getValue(request, `category_id`, "")}
            onChange={(value: string) =>
              handleAntSelectChange(value, "category_id", request, setRequest)
            }
            options={categoriesDropdownList}
            required
            validator={validator}
          />
        )}
        {/* <AntDropDown
          label="Parent Folder"
          placeholder="Select Parent Folder"
          value={getValue(request, `parent_folder`, "")}
          onChange={(value: string) =>
            handleAntSelectChange(value, "parent_folder", request, setRequest)
          }
          options={[
            { value: "jack", label: "Jack" },
            { value: "lucy", label: "Lucy" },
            { value: "Yiminghe", label: "yiminghe" },
            { value: "disabled", label: "Disabled", disabled: true },
          ]}
        /> */}
        {editId && (
          <>
            <AntDropDown
              label="Order Articles"
              placeholder="Select Article Order"
              value={getValue(request, `article_order`, "")}
              onChange={(value: string) =>
                handleAntSelectChange(
                  value,
                  "article_order",
                  request,
                  setRequest
                )
              }
              options={order}
              required
            />
            <AntDropDown
              label="Visible to"
              placeholder="Select Visible to"
              value={getValue(request, `visibility`, "")}
              onChange={(value: string) =>
                handleAntSelectChange(value, "visibility", request, setRequest)
              }
              options={visibility}
              required
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default SolutionsFolderModal;
