// export function formatMarkdownText(text: string): string {
//   if (text) {
//     return text
//       .replace(/\*(.*?)\*/g, "<strong>$1</strong>")
//       .replace(/_(.*?)_/g, "<em>$1</em>")
//       .replace(/~(.*?)~/g, "<del>$1</del>")
//       .replace(/```(.*?)```/g, "<code>$1</code>");
//   } else {
//     return text;
//   }
// }

export function formatMarkdownText(text: string): string {
  if (!text) {
    return text;
  }
  // Step 1: Split text into parts, preserving [[...]] sections
  const parts: string[] = [];
  let currentIndex = 0;

  text.replace(/\[\[(.*?)\]\]/g, (match, content, index) => {
    // Add text before the match, if any
    if (index > currentIndex) {
      parts.push(text.slice(currentIndex, index));
    }
    // Add the match itself (including [[ and ]])
    parts.push(match);
    currentIndex = index + match.length;
    return match; // This return value is unused but required by replace
  });

  // Add any remaining text after the last match
  if (currentIndex < text.length) {
    parts.push(text.slice(currentIndex));
  }

  // Step 2: Apply markdown formatting only to non-[[...]] parts
  const formattedParts = parts.map(part => {
    if (part.startsWith('[[') && part.endsWith(']]')) {
      return part; // Skip formatting for [[...]] sections
    }
    return part
      .replace(/\*(.*?)\*/g, "<strong>$1</strong>")
      .replace(/_(.*?)_/g, "<em>$1</em>")
      .replace(/~(.*?)~/g, "<del>$1</del>")
      .replace(/```(.*?)```/g, "<code>$1</code>");
  });

  // Step 3: Join the parts back together
  return formattedParts.join('');
}

export function stripFormatting(text: string): string {
  if (text) {
    // Convert WhatsApp markdown to WhatsApp's format
    return text
      .replace(/\*(.*?)\*/g, "*$1*") // Bold stays as is
      .replace(/_(.*?)_/g, "_$1_") // Italic stays as is
      .replace(/~(.*?)~/g, "~$1~") // Strikethrough stays as is
      .replace(/```(.*?)```/g, "```$1```"); // Monospace stays as is
  } else {
    return text;
  }
}
