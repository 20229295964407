import React, { useEffect, useState } from "react";
import "./TaskCompanies.scss";
import { getValue } from "@utils/lodash";
import TaskDeatilCompaniesList from "./CompaniesList";
import SimpleReactValidator from "simple-react-validator";
import { useParams } from "react-router-dom";
import { createSubPipeline } from "@services/common.service";
import { toast } from "sonner";
import DescriptionInfo from "../common/DescInfo";
import { generatePipelineAssociationPayload } from "@components/helpers/request-helper";
import { handleFormValidation } from "@components/custom/validation";
import ConfirmModal from "@components/Dialogs/Modals/confirmModal";
import TaskPendingLayout from "../../components/TaskPendingLayout/TaskPendingLayout";
import PendingTaskCommonTitle from "../common/PendingTaskCommonTitle/PendingTaskCommonTitle";
import PendingTaskCommonDescription from "../common/PendingTaskCommonDescription/PendingTaskCommonDescription";
import AddInfoPopup from "../common/AddPopup/AddInfoPopup";

export default function TaskCompanies(props: any) {
  const [isActive2, setIsActive2] = useState(false);
  useEffect(() => {
    if (getValue(props, `data.companies.length`, 0) > 0) {
      setIsActive2(true);
    }
  }, [getValue(props, `data.companies`, [])]);
  const [isRollsPopupVisibile, setRollsPopupVisisbility] = useState(false);
  const handleOpenForm = () => {
    props.getForm("companies");
    setRollsPopupVisisbility(true);
  };
  const [isRollsListPopupVisibile, setRollsListPopupVisisbility] =
    useState(false);
  //delete section
  const [deleteId, setDeleteId] = useState("");
  const [deleteValue, setDeleteValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const handleDeleteView = (id: string, value: string) => {
    setDeleteId(id);
    setDeleteValue(value);
    handleModal();
  };
  // const handleSubmit = async () => {
  //    props.handleSubmit(props.create_module);
  // };
  /* -------------------------------------------------------------------------- */
  /*                     Generating Contact Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [, forceUpdate] = React.useState(0);
  const params = useParams();
  const [formSubmitLoading, setFormSubmitLoading] = React.useState(false);
  const handleSubmit = async (module: string) => {
    if (module) {
      // const formValid = simpleValidator.current.allValid();
      // if (!formValid) {
      //   simpleValidator.current.showMessages();
      //   forceUpdate(1);
      // } else {
      let fields = JSON.parse(JSON.stringify(props.form));
      let associations = [
        { api_name: props.module, record_id: getValue(params, `id`, "") },
      ];
      let payloadRequest = generatePipelineAssociationPayload(
        module,
        fields,
        props.staticFieldRequest,
        associations
      );
      try {
        setFormSubmitLoading(true);
        let resp = await createSubPipeline(payloadRequest, module);
        if (resp) {
          toast.success("Created & Associated Successfully");
          props.getData(false);
          simpleValidator.current.hideMessages();
          forceUpdate(0);
          setRollsPopupVisisbility(false);
          setFormSubmitLoading(false);
        } else {
          setFormSubmitLoading(false);
        }
      } catch (error) {
        setFormSubmitLoading(false);
      }
      // }
    }
  };
  const errors = handleFormValidation(props.form);
  const hasErrors = errors.length > 0;

  return (
    <>
      <TaskPendingLayout>
        <PendingTaskCommonTitle
          commonTitle={
            getValue(props, `allModules`, []).find(
              (item: any) => item.api_name === "companies"
            )?.linkText
          }
          count={
            getValue(props, `data.companies.length`, 0) < 9 &&
            getValue(props, `data.companies.length`, 0) !== 0
              ? `${getValue(props, `data.companies.length`, 0)}`
              : getValue(props, `data.companies.length`, 0)
          }
          isLinkVisible={true}
          handleToggle={() => {
            setIsActive2(!isActive2);
          }}
          triggerAddPopupSlidingFromRight={() => {
            handleOpenForm();
          }}
        />
        <div
          className={`${
            isActive2 ? "showdiv" : "hidediv"
          } ${"accordian-wrapper"}`}
        >
          <PendingTaskCommonDescription description="See the businesses or organizations associated with this record." />
          <div>
            <ul>
              {getValue(props, `data.companies`, []).map(
                (item: object, index: number) => {
                  if (index < 2)
                    return (
                      <DescriptionInfo
                        index={index}
                        item={item}
                        module={"companies"}
                        handleDeleteView={handleDeleteView}
                      />
                    );
                }
              )}
            </ul>
            {getValue(props, `data.companies.length`, 0) > 2 ? (
              <p
                className={"pending-task_more_info_text"}
                onClick={() => setRollsListPopupVisisbility(true)}
              >
                View More Companies
              </p>
            ) : null}
          </div>
        </div>
      </TaskPendingLayout>
      {/* ----------------- Contact Form Popup ------------------ */}
      <AddInfoPopup
        isOpen={isRollsPopupVisibile}
        setIsOpen={setRollsPopupVisisbility}
        title="Add Companies"
        width={"590px"}
        form={props.form}
        setForm={props.setForm}
        formLoading={props.formLoading}
        //submit section
        handleSubmit={handleSubmit}
        formSubmitLoading={formSubmitLoading}
        simpleValidator={props.simpleValidator}
        handleAssociate={props.handleAssociate}
        handleDisAssociate={props.handleDisAssociate}
        //static List
        module={"companies"}
        pipelineList={props.pipelineList}
        stageList={props.stageList}
        staticFieldRequest={props.staticFieldRequest}
        setStaticFieldRequest={props.setStaticFieldRequest}
        getSpecificPipelineInfo={props.getSpecificPipelineInfo}
        selectedList={
          getValue(props, `data.companies.length`, 0) > 0
            ? getValue(props, `data.companies`, []).map((item: object) =>
                getValue(item, `id`, "")
              )
            : []
        }
        disabled={hasErrors ? true : false}
        disableValidation
        permissions={getValue(props, `permissions`, [])}
      />

      {/* ----------------- Companies Form List ------------------ */}
      <TaskDeatilCompaniesList
        isRollsListPopupVisibile={isRollsListPopupVisibile}
        setRollsListPopupVisisbility={setRollsListPopupVisisbility}
        companies={getValue(props, `data.companies`, [])}
        handleDeleteView={handleDeleteView}
        module={"companies"}
      />
      <ConfirmModal
        isOpen={isOpen}
        handleModal={handleModal}
        handleSubmit={() => {
          props.handleDisAssociate(deleteId, "companies");
          setTimeout(() => {
            handleModal();
          }, 300);
        }}
        // deleteValue={deleteValue}
      />
    </>
  );
}
