import qs from "query-string";

export const QueryRequestHelper = (props: any) => {
  let payload = { ...props };
  Object.keys(payload).forEach((key) => {
    if (
      (!payload[key] ||
        payload[key] === undefined ||
        (Array.isArray(payload[key]) && payload[key].length === 0)) &&
      typeof payload[key] !== "boolean"
    ) {
      delete payload[key];
    }
  });
  let queryRequest = qs.stringify(payload, {
    arrayFormat: "bracket",
    encode: false,
  });
  return queryRequest;
};

export const QueryRequestHelperWithAll = (props: any) => {
  let payload = { ...props };
  Object.keys(payload).forEach((key) => {
    if (
      (!payload[key] ||
        payload[key] === undefined ||
        payload[key] === "all" ||
        (Array.isArray(payload[key]) && payload[key].length === 0)) &&
      typeof payload[key] !== "boolean"
    ) {
      delete payload[key];
    }
  });
  let queryRequest = qs.stringify(payload, {
    arrayFormat: "bracket",
    encode: false,
  });
  return queryRequest;
};

export const QueryRequestHelperWithRepeat = (props: any) => {
  let payload: Record<string, any> = { ...props };

  // Convert arrays into multiple key-value pairs
  const transformedPayload: Record<string, any> = {};
  Object.keys(payload).forEach((key) => {
    if (Array.isArray(payload[key])) {
      payload[key].forEach((value: string) => {
        transformedPayload[key] = transformedPayload[key]
          ? [...transformedPayload[key], value]
          : [value];
      });
    } else {
      transformedPayload[key] = payload[key];
    }
  });

  // Remove empty values
  Object.keys(transformedPayload).forEach((key) => {
    if (
      (!transformedPayload[key] ||
        transformedPayload[key] === undefined ||
        (Array.isArray(transformedPayload[key]) &&
          transformedPayload[key].length === 0)) &&
      typeof transformedPayload[key] !== "boolean"
    ) {
      delete transformedPayload[key];
    }
  });

  // Generate query string
  let queryRequest = qs.stringify(transformedPayload, {
    arrayFormat: "none", // Prevents `[]` brackets from appearing
    encode: false,
  });

  return queryRequest;
};

export const removeBracketsFromQuery = (query: any) => {
  // Split the query into key-value pairs
  const parts = query.split("&");

  // Map through the parts, removing '[]' from the keys
  const updatedParts = parts.map((part: any) => {
    const [key, value] = part.split("=");
    const updatedKey = key.replace(/\[\]/g, ""); // Remove the brackets
    return `${updatedKey}=${value}`;
  });

  // Join the updated parts back into a query string
  return updatedParts.join("&");
};
