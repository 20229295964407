import React from "react";
import "./nodata.scss";
import { Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";

function BroadCastNoDataPage(props: any) {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <div className="n_container">
      <div className="nodata_container">
        <div className="d-flex justify-content-center align-items-center">
          <div className="nodata_container_main">
            <h6 className="nodata_container_header">
              Review past broadcast in Appzo.
            </h6>
            <p className="nodata_container_desc mt-2">
              Broadcast will appear here if you have broadcasted through Appzo.
            </p>
            {!props.isConfig && (
              <Button
                onClick={() =>
                  navigate(
                    `/${getValue(params, "orgId", "")}/settings/chat/messages`
                  )
                }
                type="primary"
                size="large"
                className="mt-3"
              >
                Configure Whatsapp
              </Button>
            )}
          </div>
          <div className="w-10"></div>
          <img
            src={"/nodata/announcement.svg"}
            alt="SVG"
            className="nodata_container_image"
          />
        </div>
      </div>
    </div>
  );
}

export default BroadCastNoDataPage;
