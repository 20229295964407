import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";
import {
  createArticle,
  createCategory,
  createCategoryFolder,
  deleteCategory,
  getCategories,
  getSpecificArticle,
  getSpecificCategoryFolder,
  updateArticle,
  updateCategory,
  updateCategoryFolder,
} from "@services/solutions.service";
import { getValue } from "@utils/lodash";
import { Button, Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import SimpleReactValidator from "simple-react-validator";
import CategoryDetailsLeftSide from "./components/CategoryDetailsLeftSide";
import CategoryDetailsRightSide from "./components/CategoryDetailsRightSide";
import { QueryRequestHelper } from "@common/query-request-helper";
import SolutionsCategoryModal from "./components/SolutionsCategoryModal";
import "./solutions.scss";
import SolutionsFolderModal from "./components/Folders/SolutionsFolderModal";
import SidebarLayout from "@layouts/SidebarLayout/SidebarLayout";
import { useStateContext } from "@context/dataContext";

const CategoryFolders = (props: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const { bookmarkedModules, themeColor, refreshBookmarks } = useStateContext();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const [viewOnly, setViewOnly] = useState(false);
  const toggleViewOption = () => {
    setViewOnly(!viewOnly);
  };

  //categories
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(100);
  const [page_no, setPage_no] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [categoriesList, setCategoriesList] = useState<any>([]);

  const [articleDetails, setArtcileDetails] = useState({});
  const [categoriesRequest, setCategoriesRequest] = useState({
    name: "",
    description: "",
  });
  const [openCreateCategories, setOpenCreateCategories] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryModalId, setCategoryModalId] = useState("");
  const [isCategoryCreationOpen, setIsCategoryCreationOpen] = useState(false);

  //
  const [selectedFolder, setSelectedFolder] = useState("");
  const [selectedArticle, setSelectedArticle] = useState("");
  const [isDataFetched, setDataFetched] = useState(false);

  //Folder
  const [openCreateFolder, setOpenCreateFolder] = useState(false);
  const [foldersRequest, setFoldersRequest] = useState<any>({
    name: "",
    description: "",
    category_id: "",
    parent_folder: "",
    article_order: "alphabetically",
    visibility: "all_users",
  });
  const [editFolderId, setEditFolderId] = useState("");
  const [isLoading4, setIsLoading4] = useState(false);
  const [isLoading5, setIsLoading5] = useState(false);
  const [categoriesDropdownList, setCategoriesDropdownList] = useState<any>([]);

  //Artcile
  const [articleRequest, setArticleRequest] = useState<any>({
    title: "",
    description: {},
    status: "draft",
    folder_id: "",
    tags: [],
    attachments: [],
  });
  const [previousArticle, setPreviousArticle] = useState({});
  const [nextArticle, setNextArticle] = useState({});

  //Tags
  const [isTagsVisible, setIsTagsVisible] = useState(false);
  const handleChange = (value: any) => {
    setArticleRequest((prev: any) => ({
      ...prev,
      tags: value,
    }));
  };

  const toggleTags = () => {
    setIsTagsVisible(!isTagsVisible);
  };

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (Object.keys(params).length === 1) {
      // getCategoriesList(true);
      if (categoriesList.length > 0) {
        navigate(
          `/${getValue(params, "orgId", "")}/solutions/${
            categoriesList[0].id
          }/folders/${categoriesList[0].folders[0].id}/article/${
            categoriesList[0].folders[0].solution_articles[0].id
          }`
        );
      }
    }
  }, [categoriesList]);

  useEffect(() => {
    if (getValue(params, "id", "")) {
      setSelectedCategory(getValue(params, "id", ""));
    }
    if (getValue(params, "folderId", "")) {
      setSelectedFolder(getValue(params, "folderId", ""));
    }
    if (getValue(params, "articleId", "")) {
      setSelectedArticle(getValue(params, "articleId", ""));
    }
    getCategoriesList(limit);
  }, [params]);

  useEffect(() => {
    if (selectedArticle) {
      handleGetArticle(selectedArticle);
    }
  }, [selectedArticle]);

  const fetchMoreData = async () => {
    const newPageSize = categoriesList.length + limit;
    await getCategoriesList(newPageSize);
  };

  /* -------------------------------------------------------------------------- */
  /*                              Categories API Section                        */
  /* -------------------------------------------------------------------------- */
  const handleCategoriesSubmit = async () => {
    setIsLoading1(true);
    try {
      let payload = JSON.parse(JSON.stringify(categoriesRequest));
      const resp = categoryModalId
        ? await updateCategory(categoryModalId, payload)
        : await createCategory(payload);
      if (resp) {
        // toast.success(
        //   `${seletedCategory ? "Updated" : "Created"} Successfully`
        // );
        setSelectedCategory("");
        setCategoryModalId("");
        setCategoriesRequest({
          name: "",
          description: "",
        });
        setOpenCreateCategories(false);
        setIsCategoryCreationOpen(false);
        getCategoriesList(limit);
      } else {
        toast.error(
          `Failed ${categoryModalId ? "updating" : "creating"} Category`
        );
      }
    } catch (error) {
      toast.error(
        `Error ${categoryModalId ? "updating" : "creating"} Category`
      );
    } finally {
      setIsLoading1(false);
    }
  };

  const getCategoriesList = async (pageSize: number) => {
    try {
      setIsLoading(true);
      const payload = {
        page_size: pageSize,
        page_no: 1,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getCategories(queryRequest);
      if (resp) {
        const modifiedResp = getValue(resp, "data.categories", []).map(
          (item: any) => ({
            value: getValue(item, "id", ""),
            label: getValue(item, "name", ""),
          })
        );
        if (getValue(resp, "data.categories.length", 0) < totalCount) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
        setCategoriesDropdownList(modifiedResp);
        setCategoriesList(getValue(resp, "data.categories", []));
        setPage_no(1);
        setTotalCount(getValue(resp, "data.pagination.total", 0));
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Failed to load categories.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteCategory = (id: string, name: string) => {
    Modal.confirm({
      title: `Are you sure you want to delete category - ${name}?`,
      content: "Once deleted, this category cannot be recovered.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await deleteCategory(id);
          toast.success("Category deleted successfully");
          getCategoriesList(limit);
        } catch (error) {
          toast.error("Error deleting category");
        }
      },
    });
  };

  const handleGetSpecificFolder = async (id: string) => {
    try {
      setIsLoading2(true);
      const resp = await getSpecificCategoryFolder(id);
      if (resp) {
        setFoldersRequest(() => ({
          name: getValue(resp, "data.name", ""),
          description: getValue(resp, "data.description", ""),
          category_id: getValue(resp, "data.category_id", ""),
          parent_folder: getValue(resp, "data.id", ""),
          article_order: getValue(resp, "data.article_order", ""),
          visibility: getValue(resp, "data.visibility", ""),
        }));
      }
    } catch (error) {
      toast.error("Failed to load folder.");
    } finally {
      setIsLoading2(false);
    }
  };

  const handleGetArticle = async (id: string) => {
    try {
      setIsLoading3(true);
      const resp = await getSpecificArticle(id);
      if (resp) {
        setArtcileDetails(getValue(resp, "data", {}));
        setArticleRequest(() => ({
          title: getValue(resp, "data.title", ""),
          description: getValue(resp, "data.description", {}),
          status: getValue(resp, "data.status", ""),
          folder_id: selectedFolder,
          tags: getValue(resp, "data.tags", []),
          attachments: [],
        }));
        setIsTagsVisible(false);
        setDataFetched(!isDataFetched);
      }
    } catch (error) {
      toast.error("Failed to load article.");
    } finally {
      setIsLoading3(false);
    }
  };

  const subMenuArray = [
    {
      linkText: `Categories`,
      linkUrl: "",
    },
  ];

  const showCategoryModal = (
    id?: string,
    name?: string,
    description?: string
  ) => {
    if (id) {
      setCategoryModalId(id);
      setCategoriesRequest({
        name: name ? name : "",
        description: description ? description : "",
      });
    }
    setOpenCreateCategories(true);
  };

  const handleCancelCategories = () => {
    setOpenCreateCategories(false);
    setCategoriesRequest({
      name: "",
      description: "",
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                              Folder Section                        */
  /* -------------------------------------------------------------------------- */
  const handleFolderSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      // toast.error(`Please fill all the mandatory fields`);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setIsLoading4(true);
      try {
        let payload = JSON.parse(JSON.stringify(foldersRequest));
        const resp = editFolderId
          ? await updateCategoryFolder(editFolderId, payload)
          : await createCategoryFolder(payload);
        if (resp) {
          // toast.success(`${editFolderId ? "Updated" : "Created"} Successfully`);
          setFoldersRequest({
            name: "",
            description: "",
            category_id: "",
            parent_folder: "",
            article_order: "alphabetically",
            visibility: "all_users",
          });
          forceUpdate(0);
          simpleValidator.current.hideMessages();
          setOpenCreateFolder(false);
          setEditFolderId("");
          getCategoriesList(limit);
        } else {
          toast.error(
            `Failed ${editFolderId ? "updating" : "creating"} Folder`
          );
        }
      } catch (error) {
        toast.error(`Error ${editFolderId ? "updating" : "creating"} Folder`);
      } finally {
        setIsLoading4(false);
      }
    }
  };

  const handleCancelFolder = () => {
    setOpenCreateFolder(false);
    setFoldersRequest({
      name: "",
      description: "",
      category_id: "",
      parent_folder: "",
      article_order: "alphabetically",
      visibility: "all_users",
    });
    forceUpdate(0);
    simpleValidator.current.hideMessages();
    setEditFolderId("");
  };

  const handleFolderEdit = (folderId: string) => {
    setEditFolderId(folderId);
    handleGetSpecificFolder(folderId);
    setOpenCreateFolder(true);
  };

  const handleCreateFolderModal = (categoryId: string) => {
    setOpenCreateFolder(true);
    setFoldersRequest((prev: any) => ({
      ...prev,
      category_id: categoryId,
    }));
  };

  /* -------------------------------------------------------------------------- */
  /*                              Article Section                        */
  /* -------------------------------------------------------------------------- */
  const handleCreateArticle = async (categoryId: string, folderId: string) => {
    setIsLoading5(true);
    try {
      const resp = await createArticle({
        title: "Untitled page",
        description: {},
        status: "draft",
        folder_id: folderId,
        tags: [],
        attachments: [],
      });
      if (resp) {
        navigate(
          `/${getValue(
            params,
            "orgId",
            ""
          )}/solutions/${categoryId}/folders/${getValue(
            resp,
            "data.folder_id",
            ""
          )}/article/${getValue(resp, "data.id", "")}`
        );
        handleGetArticle(getValue(resp, "data.id", ""));
      } else {
        toast.error(`Failed creating Article`);
      }
    } catch (error) {
      toast.error(`Error creating Article`);
    } finally {
      setIsLoading5(false);
    }
  };

  const handleSubmitArticle = async (request: any) => {
    setIsLoading5(true);
    try {
      let payload = JSON.parse(JSON.stringify(request));
      const resp = await updateArticle(
        getValue(params, "articleId", ""),
        payload
      );
      if (resp) {
        setArticleRequest({
          title: "",
          description: {},
          status: "",
          folder_id: "",
          tags: [],
          attachments: [],
        });
        setIsTagsVisible(false);
        handleGetArticle(getValue(params, "articleId", ""));
        getCategoriesList(limit);
      } else {
        toast.error(`Failed updating Article`);
      }
    } catch (error) {
      toast.error(`Error updating Article`);
    } finally {
      setIsLoading5(false);
    }
  };

  // const handleArticleOptionsUpdate = () => {
  //   handleSubmitArticle({
  //     folder_id: getValue(articleRequestCopy, "folder_id", ""),
  //     tags: getValue(articleRequestCopy, "tags", {}),
  //   });
  //   setArticleOptions(false);
  // };

  return (
    <SidebarLayout {...props} isLoading={isLoading}>
      <PipelineSubHeader
        subHeaderListTitle={`Knowledge Base`}
        refreshBookmarks={refreshBookmarks}
        bookmarkedModules={bookmarkedModules}
        themeColor={themeColor}
        bookmarkedModuleName="Knowledge Base"
        bookmarkedModulePath="solutions"
        children={
          <div className="d-flex align-items-center gap-2">
            {selectedArticle && (
              <>
                {/* <Tooltip
                  title={capitalizeFirstLetter(
                    getValue(articleRequestCopy, "status", "")
                  )}
                  placement="bottom"
                >
                  <Switch
                    // size="small"
                    value={
                      getValue(articleRequestCopy, "status", "") === "published"
                        ? true
                        : false
                    }
                    onChange={(checked) => {
                      handleSubmitArticle({
                        folder_id: getValue(
                          articleRequestCopy,
                          "folder_id",
                          ""
                        ),
                        status: checked ? "published" : "draft",
                      });
                    }}
                  />
                </Tooltip> */}
                {getValue(props, `permissions`, []).includes("create") && (
                  <>
                    <Button
                      type="default"
                      size="large"
                      className=""
                      onClick={() =>
                        handleSubmitArticle({
                          folder_id: getValue(articleRequest, "folder_id", ""),
                          description: getValue(
                            articleRequest,
                            "description",
                            {}
                          ),
                          status: "draft",
                          tags: getValue(articleRequest, "tags", []),
                        })
                      }
                      disabled={viewOnly}
                    >
                      Save as Draft
                    </Button>
                    <Button
                      type="primary"
                      size="large"
                      className=""
                      onClick={() =>
                        handleSubmitArticle({
                          folder_id: getValue(articleRequest, "folder_id", ""),
                          description: getValue(
                            articleRequest,
                            "description",
                            {}
                          ),
                          status: "published",
                          tags: getValue(articleRequest, "tags", []),
                        })
                      }
                      disabled={viewOnly}
                    >
                      Save & Publish
                    </Button>
                  </>
                )}
              </>
            )}
          </div>
        }
      />
      <div className={`d-flex w-100 solutions-main-wrapper`}>
        <div className="manageFieldRight w-100 d-flex">
          {categoriesList.length > 0 && (
            <CategoryDetailsLeftSide
              params={params}
              navigate={navigate}
              isLoading={isLoading}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
              handleFolderEdit={handleFolderEdit}
              selectedArticle={selectedArticle}
              handleFolderSubmit={handleFolderSubmit}
              handleGetSpecificFolder={handleGetSpecificFolder}
              foldersRequest={foldersRequest}
              setFoldersRequest={setFoldersRequest}
              categoriesList={categoriesList}
              handleCreateFolderModal={handleCreateFolderModal}
              showCategoryModal={showCategoryModal}
              handleDeleteCategory={handleDeleteCategory}
              getCategoriesList={getCategoriesList}
              setCategoryModalId={setCategoryModalId}
              handleCreateArticle={handleCreateArticle}
              categoriesRequest={categoriesRequest}
              setCategoriesRequest={setCategoriesRequest}
              isCategoryCreationOpen={isCategoryCreationOpen}
              setIsCategoryCreationOpen={setIsCategoryCreationOpen}
              handleCategoriesSubmit={handleCategoriesSubmit}
              articleRequest={articleRequest}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setPreviousArticle={setPreviousArticle}
              setNextArticle={setNextArticle}
              setSelectedArticle={setSelectedArticle}
              limit={limit}
              fetchMoreData={fetchMoreData}
              hasMore={hasMore}
            />
          )}
          <CategoryDetailsRightSide
            params={params}
            navigate={navigate}
            articleDetails={articleDetails}
            isDataFetched={isDataFetched}
            articleRequest={articleRequest}
            setArticleRequest={setArticleRequest}
            viewOnly={viewOnly}
            handleSubmitArticle={handleSubmitArticle}
            previousArticle={previousArticle}
            nextArticle={nextArticle}
            isTagsVisible={isTagsVisible}
            handleChange={handleChange}
            toggleTags={toggleTags}
          />
          {/* <FloatButton
            icon={<Eye size={18} />}
            type={viewOnly ? "primary" : "default"}
            style={{ insetInlineEnd: 34, insetBlockEnd: 74 }}
            tooltip="View"
            onClick={() => toggleViewOption()}
          /> */}
        </div>
      </div>
      {/* Category */}
      <SolutionsCategoryModal
        openCreateCategories={openCreateCategories}
        request={categoriesRequest}
        setRequest={setCategoriesRequest}
        seletedCategory={categoryModalId}
        isLoading={isLoading1}
        handleCategorySubmit={handleCategoriesSubmit}
        handleCancelCategories={handleCancelCategories}
      />
      {/* Folder */}
      <SolutionsFolderModal
        openCreateFolder={openCreateFolder}
        request={foldersRequest}
        setRequest={setFoldersRequest}
        isLoading={isLoading2}
        handleCancelFolder={handleCancelFolder}
        handleFolderSubmit={handleFolderSubmit}
        categoriesDropdownList={categoriesDropdownList}
        validator={simpleValidator}
        hideCategory
        editId={editFolderId}
      />
    </SidebarLayout>
  );
};

export default CategoryFolders;
