import AntSearchToolTip from "@components/custom/DebounceSelect";
import { getValue } from "@utils/lodash";
import React from "react";

const SheetLookupDropdown = React.memo(
  ({
    col,
    row,
    field,
    fieldCol,
    handleDisplayItem,
    handleInputChange,
  }: any) => {
    return (
      <AntSearchToolTip
        size="middle"
        lookup_api={getValue(fieldCol, `lookup.api_name`, "")}
        value={handleDisplayItem(col, row) || undefined}
        placeholder={`Select ${col}`}
        // options={dataInfo}
        onChange={(selectedValue) =>
          handleInputChange(getValue(row, "id", ""), col, selectedValue, field)
        }
        style={{
          minWidth: "160px",
        }}
      />
    );
  }
);

export default SheetLookupDropdown;
