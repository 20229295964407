import { Button } from "antd";
import SettingsGeneral from "./General";
import SettingsAI from "./AI";
import { useEffect, useState } from "react";
import { SettingsRequest } from "./settings-request-helper";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { showAIChatbotInstructions } from "@services/AIBot/settings.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  convertObjectKeystoJSON,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import SettingsNotifications from "./Notifications";
import { getSpecificChatbot, updateChatbot } from "@services/chatbot.service";
import WidgetInterface from "./ChatInterface/widgetInterface";
import SettingsSidebar from "./components/sidebar";
import AIAgentFullLayout from "../../Layout/full-layout";
import { toast } from "sonner";
import { aiChatbotModels } from "@services/ai.service";
import ChatView from "./ChatInterface/chat-view";

export const AIAgentSettings = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const params = useParams();
  const navigate = useNavigate();
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [request, setRequest] = useState(SettingsRequest);
  const reset = () => {
    setRequest(SettingsRequest);
  };
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getActionData();
    getInstructions();
    getAIModels();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const getActionData = async () => {
    try {
      setIsLoading(true);
      // let resp = await getSpecificAIChatbot(getValue(params, `chatbotId`, ""));
      let resp = await getSpecificChatbot(getValue(params, `chatbotId`, ""));
      if (resp) {
        setRequest({
          ...request,
          name: getValue(resp, `data.name`, ""),
          description: getValue(resp, `data.description`, ""),
          enable_credits_limit: getValue(resp, `data.enable_credits_limit`, ""),
          credits_limit: getValue(resp, `data.credits_limit`, ""),
          model: getValue(resp, `data.model`, ""),
          instructions_type: getValue(resp, `data.instructions_type`, ""),
          instructions: getValue(resp, `data.instructions`, ""),
          temp: getValue(resp, `data.temp`, ""),
          type: getValue(resp, `data.type`, ""),
          retraining_interval: getValue(resp, `data.retraining_interval`, ""),
          initial_messages:
            getValue(resp, `data.initial_messages.length`, 0) > 0
              ? getValue(resp, `data.initial_messages`, [])
              : ["Hi! What can I help you with?"].join("\n"),
          suggested_messages: getValue(
            resp,
            `data.suggested_messages`,
            []
          ).join("\n"),
          message_placeholder: getValue(resp, `data.message_placeholder`, ""),
          collect_user_feedback: getValue(
            resp,
            `data.collect_user_feedback`,
            false
          ),
          regenerate_messages: getValue(
            resp,
            `data.regenerate_messages`,
            false
          ),
          footer: getValue(resp, `data.footer`, ""),
          theme: getValue(resp, `data.theme`, ""),
          display_name: getValue(resp, `data.display_name`, ""),
          user_message_color: getValue(resp, `data.user_message_color`, ""),
          header_color: getValue(resp, `data.header_color`, ""),
          button_color: getValue(resp, `data.button_color`, ""),
          align_chat_button: getValue(resp, `data.align_chat_button`, ""),
          // auto_open_chat_window_after: getValue(
          //   resp,
          //   `data.auto_open_chat_window_after`,
          //   0
          // ),
          profile_picture_id: getValue(resp, `data.profile_picture`, {}),
          chat_icon_id: getValue(resp, `data.chat_icon`, {}),
          visibility: getValue(resp, `data.visibility`, ""),
          only_allow_on_added_domains: getValue(
            resp,
            `data.only_allow_on_added_domains`,
            false
          ),
          allowed_domains: getValue(resp, `data.allowed_domains`, []).join(
            "\n"
          ),
          ip_limit: getValue(resp, `data.ip_limit`, 0),
          ip_limit_timeframe: getValue(resp, `data.ip_limit_timeframe`, 0),
          ip_limit_message: getValue(resp, `data.ip_limit_message`, ""),
          daily_conversations_active: getValue(
            resp,
            `data.daily_conversations_active`,
            false
          ),
          daily_conversations_emails: getValue(
            resp,
            `data.daily_conversations_emails`,
            []
          ).join("\n"),
          daily_leads_collected_active: getValue(
            resp,
            `data.daily_leads_collected_active`,
            false
          ),
          daily_leads_collected_emails: getValue(
            resp,
            `data.daily_leads_collected_emails`,
            []
          ).join("\n"),
          position: getValue(resp, `data.position`, ""),
          response_interval: 1,
          idle_chat: getValue(resp, `data.idle_chat`, false),
          idle_chat_time: getValue(resp, `data.idle_chat_time`, ""),
          idle_chat_response: getValue(resp, `data.idle_chat_response`, ""),
          avatar: getValue(resp, `data.avatar`, ""),
          theme_color: getValue(resp, `data.theme_color`, ""),
          enable_email_notification: getValue(
            resp,
            `data.enable_email_notification`,
            false
          ),
          send_email_notification_to: getValue(
            resp,
            `data.send_email_notification_to`,
            []
          ),
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [instructionList, setInstructionList] = useState<any>([]);
  const getInstructions = async () => {
    try {
      let payload = {};
      let queryRequest = QueryRequestHelper(payload);
      let resp = await showAIChatbotInstructions(queryRequest);
      if (resp) {
        let list = convertObjectKeystoJSON(getValue(resp, `data`, {}));
        let mapList: any =
          getValue(list, `length`, 0) > 0
            ? list.map((item: object) => ({
                ...item,
                description: getValue(item, `value`, ""),
                value: getValue(item, `key`, ""),
              }))
            : [];
        setInstructionList(mapList);
      }
    } catch (error) {}
  };

  const [aiModelOptions, setAIModelOptions] = useState([]);
  const getAIModels = async () => {
    try {
      const resp = await aiChatbotModels();
      if (resp) {
        const data = getValue(resp, `data`, {});
        const options: any = Object.entries(data).map(
          ([provider, models]: any) => ({
            label: provider.charAt(0).toUpperCase() + provider.slice(1),
            options: Object.entries(models).map(([key, value]) => ({
              label: value,
              value: key,
              provider: provider,
            })),
          })
        );

        setAIModelOptions(options);
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSave = async () => {
    try {
      // Deep clone the request to avoid mutations
      const payloadReq = JSON.parse(JSON.stringify(request));

      // Helper function to process string arrays
      const processStringArray = (value: string | string[]): string[] => {
        if (typeof value === "string") {
          return value
            .split("\n")
            .map((line) => line.trim())
            .filter((line) => line.length > 0);
        }
        return value;
      };

      // Construct the payload with proper type handling
      const payload = {
        ...request,
        profile_picture_id: getValue(payloadReq, "profile_picture_id.id", ""),
        chat_icon_id: getValue(payloadReq, "chat_icon_id.id", ""),
        credits_limit: parseInt(getValue(payloadReq, "credits_limit", 0), 10),
        temp: parseFloat(getValue(payloadReq, "temp", 0.1)),
        ip_limit: parseInt(getValue(payloadReq, "ip_limit", 0), 10),
        ip_limit_timeframe: parseInt(
          getValue(payloadReq, "ip_limit_timeframe", 0),
          10
        ),
        instructions: getValue(payloadReq, "instructions", ""),
        // Process all array fields consistently
        initial_messages: processStringArray(payloadReq.initial_messages),
        suggested_messages: processStringArray(payloadReq.suggested_messages),
        allowed_domains: processStringArray(payloadReq.allowed_domains),
        daily_conversations_emails: processStringArray(
          payloadReq.daily_conversations_emails
        ),
        daily_leads_collected_emails: processStringArray(
          payloadReq.daily_leads_collected_emails
        ),
      };

      setSubmitLoading(true);

      const chatbotId = getValue(params, "chatbotId", "");
      const cleanPayload = removeNullOrUndefinedProperties(payload);
      const response = await updateChatbot(chatbotId, cleanPayload);

      if (response) {
        toast.success("Updated successfully");
        await getActionData();
      }
    } catch (error) {
      console.error("Failed to update chatbot:", error);
      toast.error("Failed to update chatbot");
    } finally {
      setSubmitLoading(false);
    }
  };

  const [activeTab, setActiveTab] = useState(1);

  return (
    <AIAgentFullLayout
      wrapperClassname="ai-chatbot-settings px-5"
      showButton
      activeTab={activeTab}
      handleSave={handleSave}
      submitLoading={submitLoading}
    >
      <div className="config-container">
        <SettingsSidebar activeTab={activeTab} setActiveTab={setActiveTab} />
        <div className="mb-4 d-flex gap-4">
          <div className="w-100">
            {activeTab === 1 && (
              <SettingsGeneral
                isLoading={isLoading}
                request={request}
                setRequest={setRequest}
              />
            )}
            {activeTab === 2 && (
              <SettingsAI
                isLoading={isLoading}
                request={request}
                setRequest={setRequest}
                instructionList={instructionList}
                aiModelOptions={aiModelOptions}
              />
            )}
            {activeTab === 3 && (
              <WidgetInterface
                isLoading={isLoading}
                request={request}
                setRequest={setRequest}
                instructionList={instructionList}
              />
            )}
            {/* {activeTab === 4 && (
         <div className="d-flex gap-4 ">
          <div className="mb-4">
            <div className="d-flex align-items-center justify-content-end mb-3">
              <Button
                onClick={handleSave}
                disabled={submitLoading}
                loading={submitLoading}
                type="primary"
              >
                Save
              </Button>
            </div>
            {activeTab === 1 && (
              <SettingsGeneral
                isLoading={isLoading}
                request={request}
                setRequest={setRequest}
              />
            )}
            {activeTab === 2 && (
              <SettingsAI
                isLoading={isLoading}
                request={request}
                setRequest={setRequest}
                instructionList={instructionList}
              />
            )}
            {activeTab === 3 && (
              <WidgetInterface
                isLoading={isLoading}
                request={request}
                setRequest={setRequest}
                instructionList={instructionList}
              />
            )}
            {/* {activeTab === 4 && (
            <SettingsSecurity
              isLoading={isLoading}
              request={request}
              setRequest={setRequest}
              instructionList={instructionList}
            />
          )} */}
            {activeTab === 5 && (
              <SettingsNotifications
                isLoading={isLoading}
                request={request}
                setRequest={setRequest}
                instructionList={instructionList}
              />
            )}
          </div>
          {activeTab === 3 && (
            <ChatView
              request={request}
              setRequest={setRequest}
              chatWrapper="settings-chat-wrapper"
              chatContainer="settings-chat-container"
            />
          )}
        </div>
      </div>
    </AIAgentFullLayout>
    // <AIAgentLayout>
    //   <div className="d-flex justify-content-end my-2">
    //     <Button onClick={handleSave} disabled={submitLoading} color="primary">
    //       {submitLoading ? "Please wait..." : "Save"}
    //     </Button>
    //   </div>
    //   <Tabs tabPosition="left" defaultActiveKey="1" items={items} />
    // </AIAgentLayout>
  );
};
