import { useEffect, useRef, useState } from "react";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import { Modal, Pagination } from "antd";
import { Plus } from "lucide-react";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import ListLoader from "@components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import SettingsPageDetails from "@components/Pages/PageDescription/SettingsPageDetails";
import { tagsDetails } from "@common/Data/page-details-data";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "@common/query-request-helper";
import { toast } from "sonner";
import SimpleReactValidator from "simple-react-validator";
import useDynamicTitle from "@context/useDynamicTitle";
import FiltersNoDataPage from "@components/common/NoData/filter-nodata";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import TagTable from "./components/list";
import TagsModal from "./components/modal";
import { usePermissionContext } from "@context/permissionContext";
import {
  createSettingsTag,
  deleteSettingsTag,
  getAllSettingsTags,
  updateSettingsTag,
} from "@services/settings-tags.service";

const SettingsTags = (props: any) => {
  useDynamicTitle("Sale Items");
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [list, setList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [request, setRequest] = useState<any>({
    label: "",
    color_code: "#1677ff",
    value: "",
  });
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const { mainPermissions } = usePermissionContext();

  const moduleMapping: Record<string, string> = {
    crm_leads: "is_leads",
    crm_contacts: "is_contacts",
    crm_companies: "is_companies",
    crm_deals: "is_deals",
    crm_tasks: "is_tasks",
    // crm_calls: "is_calls",
    // crm_meetings: "is_meetings",
    helpdesk_tickets: "is_tickets",
  };

  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getTagsItems(true);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getTagsItems(true);
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getTagsItems = async (status: boolean) => {
    try {
      setIsLoading(status);
      const payload = {
        page_size: getValue(UrlParams, `page_size`, "")
          ? getValue(UrlParams, `page_size`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getAllSettingsTags(queryRequest);
      if (resp) {
        setList(getValue(resp, "data.tags", []));
        setPage_no(getValue(resp, "data.pagination.page", 0));
        setTotalCount(getValue(resp, "data.pagination.total", 0));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Edit Section                                 */
  /* -------------------------------------------------------------------------- */
  const [editId, seteditId] = useState<any>("");
  const [openSpecificTag, setOpenSpecificTag] = useState<any>(false);
  const handleEdit = (item: any) => {
    seteditId(getValue(item, `id`, ""));

    let updatedRequest: any = {
      label: getValue(item, `label`, ""),
      color_code: getValue(item, `color_code`, ""),
      value: getValue(item, `value`, ""),
    };

    if (mainPermissions) {
      const allowedModules = mainPermissions
        .filter((item: any) => Object.keys(moduleMapping).includes(item.tabKey))
        .map((item: any) => moduleMapping[item.tabKey]);

      allowedModules.forEach((key: string) => {
        updatedRequest[key] = getValue(item, key, false); // Include false values too
      });
    }

    setRequest(updatedRequest);
    showModal();
  };

  const toggleSpecificTag = (id: string) => {
    setOpenSpecificTag(id);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Delete Section                               */
  /* -------------------------------------------------------------------------- */
  const handleDeleteModal = (id: string, name: string) => {
    Modal.confirm({
      title: `Are you sure you want to delete tag - ${name}?`,
      content: "Once deleted, this tag cannot be recovered.",
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: {
        disabled: isLoading1,
      },
      onOk: async () => {
        setIsLoading1(true);
        try {
          await deleteSettingsTag(id);
          toast.success("Tag deleted successfully");
          getTagsItems(true);
          setIsLoading1(false);
        } catch (error) {
          toast.error("Error deleting saleItem");
          setIsLoading1(false);
        }
      },
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: any, limit: any) => {
    setPage_no(page_no);
    setLimit(limit);

    let payload = {
      ...UrlParams,
      page_no: page_no,
      page_size: limit,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    setPage_no(page_no);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Other section                                */
  /* -------------------------------------------------------------------------- */

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    seteditId("");
    resetRequest();
  };

  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      // toast.error(`Please fill all the mandatory fields`);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setIsLoading(true);
      try {
        let payload = JSON.parse(JSON.stringify(request));
        payload["unit_price"] = getValue(payload, `unit_price`, 0)
          ? parseFloat(getValue(payload, `unit_price`, 0))
          : "";
        const resp = editId
          ? await updateSettingsTag(
              editId,
              removeNullOrUndefinedProperties(payload)
            )
          : await createSettingsTag(removeNullOrUndefinedProperties(payload));

        if (resp) {
          toast.success(`${editId ? "Updated" : "Created"} Successfully`);
          resetRequest();
          getTagsItems(true);
          seteditId("");
          setIsModalOpen(false);
          simpleValidator.current.hideMessages();
          forceUpdate(0);
        } else {
          toast.error(`Failed to ${editId ? "editing" : "creating"} tag`);
        }
      } catch (error) {
        toast.error(`Error ${editId ? "editing" : "creating"} tag`);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // const handleStatusChange = async (id: string, status: boolean) => {
  //   try {
  //     const payload = {
  //       ids: [id],
  //       is_active: !status,
  //     };
  //     const resp = await bulkUpdateSaleItem(payload);
  //     if (resp) {
  //       toast.success(`Updated Successfully`);
  //       resetRequest();
  //       getTagsItems(false);
  //       seteditId("");
  //       setIsModalOpen(false);
  //     }
  //   } catch (error) {}
  // };

  const resetRequest = () => {
    setRequest({
      label: "",
      value: "",
      color_code: "#1677ff",
    });
  };

  const createNewRequest = () => {
    if (mainPermissions) {
      const allowedModules = mainPermissions
        .filter((item: any) => Object.keys(moduleMapping).includes(item.tabKey))
        .map((item: any) => moduleMapping[item.tabKey]);

      setRequest({
        label: "",
        value: "",
        color_code: "#1677ff",
        ...Object.fromEntries(allowedModules.map((key: any) => [key, true])),
      });
    } else {
      setRequest({ label: "", value: "", color_code: "" });
    }
  };

  console.log("request", request);
  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        icon="tags"
        title="Tags"
        showSubmit
        submitIcon={<Plus size={18} />}
        submitText="New Tag"
        handleSubmit={() => {
          createNewRequest();
          showModal();
        }}
      />
      <div className="sla-policy-wrapper">
        <div className="w-70 sla-policy-card-container">
          {isLoading ? (
            <ListLoader />
          ) : getValue(list, `length`, 0) > 0 ? (
            <TagTable
              list={list}
              handleEdit={handleEdit}
              handleDeleteModal={handleDeleteModal}
              mainPermissions={mainPermissions}
              page_no={page_no}
              limit={limit}
              totalCount={totalCount}
              handleChangePagination={handleChangePagination}
            />
          ) : (
            <FiltersNoDataPage module="Tags" />
          )}
        </div>
        <div className="w-30 p-4 sla-policy-right-details">
          <SettingsPageDetails data={tagsDetails} />
        </div>
      </div>
      <TagsModal
        editId={editId}
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        request={request}
        setRequest={setRequest}
        validator={simpleValidator}
      />
    </CompanyProfileDetailsLayout>
  );
};

export default SettingsTags;
