import { InboxOutlined } from "@ant-design/icons";
import {
  handlePhoneNestedRequestArrayChange,
  handleTextNestedRequestArrayChange,
  handleTextRequestArrayChange,
} from "@common/handleChange";
import { formatString } from "@common/text-helpers";
import PhoneInputComponent from "@components/custom/Forms/PhoneInput";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Alert, Empty, Image, Modal } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { config } from "env";
import React from "react";

const TemplateCarouselModal = (props: any) => {
  const {
    isModalOpen,
    handleOk,
    request,
    setRequest,
    activeCarouselTab,
    handleCancel,
    handleCarouseFileChange,
    handleFileRemove,
    carouselFileLists,
    simpleValidator,
  } = props;

  const imageFileDetails = {
    name: "file",
    multiple: false,
    beforeUpload: () => false,
    accept: ".jpg, .png, ",
    maxFileSize: 2 * 1024 * 1024,
    onRemove: (file: any) => handleFileRemove(file, 0),
  };

  const videoFileDetails = {
    name: "file",
    multiple: false,
    beforeUpload: () => false,
    accept: ".jpg, .png, ",
    maxFileSize: 2 * 1024 * 1024,
    onRemove: (file: any) => handleFileRemove(file, 0),
  };

  return (
    <Modal
      title={`Carousel card ${activeCarouselTab + 1}`}
      open={isModalOpen}
      onOk={handleOk}
      okText="Save"
      // onClose={}
      width={760}
      onCancel={() => handleCancel(activeCarouselTab)}
      key={activeCarouselTab}
    >
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="w-60">
          <p className="form-label">Card Header</p>
          <Dragger
            {...(getValue(request, "carousel[0].header.type", "") === "IMAGE"
              ? imageFileDetails
              : videoFileDetails)}
            fileList={carouselFileLists[activeCarouselTab] || []}
            onChange={(e) => handleCarouseFileChange(e, activeCarouselTab)}
            onRemove={(file) => handleFileRemove(file, activeCarouselTab)}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              {getValue(request, "carousel[0].header.type", "") === "IMAGE"
                ? "Accepted file types: .jpg, .png within 2MB size"
                : "Accepted file types: .mp4, .3gpp within 10MB size"}
            </p>
          </Dragger>
        </div>
        <div className="w-40 d-flex align-items-center justify-content-center">
          {getValue(request, "carousel[0].header.type", "") === "IMAGE" ? (
            <Image
              src={
                getValue(
                  request,
                  `carousel[${activeCarouselTab}].header.link.key`,
                  ""
                )
                  ? `https://${config.URL}/${getValue(
                      request,
                      `carousel[${activeCarouselTab}].header.link.key`,
                      ""
                    )}`
                  : getValue(
                      request,
                      `carousel[${activeCarouselTab}].header.link`,
                      ""
                    )
                  ? `https://${config.URL}/${getValue(
                      request,
                      `carousel[${activeCarouselTab}].header.link`,
                      ""
                    )}`
                  : "/property/dummy-image.jpg"
              }
              width={200}
              height={108}
              style={{
                width: "200px",
                height: "108px",
                objectFit: "cover",
                border:
                  !getValue(
                    request,
                    `carousel[${activeCarouselTab}].header.link.key`,
                    ""
                  ) &&
                  !getValue(
                    request,
                    `carousel[${activeCarouselTab}].header.link`,
                    ""
                  )
                    ? "1px solid red"
                    : "",
              }}
              className="rounded-2"
            />
          ) : (
            <>
              {getValue(
                request,
                `carousel[${activeCarouselTab}].header.link.key`,
                ""
              ) ||
              getValue(
                request,
                `carousel[${activeCarouselTab}].header.link`,
                ""
              ) ? (
                <video autoPlay className="carousal-video-preview w-80">
                  <source
                    src={
                      getValue(
                        request,
                        `carousel[${activeCarouselTab}].header.link.key`,
                        ""
                      )
                        ? `https://${config.URL}/${getValue(
                            request,
                            `carousel[${activeCarouselTab}].header.link.key`,
                            ""
                          )}`
                        : `https://${config.URL}/${getValue(
                            request,
                            `carousel[${activeCarouselTab}].header.link`,
                            ""
                          )}`
                    }
                    type="video/mp4"
                  />
                </video>
              ) : (
                <Empty
                  description="Video not found"
                  className="rounded-2 px-4 pt-2 mt-4"
                  style={{
                    border:
                      !getValue(
                        request,
                        `carousel[${activeCarouselTab}].header.link.key`,
                        ""
                      ) &&
                      !getValue(
                        request,
                        `carousel[${activeCarouselTab}].header.link`,
                        ""
                      )
                        ? "1px solid red"
                        : "",
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
      <Alert
        message="Upload a sample to help us understand what kind of image that you want
        to send to your users. You can change this image anytime later while
        sending this template."
        type="info"
        className="mt-2 my-4"
      />
      <InputRuleForm
        inputType="TEXTAREA"
        label="Card Body"
        placeholder={`Hi [[name]]!🖐\n\nThe awesome products 😍 from [[brand_name]] are waiting to be yours!\n Get your products here👇`}
        value={getValue(request, `carousel.${activeCarouselTab}.body`, "")}
        name="body"
        onChange={(e: any) =>
          handleTextRequestArrayChange(
            activeCarouselTab,
            e,
            "carousel",
            setRequest
          )
        }
        validator={simpleValidator}
        validLeft
        // showCount
        maxLength="1024"
      />
      {getValue(request, `carousel.${activeCarouselTab}.buttons`, []).map(
        (item: object, aIndex: number) => {
          if (getValue(item, `action_type`, ""))
            return (
              <div className="mt-2">
                <p className="small_text__14 mt-3">
                  {formatString(getValue(item, `action_type`, ""))}
                </p>
                {getValue(item, `action_type`, "") === "QUICK_REPLY" ? (
                  <InputRuleForm
                    inputType="TEXT"
                    onChange={(e: any) =>
                      handleTextNestedRequestArrayChange(
                        activeCarouselTab,
                        aIndex,
                        e,
                        "carousel",
                        "buttons",
                        "text",
                        setRequest
                      )
                    }
                    name="text"
                    label="text"
                    value={getValue(item, "text", "")}
                    placeholder="eg. Yes"
                    maxLength={25}
                    // showCount
                    validator={simpleValidator}
                  />
                ) : (
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="w-50 ">
                      <InputRuleForm
                        inputType="TEXT"
                        onChange={(e: any) =>
                          handleTextNestedRequestArrayChange(
                            activeCarouselTab,
                            aIndex,
                            e,
                            "carousel",
                            "buttons",
                            "text",
                            setRequest
                          )
                        }
                        name={"text"}
                        value={getValue(item, "text", "")}
                        placeholder={
                          getValue(item, `action_type`, "") === "URL"
                            ? "eg. Visit website"
                            : "eg. Call us"
                        }
                        label={"Title"}
                        maxLength={20}
                        // showCount
                        validator={simpleValidator}
                      />
                    </div>
                    <div className="w-50 ps-2">
                      {getValue(item, "type", "") === "call" ? (
                        <PhoneInputComponent
                          placeholder="Enter phone number (+91...)"
                          value={getValue(item, "phone", "")}
                          onChange={(e: any) => {
                            handlePhoneNestedRequestArrayChange(
                              activeCarouselTab,
                              aIndex,
                              e,
                              "carousel",
                              "buttons",
                              "phone",
                              setRequest
                            );
                          }}
                          label={"Phone Number"}
                          validator={simpleValidator}
                        />
                      ) : (
                        <InputRuleForm
                          inputType="TEXT"
                          onChange={(e: any) =>
                            handleTextNestedRequestArrayChange(
                              activeCarouselTab,
                              aIndex,
                              e,
                              "carousel",
                              "buttons",
                              getValue(item, "type", "") === "url"
                                ? "url"
                                : "phone",
                              setRequest
                            )
                          }
                          name={"url"}
                          value={getValue(item, "url", "")}
                          placeholder={`Enter ${
                            getValue(item, "type", "") === "url"
                              ? "url"
                              : "Phone Number"
                          }`}
                          label={
                            getValue(item, "type", "") === "url"
                              ? "URL"
                              : "Phone"
                          }
                          validator={simpleValidator}
                          // maxLength={20}
                          // showCount
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            );
        }
      )}
    </Modal>
  );
};

export default TemplateCarouselModal;
