// import Header from "@components/Dashboard/v2/Header/Header";
// import CommonSubHeader from "@components/v2/Settings/CommonSubHeader/CommonSubHeader";
// import ManageFieldsDetails from "@components/v2/Settings/ModuleAndField/ManageFields/ManageFieldsDetails/ManageFieldsDetails";
import {
  UpdateSalesPipelineFields,
  UpdateSalesPipelineInfo,
  UpdateSalesPipelineStages,
  getModuleFieldsById,
} from "@services/modules.service";
import {
  allSalePipelines,
  getSpecificSalePipeline,
  salePipelinesDefaultFields,
} from "@services/sales.pipeline.service";
import { getValue, setValue } from "@utils/lodash";
import { QueryRequestHelper } from "common/query-request-helper";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import "./ManageFields.scss";
import CommonSubHeader from "./components/CommonSubHeader";
import ManageFieldsDetails from "./components/ManageFieldsDetails";

import SidebarLayout from "@layouts/SidebarLayout/SidebarLayout";
export default function ManageFields(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(params).length === 0) {
      getAllPipelines();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      getAllPipelines();
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [selectedPipeline, setSelectedPipeline] = useState("");
  /**
   *
   * Get all Module pipelines
   * @requires
   *
   */

  const [isLoading, setIsLoading] = useState(false);
  const [allPipelines, setAllPipelines] = useState([]);
  const [options, setOptions] = useState([]);
  const getAllPipelines = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    try {
      setIsLoading(true);
      let resp = await allSalePipelines("");
      if (resp) {
        let list = getValue(resp, `data`, []).map((item: object) => ({
          ...item,
          value: getValue(item, `name`, ""),
          label: getValue(item, `name`, ""),
        }));
        setOptions(list);
        if (getValue(params, `pipeline`, "")) {
          setSelectedPipeline(getValue(params, `pipeline`, ""));
          getData(getValue(params, `pipeline`, ""));
        } else {
          setSelectedPipeline(getValue(list, `[${0}].id`, {}));
          getData(getValue(list, `[${0}].id`, {}));
        }
        setAllPipelines(getValue(resp, `data`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /**
   *
   * Get selected pipeline
   * @requires id
   *
   */
  const [optionLoading, setOptionLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const getData = async (id: string) => {
    try {
      setOptionLoading(true);
      let resp = await getSpecificSalePipeline(id);
      if (resp) {
        let selectedFields = getValue(resp, `data.fields`, []).map(
          (item: any) => item.name
        );
        getPredefinedModuleFields(selectedFields);
        setSelectedOption(getValue(resp, `data`, {}));
        setSelectedFields(getValue(resp, `data.fields`, []));
        setStageRequest(getValue(resp, `data.stages`, []));

        setRequest({
          ...request,
          name: getValue(resp, `data.name`, ""),
          singular_label: getValue(resp, `data.singular_label`, ""),
          plural_label: getValue(resp, `data.plural_label`, ""),
        });

        let openStages = getValue(resp, `data.stages`, []).filter(
          (item: object) => getValue(item, `type`, "") === "OPEN"
        );
        let closedWonStages = getValue(resp, `data.stages`, []).filter(
          (item: object) => getValue(item, `type`, "") === "CLOSED_WON"
        );
        let closedLostStages = getValue(resp, `data.stages`, []).filter(
          (item: object) => getValue(item, `type`, "") === "CLOSED_LOST"
        );
        setValue(stageRequest, `openStages`, openStages);
        setValue(stageRequest, `closedWonStages`, closedWonStages);
        setValue(stageRequest, `closedLostStages`, closedLostStages);
        setStageRequest({ ...stageRequest });
        setOptionLoading(false);
      } else {
        setOptionLoading(false);
      }
    } catch (error) {
      setOptionLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                        Pipeline Helper section                             */
  /* -------------------------------------------------------------------------- */
  const findSelectedPipeline = (id: string) => {
    let list =
      getValue(options, `length`, 0) > 0
        ? options.filter((item: object) => getValue(item, `id`, "") === id)
        : [];
    return getValue(list, `length`, 0) > 0 ? getValue(list, `[${0}]`, "") : {};
  };
  const getSelectedPipeline = useMemo(
    () => findSelectedPipeline(selectedPipeline),
    [selectedPipeline]
  );
  const handleChangePipeline = (e: any) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    delete params.pipeline;
    let payload = {
      ...params,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleClickPipeline = (option: any) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let payload = {
      ...params,
      pipeline: getValue(option, `id`, ""),
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                          Modules Fields Section                            */
  /* -------------------------------------------------------------------------- */
  const [selectedFields, setSelectedFields] = useState([]);
  const [unUsedFields, setUnUsedFields] = useState([]);

  const getPredefinedModuleFields = async (selectedFields: any) => {
    try {
      let resp = await salePipelinesDefaultFields("");
      if (resp) {
        setUnUsedFields(
          getValue(resp, `data`, []).filter(
            (item: object) =>
              !selectedFields.includes(getValue(item, `name`, ""))
          )
        );
        // setUnUsedFields(getValue(resp, `data`, []));
      }
    } catch (error) {}
  };
  function handleOnDragEnd(result: any) {
    if (!result.destination) {
      result.destination = {
        droppableId:
          getValue(result, `source.droppableId`, "") === "items2"
            ? "item1"
            : "items2",
        index: 0,
      };
    }

    if (getValue(result, `destination.droppableId`, "") === "items2") {
      const [reorderedItem] = selectedFields.splice(result.source.index, 1);
      if (getValue(reorderedItem, `name`, ""))
        unUsedFields.splice(result.destination.index, 0, reorderedItem);
      setUnUsedFields([...unUsedFields]);
    } else {
      const [reorderedItem] = unUsedFields.splice(result.source.index, 1);
      if (getValue(reorderedItem, `name`, ""))
        selectedFields.splice(result.destination.index, 0, reorderedItem);
      setSelectedFields([...selectedFields]);
    }
  }

  /* -------------------------------------------------------------------------- */
  /*                           Modules Status Section                            */
  /* -------------------------------------------------------------------------- */
  const [stageRequest, setStageRequest] = useState({
    openStages: [
      {
        name: "",
        type: "OPEN",
      },
    ],
    closedWonStages: [
      {
        name: "",
        type: "CLOSED_WON",
      },
    ],
    closedLostStages: [
      {
        name: "",
        type: "CLOSED_LOST",
      },
    ],
  });

  const [request, setRequest] = useState({
    name: "",
    singular_label: "",
    plural_label: "",
  });
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  /**
   *
   * Update pipelines
   * @requires
   *
   */

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    let resp;
    let payload = {};
    try {
      setSubmitLoading(true);
      if (activeTab == "1") {
        payload = {
          name: request.name,
          singular_label: request.singular_label,
          plural_label: request.plural_label,
        };
        resp = await UpdateSalesPipelineInfo(
          getValue(selectedOption, `id`, ""),
          payload
        );
        if (resp) {
          toast.success("Updated successfully");
          getAllPipelines();
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      }
      if (activeTab == "2") {
        let stages = [].concat.apply(
            [],
            [
              getValue(stageRequest, `openStages`, []).map(
                (item: object, index: number) => ({
                  ...item,
                  seq_num: index + 1,
                  name: getValue(item, `name`, ""),
                  // required: false,
                })
              ),
              getValue(stageRequest, `closedLostStages`, []).map(
                (item: object, index: number) => ({
                  ...item,
                  seq_num: index + 1,
                  name: getValue(item, `name`, ""),
                  // required: false,
                })
              ),
              getValue(stageRequest, `closedWonStages`, []).map(
                (item: object, index: number) => ({
                  ...item,
                  seq_num: index + 1,
                  name: getValue(item, `name`, ""),

                  // required: false,
                })
              ),
            ]
          ),
          payload = stages;
        resp = await UpdateSalesPipelineStages(
          getValue(selectedOption, `id`, ""),
          payload
        );
        if (resp) {
          toast.success("Updated successfully");
          getAllPipelines();
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      }
      if (activeTab == "3") {
        payload = {
          fields:
            getValue(selectedFields, `length`, 0) > 0
              ? selectedFields.map((item: any, index: number) => ({
                  name: getValue(item, `name`, ""),
                  seq_num: index + 1,
                  required: false,
                }))
              : [],
        };
        resp = await UpdateSalesPipelineFields(
          getValue(selectedOption, `id`, ""),
          payload
        );
        if (resp) {
          toast.success("Updated successfully");
          getAllPipelines();
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const handleCancel = () => {};
  return (
    <SidebarLayout {...props} isLoading={isLoading}>
      <CommonSubHeader
        subHeaderTitle={"Manage Fields"}
        linkAddress={`/${getValue(
          params,
          `orgId`,
          ""
        )}/settings/crm/module-and-field`}
        pipeline
        options={options}
        selectedPipeline={getSelectedPipeline}
        handleChangePipeline={handleChangePipeline}
        handleClickPipeline={handleClickPipeline}
        hideClose={!getValue(UrlParams, `pipeline`, "") ? true : false}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        submitLoading={submitLoading}
        activeTab={activeTab}
        hideButtons={activeTab == "2" ? true : false}
      />
      <ManageFieldsDetails
        selectedFields={selectedFields}
        handleOnDragEnd={handleOnDragEnd}
        unUsedFields={unUsedFields}
        stageRequest={stageRequest}
        setStageRequest={setStageRequest}
        request={request}
        setRequest={setRequest}
        activeTab={activeTab}
        toggle={toggle}
      />
    </SidebarLayout>
  );
}
